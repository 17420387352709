// Angular
import { Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
// Rxjs
import { forkJoin, of } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { ReportDeliveryComponent } from '../report-delivery/report-delivery.component';
// Interface
import { ReportQueryParam, SectionAnchor } from '@base/model';
import { ReportMode } from '@core/enum';
// Third Party
import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'caloudi-report-topbar',
  templateUrl: './caloudi-report-topbar.component.html',
  styleUrls: ['./caloudi-report-topbar.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class CaloudiReportTopbarComponent extends BaseComponent implements OnInit {
  @Output('printMode') public printModeEmitter: EventEmitter<boolean> = new EventEmitter<false>();

  @Input('fromPage') public fromPage: string;
  @Input('printingBtn') public printingBtn: boolean = true;
  @Input('sectionAnchors') public sectionAnchors: SectionAnchor[];
  @Input('goBackTemplate') public goBackTemplate: TemplateRef<unknown>;

  @ViewChild('reportDelivery')
  private readonly reportDeliveryComponent: ReportDeliveryComponent;

  public hasLinkBack: boolean;
  public printMode: boolean;

  private paramMode: string;

  constructor(public readonly injector: Injector, private readonly pageScrollService: PageScrollService) {
    super(injector);
  }

  public ngOnInit(): void {
    forkJoin([of(this.queryParameters()), of(this.sectionAnchors.length)]).subscribe(([mode, length]) => {
      this.logger.debug('topbar forkjoin:', [mode, length]);
      if (this.sectionAnchors.length > 0) this.displayAnchors();
    });
  }

  private queryParameters(): string {
    this.route.queryParams.subscribe((params: ReportQueryParam): void => {
      if (!params.m) return;
      if (!params.p) {
        this.fromPage ? (this.hasLinkBack = true) : (this.hasLinkBack = false);
        if (!this.hasLinkBack) this.logger.debug('topbar has link:', this.hasLinkBack);
      }
      this.logger.debug('topbar params:', params);
      this.logger.debug('topbar from page:', this.fromPage);
      this.paramMode = params.m;
      this.printMode = params.m === ReportMode.print;
    });
    return this.paramMode;
  }

  public displayAnchors(): void {
    this.sectionAnchors.map(anchor => {
      if (document.getElementById(anchor.link)) anchor.display = true;
      // Else
      // this.logger.error('anchor:', anchor.link, 'not found.');
    });
  }

  public triggerScrollTo(selector: string): void {
    this.logger.debug('triggerScrollTo:', [document, '#' + selector]);
    // location.hash = '#' + selector;
    // window.scrollTo({ top: document.querySelector('#' + selector).scrollTop });
    this.scroll('#' + selector, 150);
  }

  public toggleMode(): void {
    if (!this.printMode) {
      // this.location.replaceState(this.fromPage, ReportMode.print);
      this.router.navigateByUrl(this.router.url.replace(this.paramMode, ReportMode.print));
    } else {
      this.router.navigateByUrl(this.router.url.replace(this.paramMode, ReportMode.interactive));
    }
    this.printMode = !this.printMode;
    this.logger.debug('topbar printMode:', this.printMode);
    this.printModeEmitter.emit(this.printMode);
  }

  public goBack(): void {
    this.router.navigate([this.fromPage], { relativeTo: this.route });
  }

  public scrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.logger.debug('scrollTop:', [
      document.body.scrollTop,
      document.body.clientHeight,
      document.documentElement.scrollTop,
    ]);
  }

  public showReportDeliveryDialog(): void {
    this.reportDeliveryComponent.showReportDeliveryDialog();
  }

  private scroll(selector: string, offset?: number): void {
    this.pageScrollService.scroll({
      document: document,
      scrollTarget: selector,
      scrollOffset: offset ?? 0,
    });
  }
}
