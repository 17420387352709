import { createAction, props } from '@ngrx/store';
import { MessageState } from '@Reducers';
import { ActionCreatorProp } from '.';

// The Action interface
export enum MessageActionTypes {
  Global_Msg = '[Global Message] Action',
}

export const GlobalMsgAction: ActionCreatorProp<MessageState> = createAction(MessageActionTypes.Global_Msg, props());

export const MessageActions = {
  GlobalMsgAction,
};
