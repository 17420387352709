<div #anomalyLineChart class="anomaly_line_chart"></div>
<button
  pButton
  aria-label="Button"
  type="button"
  *ngIf="displayExport && data"
  class="export_png"
  [faIcon]="fas.faShare"
  [label]="'ACTION.EXPORT' | translate"
  (click)="showExportDialog($event)"></button>

<ng-template [ngIf]="displayDialog">
  <p-dialog
    [(visible)]="displayDialog"
    [dismissableMask]="true"
    [blockScroll]="true"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    styleClass="dialog">
    <ng-template pTemplate="header">
      <h3>{{ 'LABEL.SAVE_SVG' | translate }}?</h3>
    </ng-template>
    <div class="btn_group">
      <!-- <button pButton
         aria-label="Button"
 type="button"
            [severity]="'info'"
            [label]="'ACTION.SAVE_PNG' | translate"
            (click)="exportPNG($event)"
    >
    </button> -->
      <button
        pButton
        aria-label="Button"
        type="button"
        [severity]="'info'"
        [label]="'ACTION.SAVE_SVG' | translate"
        (click)="exportSVG($event)"></button>
      <button
        pButton
        aria-label="Button"
        type="button"
        [severity]="'danger'"
        label="cancel"
        (click)="displayDialog = false"></button>
    </div>
  </p-dialog>
</ng-template>
