// Angular
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
// Ngx
import { NGXLogger } from 'ngx-logger';
// Rxjs
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, first, forkJoin, Subject, withLatestFrom } from 'rxjs';
// Caloudi
import { CollectionUtil, CommonUtil, MenuUtil } from '@util';
import { TabMenuService } from '.';
// Store
import Actions from '@Actions';
import { Store } from '@ngrx/store';
import { AppState, initialLayoutState, LayoutConfig, LayoutState, LayoutTabMenuState, TabMenuState } from '@Reducers';
import Selectors from '@Selectors';
// Interface
import { IconData, LayoutScaleType, LayoutType, TabEvent, TabMenuBreadcrumb, TabMenuItem, ThemeOptions } from '@base/model';
import { APPTYPE, ColorScheme, DocumentLangType, EAAPITYPE, InputStyles, MenuMode, MenuProfilePosition, ThemeComponentColor, ThemeMenuColor, ThemeTopbarColor, YNType } from '@core/enum';
import { AuthUser, Breadcrumb, UserMenuItem, UserProfile } from '@core/model';
// Temp
import { fas } from '@fortawesome/free-solid-svg-icons';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  /**
   * Define Layout Configs
   * - colorScheme: ColorScheme
   * - inputStyle: typeof InputStyle
   * - lang: DocumentLangType
   * - menuMode: MenuMode
   * - ripple: boolean
   * - scale: typeof LayoutScale
   * - componentTheme: ThemeComponentColor
   * - menuTheme: ThemeMenuColor
   * - topbarTheme: ThemeTopbarColor
   * - menuProfilePosition: MenuProfilePosition
  */
  public config: LayoutConfig = {
    blockLoading: !0,
    colorScheme: ColorScheme.LIGHT,
    componentTheme: ThemeComponentColor.INDIGO,
    inlineBreadcrumb: !1,
    inputStyle: InputStyles.OUTLINED,
    lang: DocumentLangType.ENG,
    layoutType: LayoutType.LEGACY,
    menuMode: MenuMode.STATIC,
    menuProfilePosition: MenuProfilePosition.END,
    menuTheme: ThemeMenuColor.LIGHT,
    ripple: true,
    scale: 14,
    tabEnrollment: !1,
    topbarTheme: ThemeTopbarColor.BLUE,
  };

  /**
   * Define Layout States
   * - anchored
   * - configSidebarVisible
   * - menuHoverActive
   * - menuProfileActive
   * - overlayMenuActive
   * - rightMenuActive
   * - sidebarActive
   * - staticMenuDesktopInactive
   * - staticMenuMobileActive
   * - topbarMenuActive
   */
  public state: LayoutState = {
    staticMenuDesktopInactive: !1,
    overlayMenuActive: !1,
    configSidebarVisible: !1,
    staticMenuMobileActive: !1,
    menuHoverActive: !1,
    rightMenuActive: !1,
    topbarMenuActive: !1,
    menuProfileActive: !1,
    sidebarActive: !1,
    anchored: !1,
  };

  public readonly topbarThemeColor: typeof ThemeTopbarColor = ThemeTopbarColor;
  public readonly menuThemeColor: typeof ThemeMenuColor = ThemeMenuColor;
  public readonly colorScheme: typeof ColorScheme = ColorScheme;
  public readonly menuMode: typeof MenuMode = MenuMode;
  public readonly inputStyle: typeof InputStyles = InputStyles;

  private readonly configUpdate = new Subject<LayoutConfig>();
  private readonly overlayOpen = new Subject<boolean>();
  private readonly topbarMenuOpen = new Subject<boolean>();
  private readonly menuProfileOpen = new Subject<boolean>();

  public configUpdate$ = this.configUpdate.asObservable();
  public overlayOpen$ = this.overlayOpen.asObservable();
  public topbarMenuOpen$ = this.topbarMenuOpen.asObservable();
  public menuProfileOpen$ = this.menuProfileOpen.asObservable();

  public menuOrientation: typeof MenuMode = MenuMode;
  public layoutTypes: typeof LayoutType = LayoutType;
  public storageLang: DocumentLangType;
  private readonly logger: NGXLogger;
  private readonly route: ActivatedRoute;
  private readonly router: Router;

  public spinnerLoading: boolean = !1;
  public subMenuLoading: boolean = !1;
  public specialFeats: boolean = !1;

  public breadcrumb: Breadcrumb;
  public homeLink: string;
  public profile: UserProfile;
  public hasReadAnnouncement: boolean;

  /** Tab Menu */
  public tabMenuState: TabMenuState;
  public tabMenuItems: TabMenuItem[];
  public currentKey: string;
  public activeTab: TabMenuItem;
  public activeSubMenu: UserMenuItem[];
  public defaultTab: string = 'UI_XCloud';
  public currentEAAPIType: EAAPITYPE;

  private currentRoute: string;
  private selectedScale$: number = 2;
  private layout$: BehaviorSubject<LayoutConfig>;

  public get currentLayout(): BehaviorSubject<LayoutConfig> { return this.layout$; }
  public get isLightMode(): boolean { return this.config.colorScheme === ColorScheme.LIGHT; }
  public get isDarkMode(): boolean { return this.config.colorScheme === ColorScheme.DARK; }
  public get isTabMenu(): boolean { return this.config.layoutType === LayoutType.TABMENU; }
  public get isDesktop(): boolean { return window.innerWidth > 991; }
  public get isMobile(): boolean { return window.innerWidth <= 991; }
  public get isLaptop(): boolean { return window.innerWidth <= 1440; }
  public get isStatic(): boolean { return this.config.menuMode === MenuMode.STATIC; }
  public get isOverlay(): boolean { return this.config.menuMode === MenuMode.OVERLAY; }
  public get isHorizontal(): boolean { return this.config.menuMode === MenuMode.HORIZONTAL; }
  public get isSlim(): boolean { return this.config.menuMode === MenuMode.SLIM; }
  public get isSlimPlus(): boolean { return this.config.menuMode === MenuMode.SLIMPLUS; }
  public get themeScale(): LayoutScaleType { return ThemeOptions.scale[this.selectedScale]; }

  public get selectedScale(): number { return this.selectedScale$; }
  public set selectedScale(e: number) { this.selectedScale$ = e >= 0 ? e : 2; }

  public get isIE(): boolean {
    this.logActive && this.logger.debug('is IE:', [
      (<CustomNavigator>window.navigator).userAgentData?.platform,
      window.navigator.userAgent,
    ]);
    return /(MSIE|Trident\/|Edge\/)/i.test((<CustomNavigator>window.navigator).userAgentData?.platform);
  }

  private readonly logActive: boolean = !1;
  private readonly tabMenuService: TabMenuService;

  constructor(
    private readonly injector: Injector,
    private readonly store: Store<AppState>,
  ) {
    this.logger = this.injector.get(NGXLogger);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.tabMenuService = this.injector.get(TabMenuService);
    this.init();
  }

  private init(): void {
    // this.menuActive = this.isStatic && !this.isMobile;
    this.layout$ = CommonUtil.observable2BehaviorSub(this.store.select(Selectors.layout), initialLayoutState);
    combineLatest([
      this.store.select(Selectors.getLoginStatus).pipe(distinctUntilChanged()),
      this.store.select(Selectors.getBreadcrumb).pipe(
        filter((b: Breadcrumb): boolean => !!b?.routeLink),
        distinctUntilChanged((p, c) => CommonUtil.equals(p, c))
      ),
    ])
      .pipe(withLatestFrom(this.store.select(Selectors.getUser)), distinctUntilChanged())
      .subscribe(([[loggedIn, breadcrumb], user]: [[boolean, Breadcrumb], AuthUser]): void => {
        this.logActive && this.logger.debug('layout service:', [loggedIn, { ...user }, { ...breadcrumb }]);
        if (!loggedIn || !user?.userProfile) return;
        [this.breadcrumb, this.homeLink, this.profile] = [breadcrumb, user.userMenu.homeRouterLink, user.userProfile];
        this.hasReadAnnouncement = user.userProfile?.hasReadAnnouncement === YNType.Y;
      });
  }

  public replaceThemeLink(href: string, onComplete: () => void): void {
    const id = 'theme-link';
    const themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }

  public onColorSchemeChange(colorScheme: ColorScheme): void {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href');
    const currentColorScheme = 'theme-' + this.config.colorScheme;
    const newColorScheme = 'theme-' + colorScheme;
    const newHref = themeLinkHref.replace(currentColorScheme, newColorScheme);
    this.replaceThemeLink(newHref, () => {
      this.config.colorScheme = colorScheme;
      if (colorScheme === ColorScheme.DARK) { this.config.menuTheme = ThemeMenuColor.DARK; }
      this.onConfigUpdate();
    });
  }

  public onComponenthangeTheme(theme: ThemeComponentColor): void {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href');
    const newHref = themeLinkHref.replace(this.config.componentTheme, theme);
    this.replaceThemeLink(newHref, () => {
      this.config.componentTheme = theme;
      this.onConfigUpdate();
    });
  }

  public onConfigUpdate(): void {
    this.configUpdate.next(this.config);

    // this.store.dispatch(
    //   ChangeLayoutAction({
    //     colorScheme: this.config.colorScheme,
    //     componentTheme: this.config.componentTheme,
    //     inputStyle: this.config.inputStyle,
    //     lang: this.storageLang,
    //     layoutType: this.config.layoutType,
    //     menuMode: this.config.menuMode,
    //     menuProfilePosition: this.config.menuProfilePosition,
    //     menuTheme: this.config.menuTheme,
    //     ripple: this.config.ripple,
    //     scale: this.config.scale,
    //     topbarTheme: this.config.topbarTheme,
    //     tabKey: this.config.layoutType === LayoutType.TABMENU ? this.config.tabKey : void 0,
    //   })
    // );
  }

  public blockBodyScroll(): void {
    if (document.body.classList) document.body.classList.add('blocked-scroll');
    else document.body.className += ' blocked-scroll';
  }

  public unblockBodyScroll(): void {
    if (document.body.classList) document.body.classList.remove('blocked-scroll');
    else document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
      'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  public onMenuToggle(): void {
    if (this.isOverlay) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;
      if (this.state.overlayMenuActive) this.overlayOpen.next(null);
    }

    if (this.isDesktop) {
      this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;
      if (this.state.staticMenuMobileActive) this.overlayOpen.next(null);
    }
  }

  public onTopbarMenuToggle(): void {
    this.state.topbarMenuActive = !this.state.topbarMenuActive;
    if (this.state.topbarMenuActive) {
      this.topbarMenuOpen.next(null);
    }
  }

  public onOverlaySubmenuOpen(): void {
    this.overlayOpen.next(null);
  }

  public showConfigSidebar(): void {
    this.state.configSidebarVisible = true;
  }

  /**
   * Get current tab list
   * Triggered by layout type changes, eaapiType changes or language changes
   */
  public tabMenuListener(): void {
    if (this.tabMenuState) return;
    else
      this.store
        .select(Selectors.tabmenu)
        .pipe(first())
        .subscribe((t: TabMenuState): TabMenuState => (this.tabMenuState = t));
    combineLatest([
      this.store
        .select(Selectors.getLayouts)
        .pipe(
          distinctUntilChanged(
            (p: LayoutConfig, c: LayoutConfig): boolean => p.layoutType === c.layoutType && p.lang === c.lang
          )
        ),
      this.store.select(Selectors.getEAAPIType).pipe(distinctUntilChanged()),
      this.store.select(Selectors.getAppId).pipe(distinctUntilChanged()),
    ]).subscribe(([layout, eaapiType, appId]: [LayoutConfig, EAAPITYPE, APPTYPE]): void => {
      this.logActive &&
        this.logger.debug('getLayoutTabMenu:', [{ ...layout }, eaapiType, appId, this.storageLang, layout.lang]);
      if (!layout || !eaapiType || !appId) return;
      this.checkLayoutType(layout, eaapiType, appId);
      if (this.currentEAAPIType === eaapiType && this.storageLang === layout.lang) return;
      else this.currentEAAPIType = eaapiType;
      if (eaapiType !== EAAPITYPE.XCloud) return;
      else setTimeout((): void => this.bindTabMenu());
    });
  }

  /**
   * Switch tab by route changes
   */
  public layoutRouteListener(): void {
    this.router.events
      .pipe(filter((event: Event | RouterEvent): boolean => event instanceof NavigationEnd && event.url !== '/reload'))
      .subscribe((event: RouterEvent): void => {
        this.logActive && this.logger.debug('routerChange:', [this.currentRoute, event]);
        if (this.config.layoutType !== LayoutType.TABMENU || this.currentRoute === event.url) return;
        this.currentRoute = event.url;
        const key: string = MenuUtil.getTabMenuCurrentTab(this.tabMenuState.breadcrumbs, event.url);
        if (this.currentKey !== key) this.getActiveTab();
      });
  }

  /**
   * Get breadcrumbs and tab menu items
   */
  private bindTabMenu(): void {
    forkJoin([this.tabMenuService.getTabMenuBreadcrumbs(), this.tabMenuService.getTabMenuItems()]).subscribe(
      ([breadcrumbs, items]: [TabMenuBreadcrumb, TabMenuItem[]]): void => {
        // items = [...items, {
        //   label: 'test',
        //   items: [
        //     { label: 'test1', routerLink: '' },
        //     { label: 'test2', routerLink: '' },
        //     { label: 'test3', routerLink: '' },
        //     { label: 'test4', routerLink: '' },
        //     { label: 'test5', routerLink: '' },
        //   ]
        // }];
        this.logActive && this.logger.debug('bindTabMenu:', [breadcrumbs, items]);
        this.store.dispatch(Actions.ChangeTabMenuBreadcrumbsAction({ breadcrumbs }));
        this.store.dispatch(Actions.ChangeTabMenuItemsAction({ items }));

        this.currentKey = MenuUtil.getTabMenuCurrentTab(breadcrumbs, location.pathname) || this.defaultTab;
        this.getSubMenu(this.currentKey);
        this.tabMenuItems = items.map(
          (item: TabMenuItem): TabMenuItem =>
            <TabMenuItem>{
              ...item,
              icon: item.icon || fas.faQuestion,
              command: (e: TabEvent): void => void (item.items || this.tabClicked(e)),
            }
        );

        this.tabMenuItems.forEach((item: TabMenuItem): void => {
          switch (item.id) {
            case 'UI_AWS':
              item.iconData = this.IconData(this.isDarkMode).AWS;
              break;
            case 'UI_Azure':
              item.iconData = this.IconData(this.isDarkMode).AZURE;
              break;
            case 'UI_GCP':
              item.iconData = this.IconData(this.isDarkMode).GCP;
              break;
            case 'UI_XCloud':
              item.icon || (item.iconData = this.IconData(this.isDarkMode).XCLOUD);
              break;
            default:
              break;
          }
        });
        this.getActiveTab();
        this.currentRoute = this.router.url;
        this.logActive && this.logger.debug('get init', [breadcrumbs, items, this.currentKey, this.route, this.router, location]);
        this.logActive && this.logger.debug('tabMenuItems:', [this.tabMenuItems]);
      }
    );
  }

  /**
   * Get sub menu by key or from memory cache
   * @param key tab menu key
   */
  private getSubMenu(key: string): void {
    this.subMenuLoading = true;
    this.store
      .select(Selectors.tabmenu)
      .pipe(first())
      .subscribe((state: TabMenuState): void => {
        this.logActive && this.logger.debug('lang:', [this.storageLang, state]);
        // Rename to fit the routerLink
        const rename = (payload: TabMenuItem[]): TabMenuItem[] => payload
          .map(item => {
            let result: TabMenuItem = { ...item, routerLink: item.routerlink };
            if (result.items) result.items = rename(item.items);
            if (!result.routerLink) delete result.routerLink;
            delete result.routerlink;
            return result;
          });
        // Skip Sales agent & admin & user profile
        if (!state.items?.some(item => item.id === key)) key = this.defaultTab;
        // Skip when cache
        if (state.cache?.[key] && state.lang === this.storageLang) {
          this.store.dispatch(Actions.ChangeSubmenuAction({ items: state.currentSubmenu }));
          this.activeSubMenu = state.cache[key];
        } else
          this.tabMenuService.getXCloudsubMenuByMenuTabKey(key).subscribe((item: TabMenuItem[]): void => {
            const menu = rename(item);
            this.store.dispatch(Actions.ChangeSubmenuAction({ items: menu }));
            this.store.dispatch(Actions.ChangeCacheByKeyAction({ cache: { [key]: menu } }));
            this.store.dispatch(Actions.ChangeTabMenuLangAction({ lang: this.storageLang }));
            this.activeSubMenu = menu;
            this.subMenuLoading = false;
            this.logActive && this.logger.debug('getSubMenu result:', [menu]);
          });
        this.logActive && this.logger.debug('getSubMenu:', [key, state, this.tabMenuItems, this.activeSubMenu]);
      });
  }

  /**
   * Navigate to default page & do something
   * @param event TabEvent
   */
  private tabClicked(event: TabEvent): void {
    this.router.navigate([event.item.routerlink]).then((): void => this.getSubMenu(event.item.id));
    this.currentKey = event.item?.items?.length > 0 ? this.currentKey : event.item.id;
    this.store.dispatch(Actions.ChangeTabKeyAction({ tabKey: this.currentKey }));
    this.logActive && this.logger.debug(`tabClicked(${event.item.id}):`, [event]);
  }

  /**
   * Highlighting current active tab
   */
  private getActiveTab(): void {
    if (!this.tabMenuItems) return;
    this.activeTab = this.tabMenuItems.find(item => item.id === this.currentKey);
    // const result = this.tabMenuItems.find(item => item.id === this.currentKey);
    // this.activeTab = result.items?.length > 0 ? this.activeTab : result;
    // this.activeTab = this.tabMenuItems[this.tabMenuItems.length - 1];
    this.store.dispatch(Actions.ChangeTabKeyAction({ tabKey: this.currentKey }));
    this.logActive && this.logger.debug('getActiveTab:', [this.tabMenuItems, this.currentKey, this.activeTab]);
  }

  /**
   * Bind current key & layout type by state & ea api type
   * @param layout layout state
   * @param eaapiType ea api type from user profile
   * @param appId app type id
   */
  public checkLayoutType(layout: LayoutTabMenuState, eaapiType: EAAPITYPE, appId: APPTYPE): void {
    this.logActive && this.logger.debug('checkLayoutType:', [layout, eaapiType, appId]);
    if (!CollectionUtil.isIncludeInEnum(APPTYPE, appId))
      this.logger.error('App Type Error: App Type Not Exist', `"${appId}"`);

    if (!CollectionUtil.isIncludeInEnum(EAAPITYPE, eaapiType))
      this.logger.error('EA API Type Error: EA API Type Not Exist', `"${eaapiType}"`);

    this.currentKey = (eaapiType && eaapiType !== EAAPITYPE.XCloud) || appId !== APPTYPE.CMP
      ? undefined
      : layout.tabKey || this.defaultTab;

    this.config.layoutType = (eaapiType && eaapiType !== EAAPITYPE.XCloud) || appId !== APPTYPE.CMP
      ? LayoutType.LEGACY
      : LayoutType.TABMENU;

    if ((eaapiType && eaapiType !== EAAPITYPE.XCloud) || appId !== APPTYPE.CMP) {
      // if (layout.layoutType === LayoutType.LEGACY) this.config.tabKey = void 0;
      // else this.store.dispatch(Actions.ClearTabMenuStaticsAction());
      if (layout.layoutType === LayoutType.LEGACY) {
        this.store.dispatch(Actions.ChangeLayoutTypeAction({ layoutType: LayoutType.LEGACY }));
        this.store.dispatch(Actions.ClearTabMenuStaticsAction());
      }
    }
  }

  /**
   * Get current logo color
   * @param bORw is black or white
   * @returns Icon datas
   */
  public IconData: (bORw?: boolean) => IconData = (bORw: boolean = true): IconData => {
    const preset: string = 'assets/images/logo/';
    const bw = bORw ? 'w' : 'b';
    return {
      AWS: { path: `${preset}logo_aws_${bw}.svg`, title: 'AWS', alt: 'Amazon Web Services' },
      AZURE: { path: `${preset}logo_azure_${bw}.svg`, alt: 'Azure', title: 'Azure' },
      GCP: { path: `${preset}logo_gcp_${bw}.svg`, alt: 'GCP', title: 'Google Cloud Platform' },
      XCLOUD: { path: `${preset}logo_xcloud_${bw}.svg`, alt: 'XCloud', title: 'Cross-Cloud' },
    };
  };
}

/** for IE */
interface CustomNavigator extends Navigator {
  userAgentData: { platform: string; };
}
