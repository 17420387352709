export const localeKeys = {
  VPN_PROFILE: {
    DOWNLOADS: 'VPN_PROFILE.DOWNLOADS',
    NAME: 'VPN_PROFILE.NAME',
    DNS_SERVERS: 'VPN_PROFILE.DNS_SERVERS',
    TITLE: {
      DELETE: 'VPN_PROFILE.TITLE.DELETE',
      EDIT: 'VPN_PROFILE.TITLE.EDIT',
      NEW: 'VPN_PROFILE.TITLE.NEW',
      TEST: 'VPN_PROFILE.TITLE.TEST',
    },
    ADD_SUCCESS: 'VPN_PROFILE.ADD_SUCCESS',
    EDIT_SUCCESS: 'VPN_PROFILE.EDIT_SUCCESS',
    DELETE_SUCCESS: 'VPN_PROFILE.DELETE_SUCCESS',
    ACTION: {
      TEST: 'VPN_PROFILE.ACTION.TEST',
      EDIT: 'VPN_PROFILE.ACTION.EDIT',
      DELETE: 'VPN_PROFILE.ACTION.DELETE',
    },
    TEST: {
      START: 'VPN_PROFILE.TEST.START',
      TESTING: 'VPN_PROFILE.TEST.TESTING',
      STANDBY: 'VPN_PROFILE.TEST.STANDBY',
    },
    MESSAGE: {
      DELETE: 'VPN_PROFILE.MESSAGE.DELETE',
    },
  },
};
