export const localeKeys = {
  CONFIG: {
    TITLE: {
      THEME_CUSTOMIZATION: 'CONFIG.TITLE.THEME_CUSTOMIZATION',
      SCALE: 'CONFIG.TITLE.SCALE',
      LAYOUT_MODE: 'CONFIG.TITLE.LAYOUT_MODE',
      MENU_MODE: 'CONFIG.TITLE.MENU_MODE',
      INPUT: 'CONFIG.TITLE.INPUT',
    },
    THEMES: {
      MENU: 'CONFIG.THEMES.MENU',
      TOPBAR: 'CONFIG.THEMES.TOPBAR',
      COMPONENT: 'CONFIG.THEMES.COMPONENT',
    },
    LIGHT: 'CONFIG.LIGHT',
    DARK: 'CONFIG.DARK',
    STATIC: 'CONFIG.STATIC',
    HORIZONTAL: 'CONFIG.HORIZONTAL',
    OVERLAY: 'CONFIG.OVERLAY',
    OUTLINED: 'CONFIG.OUTLINED',
    FILLED: 'CONFIG.FILLED',
  },
};
