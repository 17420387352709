import { DEFAULT_FILENAME, REMOVE_TIMEOUT } from './const';

/** Retrun filename from properties */
export const getFilename = (source: Element): string => {
  if (!(source instanceof SVGElement)) {
    throw new Error('SVG Element is required');
  }

  return (
    source.getAttribute('id') ||
    source.getAttribute('class') ||
    document.title.replace(/[^a-z0-9]/gi, '-').toLowerCase() ||
    DEFAULT_FILENAME
  );
};

/** Commence download */
export const commenceDownload = (filename: string, imgdata: string, callback: () => void): void => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('class', 'svg-crowbar');
  a.setAttribute('download', filename);
  a.setAttribute('href', imgdata);
  a.style.display = 'none';
  a.click();
  setTimeout((): void => {
    if (callback) {
      callback();
    }

    document.body.removeChild(a);
  }, REMOVE_TIMEOUT);
};
