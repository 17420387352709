// Angular
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Interface
import { StackBarChartData } from '@base/model';
// D3
import * as d3 from 'd3';

@Component({
  selector: 'caloudi-stack-bar-chart',
  templateUrl: './stack-bar-chart.component.html',
  styleUrls: ['./stack-bar-chart.component.sass']
})
export class StackBarChartComponent implements OnInit, OnChanges {

  @Input('data') public data: StackBarChartData[];
  /** Bar height */
  @Input('height') public _height: number;
  /** The max value of bar */
  @Input('total') public total: number;

  private el: HTMLElement;
  private width: number;
  private height: number;
  private dataCount: number;
  private totalCount: number;

  private xScale: d3.ScaleLinear<number, number>;
  private color: (t: number) => string;

  private readonly logActive: boolean = !1;

  constructor(
    private readonly element: ElementRef<HTMLElement>,
  ) { }

  public ngOnInit(): void {
    this.el = this.element.nativeElement;
    this.width = this.el.parentElement.clientWidth;
    this.height = this._height || this.el.parentElement.clientHeight || 20;
    this.data = [];

    this.color = d3.interpolate('#3333aa', '#aa3333');
    this.xScale = d3.scaleLinear()
      .range([0, this.width])
      .domain([0, this.totalCount]);

    /** Basic svg  */
    const svg = d3.select<HTMLDivElement, StackBarChartData>('#multi-bar-chart')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('viewBox', [0, 0, this.width, this.height]);

    /** Clip path */
    const clip = svg.append('defs');

    clip.append('clipPath')
      .attr('id', 'sbc-clip')
      .append('rect')
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('width', this.width)
      .attr('height', this.height);

    /** Clip path 2 */
    clip.append('clipPath')
      .attr('id', 'sbc-clip2')
      .append('rect')
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('width', this.width > this.xScale(this.dataCount) ? this.xScale(this.dataCount) : this.width)
      .attr('height', this.height);

    /** Background color */
    svg.append('rect')
      .attr('clip-path', 'url(#sbc-clip)')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', '#eee')
      .append('title')
      .text(this.totalCount - this.dataCount);

    /** Data section */
    // const data = svg.append('g')
    const data = svg.append('g')
      .attr('class', 'data')
      .attr('clip-path', 'url(#sbc-clip2)');

    /** Data Bar */
    data.selectAll<SVGRectElement, StackBarChartData>('rect')
      .data(this.data)
      .join('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.width)
      .attr('height', this.height);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.data) return;
    this.el = this.element.nativeElement;
    this.width = this.el.parentElement.clientWidth;
    this.height = this._height || this.el.parentElement.clientHeight || 20;
    this.dataCount = this.data.reduce((p, c) => p + c.value, 0);
    this.totalCount = this.total ?? this.dataCount;
    this.xScale = d3.scaleLinear()
      .range([0, this.width])
      .domain([0, this.totalCount]);

    this.logActive && console.debug('changes:', [changes]);

    d3.select('#multi-bar-chart')
      .select('.data')
      .selectAll<SVGRectElement, StackBarChartData>('rect')
      .data(this.data, d => d.value)
      .join(
        enter => enter.append('rect')
          .attr('x', (_, i) => this.xScale(this.data
            .filter((_, i2) => i2 < i)
            .reduce((p, c) => p + c.value, 0)) ?? 0)
          .attr('y', 0)
          .attr('height', this.height)
          .attr('width', d => d.value > 0 ? this.xScale(d.value) : 0)
          .attr('fill', (d, i) => d.color || this.color(i))
          .append('title')
          .text(d => `${d.name}: ${d.value}`),
        update => update,
        exit => exit.call(item =>
          item.remove())
      );
  }
}
