<!-- <div #contentDiv> -->
<div class="w-full flex gap-3 align-items-start">
  <div class="flex-1">
    <div [ngClass]="{ collapsed: isCollapsed, spread: !isCollapsed }" [ngStyle]="{ 'height.px': styleHeight }">
      <div [innerHTML]="data" #containerDiv></div>
      <!-- <ng-content></ng-content> -->
    </div>
  </div>

  <!-- [ngClass]="{ hidden: !isCollapsable }" -->
  <div style="width: 2.5%">
    <div
      *ngIf="isCollapsable"
      (click)="switchReadMore()"
      class="font-bold text-primary-500 my-1 flex align-items-center gap-1 cursor-pointer">
      <!-- <span>Read {{ isCollapsed ? 'more' : 'less' }}</span> -->
      <!-- {{ isCollapsable }} -->
      <fa-icon [icon]="isCollapsed ? fas.faChevronCircleDown : fas.faChevronCircleUp" [size]="'xl'"> </fa-icon>
    </div>
  </div>
</div>

<div *ngIf="!showReadMore" style="padding-bottom: 20px"></div>

<!-- <div [ngClass]="{ isCollapsed: !isCollapsed, collapsed: isCollapsed }" class="pull-left readMore">
  <ng-content></ng-content>
</div>
<a (click)="isCollapsed = !isCollapsed" [hidden]="isReadMore" class="pull-right">
  <fa-icon [icon]="fas.faChevronRight" *ngIf="isCollapsed"></fa-icon>
  <fa-icon [icon]="fas.faChevronDown" *ngIf="!isCollapsed"></fa-icon>
</a>
<div class="cb"></div> -->
