// Angular
import { Component, Injector, OnDestroy } from '@angular/core';
// Rxjs
import { Subject } from 'rxjs';
// Caloudi
import BaseComponent from '@base';
// import { ChatBotToken, WebChatBotService } from '@base/service';

@Component({
  selector: 'caloudi-webchat-bot',
  templateUrl: './webchat-bot.component.html',
  styleUrls: ['./webchat-bot.component.sass'],
})
export class WebChatBotComponent extends BaseComponent implements OnDestroy {

  // @ViewChild('botWindow') public botWindow: ElementRef<HTMLDivElement>;

  public botActive: boolean = !1;
  public displayBot: boolean;

  // private init: boolean;
  // private expiredAt: number;

  // private readonly logActive: boolean = !1;
  private readonly expireTimer: Subject<number> = new Subject<number>();

  constructor(
    public readonly injector: Injector,
    // private readonly webChatService: WebChatBotService,
  ) {
    super(injector);
    // this.logActive && this.logger.debug('caloudi-webchat-bot init', [window.WebChat]);
  }

  public toggleWebChat(): void {
    if ((this.displayBot = !this.displayBot) && !this.displayBot) return (document.body.style.overflow = null);
    document.body.style.overflow = 'hidden';

    // if (!this.init && (this.init = !this.init)) this.chatInit();
  }

  // private chatInit(): void {
  // this.webChatService.GenerateWebChatToken().subscribe({
  //   next: res => {
  //     if (!window.WebChat) return void this.logger.error('WebChat not found!');
  //     document.querySelectorAll('div#webchat > *').forEach(ele => ele.remove());
  //     let pair: boolean,
  //       firstPass: boolean,
  //       actionTypes: string[] = [];

  //     const directLine = window.WebChat.createDirectLine({ token: res.token });
  //     const styleOptions = {
  //       rootHeight: '100%',
  //       rootWidth: '100%',
  //       transcriptActivityVisualKeyboardIndicatorColor: 'DEFAULT_SUBTLE',
  //       transcriptActivityVisualKeyboardIndicatorStyle: 'dashed',
  //       transcriptActivityVisualKeyboardIndicatorWidth: 1,
  //       transcriptVisualKeyboardIndicatorColor: 'Black',
  //       transcriptVisualKeyboardIndicatorStyle: 'solid',
  //       transcriptVisualKeyboardIndicatorWidth: 2,
  //     };
  //     const store: unknown = window.WebChat.createStore({}, event => next => action => {
  //       this.logActive && this.logger.debug('store action:', [event.getState(), next, action]);
  //       switch (action.type) {
  //         // Chat initialed and helloed
  //         case 'DIRECT_LINE/INCOMING_ACTIVITY': {
  //           if (firstPass) break;
  //           this.logActive && this.logger.debug(`☝🏻action (${action?.type}):`, [event.getState(), next, action, pair]);
  //           event.dispatch({ type: 'WEB_CHAT/SEND_MESSAGE', payload: { text: 'help' } });
  //           break;
  //         }
  //         // Bot Reply
  //         case 'WEB_CHAT/SET_SUGGESTED_ACTIONS': {
  //           if ((pair = !pair) && !pair) break;
  //           this.logActive && this.logger.debug(`🤟🏻action (${action?.type}):`, [event.getState(), next, action, pair]);
  //           // this.webChatService.RefreshWebChatToken(res).subscribe(e => this.logActive && this.logger.debug('refreshed', [e]));
  //           break;
  //         }
  //         // Every messages from user
  //         case 'WEB_CHAT/SEND_MESSAGE': {
  //           if (!firstPass && (firstPass = !firstPass)) break;
  //           action.payload.text = 'test(🍕) ' + action.payload.text;
  //           this.logActive && this.logger.debug(`✌🏻action (${action?.type}):`, [event.getState(), next, action]);
  //           break;
  //         }
  //         // Chat fulfilled
  //         case 'DIRECT_LINE/POST_ACTIVITY_FULFILLED': {
  //           this.refresh(res);
  //           this.logActive && this.logger.debug(`🖖🏻action (${action?.type}):`, [event.getState(), next, action]);
  //           break;
  //         }
  //         default:
  //           this.logActive && this.logger.debug(`action (${action?.type})`, [event.getState(), next, action]);
  //       }

  //       // List action types
  //       ((control: boolean): void => {
  //         if (!control) return;
  //         const [oldLength, newLength] = [actionTypes.length, actionTypes.push(action.type)];
  //         actionTypes = [...new Set(actionTypes)].sort();
  //         if (oldLength !== newLength && this.logActive)
  //           this.logger.debug('actionTypes:', [actionTypes, 'lastest: ' + action.type]);
  //       })(false);

  //       return next(action);
  //     });

  //     this.expiredAt = Date.now() + res.expires_in * 1e3;
  //     this.logActive &&
  //       this.logger.debug('get token', [res, directLine, new Date(this.expiredAt).toString(), document.hidden]);

  //     /**
  //      * Remove the current bot and render a new one
  //      */
  //     combineLatest([
  //       this.store.select(this.selectors.getLayout).pipe(map(layout => layout.lang)),
  //       this.store.select(this.selectors.getUserProfile),
  //     ]).subscribe(([locale, profile]) => {
  //       this.logActive && this.logger.debug('store:', [locale, profile, firstPass]);
  //       // Fix body style
  //       if (typeof firstPass === 'boolean' && firstPass) document.body.style.overflow = null;
  //       if (!profile) return;

  //       // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //       window.WebChat?.renderWebChat(
  //         {
  //           directLine,
  //           locale,
  //           store,
  //           styleOptions,
  //           userID: profile.userId,
  //           username: profile.userName,
  //         },
  //         document.getElementById('webchat')
  //       );
  //     });
  //   },
  //   error: e => {
  //     this.logger.error('err:', [e]);
  //     [this.displayBot, this.init] = [!1, !1];
  //   },
  // });
  // }

  /**
   * Background token refresher
   * @param payload ChatBotToken
   */
  // private refresh(payload: ChatBotToken): void {
  /**
   * Check by dom changes
   */
  // new MutationObserver((list, obs) => {
  //   if (!document.querySelector('div#webchat')) obs.disconnect();
  //   from(of(list)).pipe().subscribe(res => {
  //     if (!this.displayBot || this.refreshActive) return;
  //     this.refreshActive = !0;
  //     setTimeout(() => { this.refreshActive = !1; }, 5000);
  //     this.logActive && this.logger.debug(`refresh:`, [res, obs, payload, window.WebChat]);
  //     this.webChatService.RefreshWebChatToken(payload).subscribe(e => this.logActive && this.logger.debug('refreshed', [e]));
  //   });
  // }).observe(document.querySelector('div#webchat'), { childList: !0, subtree: !0 });

  /**
   * Check by every minute or tab visibility
   */
  //   merge(of('begin'), fromEvent(document, 'visibilitychange'), interval(6e4))
  //     .pipe(
  //       takeUntil(this.expireTimer),
  //       concatMap(res => {
  //         const restTime = this.expiredAt - Date.now() - 9e5;
  //         // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //         this.logActive && this.logger.debug(`🐈 ${!document.hidden} cat:`, [res, Math.round(restTime / 1000)]);
  //         if (restTime > 0) return of();
  //         this.expiredAt = Date.now() + payload.expires_in * 1e3;
  //         return this.webChatService.RefreshWebChatToken(payload);
  //       })
  //     ).subscribe(res => this.logActive && this.logger.debug('background refresh', [res]));
  // }

  public ngOnDestroy(): void {
    this.expireTimer?.next(void 0);
    this.expireTimer?.unsubscribe();
  }
}
