// Angular
import { AfterViewChecked, Component, EventEmitter, Injector, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// Primeng
import { ConfirmationService } from 'primeng/api';
// Rxjs
import { combineLatest, distinctUntilChanged } from 'rxjs';
// Caloudi
import { BaseComponent } from '@base';
import { ReportService } from '@base/service';
import { CommonValidatorService } from '@core/service/validator';
import * as cronstrue from 'cronstrue';
import { CronOptions } from '../cron-editor/CronOptions';
// Interface
import { ReportQueryParam } from '@base/model';
import { MessageSeverity, YNType } from '@core/enum';
import { UserMenuItem } from '@core/model';
import { CurrentSubmenu } from '@store/reducers/tab-menu.reducers';

@Component({
  selector: 'caloudi-report-delivery',
  templateUrl: './report-delivery.component.html',
  styleUrls: ['./report-delivery.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportDeliveryComponent extends BaseComponent implements OnChanges, AfterViewChecked {
  @Input('reportUrl') public reportUrl: string;
  @Input('reportName') public reportName: string;
  @Input('reportTitle') public reportTitle: string;
  @Input('reportNote') public reportNote: string;
  @Input('emailRecipients') public emailRecipients: string[] = [];
  @Input('schedule') public schedule: string;
  @Input('cronReportId') public cronReportId: string;
  @Input('isActive') public isActive: YNType;
  @Input('editMode') public editMode: boolean;

  @Output('mailActionReloadTable')
  public mailActionReloadTableEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  public reportDeliveryForm: FormGroup<ReportDeliveryFormGroup>;
  public isActiveButton: boolean;
  public submitted: boolean;
  public displayReportDeliveryDialog: boolean;
  public cronForm: FormControl<string>;
  public cronLangConverter: string;

  public get f(): ReportDeliveryFormGroup {
    return this.reportDeliveryForm.controls;
  }

  public cronOptions: CronOptions = {
    formInputClass: null,
    formSelectClass: null,
    formCheckboxClass: null,
    formRadioClass: null,
    defaultTime: '06:00:00',

    hideMinutesTab: true,
    hideHourlyTab: true,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: true,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab: false,

    use24HourTime: true,
    hideSeconds: true,

    cronFlavor: 'standard', // standard or quartz
  };

  private readonly logActive: boolean;

  constructor(
    public readonly injector: Injector,
    private readonly formBuilder: FormBuilder,
    private readonly validator: CommonValidatorService,
    private readonly reportService: ReportService,
    private readonly confirmationService: ConfirmationService
  ) {
    super(injector);
  }

  public ngAfterViewChecked(): void {
    this.cronLangConverter = cronstrue.toString(this.cronForm.value, {
      locale: this.lang('LABEL.CRON_LANG'),
    });
    this.logger.debug('t' + this.lang('LABEL.CRON_LANG') + ':', [this.cronForm.value, this.cronLangConverter]);
  }

  public ngOnChanges(): void {
    // cron-editor initialize
    this.cronForm = new FormControl('0 6 * * MON-FRI');

    combineLatest([
      this.store.select(this.selectors.getBreadcrumb),
      this.store.select(this.selectors.getCurrentSubMenuItems),
    ])
      .pipe(distinctUntilChanged())
      .subscribe(([breadcrumb, submenu]) => {
        if (!breadcrumb && !submenu) return;
        this.logActive &&
          this.logger.debug('ReportDeliveryComponent breadcrumb:', [location, this.router.url, breadcrumb, submenu]);

        let menuItem: CurrentSubmenu | UserMenuItem;
        const targetRoute = this.router.url || location.pathname;
        if (breadcrumb)
          menuItem = breadcrumb.menuItemList.find(
            item =>
              (item['routerlink'] || item.routerLink) && new RegExp(item['routerlink'] as string || item.routerLink).test(targetRoute)
          );
        else menuItem = submenu.find(item => item.route && new RegExp(item.route).test(targetRoute));

        if (!this.editMode) {
          this.emailRecipients[0] = this.authenticationService.currentUser.value.userProfile.userId;
          if (breadcrumb) this.reportName = !this.reportName ? (<UserMenuItem>menuItem)?.label : this.reportName;
          else this.reportName = (!this.reportName ? (<CurrentSubmenu>menuItem)?.label : this.reportName) ?? '';
          this.reportTitle = this.reportName ?? '';
          this.reportUrl = !this.reportUrl ? this.reportUrl : location.href.split('#')[0];
          this.route.queryParams.subscribe((params: ReportQueryParam): void => {
            const paramMode = 'm=' + params.m + '&';
            if (!this.reportUrl.includes(paramMode)) this.reportUrl = location.href.split('#')[0];
            // Force replace all interactive to print mode
            // Todo: change by user choose and force choose print when spicified report
            if (paramMode === 'm=interactive&') this.reportUrl = this.reportUrl.replace(paramMode, 'm=print&');
          });
        }
        this.isActiveButton = this.isActive === this.YNType.Y;
        this.cronForm.setValue(!this.schedule ? '0 6 * * MON-FRI' : this.schedule);
        // Form initiation
        this.reportDeliveryForm = this.formBuilder.group<ReportDeliveryFormGroup>(
          {
            mailLists: new FormControl(this.emailRecipients, this.validator.multiEmailAddressValidator()),
            mailTitle: new FormControl(this.reportTitle ?? ''),
            mailNote: new FormControl(this.reportNote ?? ''),
          },
          { validators: [] }
        );

        this.logActive &&
          this.logger.debug('ReportDeliveryComponent result:', [
            breadcrumb,
            submenu,
            menuItem,
            this.reportName,
            this.reportTitle,
            this.reportUrl,
          ]);
      });
  }

  public onReportDeliveryFormSubmit(): void {
    this.submitted = true;
    if (this.reportDeliveryForm.invalid) return;
    this.reportService
      .emailReport({
        reportUrl: this.reportUrl,
        emailRecipients: this.f.mailLists.value,
        reportName: this.reportName,
        reportTitle: this.f.mailTitle.value,
        reportNote: this.f.mailNote.value,
      })
      .subscribe(res => {
        // Toast success message
        this.store.dispatch(
          this.actions.GlobalMsgAction({
            severity: MessageSeverity.SUCCESS,
            summary: this.lang('ACTION.SEND_CRON_REPORT'),
            message: res.message,
          })
        );
        this.displayReportDeliveryDialog = false;
        this.submitted = false;
      });
  }

  public onCronSubmit(): void {
    this.submitted = true;
    if (this.reportDeliveryForm.invalid) return;
    const payload = {
      emailRecipients: this.f.mailLists.value,
      reportName: this.reportName,
      reportNote: this.f.mailNote.value,
      reportTitle: this.f.mailTitle.value,
      scheduleString: this.cronForm.value,
    };
    if (this.editMode) {
      this.reportService
        .updateCronReport({
          ...payload,
          cronReportId: this.cronReportId,
          isEnabled: this.isActiveButton ? this.YNType.Y : this.YNType.N,
        })
        .subscribe(res => {
          // Toast success message
          this.store.dispatch(
            this.actions.GlobalMsgAction({
              severity: MessageSeverity.SUCCESS,
              summary: this.lang('ACTION.UPDATE_CRON_REPORT'),
              message: res.message,
            })
          );
          this.displayReportDeliveryDialog = false;
          this.mailActionReloadTableEventEmitter.emit();
        });
    } else {
      this.reportService
        .createCronReport({
          ...payload,
          reportUrl: this.reportUrl,
        })
        .subscribe(res => {
          // Toast success message
          this.store.dispatch(
            this.actions.GlobalMsgAction({
              severity: MessageSeverity.SUCCESS,
              summary: this.lang('ACTION.ADD_CRON_REPORT'),
              message: res.message,
            })
          );
          this.displayReportDeliveryDialog = false;
          this.mailActionReloadTableEventEmitter.emit();
        });
    }
    this.submitted = false;
  }

  public onDeleteSchedule(): void {
    if (!this.cronReportId) return;
    this.confirmationService.confirm({
      message: this.lang('ACTION.DELETE_CRON_REPORT') + '?',
      accept: () => {
        this.reportService.deleteCronReport(this.cronReportId).subscribe(res => {
          // Toast success message
          this.store.dispatch(
            this.actions.GlobalMsgAction({
              severity: MessageSeverity.SUCCESS,
              summary: this.lang('ACTION.DELETE_CRON_REPORT'),
              message: res.message,
            })
          );
          this.displayReportDeliveryDialog = false;
          this.mailActionReloadTableEventEmitter.emit();
        });
      },
    });
  }

  public onShowSchedule(): void {
    window.open(this.reportUrl, '_blank');
    this.displayReportDeliveryDialog = false;
  }

  public showReportDeliveryDialog(): void {
    this.displayReportDeliveryDialog = true;
  }
}

interface ReportDeliveryFormGroup {
  mailLists: FormControl<string[]>;
  mailTitle: FormControl<string>;
  mailNote: FormControl<string>;
}
