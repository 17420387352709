export const AppInsightsEventName = {
  // Dropdowns
  dropdown: 'Dropdown',
  multiSelect: 'MultiSelect',
  // Table Elements
  rowExpansion: 'RowExpansion',
  selectedRow: 'SelectedRow',
  // Links
  button: 'Button',
  dialog: 'Dialog',
  link: 'Link',
  radioButton: 'RadioButton',
  share: 'Share',
  toggleButton: 'ToggleButton',
  // Input
  input: 'Input',
  chips: 'Chips',
  autoComplete: 'AutoComplete',
  // Etc
  data: 'Data',
  // Period Selector
  period: 'PeriodSelector',
  // Tab View
  tabview: 'TabView',
  // Checkbox
  arrayCheckbox: 'ArrayCheckbox',
  checkbox: 'Checkbox',
  inputSwitch: 'InputSwitch',
  tribox: 'TriStateCheckbox',
  // Slider
  slider: 'Slider',
  // Panel
  panel: 'Panel',
  // Calendar
  calendar: 'Calendar',
};
