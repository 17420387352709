// Angular
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Rxjs
import { BehaviorSubject } from 'rxjs';
// Store
import Actions from '@Actions';
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
import Selectors from '@Selectors';
// Caloudi
import { LayoutService } from '@base/service/layout';
import { CaloudiAppInsightsService } from '@core/service';
import { AppUtilModule, RouteEvent, RouteUtilModule } from '@core/service/common';
import { AuthenticationService, LanguageService } from '@core/service/config';
import { ExcludeListService } from '@core/service/http';
import { ComponentStateService } from '@core/service/state';
import { CommonUtil } from '@util';
// Interface
import { DataColumn, PrimeTableColumn } from '@base/model';
import { APPROUTETYPE, APPTYPE, APPUSERTYPE, ChartTypes, EAAPITYPE, MessageSeverity, YNType } from '@core/enum';
import { Breadcrumb, FontawesomeBrands, FontawesomeIcons, OriginState } from '@core/model';
// Third Party
import { FontawesomeService } from '@core/service/provider';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
/**
 * For Basic Component
 *
 * @author Tony.su
 *
 */
@Injectable({ providedIn: 'root' })
export abstract class BaseComponent {

  protected readonly logger: NGXLogger;
  protected readonly router: Router;
  protected readonly route: ActivatedRoute;
  protected readonly store: Store<AppState>;
  protected readonly appUtil: AppUtilModule;
  protected readonly routeUtil: RouteUtilModule;

  // Stores
  protected readonly selectors: typeof Selectors;
  protected readonly actions: typeof Actions;

  // Variables
  protected readonly YNType: typeof YNType;
  protected readonly ChartTypes: typeof ChartTypes;

  // Fontawesome
  public readonly fas: FontawesomeIcons;
  public readonly fab: FontawesomeBrands;

  // Services
  protected readonly appInsightsService: CaloudiAppInsightsService;
  protected readonly authenticationService: AuthenticationService;
  protected readonly componentStateService: ComponentStateService;
  protected readonly excludeListService: ExcludeListService;
  protected readonly languageService: LanguageService;
  protected readonly layoutService: LayoutService;

  // From AppUtil
  public readonly userId: string;
  public readonly companyId: string;
  public readonly appId: APPTYPE;
  public readonly appRoute: APPROUTETYPE;
  public readonly authBreadcrumb: Breadcrumb;
  public readonly authBreadcrumbs: Breadcrumb[];
  public readonly isLogin: boolean;
  public readonly isCSPDisti: boolean;
  public readonly isCSPPartner: boolean;
  public readonly isCSPReseller: boolean;
  public readonly isCMP: boolean;
  public readonly appUserType: APPUSERTYPE;
  public readonly eaAPIType: EAAPITYPE;
  public readonly isLimitFilterByAPIType: boolean;

  // From RouteEvent
  public readonly getRouteEvent: RouteEvent;
  public readonly routeEvent: BehaviorSubject<RouteEvent>;

  // Cookie
  protected readonly cookie: CookieService;

  constructor(public readonly _injector: Injector) {
    // Variables
    this.YNType = YNType;
    this.ChartTypes = ChartTypes;
    this.actions = Actions;
    // Bind all from base
    this.logger = this._injector.get(NGXLogger);
    this.router = this._injector.get(Router);
    this.route = this._injector.get(ActivatedRoute);
    this.store = this._injector.get<Store<AppState>>(Store);
    this.appUtil = this._injector.get(AppUtilModule);
    this.routeUtil = this._injector.get(RouteUtilModule);
    this.appInsightsService = this._injector.get(CaloudiAppInsightsService);
    this.authenticationService = this._injector.get(AuthenticationService);
    this.componentStateService = this._injector.get(ComponentStateService);
    this.excludeListService = this._injector.get(ExcludeListService);
    this.languageService = this._injector.get(LanguageService);
    this.layoutService = this._injector.get(LayoutService);
    this.cookie = this._injector.get(CookieService);

    const fontawesome: FontawesomeService = this._injector.get(FontawesomeService);
    // Bind AppUtil
    this.userId = this.appUtil.userId;
    this.companyId = this.appUtil.companyId;
    this.appId = this.appUtil.appId;
    this.appRoute = this.appUtil.appRoute;
    this.authBreadcrumb = this.appUtil.authBreadcrumb;
    this.authBreadcrumbs = this.appUtil.authBreadcrumbs;
    this.isLogin = this.appUtil.isLogin;
    this.isCSPDisti = this.appUtil.isCSPDisti;
    this.isCSPPartner = this.appUtil.isCSPPartner;
    this.isCSPReseller = this.appUtil.isCSPReseller;
    this.isCMP = this.appUtil.isCMP;
    this.appUserType = this.appUtil.appUserType;
    this.eaAPIType = this.appUtil.eaAPIType;
    this.isLimitFilterByAPIType = this.appUtil.isLimitFilterByAPIType;
    this.routeEvent = this.routeUtil.routeEvent;
    this.getRouteEvent = this.routeUtil.getRouteEvent;
    this.selectors = Selectors;
    // Bind RouteEvent
    this.fas = fontawesome.fas;
    this.fab = fontawesome.fab;
  }

  /**
   * This willw storage with sent key.
   * @param key Local storage key
   * @param initState Initial state when local storage not including state's keys
   * @returns Retrun state from local storage with key
   */
  protected getState<T>(key: string, initState?: T, preserve?: boolean): T {
    return this.componentStateService.mergeState(key, initState, preserve);
  }

  /**
   * This only returns reguired key from state.
   * @param key Local storage key
   * @returns Retrun state from local storage with key
   */
  protected getStateRaw<T>(key: string, preserve?: boolean): T {
    return this.componentStateService.getState(key, preserve);
  }

  /**
   * Get all statement
   */
  protected getStateAll<T>(): T {
    return this.componentStateService.getStateAll<T>();
  }

  /**
   * Get logo path
   * @param color 'color' | 'dark' | 'white'
   * @returns logo path
   */
  public logoPath(color: 'color' | 'dark' | 'white' = 'color'): string {
    const isCaloudi = RegExp(/(Caloudi|localhost)/ig).test(location.hostname);
    return `assets/images/${isCaloudi ? 'caloudi' : '8isoft'}/${isCaloudi ? 'caloudi' : '8isoft'}_logo_${color}.svg`;
    // return `assets/images/8isoft/8isoft_logo_${color}.svg`;
  }

  /**
   * This will set local storage with sent key and state.
   * @param key Local storage key
   * @param state State changes local storage
   * @returns {OriginState} { localStorageKey, stateChanges, previousStorage, currentStorage, fullStorage }
   */
  protected setState<T>(key: string, state: T, perserve?: boolean): OriginState {
    // this.logger.debug('setState:', state);
    return this.componentStateService.setState(key, state, perserve);
  }

  /**
   * Remove specific key form store
   * @param key storage key to remove
   */
  protected removeState(key: string): void {
    this.componentStateService.removeState(key);
  }

  /**
   * Bind Column headers for display
   * @param resColumns Original columns
   * @param displayColumns Custom display columns
   * @returns Binded result
   */
  protected getDataTableColumns<T extends DataColumn, K extends PrimeTableColumn>(
    resColumns: T[],
    displayColumns: K[]
  ): K[] {
    if (!resColumns || !displayColumns) return undefined;
    return displayColumns.map(displayColumn => {
      const column = resColumns.find(item => item.field === displayColumn.field);

      if (!CommonUtil.isUndefined(column)) {
        if (CommonUtil.isUndefined(displayColumn.header)) {
          displayColumn.header = column.header;
        }
      } else if (CommonUtil.isUndefined(displayColumn.header)) displayColumn.header = displayColumn.field;

      return displayColumn;
    });
  }

  /**
   * Empty result Message
   */
  protected toastQueryEmptyResultMessage(): void {
    this.store.dispatch(
      this.actions.GlobalMsgAction({
        severity: MessageSeverity.INFO,
        summary: this.lang('PAGE.DATA_QUERY'),
        message: this.lang('PAGE.DATA_QUERY_EMPTY'),
      })
    );
  }

  /**
   * Translation shorthand
   * @param key Translation keys
   * @param interpolateParams Interpolate params
   * @returns Translated string
   */
  protected lang<T>(key: string[] | string, interpolateParams?: T): string {
    if (Array.isArray(key)) return key.map(k => this.languageService.get<T>(k, interpolateParams).value).join('');
    else return this.languageService.get<T>(key, interpolateParams).value;
  }

  /**
   * Convert payload to number for html
   * @param payload
   * @returns
   */
  protected num(payload: unknown): number {
    try { return Number(payload); } catch (error) { return payload as number; }
  }

  /**
   * Convert payload to string for html
   * @param payload
   * @returns
   */
  protected str(payload: unknown): string {
    try { return String(payload); } catch (error) { return payload as string; }
  }
}

export default BaseComponent;
