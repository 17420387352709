export const Days = {
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
};

export const MonthWeeks = {
  '#1': '1st',
  '#2': '2nd',
  '#3': '3rd',
  '#4': '4th',
  '#5': '5th',
  L: 'Last',
};

export enum Months {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
