// Angular
import { Injectable } from '@angular/core';
// Store
import { Store } from '@ngrx/store';
import { AppState, AuthState } from '@Reducers';
import Selectors from '@Selectors';
// Rxjs
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Interface
import { TRANSLATION_INDEX } from '@core/config';
import { APPROUTETYPE, APPTYPE, APPUSERTYPE, EAAPITYPE } from '@core/enum';
import { Breadcrumb } from '@core/model';

@Injectable({ providedIn: 'root' })
export class AppUtilModule {
  // App State
  private _userId: string;
  public get userId(): string {
    return this._userId;
  }

  private _companyId: string;
  public get companyId(): string {
    return this._companyId;
  }

  private _appId: APPTYPE;
  public get appId(): APPTYPE {
    return this._appId;
  }

  private _appRoute: APPROUTETYPE;
  public get appRoute(): APPROUTETYPE {
    return this._appRoute;
  }

  private _authBreadcrumb: Breadcrumb;
  public get authBreadcrumb(): Breadcrumb {
    return this._authBreadcrumb;
  }

  private _authBreadcrumbs: Breadcrumb[];
  public get authBreadcrumbs(): Breadcrumb[] {
    return this._authBreadcrumbs;
  }

  private _isLogin: boolean;
  public get isLogin(): boolean {
    return this._isLogin;
  }

  // APP Type
  private _isCSPDisti: boolean;
  public get isCSPDisti(): boolean {
    return this._isCSPDisti;
  }

  private _isCSPPartner: boolean;
  public get isCSPPartner(): boolean {
    return this._isCSPPartner;
  }

  private _isCSPReseller: boolean;
  public get isCSPReseller(): boolean {
    return this._isCSPReseller;
  }

  private _isCMP: boolean;
  public get isCMP(): boolean {
    return this._isCMP;
  }

  private _appUserType: APPUSERTYPE;
  public get appUserType(): APPUSERTYPE {
    return this._appUserType;
  }

  // EaAPITypes
  private _eaAPIType: EAAPITYPE;
  public get eaAPIType(): EAAPITYPE {
    return this._eaAPIType;
  }

  private _isLimitFilterByAPIType: boolean;
  public get isLimitFilterByAPIType(): boolean {
    return this._isLimitFilterByAPIType;
  }

  // Util
  private readonly _lang: typeof TRANSLATION_INDEX;
  public get lang(): typeof TRANSLATION_INDEX {
    return this._lang;
  }

  private readonly _state: Subject<[AuthState, Breadcrumb[]]> = new Subject<[AuthState, Breadcrumb[]]>();
  public get localState(): Subject<[AuthState, Breadcrumb[]]> {
    return this._state;
  }

  constructor(private readonly store: Store<AppState>) {
    const deepScan = <T>(payload: T, flattenedPath?: string): T => {
      let result = {} as T;
      Object.keys(payload).forEach(key => {
        const path = flattenedPath ? `${flattenedPath}.${key}` : key;
        Object.assign(result, {
          [key]:
            typeof payload[key] !== 'object'
              ? path.replace(/\w*\.(.*)/, '$1')
              : deepScan(payload[key as keyof T], path),
        });
      });
      return result;
    };
    this._lang = deepScan(TRANSLATION_INDEX);
    // console.debug('lang$', [this.lang$]);

    this.store
      .select(Selectors.auth)
      .pipe(takeUntil(this._state))
      .subscribe({
        next: (auth: AuthState): void => {
          // console.debug('trigger:', [auth, auth?.user?.userProfile?.appId === APPTYPE.CMP]);
          this._userId = auth?.user?.userProfile?.userId;
          this._companyId = auth?.user?.userProfile?.companyName;
          this._appId = auth?.user?.userProfile?.appId;
          this._eaAPIType = auth?.user?.userProfile?.eaapiType;
          this._authBreadcrumb = auth.breadcrumb;
          this._authBreadcrumbs = auth.breadcrumbs;
          this._isLogin = auth.loggedIn;

          this._isCSPDisti = this._appId === APPTYPE.CSPDisti;
          this._isCSPPartner = this._appId === APPTYPE.CSPPartner;
          this._isCSPReseller = this._appId === APPTYPE.CSPReseller;
          this._isCMP = this._appId === APPTYPE.CMP;

          switch (this._appId) {
            case APPTYPE.CSPDisti:
              this._appUserType = APPUSERTYPE.CSPDisti;
              break;
            case APPTYPE.CSPPartner:
              this._appUserType = APPUSERTYPE.CSPPartner;
              break;
            case APPTYPE.CSPReseller:
              this._appUserType = APPUSERTYPE.CSPReseller;
              break;
            default:
              this._appUserType = APPUSERTYPE.Customer;
              break;
          }

          switch (this._appId) {
            case APPTYPE.CSPDisti:
              this._appRoute = APPROUTETYPE.CSPDisti;
              break;
            case APPTYPE.CSPPartner:
              this._appRoute = APPROUTETYPE.CSPPartner;
              break;
            case APPTYPE.CSPReseller:
              this._appRoute = APPROUTETYPE.CSPReseller;
              break;
            default:
              this._appRoute = APPROUTETYPE.CMPCustomer;
              break;
          }
          // console.debug('appid', [this.appId, this.appRoute]);

          // EaAPIType is 'AWD or AzurePlan', it doesn't have Department & Account data.
          // Hidden UI (filter/data no show): Department & Account.
          switch (this._eaAPIType) {
            case EAAPITYPE.AWD:
              this._isLimitFilterByAPIType = true;
              break;
            case EAAPITYPE.AzurePlan:
              this._isLimitFilterByAPIType = true;
              break;
            case EAAPITYPE.CaloudiCSPClient:
              this._isLimitFilterByAPIType = true;
              break;
            default:
              this._isLimitFilterByAPIType = false;
              break;
          }

          if (this._eaAPIType === EAAPITYPE.XCloud) {
            switch (auth.user.userProfile.xCloudAzureAPITypeId as EAAPITYPE) {
              case EAAPITYPE.AWD:
                this._isLimitFilterByAPIType = true;
                break;
              case EAAPITYPE.AzurePlan:
                this._isLimitFilterByAPIType = true;
                break;
              case EAAPITYPE.CaloudiCSPClient:
                this._isLimitFilterByAPIType = true;
                break;
              default:
                this._isLimitFilterByAPIType = false;
                break;
            }
          }
          // console.debug('eaAPIType', [this.eaAPIType, this.isLimitFilterByAPIType]);
        },
        error: err => {
          console.error('app util error', err);
        },
        complete: () => void 0,
      });
  }

  protected getAppProfile(): AppProfile {
    return {
      userId: this.userId,
      appId: this.appId,
      appRoute: this.appRoute,
      authBreadcrumb: this.authBreadcrumb,
      authBreadcrumbs: this.authBreadcrumbs,
      isLogin: this.isLogin,
      isCSPDisti: this.isCSPDisti,
      isCSPPartner: this.isCSPPartner,
      isCSPReseller: this.isCSPReseller,
      isCMP: this.isCMP,
      appUserType: this.appUserType,
      eaAPIType: this.eaAPIType,
      isLimitFilterByAPIType: this.isLimitFilterByAPIType,
    };
  }
}

export declare type AppProfile = {
  userId: string;
  appId: APPTYPE;
  appRoute: APPROUTETYPE;
  authBreadcrumb: Breadcrumb;
  authBreadcrumbs: Breadcrumb[];
  isLogin: boolean;
  isCSPDisti: boolean;
  isCSPPartner: boolean;
  isCSPReseller: boolean;
  isCMP: boolean;
  appUserType: APPUSERTYPE;
  eaAPIType: EAAPITYPE;
  isLimitFilterByAPIType: boolean;
};
