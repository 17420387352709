export const localeKeys = {
  ID: {
    MSID: 'ID.MSID',
    MPNID: 'ID.MPNID',
    TENANTID: 'ID.TENANTID',
    TENANTID_DOMAIN: 'ID.TENANTID_DOMAIN',
  },
  ONBOARD: {
    AD_PROFILE: 'ONBOARD.AD_PROFILE',
    IDENTIFIER: 'ONBOARD.IDENTIFIER',
    VERIFY_IPP: 'ONBOARD.VERIFY_IPP',
    COMPANY_INFO: 'ONBOARD.COMPANY_INFO',
    BILLING_INFO: 'ONBOARD.BILLING_INFO',
    TAX_INFO: 'ONBOARD.TAX_INFO',
    REVIEW: 'ONBOARD.REVIEW',
    SAME_AS_COMPANY: 'ONBOARD.SAME_AS_COMPANY',
    TOKEN: {
      TOKEN: 'ONBOARD.TOKEN.TOKEN',
      ONBOARD: 'ONBOARD.TOKEN.ONBOARD',
      ENCUSER: 'ONBOARD.TOKEN.ENCUSER',
    },
    RETURN_TO_LOGIN: 'ONBOARD.RETURN_TO_LOGIN',
    USRNAME: 'ONBOARD.USRNAME',
    USRMAIL: 'ONBOARD.USRMAIL',
    USRACC: 'ONBOARD.USRACC',
    EA_ENROLLMENT_NUM: 'ONBOARD.EA_ENROLLMENT_NUM',
    PASSWD: 'ONBOARD.PASSWD',
    PASSWD_WARN: 'ONBOARD.PASSWD_WARN',
    GO_PARTNER_CENTER: 'ONBOARD.GO_PARTNER_CENTER',
    LINK_PAL: 'ONBOARD.LINK_PAL',
    GO_EA_PORTAL: 'ONBOARD.GO_EA_PORTAL',
    CONTACT_SALES: 'ONBOARD.CONTACT_SALES',
    OR_CONTACT_SALES: 'ONBOARD.OR_CONTACT_SALES',
    ONBOARD_LINK: 'ONBOARD.ONBOARD_LINK',
    CONTACT_MAIL: 'ONBOARD.CONTACT_MAIL',
    CONTACT_NAME: 'ONBOARD.CONTACT_NAME',
    CONTACT_ADMIN: 'ONBOARD.CONTACT_ADMIN',
    TCA: 'ONBOARD.TCA',
    WIZARD: 'ONBOARD.WIZARD',
    EXPAND_KEY: 'ONBOARD.EXPAND_KEY',
    EA_ACCESS_KEY: 'ONBOARD.EA_ACCESS_KEY',
    EMAIL_RECIPIENT: 'ONBOARD.EMAIL_RECIPIENT',
    INVITE: 'ONBOARD.INVITE',
    SUCCESS: 'ONBOARD.SUCCESS',
    ERROR: {
      ERROR: 'ONBOARD.ERROR.ERROR',
      MESSAGE: 'ONBOARD.ERROR.MESSAGE',
      CODE_TYPE: 'ONBOARD.ERROR.CODE_TYPE',
      CODE: 'ONBOARD.ERROR.CODE',
      NOTFOUND: 'ONBOARD.ERROR.NOTFOUND',
    },
    CMP: {
      STEP_1_1: 'ONBOARD.CMP.STEP_1_1',
      STEP_1_2: 'ONBOARD.CMP.STEP_1_2',
      STEP_2_1: 'ONBOARD.CMP.STEP_2_1',
      STEP_2_2: 'ONBOARD.CMP.STEP_2_2',
      STEP_3: 'ONBOARD.CMP.STEP_3',
      STEP_4: 'ONBOARD.CMP.STEP_4',
      SUCCESS: 'ONBOARD.CMP.SUCCESS',
      SYNCING: 'ONBOARD.CMP.SYNCING',
      STEP1: {
        TITLE: 'ONBOARD.CMP.STEP1.TITLE',
        TEXT1: 'ONBOARD.CMP.STEP1.TEXT1',
        TEXT1_2: 'ONBOARD.CMP.STEP1.TEXT1_2',
        TEXT2: 'ONBOARD.CMP.STEP1.TEXT2',
        TEXT3: 'ONBOARD.CMP.STEP1.TEXT3',
        // TEXT4: 'ONBOARD.CMP.STEP1.// TEXT4',
        TEXT4_1: 'ONBOARD.CMP.STEP1.TEXT4_1',
        TEXT4_2: 'ONBOARD.CMP.STEP1.TEXT4_2',
        TEXT5_1: 'ONBOARD.CMP.STEP1.TEXT5_1',
        TEXT5_2: 'ONBOARD.CMP.STEP1.TEXT5_2',
        TEXT5_3: 'ONBOARD.CMP.STEP1.TEXT5_3',
      },
      STEP3: {
        TITLE1: 'ONBOARD.CMP.STEP3.TITLE1',
        TITLE2: 'ONBOARD.CMP.STEP3.TITLE2',
        // TEXT1: 'ONBOARD.CMP.STEP3.// TEXT1',
        TEXT1: 'ONBOARD.CMP.STEP3.TEXT1',
        TEXT2: 'ONBOARD.CMP.STEP3.TEXT2',
        TEXT3: 'ONBOARD.CMP.STEP3.TEXT3',
      },
      STEP4: {
        TEXT1: 'ONBOARD.CMP.STEP4.TEXT1',
        TEXT2: 'ONBOARD.CMP.STEP4.TEXT2',
        TEXT3: 'ONBOARD.CMP.STEP4.TEXT3',
        TEXT3_1: 'ONBOARD.CMP.STEP4.TEXT3_1',
        // TEXT4: 'ONBOARD.CMP.STEP4.// TEXT4',
        TEXT4: 'ONBOARD.CMP.STEP4.TEXT4',
        TEXT5: 'ONBOARD.CMP.STEP4.TEXT5',
        TEXT6: 'ONBOARD.CMP.STEP4.TEXT6',
        BUTTON: 'ONBOARD.CMP.STEP4.BUTTON',
        CLOSE: 'ONBOARD.CMP.STEP4.CLOSE',
      },
      ROLE: {
        AZURE_GADM: 'ONBOARD.CMP.ROLE.AZURE_GADM',
        GADM: 'ONBOARD.CMP.ROLE.GADM',
        SALES_AGENT: 'ONBOARD.CMP.ROLE.SALES_AGENT',
        CONTRIBUTOR: 'ONBOARD.CMP.ROLE.CONTRIBUTOR',
      },
    },
    CSP: {
      STEP_1: 'ONBOARD.CSP.STEP_1',
      STEP_2: 'ONBOARD.CSP.STEP_2',
      STEP_3: 'ONBOARD.CSP.STEP_3',
      STEP_4: 'ONBOARD.CSP.STEP_4',
      STEP_5: 'ONBOARD.CSP.STEP_5',
      STEP1: {
        TITLE: 'ONBOARD.CSP.STEP1.TITLE',
        TEXT: 'ONBOARD.CSP.STEP1.TEXT',
      },
      STEP2: {
        ARTICLE_TITLE_EXIST: 'ONBOARD.CSP.STEP2.ARTICLE_TITLE_EXIST',
        ARTICLE_TITLE_NEW: 'ONBOARD.CSP.STEP2.ARTICLE_TITLE_NEW',
        ARTICLE: {
          TEXT1: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT1',
          TEXT2: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT2',
          TEXT3: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT3',
          TEXT4: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT4',
          TEXT5: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT5',
          TEXT6: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT6',
          TEXT7: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT7',
          TEXT8: 'ONBOARD.CSP.STEP2.ARTICLE.TEXT8',
        },
      },
    },
    OTP: {
      SEND: 'ONBOARD.OTP.SEND',
      RESEND: 'ONBOARD.OTP.RESEND',
      SENT: 'ONBOARD.OTP.SENT',
      ENTER_DIGIT_CODE: 'ONBOARD.OTP.ENTER_DIGIT_CODE',
      VERIFY_CODE: 'ONBOARD.OTP.VERIFY_CODE',
    },
    PROFILE: {
      COUNTRY: 'ONBOARD.PROFILE.COUNTRY',
      COMPANY_NAME: 'ONBOARD.PROFILE.COMPANY_NAME',
      DOMAIN: 'ONBOARD.PROFILE.DOMAIN',
      TENANTDOMAIN: 'ONBOARD.PROFILE.TENANTDOMAIN',
      FNAME: 'ONBOARD.PROFILE.FNAME',
      MNAME: 'ONBOARD.PROFILE.MNAME',
      LNAME: 'ONBOARD.PROFILE.LNAME',
      ADDRESS: 'ONBOARD.PROFILE.ADDRESS',
      CITY: 'ONBOARD.PROFILE.CITY',
      STATE: 'ONBOARD.PROFILE.STATE',
      POSTCODE: 'ONBOARD.PROFILE.POSTCODE',
      EMAIL: 'ONBOARD.PROFILE.EMAIL',
      PHONE: 'ONBOARD.PROFILE.PHONE',
      TAX_ID: 'ONBOARD.PROFILE.TAX_ID',
      SUBSCRIPTION: 'ONBOARD.PROFILE.SUBSCRIPTION',
      OFFER: 'ONBOARD.PROFILE.OFFER',
      PLAN: 'ONBOARD.PROFILE.PLAN',
      STARTDATE: 'ONBOARD.PROFILE.STARTDATE',
      BILLING_TERMS: 'ONBOARD.PROFILE.BILLING_TERMS',
      PURCHASER_EMAIL: 'ONBOARD.PROFILE.PURCHASER_EMAIL',
      BENEFIT_EMAIL: 'ONBOARD.PROFILE.BENEFIT_EMAIL',
    },
    TICKET: {
      INTRO_TEXT: 'ONBOARD.TICKET.INTRO_TEXT',
      ATTACHMENT_TEXT: 'ONBOARD.TICKET.ATTACHMENT_TEXT',
      NAME_PLACEHOLDER: 'ONBOARD.TICKET.NAME_PLACEHOLDER',
      EMAIL_PLACEHOLDER: 'ONBOARD.TICKET.EMAIL_PLACEHOLDER',
      // ASK_US_FOR_GETTING_HELP: 'ONBOARD.TICKET.// ASK_US_FOR_GETTING_HELP',
      ASK_US_FOR_GETTING_HELP: 'ONBOARD.TICKET.ASK_US_FOR_GETTING_HELP',
      YOUR_NAME: 'ONBOARD.TICKET.YOUR_NAME',
      YOUR_EMAIL: 'ONBOARD.TICKET.YOUR_EMAIL',
      CC_EMAILS: 'ONBOARD.TICKET.CC_EMAILS',
      PHONE: 'ONBOARD.TICKET.PHONE',
      DESCRIPTION: 'ONBOARD.TICKET.DESCRIPTION',
      ATTACHMENT: 'ONBOARD.TICKET.ATTACHMENT',
      FAQ: 'ONBOARD.TICKET.FAQ',
    },
  },
};
