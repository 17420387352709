import { SVGCrowbarPNGOptions } from '@base/model';
import getSource from './input-processor';
import * as png from './png';
import * as svg from './svg';
import { getFilename } from './util';

const downloadSvg = (svgElement: SVGElement, filename?: string, options?: SVGCrowbarPNGOptions): void => {
  return void svg.downloadSvg(getSource(svgElement, options), filename || getFilename(svgElement));
};

const downloadPng = (svgElement: SVGElement, filename?: string, options?: SVGCrowbarPNGOptions): void => {
  return void png.downloadPng(
    getSource(svgElement, options),
    filename || getFilename(svgElement),
    !options ? void 0 : options.downloadPNGOptions
  );
};

export { downloadSvg, downloadPng };
