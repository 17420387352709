
/**
 * Utils for array type.
 */
export default class CollectionUtil {

  /**
   * Check input data is array or not, and has data.
   * @param payload any types of data
   * @returns boolean
   */
  public static isNotEmpty<T>(payload: T): boolean {
    return Array.isArray(payload) && payload.length > 0;
  }

  /**
   * Check data is included in the enum type.
   * @param enumType enum List
   * @param payload data to check
   * @returns boolean
   */
  public static isIncludeInEnum<T>(enumType: T, payload: string): boolean {
    if (payload?.length <= 0) return false;
    return Object.values(enumType).some((val: number | string) => val === payload);
  }

  /**
   * Check the input key(s) is included in object.
   * @param payload object to test
   * @param keys keys of object
   * @returns boolean
   */
  public static isKeyIncluded<T>(payload: T, keys: string[] | string): boolean {
    try {
      return Object.keys(payload).some(key => (Array.isArray(keys) ? keys.some(k => k === key) : key === keys));
    } catch (error) {
      return false;
    }
  }

  /**
   * Check the data is iterable or not.
   * @param payload object to test
   * @returns boolean
   */
  public static isIterable<T>(payload: T): boolean {
    // checks for null and undefined
    return payload != null && typeof payload[Symbol.iterator] === 'function';
  }

  /**
   * Deduplicate array of data.
   * @param payload any type of data array
   * @param options for object filtering
   * @returns deduplicated array
   */
  public static deduplicateArray<T>(payload: T[], options?: DeduplicateArrayOptions): T[] {
    try {
      if (!this.isIterable(payload)) return payload;
      if (options?.key?.length > 0) return payload.filter((item, pos, self) =>
        self.map(obj => obj[options.key as keyof T]).indexOf(item[options.key as keyof T]) === pos);
      return [...new Set(payload)];
    } catch (error) {
      return payload;
    }
    // return payload.filter((item, pos, self) => self.indexOf(item) === pos)
  }
}

interface DeduplicateArrayOptions {
  key?: string;
  sort?: boolean;
}
