import { Component, EventEmitter, Injector, Input, OnDestroy, Output } from '@angular/core';
import { Event, ResolveStart, RouterEvent } from '@angular/router';
// Rxjs
import { asyncScheduler, Subscription } from 'rxjs';
import { filter, map, throttleTime } from 'rxjs/operators';
// Ngx
import { NgxSpinnerService, Spinner } from 'ngx-spinner';
// Caloudi
import { BaseComponent } from '@base';
// Store
import { BlockUIState } from '@Reducers';

@Component({
  selector: 'spinner, [spinner]',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.sass'],
})
export class SpinnerComponent extends BaseComponent implements OnDestroy {

  @Input('name') public spinnerName: string = 'spinner';
  @Input('option') public spinnerOption: Spinner = {
    type: 'ball-spin-clockwise',
    fullScreen: false,
    // ZIndex: 2047
  };

  public loading: boolean;
  @Output('loading') public isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly loggerActive: boolean;
  private readonly spinnerDisable: boolean;
  // private readonly spinnerDisable: boolean = location.host.split(':')?.[0] === 'localhost';
  private readonly spinnerService: NgxSpinnerService;

  private readonly routeSub$: Subscription;
  private readonly storeSub$: Subscription;

  constructor(public readonly injector: Injector) {
    super(injector);
    this.spinnerService = this._injector.get(NgxSpinnerService);

    this.routeSub$ = this.router.events
      .pipe(filter((event: Event | RouterEvent) => event instanceof ResolveStart && event.url !== '/reload'))
      .subscribe((_event: ResolveStart): void => {
        this.spinnerService.hide(this.spinnerName);
        this.layoutService.spinnerLoading = false;
      });

    this.storeSub$ = this.store
      .select<BlockUIState>(this.selectors.getBlockUI)
      .pipe(
        throttleTime(0, asyncScheduler, { leading: false, trailing: true }),
        map(v => {
          this.loggerActive && this.logger.debug('count', v);
          return v;
        })
      )
      .subscribe({
        next: res => {
          this.layoutService.spinnerLoading = res.count > 0;
          this.loading = res.count > 0;
          this.isLoading.emit(res.count > 0);

          if (!this.layoutService.config.blockLoading) return void this.spinnerService.hide(this.spinnerName);
          if (res.count <= 0 || this.spinnerDisable) this.spinnerService.hide(this.spinnerName);
          else this.spinnerService.show(this.spinnerName);
        },
        error: e => {
          this.logger.error(e);
          this.spinnerService.hide(this.spinnerName);
        },
      });
  }

  public ngOnDestroy(): void {
    this.routeSub$?.unsubscribe();
    this.storeSub$?.unsubscribe();
  }
}
