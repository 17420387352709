/**
 * Basic Date converters.
 */
export default class DateUtil {

  /**
   * Convert Date to time epoch number.
   * @param currentTime Date
   * @returns time epoch number
   */
  public static getTimeEpoch(currentTime: Date): number {
    return Math.floor(currentTime.getTime() / 1000);
  }

  /**
   * Convert Date to ISO format.
   * @param currentTime Date
   * @returns ISO format time
   */
  public static getTimeIso(currentTime: Date): string {
    return currentTime.toISOString();
  }
}
