import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as FAS from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'caloudi-content-mask',
  templateUrl: './caloudi-content-mask.component.html',
  styleUrls: ['./caloudi-content-mask.component.sass'],
})
export class CaloudiContentMaskComponent implements OnChanges {
  @Input('content') public content: string;
  @Input('reverseMask') public reverseMask: boolean;
  @Input('unmaskLengh') public unmaskLengh: number = 3;

  @Input('toggleMask') public toggleMask: boolean;
  @Input('contentMask') public contentMask: boolean = true;

  @HostBinding('class.flex-1') public readonly klass = true;

  public fas: typeof FAS = FAS;
  public displayContent: string;
  private contentMasked: string;

  constructor() { }

  public ngOnChanges(_changes: SimpleChanges): void {
    this.contentMasking();
  }

  private contentMasking(): void {
    this.displayContent = this.content;
    if (!this.content.includes('/')) {
      if (this.content.length > this.unmaskLengh) {
        let splitedContent = this.content;
        splitedContent = splitedContent.slice(0, this.unmaskLengh);
        this.contentMasked = splitedContent;
        this.displayContent = this.contentMasked;
      }
    } else {
      if (this.content.length > this.unmaskLengh) {
        let splitedContent = this.content.split('/');
        this.reverseMask
          ? (splitedContent = splitedContent.slice(0, this.unmaskLengh))
          : (splitedContent = splitedContent.slice(splitedContent.length - this.unmaskLengh, splitedContent.length));
        this.contentMasked = splitedContent.join('/');
        this.displayContent = this.contentMasked;
      }
    }
  }

  public maskToggle(): void {
    this.toggleMask ? (this.contentMask = !this.contentMask) : (this.contentMask = false);
    this.contentMask ? (this.displayContent = this.contentMasked) : (this.displayContent = this.content);
  }
}
