<prime-dialog *ngIf="displayReportDeliveryDialog" [(visible)]="displayReportDeliveryDialog">
  <ng-container header>
    {{ 'ACTION.MAIL_SCHEDULE' | translate }}:
    <span class="text-primary">{{ reportName }}</span>
    <i *ngIf="editMode === true" class="pi pi-external-link icon-pointer" (click)="onShowSchedule()"></i>
  </ng-container>

  <div class="cron_wrap">
    <section class="cron_section">
      <form (ngSubmit)="onReportDeliveryFormSubmit()" [formGroup]="reportDeliveryForm">
        <ul>
          <li>
            <label for="mailLists">{{ 'LABEL.EMAIL_RECIPIENTS' | translate }}:</label>
            <div>
              <p-chips
                id="mailLists"
                separator=","
                [addOnTab]="true"
                [addOnBlur]="true"
                formControlName="mailLists"
                promptLabel="Mail lists"
                required>
              </p-chips>
            </div>
            <section
              *ngIf="
                ((submitted && f.mailLists.invalid) || f.mailLists.dirty || f.mailLists.touched) && f.mailLists.errors;
                else unInit
              ">
              <p-messages
                severity="error"
                *ngIf="f.mailLists.errors.required"
                text="mail address is required"></p-messages>
              <p-messages
                severity="error"
                *ngIf="f.mailLists.errors.invalidEmail"
                text="mail format invalid"></p-messages>
            </section>
            <ng-template #unInit>
              <section></section>
            </ng-template>
            <span>({{ 'HINT.EMAIL_RECIPIENTS_HINT' | translate }})</span>
          </li>
          <li class="p-float-label">
            <input
              type="text"
              pInputText
              id="mailTitle"
              formControlName="mailTitle"
              promptLabel="Title"
              [logInput]="{ label: 'Report Title', payload: f.mailTitle.value }" />
            <label for="mailTitle">{{ 'REPORT.TITLE' | translate }}:</label>
          </li>
          <li class="p-float-label">
            <input
              type="text"
              pInputText
              id="mailNote"
              formControlName="mailNote"
              promptLabel="Note"
              [logInput]="{ label: 'Report Note', payload: f.mailNote.value }" />
            <label for="mailNote">{{ 'REPORT.NOTE' | translate }}:</label>
          </li>
        </ul>
        <div class="btn-group">
          <button
            pButton
            aria-label="Button"
            [label]="'ACTION.SEND_NOW' | translate"
            [disabled]="reportDeliveryForm.invalid"
            [logButton]="{ label: 'Send Now', payload: reportDeliveryForm.value }"></button>
        </div>
      </form>
    </section>

    <section class="cron_section">
      <div>
        <label for="mailLists">{{ 'LABEL.SCHEDULED_REPORT' | translate }}:</label>
        <ng-container *ngIf="editMode === true">
          <div>
            <p-inputSwitch [(ngModel)]="isActiveButton" [logSwitch]="{ label: 'Active', payload: isActiveButton }">
            </p-inputSwitch>
            <span *ngIf="isActiveButton" class="text-primary">{{ 'LABEL.ACTIVE' | translate }}</span>
            <span *ngIf="!isActiveButton" class="text-danger">{{ 'LABEL.INACTIVE' | translate }}</span>
          </div>
        </ng-container>
      </div>
      <caloudi-cron-editor [formControl]="cronForm" [options]="cronOptions" [editMode]="editMode">
      </caloudi-cron-editor>
      <div>
        <p><span></span> {{ cronLangConverter }}</p>
        <p><span>Cron Expression:</span> {{ cronForm.value }}</p>
      </div>
      <div class="btn-group">
        <button
          pButton
          [label]="'ACTION.SAVE' | translate"
          [disabled]="reportDeliveryForm.invalid"
          (click)="onCronSubmit()"
          [logButton]="{ label: 'Save', payload: cronForm.value }"></button>
        <button
          pButton
          [label]="'ACTION.DELETE' | translate"
          *ngIf="editMode"
          (click)="onDeleteSchedule()"
          [logDialog]="{ label: 'Delete' }"></button>
      </div>
    </section>
  </div>
</prime-dialog>

<p-confirmDialog #action [header]="'ACTION.CONFIRMATION' | translate" icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="footer">
    <button
      pButton
      aria-label="Button"
      type="button"
      [faIcon]="fas.faCheck"
      [label]="'COMMON.YES' | translate"
      (click)="action.accept()"
      [logButton]="'Delete - Yes'"></button>
    <button
      pButton
      aria-label="Button"
      type="button"
      [faIcon]="fas.faXmark"
      [label]="'COMMON.NO' | translate"
      (click)="action.reject()"
      [logButton]="'Delete - No'"></button>
  </ng-template>
</p-confirmDialog>
