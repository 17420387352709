import { createAction, props } from '@ngrx/store';
import { ActionCreatorProp, ActionCreatorType } from '.';
import { LayoutConfig } from '../reducers';

// The Action interface
export enum LayoutActionTypes {
  Change_Block_Loading = '[Change Block Loading] Action',
  Change_Inline_Breadcrumb = '[Change Inline Breadcrumb] Action',
  Change_Tab_Enrollment = '[Change Tab Enrollment] Action',
  Change_Layout = '[Change Layout] Action',
  Change_Scale = '[Change Scale] Action',
  Change_Input_Style = '[Change Input Style] Action',
  Change_Color_Scheme = '[Change Color Scheme] Action',
  Change_Menu_Mode = '[Change Menu Mode] Action',
  Change_Menu_Color = '[Change Menu Color] Action',
  Change_Menu_Profile_Position = '[Change Menu Profile Position] Action',
  Change_Topbar_Color = '[Change Topbar Color] Action',
  Change_Component_Color = '[Change Component Color] Action',
  Change_Language = '[Change Language] Action',
  Change_Ripple = '[Change Ripple] Action',
  Layout_State = '[Layout State] Action',
}

export type LayoutPayload = {
  Change_Block_Loading: { blockLoading: LayoutConfig['blockLoading']; };
  Change_Inline_Breadcrumb: { inlineBreadcrumb: LayoutConfig['inlineBreadcrumb']; };
  Change_Tab_Enrollment: { tabEnrollment: LayoutConfig['tabEnrollment']; };
  Change_Layout: LayoutConfig;
  Layout_State: LayoutConfig;
  Change_Scale: { scale: LayoutConfig['scale']; };
  Change_Input_Style: { input: LayoutConfig['inputStyle']; };
  Change_Color_Scheme: { colorScheme: LayoutConfig['colorScheme']; };
  Change_Menu_Mode: { menuMode: LayoutConfig['menuMode']; };
  Change_Menu_Color: { menuTheme: LayoutConfig['menuTheme']; };
  Change_Menu_Profile_Position: { menuProfilePosition: LayoutConfig['menuProfilePosition']; },
  Change_Topbar_Color: { topbarColor: LayoutConfig['topbarTheme']; };
  Change_Component_Color: { componentColor: LayoutConfig['componentTheme']; };
  Change_Language: { lang: LayoutConfig['lang']; };
  Change_Ripple: { ripple: LayoutConfig['ripple']; };
};

export const ChangeBlockLoadingAction: ActionCreatorProp<LayoutPayload['Change_Block_Loading']> = createAction(
  LayoutActionTypes.Change_Block_Loading,
  props()
);

export const ChangeInlineBreadcrumbAction: ActionCreatorProp<LayoutPayload['Change_Inline_Breadcrumb']> = createAction(
  LayoutActionTypes.Change_Inline_Breadcrumb,
  props()
);

export const ChangeTabEnrollmentAction: ActionCreatorProp<LayoutPayload['Change_Tab_Enrollment']> = createAction(
  LayoutActionTypes.Change_Tab_Enrollment,
  props()
);

export const ChangeLayoutAction: ActionCreatorProp<LayoutPayload['Change_Layout']> = createAction(
  LayoutActionTypes.Change_Layout,
  props()
);

export const ChangeScaleAction: ActionCreatorProp<LayoutPayload['Change_Scale']> = createAction(
  LayoutActionTypes.Change_Scale,
  props()
);

export const ChangeInputStyleAction: ActionCreatorProp<LayoutPayload['Change_Input_Style']> = createAction(
  LayoutActionTypes.Change_Input_Style,
  props()
);

export const ChangeColorSchemeAction: ActionCreatorProp<LayoutPayload['Change_Color_Scheme']> = createAction(
  LayoutActionTypes.Change_Color_Scheme,
  props()
);

export const ChangeMenuModeAction: ActionCreatorProp<LayoutPayload['Change_Menu_Mode']> = createAction(
  LayoutActionTypes.Change_Menu_Mode,
  props()
);

export const ChangeMenuColorAction: ActionCreatorProp<LayoutPayload['Change_Menu_Color']> = createAction(
  LayoutActionTypes.Change_Menu_Color,
  props()
);

export const ChangeMnuProfilePositionAction: ActionCreatorProp<LayoutPayload['Change_Menu_Profile_Position']> = createAction(
  LayoutActionTypes.Change_Menu_Profile_Position,
  props()
);

export const ChangeTopbarColorAction: ActionCreatorProp<LayoutPayload['Change_Topbar_Color']> = createAction(
  LayoutActionTypes.Change_Topbar_Color,
  props()
);

export const ChangeComponentColorAction: ActionCreatorProp<LayoutPayload['Change_Component_Color']> = createAction(
  LayoutActionTypes.Change_Component_Color,
  props()
);

export const ChangeLanguageAction: ActionCreatorProp<LayoutPayload['Change_Language']> = createAction(
  LayoutActionTypes.Change_Language,
  props()
);

export const ChangeRippleAction: ActionCreatorProp<LayoutPayload['Change_Ripple']> = createAction(
  LayoutActionTypes.Change_Ripple,
  props()
);

export const LayoutConfigAction: ActionCreatorType = createAction(LayoutActionTypes.Layout_State);

export const LayoutActions = {
  ChangeBlockLoadingAction,
  ChangeColorSchemeAction,
  ChangeComponentColorAction,
  ChangeInlineBreadcrumbAction,
  ChangeInputStyleAction,
  ChangeLanguageAction,
  ChangeLayoutAction,
  ChangeMenuColorAction,
  ChangeMenuModeAction,
  ChangeMnuProfilePositionAction,
  ChangeRippleAction,
  ChangeScaleAction,
  ChangeTabEnrollmentAction,
  ChangeTopbarColorAction,
  LayoutConfigAction,
};
