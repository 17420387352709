import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';

/**
 * Data uninitialized div, can use class and style
 * @interface class Custom class list
 *
 * @member f0: flex 0
 * @member w100: width 100%
 * @member nh100: min-height 100%
 */
@Component({
  selector: 'uninitialized',
  templateUrl: './uninitialized.component.html',
  styleUrls: ['./uninitialized.component.sass'],
  animations: [
    trigger('transAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0s cubic-bezier(0,0,.2,1)', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0s cubic-bezier(0,0,.2,1)', style({ opacity: 0 }))]),
    ]),
  ],
})
export class UninitializedComponent {
  @HostBinding('class') public readonly klass: string = 'uninitialized_section';

  constructor() {}
  // @HostBinding('@transAnimation') get transAnimation(): boolean {
  //   return false;
  // }
}
