import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    VPN_PROFILE: {
      DOWNLOADS: '下載 VPN 設定檔',
      NAME: 'VPN 設定檔名稱',
      DNS_SERVERS: 'DNS 伺服器位址',
      TITLE: {
        DELETE: '刪除 VPN 設定檔',
        EDIT: '編輯 VPN 設定檔',
        NEW: '新增 VPN 設定檔',
        TEST: '連線測試',
      },
      ADD_SUCCESS: '設定檔新增成功',
      EDIT_SUCCESS: '設定檔更新成功',
      DELETE_SUCCESS: '設定檔刪除成功',
      ACTION: {
        TEST: '測試連線',
        EDIT: '編輯 VPN 設定檔',
        DELETE: '刪除 VPN 設定檔',
      },
      TEST: {
        START: '開始測試',
        TESTING: '連線測試中',
        STANDBY: '待命中',
      },
      MESSAGE: {
        DELETE: '此操作無法復原。\n您確定要刪除此 VPN 設定檔嗎？',
      },
    },
  },
};
