// Angular
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
// Primeng
// Caloudi
import { BaseComponent } from '@base';
import { ShareLinkService } from '@base/service';
// Interface
import { ShareLinkCreate } from '@base/model';
import { MessageSeverity } from '@core/enum';

@Component({
  selector: 'share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.sass'],
})
export class ShareLinkDialogComponent extends BaseComponent implements OnInit {
  @Input('shareLinkCreate') public shareLinkCreate: ShareLinkCreate<unknown>;
  @Input('visible') public displayDialog: boolean;
  @Output('visibleChange') private readonly displayDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public shareLink: string;

  constructor(public readonly injector: Injector, private readonly shareLinkService: ShareLinkService) {
    super(injector);
  }

  public ngOnInit(): void {
    this.shareLinkService.createShareLink(this.shareLinkCreate).subscribe(res => {
      this.shareLink = res;
    });
  }

  public copyShareLink(value: string): void {
    if (!value) return;
    this.store.dispatch(
      this.actions.GlobalMsgAction({
        severity: MessageSeverity.SUCCESS,
        summary: 'Copied',
      })
    );
    navigator.clipboard.writeText(value);
  }

  public visibleChange(e: boolean): void {
    this.displayDialog = false;
    this.displayDialogChange.emit(e);
  }
}
