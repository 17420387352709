// Angular
import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
// Rxjs
import { Observable, of } from 'rxjs';

import { PageScrollService } from 'ngx-page-scroll-core';

/**
 * Service to handle links generated through markdown parsing.
 * The following `RouterModule` configuration is required to enabled anchors
 * to be scrolled to when URL has a fragment via the Angular router:
 * ```
 * RouterModule.forRoot(routes, {
 * anchorScrolling: 'enabled',           // scrolls to the anchor element when the URL has a fragment
 * scrollOffset: [0, 64],                // scroll offset when scrolling to an element (optional)
 * scrollPositionRestoration: 'enabled', // restores the previous scroll position on backward navigation
 * })
 * ```
 * _Refer to [Angular Router documentation](https://angular.io/api/router/ExtraOptions#anchorScrolling) for more details._
 */
@Injectable({ providedIn: 'root' })
export class CMPDocsAnchorService {

  private readonly logActive: boolean = !1;

  constructor(
    private readonly locationStrategy: LocationStrategy,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly pageScrollService: PageScrollService
  ) { }

  /**
   * Intercept clicks on `HTMLAnchorElement` to use `Router.navigate()`
   * when `href` is an internal URL not handled by `routerLink` directive.
   * @param event The event to evaluated for link click.
   */
  public interceptClick(event: PointerEvent): void {
    // const clickRoot: HTMLElement[] = [...(<{ path: HTMLElement[]; }>(<unknown>event)).path].reverse();
    const clickRoot = <HTMLElement[]>[...event.composedPath()].reverse();
    const element = event.target;
    this.logActive && console.debug('touch:', [clickRoot, element]);

    const pathFinding =
      clickRoot.some(node => node.nodeName === 'CMP-DOCS') &&
      clickRoot.some(node => node.id === 'tocContent' || node.id === 'pocContent' || node.id === 'mainContent');
    const tocContentFinding =
      clickRoot.some(node => node.nodeName === 'CMP-DOCS') && clickRoot.some(node => node.id === 'tocContent');
    this.logActive && console.debug('mouse:', [event, [...clickRoot], event?.target, pathFinding, tocContentFinding]);

    if (!pathFinding) return void event.preventDefault();
    if (!(element instanceof HTMLAnchorElement)) return;
    const pathName = element.pathname === '/' ? element.hash : element.pathname;
    this.logActive && console.debug([element, pathName, this.isExternalUrl(element.getAttribute('href')), this.isRouterLink(element)]);
    if (!pathName || this.isExternalUrl(element.getAttribute('href')) || this.isRouterLink(element)) return;
    this.navigate(pathName);
    event.preventDefault();
  }

  /**
   * Navigate to URL using angular `Router`.
   * @param url Destination path to navigate to.
   */
  public navigate(url: string): void {
    const urlTree = this.getUrlTree(url);
    this.logActive && console.debug('navigate', [url, urlTree]);
    this.router.navigated = false;
    if (urlTree.fragment) this.pageScrollService.scroll({
      document: document,
      scrollTarget: url,
      scrollOffset: 150,
      duration: .4,
    });
    else this.router.navigateByUrl(urlTree, { replaceUrl: !1 });
  }

  /**
   * Transform a relative URL to its absolute representation according to current router state.
   * @param url Relative URL path.
   * @return Absolute URL based on the current route.
   */
  public normalizeExternalUrl(url: string): string {
    if (this.isExternalUrl(url)) {
      return url;
    }
    const urlTree = this.getUrlTree(url);
    const serializedUrl = this.router.serializeUrl(urlTree);
    return this.locationStrategy.prepareExternalUrl(serializedUrl);
  }

  /**
   * Scroll view to the anchor corresponding to current route fragment.
   */
  public scrollToAnchor(): Observable<number> {
    const url = this.router.parseUrl(this.router.url);
    // console.log('scrollToAnchor:', [url, url.fragment]);
    let result = 0;
    if (url.fragment) {
      const instance = this.pageScrollService.create({
        document: document,
        scrollTarget: '#' + url.fragment,
        scrollOffset: 150,
      });
      this.logActive && console.debug('scrollToAnchor:', [instance]);
      this.pageScrollService.start(instance);
      return of(instance.targetScrollPosition);
    }
    return of(result);
  }

  private getUrlTree(url: string): UrlTree {
    const urlPath = this.stripFragment(url) || this.stripFragment(this.router.url);

    const urlFragment = this.router.parseUrl(url).fragment;
    return this.router.createUrlTree([urlPath], { relativeTo: this.route, fragment: urlFragment });
  }

  private isExternalUrl(url: string): boolean {
    return /^(?!http(s?):\/\/).+$/.exec(url) === null;
  }

  private isRouterLink(element: HTMLAnchorElement): boolean {
    return element.getAttributeNames().some(n => n.startsWith('_ngcontent'));
  }

  private stripFragment(url: string): string {
    return /[^#]*/.exec(url)[0];
  }
}
