export enum LanguageType {
  CHT = 'zh-TW',
  CHS = 'zh-CN',
  ENG = 'en-US',
}

export enum DocumentLangType {
  CHT = 'zh-Hant',
  CHS = 'zh-Hans',
  ENG = 'en-US',
}
