import { AuthActions } from '@Actions';
import { createReducer, on } from '@ngrx/store';
import { JSONUtil } from '@util';
// Interface
import { AuthUser, Breadcrumb, JWT } from '@core/model';

/** Defining the state shape */
export interface AuthState {
  loggedIn: boolean;
  user?: AuthUser;
  expiresAt?: number;
  breadcrumbs?: Breadcrumb[];
  breadcrumb?: Breadcrumb;
}

/** Setting the initial state */
export const initialAuthState: AuthState = {
  loggedIn: false,
};

/** Creating the reducer function */
export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.LoginAction, (state, { authUser }): AuthState => {
    const authUsr = {} as AuthUser;
    Object.keys(authUser).forEach(key => {
      if (key !== 'ssoRouterLink') Object.assign(authUsr, { [key]: authUser[key] as Record<string, unknown> });
    });

    const { exp }: JWT = JSONUtil.parse<JWT>(atob(authUsr.accessToken.split('.')[1]).toString());
    // console.debug('logging reducer:', [state, action, authUser, jwt]);
    return {
      ...state,
      loggedIn: true,
      user: authUsr,
      expiresAt: exp,
    };
  }),
  on(AuthActions.AutoLoginAction, (state, { authUser }): AuthState => {
    const authUsr = {} as AuthUser;
    Object.keys(authUser).forEach(key => {
      if (key !== 'ssoRouterLink') Object.assign(authUsr, { [key]: authUser[key] as Record<string, unknown> });
    });
    const { exp }: JWT = JSONUtil.parse<JWT>(atob(authUsr.accessToken.split('.')[1]).toString());
    return {
      ...state,
      loggedIn: true,
      user: authUsr,
      expiresAt: exp,
    };
  }),
  on(AuthActions.RefreshTokenAction, (state, { accessToken }): AuthState => {
    const { exp }: JWT = JSONUtil.parse<JWT>(atob(accessToken.split('.')[1]).toString());
    const cloneUser = { ...state.user };
    cloneUser.accessToken = accessToken;
    return {
      ...state,
      loggedIn: true,
      user: cloneUser,
      expiresAt: exp,
    };
  }),
  on(AuthActions.LogoutAction, (): AuthState => ({ loggedIn: false })),
  on(AuthActions.UpdateUserAction, (state, { authUser }): AuthState => {
    const authUsr = {} as AuthUser;
    Object.keys(authUser).forEach(key => {
      if (key !== 'ssoRouterLink') Object.assign(authUsr, { [key]: authUser[key] as Record<string, unknown> });
    });
    return {
      ...state,
      user: authUsr,
    };
  }),
  on(
    AuthActions.LoadBreadcrumbsAction,
    (state, { breadcrumbs }): AuthState => ({
      ...state,
      breadcrumbs: breadcrumbs,
    })
  ),
  on(
    AuthActions.SetBreadcrumbAction,
    (state, { breadcrumb }): AuthState => ({
      ...state,
      breadcrumb: breadcrumb,
    })
  )
);
