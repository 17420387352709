// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Caloudi
import { CronReport, CronReportCreate, CronReportDeleteResult, CronReportQuery, CronReportUpdate, CronReportUpdateResult, DataTable, EmailReport, EmailReportResult } from '@base/model';
import environment from '@env';

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private readonly http: HttpClient) {}

  public emailReport(emailReport: EmailReport): Observable<EmailReportResult> {
    return this.http.post<EmailReportResult>(`${environment.apiServer.url}/api/report/emailReport`, emailReport);
  }

  public queryCronReport(cronReportQuery: CronReportQuery): Observable<DataTable<CronReport>> {
    return this.http.post<DataTable<CronReport>>(
      `${environment.apiServer.url}/api/report/cronReport/query`,
      cronReportQuery
    );
  }

  public getCronReport(cronReportId: string): Observable<CronReportQuery> {
    return this.http.get<CronReportQuery>(`${environment.apiServer.url}/api/report/cronReport/${cronReportId}`);
  }

  public updateCronReport(cronReportUpdate: CronReportUpdate): Observable<CronReportUpdateResult> {
    return this.http.post<CronReportUpdateResult>(
      `${environment.apiServer.url}/api/report/cronReport`,
      cronReportUpdate
    );
  }

  public deleteCronReport(cronReportId: string): Observable<CronReportDeleteResult> {
    return this.http.delete<CronReportDeleteResult>(
      `${environment.apiServer.url}/api/report/cronReport/${cronReportId}`
    );
  }

  public createCronReport(cronReportCreate: CronReportCreate): Observable<CronReportDeleteResult> {
    return this.http.put<CronReportDeleteResult>(
      `${environment.apiServer.url}/api/report/cronReport`,
      cronReportCreate
    );
  }
}
