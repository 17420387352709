import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    ID: {
      MSID: 'Microsoft ID',
      MPNID: 'MPN ID',
      TENANTID: '租戶 ID',
      TENANTID_DOMAIN: '租戶 ID/網域',
    },
    ONBOARD: {
      AD_PROFILE: 'Azure AD 檔案',
      IDENTIFIER: '識別碼',
      VERIFY_IPP: '驗證間接供應商夥伴關係',
      COMPANY_INFO: '公司資訊',
      BILLING_INFO: '帳務資訊',
      TAX_INFO: '稅務資訊',
      REVIEW: '審核',
      SAME_AS_COMPANY: '與公司資訊相同',
      TOKEN: {
        TOKEN: '憑證',
        ONBOARD: '上線憑證',
        ENCUSER: '加密使用者憑證',
      },
      RETURN_TO_LOGIN: '返回登入頁面',
      USRNAME: '使用者名稱',
      USRMAIL: '使用者電子郵件',
      USRACC: '管理者帳號',
      EA_ENROLLMENT_NUM: '註冊編號',
      PASSWD: '臨時密碼',
      PASSWD_WARN: '請確保已複製臨時密碼，否則將無法再次查看',
      GO_PARTNER_CENTER: '前往 Microsoft 合作夥伴中心',
      LINK_PAL: '連結 PAL 至使用者',
      GO_EA_PORTAL: '前往 EA 入口',
      CONTACT_SALES: '聯絡您的業務代表',
      OR_CONTACT_SALES: '或聯絡您的業務代表。',
      ONBOARD_LINK: '上線連結',
      CONTACT_MAIL: '聯絡電子郵件',
      CONTACT_NAME: '聯絡人姓名',
      CONTACT_ADMIN: '請聯絡全域管理員以繼續',
      TCA: '條款與條件協議',
      WIZARD: '資料同步進度',
      EXPAND_KEY: '展開金鑰',
      EA_ACCESS_KEY: 'EA 存取金鑰',
      EMAIL_RECIPIENT: '電子郵件接收者',
      INVITE: '上線邀請',
      SUCCESS: '上線邀請成功',
      ERROR: {
        ERROR: '上線時發生錯誤',
        MESSAGE: '錯誤訊息',
        CODE_TYPE: '錯誤代碼：{{ code }}',
        CODE: '錯誤代碼',
        NOTFOUND: '找不到',
      },
      CMP: {
        STEP_1_1: '資訊確認',
        STEP_1_2: '服務檢查',
        STEP_2_1: '{{ platform }} 租戶授權',
        STEP_2_2: '授權',
        STEP_3: 'EA API 金鑰',
        STEP_4: '資料同步',
        SUCCESS: '恭喜您完成了 Caloudi CRP 上線流程',
        SYNCING: '正在同步您的資料...',
        STEP1: {
          TITLE: '請確認您的上線資訊',
          TEXT1: '歡迎使用 Caloudi CRP，',
          TEXT1_2: '請檢查以下服務資訊。',
          TEXT2: '您必須為',
          TEXT3: '才能授權 Caloudi CRP 執行以下上線流程',
          TEXT4_1: '如有任何問題，請點選',
          TEXT4_2: '上方按鈕',
          TEXT5_1: '將 Caloudi 合作夥伴 ID 連結至您的帳號',
          TEXT5_2: '允許 Caloudi CRP 以貢獻者角色存取訂閱',
          TEXT5_3: '允許 Caloudi CRP 以閱讀者角色存取訂閱',
        },
        STEP3: {
          TITLE1: 'EA API 金鑰',
          TITLE2: '請提供您的企業協議註冊資訊',
          TEXT1: 'EA API 金鑰',
          TEXT2: '如何找到註冊存取金鑰？',
          TEXT3: '您可以找到您的',
        },
        STEP4: {
          TEXT1: '新管理員帳號',
          TEXT2: '已成功建立新的管理員帳號',
          TEXT3: '系統正在同步您的 Azure 訂閱與使用資料。',
          TEXT3_1: '這個過程將花費幾分鐘到數小時不等。',
          TEXT4: '我們會很快發送電子郵件，指引您進行後續操作。',
          TEXT5: '以檢查同步進度',
          TEXT6: '同步完成後，我們將透過電子郵件通知您。',
          BUTTON: '前往資料同步進度',
          CLOSE: '關閉視窗',
        },
        ROLE: {
          AZURE_GADM: 'Azure 全域管理員',
          GADM: '全域管理員',
          SALES_AGENT: '業務代理',
          CONTRIBUTOR: '貢獻者',
        },
      },
      CSP: {
        STEP_1: '電子郵件驗證',
        STEP_2: '{{ role }} 合作夥伴關係',
        STEP_3: '公司與帳務資料',
        STEP_4: '檢閱',
        STEP_5: '完成',
        STEP1: {
          TITLE: '請輸入一次性密碼以驗證您的帳號',
          TEXT: `此電子郵件將用於後續步驟建立 {{ role }} 管理員帳號。
        請檢查您的電子郵件並輸入 {{ digit }} 位數的 OTP 驗證碼。`,
        },
        STEP2: {
          ARTICLE_TITLE_EXIST: '請輸入您的 MPN ID，MPN ID 如下',
          ARTICLE_TITLE_NEW: '請輸入您的 Microsoft ID、MPN ID 並驗證 {{ role }} 合作夥伴關係',
          ARTICLE: {
            TEXT1: '如何找到合作夥伴 Microsoft ID？',
            TEXT2: '您將找到您的',
            TEXT3: '請於下方輸入您的 Microsoft ID',
            TEXT4: '如何找到 MPN ID？',
            TEXT5: '您將找到您的',
            TEXT6: '請於下方輸入您的 MPN ID',
            TEXT7: '您必須是合作夥伴中心的全域管理員，才能授權間接供應商合作夥伴關係請求。',
            TEXT8: '間接供應商合作夥伴授權。',
          },
        },
      },
      OTP: {
        SEND: '發送驗證碼',
        RESEND: '重新發送驗證碼',
        SENT: '驗證碼已發送',
        ENTER_DIGIT_CODE: '輸入 {{ digit }} 位數驗證碼',
        VERIFY_CODE: '驗證碼',
      },
      PROFILE: {
        COUNTRY: '國家/地區',
        COMPANY_NAME: '公司名稱',
        DOMAIN: '網域',
        TENANTDOMAIN: '租戶網域',
        FNAME: '名字',
        MNAME: '中間名',
        LNAME: '姓氏',
        ADDRESS: '地址',
        CITY: '城市',
        STATE: '州/省',
        POSTCODE: '郵遞區號',
        EMAIL: '電子郵件',
        PHONE: '電話號碼',
        TAX_ID: '統一編號',
        SUBSCRIPTION: '訂閱',
        OFFER: '方案',
        PLAN: '計畫',
        STARTDATE: '開始日期',
        BILLING_TERMS: '帳單條件',
        PURCHASER_EMAIL: '採購人電子郵件',
        BENEFIT_EMAIL: '受益人電子郵件',
      },
      TICKET: {
        INTRO_TEXT:
          '請提供更多問題詳情，我們的支援團隊將聯繫您。感謝您的耐心等待。',
        ATTACHMENT_TEXT: '允許自動附上收到的錯誤訊息截圖。',
        NAME_PLACEHOLDER: '請填寫您的姓名',
        EMAIL_PLACEHOLDER: '請填寫您的電子郵件',
        ASK_US_FOR_GETTING_HELP: '尋求協助',
        YOUR_NAME: '您的姓名',
        YOUR_EMAIL: '您的電子郵件',
        CC_EMAILS: '副本電子郵件',
        PHONE: '電話號碼',
        DESCRIPTION: '描述',
        ATTACHMENT: '附件',
        FAQ: '常見問題',
      },
      SURVEY: {
        CUSTOMER_INFO_FORM: '新客戶資訊表單',
        SPECIAL_OFFER_FORM: '特惠及折扣資格表單',
        SPECIAL_OFFER_DESCRIPTION:
          '請回答以下問題，我們將聯繫您確認是否符合特惠資格。',
        RECEIVE_UPDATES: '您是否希望收到 8iSoft 的最新功能更新與優惠方案？',
        RECEIVE_NEWSLETTERS: '您是否希望接收多雲管理的最佳實務電子報？',
      },
    },
  },
};
