// Angular
import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, NavigationStart, ResolveEnd, Router, RouterEvent } from '@angular/router';
// Rxjs
import { BehaviorSubject, filter, map } from 'rxjs';
// NGX
import { NGXLogger } from 'ngx-logger';
// Caloudi
import { CommonUtil } from '@util';
import { AppUtilModule } from '.';
// Interface
import { RouteElementDefault } from '@core/model';

@Injectable({ providedIn: 'root' })
export class RouteUtilModule {
  private _routeUrl: string;
  private _fullUrl: string;
  private _routeLocation: string;
  private _routeQueryParams: RouteElementDefault;
  private _routeFrag: RouteElementDefault;
  private _pageTitle: string;

  public get routeUrl(): string {
    return this._routeUrl;
  }

  public get fullUrl(): string {
    return this._fullUrl;
  }

  public get routeLocation(): string {
    return this._routeLocation;
  }

  public get routeQueryParams(): RouteElementDefault {
    return this._routeQueryParams;
  }

  public get routeFrag(): RouteElementDefault {
    return this._routeFrag;
  }

  public get pageTitle(): string {
    return this._pageTitle;
  }

  public get getRouteEvent(): RouteEvent {
    return {
      routeFrag: this._routeFrag,
      routeLocation: location.href,
      routeQueryParams: this._routeQueryParams,
      pageTitle: this._pageTitle,
      routeUrl: this._routeUrl,
      fullUrl: this._fullUrl,
    };
  }

  public onRouteChange = new EventEmitter<RouteEvent>();

  public readonly routeEvent: BehaviorSubject<RouteEvent>;

  private readonly logActive: boolean;

  constructor(
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly appUtil: AppUtilModule,
    private readonly titleService: Title
  ) {
    this.routeEvent = CommonUtil.observable2BehaviorSub<RouteEvent>(
      this.router.events.pipe(
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd && event.url !== '/reload'),
        map((_event: RouterEvent) => {
          this._routeQueryParams = CommonUtil.URLDecode(location.search);
          this._routeFrag = CommonUtil.URLDecode(location.hash);
          this._fullUrl = location.href.split(location.origin)[1];
          this._routeUrl = location.pathname;

          this.logActive &&
            this.logger.debug('nav end:', [
              location,
              this.routeQueryParams,
              this.routeFrag,
              location.href.split(location.origin)[1],
            ]);

          this.onRouteChange.emit(this.getRouteEvent);
          return <RouteEvent>{
            routeFrag: this._routeFrag,
            routeLocation: location.href,
            routeQueryParams: this._routeQueryParams,
            pageTitle: this._pageTitle,
            routeUrl: this._routeUrl,
            fullUrl: this._fullUrl,
          };
        })
      ),
      {
        routeFrag: undefined,
        routeLocation: location.href,
        routeQueryParams: undefined,
        pageTitle: undefined,
        routeUrl: undefined,
        fullUrl: undefined,
      }
    );
    this.router.events
      .pipe(filter((e: Event | RouterEvent) => (<RouterEvent>e).url !== '/reload'))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this._routeLocation = location.href;
          this.logActive && this.logger.debug('start:', [location.href.split(location.origin)[1]]);
        }
        if (event instanceof ResolveEnd) {
          const current = this.appUtil.authBreadcrumbs?.find(item => new RegExp(item.routeLink).test(event.url));
          const caloudiMatcher = /(localhost|caloudi)/i;
          const titleName = new RegExp(caloudiMatcher).test(location.hostname) ? 'Caloudi' : '8iSoft';

          this._pageTitle = current?.menuItemList.map(item => item.label).join('/ ');
          this.titleService.setTitle(`${titleName}${this.pageTitle ? ' - ' + this.pageTitle : ''}`);

          this.logActive &&
            this.logger.debug('resolve end', [this.routeQueryParams, this.routeFrag, current, this.router.url, location]);
        }
      });
  }
}

export interface RouteEvent<P = RouteElementDefault, F = RouteElementDefault> {
  routeFrag: F;
  routeLocation: string;
  routeQueryParams: P;
  pageTitle: string;
  routeUrl: string;
  fullUrl: string;
}
