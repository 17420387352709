// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject, Observable } from 'rxjs';
// Ngx
import { TranslateService } from '@ngx-translate/core';
// Caloudi
import { CommonUtil } from '@util';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
import { getUser } from '@Selectors';
// Translation
import { DocumentLangType } from '@core/enum';
import { AuthUser, Locale } from '@core/model';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  // Private properties
  private readonly langIds: DocumentLangType[] = [];

  /**
   * Service Constructor
   *
   * @param translate: TranslateService
   */
  constructor(private readonly translate: TranslateService, private readonly store: Store<AppState>) {
    // Add new langIds to the list
    this.translate.addLangs([DocumentLangType.ENG]);

    // This language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(DocumentLangType.ENG);

    this.store.select(getUser).subscribe((authUser: AuthUser) => {
      if (authUser) this.setLanguage(authUser.userProfile?.langId || DocumentLangType.ENG);
    });
  }

  /**
   * Load Translation
   *
   * @param args: Locale[]
   */
  public loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // Use setTranslation() with the third argument set to true
      // To append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // Add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   *
   * @param lang: string
   */
  private setLanguage(lang: DocumentLangType): void {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
    }
  }

  /**
   * Gets the translated value of a key
   */
  public get<T>(key: string[] | string, interpolateParams?: T): BehaviorSubject<string> {
    return CommonUtil.observable2BehaviorSub<string>(
      this.translate.get(key, interpolateParams) as Observable<string>,
      ''
    );
  }
}
