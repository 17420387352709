import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    CONFIG: {
      TITLE: {
        COLOR_SCHEME: '颜色主题',
        INPUT: '输入框背景',
        LAYOUT_MODE: '布局模式',
        LAYOUT_TYPE: '布局类型',
        MENU_MODE: '菜单模式',
        SCALE: '界面比例',
        SPECIAL_FEATS: '测试功能',
        THEME_CUSTOMIZATION: '自定义主题',
      },
      THEMES: {
        MENU: '菜单主题',
        TOPBAR: '顶部栏主题',
        COMPONENT: '组件主题',
      },
      TAB_MENU: {
        PRIME_LAYOUT: 'PrimeNG 布局',
        TAB_MENU_LAYOUT: '标签式布局',
      },
      SPECIAL_FEATS: {
        BLOCK_LOADING: 'Block Loading',
        INLINE_BREADCRUMB: 'Inline Breadcrumb',
        TAB_ENROLLMENT: 'Tab Enrollment',
      },
      LIGHT: '浅色模式',
      DARK: '深色模式',
      SLIM: '简约',
      SLIMPLUS: '简约+',
      REVEAL: '展示',
      DRAWER: '抽屉',
      STATIC: '固定',
      HORIZONTAL: '水平',
      OVERLAY: '覆盖',
      OUTLINED: '边框',
      FILLED: '填充',
    },
  },
};
