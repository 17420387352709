export interface Environment {
  /** App build version for debugging */
  appVersion: string;
  /** Production mode */
  production: boolean;
  /** Build name */
  name: BuildName;
  /** Version trailer */
  versionName: string;
  /** Fallback route */
  homeRouterLink?: string;
  /** App Insight GUID? */
  appInsight?: string;
  /** Runtime auto refreshing mechanism */
  buildNumber?: string;
  /** Default API server */
  apiServer: APIServer;
  /** CMP API server */
  cmpAPIServer: APIServer;
  /** GCP API server */
  gcpAPIServer: APIServer;
  /** AWS API server */
  awsAPIServer: APIServer;
  /** XCloud API server */
  xcloudAPIServer: APIServer;
  /** Microsoft 365 API server */
  m365APIServer: APIServer;
}

interface APIServer {
  /** Server endpoint */
  url: string;
  /** Config? */
  ssoConfig?: string;
  /** Refresh? */
  minutesToRefresh?: number;
}

export enum BuildName {
  cmpdevaws = 'cmp-dev-aws',
  cmpdev = 'cmp-dev',
  cmpprod = 'cmp-prod',
  cmpqa = 'cmp-qa',
  cmptest = 'cmp-test',
  dev = 'dev',
  ysldev = 'ysl-dev',
  yslprod = 'ysl-prod',
  yslserve = 'ysl-serve',
}
