import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    CONFIG: {
      TITLE: {
        THEME_CUSTOMIZATION: 'theme customization',
        SCALE: 'layout/theme scale',
        LAYOUT_MODE: 'layout mode',
        LAYOUT_TYPE: 'layout type',
        MENU_MODE: 'menu mode',
        INPUT: 'input background',
        SPECIAL_FEATS: 'Testing Features',
        COLOR_SCHEME: 'color scheme',
      },
      THEMES: {
        MENU: 'menu themes',
        TOPBAR: 'topbar themes',
        COMPONENT: 'component themes',
      },
      TAB_MENU: {
        PRIME_LAYOUT: 'PrimeNG Layout',
        TAB_MENU_LAYOUT: 'Tab Menu Layout',
      },
      SPECIAL_FEATS: {
        BLOCK_LOADING: 'Block Loading',
        INLINE_BREADCRUMB: 'Inline Breadcrumb',
        TAB_ENROLLMENT: 'Tab Enrollment',
      },
      LIGHT: 'light',
      DARK: 'dark',
      SLIM: 'slim',
      SLIMPLUS: 'slimplus',
      REVEAL: 'reveal',
      DRAWER: 'drawer',
      STATIC: 'static',
      HORIZONTAL: 'horizontal',
      OVERLAY: 'overlay',
      OUTLINED: 'outlined',
      FILLED: 'filled',
    },
  },
};
