// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Caloudi
import { DataResponse, PDFCreate } from '@core/model';
import environment from '@env';

@Injectable({ providedIn: 'root' })
export class GetPagePDFService {
  constructor(private readonly http: HttpClient) {}

  public getPagePDFResponse(pdfCreate: PDFCreate): Observable<DataResponse> {
    return this.http.post<DataResponse>(`${environment.cmpAPIServer.url}/api/report/pdf/jsonData`, pdfCreate);
  }
}
