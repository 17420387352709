// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
// Interface
import { TabMenuBreadcrumb, TabMenuItem } from '@base/model';

@Injectable({ providedIn: 'root' })
export class TabMenuService {
  constructor(private readonly http: HttpClient) {}

  public getTabMenuBreadcrumbs(): Observable<TabMenuBreadcrumb> {
    return this.http.get<TabMenuBreadcrumb>(`${environment.apiServer.url}/api/menu/xCloud/breadcrumbs`);
  }

  public getTabMenuItems(): Observable<TabMenuItem[]> {
    return this.http.get<TabMenuItem[]>(`${environment.apiServer.url}/api/menu/xCloud/tabMenu`);
  }

  public getXCloudsubMenuByMenuTabKey(menuTabKey: string): Observable<TabMenuItem[]> {
    return this.http.get<TabMenuItem[]>(`${environment.apiServer.url}/api/menu/xCloud/${menuTabKey}/sideMenu`);
  }
}
