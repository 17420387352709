export enum SortOrder {
  asc = 1,
  dec = -1,
}

export enum SelectType {
  row = 'row',
  checkbox = 'checkbox',
}

export enum PaginatorPosition {
  top = 'top',
  bottom = 'bottom',
}

export enum SelectionMode {
  multiple = 'multiple',
  single = 'single',
}
