import { createAction, props } from '@ngrx/store';
import { ActionCreatorProp, ActionCreatorType } from '.';

// The Action interface
export enum BlockUIActionTypes {
  Count = '[BlockUI Count] Action',
  Clear = '[BlockUI Clear] Action',
}

export type BlockUIPayload = {
  Count: { count: number };
};

export const BlockUICountAction: ActionCreatorProp<BlockUIPayload['Count']> = createAction(
  BlockUIActionTypes.Count,
  props()
);

export const BlockUIClearAction: ActionCreatorType = createAction(BlockUIActionTypes.Clear);

export const BlockUIActions = {
  BlockUICountAction,
  BlockUIClearAction,
};
