import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    ID: {
      MSID: 'Microsoft ID',
      MPNID: 'MPN ID',
      TENANTID: 'Tenant ID',
      TENANTID_DOMAIN: 'Tenant ID/Domain',
    },
    ONBOARD: {
      AD_PROFILE: 'Azure AD Profile',
      IDENTIFIER: 'Identifier',
      VERIFY_IPP: 'Verify Indirect Provider Partnership',
      COMPANY_INFO: 'Company Info',
      BILLING_INFO: 'Billing Info',
      TAX_INFO: 'Tax Info',
      REVIEW: 'Review',
      SAME_AS_COMPANY: 'Same as Company Info',
      TOKEN: {
        TOKEN: 'Token',
        ONBOARD: 'Onboard Token',
        ENCUSER: 'Encrypted User Token',
      },
      RETURN_TO_LOGIN: 'Return to Login',
      USRNAME: 'User Name',
      USRMAIL: 'User Mail',
      USRACC: 'Admin User Account',
      EA_ENROLLMENT_NUM: 'Enrollment ID',
      PASSWD: 'Temporary Password',
      PASSWD_WARN: "Make sure to copy the temporary password, otherwise it won't show again",
      GO_PARTNER_CENTER: 'Go to Microsoft Partner Center',
      LINK_PAL: 'Link PAL to User',
      GO_EA_PORTAL: 'Go to EA Portal',
      CONTACT_SALES: 'contact your Sales Agent',
      OR_CONTACT_SALES: 'or contact your sales agent.',
      ONBOARD_LINK: 'Onboard Link',
      CONTACT_MAIL: 'Contact Email',
      CONTACT_NAME: 'Contact Name',
      CONTACT_ADMIN: 'Please contact one of global admins to continue',
      TCA: 'Terms and Conditions agreement',
      WIZARD: 'Data Sync Progress',
      EXPAND_KEY: 'Expand Key',
      EA_ACCESS_KEY: 'EA Access Key',
      EMAIL_RECIPIENT: 'Email Recipient',
      INVITE: 'Onboard Invite',
      SUCCESS: 'Onboard Invite Success',
      ERROR: {
        ERROR: 'Onboard Error Happened',
        MESSAGE: 'Error Message',
        CODE_TYPE: 'Error Code: {{ code }}',
        CODE: 'Error Code',
        NOTFOUND: 'Not Found',
      },
      CMP: {
        STEP_1_1: 'Information Confirmation',
        STEP_1_2: 'Service Check',
        STEP_2_1: '{{ platform }} Tenant Authorization',
        STEP_2_2: 'Authorization',
        STEP_3: 'EA API Key',
        STEP_4: 'Data Synchronization',
        SUCCESS: 'Congratulations, you have completed the Caloudi CRP onboard process',
        SYNCING: 'Syncing your data...',
        STEP1: {
          TITLE: 'Please confirm your onboard',
          TEXT1: 'Welcome to Caloudi CRP,',
          TEXT1_2: 'please check the following information about this service.',
          TEXT2: 'You must be an',
          TEXT3: 'to grant Caloudi CRP permission to proceed the following onboarding process',

          // TEXT4: 'If you have any questions, please',
          TEXT4_1: 'If you have any questions, please click',
          TEXT4_2: 'button on the top',
          TEXT5_1: 'Link Caloudi partner ID to your user account',
          TEXT5_2: 'Allow Caloudi CRP to access subscriptions as Contributor',
          TEXT5_3: 'Allow Caloudi CRP to access subscriptions as Reader',
        },
        STEP3: {
          TITLE1: 'EA API Key',
          TITLE2: 'Please provide your Enterprise Agreement Enrollment Info',
          // TEXT1: 'Enter EA API key below',
          TEXT1: 'EA API key',
          TEXT2: 'How to locate Enrollment Access Key?',
          TEXT3: 'and you will find your',
        },
        STEP4: {
          TEXT1: 'A new admin account',
          TEXT2: 'has been successfully created',
          TEXT3: 'The system is now synchronizing your Azure subscription and usage data.',
          TEXT3_1: 'It will take a few minutes to hours.',
          // TEXT4: 'You can go to the',
          TEXT4: 'We will send an email shortly to guide you.',
          TEXT5: 'to check your sync progress',
          TEXT6: 'We will notify you after the data sync completes in email.',
          BUTTON: 'Go To Data Sync Progress',
          CLOSE: 'Close Window',
        },
        ROLE: {
          AZURE_GADM: 'Azure Global Admin',
          GADM: 'Global Admin',
          SALES_AGENT: 'Sales Agent',
          CONTRIBUTOR: 'Contributor',
        },
      },
      CSP: {
        STEP_1: 'Email Verification',
        STEP_2: '{{ role }} Partnership Relationship',
        STEP_3: 'Company and Billing Profile',
        STEP_4: 'Review',
        STEP_5: 'Complete',
        STEP1: {
          TITLE: 'Please enter the one-time password to verify your account',
          TEXT: `The email will be used later step to create {{ role }} admin user.
        Please check your email and fill in the {{ digit }}-digit code for OTP verification.`,
        },
        STEP2: {
          ARTICLE_TITLE_EXIST: 'Please enter your MPN ID, MPN ID below',
          ARTICLE_TITLE_NEW: 'Please enter your Microsoft ID, MPN ID and verify {{ role }} partnership relationship',
          ARTICLE: {
            TEXT1: 'How to locate partner Microsoft ID?',
            TEXT2: 'and you will find your',
            TEXT3: 'Please enter your Microsoft ID below',
            TEXT4: 'How to locate MPN ID?',
            TEXT5: 'and you will find your',
            TEXT6: 'Please enter your MPN ID below',
            TEXT7:
              'You must be a global admin in the Partner Center to authorize Inderict Provider Partnership request.',
            TEXT8: 'Indirect Provider Partnership Authorization.',
          },
        },
      },
      OTP: {
        SEND: 'Send Verification Code',
        RESEND: 'Resend Verification Code',
        SENT: 'Verification Code Sent',
        ENTER_DIGIT_CODE: 'Enter {{ digit }}-digit Code',
        VERIFY_CODE: 'Verify Code',
      },
      PROFILE: {
        COUNTRY: 'Country/Region',
        COMPANY_NAME: 'Company Name',
        DOMAIN: 'Domain',
        TENANTDOMAIN: 'Tenant Domain',
        FNAME: 'First Name',
        MNAME: 'Middle Name',
        LNAME: 'Last Name',
        ADDRESS: 'Address',
        CITY: 'City',
        STATE: 'State',
        POSTCODE: 'ZIP/Postal Number',
        EMAIL: 'Email',
        PHONE: 'Phone Number',
        TAX_ID: 'Tax ID',
        SUBSCRIPTION: 'Subscription',
        OFFER: 'Offer',
        PLAN: 'Plan',
        STARTDATE: 'Start Date',
        BILLING_TERMS: 'Billing Terms',
        PURCHASER_EMAIL: 'Purchaser Email',
        BENEFIT_EMAIL: 'Beneficiary Email',
      },
      TICKET: {
        INTRO_TEXT:
          'Please provide more details of your issue by filling out the form below. Our support team will contact you as we look into the issue. Thank you for your patience.',
        ATTACHMENT_TEXT: 'Allow automatic screenshot attachment of the error message you have received.',
        NAME_PLACEHOLDER: 'Please fill in your name',
        EMAIL_PLACEHOLDER: 'Please fill in your email',
        // ASK_US_FOR_GETTING_HELP: 'Ask us for getting help',
        ASK_US_FOR_GETTING_HELP: 'Ask for help',
        YOUR_NAME: 'Your Name',
        YOUR_EMAIL: 'Your Email',
        CC_EMAILS: 'Cc Emails',
        PHONE: 'Phone',
        DESCRIPTION: 'Description',
        ATTACHMENT: 'Attachment',
        FAQ: 'FAQ',
      },
      SURVEY: {
        CUSTOMER_INFO_FORM: 'New Customer Information Form',
        SPECIAL_OFFER_FORM: 'Special Offer & Discount Eligibility Form',
        SPECIAL_OFFER_DESCRIPTION:
          'Please respond to the following question(s). We will contact you via email if you are eligible for any special offers.',
        RECEIVE_UPDATES: 'Would you like to hear from 8iSoft about our newest feature updates and discount plans?',
        RECEIVE_NEWSLETTERS: 'Would you like to receive best practice newsletters about multi-cloud management?',
      },
    },
  },
};
