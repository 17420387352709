import { createReducer, on } from '@ngrx/store';
import { BlockUIActions } from '../actions';

export interface BlockUIState {
  count: number;
  max?: number;
}

export const initialBlockUIState: BlockUIState = {
  count: -1,
  max: -1,
};

export const blockUIReducer = createReducer(
  initialBlockUIState,
  on(BlockUIActions.BlockUICountAction, (state, { count }): BlockUIState => {
    if (state.count === 0 && state.max > 0) {
      return { count: count, max: state.max };
    } else {
      return { count: count, max: count > state.max ? count : state.max };
    }
  }),
  on(BlockUIActions.BlockUIClearAction, (): BlockUIState => initialBlockUIState)
);
