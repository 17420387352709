<p-chip
  #chip
  [label]="label"
  [icon]="icon"
  [image]="image"
  [removable]="removable"
  [style]="style"
  [styleClass]="styleClass"
  [removeIcon]="removeIcon"
  (onRemove)="onRemove($event)"
  (onImageError)="onImageError($event)">
  <ng-content></ng-content>
</p-chip>
