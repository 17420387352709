// Caloudi
import { CommonUtil } from '@util';
// NGRX
import { createSelector } from '@ngrx/store';
import { AppState, AuthState } from '../reducers';

// For selector
export const selectAuthState: (state: AppState) => AuthState = (state: AppState): AuthState => state.auth;

export const getAuth = createSelector(selectAuthState, auth => auth);

// Point to 'auth' from state subtree
export const isLoggedIn = createSelector(selectAuthState, auth => auth.loggedIn);

export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);

export const getUser = createSelector(selectAuthState, auth => auth.user);

export const getExpiresAt = createSelector(selectAuthState, auth => auth.expiresAt);

export const getBreadcrumbs = createSelector(selectAuthState, auth => auth.breadcrumbs);

export const getBreadcrumb = createSelector(
  selectAuthState,
  auth => !CommonUtil.equals({}, auth.breadcrumb || {}) && auth.breadcrumb
);

export const getUserProfile = createSelector(selectAuthState, auth => auth.user?.userProfile);

export const getUserMenu = createSelector(selectAuthState, auth => auth.user?.userMenu);

export const getEAAPIType = createSelector(selectAuthState, auth => auth.user?.userProfile?.eaapiType);

export const getAppId = createSelector(selectAuthState, auth => auth.user?.userProfile?.appId);

export const getLoginStatus = createSelector(selectAuthState, auth => auth.loggedIn);
