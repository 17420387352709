import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    VPN_PROFILE: {
      DOWNLOADS: 'Download Profiles',
      NAME: 'VPN Profile Name',
      DNS_SERVERS: 'DNS Servers',
      TITLE: {
        DELETE: 'Delete VPN Profile',
        EDIT: 'Edit VPN Profile',
        NEW: 'Add VPN Profile',
        TEST: 'Test Connection',
      },
      ADD_SUCCESS: 'add successful',
      EDIT_SUCCESS: 'edit successful',
      DELETE_SUCCESS: 'delete successful',
      ACTION: {
        TEST: 'test profile',
        EDIT: 'edit profile',
        DELETE: 'delete profile',
      },
      TEST: {
        START: 'start test',
        TESTING: 'testing in progress',
        STANDBY: 'standby',
      },
      MESSAGE: {
        DELETE: 'This action cannot be undone.\r\nAre you sure you want to delete this VPN profile?',
      },
    },
  },
};
