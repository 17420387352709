import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    ID: {
      MSID: 'Microsoft ID',
      MPNID: 'MPN ID',
      TENANTID: '租户 ID',
      TENANTID_DOMAIN: '租户 ID/域名',
    },
    ONBOARD: {
      AD_PROFILE: 'Azure AD 文件',
      IDENTIFIER: '标识符',
      VERIFY_IPP: '验证间接供应商合作关系',
      COMPANY_INFO: '公司信息',
      BILLING_INFO: '账单信息',
      TAX_INFO: '税务信息',
      REVIEW: '审核',
      SAME_AS_COMPANY: '与公司信息相同',
      TOKEN: {
        TOKEN: '令牌',
        ONBOARD: '上线上令牌',
        ENCUSER: '加密用户令牌',
      },
      RETURN_TO_LOGIN: '返回登录页面',
      USRNAME: '用户名',
      USRMAIL: '用户邮箱',
      USRACC: '管理员账户',
      EA_ENROLLMENT_NUM: '注册编号',
      PASSWD: '临时密码',
      PASSWD_WARN: '请确保已复制临时密码，否则将无法再次查看',
      GO_PARTNER_CENTER: '前往 Microsoft 合作伙伴中心',
      LINK_PAL: '将 PAL 链接到用户',
      GO_EA_PORTAL: '前往 EA 门户',
      CONTACT_SALES: '联系您的销售代表',
      OR_CONTACT_SALES: '或联系您的销售代表。',
      ONBOARD_LINK: '上线上链接',
      CONTACT_MAIL: '联系邮箱',
      CONTACT_NAME: '联系人姓名',
      CONTACT_ADMIN: '请联系全局管理员以继续',
      TCA: '条款与条件协议',
      WIZARD: '数据同步进度',
      EXPAND_KEY: '展开密钥',
      EA_ACCESS_KEY: 'EA 访问密钥',
      EMAIL_RECIPIENT: '邮件接收者',
      INVITE: '上线上邀请',
      SUCCESS: '上线上邀请成功',
      ERROR: {
        ERROR: '上线上发生错误',
        MESSAGE: '错误信息',
        CODE_TYPE: '错误代码：{{ code }}',
        CODE: '错误代码',
        NOTFOUND: '未找到',
      },
      CMP: {
        STEP_1_1: '信息确认',
        STEP_1_2: '服务检查',
        STEP_2_1: '{{ platform }} 租户授权',
        STEP_2_2: '授权',
        STEP_3: 'EA API 密钥',
        STEP_4: '数据同步',
        SUCCESS: '恭喜您完成了 Caloudi CRP 上线流程',
        SYNCING: '正在同步您的数据...',
        STEP1: {
          TITLE: '请确认您的上线信息',
          TEXT1: '欢迎使用 Caloudi CRP，',
          TEXT1_2: '请检查以下服务信息。',
          TEXT2: '您必须是',
          TEXT3: '才能授权 Caloudi CRP 执行以下上线流程',
          TEXT4_1: '如有任何问题，请点击',
          TEXT4_2: '顶部按钮',
          TEXT5_1: '将 Caloudi 合作伙伴 ID 链接至您的账户',
          TEXT5_2: '允许 Caloudi CRP 以贡献者角色访问订阅',
          TEXT5_3: '允许 Caloudi CRP 以读者角色访问订阅',
        },
        STEP3: {
          TITLE1: 'EA API 密钥',
          TITLE2: '请提供您的企业协议注册信息',
          TEXT1: 'EA API 密钥',
          TEXT2: '如何找到注册访问密钥？',
          TEXT3: '您可以找到您的',
        },
        STEP4: {
          TEXT1: '新管理员账户',
          TEXT2: '已成功创建新的管理员账户',
          TEXT3: '系统正在同步您的 Azure 订阅与使用数据。',
          TEXT3_1: '该过程将花费几分钟到几小时不等。',
          TEXT4: '我们将很快通过邮件指导您进行后续操作。',
          TEXT5: '以检查同步进度',
          TEXT6: '同步完成后，我们将通过邮件通知您。',
          BUTTON: '前往数据同步进度',
          CLOSE: '关闭窗口',
        },
        ROLE: {
          AZURE_GADM: 'Azure 全局管理员',
          GADM: '全局管理员',
          SALES_AGENT: '销售代理',
          CONTRIBUTOR: '贡献者',
        },
      },
      CSP: {
        STEP_1: '邮箱验证',
        STEP_2: '{{ role }} 合作伙伴关系',
        STEP_3: '公司与账单信息',
        STEP_4: '审核',
        STEP_5: '完成',
        STEP1: {
          TITLE: '请输入一次性密码以验证您的账户',
          TEXT: `此邮箱将用于后续步骤创建 {{ role }} 管理员账户。
        请检查您的邮箱并输入 {{ digit }} 位的 OTP 验证码。`,
        },
        STEP2: {
          ARTICLE_TITLE_EXIST: '请输入您的 MPN ID，MPN ID 如下',
          ARTICLE_TITLE_NEW: '请输入您的 Microsoft ID、MPN ID 并验证 {{ role }} 合作伙伴关系',
          ARTICLE: {
            TEXT1: '如何找到合作伙伴 Microsoft ID？',
            TEXT2: '您将找到您的',
            TEXT3: '请在下方输入您的 Microsoft ID',
            TEXT4: '如何找到 MPN ID？',
            TEXT5: '您将找到您的',
            TEXT6: '请在下方输入您的 MPN ID',
            TEXT7: '您必须是合作伙伴中心的全局管理员，才能授权间接供应商合作伙伴关系请求。',
            TEXT8: '间接供应商合作伙伴授权。',
          },
        },
      },
      OTP: {
        SEND: '发送验证码',
        RESEND: '重新发送验证码',
        SENT: '验证码已发送',
        ENTER_DIGIT_CODE: '输入 {{ digit }} 位验证码',
        VERIFY_CODE: '验证验证码',
      },
      PROFILE: {
        COUNTRY: '国家/地区',
        COMPANY_NAME: '公司名称',
        DOMAIN: '域名',
        TENANTDOMAIN: '租户域名',
        FNAME: '名字',
        MNAME: '中间名',
        LNAME: '姓氏',
        ADDRESS: '地址',
        CITY: '城市',
        STATE: '州/省',
        POSTCODE: '邮政编码',
        EMAIL: '邮箱',
        PHONE: '电话号码',
        TAX_ID: '税号',
        SUBSCRIPTION: '订阅',
        OFFER: '方案',
        PLAN: '计划',
        STARTDATE: '开始日期',
        BILLING_TERMS: '账单条款',
        PURCHASER_EMAIL: '采购人邮箱',
        BENEFIT_EMAIL: '受益人邮箱',
      },
      TICKET: {
        INTRO_TEXT:
          '请提供更多问题详情，我们的支持团队将联系您。感谢您的耐心等待。',
        ATTACHMENT_TEXT: '允许自动附上收到的错误消息截图。',
        NAME_PLACEHOLDER: '请填写您的姓名',
        EMAIL_PLACEHOLDER: '请填写您的邮箱',
        ASK_US_FOR_GETTING_HELP: '寻求帮助',
        YOUR_NAME: '您的姓名',
        YOUR_EMAIL: '您的邮箱',
        CC_EMAILS: '抄送邮箱',
        PHONE: '电话号码',
        DESCRIPTION: '描述',
        ATTACHMENT: '附件',
        FAQ: '常见问题',
      },
      SURVEY: {
        CUSTOMER_INFO_FORM: '新客户信息表单',
        SPECIAL_OFFER_FORM: '特惠及折扣资格表单',
        SPECIAL_OFFER_DESCRIPTION:
          '请回答以下问题，我们将联系您确认是否符合特惠资格。',
        RECEIVE_UPDATES: '您是否希望收到 8iSoft 的最新功能更新与优惠方案？',
        RECEIVE_NEWSLETTERS: '您是否希望接收多云管理的最佳实践电子报？',
      },
    },
  },
};
