import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    HINT: {
      ACCESS_GROUP_DELETED: '存取群組已成功刪除',
      ACCESS_GROUP_UPDATED: '存取群組已成功更新',
      ACTIVATE_ORGANIZATION_UNIT_NOTE: '您確定要啟用選定的組織單位嗎？',
      ACTIVATE_USER_GROUP_NOTE: '您確定要啟用選定的使用者群組嗎？',
      ADD_NEW_ACCESS_GROUP: '成功新增存取群組',
      ADD_TO_ADVISORY_NOTE: '節省金額及覆蓋率將隨使用情況變動',
      ADMIN_USER_HAS_BEEN_EDITED: '管理者帳號已成功更新',
      ANNOUNCEMENT_HAS_BEEN_CREATED: '公告已成功新增',
      ANNOUNCEMENT_HAS_BEEN_DELETED: '公告已成功刪除',
      ANNOUNCEMENT_HAS_BEEN_UPDATED: '公告已成功更新',
      ANNOUNCEMENT_TYPE_HAS_BEEN_CREATED: '公告類型已成功新增',
      ANNOUNCEMENT_TYPE_HAS_BEEN_DELETED: '公告類型已成功刪除',
      ANNOUNCEMENT_TYPE_HAS_BEEN_EDITED: '公告類型已成功更新',
      ASSIGNMENT_LIST_HAS_BEEN_UPDATED: '指派清單已成功更新',
      AUTOCOMPLETE_INFO: '請輸入關鍵字進行搜尋，然後從搜尋結果中選擇',
      BC_CUSTOMER_INFO: '客戶帳務結算週期',
      BC_PARTNER_INFO: '合作夥伴帳務結算週期',
      COMMENT_HAS_BEEN_ADDED: '留言已成功新增',
      CONFIRM_DELETE_TAG: '確定要刪除此標籤？此操作將影響所有關聯的供應商。',
      CONFIRM_DELETE_TAG_VALUE: '確定要刪除此標籤值？此操作將影響所有關聯的供應商。',
      CONTACT_HAS_BEEN_CREATED: '聯絡人已成功新增',
      CONTACT_HAS_BEEN_DELETED: '聯絡人已成功刪除',
      CONTACT_HAS_BEEN_UPDATED: '聯絡人已成功更新',
      COST_DEVIATION_TOOLTIP: '根據預估值計算的成本差異',
      CSP_EMPTY_BAR_INFO: '請選擇公司以查詢相關結果',
      CUSTOMER_HAS_BEEN_CREATED: '客戶資料已成功新增',
      CUSTOMER_HAS_BEEN_UPDATED: '客戶資料已成功更新',
      CUSTOMER_ORDER_HISTORY_CANCEL: '您確定要取消這筆訂單嗎？',
      CUSTOMER_SUBSCRIPTION_ACTIVE_INFO: '您確定要啟用此訂閱？',
      CUSTOMER_SUBSCRIPTION_SPSENDED_INFO: '您確定要暫停此訂閱？',
      DEFAULT_PRICING_NOTE: '未指定定價配置，將使用預設值',
      DELETE_ACCESS_GROUP: '確定要刪除此存取群組？',
      DELETE_ACCESS_GROUP_FAILED: '刪除存取群組失敗',
      DELETE_ANNOUNCEMENT_NOTE: '確定要刪除此公告？',
      DELETE_ANNOUNCEMENT_TYPE_NOTE: '確定要刪除「{{ tagName }}」嗎？',
      DELETE_BUDGET_NOTE: '確定要刪除此預算表？',
      DELETE_CONTACT_NOTE: '確定要刪除此聯絡人？',
      DELETE_DISCOUNT_PERCENT_RULE_NOTE: '確定要刪除此折扣規則？',
      DELETE_EAENROLLMENT_NOTE: '確定要刪除選定的 EA Enrollment？',
      DELETE_FEATURE_PACK_NOTE: '確定要刪除功能組「{{ filterName }}」嗎？',
      DELETE_MAPPING_RULE_NOTE: '確定要刪除此對應規則？',
      DELETE_ORGANIZATION_NOTE: '確定要刪除選定的組織？',
      DELETE_PERSONAL_FILTER_NOTE: '確定要刪除篩選條件「{{ filterName }}」嗎？',
      DELETE_PERSONAL_VIEW_NOTE: '確定要刪除檢視「{{ viewName }}」嗎？',
      DELETE_PRICING_CONFIG_NOTE: '確定要刪除此定價配置？',
      DELETE_PROBLEM_SUBTYPE_NOTE: '確定要刪除此問題子類型？',
      DELETE_PROBLEM_TYPE_NOTE: '確定要刪除此問題類型？',
      DELETE_RECOMMENDATION_NOTE: '確定要刪除此建議？',
      DELETE_RESELLER_CONFIRM: '確定要刪除經銷商「{{ companyName }}」嗎？',
      DELETE_RI_RECOMM_REPORT_NOTE: '確定要刪除選定的報表？',
      DELETE_SKU_RULE_NOTE: '確定要刪除選定的 SKU 規則？',
      DELETE_USAGE_ACCESS_CONTROL_NOTE: '確定要刪除費用存取限制「{{ filterName }}」嗎？',
      DELETE_USER_NOTE: '確定要刪除選定的使用者？此操作將同時刪除其權限。',
      DESTROY_PROVISION_NOTE: '確定要移除此佈建？',
      DIMENSION_RULE_HAS_BEEN_CREATED: '維度對應規則已成功新增',
      DIMENSION_RULE_HAS_BEEN_DELETED: '維度對應規則已成功刪除',
      DIMENSION_RULE_HAS_BEEN_IMPORTED: '維度對應規則已成功匯入',
      DIMENSION_RULE_HAS_BEEN_UPDATED: '維度對應規則已成功更新',
      EA_ENROLLMENT_SAVE_CONFIRM: '您確定要儲存此次 EA Enrollment 設定修改嗎？',
      EMAIL_RECIPIENTS_HINT: '請在每個電子郵件地址後按下 Enter 鍵',
      ENGINEER_OWNER_HAS_BEEN_ASSIGNED: '工程師負責人已成功指派',
      FILE_UPLOADED: '檔案已成功上傳',
      INTERVAL_INFO: '設定間隔以限制每次顯示的結果數量',
      INVOICE_BILLING_PERIOD: '此發票適用於該結算週期',
      LINE_ITEM_HAS_BEEN_UPDATED: '項目已成功更新',
      LINE_ITEM_HAS_BEEN_VOIDED: '項目已作廢',
      LOADING_MESSAGE: '正在處理您的請求，請稍候...',
      MAKE_PAYMENT_NOTE: '確定要進行此付款？',
      MAX_CPU_P_95_NOTE: '最大 CPU 使用率 (95 百分位)',
      MAX_MEMORY_P_95_NOTE: '最大記憶體使用率 (95 百分位)',
      MAX_TOTAL_NETWORK_P_95_NOTE: '最大網路使用率 (95 百分位)',
      MEMO_UPDATED: '備註已成功更新',
      MUST_CONTAIN8888: 'DNS 列表必須包含 IP 8.8.8.8。',
      NAME_EMPTY_ALERT: '名稱至少應包含一個字元',
      NEW_ACCESS_GROUP_ADDED: '成功新增存取群組',
      NEW_ACCESS_GROUP_FAILED: '存取群組新增失敗',
      NEW_USER_HAS_BEEN_CREATED: '使用者已成功新增',
      ORGANIZATION_HAS_BEEN_CREATED: '組織已成功新增',
      ORGANIZATION_HAS_BEEN_DELETED: '組織已成功刪除',
      ORGANIZATION_HAS_BEEN_EDITED: '組織已成功更新',
      ORGANIZATION_UNIT_HAS_BEEN_CREATED: '組織單位已成功新增',
      ORGANIZATION_UNIT_HAS_BEEN_DELETED: '組織單位已成功刪除',
      ORGANIZATION_UNIT_HAS_BEEN_UPDATED: '組織單位已成功更新',
      PAYMENT_SUCCESSFUL: '付款已成功完成',
      PLACE_LINE_ITEM_ORDER_NOTE: '確定要下訂此項目嗎？',
      PLACE_ORDER_NOTE: '確定要下此訂單嗎？',
      PLEASE_WAIT: '請稍候...',
      PRICING_CONFIG_HAS_BEEN_CREATED: '定價配置已成功新增',
      PRICING_CONFIG_HAS_BEEN_DELETED: '定價配置已成功刪除',
      PRICING_CONFIG_HAS_BEEN_UPDATED: '定價配置已成功更新',
      PRIORITY_HAS_BEEN_UPDATED: '優先順序已成功更新',
      PROBLEM_SUBTYPE_HAS_BEEN_CREATED: '問題子類型已成功新增',
      PROBLEM_SUBTYPE_HAS_BEEN_DELETED: '問題子類型已成功刪除',
      PROBLEM_SUBTYPE_HAS_BEEN_UPDATED: '問題子類型已成功更新',
      PROBLEM_TYPE_HAS_BEEN_CREATED: '問題類型已成功新增',
      PROBLEM_TYPE_HAS_BEEN_DELETED: '問題類型已成功刪除',
      PROBLEM_TYPE_HAS_BEEN_UPDATED: '問題類型已成功更新',
      PURCHASE_AGREEMENT_INFO: '客戶需接受 Microsoft 客戶合約才能購買產品',
      PURCHASE_AGREEMENT_VISIT: '欲接受 Microsoft 客戶合約，請訪問',
      PURCHASE_RI_INFO: '購買 RI 需先訂閱 Azure 計劃',
      RECOMMENDATIONS_NOTICS: '建議基於過去 30 天的 VM 使用情況，實際節省金額可能有所不同。',
      RECOMMENDATION_HAS_BEEN_DELETED: '建議已成功刪除',
      REPORT_HAS_BEEN_DELETED: '報表已成功刪除',
      REPORT_IS_BEING_GENERATED: '報表生成中，請稍候',
      RESELLER_PREREQUISITES_NOTE: '此經銷商尚未符合購買資格，無法為客戶下訂。',
      RESERLLER_PROFILE_LINK_NOTE: '查看並編輯經銷商資料',
      RESOURCE_PLAN_HAS_BEEN_CREATED: '資源規劃已成功新增',
      RESOURCE_PLAN_HAS_BEEN_DELETED: '資源規劃已成功刪除',
      RESOURCE_PLAN_HAS_BEEN_UPDATED: '資源規劃已成功更新',
      RESOURCE_TAG_INFO: '請先輸入 Key 並加上等號，再輸入 Value 進行查詢',
      RI_ANALYSIS_COST_WITH_RECOMMENDATION_NOTE: '新使用時數及當前與新 RI 使用率的預估成本',
      RI_ANALYSIS_COVERAGE_RATE_NOTE: '依據預估小時數',
      RI_ANALYSIS_CURRENT_COST_NOTE: '當前使用時數與 RI 使用率的預估成本',
      RI_ANALYSIS_RESET_NOTE: '重設至初始 RI 建議',
      RI_COVERAGE_RATE_COST_NOTE: 'RI 成本 / (使用成本 + RI 成本)',
      RI_COVERAGE_RATE_NOTE: 'RI 保留時數 / (使用時數 + RI 保留時數)',
      RI_COVERAGE_RATE_QUANTITY_NOTE: 'RI 保留數量 / (使用數量 + RI 保留數量)',
      RI_DEDUCTED_HOURS_NOTE: '含彈性比例的使用時數',
      RI_NEW_COVERAGE_RATE_NOTE: '新 RI 保留時數 / (新使用時數 + 新 RI 保留時數)',
      RI_NEW_COVERAGE_RATE_QUANTITY_NOTE: '新 RI 保留數量 / (新使用數量 + 新 RI 保留數量)',
      RI_RECOMMENDATIONS_NOTICS: 'RI 建議基於過去 30 天的 VM 使用情況，實際節省金額可能有所不同。',
      RI_RECOMM_REPORT_NOTICES: 'RI 建議基於過去 30 天的 VM 使用情況、一年以及共用，實際節省金額可能有所不同。',
      RI_UTILIZATION_RATE_NOTE: '已使用時數 / 保留時數',
      RI_UTILIZATION_RATE_QUANTITY_NOTE: '已使用數量 / 保留數量',
      SALES_ORDER_HAS_BEEN_VOIDED: '銷售訂單已作廢',
      SAME_NAME_ALERT: '此名稱已存在',
      SAVING_RANGE_INPUT_HINT: '此服務類別的最大節省金額為 ${{ maxSavings }}，請輸入 $0 至 ${{ maxSavings }} 之間的金額。',
      SAVING_RANGE_MIN_MAX_HINT: '節省金額的最小值不可超過最大值',
      SET_UP_ACCOUNT_NOTE: '請先設定付款帳戶',
      STATUS_HAS_BEEN_UPDATED: '狀態已成功更新',
      SUBSCRIPTION_COUNTS_ONBOARD_NOTE: '上線時的訂閱數量',
      SUPPORT_OWNER_HAS_BEEN_ASSIGNED: '支援負責人已成功指派',
      SUPPORT_TICKET_HAS_BEEN_CREATED: '支援請求已成功建立',
      SUSPEND_ORGANIZATION_UNIT_NOTE: '您確定要暫停選定的組織單位嗎？',
      SUSPEND_USER_GROUP_NOTE: '您確定要暫停選定的使用者群組嗎？',
      SWITCH_TO_USERID_NOTE: '您確定要切換至「{{ userId }}」嗎？',
      SWITCH_TO_USERID_SUCESSFULLY: '成功切換至「{{ userId }}」',
      TAG_MANAGEMENT_INFO: '有效的風險管理計劃中，標籤為關鍵組成部分。最多可建立三個標籤，這些標籤將在新增供應商時生成自訂欄位，且第一個標籤將顯示於儀表板。',
      TOTAL_REVENUE_INFO: '總收入為篩選範圍內的匯總',
      UNSUBSCRIBE_SUBSCRIPTION: '您確定要取消訂閱「{{ subscriptionName }}」嗎？',
      UPDATE_ACCESS_GROUP: '存取群組已成功更新',
      UPDATE_ACCESS_GROUP_FAILED: '存取群組更新失敗',
      UPLOADING: '正在上傳中...',
      UPLOAD_FAILED: '上傳失敗',
      USER_GROUP_HAS_BEEN_CREATED: '使用者群組已成功新增',
      USER_GROUP_HAS_BEEN_DELETED: '使用者群組已成功刪除',
      USER_GROUP_HAS_BEEN_UPDATED: '使用者群組已成功更新',
      VOID_LINE_ITEM_NOTE: '確定要將此項目作廢？',
      VOID_SALES_ORDER_NOTE: '確定要將此訂單作廢？',
      WRONG_EMAIL_FORMAT: '電子郵件格式不正確',
    },
  },
};
