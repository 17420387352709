import { AuthState } from '@Reducers';
import { createAction, props } from '@ngrx/store';
import { ActionCreatorProp, ActionCreatorType } from '.';

// The Action interface
export enum AuthActionTypes {
  Auto_Login = '[Auto Login] Action',
  Check_Alive = '[Check Alive] Action',
  Check_Alive_Idle = '[Check Alive Idle] Action',
  Load_Breadcrumbs = '[Load_Breadcrumbs] Action',
  Login = '[Login] Action',
  Logout = '[Logout] Action',
  Refresh_Page = '[Refresh Page] ACtion',
  Refresh_Token = '[Refresh Token] ACtion',
  Refresh_User = '[Refresh User] Action',
  Set_Breadcrumb = '[Set Breadcrumb] Action',
  Token_Expired = '[Logout Expired] Action',
  Update_User = '[Update User] Action',
}

export type AuthPayload = {
  Auto_Login: { authUser: AuthState['user']; };
  Check_Alive: { expiresAt: number; };
  Load_Breadcrumbs: { breadcrumbs: AuthState['breadcrumbs']; };
  Login: { authUser: AuthState['user']; };
  Refresh_Token: { accessToken: AuthState['user']['accessToken']; };
  Set_Breadcrumb: { breadcrumb: AuthState['breadcrumb']; };
  Token_Expired: { authUser: AuthState['user']; };
  Update_User: { authUser: AuthState['user']; };
};

export const AutoLoginAction: ActionCreatorProp<AuthPayload['Auto_Login']> = createAction(
  AuthActionTypes.Auto_Login,
  props()
);

export const CheckAliveAction: ActionCreatorProp<AuthPayload['Check_Alive']> = createAction(
  AuthActionTypes.Check_Alive,
  props()
);

export const CheckAliveIdleAction: ActionCreatorType = createAction(AuthActionTypes.Check_Alive_Idle);

export const LoadBreadcrumbsAction: ActionCreatorProp<AuthPayload['Load_Breadcrumbs']> = createAction(
  AuthActionTypes.Load_Breadcrumbs,
  props()
);

export const LoginAction: ActionCreatorProp<AuthPayload['Login']> = createAction(AuthActionTypes.Login, props());

export const LogoutAction: ActionCreatorType = createAction(AuthActionTypes.Logout);

export const RefreshPageAction: ActionCreatorType = createAction(AuthActionTypes.Refresh_Page);

export const RefreshTokenAction: ActionCreatorProp<AuthPayload['Refresh_Token']> = createAction(
  AuthActionTypes.Refresh_Token,
  props()
);

export const RefreshUserAction: ActionCreatorType = createAction(AuthActionTypes.Refresh_User);

export const SetBreadcrumbAction: ActionCreatorProp<AuthPayload['Set_Breadcrumb']> = createAction(
  AuthActionTypes.Set_Breadcrumb,
  props()
);

export const TokenExpiredAction: ActionCreatorProp<AuthPayload['Token_Expired']> = createAction(
  AuthActionTypes.Token_Expired,
  props()
);

export const UpdateUserAction: ActionCreatorProp<AuthPayload['Update_User']> = createAction(
  AuthActionTypes.Update_User,
  props()
);

export const AuthActions = {
  AutoLoginAction,
  CheckAliveAction,
  CheckAliveIdleAction,
  LoadBreadcrumbsAction,
  LoginAction,
  LogoutAction,
  RefreshPageAction,
  RefreshTokenAction,
  RefreshUserAction,
  SetBreadcrumbAction,
  TokenExpiredAction,
  UpdateUserAction,
};
