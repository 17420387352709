// Angular
import { AfterViewInit, Component, Injector, Input } from '@angular/core';
// Caloudi
import { ViewEncapsulation } from '@angular/core';
import BaseComponent from '@base';
import { CaloudiGauge, GaugeOptions } from './func';

@Component({
  selector: 'caloudi-gauge-chart',
  templateUrl: './caloudi-gauge-chart.component.html',
  styleUrls: ['./caloudi-gauge-chart.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class CaloudiGaugeChartComponent extends BaseComponent implements AfterViewInit {
  @Input('attrId') public attrId: string = 'gaugeArea';
  @Input('hasNeedle') public hasNeedle: boolean = true;
  @Input('outerNeedle') public outerNeedle: boolean;
  @Input('needleColor') public needleColor: string;
  @Input('needleUpdateSpeed') public needleUpdateSpeed: number = 1000;
  @Input('arcColors') public arcColors: string[] = [];
  @Input('arcDelimiters') public arcDelimiters: number[] = [];
  @Input('rangeLabel') public rangeLabel: string[];
  @Input('centralLabel') public centralLabel: string = '';
  @Input('needleValue') public needleValue: number;
  @Input('areaWidth') public areaWidth: number = 350;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  public ngAfterViewInit(): void {
    const element: HTMLElement = document.querySelector('#' + this.attrId);
    // this.logger.debug(element);
    const value = (this.needleValue / Number(this.rangeLabel[1])) * 100;
    // this.logger.debug(this.needleValue, value);

    let gaugeOptions: GaugeOptions = {
      hasNeedle: this.hasNeedle,
      needleColor: this.needleColor,
      needleUpdateSpeed: this.needleUpdateSpeed,
      arcColors: this.arcColors,
      arcDelimiters: this.arcDelimiters,
      rangeLabel: this.rangeLabel,
      centralLabel: this.centralLabel,
      outerNeedle: this.outerNeedle,
    };

    new CaloudiGauge(this.injector).gaugeChart(element, this.areaWidth, gaugeOptions).updateNeedle(value);
  }

  // public ngOnInit(): void {
  //   // const GaugeChart = require('gauge-chart');
  //   const element: HTMLElement = document.querySelector('#gaugeArea');
  //   this.logger.debug(element);
  //   const value = (this.needleValue / Number(this.rangeLabel[1])) * 100;
  //   // this.logger.debug(this.needleValue, value);

  //   let gaugeOptions: GaugeOptions = {
  //     hasNeedle: this.hasNeedle,
  //     needleColor: this.needleColor,
  //     needleUpdateSpeed: this.needleUpdateSpeed,
  //     arcColors: this.arcColors,
  //     arcDelimiters: this.arcDelimiters,
  //     rangeLabel: this.rangeLabel,
  //     centralLabel: this.centralLabel,
  //     outerNeedle: this.outerNeedle,
  //   };

  //   new CaloudiGauge(this.injector).gaugeChart(element, 350, gaugeOptions).updateNeedle(value);
  // }
}
