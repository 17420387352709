import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { first } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { CommonUtil } from '@util';
// Interface
import { LogEventPeriod, PeriodItem, PeriodItemStore } from '@base/model';
import { SelectRange } from '@core/enum';
import { NgChange } from '@core/model';

@Component({
  selector: 'caloudi-common-period-selector, cps, [cps]',
  templateUrl: './common-period-selector.component.html',
  styleUrls: ['./common-period-selector.component.sass'],
})
export class CommonPeriodSelectorComponent extends BaseComponent implements OnChanges {
  public SelectRange = SelectRange;

  // Date Values
  @Input('value') public dateList: SelectItem<string>[];
  @Input('startValue') public dateStartList: SelectItem<string>[];
  @Input('endValue') public dateEndList: SelectItem<string>[];

  // Range Control
  @Input('range') public defaultSelectRange: SelectRange;
  @Input('selectedStart') public startInput: string;
  @Input('selectedEnd') public endInput: string;

  // Title Contorl
  @Input('title') public selectorTitle: string;
  @Input('disabled') public disable: boolean;
  @Input('displayTitle') public displayTitle: boolean = true;
  @Input('label') public customLabel: string;

  // Various Contorl
  @Input('tempStore') public tempStore: boolean = true;
  @Input('sliceStart') public sliceStartControl: boolean = true;
  private readonly logActive: boolean;

  @Output('periodChange') private readonly periodChange: EventEmitter<PeriodItem> = new EventEmitter<PeriodItem>();

  public loggerPayload: LogEventPeriod['payload'];

  public empty: boolean;

  private dateStartItem: SelectItem<string>;
  private dateEndItem: SelectItem<string>;

  public dropdownItems: DropdownItems;

  private get startList(): SelectItem<string>[] {
    return this.dateList || this.dateStartList;
  }

  private get endList(): SelectItem<string>[] {
    return this.dateList || this.dateEndList;
  }

  constructor(public readonly injector: Injector) {
    super(injector);
    this.defaultSelectRange = SelectRange.Full;
    if (!this.tempStore) return;
    this.store
      .select<PeriodItemStore>(this.selectors.temp)
      .pipe(first())
      .subscribe(res => {
        const key = this.router.url;
        const keys = Object.keys(res || {});
        const periodItem: PeriodItem = {
          start: res?.[key]?.period?.start,
          end: res?.[key]?.period?.end,
        };

        if (!keys.some(v => v === this.router.url))
          this.store.dispatch(this.actions.TempStoreAppend({ [this.router.url]: { period: periodItem } }));

        this.dateStartItem = periodItem.start;
        this.dateEndItem = periodItem.end;
        this.logActive && this.logger.debug('period selector store:', { store: res, keys: keys, item: periodItem });
      });
  }

  public ngOnChanges(changes: DateSimpleChanges): void {
    const item = changes?.dateList?.currentValue;
    const startItem = changes?.dateStartList?.currentValue;
    const endItem = changes?.dateEndList?.currentValue;
    if (!item && !startItem && !endItem) return;
    this.logActive && this.logger.debug('any changes:', [CommonUtil.deepCopy(changes, item, startItem, endItem)]);

    if (CommonUtil.ngIsChanges(changes?.startInput) && CommonUtil.ngIsChanges(changes?.endInput)) {
      this.logActive && this.logger.debug('input change:', [changes.startInput, changes.endInput]);
      this.dateEndItem = this.bindEnd(changes.endInput.currentValue);
      this.dateStartItem = this.bindEnd(changes.startInput.currentValue);
    }

    if (item && CommonUtil.ngIsChanges(changes?.dateList)) {
      this.logActive &&
        this.logger.debug('period selector init:', [changes.dateList, this.dateStartItem, this.dateEndItem]);

      this.empty = (this.dateList || []).length === 0;
      this.dateEndItem = this.bindEnd() || [...this.dateList][0];
      this.dateStartItem = this.bindStart();
      if (!this.dateStartItem) {
        switch (this.defaultSelectRange) {
          case SelectRange.Full:
            this.dateStartItem = [...this.dateList].splice(-1)[0];
            break;
          case SelectRange.Last:
            this.dateStartItem = [...this.dateList][1];
            break;
          case SelectRange.Current:
            this.dateStartItem = [...this.dateList][0];
            break;
          default:
            this.dateStartItem = [...this.dateList].splice(-1)[0];
        }
      }
      this.logActive &&
        this.logger.debug('date item:', [this.dateStartItem, this.dateEndItem, { ...this.dropdownItems }]);
      this.bindSelected();
    }

    if (
      (startItem && CommonUtil.ngIsChanges(changes?.dateStartList)) ||
      (endItem && CommonUtil.ngIsChanges(changes?.dateEndList))
    ) {
      this.logActive &&
        this.logger.debug('period selector init:', [
          changes.dateStartList,
          changes.dateEndList,
          this.dateStartItem,
          this.dateEndItem,
        ]);

      this.empty = (this.dateStartList || []).length === 0 || (this.dateEndList || []).length === 0;

      this.dateEndItem = this.bindEnd() || [...this.dateEndList][0];
      this.dateStartItem = this.bindStart();
      if (!this.dateStartItem) {
        switch (this.defaultSelectRange) {
          case SelectRange.Full:
            this.dateStartItem = [...this.dateStartList].splice(-1)[0];
            break;
          case SelectRange.Last:
            this.dateStartItem = [...this.dateStartList][1];
            break;
          case SelectRange.Current:
            this.dateStartItem = [...this.dateStartList][0];
            break;
          default:
            this.dateStartItem = [...this.dateStartList].splice(-1)[0];
        }
      }
      this.logActive &&
        this.logger.debug('date item:', [this.dateStartItem, this.dateEndItem, { ...this.dropdownItems }]);
      this.bindSelected();
    }
  }

  private bindSelected(): void {
    this.dropdownItems = {
      start: this.dateStartItem.value,
      end: this.dateEndItem.value,
      startList: this.sliceStart(),
      endList: this.sliceEnd(),
    };
    this.logActive && this.logger.debug('dropdown items:', { ...this.dropdownItems });
    this.emitChange();
  }

  private bindEnd(date?: string): SelectItem<string> {
    this.logActive && this.logger.debug('bind end:', [date, this.dateEndItem, this.endList]);
    return (
      this.endList.find(item => {
        if (date) return item.value === date;
        if (this.endInput && !this.dateEndItem) return item.value === this.endInput;
        return item.value === this.dateEndItem?.value;
      }) || this.endList[0]
    );
  }

  private bindStart(date?: string): SelectItem<string> {
    this.logActive && this.logger.debug('bind start:', [date, this.dateStartItem, this.startList]);
    return this.startList.find(item => {
      if (date) return item.value === date;
      if (this.startInput && !this.dateStartItem) return item.value === this.startInput;
      return item.value === this.dateStartItem?.value;
    });
  }

  private sliceEnd(): SelectItem<string>[] {
    this.logActive && this.logger.debug('slice end:', [this.endList, this.dateStartItem]);
    if (this.dateEndList && !this.dateEndList?.some(item => item.value === this.dateStartItem.value))
      return [...this.endList].slice(0, 1);
    else return [...this.endList].slice(0, this.findFromEnd(this.dateStartItem.value) + 1);
  }

  private sliceStart(): SelectItem<string>[] {
    this.logActive && this.logger.debug('slice start:', [this.startList, this.dateEndItem]);
    if (!this.sliceStartControl) return [...this.startList];
    if (this.dateStartList && !this.dateStartList?.some(item => item.value === this.dateEndItem.value))
      return [...this.startList];
    else return [...this.startList].slice(this.findFromStart(this.dateEndItem.value));
  }

  public onDateStartChange(date: string, fromDrilldown?: true): void {
    this.logActive && this.logger.debug('start change:', [date, this.dateStartItem, { ...this.dropdownItems }]);
    if (!date) return;
    const startIndex = this.findFromEnd(date);
    const endIndex = this.findFromEnd(this.dropdownItems.end);

    const convertedDate = startIndex < endIndex ? date : this.dropdownItems.end;
    if (convertedDate) {
      this.logActive && this.logger.debug('out of end:', [startIndex, endIndex, convertedDate]);
      this.dateEndItem = this.bindEnd(convertedDate);
      this.dropdownItems.end = this.bindEnd(convertedDate).value;
    }

    this.dateStartItem = this.bindStart(date);
    this.dropdownItems.endList = this.sliceEnd();

    if (!fromDrilldown) this.emitChange();
  }

  public onDateEndChange(date: string): void {
    this.logActive && this.logger.debug('end change:', [date, this.dateEndItem, { ...this.dropdownItems }]);
    if (!date) return;
    this.dateEndItem = this.bindEnd(date);
    this.dropdownItems.end = date;
    this.dropdownItems.startList = this.sliceStart();
    this.emitChange();
  }

  private findFromStart(key: string): number {
    return this.startList.findIndex(item => item.value === key);
  }

  private findFromEnd(key: string): number {
    return this.endList.findIndex(item => item.value === key);
  }

  private emitChange(): void {
    const periodItem: PeriodItem = { start: this.dateStartItem, end: this.dateEndItem };
    this.logActive && this.logger.debug('emit change:', CommonUtil.deepCopy(periodItem, this.startList, this.endList));

    if (
      this.dateList &&
      !this.dateList.some(item => item.value === periodItem.start.value || item.value === periodItem.end.value)
    )
      return;

    if (
      (this.dateStartList && !this.dateStartList.some(item => item.value === periodItem.start.value)) ||
      (this.dateEndList && !this.dateEndList.some(item => item.value === periodItem.end.value))
    )
      return;

    this.loggerPayload = {
      list: this.dateList,
      startList: this.dateStartList,
      endList: this.dateEndList,
      start: periodItem.start.value,
      end: periodItem.end.value,
      defaultRange: SelectRange[this.defaultSelectRange],
    };
    this.logActive &&
      this.logger.debug('logger payload:', [this.loggerPayload, { ...this.dropdownItems }, { ...periodItem }]);
    if (this.tempStore)
      this.store.dispatch(this.actions.TempStoreAppend({ [this.router.url]: { period: periodItem } }));
    this.periodChange.emit(periodItem);
  }
}

interface DateSimpleChanges extends SimpleChanges {
  dateList?: NgChange<SelectItem<string>[]>;
  dateStartList?: NgChange<SelectItem<string>[]>;
  dateEndList?: NgChange<SelectItem<string>[]>;
  startInput?: NgChange<string>;
  endInput?: NgChange<string>;
}

interface DropdownItems {
  start: string;
  end: string;
  startList: SelectItem<string>[];
  endList: SelectItem<string>[];
}
