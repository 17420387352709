import { TabMenuActions } from '@Actions';
import { createReducer, on } from '@ngrx/store';
// Interface
import { TabMenuBreadcrumb, TabMenuItem, TabMenuItemList } from '@base/model';
import { DocumentLangType } from '@core/enum';

export interface CurrentSubmenu {
  label: string;
  route: string;
}
export interface TabMenuState {
  breadcrumbs: TabMenuBreadcrumb;
  items: TabMenuItem[];
  cache: TabMenuItemList;
  lang: DocumentLangType;
  currentSubmenu: CurrentSubmenu[];
}

export const initialTabMenuState: TabMenuState = {
  breadcrumbs: undefined,
  items: undefined,
  cache: undefined,
  lang: DocumentLangType.ENG,
  currentSubmenu: undefined,
};

/** Creating the reducer function */
export const tabMenuBreadcrumbListReducer = createReducer(
  initialTabMenuState,
  on(
    TabMenuActions.ChangeCacheByKeyAction,
    (state, { cache }): TabMenuState => ({
      ...state,
      cache: {
        ...state.cache,
        ...cache,
      },
    })
  ),
  on(
    TabMenuActions.ChangeTabMenuBreadcrumbsAction,
    (state, { breadcrumbs }): TabMenuState => ({
      ...state,
      breadcrumbs,
    })
  ),
  on(
    TabMenuActions.ChangeTabMenuItemsAction,
    (state, { items }): TabMenuState => ({
      ...state,
      items,
    })
  ),
  on(
    TabMenuActions.ChangeTabMenuLangAction,
    (state, { lang }): TabMenuState => ({
      ...state,
      lang,
    })
  ),
  on(TabMenuActions.ChangeSubmenuAction, (state, { items }): TabMenuState => {
    const result: CurrentSubmenu[] = items
      .flatMap(val => (val.items ? val.items : val))
      .flatMap(item => ({
        label: item.label,
        route: item.routerlink || (item.routerLink as string),
      }));

    return {
      ...state,
      currentSubmenu: result,
    };
  })
);
