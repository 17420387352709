// Angular
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
// Primeng
import { SelectItem, SelectItemGroup } from 'primeng/api';
// Caloudi
import { BaseComponent } from '@base';
import { CustomPeriodSelectorService } from '@base/service';
// Interface
import { NgChange } from '@core/model';

@Component({
  selector: 'custom-period-selector',
  templateUrl: './custom-period-selector.component.html',
  styleUrls: ['./custom-period-selector.component.sass'],
})
export class CustomPeriodSelectorComponent extends BaseComponent implements OnInit, OnChanges {
  /*
   timeScope: is not required, default is Recent 2 month.
     - date: 'Current Month' (format: string).
     - Range Dates: "2021-10-05,2022-01-05" (format: string).
   monthRange: not include the current month, is not required, default is no limit.
   showAllTime: is need to show 'All Time' options, is not required, default is false.
   initOutput:  When component does not use localStorage, needs to return the initial value,  is not required, default is false.
  */
  @Input('showAllTime') public showAllTime: boolean;
  @Input('timeScope') public timeScope: string;
  @Input('monthRange') public monthRange: number;
  @Input('initOutput') public initOutput: boolean;
  @Input('showLast12Month') public showLast12Month: boolean;
  @Input('disableRelativeDates') public disableRelativeDates: boolean = !1;

  @Output('onChangeSelectedDate') public onChangeSelectedDateEventEmitter: EventEmitter<string> =
    new EventEmitter<string>();

  public displayList: SelectItemGroup<string>[];
  public selectedDate: SelectItem<string>;
  public isCustomDateModel: boolean;
  public rangeDates: Date[];
  public minDateValue: Date;
  public maxDateValue: Date;
  public checkCustomRangeCount: number;
  public monthAbbreviation: string[] =
    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  constructor(
    public readonly injector: Injector,
    private readonly customPeriodSelectorService: CustomPeriodSelectorService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    // this.logger.debug('commPeriod ngOnInit', this.disableRelativeDates);
    const source = this.showAllTime
      ? this.customPeriodSelectorService.getTimeScopeGroupListContainsAllTime()
      : this.showLast12Month
        ? this.customPeriodSelectorService.getTimeScopeGroupListContains12Months()
        : this.customPeriodSelectorService.getCommonTimeScopeGroupList();

    source.subscribe((res: SelectItemGroup<string>[]): void => {
      this.displayList = res;
      if (this.disableRelativeDates) {
        this.disableDateRange();
      }
      this.maxDateValue = new Date();
      this.bindSelectedPeriod();
      this.getMinDateRange(this.monthRange);
      this.logger.debug('period ngOnInit:', [
        this.timeScope,
        this.rangeDates,
        this.selectedDate,
        this.monthRange,
        this.checkCustomRangeCount,
      ]);
      // When component does not use localStorage, needs to return the initial value
      if (this.initOutput) this.onPeriodChange();
    });
  }

  public ngOnChanges(changes: Changes): void {
    if (changes.timeScope.firstChange) return;
    this.timeScope = changes.timeScope.currentValue;
    this.bindSelectedPeriod();
    this.getMinDateRange(this.monthRange);
    // this.onPeriodChange();
  }

  private bindSelectedPeriod(): void {
    const getSelectedDate = (e: string): SelectItem<string> => {
      return this.displayList
        .flatMap(v => v.items)
        .find(v => v.value === e);
    };
    this.checkCustomRangeCount = 1;
    if (this.timeScope && this.timeScope.split(',')?.length > 1) {
      this.setCustomRangeTitle(this.reorganizedDateFormat(this.rangeDates)[1]);
      this.rangeDates = [
        new Date(this.timeScope.split(',')[0]),
        new Date(this.timeScope.split(',')[1] || null)
      ];
      this.checkCustomRangeCount = 0;
      this.selectedDate = getSelectedDate('Custom Date Range');
    } else if (this.timeScope && this.timeScope.split(',')?.length === 1) {
      this.selectedDate = getSelectedDate(this.timeScope);
    } else {
      this.selectedDate = getSelectedDate(this.displayList[0].items[0].value);
    }
  }

  public onPeriodChange(e?: string): void {
    this.logger.debug('onPeriodChnage:', [e, this.selectedDate, this.displayList]);
    if (e === 'Custom Date Range') return;
    this.onChangeSelectedDateEventEmitter.emit(this.selectedDate?.value);
  }

  private getMinDateRange(range: number): void {
    if (!range) return void (this.minDateValue = null);
    let yearToDeduct: number = 1;
    if (range > 12) {
      yearToDeduct =
        range % 12 >= this.maxDateValue.getMonth() + 1 ? Math.trunc(range / 12) + 1 : Math.trunc(range / 12);
      range = range % 12;
    }
    // range = range > 12 ? 12 : range;
    const month: number =
      this.maxDateValue.getMonth() - range >= 0
        ? this.maxDateValue.getMonth() + 1 - range
        : 12 - Math.abs(this.maxDateValue.getMonth() + 1 - range);
    const year: number =
      this.maxDateValue.getMonth() - range >= 0 && yearToDeduct === 1
        ? this.maxDateValue.getFullYear()
        : this.maxDateValue.getFullYear() - yearToDeduct;
    let day: number = 1;
    // if (month === 2) {
    //   day = this.maxDateValue.getDate() >= 28 ? 28 : this.maxDateValue.getDate();
    // } else if (month / 2 === 1 || month === 8) {
    //   day = this.maxDateValue.getDate();
    // } else {
    //   day = this.maxDateValue.getDate() >= 30 ? 30 : this.maxDateValue.getDate();
    // }
    this.minDateValue = new Date(`${year}/${Math.abs(month)}/0${day}`);
    this.logger.debug(
      'getMinDateRange:',
      [
        this.minDateValue,
        this.maxDateValue,
        yearToDeduct,
        range,
        this.maxDateValue.getFullYear(),
        this.maxDateValue.getMonth(),
      ],
      'getMinDateRange_bind',
      [year, month, day]
    );
  }

  public checkCustomRangeOption(): void {
    this.logger.debug('checkCustomRangeOption:', [this.selectedDate]);
    if (this.selectedDate.value === 'Custom Date Range') {
      this.checkCustomRangeCount++;
      this.isCustomDateModel = this.checkCustomRangeCount === 2;
    }
    if (this.checkCustomRangeCount === 2) this.checkCustomRangeCount = 0;
    this.logger.debug('chekcCustomRangeOption', [this.displayList, this.checkCustomRangeCount, this.selectedDate]);
  }

  public doneDateRange(): void {
    let customDateQuery: string;
    if (this.rangeDates?.length === 0 || !this.rangeDates) {
      const today = new Date();
      customDateQuery = this.reorganizedDateFormat([today, today])[0];
      const todayTitle: string = this.reorganizedDateFormat([today, today])[1];
      this.setCustomRangeTitle(todayTitle);
      this.isCustomDateModel = false;
      this.logger.debug('todayLabel:', [this.rangeDates, todayTitle, this.displayList]);
    } else {
      customDateQuery = this.reorganizedDateFormat(this.rangeDates)[0];
      const customRangeLabel: string = this.reorganizedDateFormat(this.rangeDates)[1];
      this.setCustomRangeTitle(customRangeLabel);
      this.isCustomDateModel = false;
      this.logger.debug('doneDateRange:', [this.rangeDates, customDateQuery, customRangeLabel, this.displayList]);
    }
    this.onChangeSelectedDateEventEmitter.emit(customDateQuery);
  }

  private setCustomRangeTitle(title: string): void {
    this.displayList.forEach(item => {
      if (item.value !== 'Quarter' || item.items.length <= 0) return;
      item.items.forEach(val => {
        if (val.value === 'Custom Date Range') val.title = title;
      });
    });
  }

  private reorganizedDateFormat(dates: Date[]): string[] {
    let customRange: string[] = [];
    // Custom Range Query Format
    const starDateRange: string = dates[0]
      ? `${dates[0].getFullYear()}-${dates[0].getMonth() + 1 >= 10 ? dates[0].getMonth() + 1 : '0' + String(dates[0].getMonth() + 1)
      }-${dates[0].getDate() >= 10 ? dates[0].getDate() : '0' + String(dates[0].getDate())}`
      : 'null';
    const endDateRange: string = dates[1]
      ? `${dates[1].getFullYear()}-${dates[1].getMonth() + 1 >= 10 ? dates[1].getMonth() + 1 : '0' + String(dates[1].getMonth() + 1)
      }-${dates[1].getDate() >= 10 ? dates[1].getDate() : '0' + String(dates[1].getDate())}`
      : 'null';
    const dateRangeQuery: string = `${starDateRange},${endDateRange !== 'null' ? endDateRange : starDateRange}`;
    customRange.push(dateRangeQuery);
    // Custom Date Range Label
    const startRangeTitle: string = dates[0]
      ? `${this.monthAbbreviation[dates[0].getMonth()]} ${dates[0].getDate()}`
      : 'null';
    const endRangeTitle: string = dates[1]
      ? `${this.monthAbbreviation[dates[1].getMonth()]} ${dates[1].getDate()}`
      : 'null';
    const customRangeLabel: string = `${startRangeTitle} - ${endRangeTitle !== 'null' ? endRangeTitle : startRangeTitle
      }`;
    customRange.push(customRangeLabel);
    return customRange;
  }

  public onClearDate(): void {
    this.rangeDates = null;
  }

  private disableDateRange(): void {
    this.displayList = this.displayList.filter(item => item.value !== 'Relative Dates');
    let index = this.displayList.findIndex(item => item.value === 'Quarter');
    this.displayList[index].items = this.displayList[index].items.filter(item => item.value !== 'Custom Date Range');
    this.logger.debug(['displayList', this.displayList]);
  }
}

interface Changes extends SimpleChanges {
  showAllTime: NgChange<boolean>;
  timeScope: NgChange<string>;
  monthRange: NgChange<number>;
  initOutput: NgChange<boolean>;
  showLast12Month: NgChange<boolean>;
  disableRelativeDates: NgChange<boolean>;
}
