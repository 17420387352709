/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// Angular
import { AfterViewInit, Component, ElementRef, HostListener, Injector, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
// Rxjs
import { Subject } from 'rxjs';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { ColumnFormat, GoogleChartService } from '@base/service';
import { CollectionUtil, CommonUtil } from '@util';
// Store
import { LayoutConfig } from '@Reducers';
// Interface
import { GoogleChartData, GoogleChartOptions } from '@base/model';
import { ChartTypes } from '@core/enum';
import { NgChange } from '@core/model';

@Component({
  selector: 'caloudi-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass'],
})
export class ChartComponent extends BaseComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input('chartType') public _chartType: ChartTypes;
  @Input('data') public _data: GoogleChartData;
  @Input('options') public _options: GoogleChartOptions;
  @Input('columns') public _columns: (number | { [key: string]: number | string; })[];
  @Input('columnFormats') public _columnFormats: ColumnFormat[];

  @HostListener('window:resize', ['$event'])
  public onresize(_event: Event): void {
    this.logActive && this.logger.debug('window resize:', [_event]);
    this.drawChart();
  }

  public options: GoogleChartOptions;
  private readonly chartSub: Subject<LayoutConfig> = new Subject<LayoutConfig>();
  private readonly element: HTMLElement = this.elementRef.nativeElement;
  private readonly logActive: boolean;

  constructor(
    public readonly injector: Injector,
    private readonly chartService: GoogleChartService,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {
    super(injector);
  }

  public ngOnChanges(changes: GoogleChartChanges): void {
    this.logActive && this.logger.debug('any changes:', [changes]);
    if (changes._options?.currentValue) this.options = { ...changes._options.currentValue };
    if (CommonUtil.ngIsChanges(changes._data)) this.drawChart();
  }

  public ngAfterViewInit(): void {
    this.store
      .select(this.selectors.layout)
      .pipe(takeUntil(this.chartSub), skip(1), distinctUntilChanged())
      .subscribe(layout => {
        // if (this.layoutService.isDarkMode === mode) return;
        // if (this._chartType !== ChartTypes.ComboChart && this._chartType !== ChartTypes.PieChart) return;
        this.drawChart();
        this.logActive && this.logger.debug('v:', [this.layoutService.isDarkMode, layout]);
      });
  }

  private drawChart(): void {
    // try {
    const parent = this.element.parentElement;
    if (!parent) return;
    this.logActive && this.logger.debug('drawChart:', [this.layoutService.isDarkMode, parent, this._options]);

    const textColor = this.layoutService.isDarkMode ? 'white' : 'black';
    // const textColor = this._chartType !== ChartTypes.ComboChart && this._chartType !== ChartTypes.PieChart
    //   ? 'var(--text-color)'
    //   : this.layoutService.isDarkMode ? 'white' : 'black';

    this.options = {
      ...this._options,
      width: this._options['width'] || parent.clientWidth,
      height: this._options['height'] || this.getAutoHeight(parent.clientWidth),
      series: this._options['series'] || {
        0: { color: '#3366cc' },
        1: { color: '#dc3912' },
        2: { color: '#ff9900' },
        3: { color: '#109618' },
        4: { color: '#990099' },
        5: { color: '#0099c6' },
        6: { color: '#dd4477' },
        7: { color: '#66aa00' },
        8: { color: '#b82e2e' },
        9: { color: '#316395' },
        10: { color: '#994499' },
        11: { color: '#22aa99' },
        12: { color: '#aaaa11' },
        13: { color: '#6633cc' },
        14: { color: '#e67300' },
        15: { color: '#8b0707' },
        16: { color: '#651067' },
        17: { color: '#329262' },
        18: { color: '#5574a6' },
        19: { color: '#3b3eac' },
        20: { color: '#b77322' },
        21: { color: '#16d620' },
        22: { color: '#b91383' },
        23: { color: '#f4359e' },
        24: { color: '#9c5935' },
        25: { color: '#a9c413' },
        26: { color: '#2a778d' },
        27: { color: '#668d1c' },
        28: { color: '#bea413' },
        29: { color: '#0c5922' },
        30: { color: '#743411' },
      },
      // theme: this._options['theme'] || 'maximized',
      bar: {
        groupWidth: '1rem',
        ...this._options['bar'],
      },
      backgroundColor: {
        fill: 'transparent',
        ...this._options['backgroundColor'],
      },
      chartArea: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        ...this._options['chartArea'],
      },
      legend: {
        // ...typeof this._options['legend'] === 'object' ? this._options['legend'] : {},
        position: this._options['legend']?.position || 'right',
        alignment: this._chartType === ChartTypes.PieChart ? 'start' : 'end',
        ...this._options['legend'],
        textStyle: {
          color: textColor,
          fontSize: '1rem',
          ...this._options['legend']?.textStyle,
        },
      },
      annotations: {
        ...this._options['annotations'],
        textStyle: {
          color: textColor,
          // ...this._options['annotations']?.textStyle,
        },
      },
      tooltip: {
        // trigger: 'selection',
        // isHtml: true,
        showColorCode: true,
        ...this._options['tooltip'],
      },
      vAxis: {
        ...this._options['vAxis'],
        textStyle: {
          color: textColor,
          ...this._options['vAxis']?.textStyle,
        },
        titleTextStyle: {
          color: textColor,
          ...this._options['vAxis']?.titleTextStyle,
        },
      },
      hAxis: {
        ...this._options['hAxis'],
        textStyle: {
          color: textColor,
          ...this._options['hAxis']?.textStyle,
        },
        titleTextStyle: {
          color: textColor,
          ...this._options['hAxis']?.titleTextStyle,
        },
      },
    };

    if (this._chartType === ChartTypes.PieChart)
      this.options['curveType'] = this._options['curveType'] || 'function';


    this.logActive &&
      this.logger.debug(`${this._chartType} draw ${this.layoutService.config.colorScheme}:`, [
        this.element,
        this.options,
        this._data,
        this._columns,
        this._columnFormats,
      ]);
    this.chartService.buildChart(
      this._chartType,
      this.elementRef,
      this._data,
      this.options,
      this._columns,
      this._columnFormats
    );

    document.querySelectorAll('body > div:not([class])')?.forEach(item => item.remove());
    this.logActive &&
      this.logger.debug(this._chartType, 'clientWidth:', parent.clientWidth, 'clientHeight:', parent.clientHeight);
    this.logActive && this.logger.debug(this._chartType, 'width:', this.options.width, 'height:', this.options.height);
    // } catch (error) {
    // this.logActive && this.logger.debug('err:', [error]);
    // }
  }

  public getAutoHeight(parentWidth: number): number {
    if (!this._options.height && CollectionUtil.isKeyIncluded(this._options, ['isStacked', 'is3D']))
      return this._options['isStacked'] || this._options['is3D']
        ? window.innerHeight - parentWidth * 0.3
        : parentWidth * 0.3;
    return this._options.height as number;
  }

  public ngOnDestroy(): void {
    this.chartSub.next(void 0);
    this.chartSub.unsubscribe();
    document.querySelectorAll('body > div:not([class])')?.forEach(item => item.remove());
    this.chartService.killListener();
  }
}

interface GoogleChartChanges extends SimpleChanges {
  _chartType: NgChange<string>;
  _data: NgChange<GoogleChartData>;
  _options: NgChange<GoogleChartOptions>;
  _columns: NgChange<(number | { [key: string]: number | string; })[]>;
  _columnFormats: NgChange<ColumnFormat[]>;
}
