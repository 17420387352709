/* eslint-disable @typescript-eslint/naming-convention */
import { SVGCrowbarPNGOptions, SVGCrowbarPNGSource } from '@base/model';
import { doctype, prefix } from './const';

const getEmptySvgDeclarationComputed: () => CSSStyleDeclaration = (): CSSStyleDeclaration => {
  let emptySvg: SVGElement = document.createElementNS(prefix.svg, 'svg') as SVGElement;
  document.body.appendChild(emptySvg);
  emptySvg.style.all = 'initial';
  const emptySvgDeclarationComputed: CSSStyleDeclaration = getComputedStyle(emptySvg);
  document.body.removeChild(emptySvg);
  emptySvg = null;
  return emptySvgDeclarationComputed;
};

const getSource = (svg: SVGElement, options?: SVGCrowbarPNGOptions): SVGCrowbarPNGSource => {
  console.debug('get source', [svg, options]);
  const _ref: SVGCrowbarPNGOptions = options !== undefined ? options : {};
  const _ref$css: string = _ref.css;
  const css: string = _ref$css === void 0 ? 'inline' : _ref$css;

  if (!(svg instanceof Element)) {
    throw new Error('SVG element is required');
  }

  svg.setAttribute('version', '1.1'); // removing attributes so they aren't doubled up

  svg.removeAttribute('xmlns');
  svg.removeAttribute('xlink'); // These are needed for the svg

  if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns')) {
    svg.setAttributeNS(prefix.xmlns, 'xmlns', prefix.svg);
  }

  if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns:xlink')) {
    svg.setAttributeNS(prefix.xmlns, 'xmlns:xlink', prefix.xlink);
  }

  if (css === 'inline') {
    setInlineStyles(svg, getEmptySvgDeclarationComputed());
  } else if (css === 'internal') {
    setInternalStyles(svg);
  }

  const source: string = new XMLSerializer().serializeToString(svg);
  const rect: DOMRect = svg.getBoundingClientRect();
  const result: SVGCrowbarPNGSource = {
    top: rect.top,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    class: svg.getAttribute('class'),
    id: svg.getAttribute('id'),
    name: svg.getAttribute('name'),
    childElementCount: svg.childElementCount,
    source: doctype + source,
  };
  return result;
};

const setInlineStyles = (svg: Element, emptySvgDeclarationComputed: CSSStyleDeclaration): void => {
  const explicitlySetStyle = (element: Element): void => {
    const cSSStyleDeclarationComputed = getComputedStyle(element);
    let key: string;
    let value: string;
    let computedStyleStr = '';

    for (let _i = 0, len = cSSStyleDeclarationComputed.length; _i < len; _i++) {
      key = cSSStyleDeclarationComputed[_i];
      value = cSSStyleDeclarationComputed.getPropertyValue(key);

      if (value !== emptySvgDeclarationComputed.getPropertyValue(key)) {
        computedStyleStr += ''.concat(key, ':').concat(value, ';');
      }
    }

    element.setAttribute('style', computedStyleStr);
  };

  const traverse = (obj: Element): Element[] => {
    const visit = (node: Element): void => {
      if (node && node.hasChildNodes()) {
        let child: Element = node.firstChild as Element;

        while (child) {
          if (child.nodeType === 1 && child.nodeName !== 'SCRIPT') {
            tree.push(child);
            visit(child);
          }

          child = child.nextSibling as Element;
        }
      }
    };
    const tree: Element[] = [];
    tree.push(obj);
    visit(obj);

    return tree;
  }; // hardcode computed css styles inside svg

  const allElements = traverse(svg);
  let i = allElements.length;

  while (i--) {
    explicitlySetStyle(allElements[i]);
  }
};

const setInternalStyles: (svg: Element) => void = (svg: Element): void => {
  const style: HTMLStyleElement = document.createElement('style');
  style.innerHTML = Array.from(document.styleSheets)
    .filter(styleSheet => {
      return (
        // Prevent CORS errors
        !styleSheet.href || styleSheet.href.startsWith(document.location.origin)
      );
    })
    .map(styleSheet => {
      return Array.from(styleSheet.cssRules)
        .map(rule => {
          return rule.cssText;
        })
        .join(' ');
    })
    .join(' ');
  svg.prepend(style);
};

export default getSource;
