// Interface
import { EntityObject, EntityObjectDisplayItem, PrimeTableColumn } from '@base/model';

/**
 * Entity object converters.
 */
export default class EntityObjectUtil {

  /**
   * Convert EntityObject into EntityObjectDisplayItem.
   * @param obj EntityObject<T>
   * @param displayFields fields to display
   * @returns EntityObjectDisplayItem[]
   */
  public static mapEntityObjectToDisplayItems<T>(obj: EntityObject<T>, displayFields: string[]): EntityObjectDisplayItem[] {
    return displayFields.map(displayField => {
      try {
        return <EntityObjectDisplayItem>{
          field: displayField,
          label: obj.fields.find(entityField => entityField.field === displayField)?.label || displayField,
          value: obj.value[displayField] as string,
        };
      } catch (error: unknown) {
        throw { notFound: displayField, error };
      }
    });
  }

  /**
   * Convert EntityObject into EntityObjectDisplayItem.
   * @param obj EntityObject<T>
   * @param displayFields PrimeTableColumn[], headers to display
   * @returns EntityObjectDisplayItem[]
   */
  public static mapEntityObjectToDisplayHeaders<T>(obj: EntityObject<T>, displayFields: PrimeTableColumn[]): EntityObjectDisplayItem[] {
    let displyLabel: string;
    return displayFields.map(displayField => {
      try {
        obj.fields.forEach(entityField => {
          if (entityField.field === displayField.field) displyLabel = displayField?.header || entityField?.label;
        });
        return <EntityObjectDisplayItem>{
          field: displayField.field,
          label: displyLabel,
          value: obj.value[displayField.field] as string,
        };
      } catch (error: unknown) {
        throw { notFound: displayField, error };
      }
    });
  }
}
