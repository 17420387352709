// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
// Interface
import { AnnouncementType, Announcement, AnnouncementCreate, AnnouncementUpdate, Announcements, DateSelectItem, APPROLEITEM } from '@base/model';
import { UserMessage } from '@core/model';
import { DataTable } from '@base/model';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
  constructor(private readonly http: HttpClient) {}

  public getAnnouncementTypeList(): Observable<AnnouncementType[]> {
    return this.http.get<AnnouncementType[]>(`${environment.cmpAPIServer.url}/api/announcement/dimension/type`);
  }

  public getAppRoleList(): Observable<APPROLEITEM[]> {
    return this.http.get<APPROLEITEM[]>(`${environment.cmpAPIServer.url}/api/announcement/dimension/appRole`);
  }

  public queryAnnouncement(): Observable<DataTable<Announcements>> {
    return this.http.get<DataTable<Announcements>>(`${environment.cmpAPIServer.url}/api/announcement/query`);
  }

  public getAnnouncementById(announcementId: number): Observable<Announcement> {
    return this.http.get<Announcement>(`${environment.cmpAPIServer.url}/api/announcement/${announcementId}`);
  }

  public createAnnouncement(announcementCreate: AnnouncementCreate): Observable<UserMessage> {
    return this.http.put<UserMessage>(`${environment.cmpAPIServer.url}/api/announcement`, announcementCreate);
  }

  public editAnnouncement(announcement: AnnouncementUpdate): Observable<UserMessage> {
    return this.http.post<UserMessage>(`${environment.cmpAPIServer.url}/api/announcement`, announcement);
  }

  public deleteAnnouncement(announcementId: number): Observable<UserMessage> {
    return this.http.delete<UserMessage>(`${environment.cmpAPIServer.url}/api/announcement/${announcementId}`);
  }

  public getWriterList(): Observable<SelectItem<string>[]> {
    return this.http.get<SelectItem<string>[]>(`${environment.cmpAPIServer.url}/api/announcement/dimension/writer`);
  }

  public getAnnouncementMonthList(): Observable<DateSelectItem[]> {
    return this.http.get<DateSelectItem[]>(
      `${environment.cmpAPIServer.url}/api/announcement/dimension/announcementMonth`
    );
  }

  public createAnnouncementType(announcementType: AnnouncementType): Observable<UserMessage> {
    return this.http.put<UserMessage>(`${environment.cmpAPIServer.url}/api/announcement/type`, announcementType);
  }

  public updateAnnouncementType(announcementType: AnnouncementType): Observable<UserMessage> {
    return this.http.post<UserMessage>(`${environment.cmpAPIServer.url}/api/announcement/type`, announcementType);
  }

  public deleteAnnouncementType(announcementId: string): Observable<UserMessage> {
    return this.http.delete<UserMessage>(`${environment.cmpAPIServer.url}/api/announcement/type/${announcementId}`);
  }

  // user-announcement

  public getAnnouncementData(): Observable<Announcement[]> {
    return this.http.get<Announcement[]>(`${environment.cmpAPIServer.url}/api/announcement/bulletinBoard`);
  }

  public getFirstAnnouncementData(): Observable<Announcement> {
    return this.http.get<Announcement>(`${environment.cmpAPIServer.url}/api/announcement/bulletinBoard/first`);
  }
}
