import { GlobalMsgAction } from '@Actions';
import { MessageSeverity } from '@core/enum';
import { createReducer, on } from '@ngrx/store';

// Defining the state shape
export interface MessageState {
  forceProduction?: boolean;
  message?: ErrorMessage | string;
  msgDate?: string;
  severity: MessageSeverity;
  summary: string;
}

interface ErrorMessage {
  errors: Record<string, unknown>;
  status: number;
  title: string;
  traceId: string;
  type: string;
}

// Setting the initial state
export const initialMsgState: MessageState = {
  forceProduction: false,
  message: undefined,
  msgDate: new Date().toJSON(),
  severity: undefined,
  summary: undefined,
};

export const messageReducer = createReducer(
  initialMsgState,
  on(GlobalMsgAction, (_state, payload: MessageState): MessageState => {
    let message: ErrorMessage | string;
    if (!payload.message)
      switch (payload.severity) {
        case MessageSeverity.INFO:
          message = '';
          break;
        case MessageSeverity.SUCCESS:
          message = 'OK';
          break;
        case MessageSeverity.WARN:
          message = 'Warning';
          break;
        case MessageSeverity.ERROR:
          message = 'Error';
          break;
        default:
          message = undefined;
          break;
      }
    else message = payload.message;

    return {
      forceProduction: payload.forceProduction,
      message: message,
      msgDate: payload.msgDate,
      severity: payload.severity,
      summary: payload.summary,
    };
  })
);
