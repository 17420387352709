import { Injectable } from '@angular/core';

/**
 * This provide an exclude list of pages.
 * @Author Wei.Lin
 */
@Injectable({ providedIn: 'root' })
export class ExcludeListService {

  /**
   * API of those pages that does not cancel when route changes
   */
  private excludeList: string[] = [
    '/pages/azure-usage/usage-query',
    '/reload',
    '/pages/xcloud-usage/dashboard'
  ];

  /**
   * Pages that does not using block ui
   */
  private readonly unblockList: string[] = [
    '/pages/azure-usage/provision',
    '/pages/admin/azure/data-sync-progress',
    '/pages/agent/azure/saas-subscription',
    '/pages/ysl/tasks',
    '/pages/ysl/runs'
  ];

  /**
   * Exclude path for interceptor
   */
  private readonly excludePath: string[] = [
    'signup',
    'sso',
    'onboard',
  ];

  constructor() { }

  public getResult(payload: string): boolean {
    let result: boolean;
    this.excludeList.forEach(item => {
      const reg = new RegExp(item + '.*');
      // console.log([reg, reg.test(payload)]);
      result = reg.test(payload);
    });
    return result;
  }

  public addToList(payload: string): string[] {
    // console.debug('addToList:', [this.excludeList, payload]);
    if (this.excludeList.some(item => item === payload)) return this.excludeList;
    else return (this.excludeList = [...this.excludeList, payload]);
  }

  public showList(): string[] {
    return this.excludeList;
  }

  public removeFromList(payload: string): string[] {
    // console.debug('removeFromList:', [this.excludeList, payload]);
    return (this.excludeList = this.excludeList.filter(item => item !== payload));
  }

  public getUnblockList(): string[] {
    return this.unblockList;
  }

  public getUnblockPath(): string[] {
    return this.excludePath;
  }
}
