<prime-dialog
  *ngIf="dialogVisible"
  [(visible)]="dialogVisible"
  (visibleChange)="displaySendReportDialog.emit(false)"
  [header]="'ACTION.SEND_REPORT' | translate">
  <form id="email_report" (ngSubmit)="sendReport()" [formGroup]="reportDeliveryForm">
    <ul>
      <li>
        <label for="mail_ist">{{ 'LABEL.EMAIL_RECIPIENTS' | translate }}:</label>
        <p-chips
          required
          id="mail_list"
          promptLabel="Mail list"
          [styleClass]="'w-100'"
          [separator]="','"
          [addOnTab]="true"
          [addOnBlur]="true"
          [formControlName]="'mailList'"></p-chips>
        <div></div>
        <span>({{ 'HINT.EMAIL_RECIPIENTS_HINT' | translate }})</span>
        <div></div>
        <ng-container
          *ngIf="(formControls.mailList.invalid || formControls.mailList.dirty) && formControls.mailList.errors">
          <p-messages
            *ngIf="formControls.mailList.errors.required"
            [value]="[{ severity: 'error', detail: 'mail address is required' }]"></p-messages>
          <p-messages
            *ngIf="formControls.mailList.errors.invalidEmail"
            [value]="[{ severity: 'error', detail: 'mail format invalid' }]"></p-messages>
        </ng-container>
      </li>
      <li>
        <label for="mail_title">{{ 'REPORT.TITLE' | translate }}:</label>
        <input pInputText type="text" id="mail_title" promptLabel="Title" formControlName="mailTitle" />
      </li>
      <li>
        <label for="mail_note">{{ 'REPORT.NOTE' | translate }}:</label>
        <input pInputText type="text" id="mail_note" promptLabel="Note" formControlName="mailNote" />
      </li>
    </ul>
  </form>

  <button
    pButton
    aria-label="Button"
    footer
    type="button"
    [label]="'ACTION.SEND_NOW' | translate"
    [disabled]="reportDeliveryForm.invalid"
    (click)="sendReport()"></button>
</prime-dialog>

<prime-dialog
  *ngIf="displayConfirmDialog"
  icon="pi pi-exclamation-triangle"
  iconColor="#e74747"
  [(visible)]="displayConfirmDialog"
  [header]="'ACTION.SEND_REPORT_BY_EMAIL' | translate">
  <dl class="confirmation_list">
    <dt>{{ 'REPORT.EMAIL_LIST' | translate }}:</dt>
    <dd>
      <ng-container *ngFor="let mail of formControls.mailList.value">
        <span>{{ mail }}</span>
      </ng-container>
    </dd>
    <dt>{{ 'REPORT.TITLE' | translate }}:</dt>
    <dd [ngClass]="{ 'text-muted': !formControls.mailTitle.value }">
      {{ formControls.mailTitle.value || 'REPORT.UNTITLED' | translate }}
    </dd>

    <dt>{{ 'REPORT.NOTE' | translate }}:</dt>
    <dd [ngClass]="{ 'text-muted': !formControls.mailNote.value }">
      {{ formControls.mailNote.value }}
    </dd>
  </dl>

  <ng-container footer>
    <button
      pButton
      aria-label="Button"
      type="button"
      [severity]="'danger'"
      [faIcon]="fas.faCheck"
      [label]="'COMMON.YES' | translate"
      (click)="sendAccept()"></button>
    <button
      pButton
      aria-label="Button"
      type="button"
      [faIcon]="fas.faXmark"
      [label]="'COMMON.NO' | translate"
      (click)="sendReject()"></button>
  </ng-container>
</prime-dialog>
