// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import { LogMessage } from '@core/model';
import environment from '@env';

/**
 * NG Log Management For Back-End API
 *
 * @author Tony.su
 *
 */
@Injectable({ providedIn: 'root' })
export class NgLogService {
  constructor(private readonly http: HttpClient) {}

  public postLogMsg(ngLogPost: LogMessage): Observable<string> {
    return this.http.post<string>(`${environment.apiServer.url}/api/ng/log`, ngLogPost);
  }
}
