// Caloudi
import CollectionUtil from './collection.util';
// Interface
import { TabMenuBreadcrumb } from '@base/model';
import { ICONTYPE } from '@core/enum';
import { FontawesomeBrands, FontawesomeIcons } from '@core/model';
import { Breadcrumb } from '../model/breadcrumb';
import { UserMenu, UserMenuItem } from '../model/response/auth';

/**
 * Util for layout menu.
 */
export default class MenuUtil {

  /**
   * Collection all MenuItem
   * @param userMenu UserMenu Object
   * @returns all MenuItem
   */
  public static getAllMenuItemsFromUserMenu(userMenu: UserMenu): UserMenuItem[] {
    const menuItems: UserMenuItem[] = [];
    if (CollectionUtil.isNotEmpty(userMenu.mainMenu))
      userMenu.mainMenu.forEach(item => menuItems.push(item));
    if (CollectionUtil.isNotEmpty(userMenu.top1Menu))
      userMenu.top1Menu.forEach(item => menuItems.push(item));
    if (CollectionUtil.isNotEmpty(userMenu.top2Menu))
      userMenu.top2Menu.forEach(item => menuItems.push(item));
    if (CollectionUtil.isNotEmpty(userMenu.top3Menu))
      userMenu.top3Menu.forEach(item => menuItems.push(item));
    if (CollectionUtil.isNotEmpty(userMenu.loginUserMenu))
      userMenu.loginUserMenu.forEach(item => menuItems.push(item));
    if (CollectionUtil.isNotEmpty(userMenu.hiddenMenu))
      userMenu.hiddenMenu.forEach(item => menuItems.push(item));
    return menuItems;
  }

  /**
   * Convert UserMenuItem into Breadcrumb.
   * @param items UserMenuItem[]
   * @returns Breadcrumb[]
   */
  public static constructBreadcrumb(items: UserMenuItem[]): Breadcrumb[] {
    let result: Breadcrumb[] = [];
    if (items !== undefined) items.forEach(val => {
      if (val.items !== undefined) {
        let tmp = this.constructBreadcrumb(val.items);
        if (tmp.length > 0) {
          tmp.forEach(tmpItem => {
            tmpItem.menuItemList.unshift(val);
            result.push(tmpItem);
          });
        }
      }
      if (val['routerlink'] !== undefined) {
        result.push({
          routeLink: val['routerlink'] as string,
          menuItemList: new Array<UserMenuItem>(val),
        });
      }
    });
    return result;
  }

  /**
   * Check if routerlink exists.
   * @param routeLink router link
   * @returns does routerlink exist
   */
  public static isRouterLink(breadcrumbs: Breadcrumb[], routeLink: string): boolean {
    try {
      return breadcrumbs.some(item => new RegExp(item.routeLink).test(routeLink));
    } catch (error) { return false; }
  }

  /**
   * Get current tab key from store.
   * @param tabMenuBreadcrumb all tab menu routes by key
   * @param routeLink current route link
   * @returns tab key
   */
  public static getTabMenuCurrentTab(tabMenuBreadcrumb: TabMenuBreadcrumb, routeLink: string): string {
    try {
      const keys = Object.keys(tabMenuBreadcrumb || {});
      return keys.find(key => tabMenuBreadcrumb[key].some(tabRoute => tabRoute === routeLink));
    } catch (error) { return void 0; }
  }

  /**
   * Get current breadcrumb.
   * @param breadcrumbs breadcrumb items
   * @param routeLink link matcher
   * @returns Breadcrumb result
   */
  public static getBreadcrumb(breadcrumbs: Breadcrumb[], routeLink: string): Breadcrumb {
    const result: Breadcrumb = breadcrumbs?.find(breadcrumb =>
      new RegExp(`^${breadcrumb.routeLink}`, 'i').test(routeLink));
    if (!breadcrumbs || !result) return void 0;
    return result && { routeLink, menuItemList: result.menuItemList };
  }

  /**
   * Check icon type is primeng or fontawesome.
   * @param icon icon string
   * @returns ICONTYPE
   */
  public static iconVer(icon: string): ICONTYPE {
    try {
      // console.debug('icon:', [e, new RegExp(/^fa[s|b]\s/).test(e), e.split(/\s/)[0].toLowerCase() === 'pi']);
      if (icon?.split(/\s/)[0].toLowerCase() as ICONTYPE === ICONTYPE.PI) return ICONTYPE.PI;
      else if (new RegExp(/^fas\s/).test(icon)) return ICONTYPE.FAS;
      else if (new RegExp(/^fab\s/).test(icon)) return ICONTYPE.FAB;
      else return void 0;
    } catch (error) { return void 0; }
  }

  /**
   * Convert icon string into fontawesome key.
   * @param icon icon string
   * @returns keyof FontawesomeBrands or keyof FontawesomeIcons
   */
  public static faIconConvert(icon: string): keyof FontawesomeBrands | keyof FontawesomeIcons {
    try {
      return icon.slice(4) as keyof FontawesomeBrands | keyof FontawesomeIcons;
    } catch (error) { return 'faCircleXmark'; }
  }
}
