/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// Angular
import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { CommonUtil } from '@util';
// Interface
import { ParkingSchedule } from '@base/model';
import { NgChange } from '@core/model';

@Component({
  selector: 'caloudi-parking-schedule-editor',
  templateUrl: './parking-schedule-editor.component.html',
  styleUrls: ['./parking-schedule-editor.component.sass'],
})
export class ParkingScheduleEditorComponent extends BaseComponent implements OnChanges {
  @HostListener('mousedown', ['$event'])
  public onMouseDown(ev: MouseEvent): void {
    this.cellReadonly ? void 0 : this.startFunc(ev);
  }

  @HostListener('mouseup', ['$event'])
  public onMouseUp(ev: MouseEvent): void {
    this.cellReadonly ? void 0 : this.endFunc(ev);
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(ev: MouseEvent): void {
    this.cellReadonly ? void 0 : this.moveFunc(ev);
  }

  @ViewChild('tbody') private readonly _tableBody: ElementRef<HTMLTableSectionElement>;

  @Input('debug') public debug: boolean;
  @Input('readonly') public cellReadonly: boolean;
  @Input('label') public displayLabel: boolean;
  @Input('animated') public cellAnimated: boolean;
  @Input('undo') public enableUndo: boolean;
  @Input('padding') public _padding: number;
  @Input('rowTitle') public rowTitleList: ParkingSchedule['Title'][];
  @Input('colTitle') public colTitleList: ParkingSchedule['Title'][];
  @Input('data') public _tableData: ParkingSchedule['DataTypes'][][];
  @Input('debug') public logActive: boolean;

  @Output('dataChange') public tableDataOutput = new EventEmitter();
  // @Output('allCells') public allCells = new EventEmitter<ParkingSchedule['CellStatus'][][]>();
  // @Output('selectedCells') public selectedCells = new EventEmitter<ParkingSchedule['Coord'][]>();
  // @Output('allData') public allData = new EventEmitter<ParkingSchedule['TableData'][][]>();
  // @Output('selectedData') public selectedData = new EventEmitter<ParkingSchedule['TableData'][]>();

  public currentTableData: ParkingSchedule['TableData'][][];
  public currentState: ParkingSchedule['DataTypes'][][];
  public previousState: ParkingSchedule['DataTypes'][][][];
  public originState: ParkingSchedule['DataTypes'][][];
  public get padding(): string {
    return String(this._padding || 0.125) + 'rem';
  }

  private get dialog(): HTMLElement {
    return this._tableBody.nativeElement.closest('.p-dialog');
  }

  private get tableOffsetX(): number {
    return this.dialog?.offsetLeft || 0;
  }

  private get tableOffsetY(): number {
    return this.dialog?.getBoundingClientRect().top + window.scrollY || 0;
    // return this.dialog?.offsetTop + document.querySelector('div.layout-topbar').clientHeight || 0;
  }

  private get cells(): HTMLDivElement[][] {
    return (<HTMLTableRowElement[]>Array.from(this._tableBody.nativeElement.children))
      .map(c1 => <HTMLTableCellElement[]>Array.from(c1.children))
      .map(c2 => c2.flatMap(c3 => c3.firstElementChild as HTMLDivElement).filter(c4 => c4.classList.contains('cell')));
  }

  public get tableData(): ParkingSchedule['TableData'][][] {
    return [...this._tableData].map((colData, colIndex) =>
      colData.map((rowData, rowIndex): ParkingSchedule['TableData'] => ({
        coord: { x: rowIndex + 1, y: colIndex + 1 },
        data: rowData,
        el: (this.cellData || this.cells)[colIndex][rowIndex],
      })));
  }
  // private get selectedTableData(): ParkingSchedule['TableData'][] {
  //   return this.tableData.flat()
  //     .filter(data => data.data)
  //     .map(data => ({ data: data.data, el: data.el } as ParkingSchedule['TableData']));
  // }
  // public get allCellStatus(): ParkingSchedule['CellStatus'][][] {
  //   return this.cells.map(colData => colData
  //     .map((cell: HTMLDivElement): ParkingSchedule['CellStatus'] => ({
  //       x: Number(cell.attributes.getNamedItem('colIndex').value),
  //       y: Number(cell.attributes.getNamedItem('rowIndex').value),
  //       active: cell.classList.contains('active'),
  //     })));
  // }
  // public get selectedCellStatus(): ParkingSchedule['Coord'][] {
  //   return [...this.allCellStatus].flatMap(colData => colData
  //     .filter((res): boolean => res.active)
  //     .map((cell): ParkingSchedule['Coord'] => ({ x: cell.x, y: cell.y })));
  // }

  public onSelect: boolean;
  public selectionArea: ParkingSchedule['SelectionArea'];
  public currentCoord: ParkingSchedule['Coord'];
  public start: MouseEvent;

  private cellData: HTMLDivElement[][];

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  private arrayScan(
    origin: ParkingSchedule['DataTypes'][][],
    func: (
      rowIndex: number,
      colIndex: number,
      rowData?: ParkingSchedule['DataTypes'],
      colData?: ParkingSchedule['DataTypes'][]
    ) => void
  ): void {
    origin.forEach((colData, colIndex) =>
      colData.forEach((rowData, rowIndex) => func(rowIndex, colIndex, rowData, colData)));
  }

  public ngOnChanges(changes: Changes): void {
    try {
      this.logActive && this.logger.debug('changes:', [changes]);

      const data = [...changes._tableData.currentValue];
      const colTitles = changes.colTitleList?.currentValue;
      const rowTitles = changes.rowTitleList?.currentValue;

      if (data.length <= 0) {
        this._tableData = this.currentState;
        this.arrayScan(this.currentState || [[]], (rowIndex, colIndex) =>
          this.tableData[colIndex][rowIndex].el.classList.remove('active'));
        return;
      }

      // Auto set to readonly
      if (typeof data[0][0] === 'number' && typeof this.cellReadonly !== 'boolean') this.cellReadonly = true;

      // Data Reset
      if (
        !changes._tableData.firstChange &&
        (data !== this.originState || !CommonUtil.ngIsChanges(changes._tableData))
      ) {
        this.arrayScan(this._tableData, (rowIndex, colIndex) =>
          this.tableData[colIndex][rowIndex].el.classList.remove('active'));
        this.bindInitData();
      }

      // Table Init
      if (changes._tableData.firstChange) {
        setTimeout(() => {
          this.bindInitData();
        });

        if (!colTitles)
          this.colTitleList = [...(colTitles || data[0])].map((_item, i): ParkingSchedule['Title'] => ({
            label: String(i),
          }));

        if (!rowTitles)
          this.rowTitleList = [...(rowTitles || data)].map((_item, i): ParkingSchedule['Title'] => ({
            label: String(i),
          }));
      }
    } catch (error) {
      this.logger.error('Parking Schedule Error', error);
    }
  }

  private bindInitData(): void {
    const time = Date.now();
    this.logActive && this.logger.debug('init');

    this.arrayScan(this._tableData, (rowIndex, colIndex, rowData) => {
      if (rowData || rowData?.['data']) this.tableData[colIndex][rowIndex].el.classList.add('active');
    });

    this.currentTableData = this.tableData;
    this.currentState = this._tableData;
    this.originState = this._tableData;
    this.cellData = this.cells;
    this.previousState = [];
    this.logActive && this.logger.debug('done.', (Date.now() - time) / 1000);
  }

  private startFunc(ev: MouseEvent): void {
    this.onSelect = true;
    this.selectionArea = {
      startx: ev.clientX - this.tableOffsetX,
      starty: ev.pageY - this.tableOffsetY,
      endx: ev.screenX - this.tableOffsetX,
      endy: ev.pageY - this.tableOffsetY,
    };
    this.start = ev;
    this.logActive && this.logger.debug('event:', [ev, this.dialog]);
    this.logActive &&
      this.logger.debug('offsety:', [this.tableOffsetY, document.querySelector('div.layout-topbar').clientHeight]);
  }

  private endFunc(ev: MouseEvent): void {
    this.onSelect = false;
    // this.logger.debug('event:', [ev.target]);
    if (this.previousState.length > 5) this.previousState.shift();
    if ((<HTMLElement>ev.target).classList.contains('cell')) this.previousState.push(this.currentState);
    if (!this.start || !ev) return;
    // if (!(<HTMLElement>ev.target).classList.contains('cell')) return;
    const [startX, startY, endX, endY] = [this.start.clientX, this.start.clientY, ev.clientX, ev.clientY];

    // Toggle Status
    const selectedCells = this.currentTableData.map(colData =>
      colData.filter(cell => {
        const bounding: DOMRect = cell.el.getBoundingClientRect();
        return (
          bounding.left >= (startX <= endX ? startX : endX) - bounding.width &&
          bounding.right <= (startX <= endX ? endX : startX) + bounding.width &&
          bounding.top >= (startY <= endY ? startY : endY) - bounding.height &&
          bounding.bottom <= (startY <= endY ? endY : startY) + bounding.height
        );
      }));
    selectedCells.forEach(colData =>
      colData.forEach(rowData => {
        const cList = rowData.el.classList;
        if (typeof rowData.data === 'boolean') {
          rowData.data = !rowData.data;
          cList.toggle('active');
        } else if (typeof rowData.data === 'number') {
          rowData.data = rowData.data < 5 ? (rowData.data += 1) : 0;
          rowData.data > 0 ? cList.add('active') : cList.remove('active');
        }
      }));
    const current = this.getDataState();
    this.currentState = current;
    if (CommonUtil.equals(this.currentState, this.previousState[this.previousState.length - 1])) return;

    // Render Output Data
    // this.allCells.emit(this.allCellStatus);
    // this.selectedCells.emit(this.selectedCellStatus);
    // this.allData.emit(this.tableData);
    // this.selectedData.emit(this.selectedTableData);
    this.tableDataOutput.emit(current);

    this.logActive &&
      this.logger.debug('end:', [
        `start: ${startX}x${startY}, end: ${endX}x${endY}`,
        'data:',
        [this.start, ev],
        'table cells:',
        [this.cells, selectedCells],
        'table data:',
        [this.tableData],
      ]);
  }

  private moveFunc(ev: MouseEvent): void {
    this.currentCoord = { x: ev.clientX, y: ev.clientY };
    if (!this.onSelect) return;
    this.selectionArea.endx = ev.clientX - this.tableOffsetX;
    this.selectionArea.endy = ev.clientY - this.tableOffsetY;
    this.logActive && this.logger.debug('mouse move:', [ev]);
  }

  private getDataState(): ParkingSchedule['DataTypes'][][] {
    return [...this.currentTableData].map(colData =>
      colData.map(rowData => rowData.data as ParkingSchedule['DataTypes']));
  }

  public calcPoint(start: number, end: number): number {
    return start >= end ? end : start;
  }

  public calcArea(start: number, end: number): number {
    return end - start < 0 ? (end - start) * -1 : end - start;
  }

  public undoLast(): void {
    this.logger.debug('pre:', [this.previousState]);
    if (this.previousState.length === 1) return;
    this.arrayScan(this._tableData, (rowIndex, colIndex) =>
      this.tableData[colIndex][rowIndex].el.classList.remove('active'));

    this.arrayScan(this._tableData, (rowIndex, colIndex, rowData) => {
      if (rowData || rowData?.['data']) this.tableData[colIndex][rowIndex].el.classList.add('active');
    });
    // this.currentTableData.forEach(colData => colData.forEach(rowData => {
    //   const cList = rowData.el.classList;
    //   if (typeof rowData.data === 'boolean') {
    //     rowData.data = !rowData.data;
    //     cList.toggle('active');
    //   }
    //   else if (typeof rowData.data === 'number') {
    //     rowData.data = rowData.data < 5 ? rowData.data += 1 : 0;
    //     rowData.data > 0 ? cList.add('active') : cList.remove('active');
    //   }
    // }));
  }
}

interface Changes extends SimpleChanges {
  _tableData: NgChange<ParkingSchedule['ParkingScheduleData'][][]>;
  _padding: NgChange<number>;
  debug: NgChange<boolean>;
  rowTitleList: NgChange<ParkingSchedule['Title'][]>;
  colTitleList: NgChange<ParkingSchedule['Title'][]>;
}
