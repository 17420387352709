// Angular
import { Component, ElementRef, Injector, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
// import { CollectionUtil } from '@util';
// Interface
import { D3DragEvent, SimLink, SimNode, TopologyGraph } from '@base/model';
import { NgChange } from '@core/model';
// D3
import * as d3 from 'd3';

@Component({
  selector: 'caloudi-d3-force-directed-graph',
  templateUrl: './force-directed-graph.component.html',
  styleUrls: ['./force-directed-graph.component.sass'],
})
export class ForceDirectedGraphComponent extends BaseComponent implements OnChanges {
  @Input('data') public _data: TopologyGraph;

  @ViewChild('chart') private readonly ele: ElementRef<HTMLDivElement>;

  // private svg: d3.Selection<SVGSVGElement, unknown, HTMLDivElement, TopologyGraph>;
  private data: TopologyGraph;
  private width: number;
  private height: number;
  private radius: number;
  private readonly margin: number = 10;

  private readonly logActive: boolean = !1;

  constructor(public readonly injector: Injector) {
    super(injector);

    window.onresize = (): void => {
      this.resize();
      this.createChart();
    };
  }

  public ngOnChanges(changes: Changes): void {
    if (!changes._data?.currentValue || !this.ele?.nativeElement) return;
    this._data.nodes.forEach(
      node =>
      (node.children = this._data.nodes.filter(n =>
        this._data.links.filter(link => link.target.id === node.id).some(link => link.source.id === n.id)))
    );
    this.data = {
      links: [...this._data.links],
      nodes: [...this._data.nodes],
    };
    this.resize();
    this.logActive && this.logger.debug('changes:', [changes]);
    this.createChart();
  }

  private resize(): void {
    if (!this.ele.nativeElement) return;
    this.width = this.ele.nativeElement.clientWidth;
    this.height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.75;
    this.radius ??= 20;
    this.logActive && this.logger.debug('size:', [this.ele, { w: this.width, h: this.height, r: this.radius }]);
  }

  private createChart(): void {
    if (!this.ele.nativeElement) return;
    const viewBox = [-this.width / 2, -this.height / 2, this.width, this.height];
    const color: d3.ScaleOrdinal<string, string> = d3.scaleOrdinal(d3.schemeCategory10);

    // let nodes = CollectionUtil.deduplicateArray(this.data.nodes, { key: 'group' });
    // let links = CollectionUtil.deduplicateArray(this.data.links, { key: 'target[id]' });

    const { nodes, links } = this.prepareData();

    this.logger.debug('filtered data:', [nodes, links]);

    const ticked = (): void => {
      // For SVG Element
      // node
      //   .attr('cx', d => Math.max(-(this.width - this.margin) / 2, Math.min((this.width - this.margin) / 2, d.x)))
      //   .attr('cy', d => Math.max(-(this.height - this.margin) / 2, Math.min((this.height - this.margin) / 2, d.y)));

      // For Html Element
      node.attr('transform', d => {
        d.x = Math.max(-(this.width - this.margin) / 2, Math.min((this.width - this.margin) / 2, d.x));
        d.y = Math.max(-(this.height - this.margin) / 2, Math.min((this.height - this.margin) / 2, d.y));
        return `translate(${d.x}, ${d.y})`;
      });

      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);
    };

    const simulation: d3.Simulation<SimNode, SimLink> = d3
      .forceSimulation<SimNode>(nodes)
      .force('charge', d3.forceManyBody<SimNode>())
      .force(
        'link',
        d3
          .forceLink<SimNode, SimLink>(links)
          .id(d => d.id)
          .distance(d => d.strength * 100)
      )
      // .force('x', d3.forceCenter().strength(0.02))
      // .force('y', d3.forceCenter().strength(0.02))
      .force('x', d3.forceX())
      .force('y', d3.forceY())
      .force(
        'collision',
        d3.forceCollide(d => d.level * 2 + 16)
      )
      .on('tick', ticked)
      .alphaDecay(1 - Math.pow(0.1, 1 / 100));

    this.logActive && this.logger.debug('viewBox:', [this.width, this.height], viewBox);
    this.logActive && this.logger.debug('dots:', [{ ...this.data }, nodes, links]);

    d3.select(this.ele.nativeElement).selectAll('*').remove();

    const svg = d3
      .select(this.ele.nativeElement)
      .append('svg')
      .attr('viewBox', viewBox)
      .attr('width', this.width)
      .attr('height', this.height)
      .on('dblclick', (event: PointerEvent): void => {
        this.logger.debug('dblclick:', [event]);
        if (event.target !== this.ele.nativeElement.firstElementChild) return;
        this.data = {
          links: [...this._data.links],
          nodes: this._data.nodes.map(node => {
            node.fx = null;
            node.fy = null;
            return node;
          }),
        };
        this.createChart();
      });

    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.width - this.margin)
      .attr('height', this.height - this.margin)
      .attr('x', -(this.width - this.margin) / 2)
      .attr('y', -(this.height - this.margin) / 2);

    const link = svg
      .append('g')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6)
      .attr('stroke-width', 1.5)
      .attr('stroke-linecap', 'round')
      .selectAll('line')
      .data(links)
      .join('line')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6);

    const node = svg
      .append('g')
      .attr('fill', '#fff')
      .attr('stroke', '#000')
      .attr('stroke-width', '1px')
      .selectAll('g')
      .data(nodes, (d: SimNode) => d.id)
      .join('g')
      .attr('class', 'circle_item')
      // .on('dblclick', (event: D3DragEvent, d: SimNode): void => {
      //   // this.logger.debug(`dblclick:`, [event, d]);
      //   if (!event.active) simulation.alphaTarget(0);
      //   d.fx = null;
      //   d.fy = null;
      // })
      .on('click', (event: PointerEvent, d: SimNode): void => {
        if (d.children?.length <= 0) return;
        this.logger.debug('children:', [event, d, d.children]);
        this.data.nodes = d.children;
        this.data.nodes.unshift(d);
        this.data.links = this._data.links.filter(link => this.data.nodes.some(node => link.target.id === node.id));
        this.createChart();
      })
      .call(
        d3
          .drag<SVGGElement, SimNode, SimNode>()
          .on('start', (event: D3DragEvent, d: SimNode): void => {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
          })
          .on('drag', (event: D3DragEvent, d: SimNode): void => {
            d.fx = event.x;
            d.fy = event.y;
          })
          .on('end', (event: D3DragEvent, d: SimNode): void => {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = d.x;
            d.fy = d.y;
          })
      );

    node
      .append('circle')
      .attr('fill', d => color(d.group))
      .attr('r', d => d.level * 2 + 8);

    // node.append('polygon')
    //   .attr('points', '14,6 10,12 4,12 0,6 4,0 10,0 14, 6')
    //   .attr('fill', d => color(d.group))
    //   .attr('width', 16)
    //   .attr('height', 16);

    // node.append('rect')
    //   .attr('fill', d => color(d.group))
    //   .attr('stroke', d => d.children ? null : '#fff')
    //   .attr('r', 8)
    //   .attr('width', 16)
    //   .attr('height', 16)
    //   .attr('xlink:href', d => `https://portal.azure.com/#resource${d.id}`);

    // node.append('image')
    //   .attr('xlink:href', d => (`/assets/images/topology/${d.group}.svg`))
    //   .attr('width', 32)
    //   .attr('height', 32)
    //   .attr('x', - 16)
    //   .attr('y', - 16)
    //   .attr('fill', d => color(d.group));

    node
      .append('text')
      .attr('font-size', '.8rem')
      .attr('dx', 8)
      .attr('dy', '.35rem')
      .attr('x', 8)
      .attr('color', 'var(--text-color)')
      .attr('style', '-webkit-user-select: none; user-select: none;')
      .text(d => (d.children?.length > 1 ? '...' : d.label));

    // node.append('title').text(d => d.label);

    // simulation.nodes(nodes);
    // simulation.force<d3.ForceLink<SimNode, SimLink>>('link').links(links);
    simulation.alpha(1).restart();
    // simulation.on('tick', () => ticked());
  }

  private prepareData(): ChartData {
    // this.data.nodes.forEach(node => node.children = this.data.nodes.filter(n =>
    //   this.data.links
    //     .filter(link => link.target.id === node.id).length > 1 &&
    //   this.data.links
    //     .filter(link => link.target.id === node.id)
    //     .some(link => link.source.id === n.id)
    // ));

    const nodes: SimNode[] = [
      // Orphan nodes
      ...this.data.nodes.filter(
        node =>
          !this.data.links.some(link => link.target.id === node.id) &&
          !this.data.links.some(link => link.source.id === node.id)
      ),
      // Non-orphan nodes reduced
      ...this.data.nodes.filter(node =>
        this.data.links.some(({ target, source }) => target.id === node.id || (!target.id && !source.id))),
      // Non-orphan nodes reduced renew
      // ...this.data.nodes.flatMap(node => this._data.nodes.filter(_n =>
      //   node.children?.length === 1 && node.children[0].children?.length > 0
      // ))

      // ...this.data.nodes.filter(node =>
      //   this.data.links.some(link => link.target.id === node.id)
      //   && this.data.links.some(link => link.source.id === node.id))
    ];

    const links: SimLink[] = this.data.links.filter(link => nodes.some(node => link.source.id === node.id));

    return { nodes, links };
  }
}

interface Changes extends SimpleChanges {
  _data: NgChange<TopologyGraph>;
  radius: NgChange<number>;
}

interface ChartData {
  nodes: SimNode[];
  links: SimLink[];
}
