import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    LABEL: {
      ABNORMAL_DETECTION: '異常偵測',
      ACCESSIBLE_RESOURCES: '可存取資源',
      ACCESS_ACTIVITIES: '存取活動記錄',
      ACCESS_CONTROL: '存取控制',
      ACCESS_GROUP: '存取群組',
      ACCESS_HOSTS: '存取主機',
      ACCESS_MEMBERS: '存取成員',
      ACCOUNT: '帳戶',
      ACCOUNT_BALANCE: '帳戶餘額',
      ACCOUNT_HOLDER_TYPE: '帳戶持有人類型',
      ACCOUNT_NAME: '帳戶名稱',
      ACTION: '動作',
      ACTIONS: '動作',
      ACTIVATE: '啟用',
      ACTIVATE_SUBSCRIPTION: '啟用訂閱',
      ACTIVE: '已啟用',
      ADD: '新增',
      ADDITIONAL_EMAIL: '其他電子郵件',
      ADDRESS: '地址',
      ADDRESS_LINE_1: '地址一',
      ADDRESS_LINE_2: '地址二',
      ADD_NEW_CUSTOMER: '新增客戶',
      ADD_ON_SUBSCRIPTION: '附加訂閱',
      ADMINISTER_SERVICES: '管理服務',
      ADMIN_USERS: '管理者',
      ADMIN_USER_ASSIGNMENTS: '管理者指派',
      ADVISOR_CATEGORY: '建議類型',
      AGREEMENT_HAS_ACCEPTED: '客戶已接受最新的 Microsoft 客戶合約',
      AGREEMENT_TYPE: '合約類型',
      AGREEMENT_WILL_BE_ASKED: '客戶必須接受 Microsoft 365 管理中心內的 Microsoft 客戶合約',
      ALERT_RECIPIENTS: '通知接收者',
      ALL: '全部',
      AMORTIZED_RI_COST: 'RI 攤銷成本',
      ANNOUNCEMENT: '公告',
      ANNOUNCEMENT_TIME: '公告時間',
      ANNOUNCEMENT_TYPE: '公告類型',
      ANNUAL: '年度',
      API_ACCESS_CONFIG: 'API 存取設定',
      API_ACCESS_KEY: 'API 存取金鑰',
      API_SETTING_TEST: 'API 設定測試',
      API_TYPE: 'API 類型',
      APP_ROLE: '應用角色',
      APP_ROLES: '應用角色',
      ASSIGNED: '已指派',
      ASSIGNED_ROLES: '已指派角色',
      ASSIGNED_USERS: '已指派使用者',
      ASSIGNMENTS: '指派',
      ASSIGN_LICENSE: '分配授權',
      AT: '於',
      ATTACHMENTS: '附件',
      ATTENTION: '注意',
      AT_TIME: '於',
      AUTHORIZED_SUBSCRIPTION: '已授權訂閱',
      AVERAGE: '平均',
      AVG_CPU_PERCENT_30_DAYS: '30 天平均 CPU(%)',
      BANK: '銀行',
      BANK_NAME: '銀行名稱',
      BANK_ROUTING: '銀行路由編號',
      BC_CUSTOMER: '客戶結算週期',
      BC_PARTNER: '合作夥伴結算週期',
      BEYOND_DATE: '指定日期警示日',
      BEYOND_DATE_ALERT: '指定日期警示',
      BILLING: '計費',
      BILLING_ACCOUNT: '帳單帳戶',
      BILLING_CURRENCY: '計費幣別',
      BILLING_CYCLE: '結算週期',
      BILLING_PERIOD: '計費期間',
      BILLING_SCHEDULE: '計費排程',
      BY_FOR_GROUP: '依據群組',
      CALOUDI_USER: 'Caloudi 使用者',
      CANCEL: '取消',
      CANCEL_ORDER: '取消訂單',
      CC_EMAILS: '副本收件人',
      CHANGE_LANG: '切換語言',
      CHARGE_UNIT: '計價單位',
      CHART_GROUP_BY: '圖表依據分組',
      CITY: '縣市',
      CLEAR: '清除',
      CLEAR_FILTER: '清除篩選條件',
      CLOSE: '關閉',
      CLOUD_USAGE_DATA: '雲端使用數據',
      CLOUD_USAGE_DATA_SYNC_PROGRESS: '雲端使用數據同步進度',
      CLOUD_VENDOR: '雲端供應商',
      COLLAPSE: '收合',
      COMMENT: '評論',
      COMMENTS: '評論',
      COMPANY: '公司',
      COMPANY_COUNTRY: '公司國家',
      COMPANY_ID: '公司 ID',
      COMPANY_NAME: '公司名稱',
      COMPANY_SIZE: '公司規模',
      COMPANY_WEBSITE: '公司網站',
      COMPARISON: '比較',
      CONSTRAINTS: '限制',
      CONSUMED: '已使用',
      CONSUMPTION: '消耗量',
      CONTACT: '聯絡人',
      CONTACT_INFO: '聯絡資訊',
      CONTACT_METHOD: '聯絡方式',
      CONTACT_TYPES: '聯絡類型',
      COPY_TO_CLIPBOARD: '複製到剪貼簿',
      CORE: '核心',
      COST_PERCENTAGE_LIST_PRICE: '成本百分比',
      COST_PRICE: '成本價格',
      COST_WITH_RECOMMENDATION: '建議成本',
      COUNTRY: '國家',
      COUNTS: '數量',
      COVERAGE_RATE: '覆蓋率',
      CREATED: '已建立',
      CREATED_BY: '建立者',
      CREATED_TIME: '建立時間',
      CREATE_DATE: '建立日期',
      CREATE_SALES_ORDER: '建立銷售訂單',
      CREATE_TIME: '建立時間',
      CREATION_TIME: '建立時間',
      CREATOR: '建立者',
      CREDITS: '抵免額',
      CREDIT_CARD: '信用卡',
      CREDIT_CARD_HOLDER_NAME: '信用卡持卡人名稱',
      CREDIT_CARD_NUMBER: '信用卡號碼',
      CREDIT_CARD_TYPE: '信用卡類型',
      CREDIT_LIMIT: '信用額度',
      CRON_LANG: 'zh_TW',
      CUD_RECOMMENDATION: 'CUD 建議',
      CURRENT: '當前',
      CURRENT_COST: '當前成本',
      CURRENT_QTY: '當前數量',
      CURRENT_SKU: '當前 SKU',
      CUSTOMER: '客戶',
      CUSTOMER_AGREEMENT: '客戶合約',
      CUSTOMER_ASSIGNMENTS: '客戶指派',
      CUSTOMER_DETAIL: '客戶詳情',
      CUSTOMER_MANAGEMENT: '客戶管理',
      CUSTOMER_PRIMARY_CONTACT: '客戶主要聯絡人',
      CUSTOMER_SEGMENT: '客戶細分',
      CUSTOMER_TYPE: '客戶類型',
      CUSTOM_EVENTS: '客戶活動記錄',
      CVC_NUMBER: 'CVC 驗證碼',
      DASHBOARD: '儀表板',
      DEDUCTED_HOURS: '扣除時數',
      DEFAULT_METHOD: '預設付款方式',
      DELETE: '刪除',
      DELETED_USERS: '已刪除的使用者',
      DESCRIPTION: '描述',
      DETAIL: '詳情',
      DETAILS: '詳情',
      DIMENSION: '維度',
      DISCOUNT_PERCENTAGE: '折扣百分比',
      DISCOUNT_RATE: '折扣率',
      DISPLAY_NAME: '顯示名稱',
      DISPLAY_VALUE: '顯示值',
      DISTRIBUTION: '分佈',
      DOCUMENTATION: '文件',
      DURATION: '期限',
      EA_ACCESS_CONTROL: 'EA 存取控制',
      EA_ACCESS_CONTROL_ASSIGNMENTS: 'EA 存取控制指派',
      EA_ACCESS_CONTROL_LIST: 'EA 存取控制清單',
      EA_ENROLLMENT: 'EA Enrollment',
      EA_USAGE_HOURS: 'EA 使用時數',
      EDIT: '編輯',
      EDIT_LICENSE_QTY: '編輯授權數量',
      EMAIL: '電子郵件',
      EMAIL_RECIPIENTS: '電子郵件接收者',
      END_CUSTOMER_TYPE: '最終客戶類型',
      ENGINEER_OWNER: '工程師負責人',
      ENROLLMENT_NUMBER: 'Enrollment 號碼',
      ESTIMATED_BY: '估計依據',
      EVENT_TYPE: '事件類型',
      EVERY: '每',
      EXCEPTION: '例外',
      EXCLUDE: '排除',
      EXPIRATION_DATE: '到期日',
      FAILURE: '失敗',
      FEATURE: '功能',
      FEATURE_UPDATES: '功能更新',
      FILTER: '篩選',
      FILTERED_BY: '篩選條件',
      FILTER_BY_CUSTOMER: '按客戶篩選',
      FILTER_NAME: '篩選名稱',
      FIND: '查詢',
      FINDING_TIME: '查詢時間',
      FIRST_NAME: '名字',
      FOLDER: '資料夾',
      FREQUENCY: '週期',
      FULL_PAYMENT: '全額付款',
      GOAL: '目標',
      GOAL_ACCOMPLISHED: '目標達成',
      GOAL_STATUS: '目標狀態',
      GOAL_UNDONE: '目標未完成',
      GPU: 'GPU',
      GROUPS: '群組',
      GROUP_BY: '依據分組',
      GUIDING_CARD_TITLE: '感謝您選擇 Caloudi CMP。以下資訊將協助您快速入門。',
      HAS_ADD_ONS: '有附加元件',
      HINTS: '提示',
      HOMEPAGE: '首頁',
      HOST: '主機',
      HOSTS: '主機',
      HTTP_HEADERS: 'HTTP 標頭',
      IDENTIFIED: '已識別',
      ID_EXISTED: 'ID 已存在',
      IMPACT: '影響',
      IMPACTED_FIELD: '影響類型',
      IMPORT: '匯入',
      INACTIVE: '已停用',
      INCLUDE: '包含',
      INCLUDE_CREDITS: '包含抵免額',
      INTERNAL: '內部',
      INTERVAL: '間隔',
      INVITE_NEW_CUSTOMER: '邀請新客戶',
      INVITE_NEW_RESELLER: '邀請新經銷商',
      ISSUE_TYPE: '問題類型',
      IS_ADD_ON: '為附加元件',
      IS_MS_PRODUCT: '為 Microsoft 產品',
      ITEMS_IN_CART: '筆項目',
      ITEMS_SELECTED: '已選擇的項目',
      JOB_TITLE: '職稱',
      KEY: '鍵',
      KEYWORD: '關鍵字',
      LABEL: '標籤',
      LABEL_KEY: '標籤鍵',
      LABEL_SELECTED: '個標籤已選擇',
      LANGUAGE: '語言',
      LAST_MODIFIED_TIME: '最後修改時間',
      LAST_NAME: '姓氏',
      LAST_UPDATED: '最後更新',
      LATENCY_FROM_YOU: '來自您的延遲',
      LETTERSPACE: '',
      LIST: '清單',
      LIST_PRICE: '標價',
      LOCATION: '位置',
      MACHINE_FAMILY: '機型系列',
      MANAGEMENT: '管理',
      MANAGEMENT_GROUP: '管理群組',
      MANAGEMENT_GROUP_ASSIGNMENT: '管理群組存取控制指派',
      MANAGER: '管理者',
      MAP: '地圖',
      MAPPING: '對應規則',
      MARGIN_PERCENTAGE_SYMBOL: '毛利 %',
      MARKETPLACE: '市場',
      MEMO: '備忘錄',
      MENU: '選單',
      MENU_TYPE: '選單類型',
      MESSAGE: '訊息',
      METERED_BILLING: '計量計費',
      METERING_SERVICE_DIMENSIONS: '計量服務維度',
      METRICS: '效能指標',
      MICROSOFT_PARTNER_AGREEMENT: 'Microsoft 合作夥伴合約',
      MIDDLE_NAME: '中間名',
      MONTHLY_OVERRAGE_DATE_ALERT: '每月超額預警',
      MONTHLY_REVENUE: '月收入',
      MONTHLY_SAVINGS: '每月節省',
      MONTHLY_USAGE: '每月使用量',
      MORE: '更多',
      MY_CART: '我的購物車',
      MY_RECOMMENDATION_LIST: '我的推薦清單',
      NAME: '名稱',
      NEW: '新增',
      NEW_DIMENSION: '新維度',
      NEW_MONTHLY_HOURS: '新每月使用時數',
      NEW_UNIT_PRICE_1Y: '一年新單價',
      NEW_USAGE_HOURS: '新使用時數',
      NEW_USAGE_QTY: '新使用數量',
      NEXT: '下一步',
      NONE: '無',
      NORMAL: '正常',
      NOTE: '備註',
      NOTIFICATION: '通知',
      NOT_AVAILABLE_FOR_PURCHASE: '無法購買',
      NO_ADMIN_USERS: '無管理者',
      NO_INSTANCE_LABEL: '無實例標籤',
      NO_LABEL: '無標籤',
      NO_PURCHASE_ITEM: '無購買項目',
      NO_SUBSCRIPTION: '無訂閱',
      NUMBER_OF_CHARACTER_CLASSES: '字符類型數量',
      NUMBER_OF_RESOURCES: '無主資源總數',
      NUMBER_OF_TYPES: '無主資源類型總數',
      OFFER: '供應項目',
      OFFER_NAME: '供應項目名稱',
      OF_EVERY: '每',
      ONLY_ONE_SERVICE_PER_SUBSCRIPTION: '每個訂閱只能包含一項服務',
      ON_THE: '於第',
      OPEN_IN_EMAIL: '於電子郵件中開啟',
      OPERATION_HISTORY: '操作記錄',
      ORDER: '訂單',
      ORDER_NUMBER: '訂單編號',
      ORDER_RESULT: '訂單結果',
      ORDER_STATUS: '訂單狀態',
      ORDINAL: '序數',
      ORGANIZATION: '組織',
      ORGANIZATION_NAME: '組織名稱',
      ORGANIZATION_UNIT: '組織單位',
      ORGANIZATION_UNIT_NAME: '組織單位名稱',
      ORIGINAL_PRICE: '原價',
      OVERALL: '總覽',
      OWNER: '負責人',
      PAGE: '頁',
      PAGEVIEWS_DASHBOARD: '頁面瀏覽量儀表板',
      PART_NUMBER: '品號',
      PAYMENT_AMOUNT: '付款金額',
      PAYMENT_METHOD: '付款方式',
      PAYMENT_METHOD_NAME: '付款方式名稱',
      PAYMENT_TYPE: '付款類型',
      PERCENT: '百分比',
      PERCENTAGE: '百分比',
      PERCENTAGE_LIST_PRICE: '標價百分比',
      PERCENTAGE_LIST_PRICE_SYMBOL: '標價百分比',
      PERIOD: '週期',
      PERMISSIONS: '權限',
      PERSONAL_INFO: '個人資訊',
      PER_HOUR: '每小時',
      PER_MONTH: '每月',
      PER_YEAR: '每年',
      PHONE: '電話號碼',
      PIVOT_SAVE_INFO: '如以下資訊變更，請儲存變更。',
      PLAN: '方案',
      PLATFORM: '平台',
      PORT: '埠',
      POSTAL_CODE: '郵遞區號',
      PREFERRED_REGION: '首選區域',
      PREFERRED_VENDOR: '首選供應商',
      PRESET_FILTER: '預設篩選條件',
      PRESET_INFO: '可儲存或套用常用的篩選組合',
      PREVIOUS: '前一',
      PRICE_SCHEDULES: '價格表',
      PRICE_SHEET: '價格單',
      PRICE_SHEET_UPLOAD: '價格單上傳',
      PRICING_CONFIG: '定價配置',
      PRICING_SETTING: '定價設定',
      PRICING_SETTINGS: '定價設定',
      PRIMARY_DOMAIN: '主要網域',
      PRINCIPAL_TYPE: '主體類型',
      PRIORITY: '優先順序',
      PROBLEM_SUBTYPE: '問題子類別',
      PROBLEM_SUBTYPE_DESCRIPTION: '問題子類別描述',
      PROBLEM_SUBTYPE_LIST: '問題子類別清單',
      PROBLEM_TYPE: '問題類別',
      PROBLEM_TYPE_DESCRIPTION: '問題類別描述',
      PROCESSED: '已處理',
      PRODUCT_CATEGORY: '產品類別',
      PROGRAM: '計畫',
      PROPERTIES: '屬性',
      PROVINCE: '省',
      PUBLISHED: '發布時間',
      PUBLISHER: '發行者',
      PURCHASE_PREREQUISITE: '購買資格',
      QUANTITY: '數量',
      QUERY_RESULT: '查詢結果',
      RAM: '記憶體',
      RANGE: '範圍',
      RATING: '評分',
      RECEIVER: '接收者',
      RECIPIENTS: '收件人',
      RECOMMEND: '推薦',
      RECOMMENDED_BY: '推薦依據',
      RECOMMENDED_INSTANCE: '建議實例',
      RECOMMENDED_SKU: '建議 SKU',
      RECOMMEND_RIGHT_SIZING_ANALYSIS_INSTANCE: '推薦型號調整分析',
      RECORDS: '筆記錄',
      REFERENCE_EXCHANGE_RATE: '參考匯率',
      REGION: '區域',
      REMOVE_PARTNERSHIP: '移除合作',
      REPLAY: '重播',
      REQUEST: '請求',
      REQUIRED: '必填欄位',
      RESELLER: '經銷商',
      RESELLERS: '經銷商',
      RESELLER_ACCEPTANCE_THROUGH_MICROSOFT: '經銷商透過 Microsoft 接受',
      RESELLER_AGENT: '經銷商代理',
      RESELLER_AGENT_ASSIGNMENT: '經銷商代理存取指派',
      RESELLER_ASSIGNMENTS: '經銷商指派',
      RESELLER_EMAIL: '經銷商電子郵件',
      RESELLER_PRIMARY_CONTACT: '經銷商主要聯絡人',
      RESERVATION: 'RI 費用',
      RESERVED_HOURS: '保留時數',
      RESOURCES_LIST: '資源清單',
      RESOURCE_CHARGES_LIST: '資源費用清單',
      RESOURCE_DATA: '資源數據',
      RESOURCE_PROPERTIES: '資源屬性',
      RESPONSE_TIME: '回應時間',
      RESULTS: '項結果',
      RESULT_CODE: '結果代碼',
      RETAIL: '零售',
      REVENUE_PERIOD: '收入週期',
      REVIEW: '檢閱',
      REVISION: '修訂',
      RIGHT_SIZING: '型號調整',
      RIGHT_SIZING_ANALYSIS: '型號調整分析',
      RIGHT_SIZING_ANALYSIS_DETAIL: '型號調整分析詳情',
      ROLE: '角色',
      ROLE_ASSIGNMENTS: '角色指派',
      ROLE_LIST: '角色清單',
      ROOT_CAUSE_ANALYSIS: '根本原因分析',
      ROOT_DOMAIN: '根域',
      ROUND_TO: '四捨五入至',
      SALES: '銷售',
      SALES_AGENT: '銷售代理',
      SALES_AGENT_ASSIGNMENT: '銷售代理存取指派',
      SALES_AGENT_EMAIL: '銷售代理電子郵件',
      SALES_AMOUNT: '銷售額',
      SALES_REVENUE: '銷售收入',
      SAVE: '儲存',
      SAVING: '節省',
      SAVINGS: '節省',
      SAVINGS_RANGE: '節省範圍',
      SAVING_PERCENT: '平均節省百分比',
      SCAN: '掃描',
      SCANNER: '掃描器',
      SCHEDULED_REPORT: '定期報表',
      SCOPE: '範圍',
      SCORE: '分數',
      SCREENSHOT: '截圖',
      SECURITY: '資安',
      SELECTED: '已選擇',
      SELECT_ONE_OPTION: '請選擇一個選項',
      SEND_EMAIL_DESCRIPTION: '若有需要，您可以編輯內容後透過電子郵件發送給客戶',
      SENSITIVITY: '敏感度',
      SERVICE_ANALYSIS: '服務分析',
      SERVICE_COST: '服務成本',
      SERVICE_PLANS: '服務方案',
      SERVICE_TYPE: '服務類型',
      SETTING: '設定',
      SEVERITY: '嚴重性',
      SHOW_PRICE_AS: '價格顯示方式',
      SHOW_REVENUE_AS: '收入顯示方式',
      SIZE: '大小',
      SKU_COUNTS: 'SKU 數量',
      SKU_INFO: 'SKU 資訊',
      SKU_NAME: 'SKU 名稱',
      SLOWEST_REQUEST: '請求速度過慢',
      SPOT: '現成 (Spot)',
      STATE: '州/省',
      STATUS: '狀態',
      STORAGE: '儲存',
      SUBJECT: '主旨',
      SUBMENU: '子選單',
      SUBMITTED: '已送出',
      SUBSCRIBED: '已訂閱',
      SUBSCRIPTIONS: '訂閱',
      SUBSCRIPTION_NAME: '訂閱名稱',
      SUMMARY: '摘要',
      SUPPLIER: '供應商',
      SUPPLIERS: '供應商',
      SUPPORT: '支援',
      SUPPORT_AGENT: '支援代理',
      SUPPORT_AGENT_EMAIL: '支援代理電子郵件',
      SUPPORT_OWNER: '支援負責人',
      SUPPORT_TICKET: '支援請求',
      SUPPORT_TICKET_INFO: '支援請求資訊',
      SUSPEND: '暫停',
      SUSPENDED: '已暫停',
      SUSPENDED_SUBSCRIPTION: '已暫停訂閱',
      SWITCHABLE_USER_ASSIGN: '可切換使用者指派',
      SWITCHABLE_USER_HAS_BEEN_UPDATED: '可切換使用者已更新',
      SYNCED: '已同步',
      SYNC_LAST_DATA_MODIFIED_TIME: '同步上次數據修改時間',
      SYSTEM_MAINTENANCE: '系統維護',
      TABLE: '表格',
      TAG: '標籤',
      TAGS: '標籤',
      TAG_ID: '標籤 ID',
      TAG_NAME: '標籤名稱',
      TARGET: '目標',
      TARGETS: '目標',
      TARGET_SKU: '目標 SKU',
      TASK: '任務',
      TAX_EXEMPTION: '免稅',
      TAX_EXEMPTION_DETAIL: '免稅詳情',
      TAX_ID: '統一編號',
      TAX_INFO: '稅務資訊',
      TAX_RATE: '稅率',
      TEMPSTORAGE: '暫存',
      TERM: '期限',
      TEXT: '內容',
      TIMESPAN: '時間範圍',
      TIME_PERIOD: '週期',
      TIME_SCOPE: '時間範圍',
      TIME_STAMP: '時間戳記',
      TIME_ZONE: '時區',
      TITLE: '標題',
      TOOL: '工具',
      TOP: '前',
      TOTAL_USAGE: '總使用量',
      TUTORIAL_VIDEO: '教學影片',
      TYPE: '類型',
      UNCONSUMED: '未使用',
      UNDEDUCTED_COST: '未扣除成本',
      UNDEDUCTED_HOURS: '未扣除時數',
      UNIT_PRICE: '單價',
      UNIT_PRICE_1Y: '一年單價',
      UNIT_TYPE: '單位類型',
      UNSELECTED: '未選擇',
      UNSUBSCRIBE: '取消訂閱',
      UNSUBSCRIBED: '未訂閱',
      UNSYNCED: '未同步',
      UPDATE: '更新',
      UPDATE_LICENSE_AND_CREATE_INFO: '更新授權數量並新增銷售訂單',
      UPDATE_SUBSCRIPTION_STATUS: '更新訂閱狀態',
      USAGE: '使用量',
      USAGE_BREAKDOWN: '使用明細',
      USAGE_COST: '使用成本',
      USAGE_DASHBOARD: '使用量儀表板',
      USAGE_DATE: '計費日期',
      USAGE_DETAIL: '使用量明細',
      USAGE_FILTER_DETAIL: '費用篩選明細',
      USAGE_HOURS: '使用時數',
      USAGE_LINEITEMS: '計量項目',
      USAGE_MONITOR: '費用監控',
      USAGE_QTY: '使用數量',
      USAGE_QUERY: '費用查詢',
      USAGE_SUMMARY: '費用摘要',
      USED_HOURS: '已使用時數',
      USER: '使用者',
      USERS: '使用者',
      USERS_LICENSES: '使用者與授權',
      USER_ACTIVITIES: '使用者活動',
      USER_ASSIGNMENTS: '使用者指派',
      USER_GROUP: '使用者群組',
      USER_GROUPS: '使用者群組',
      USER_GROUP_ASSIGNMENT: '使用者群組存取指派',
      USER_GROUP_CONTROL_ASSIGNMENTS: '使用者群組存取指派',
      USER_GROUP_CONTROL_LIST: '使用者群組清單',
      USER_GROUP_NAME: '使用者群組名稱',
      USER_INFO: '使用者資訊',
      USER_LIST: '使用者清單',
      USER_SEARCH: '使用者搜尋',
      VALUE: '值',
      VALUES: '值',
      VAULT_URI: '金鑰 URI',
      VENDOR: '供應商',
      VIEW_CUSTOMERS: '查看客戶',
      VIEW_NAME: '檢視名稱',
      VISIBILITY: '可見性',
      VM_SIZE: 'VM 大小',
      VULNERABILITY: '漏洞',
      WAIT_FOR: '請稍候',
      WEBSITE: '網站',
      WEEKLY_USAGE: '每週使用量',
      WRITER: '撰寫者',
      YEARLY_SAVINGS: '年節省',
    },
  },
};
