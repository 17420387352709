export enum MenuMode {
  DRAWER = 'drawer',
  HORIZONTAL = 'horizontal',
  OVERLAY = 'overlay',
  REVEAL = 'reveal',
  SLIM = 'slim',
  SLIMPLUS = 'slim-plus',
  STATIC = 'static',
}

export const MenuModeLabel = {
  DRAWER: 'THEME.MODE.DRAWER',
  HORIZONTAL: 'THEME.MODE.HORIZONTAL',
  OVERLAY: 'THEME.MODE.OVERLAY',
  REVEAL: 'THEME.MODE.REVEAL',
  SLIM: 'THEME.MODE.SLIM',
  SLIMPLUS: 'THEME.MODE.SLIMPLUS',
  STATIC: 'THEME.MODE.STATIC',
};

export const MenuModeList = [
  MenuMode.DRAWER,
  MenuMode.HORIZONTAL,
  MenuMode.OVERLAY,
  MenuMode.REVEAL,
  MenuMode.SLIM,
  MenuMode.SLIMPLUS,
  MenuMode.STATIC,
];

export enum MenuProfilePosition {
  START = 'start',
  END = 'end',
}
