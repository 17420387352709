import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    ACTION: {
      ACCEPT: '接受',
      ADD_CHILD: '新增子目錄',
      ADD_COMMENT: '新增留言',
      ADD_CONTACT: '新增聯絡人',
      ADD_CRON_REPORT: '新增報表',
      ADD_DISCOUNT_PERCENT_RULE: '新增折扣率規則',
      ADD_ENROLLMENT: '新增 Enrollment',
      ADD_FEATURE_PACK: '新增功能組',
      ADD_FILTER: '新增篩選器',
      ADD_LABEL: '新增標籤',
      ADD_NEW: '新增資料',
      ADD_ORGANIZATION_UNIT: '新增組織單位',
      ADD_ORGANIZATION: '新增組織',
      ADD_PAYMENT_METHOD: '新增付款方式',
      ADD_PRICING_CONFIG: '新增定價設定',
      ADD_PROBLEM_SUBTYPE: '新增問題子類型',
      ADD_PROBLEM_TYPE: '新增問題類型',
      ADD_PRODUCT: '新增產品',
      ADD_PRODUCTS: '購買商品',
      ADD_RESELLER: '新增經銷商',
      ADD_RESOURCE_GROUP: '新增資源群組',
      ADD_ROOT: '新增根目錄',
      ADD_SKU_RULE: '新增SKU規則',
      ADD_SUBSCRIPTIONS: '新增訂閱',
      ADD_TAG: '新增標籤',
      ADD_TO_CART: '加入購物車',
      ADD_USER_GROUP: '新增使用者群組',
      ADD_USER: '新增使用者',
      ADD: '新增',
      ADDED_TO_CART: '已加入購物車',
      ANALYSIS_DETAIL: '分析詳情',
      APPLY_FILTER: '套用篩選器',
      ATTACH: '綁定',
      BACK: '返回',
      BACK_TO_SEARCH_MODE: '返回搜尋模式並清空選擇欄位',
      BUILD: '建立',
      BUY: '購買',
      CANCEL: '取消',
      CHANGE_PASSWORD: '更改密碼',
      CHOOSE: '選擇',
      CLICK: '點擊',
      CLOSE: '關閉',
      CONFIRM: '確認',
      CONFIRM_NEXT: '確認並進入下一步',
      CONFIRMATION: '確認',
      CONTINUE: '繼續',
      CONTINUE_PURCHASE: '繼續購物',
      COPY_RESOURCE_ID_TO_CLIPBOARD: '複製資源ID',
      CREATE_ANNOUNCEMENT: '新增公告',
      CREATE_USAGE_ACCESS_CONTROL: '新增費用存取限制',
      DEACTIVE: '停用',
      DELETE: '刪除',
      DELETE_BUDGET: '刪除預算表',
      DELETE_CRON_REPORT: '刪除報表',
      DELETE_FILTER: '刪除篩選器',
      DELETE_MENU_NODE: '確認刪除選單 「{{ label }}」 嗎?',
      DELETE_MENU_NODE_SUCCESSFULLY: '成功刪除選單 「{{ label }}」',
      DELETE_TAG: '刪除標籤',
      DELETE_USAGE_ACCESS_CONTROL: '刪除費用存取限制',
      DELETE_USER: '刪除使用者',
      DELETE_VIEW: '刪除檢視',
      DELIVER: '傳送',
      DENIED: '拒絕',
      DETACH: '解除綁定',
      DETECTING: '偵測中',
      DISABLE: '停用',
      DISABLED: '已停用',
      DONE: '完成',
      DOWNLOAD: '下載',
      EDIT: '編輯',
      EDIT_ADMIN_USER: '編輯管理者',
      EDIT_ANNOUNCEMENT: '編輯公告',
      EDIT_CONTACT: '編輯聯絡人',
      EDIT_MEMO: '編輯備忘錄',
      EDIT_ORGANIZATION: '編輯組織',
      EDIT_PRIORITY: '編輯優先順序',
      EDIT_PROBLEM_SUBTYPE: '編輯問題子類型',
      EDIT_PROBLEM_TYPE: '編輯問題類型',
      EDIT_STATUS: '編輯狀態',
      EDIT_SWITCHABLE_USER: '編輯可切換註冊使用者',
      EDIT_TAG: '編輯標籤',
      EDIT_USER: '編輯使用者',
      ENABLE: '啟用',
      ENABLED: '已啟用',
      ENTER_NEW_NAME: '輸入新名稱',
      EXPAND: '展開',
      EXPAND_GROUP: '展開群組',
      EXPORT: '匯出',
      EXPORT_ALL: '匯出全部',
      GENERATE_REPORT: '產生報表',
      GO_BACK: '返回',
      HIDE_DETAILS: '隱藏詳情',
      IMPORT: '匯入',
      INTERACTIVE_MODE: '互動模式',
      LEARN_MORE: '了解更多資訊',
      LOGOUT: '登出',
      MAIL_SCHEDULE: 'E-Mail / 定時報表',
      MAKE_A_PAYMENT: '付款',
      MODIFY: '修改',
      NEW: '新增',
      NEW_ACCESS_GROUP: '新增存取群組',
      NEW_BUDGET: '新增預算',
      NEW_REPORT: '新增報表',
      NEW_SUPPORT_TICKET: '新增支援請求',
      NEXT: '下一步: {{ target }}',
      PAUSE: '暫停',
      PAY: '支付',
      PREVIEW: '預覽',
      PRINT: '列印',
      PRINT_MODE: '列印模式',
      PUBLISH: '發布',
      QUERY: '查詢',
      REFRESH: '重新整理',
      REFRESHING: '重新整理中',
      REFRESH_LATENCY: '即時偵測，延遲可能受網路連線速度與品質影響。',
      REMOVE: '移除',
      RESEND: '重新傳送',
      RESET: '重設',
      RESET_USER_MFA: '重設使用多重驗證',
      RESET_USER_PASSWORD: '重設使用者密碼',
      RESTORE: '還原',
      RETURN: '返回',
      SAVE: '儲存',
      SAVE_AS: '另存為',
      SAVE_CHART: '儲存圖表',
      SAVE_FILTER: '儲存篩選器',
      SAVE_PNG: '儲存成PNG',
      SAVE_SVG: '儲存成SVG',
      SAVE_VIEW: '儲存檢視',
      SCAN: '掃描',
      SEARCH: '搜尋',
      SELECT: '選擇',
      SELECT_DATE: '選擇日期',
      SELECT_DIMENSION: '選擇維度',
      SELECT_FOLDER: '選擇資料夾',
      SELECT_KEY: '選擇鍵',
      SELECT_VALUE: '選擇值',
      SELECT_VALUES: '選擇值',
      SEND: '傳送',
      SENT: '已傳送',
      SEND_CRON_REPORT: '傳送報表',
      SEND_INVITATION_SUCCESS: '上線邀請已傳送',
      SEND_NOW: '立即傳送',
      SEND_REPORT: '傳送報表',
      SEND_REPORT_BY_EMAIL: '透過電子郵件傳送報表',
      SET_AS_DEFAULT: '設為預設',
      SHARE: '分享',
      SHOW_DETAILS: '顯示詳情',
      START: '開始',
      STATUS_HAS_BEEN_UPDATED: '狀態已更新',
      SUBMIT: '提交',
      SUPPORT_TICKET_HAS_BEEN_CREATED: '支援請求已建立',
      SWITCH: '切換',
      SWITCH_APP: '切換 App',
      SWITCH_CUSTOMER: '切換客戶',
      SWITCH_EA: '切換EA',
      SWITCH_ENROLLMENT: '切換 Enrollment',
      SWITCH_PARTNER: '切換合作夥伴',
      SWITCH_RESELLER: '切換經銷商',
      SWITCH_USER: '切換使用者',
      SWITCH_VIEW: '切換檢視',
      TAKE_ACTION: '採取行動',
      TOGGLE_FULL_WIDTH: '切換全寬',
      TOP: '前',
      UPDATE: '更新',
      UPDATE_BUDGET: '更新預算表',
      UPDATE_CRON_REPORT: '更新報表',
      UPDATE_EA_ACCESS_CONTROL: '更新EA存取限制',
      UPDATE_MENU: '更新選單',
      UPDATE_USAGE_ACCESS_CONTROL: '更新費用存取限制',
      UPLOAD: '上傳',
      UPLOAD_USER: '上傳使用者',
      VIEW: '檢視',
      VIEW_ORDERS: '檢視訂單',
      VIEW_SUBSCRIPTIONS: '檢視訂閱',
    },
  },
};
