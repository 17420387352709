<p-dialog
  *ngIf="displayDialog && shareLink"
  [(visible)]="displayDialog"
  (visibleChange)="visibleChange($event)"
  [styleClass]="'saving_dialog'"
  [header]="'Share'"
  [dismissableMask]="false"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  id="share_link_dialog">
  <section style="min-width: 45vw">
    <div class="p-md-4">
      <div class="p-inputgroup">
        <input type="text" pInputText name="shareLinkId" id="shareLink" [value]="shareLink" readonly />
        <span class="p-inputgroup-addon" (click)="copyShareLink(shareLink)">
          <i class="pi pi-copy"></i>
        </span>
      </div>
    </div>
  </section>
</p-dialog>
