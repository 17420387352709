import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    ACTION: {
      ACCEPT: 'Accept',
      ADD_CHILD: 'Add Child',
      ADD_COMMENT: 'Add Comment',
      ADD_CONTACT: 'Add Contact',
      ADD_CRON_REPORT: 'Add Scheduled Report',
      ADD_DISCOUNT_PERCENT_RULE: 'Add Discount Percent Rule',
      ADD_ENROLLMENT: 'Add Enrollment',
      ADD_FEATURE_PACK: 'Add Feature Pack',
      ADD_FILTER: 'Add Filter',
      ADD_LABEL: 'Add Label',
      ADD_NEW: 'Add New',
      ADD_ORGANIZATION_UNIT: 'Add Organization Unit',
      ADD_ORGANIZATION: 'Add Organization',
      ADD_PAYMENT_METHOD: 'Add Payment Method',
      ADD_PRICING_CONFIG: 'Add Pricing Config',
      ADD_PROBLEM_SUBTYPE: 'Add Problem Subtype',
      ADD_PROBLEM_TYPE: 'Add Problem Type',
      ADD_PRODUCT: 'Add Product',
      ADD_PRODUCTS: 'Add Products',
      ADD_RESELLER: 'Add Reseller',
      ADD_RESOURCE_GROUP: 'Add Resource Group',
      ADD_ROOT: 'Add Root',
      ADD_SKU_RULE: 'Add SKU Rule',
      ADD_SUBSCRIPTIONS: 'Add Subscriptions',
      ADD_TAG: 'Add Tag',
      ADD_TO_CART: 'Add to Cart',
      ADD_USER_GROUP: 'Add User Group',
      ADD_USER: 'Add User',
      ADD: 'Add',
      ADDED_TO_CART: 'Added to Cart',
      ANALYSIS_DETAIL: 'Analysis Detail',
      APPLY_FILTER: 'Apply Filter',
      ATTACH: 'Attach',
      BACK: 'Back',
      BACK_TO_SEARCH_MODE: 'Back to search mode and clear selected items',
      BUILD: 'Build',
      BUY: 'BUY',
      CANCEL: 'Cancel',
      CHANGE_PASSWORD: 'Change Password',
      CHOOSE: 'Choose',
      CLICK: 'Click',
      CLOSE: 'Close',
      CONFIRM: 'Confirm',
      CONFIRM_NEXT: 'Confirm and Next',
      CONFIRMATION: 'Confirmation',
      CONTINUE: 'Continue',
      CONTINUE_PURCHASE: 'Continue Purchase',
      COPY_RESOURCE_ID_TO_CLIPBOARD: 'Copy resource ID to clipboard',
      CREATE_ANNOUNCEMENT: 'Create Announcement',
      CREATE_USAGE_ACCESS_CONTROL: 'Create Usage Access Control',
      DEACTIVATE: 'Deactivate',
      DELETE: 'Delete',
      DELETE_BUDGET: 'Delete Budget',
      DELETE_CRON_REPORT: 'Delete Scheduled Report',
      DELETE_FILTER: 'Delete Filter',
      DELETE_MENU_NODE: 'Are you sure you want to delete Menu 「{{ label }}」?',
      DELETE_MENU_NODE_SUCCESSFULLY: 'Delete Menu 「{{ label }}」 Successfully',
      DELETE_TAG: 'Delete Tag',
      DELETE_USAGE_ACCESS_CONTROL: 'Delete Usage Access Control',
      DELETE_USER: 'Delete User',
      DELETE_VIEW: 'Delete View',
      DELIVER: 'Deliver',
      DENIED: 'Denied',
      DETACH: 'Detach',
      DETECTING: 'Detecting',
      DISABLE: 'Disable',
      DISABLED: 'disabled',
      DONE: 'Done',
      DOWNLOAD: 'Download',
      EDIT: 'Edit',
      EDIT_ADMIN_USERS: 'Edit Admin Users',
      EDIT_ANNOUNCEMENT: 'Edit Announcement',
      EDIT_CONTACT: 'Edit Contact',
      EDIT_MEMO: 'Edit Memo',
      EDIT_ORGANIZATION: 'Edit Organization',
      EDIT_PRIORITY: 'Edit Priority',
      EDIT_PROBLEM_SUBTYPE: 'Edit Problem Subtype',
      EDIT_PROBLEM_TYPE: 'Edit Problem Type',
      EDIT_STATUS: 'Edit Status',
      EDIT_SWITCHABLE_USERS: 'Edit Switchable Users',
      EDIT_TAG: 'Edit Tag',
      EDIT_USER: 'Edit User',
      ENABLE: 'enable',
      ENABLED: 'enabled',
      ENTER_NEW_NAME: 'Enter New Name',
      EXPAND: 'Expand',
      EXPAND_GROUP: 'Expand Group',
      EXPORT: 'Export',
      EXPORT_ALL: 'Export All',
      GENERATE_REPORT: 'Generate Report',
      GO_BACK: 'go back',
      HIDE_DETAILS: 'Hide Details',
      IMPORT: 'Import',
      INTERACTIVE_MODE: 'Interactive Mode',
      LEARN_MORE: 'Learn more',
      LOGOUT: 'logout',
      MAIL_SCHEDULE: 'E-Mail / Scheduled Report',
      MAKE_A_PAYMENT: 'Make a Payment',
      MODIFY: 'Modify',
      NEW: 'New',
      NEW_ACCESS_GROUP: 'new access group',
      NEW_BUDGET: 'New Budget',
      NEW_REPORT: 'New Report',
      NEW_SUPPORT_TICKET: 'New Support Ticket',
      NEXT: 'Next: {{ target }}',
      PAUSE: 'Pause',
      PAY: 'Pay',
      PREVIEW: 'Preview',
      PRINT: 'Print',
      PRINT_MODE: 'Print Mode',
      PUBLISH: 'Publish',
      QUERY: 'Query',
      REFRESH: 'Refresh',
      REFRESHING: 'Refreshing',
      REFRESH_LATENCY: 'Detection in real-time. Time will be affected by the speed and quality of your internet connection.',
      REMOVE: 'Remove',
      RESEND: 'Resend',
      RESET: 'Reset',
      RESET_USER_MFA: 'Reset User MFA',
      RESET_USER_PASSWORD: 'Reset User Password',
      RESTORE: 'Restore',
      RETURN: 'Return',
      SAVE: 'Save',
      SAVE_AS: 'Save As',
      SAVE_CHART: 'Save Chart',
      SAVE_FILTER: 'Save Filter',
      SAVE_PNG: 'Save As PNG',
      SAVE_SVG: 'Save As SVG',
      SAVE_VIEW: 'Save View',
      SCAN: 'Scan',
      SEARCH: 'Search',
      SELECT: 'Select',
      SELECT_DATE: 'Select Date',
      SELECT_DIMENSION: 'Select Dimension',
      SELECT_FOLDER: 'Select a Folder',
      SELECT_KEY: 'Select Key',
      SELECT_VALUE: 'Select Value',
      SELECT_VALUES: 'Select Values',
      SEND: 'Send',
      SENT: 'Sent',
      SEND_CRON_REPORT: 'Send Scheduled Report',
      SEND_INVITATION_SUCCESS: 'Onboard Invite request has been posted successfully',
      SEND_NOW: 'Send Now',
      SEND_REPORT: 'Send Report',
      SEND_REPORT_BY_EMAIL: 'Send Report by email',
      SET_AS_DEFAULT: 'Set as Default',
      SHARE: 'Share',
      SHOW_DETAILS: 'Show Details',
      START: 'Start',
      STATUS_HAS_BEEN_UPDATED: 'Status has been updated',
      SUBMIT: 'Submit',
      SUPPORT_TICKET_HAS_BEEN_CREATED: 'Support ticket has been created',
      SWITCH: 'Switch',
      SWITCH_APP: 'Switch App',
      SWITCH_CUSTOMER: 'Switch Customer',
      SWITCH_EA: 'Switch EA',
      SWITCH_ENROLLMENT: 'Switch Enrollment',
      SWITCH_PARTNER: 'Switch Partner',
      SWITCH_RESELLER: 'Switch Reseller',
      SWITCH_USER: 'Switch User',
      SWITCH_VIEW: 'Switch View',
      TAKE_ACTION: 'Take Action',
      TOGGLE_FULL_WIDTH: 'Toggle Full Width',
      TOP: 'Top',
      UPDATE: 'Update',
      UPDATE_BUDGET: 'Update Budget',
      UPDATE_CRON_REPORT: 'Update Scheduled Report',
      UPDATE_EA_ACCESS_CONTROL: 'Update EA Access Control',
      UPDATE_MENU: 'Update Menu',
      UPDATE_USAGE_ACCESS_CONTROL: 'Update Usage Access Control',
      UPLOAD: 'Upload',
      UPLOAD_USER: 'Upload Users',
      VIEW: 'View',
      VIEW_ORDERS: 'View Orders',
      VIEW_SUBSCRIPTIONS: 'View Subscriptions',
    },
  },
};
