/* eslint-disable @typescript-eslint/naming-convention */
// Angular
import { Component, forwardRef, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
// Primeng
import { SelectItem } from 'primeng/api';
// Caloudi
import { BaseComponent } from '@base';
import { CronOptions } from './CronOptions';
// Interface
import { Days, Months, MonthWeeks } from './enums';

export const CRON_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CronGenComponent),
  multi: true,
};
@Component({
  selector: 'caloudi-cron-editor',
  templateUrl: './cron-editor.component.html',
  styleUrls: ['./cron-editor.component.sass'],
  providers: [CRON_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
})
export class CronGenComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @Input('backgroundColor') public backgroundColor: ThemePalette;
  @Input('color') public color: ThemePalette;
  @Input('disabled') public disabled: boolean;
  @Input('options') public options: CronOptions;
  @Input('editMode') public editMode: boolean;

  private initialize: boolean;

  @Input('cron') public get cron(): string {
    return this.localCron;
  }

  private set cron(value: string) {
    this.localCron = value;
    this.onChange(value);
    if (this.initialize) return;
    if (this.editMode) this.setLoadingValue(value);
    this.minutesForm.valueChanges.subscribe(v => this.computeMinutesCron(v as unknown as AntiPatternState));
    this.hourlyForm.valueChanges.subscribe(v => this.computeHourlyCron(v as unknown as AntiPatternState));
    this.dailyForm.valueChanges.subscribe(v => this.computeDailyCron(v as unknown as AntiPatternState));
    this.weeklyForm.valueChanges.subscribe(v => this.computeWeeklyCron(v as unknown as AntiPatternState));
    this.monthlyForm.valueChanges.subscribe(v => this.computeMonthlyCron(v as unknown as AntiPatternState));
    this.yearlyForm.valueChanges.subscribe(v => this.computeYearlyCron(v as unknown as AntiPatternState));
    this.initialize = true;
  }

  public tabIndex: number = 0;

  // The name is an Angular convention, @Input variable name + 'Change' suffix
  // @Output() cronChange = new EventEmitter();

  public activeTab: string;
  public selectOptions = this.getSelectOptions();
  public state: AntiPatternState;

  private localCron = '0 0 1/1 * *';
  private isDirty: boolean;

  private cronForm: FormControl;

  private minutesForm: FormGroup<MinutesFormGroup>;
  private hourlyForm: FormGroup<HourlyFormGroup>;
  public dailyForm: FormGroup<DailyFormGroup>;
  public weeklyForm: FormGroup<WeeklyFormGroup>;
  public monthlyForm: FormGroup<MonthlyFormGroup>;
  private yearlyForm: FormGroup<YearlyFormGroup>;
  private advancedForm: FormGroup<AdvancedFormGroup>;

  public get isCronFlavorQuartz(): boolean {
    return this.options.cronFlavor === 'quartz';
  }

  public get isCronFlavorStandard(): boolean {
    return this.options.cronFlavor === 'standard';
  }

  public get yearDefaultChar(): '' | '*' {
    return this.options.cronFlavor === 'quartz' ? '*' : '';
  }

  public get weekDayDefaultChar(): '?' | '*' {
    return this.options.cronFlavor === 'quartz' ? '?' : '*';
  }

  public get monthDayDefaultChar(): '?' | '*' {
    return this.options.cronFlavor === 'quartz' ? '?' : '*';
  }

  constructor(public readonly injector: Injector, private readonly fb: FormBuilder) {
    super(injector);
  }

  public setLoadingValue(cron: string): void {
    this.logger.debug('setLoadingValue:', [cron]);
    if (cron.split(' ').length === 5 && this.isCronFlavorStandard) {
      cron = `0 ${cron} *`;
    }
    const [, minutes, hours, dayOfMonth, month, dayOfWeek] = cron.split(' ');
    if (cron.match(/\d+ 0\/\d+ \* 1\/1 \* [?*]] \*/)) {
      // Minutes
    } else if (cron.match(/\d+ \d+ 0\/\d+ 1\/1 \* [?*]] \*/)) {
      // Hourly
    } else if (cron.match(/\d+ \d+ \d+ 1\/\d+ \* [?*]] \*/)) {
      // Daily everyDays
      // this.dailyForm.value.hours = hours; // no react
      this.tabIndex = 0;
      const dayValue: number = parseInt(dayOfMonth.substring(2), 10);
      this.dailyForm.patchValue({
        subTab: 'everyDays',
        everyDays: { days: dayValue, hours: hours as unknown as number, minutes: minutes as unknown as number },
      });
    } else if (cron.match(/\d+ \d+ \d+ [?*]] \* MON-FRI \*/)) {
      // Daily everyWeekDay
      // const timerHour = document.getElementById('timerHour');  // no react
      this.tabIndex = 0;
      this.dailyForm.patchValue({
        everyWeekDay: { hours: hours as unknown as number, minutes: minutes as unknown as number },
      });
    } else if (cron.match(/\d+ \d+ \d+ [?*]] \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/)) {
      // Weekly
      // dayOfWeek.split(',').forEach(weekDay => this.weeklyForm[weekDay] = true);
      this.tabIndex = 1;
      // this.logger.debug(dayOfWeek.split(',').forEach(weekDay => WeekValue[weekDay] = true));
      // let WeekValue:string[] = [];
      // this.selectOptions.days.forEach(weekDay =>  WeekValue[weekDay] = false);
      // dayOfWeek.split(',').forEach(weekDay => WeekValue[weekDay] = true); // Fail
      // const WeekValue=dayOfWeek.split(',').map(weekDay => WeekValue.weekDay = true ); // Fail
      // this.logger.debug('w', WeekValue);
      // this.logger.debug('s', this.state.weekly);
      this.weeklyForm.patchValue({
        MON: dayOfWeek.includes('MON'),
        TUE: dayOfWeek.includes('TUE'),
        WED: dayOfWeek.includes('WED'),
        THU: dayOfWeek.includes('THU'),
        FRI: dayOfWeek.includes('FRI'),
        SAT: dayOfWeek.includes('SAT'),
        SUN: dayOfWeek.includes('SUN'),
        hours: hours as unknown as number,
        minutes: minutes as unknown as number,
      });
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) 1\/\d+ [?*]] \*/)) {
      // Monthly specificDay
      this.tabIndex = 2;
      const monthValue: number = parseInt(month.substring(2), 10);
      this.monthlyForm.patchValue({
        specificDay: {
          months: monthValue,
          day: dayOfMonth,
          hours: hours as unknown as number,
          minutes: minutes as unknown as number,
        },
      });
    } else if (cron.match(/\d+ \d+ \d+ [?*]] 1\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
      // Monthly specificWeekDay
      this.tabIndex = 2;
      const monthValue: number = parseInt(month.substring(2), 10);
      this.monthlyForm.patchValue({
        subTab: 'specificWeekDay',
        specificWeekDay: {
          months: monthValue,
          monthWeek: dayOfWeek.substr(3),
          day: dayOfWeek.substr(0, 3),
          hours: hours as unknown as number,
          minutes: minutes as unknown as number,
        },
      });
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ [?*]] \*/)) {
      // Yearly specificMonthDay
    } else if (cron.match(/\d+ \d+ \d+ [?*]] \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
      // Yearly specificMonthWeek
    } else {
      // Advanced
    }
  }

  /** Update the cron output to that of the selected tab.
   * The cron output value is updated whenever a form is updated. To make it change in response to tab selection, we simply reset
   * the value of the form that goes into focus.
   */
  public onTabFocus(idx: number): void {
    switch (idx) {
      case 0:
        this.dailyForm.setValue({
          subTab: this.dailyForm.value.subTab,
          everyWeekDay: {
            days: this.dailyForm.value.everyWeekDay.days,
            hours: this.dailyForm.value.everyWeekDay.hours,
            minutes: this.dailyForm.value.everyWeekDay.minutes,
            seconds: this.dailyForm.value.everyWeekDay.seconds,
            hourType: this.dailyForm.value.everyWeekDay.hourType,
          },
          everyDays: {
            days: this.dailyForm.value.everyDays.days,
            hours: this.dailyForm.value.everyDays.hours,
            minutes: this.dailyForm.value.everyDays.minutes,
            seconds: this.dailyForm.value.everyDays.seconds,
            hourType: this.dailyForm.value.everyDays.hourType,
          },
        });
        // this.minutesForm.setValue(this.minutesForm.value);
        break;
      case 1:
        this.weeklyForm.setValue({
          MON: this.weeklyForm.value.MON,
          TUE: this.weeklyForm.value.TUE,
          WED: this.weeklyForm.value.WED,
          THU: this.weeklyForm.value.THU,
          FRI: this.weeklyForm.value.FRI,
          SAT: this.weeklyForm.value.SAT,
          SUN: this.weeklyForm.value.SUN,
          hours: this.weeklyForm.value.hours,
          minutes: this.weeklyForm.value.minutes,
          seconds: this.weeklyForm.value.seconds,
          hourType: this.weeklyForm.value.hourType,
        });
        // this.hourlyForm.setValue(this.hourlyForm.value);
        break;
      case 2:
        this.monthlyForm.setValue({
          subTab: this.monthlyForm.value.subTab,
          specificDay: {
            day: this.monthlyForm.value.specificDay.day,
            months: this.monthlyForm.value.specificDay.months,
            hours: this.monthlyForm.value.specificDay.hours,
            minutes: this.monthlyForm.value.specificDay.minutes,
            seconds: this.monthlyForm.value.specificDay.seconds,
            hourType: this.monthlyForm.value.specificDay.hourType,
          },
          specificWeekDay: {
            monthWeek: this.monthlyForm.value.specificWeekDay.monthWeek,
            day: this.monthlyForm.value.specificWeekDay.day,
            months: this.monthlyForm.value.specificWeekDay.months,
            hours: this.monthlyForm.value.specificWeekDay.hours,
            minutes: this.monthlyForm.value.specificWeekDay.minutes,
            seconds: this.monthlyForm.value.specificWeekDay.seconds,
            hourType: this.monthlyForm.value.specificWeekDay.hourType,
          },
        });
        // this.dailyForm.setValue(this.dailyForm.value);
        break;
      case 3:
        this.weeklyForm.setValue({
          MON: this.weeklyForm.value.MON,
          TUE: this.weeklyForm.value.TUE,
          WED: this.weeklyForm.value.WED,
          THU: this.weeklyForm.value.THU,
          FRI: this.weeklyForm.value.FRI,
          SAT: this.weeklyForm.value.SAT,
          SUN: this.weeklyForm.value.SUN,
          hours: this.weeklyForm.value.hours,
          minutes: this.weeklyForm.value.minutes,
          seconds: this.weeklyForm.value.seconds,
          hourType: this.weeklyForm.value.hourType,
        });
        break;
      case 4:
        this.monthlyForm.setValue({
          subTab: this.monthlyForm.value.subTab,
          specificDay: {
            day: this.monthlyForm.value.specificDay.day,
            months: this.monthlyForm.value.specificDay.months,
            hours: this.monthlyForm.value.specificDay.hours,
            minutes: this.monthlyForm.value.specificDay.minutes,
            seconds: this.monthlyForm.value.specificDay.seconds,
            hourType: this.monthlyForm.value.specificDay.hourType,
          },
          specificWeekDay: {
            monthWeek: this.monthlyForm.value.specificWeekDay.monthWeek,
            day: this.monthlyForm.value.specificWeekDay.day,
            months: this.monthlyForm.value.specificWeekDay.months,
            hours: this.monthlyForm.value.specificWeekDay.hours,
            minutes: this.monthlyForm.value.specificWeekDay.minutes,
            seconds: this.monthlyForm.value.specificWeekDay.seconds,
            hourType: this.monthlyForm.value.specificWeekDay.hourType,
          },
        });
        break;
      case 5:
        this.yearlyForm.setValue({
          subTab: this.yearlyForm.value.subTab,
          specificMonthDay: {
            month: this.yearlyForm.value.specificMonthDay.month,
            day: this.yearlyForm.value.specificMonthDay.day,
            hours: this.yearlyForm.value.specificMonthDay.hours,
            minutes: this.yearlyForm.value.specificMonthDay.minutes,
            seconds: this.yearlyForm.value.specificMonthDay.seconds,
            hourType: this.yearlyForm.value.specificMonthDay.hourType,
          },
          specificMonthWeek: {
            monthWeek: this.yearlyForm.value.specificMonthWeek.monthWeek,
            day: this.yearlyForm.value.specificMonthWeek.day,
            month: this.yearlyForm.value.specificMonthWeek.month,
            hours: this.yearlyForm.value.specificMonthWeek.hours,
            minutes: this.yearlyForm.value.specificMonthWeek.minutes,
            seconds: this.yearlyForm.value.specificMonthWeek.seconds,
            hourType: this.yearlyForm.value.specificMonthWeek.hourType,
          },
        });
        break;
      case 6:
        this.advancedForm.setValue({ expression: this.advancedForm.value.expression });
        break;
      default:
        throw new Error('Invalid tab selected');
    }
  }

  public ngOnInit(): void {
    this.state = this.getDefaultState();

    this.handleModelChange(this.cron);

    const [defaultHours, defaultMinutes, defaultSeconds] = this.options.defaultTime.split(':').map(Number);

    this.cronForm = new FormControl('0 0 1/1 * *');

    this.minutesForm = this.fb.group<MinutesFormGroup>({
      hours: new FormControl(0),
      minutes: new FormControl(1),
      seconds: new FormControl(0),
    });

    // this.minutesForm.valueChanges.subscribe(value => this.computeMinutesCron(value));

    this.hourlyForm = this.fb.group<HourlyFormGroup>({
      hours: new FormControl(1),
      minutes: new FormControl(0),
      seconds: new FormControl(0),
    });
    // this.hourlyForm.valueChanges.subscribe(value => this.computeHourlyCron(value));

    this.dailyForm = this.fb.group<DailyFormGroup>({
      subTab: new FormControl('everyWeekDay'),
      everyWeekDay: this.fb.group<EveryWeekDayFormGroup>({
        days: new FormControl(0),
        hours: new FormControl(this.getAmPmHour(6)),
        minutes: new FormControl(0),
        seconds: new FormControl(0),
        hourType: new FormControl(this.getHourType(0)),
      }),
      everyDays: this.fb.group<EveryDaysFormGroup>({
        days: new FormControl(1),
        hours: new FormControl(this.getAmPmHour(6)),
        minutes: new FormControl(0),
        seconds: new FormControl(0),
        hourType: new FormControl(this.getHourType(0)),
      }),
    });
    // this.dailyForm.valueChanges.subscribe(value => this.computeDailyCron(value));

    this.weeklyForm = this.fb.group<WeeklyFormGroup>({
      MON: new FormControl(true),
      TUE: new FormControl(false),
      WED: new FormControl(false),
      THU: new FormControl(false),
      FRI: new FormControl(false),
      SAT: new FormControl(false),
      SUN: new FormControl(false),
      hours: new FormControl(this.getAmPmHour(defaultHours)),
      minutes: new FormControl(defaultMinutes),
      seconds: new FormControl(defaultSeconds),
      hourType: new FormControl(this.getHourType(defaultHours)),
    });
    // this.weeklyForm.valueChanges.subscribe(next => this.computeWeeklyCron(next));

    this.monthlyForm = this.fb.group<MonthlyFormGroup>({
      subTab: new FormControl('specificDay'),
      specificDay: this.fb.group<SpecificDayFormGroup>({
        day: new FormControl('1'),
        months: new FormControl(1),
        hours: new FormControl(this.getAmPmHour(defaultHours)),
        minutes: new FormControl(defaultMinutes),
        seconds: new FormControl(defaultSeconds),
        hourType: new FormControl(this.getHourType(defaultHours)),
      }),
      specificWeekDay: this.fb.group<SpecificWeekDayFormGroup>({
        monthWeek: new FormControl('#1'),
        day: new FormControl('MON'),
        months: new FormControl(1),
        hours: new FormControl(this.getAmPmHour(defaultHours)),
        minutes: new FormControl(defaultMinutes),
        seconds: new FormControl(defaultSeconds),
        hourType: new FormControl(this.getHourType(defaultHours)),
      }),
    });
    // this.monthlyForm.valueChanges.subscribe(next => this.computeMonthlyCron(next));

    this.yearlyForm = this.fb.group<YearlyFormGroup>({
      subTab: new FormControl('specificMonthDay'),
      specificMonthDay: this.fb.group<SpecificMonthDayFormGroup>({
        month: new FormControl(1),
        day: new FormControl('1'),
        hours: new FormControl(this.getAmPmHour(defaultHours)),
        minutes: new FormControl(defaultMinutes),
        seconds: new FormControl(defaultSeconds),
        hourType: new FormControl(this.getHourType(defaultHours)),
      }),
      specificMonthWeek: this.fb.group<SpecificMonthWeekFormGroup>({
        monthWeek: new FormControl('#1'),
        day: new FormControl('MON'),
        month: new FormControl(1),
        hours: new FormControl(this.getAmPmHour(defaultHours)),
        minutes: new FormControl(defaultMinutes),
        seconds: new FormControl(defaultSeconds),
        hourType: new FormControl(this.getHourType(defaultHours)),
      }),
    });
    // this.yearlyForm.valueChanges.subscribe(next => this.computeYearlyCron(next));

    this.advancedForm = this.fb.group<AdvancedFormGroup>({
      expression: new FormControl(this.isCronFlavorQuartz ? '0 15 10 L-2 * ? *' : '15 10 2 * *'),
    });
    // this.advancedForm.controls.expression.valueChanges.subscribe(next => this.computeAdvancedExpression(next));
  }

  private computeMinutesCron(state: AntiPatternState): void {
    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} 0/${String(state.minutes)} * 1/1 * ${this.weekDayDefaultChar
      } ${this.yearDefaultChar}`.trim();
    this.cronForm.setValue(this.cron);
  }

  private computeHourlyCron(state: AntiPatternState): void {
    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} ${String(state.minutes)} 0/${state.hours} 1/1 * ${this.weekDayDefaultChar
      } ${this.yearDefaultChar}`.trim();
    this.cronForm.setValue(this.cron);
  }

  private computeDailyCron(state: AntiPatternState): void {
    switch (state.subTab) {
      case 'everyDays':
        this.cron = `${this.isCronFlavorQuartz ? state.everyDays.seconds : ''} ${state.everyDays.minutes
          } ${this.hourToCron(state.everyDays.hours, state.everyDays.hourType)} 1/${state.everyDays.days} * ${this.weekDayDefaultChar
          } ${this.yearDefaultChar}`.trim();
        break;
      case 'everyWeekDay':
        this.cron = `${this.isCronFlavorQuartz ? state.everyWeekDay.seconds : ''} ${state.everyWeekDay.minutes
          } ${this.hourToCron(state.everyWeekDay.hours, state.everyWeekDay.hourType)} ${this.monthDayDefaultChar
          } * MON-FRI ${this.yearDefaultChar}`.trim();
        break;
      default:
        throw new Error('Invalid cron daily subtab selection');
    }
    this.cronForm.setValue(this.cron);
  }

  private computeWeeklyCron(state: AntiPatternState): void {
    const days = this.selectOptions.days
      .reduce((acc: string[], day: string): string[] => (state[day] ? acc.concat([day]) : acc), [])
      .join(',');
    if (days !== '') {
      this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} ${String(state.minutes)} ${this.hourToCron(
        state.hours,
        state.hourType
      )} ${this.monthDayDefaultChar} * ${days} ${this.yearDefaultChar}`.trim();
      this.cronForm.setValue(this.cron);
    }
  }

  private computeMonthlyCron(state: AntiPatternState): void {
    switch (state.subTab) {
      case 'specificDay':
        this.cron = `${this.isCronFlavorQuartz ? state.specificDay.seconds : ''} ${state.specificDay.minutes
          } ${this.hourToCron(state.specificDay.hours, state.specificDay.hourType)} ${state.specificDay.day} 1/${state.specificDay.months
          } ${this.weekDayDefaultChar} ${this.yearDefaultChar}`.trim();
        break;
      case 'specificWeekDay':
        this.cron = `${this.isCronFlavorQuartz ? state.specificWeekDay.seconds : ''} ${state.specificWeekDay.minutes
          } ${this.hourToCron(state.specificWeekDay.hours, state.specificWeekDay.hourType)} ${this.monthDayDefaultChar
          } 1/${state.specificWeekDay.months} ${state.specificWeekDay.day}${state.specificWeekDay.monthWeek} ${this.yearDefaultChar
          }`.trim();
        break;
      default:
        throw new Error('Invalid cron montly subtab selection');
    }
    this.cronForm.setValue(this.cron);
  }

  private computeYearlyCron(state: AntiPatternState): void {
    switch (state.subTab) {
      case 'specificMonthDay':
        this.cron = `${this.isCronFlavorQuartz ? state.specificMonthDay.seconds : ''} ${state.specificMonthDay.minutes
          } ${this.hourToCron(state.specificMonthDay.hours, state.specificMonthDay.hourType)} ${state.specificMonthDay.day
          } ${state.specificMonthDay.month} ${this.weekDayDefaultChar} ${this.yearDefaultChar}`.trim();
        break;
      case 'specificMonthWeek':
        this.cron = `${this.isCronFlavorQuartz ? state.specificMonthWeek.seconds : ''} ${state.specificMonthWeek.minutes
          } ${this.hourToCron(state.specificMonthWeek.hours, state.specificMonthWeek.hourType)} ${this.monthDayDefaultChar
          } ${state.specificMonthWeek.month} ${state.specificMonthWeek.day}${state.specificMonthWeek.monthWeek} ${this.yearDefaultChar
          }`.trim();
        break;
      default:
        throw new Error('Invalid cron yearly subtab selection');
    }
    this.cronForm.setValue(this.cron);
  }

  // private computeAdvancedExpression(expression: unknown) {
  //   this.cron = expression;
  //   this.cronForm.setValue(this.cron);
  // }

  public dayDisplay(day: string): string {
    return Days[day] as string;
  }

  public monthWeekDisplay(monthWeekNumber: string): string {
    return MonthWeeks[monthWeekNumber] as string;
  }

  public monthDisplay(month: number): string {
    return Months[month];
  }

  public monthDayDisplay(month: string): string {
    if (month === 'L') {
      return 'Last Day';
    } else if (month === 'LW') {
      return 'Last Weekday';
    } else if (month === '1W') {
      return 'First Weekday';
    } else {
      return `${month}${this.getOrdinalSuffix(month)}`;
    }
  }

  private getAmPmHour(hour: number): number {
    return this.options.use24HourTime ? hour : ((hour + 11) % 12) + 1;
  }

  private getHourType(hour: number): 'AM' | 'PM' {
    return this.options.use24HourTime ? undefined : hour >= 12 ? 'PM' : 'AM';
  }

  private hourToCron(hour: number, hourType: string): number {
    if (this.options.use24HourTime) {
      return hour;
    } else {
      return hourType === 'AM' ? (hour === 12 ? 0 : hour) : hour === 12 ? 12 : hour + 12;
    }
  }

  private handleModelChange(cron: string): void {
    if (this.isDirty) {
      this.isDirty = false;
      return;
    } else {
      this.isDirty = false;
    }

    if (!this.cronIsValid(cron)) {
      if (this.isCronFlavorQuartz) {
        throw new Error('Invalid cron expression, there must be 6 or 7 segments');
      }

      if (this.isCronFlavorStandard) {
        throw new Error('Invalid cron expression, there must be 5 segments');
      }
    }

    const origCron: string = cron;
    if (cron.split(' ').length === 5 && this.isCronFlavorStandard) {
      cron = `0 ${cron} *`;
    }

    const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = cron.split(' ');

    if (cron.match(/\d+ 0\/\d+ \* 1\/1 \* [?*]] \*/)) {
      this.activeTab = 'minutes';

      this.state.minutes.minutes = parseInt(minutes.substring(2), 10);
      this.state.minutes.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ 0\/\d+ 1\/1 \* [?*]] \*/)) {
      this.activeTab = this.lang('DATE.HOURLY');

      this.state.hourly.hours = parseInt(hours.substring(2), 10);
      this.state.hourly.minutes = parseInt(minutes, 10);
      this.state.hourly.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ 1\/\d+ \* [?*]] \*/)) {
      this.activeTab = this.lang('DATE.DAILY');

      this.state.daily.subTab = 'everyDays';
      this.state.daily.everyDays.days = parseInt(dayOfMonth.substring(2), 10);
      const parsedHours = parseInt(hours, 10);
      this.state.daily.everyDays.hours = this.getAmPmHour(parsedHours);
      this.state.daily.everyDays.hourType = this.getHourType(parsedHours);
      this.state.daily.everyDays.minutes = parseInt(minutes, 10);
      this.state.daily.everyDays.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ [?*]] \* MON-FRI \*/)) {
      this.activeTab = this.lang('DATE.DAILY');

      this.state.daily.subTab = 'everyWeekDay';
      const parsedHours = parseInt(hours, 10);
      this.state.daily.everyWeekDay.hours = this.getAmPmHour(parsedHours);
      this.state.daily.everyWeekDay.hourType = this.getHourType(parsedHours);
      this.state.daily.everyWeekDay.minutes = parseInt(minutes, 10);
      this.state.daily.everyWeekDay.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ [?*]] \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/)) {
      this.activeTab = this.lang('DATE.WEEKLY');
      this.selectOptions.days.forEach(weekDay => (this.state.weekly[weekDay] = false));
      dayOfWeek.split(',').forEach(weekDay => (this.state.weekly[weekDay] = true));
      const parsedHours = parseInt(hours, 10);
      this.state.weekly.hours = this.getAmPmHour(parsedHours);
      this.state.weekly.hourType = this.getHourType(parsedHours);
      this.state.weekly.minutes = parseInt(minutes, 10);
      this.state.weekly.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) 1\/\d+ [?*]] \*/)) {
      this.activeTab = this.lang('DATE.MONTHLY');
      this.state.monthly.subTab = 'specificDay';
      this.state.monthly.specificDay.day = dayOfMonth;
      this.state.monthly.specificDay.months = parseInt(month.substring(2), 10);
      const parsedHours = parseInt(hours, 10);
      this.state.monthly.specificDay.hours = this.getAmPmHour(parsedHours);
      this.state.monthly.specificDay.hourType = this.getHourType(parsedHours);
      this.state.monthly.specificDay.minutes = parseInt(minutes, 10);
      this.state.monthly.specificDay.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ [?*]] 1\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
      const day = dayOfWeek.substr(0, 3);
      const monthWeek = dayOfWeek.substr(3);
      this.activeTab = this.lang('DATE.MONTHLY');
      this.state.monthly.subTab = 'specificWeekDay';
      this.state.monthly.specificWeekDay.monthWeek = monthWeek;
      this.state.monthly.specificWeekDay.day = day;
      this.state.monthly.specificWeekDay.months = parseInt(month.substring(2), 10);
      const parsedHours = parseInt(hours, 10);
      this.state.monthly.specificWeekDay.hours = this.getAmPmHour(parsedHours);
      this.state.monthly.specificWeekDay.hourType = this.getHourType(parsedHours);
      this.state.monthly.specificWeekDay.minutes = parseInt(minutes, 10);
      this.state.monthly.specificWeekDay.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ [?*]] \*/)) {
      this.activeTab = 'yearly';
      this.state.yearly.subTab = 'specificMonthDay';
      this.state.yearly.specificMonthDay.month = parseInt(month, 10);
      this.state.yearly.specificMonthDay.day = dayOfMonth;
      const parsedHours = parseInt(hours, 10);
      this.state.yearly.specificMonthDay.hours = this.getAmPmHour(parsedHours);
      this.state.yearly.specificMonthDay.hourType = this.getHourType(parsedHours);
      this.state.yearly.specificMonthDay.minutes = parseInt(minutes, 10);
      this.state.yearly.specificMonthDay.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ [?*]] \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/)) {
      const day = dayOfWeek.substr(0, 3);
      const monthWeek = dayOfWeek.substr(3);
      this.activeTab = 'yearly';
      this.state.yearly.subTab = 'specificMonthWeek';
      this.state.yearly.specificMonthWeek.monthWeek = monthWeek;
      this.state.yearly.specificMonthWeek.day = day;
      this.state.yearly.specificMonthWeek.month = parseInt(month, 10);
      const parsedHours = parseInt(hours, 10);
      this.state.yearly.specificMonthWeek.hours = this.getAmPmHour(parsedHours);
      this.state.yearly.specificMonthWeek.hourType = this.getHourType(parsedHours);
      this.state.yearly.specificMonthWeek.minutes = parseInt(minutes, 10);
      this.state.yearly.specificMonthWeek.seconds = parseInt(seconds, 10);
    } else {
      this.activeTab = 'advanced';
      this.state.advanced.expression = origCron;
    }
  }

  private cronIsValid(cron: string): boolean {
    if (!cron) return false;
    const cronParts = cron.split(' ');
    return (
      (this.isCronFlavorQuartz && (cronParts.length === 6 || cronParts.length === 7)) ||
      (this.isCronFlavorStandard && cronParts.length === 5)
    );
  }

  private getDefaultState(): AntiPatternState {
    const [defaultHours, defaultMinutes, defaultSeconds] = this.options.defaultTime.split(':').map(Number);
    return <AntiPatternState>{
      minutes: {
        minutes: 1,
        seconds: 0,
      },
      hourly: {
        hours: 1,
        minutes: 0,
        seconds: 0,
      },
      daily: {
        subTab: 'everyDays',
        everyDays: {
          days: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        everyWeekDay: {
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
      },
      weekly: {
        MON: true,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false,
        hours: this.getAmPmHour(defaultHours),
        minutes: defaultMinutes,
        seconds: defaultSeconds,
        hourType: this.getHourType(defaultHours),
      },
      monthly: {
        subTab: 'specificDay',
        specificDay: {
          day: '1',
          months: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        specificWeekDay: {
          monthWeek: '#1',
          day: 'MON',
          months: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
      },
      yearly: {
        subTab: 'specificMonthDay',
        specificMonthDay: {
          month: 1,
          day: '1',
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        specificMonthWeek: {
          monthWeek: '#1',
          day: 'MON',
          month: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
      },
      advanced: {
        expression: this.isCronFlavorQuartz ? '0 15 10 L-2 * ? *' : '15 10 2 * *',
      },
    };
  }

  private getOrdinalSuffix(value: string): 'nd' | 'rd' | 'st' | 'th' {
    if (value.length > 1) {
      const secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === '1') {
        return 'th';
      }
    }

    const lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case '1':
        return 'st';
      case '2':
        return 'nd';
      case '3':
        return 'rd';
      default:
        return 'th';
    }
  }

  private getSelectOptions(): SelectOption {
    return {
      months: this.getRange(1, 12),
      monthWeeks: [
        { label: '1st', value: '#1' },
        { label: '2st', value: '#2' },
        { label: '3st', value: '#3' },
        { label: '4st', value: '#4' },
        { label: '5st', value: '#5' },
        { label: 'Last', value: 'L' },
      ],
      // MonthWeeks: ['#1', '#2', '#3', '#4', '#5', 'L'],
      days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      minutes: this.getRange(0, 59),
      fullMinutes: this.getRange(0, 59),
      seconds: this.getRange(0, 59),
      hours: this.getRange(1, 23),
      monthDays: this.getRange(1, 31),
      monthDaysWithLasts: ['1W', ...[...this.getRange(1, 31).map(String)], 'LW', 'L'],
      monthDaysWithOutLasts: [
        ...[
          ...this.getRange(1, 31).map(v => ({
            label: this.monthDayDisplay(String(v)),
            value: String(),
          })),
        ],
      ],
      hourTypes: ['AM', 'PM'],
    };
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    const result: number[] = [];
    for (let count = 0; count <= length; count++) {
      result.push(count);
    }
    result.shift();
    return result;
  }

  /*
   * ControlValueAccessor
   */
  public onChange: (payload?: string) => void = (): void => void 0;
  public onTouched: () => void = (): void => void 0;

  public writeValue(obj: string): void {
    this.cron = obj;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

interface SelectOption {
  months: number[];
  monthWeeks: SelectItem<string>[];
  days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  minutes: number[];
  fullMinutes: number[];
  seconds: number[];
  hours: number[];
  monthDays: number[];
  monthDaysWithLasts: string[];
  monthDaysWithOutLasts: {
    label: string;
    value: string;
  }[];
  hourTypes: ['AM', 'PM'];
}

interface AntiPatternState {
  seconds: number;
  hours: number;
  hourType: string;
  subTab: 'everyDays' | 'everyWeekDay' | 'specificDay' | 'specificMonthDay' | 'specificMonthWeek' | 'specificWeekDay';
  minutes: {
    minutes: number;
    seconds: number;
  };
  hourly: {
    hours: number;
    minutes: number;
    seconds: number;
  };

  everyDays: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };
  everyWeekDay: {
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };
  specificDay: {
    day: string;
    months: number;
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };
  specificWeekDay: {
    monthWeek: string;
    day: string;
    months: number;
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };
  specificMonthDay: {
    month: number;
    day: string;
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };
  specificMonthWeek: {
    monthWeek: string;
    day: string;
    month: number;
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };

  daily: {
    subTab: 'everyDays' | 'everyWeekDay';
    everyDays: {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
      hourType: 'AM' | 'PM';
    };
    everyWeekDay: {
      hours: number;
      minutes: number;
      seconds: number;
      hourType: 'AM' | 'PM';
    };
  };
  weekly: {
    MON: boolean;
    TUE: boolean;
    WED: boolean;
    THU: boolean;
    FRI: boolean;
    SAT: boolean;
    SUN: boolean;
    hours: number;
    minutes: number;
    seconds: number;
    hourType: 'AM' | 'PM';
  };
  monthly: {
    subTab: 'specificDay' | 'specificWeekDay';
    specificDay: {
      day: string;
      months: number;
      hours: number;
      minutes: number;
      seconds: number;
      hourType: 'AM' | 'PM';
    };
    specificWeekDay: {
      monthWeek: string;
      day: string;
      months: number;
      hours: number;
      minutes: number;
      seconds: number;
      hourType: 'AM' | 'PM';
    };
  };
  yearly: {
    subTab: 'specificMonthDay' | 'specificMonthWeek';
    specificMonthDay: {
      month: number;
      day: string;
      hours: number;
      minutes: number;
      seconds: number;
      hourType: 'AM' | 'PM';
    };
    specificMonthWeek: {
      monthWeek: string;
      day: string;
      month: number;
      hours: number;
      minutes: number;
      seconds: number;
      hourType: 'AM' | 'PM';
    };
  };
  advanced: {
    expression: string;
  };
}

interface MinutesFormGroup {
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
}

interface HourlyFormGroup {
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
}

interface DailyFormGroup {
  subTab: FormControl<string>;
  everyWeekDay: FormGroup<EveryWeekDayFormGroup>;
  everyDays: FormGroup<EveryDaysFormGroup>;
}

interface WeeklyFormGroup {
  MON: FormControl<boolean>;
  TUE: FormControl<boolean>;
  WED: FormControl<boolean>;
  THU: FormControl<boolean>;
  FRI: FormControl<boolean>;
  SAT: FormControl<boolean>;
  SUN: FormControl<boolean>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}

interface MonthlyFormGroup {
  subTab: FormControl<string>;
  specificDay: FormGroup<SpecificDayFormGroup>;
  specificWeekDay: FormGroup<SpecificWeekDayFormGroup>;
}

interface YearlyFormGroup {
  subTab: FormControl<string>;
  specificMonthDay: FormGroup<SpecificMonthDayFormGroup>;
  specificMonthWeek: FormGroup<SpecificMonthWeekFormGroup>;
}

interface AdvancedFormGroup {
  expression: FormControl<string>;
}

interface EveryWeekDayFormGroup {
  days: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}

interface EveryDaysFormGroup {
  days: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}

interface SpecificDayFormGroup {
  day: FormControl<string>;
  months: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}

interface SpecificWeekDayFormGroup {
  monthWeek: FormControl<string>;
  day: FormControl<string>;
  months: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}

interface SpecificMonthDayFormGroup {
  day: FormControl<string>;
  month: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}

interface SpecificMonthWeekFormGroup {
  monthWeek: FormControl<string>;
  day: FormControl<string>;
  month: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
  hourType: FormControl<'AM' | 'PM'>;
}
