import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: '[faIcon]',
  templateUrl: './fa-icon.component.html',
  styleUrls: ['./fa-icon.component.sass'],
})
export class FaIconComponent implements OnChanges {

  @Input('faIcon') public ikon: IconDefinition;
  @Input('iconPos') public ikonPos: 'left' | 'right' = 'left';
  @Input('faSize') public _ikonSize: number;

  public get ikonSize(): string {
    return String(this._ikonSize >= 0 ? this._ikonSize : 1) + 'rem';
  }

  private readonly _el: HTMLElement;
  public get el(): HTMLElement {
    return this._el;
  }

  private _textContent: boolean;
  public get textContent(): boolean {
    return this._textContent;
  }

  constructor(private readonly element: ElementRef<HTMLElement>) {
    this._el = this.element.nativeElement;
  }

  public ngOnChanges(): void {
    setTimeout(() => {
      // console.debug('fa-icon:', [this._el, this.textContent]);
      this._textContent = this._el.textContent.trim().length > 0;
      if (!this._textContent) {
        if (this._el.tagName === 'BUTTON' && this._el.classList.contains('p-button'))
          this._el.classList.add('p-button-icon-only');
        // console.debug('faicon', [this.el, this.el.className, this.el.tagName]);
      }
    });
  }
}
