<div id="commPeriod">
  <div *ngIf="displayList && (selectedDate || rangeDates)" class="comm_period_wrap">
    <label>{{ 'LABEL.PERIOD' | translate }}:</label>
    <p-dropdown
      scrollHeight="400px"
      [group]="true"
      [options]="displayList"
      [optionLabel]="'label'"
      [(ngModel)]="selectedDate"
      (ngModelChange)="onPeriodChange($event)"
      (click)="checkCustomRangeOption()">
      <!-- period option label -->
      <ng-template let-item pTemplate="selectedItem">
        <div class="period_value">
          {{ item?.label }}
          <span class="text-secondary ms-2">{{ item?.title }}</span>
        </div>
      </ng-template>

      <!-- period group label -->
      <ng-template let-group pTemplate="group">
        <div class="period_group">
          {{ group?.label }}
        </div>
      </ng-template>

      <!-- period group value -->
      <ng-template let-item pTemplate="item">
        <div class="period_value">
          {{ item?.label }}
          <span></span>
          <span class="text-secondary ms-2">{{ item?.title }}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <ng-container *ngIf="isCustomDateModel">
    <div class="range_wrap">
      <p-calendar
        #calender
        inputId="range"
        placeholder="Select Date Rage"
        dateFormat="mm/dd"
        selectionMode="range"
        [readonlyInput]="true"
        [minDate]="minDateValue"
        [maxDate]="maxDateValue"
        [inline]="isCustomDateModel"
        [selectOtherMonths]="true"
        [(ngModel)]="rangeDates">
        <p-footer>
          <div class="foot_btn">
            <button
              pButton
              aria-label="Button"
              type="button"
              label="Clear"
              class="btn_clear"
              (click)="onClearDate()"></button>
            <div>
              <button
                pButton
                aria-label="Button"
                type="button"
                label="Done"
                class="btn_done"
                (click)="doneDateRange(); $event.stopPropagation()"></button>
              <button
                pButton
                aria-label="Button"
                type="button"
                label="Close"
                (click)="isCustomDateModel = false; $event.stopPropagation()"></button>
            </div>
          </div>
        </p-footer>
      </p-calendar>
    </div>
  </ng-container>
</div>
