// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
// Caloudi
import { AuthUser } from '@core/model';
import environment from '@env';
import { CommonUtil, DateUtil } from '@util';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
import { getExpiresAt, getUser } from '@Selectors';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  /** Environment */
  private readonly minutesToRefresh: number = environment.apiServer.minutesToRefresh;
  private readonly expiresAtSub$: BehaviorSubject<number>;
  private readonly currentUserSub$: BehaviorSubject<AuthUser>;
  private expiresAt$: number;
  private currentUser$: AuthUser;

  constructor(private readonly logger: NGXLogger, private readonly store: Store<AppState>) {
    this.expiresAtSub$ = CommonUtil.observable2BehaviorSub<number>(this.store.select(getExpiresAt), 0);
    this.currentUserSub$ = CommonUtil.observable2BehaviorSub<AuthUser>(this.store.select(getUser), <AuthUser>{});
    this.expiresAtSub$.subscribe(v => (this.expiresAt$ = v));
    this.currentUserSub$.subscribe(v => (this.currentUser$ = v));
  }

  public get currentUser(): BehaviorSubject<AuthUser> {
    return this.currentUserSub$;
  }

  public get currentUserItem(): AuthUser {
    return this.currentUser$;
  }

  /**
   * Get Token duration
   *
   * @returns duration(unit:sec)
   *
   */
  public getTokenDuration(): AuthDuration {
    let currentTimeEpoch = DateUtil.getTimeEpoch(new Date());
    let expireTimeEpoch = this.expiresAt$;
    let expireDuration = expireTimeEpoch - currentTimeEpoch;
    let safetyInterval = this.minutesToRefresh * 60;
    let refreshDuration = expireDuration - safetyInterval;
    this.logger.trace('Expire Token Duration:', expireDuration);
    this.logger.trace('Refresh Token Duration:', refreshDuration);
    return { expireDuration, refreshDuration };
  }
}

interface AuthDuration {
  expireDuration: number;
  refreshDuration: number;
}
