import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    HINT: {
      ACCESS_GROUP_DELETED: '访问组已删除',
      ACCESS_GROUP_UPDATED: '访问组已更新',
      ACTIVATE_ORGANIZATION_UNIT_NOTE: '确定要启用选取的组织单位?',
      ACTIVATE_USER_GROUP_NOTE: '确定要启用选取的用户群组?',
      ADD_NEW_ACCESS_GROUP: '访问组已添加',
      ADD_TO_ADVISORY_NOTE: '节省费用与覆盖率会依当前使用量变化',
      ADMIN_USER_HAS_BEEN_UPDATED: '管理员已更新',
      ANNOUNCEMENT_HAS_BEEN_CREATED: '公告已创建',
      ANNOUNCEMENT_HAS_BEEN_DELETED: '公告已删除',
      ANNOUNCEMENT_HAS_BEEN_UPDATED: '公告已更新',
      ANNOUNCEMENT_TYPE_HAS_BEEN_CREATED: '公告类别已创建',
      ANNOUNCEMENT_TYPE_HAS_BEEN_DELETED: '公告类别已删除',
      ANNOUNCEMENT_TYPE_HAS_BEEN_UPDATED: '公告类别已更新',
      ASSIGNMENT_LIST_HAS_BEEN_UPDATED: '指派列表已更新',
      AUTOCOMPLETE_INFO: '先输入关键字查询，再从搜索结果中进行选择',
      BC_CUSTOMER_INFO: '客户结账周期',
      BC_PARTNER_INFO: '合作伙伴结账周期',
      COMMENT_HAS_BEEN_ADDED: '评论已添加',
      CONFIRM_DELETE_TAG: '您确定要删除此标签吗？此操作将影响所有与此标签相关的供应商。',
      CONFIRM_DELETE_TAG_VALUE: '您确定要删除此值吗？此操作将影响所有与此标签值相关的供应商。',
      CONTACT_HAS_BEEN_CREATED: '联系人已添加',
      CONTACT_HAS_BEEN_DELETED: '联系人已删除',
      CONTACT_HAS_BEEN_UPDATED: '联系人已更新',
      COST_DEVIATION_TOOLTIP: '依照预估值所算费用差',
      CSP_EMPTY_BAR_INFO: '请选择一家公司查询结果',
      CUSTOMER_HAS_BEEN_CREATED: '已创建客户资料',
      CUSTOMER_HAS_BEEN_UPDATED: '已更新客户资料',
      CUSTOMER_ORDER_HISTORY_CANCEL: '确定要取消此笔订单吗?',
      CUSTOMER_SUBSCRIPTION_ACTIVE_INFO: '确定要启用订阅吗?',
      CUSTOMER_SUBSCRIPTION_SPSENDED_INFO: '确定要暂停订阅吗?',
      DEFAULT_PRICING_NOTE: '无指定的定价配置，因此适用默认值',
      DELETE_ACCESS_GROUP: '确定要删除访问组？',
      DELETE_ACCESS_GROUP_FAILED: '删除访问组失败',
      DELETE_ANNOUNCEMENT_NOTE: '确定要删除选取的公告?',
      DELETE_ANNOUNCEMENT_TYPE_NOTE: '确定要删除{{ tagName }}类别?',
      DELETE_BUDGET_NOTE: '确定要删除这份预算表?',
      DELETE_CONTACT_NOTE: '确定要删除选取的联系人?',
      DELETE_DISCOUNT_PERCENT_RULE_NOTE: '确定要删除选取的折扣率规则?',
      DELETE_EAENROLLMENT_NOTE: '确定要删除选取的EA Enrollment?',
      DELETE_FEATURE_PACK_NOTE: '确定要删除功能组 "{{ filterName }}" ?',
      DELETE_MAPPING_RULE_NOTE: '确定要删除选取的规则?',
      DELETE_ORGANIZATION_NOTE: '确定要删除选取的组织?',
      DELETE_PERSONAL_FILTER_NOTE: '确定要删除保存的过滤「{{ filterName }}」?',
      DELETE_PERSONAL_VIEW_NOTE: '确定要删除保存的视图「{{ viewName }}」?',
      DELETE_PRICING_CONFIG_NOTE: '确定要删除选取的定价配置?',
      DELETE_PROBLEM_SUBTYPE_NOTE: '确定要删除选取的问题子类别?',
      DELETE_PROBLEM_TYPE_NOTE: '确定要删除选取的问题主类别?',
      DELETE_RECOMMENDATION_NOTE: '确定要删除选取的建议?',
      DELETE_RESELLER_CONFIRM: '确定要删除 "{{ companyName }}" ?',
      DELETE_RI_RECOMM_REPORT_NOTE: '确定要删除选取的报表?',
      DELETE_SKU_RULE_NOTE: '确定要删除选取的SKU规则?',
      DELETE_USAGE_ACCESS_CONTROL_NOTE: '确定要删除费用访问限制「{{ filterName }}」?',
      DELETE_USER_NOTE: '确定要删除选取的用户? 删除用户帐户也会删除其权限。',
      DESTROY_PROVISION_NOTE: '确定要移除该项部件?',
      DIMENSION_RULE_HAS_BEEN_CREATED: '维度对应规则已创建',
      DIMENSION_RULE_HAS_BEEN_DELETED: '维度对应规则已删除',
      DIMENSION_RULE_HAS_BEEN_IMPORTED: '维度对应规则已导入',
      DIMENSION_RULE_HAS_BEEN_UPDATED: '维度对应规则已更新',
      EA_ENROLLMENT_SAVE_CONFIRM: '确定要保存此次EA注册设定修改?',
      EMAIL_RECIPIENTS_HINT: '在每一个email 输入完毕后请按”Enter”键',
      ENGINEER_OWNER_HAS_BEEN_ASSIGNED: '工程师负责人已指派',
      FILE_UPLOADED: '文件已上传',
      INTERVAL_INFO: '设置间隔以限制每次显示的结果数量',
      INVOICE_BILLING_PERIOD: '此发票适用于结算期',
      LINE_ITEM_HAS_BEEN_UPDATED: '条目已更新',
      LINE_ITEM_HAS_BEEN_VOIDED: '条目已作废',
      LOADING_MESSAGE: '正在处理请求，请稍候...',
      MAKE_PAYMENT_NOTE: '您确定要付款吗?',
      MAX_CPU_P_95_NOTE: '最大CPU 95百分位数',
      MAX_MEMORY_P_95_NOTE: '最大内存95百分位数',
      MAX_TOTAL_NETWORK_P_95_NOTE: '最大网络95百分位数',
      MEMO_UPDATED: '备注已更新',
      MUST_CONTAIN8888: 'DNS 列表必须包含 IP 8.8.8.8。',
      NAME_EMPTY_ALERT: '至少需要输入一个字符',
      NEW_ACCESS_GROUP_ADDED: '访问组已添加',
      NEW_ACCESS_GROUP_FAILED: '访问组添加失败',
      NEW_USER_HAS_BEEN_CREATED: '用户已创建',
      ORGANIZATION_HAS_BEEN_CREATED: '组织已创建',
      ORGANIZATION_HAS_BEEN_DELETED: '组织已删除',
      ORGANIZATION_HAS_BEEN_UPDATED: '组织已更新',
      ORGANIZATION_UNIT_HAS_BEEN_CREATED: '组织单位已创建',
      ORGANIZATION_UNIT_HAS_BEEN_DELETED: '组织单位已删除',
      ORGANIZATION_UNIT_HAS_BEEN_UPDATED: '组织单位已更新',
      PAYMENT_SUCCESSFUL: '付款成功',
      PLACE_LINE_ITEM_ORDER_NOTE: '确定要下订此条目吗?',
      PLACE_ORDER_NOTE: '确定要下订单吗?',
      PLEASE_WAIT: '请稍候 ...',
      PRICING_CONFIG_HAS_BEEN_CREATED: '定价配置已创建',
      PRICING_CONFIG_HAS_BEEN_DELETED: '定价配置已删除',
      PRICING_CONFIG_HAS_BEEN_UPDATED: '定价配置已更新',
      PRIORITY_HAS_BEEN_UPDATED: '优先顺序已更新',
      PROBLEM_SUBTYPE_HAS_BEEN_CREATED: '问题子类别已创建',
      PROBLEM_SUBTYPE_HAS_BEEN_DELETED: '问题子类别已删除',
      PROBLEM_SUBTYPE_HAS_BEEN_UPDATED: '问题子类别已更新',
      PROBLEM_TYPE_HAS_BEEN_CREATED: '问题主类别已创建',
      PROBLEM_TYPE_HAS_BEEN_DELETED: '问题主类别已删除',
      PROBLEM_TYPE_HAS_BEEN_UPDATED: '问题主类别已更新',
      PURCHASE_AGREEMENT_INFO: '客户必须接受 Microsoft 客户协议才能购买产品',
      PURCHASE_AGREEMENT_VISIT: '要接受 Microsoft 客户协议，请访问',
      PURCHASE_RI_INFO: '若要购买RI，必须先订阅一项Azure Plan',
      RECOMMENDATIONS_NOTICS: '建议是基于最近30天的VM使用情况。实际节省的金额可能会有所不同。',
      RECOMMENDATION_HAS_BEEN_DELETED: '建议已删除',
      REPORT_HAS_BEEN_DELETED: '报表已删除',
      REPORT_IS_BEING_GENERATED: '报表生成中',
      RESELLER_PREREQUISITES_NOTE: '此经销商尚未符合购买资格，无法为客户下单。',
      RESERLLER_PROFILE_LINK_NOTE: '查看经销商资料进行编辑',
      RESOURCE_PLAN_HAS_BEEN_CREATED: '资源规划已创建。',
      RESOURCE_PLAN_HAS_BEEN_DELETED: '资源规划已删除。',
      RESOURCE_PLAN_HAS_BEEN_UPDATED: '资源规划已更新。',
      RESOURCE_TAG_INFO: '先输入Key进行查询并加上等号，再输入Value',
      RI_ANALYSIS_COST_WITH_RECOMMENDATION_NOTE: '新使用小时数以及当前和新RI使用率的预估费用',
      RI_ANALYSIS_COVERAGE_RATE_NOTE: '预估依小时数',
      RI_ANALYSIS_CURRENT_COST_NOTE: '当前使用小时数和RI使用率的预估费用',
      RI_ANALYSIS_RESET_NOTE: '重置至初始RI推荐',
      RI_COVERAGE_RATE_COST_NOTE: 'RI费用 / (使用费用 + RI费用)',
      RI_COVERAGE_RATE_NOTE: 'RI保留时数 / (使用时数 + RI保留时数)',
      RI_COVERAGE_RATE_QUANTITY_NOTE: 'RI保留数量 / (使用数量 + RI保留数量)',
      RI_DEDUCTED_HOURS_NOTE: '含弹性比例的使用时数',
      RI_NEW_COVERAGE_RATE_NOTE: '新RI保留时数 / (新使用时数 + 新RI保留时数)',
      RI_NEW_COVERAGE_RATE_QUANTITY_NOTE: '新RI保留数量 / (新使用数量 + 新RI保留数量)',
      RI_RECOMMENDATIONS_NOTICS: 'RI建议是基于最近30天的VM使用情况。实际节省的金额可能会有所不同。',
      RI_RECOMM_REPORT_NOTICES: 'RI建议是基于最近30天的VM使用情况、一年以及共用。实际节省的金额可能会有所不同。',
      RI_UTILIZATION_RATE_NOTE: '抵扣时数 / 保留时数',
      RI_UTILIZATION_RATE_QUANTITY_NOTE: '抵扣数量 / 保留数量',
      SALES_ORDER_HAS_BEEN_VOIDED: '销售订单已作废',
      SAME_NAME_ALERT: '已有相同名称存在',
      SAVING_RANGE_INPUT_HINT: '此服务类别的节省最大值为 ${{ maxSavings }}。 请输入数字 $0 ~ ${{ maxSavings }}。',
      SAVING_RANGE_MIN_MAX_HINT: '节省最小值不可超过最大值',
      SET_UP_ACCOUNT_NOTE: '请先建立一个付款账户。',
      STATUS_HAS_BEEN_UPDATED: '状态已更新',
      SUBSCRIPTION_COUNTS_ONBOARD_NOTE: '启用时的订阅数量',
      SUPPORT_OWNER_HAS_BEEN_ASSIGNED: '支持负责人已指派',
      SUPPORT_TICKET_HAS_BEEN_CREATED: '支持请求已创建',
      SUSPEND_ORGANIZATION_UNIT_NOTE: '确定要暂停选取的组织单位?',
      SUSPEND_USER_GROUP_NOTE: '确定要暂停选取的用户群组?',
      SWITCH_TO_USERID_NOTE: '确认要转换为「{{ userId }}」?',
      SWITCH_TO_USERID_SUCESSFULLY: '转换为 「{{ userId }}」 成功',
      TAG_MANAGEMENT_INFO: '成功的第三方风险管理计划中的关键组成部分是标签。您最多可以创建三个标签。这些标签将在新增供应商时生成自定义列，并且第一个标签将用于仪表板上显示。',
      TOTAL_REVENUE_INFO: '总收入是根据过滤项目范围的总和',
      UNSUBSCRIBE_SUBSCRIPTION: '确认要取消订阅 「{{ subscriptionName }}」 ?',
      UPDATE_ACCESS_GROUP: '访问组已更新',
      UPDATE_ACCESS_GROUP_FAILED: '访问组更新失败',
      UPLOADING: '上传中...',
      UPLOAD_FAILED: '上传失败',
      USER_GROUP_HAS_BEEN_CREATED: '用户群组已创建',
      USER_GROUP_HAS_BEEN_DELETED: '用户群组已删除',
      USER_GROUP_HAS_BEEN_UPDATED: '用户群组已更新',
      VOID_LINE_ITEM_NOTE: '确定要将此条目作废?',
      VOID_SALES_ORDER_NOTE: '确定要将此订单作废?',
      WRONG_EMAIL_FORMAT: '错误的邮箱格式',
    },
  },
};
