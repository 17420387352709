// Angular
import { Component, ElementRef, EventEmitter, HostBinding, Injector, Input, NgZone, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
// D3
import * as d3 from 'd3';
// Interface
import { ChartData, ChartItem, D3Chart, DataInterpretation, DataType, MarginInfo, RawData } from '@base/model';
import { CommonUtil } from '@util';

@Component({
  selector: 'caloudi-multivariate-anomaly-chart, caloudi-anomaly-chart',
  templateUrl: './multivariate-anomaly-chart.component.html',
  styleUrls: ['./multivariate-anomaly-chart.component.sass'],
})
export class MultivariateAnomalyChartComponent extends BaseComponent implements OnInit, OnChanges {
  @HostBinding('class') public readonly klass: string = 'multivariate_anomaly_chart';

  @Input('data') public $data: RawData;
  @Input('margin') public $margin: MarginInfo = { left: 20, top: 40, right: 20, bottom: 40 };
  @Input('height') public $height: number;
  @Input('width') public $width: number;
  @Input('line') public $displayGuideLine: boolean = true;
  @Input('range') public $yRange: number = 30;
  @Input('timeFormat') public $timeFormat: string = '%d-%b %H:%M';

  @Output('sid') public $sid: EventEmitter<number> = new EventEmitter<number>();

  private get margin(): MarginInfo {
    return {
      ...this.$margin,
      inline: this.$margin.left + this.$margin.right,
      block: this.$margin.top + this.$margin.bottom,
    };
  }

  private readonly sid: number = Date.now();
  private readonly colors = {
    strokeline: 'var(--text-color-secondary)',
    colorSeries: [
      '#e34d4d',
      '#00ff00',
      '#0000ff',
      '#00ffff',
      '#ff00ff',
      '#ffff00',
      '#ffaa00',
      '#ff66cc',
      '#ffcc00',
      '#ffaaff',
      '#aaffff',
      '#aa00aa',
    ],
  };

  private chartData: ChartItem[];
  private width: number;
  private height: number;
  private chartHeight: number;
  private readonly chartMargin: number = 50;
  private svg: D3Chart;

  private readonly zoneCorrection: (d: Date | string) => Date = (d: Date | string): Date =>
    new Date(new Date(d).getTime() + new Date().getTimezoneOffset() * 6e4);

  constructor(
    public readonly injector: Injector,
    private readonly ele: ElementRef<HTMLElement>,
    private readonly ngZone: NgZone
  ) {
    super(injector);
    window.onresize = (): void =>
      this.ngZone.run(() => {
        this.resize();
        this.bindChartData();
      });
    window.matchMedia('print').onchange = () => {
      this.resize();
      this.bindChartData();
    };
  }

  public ngOnInit(): void {
    this.logger.debug('chart init:', [this.ele.nativeElement]);
    this.$sid.emit(this.sid);
    this.width = this.$width || this.ele.nativeElement.clientWidth;
    this.height = this.$height || 200;
    this.chartHeight = this.height;
    this.svg = d3.select<HTMLElement, ChartItem>(this.ele.nativeElement).append('svg');
  }

  public ngOnChanges(changes: AnomalyChartChanges): void {
    this.logger.debug('any changes:', [{ ...changes }]);

    if (CommonUtil.ngIsChanges(changes.$displayGuideLine, true) && this.svg)
      this.svg
        .select<SVGGElement>('g.anomaly_line_data')
        .transition()
        .duration(400)
        .style('opacity', this.$displayGuideLine ? 1 : 0);

    if (CommonUtil.ngIsChanges(changes.$yRange, true) && this.svg)
      this.chartData.forEach((_d, i): void => {
        const ys: D3Chart = this.svg
          .select(`g.axis_group_${i} .axis_y`)
          .transition()
          .duration(400) as unknown as D3Chart;
        ys.call(this.basicFunc(i).yAxis);
      });

    if (!CommonUtil.ngIsChanges(changes.$data)) return;
    this.dataBinding();
    this.resize();
    this.chartData.forEach((d: ChartItem, i: number): void => this.drawChart(d, i));
    this.svg.selectAll('g.anomaly_line_data line').remove();
    this.bindChartData();

    this.logger.debug('svg init:', [this.svg, `${this.width}x${this.chartHeight}`, this.$data, this.chartData]);
  }

  private dataBinding(): void {
    const dataSet: DataType[] = this.$data.result;
    const firstSet: DataInterpretation[] = dataSet[0].value.interpretation;

    this.chartData = firstSet.map(
      (data, i): ChartItem => ({
        title: data.variable,
        data: dataSet.map(
          ({ value, timestamp }): ChartData => ({
            time: this.zoneCorrection(new Date(timestamp)),
            score: value.interpretation[i].score,
            isAnomaly: value.isAnomaly,
            originalValue: value.interpretation[i].original_value,
            originalNormalizedValue: value.interpretation[i].original_normalized_value,
          })
        ),
      })
    );

    this.chartData.unshift({
      title: 'Resources Anomaly Score',
      threshold: this.$data?.summary?.threshold || 0,
      data: dataSet.map(d => ({
        time: this.zoneCorrection(new Date(d.timestamp)),
        score: d.value.score,
      })),
    });

    this.chartHeight = (firstSet.length + 1) * this.height + this.chartData.length * this.chartMargin;
  }

  private resize(): void {
    this.width = this.$width || this.ele.nativeElement.clientWidth;
    this.svg
      .attr('viewBox', [0, 0, this.width, this.chartHeight])
      .attr('width', this.width)
      .attr('height', this.chartHeight);
  }

  private basicFunc(i: number = 0): {
    xAxis: typeof xAxis;
    yAxis: typeof yAxis;
    xScale: typeof xScale;
    yScale: typeof yScale;
    dataPath: typeof dataPath;
  } {
    const yPos: number = (this.height + this.chartMargin) * i + this.chartMargin;
    const data: ChartItem = this.chartData[i];

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data.data, d => d.time))
      .range([this.margin.left * 2, this.width - this.margin.right]);

    const yScale = d3
      .scaleLinear()
      // .domain(d3.extent(data.data, d => d.originalValue ?? d.score))
      .domain([
        d3.min(data.data, d => d.originalValue ?? d.score),
        d3.max(data.data, d =>
          data.threshold > (d.originalValue ?? d.score) ? data.threshold * 1.2 : d.originalValue ?? d.score),
      ])
      .range([this.height - this.margin.block, 0])
      .nice();

    const xAxis = (x: D3Chart): D3Chart<SVGTextElement, SVGGElement> =>
      x
        .attr('transform', `translate(${0}, ${yPos + this.height - this.margin.block})`)
        .attr('stroke-width', 0.5)
        .attr('stroke', this.colors.strokeline)
        .attr('fill', 'none')
        .call(
          d3
            .axisBottom<Date>(xScale)
            .ticks(d3.timeHour.every(0.5))
            .tickFormat(d3.timeFormat(this.$timeFormat))
            .tickSizeOuter(-this.height + this.margin.block)
        )
        .selectAll<SVGTextElement, ChartItem>('text:first-of-type')
        .attr('text-anchor', 'end')
        .attr('y', 8)
        .attr('x', 0)
        .attr('fill', 'var(--text-color-secondary)')
        .style('transform', 'rotate(-30deg)');

    const yAxis = (y: D3Chart): D3Chart<SVGTextElement, SVGGElement> =>
      y
        .attr('transform', `translate(${this.margin.left * 2}, ${yPos})`)
        .attr('stroke-width', 0.5)
        .attr('stroke', this.colors.strokeline)
        .attr('fill', 'none')
        .call(
          d3
            .axisLeft<number>(yScale)
            .ticks((this.height - this.margin.block) / this.$yRange)
            .tickFormat(v => (v > 1e4 ? v.toExponential() : v.toString()))
            .tickSizeInner(-this.width + this.margin.inline)
            .tickSizeOuter(-this.width + this.margin.inline + this.margin.right)
            .tickPadding(6)
        )
        .selectAll<SVGTextElement, ChartItem>('text')
        .attr('text-anchor', 'end')
        .attr('x', -4);

    const dataPath = d3
      .line<ChartData>()
      .x(d => xScale(d.time))
      .y(d => yPos + yScale(d.originalValue ?? d.score))
      .curve(d3.curveMonotoneX);

    return {
      xAxis: xAxis,
      yAxis: yAxis,
      xScale: xScale,
      yScale: yScale,
      dataPath: dataPath,
    };
  }

  private drawChart({ data, threshold, title }: ChartItem, i: number = 0): void {
    const yPos: number = (this.height + this.chartMargin) * i + this.chartMargin;
    const format = d3.timeFormat('%d-%b-%Y');
    const timeRange = `
    (${format(data[0].time)} - ${format(data[data.length - 1].time)})`;
    // (${data.data[0].time.toDateString()} - ${data.data[data.data.length - 1].time.toDateString()})`;

    if (i === 0) {
      const anomalyLineGroup: D3Chart = this.svg.append('g').attr('class', 'anomaly_line_group');

      anomalyLineGroup.append('defs').append('clipPath').attr('id', 'clip_anomaly_line');

      anomalyLineGroup.append('g').attr('class', 'anomaly_line_data').attr('clip-path', 'url(#clip_anomaly_line)');
    }

    this.svg
      .select<SVGClipPathElement>('clipPath#clip_anomaly_line')
      .append('rect')
      .attr('width', this.width - this.margin.right - this.margin.left * 2)
      .attr('height', this.height - this.margin.block)
      .attr('x', this.margin.left * 2)
      .attr('y', yPos);

    const group: D3Chart = this.svg.append('g').attr('class', `group_${i}`).attr('pos', yPos);

    const clip_group: D3Chart<SVGDefsElement> = group.append('defs');

    const label_group: D3Chart = group.append('g').attr('class', `label_group_${i}`);

    const chart_group: D3Chart = group.append('g').attr('class', `chart_group_${i}`);

    // i === this.chartData.length - 1 && this.svg
    // .append('g')
    // .attr('class', `anomaly_line_group`);

    clip_group
      .append('clipPath')
      .attr('id', `clip_axis_${i}`)
      .append('rect')
      .attr('width', this.width - this.margin.right + 1)
      .attr('height', this.height + this.margin.top + 2)
      .attr('x', 0)
      .attr('y', yPos - this.margin.top - 1);

    clip_group
      .append('clipPath')
      .attr('id', `clip_data_${i}`)
      .append('rect')
      .attr('width', this.width - this.margin.right - this.margin.left * 2)
      .attr('height', this.height - this.margin.block)
      .attr('x', this.margin.left * 2)
      .attr('y', yPos);

    chart_group.append('g').attr('class', `axis_group_${i}`).attr('clip-path', `url(#clip_axis_${i})`);

    chart_group.append('g').attr('class', `data_group_${i}`).attr('clip-path', `url(#clip_data_${i})`);

    chart_group.select<SVGGElement>(`g.axis_group_${i}`).append('g').attr('class', 'axis_x');

    chart_group.select<SVGGElement>(`g.axis_group_${i}`).append('g').attr('class', 'axis_y');

    chart_group
      .select<SVGGElement>(`g.data_group_${i}`)
      .append('path')
      .attr('class', 'data_path')
      .attr('fill', 'none')
      .attr('stroke', this.colors.colorSeries[0]);

    label_group
      .append('text')
      .attr('fill', 'var(--text-color-secondary)')
      .attr('font-size', '1.25rem')
      .attr('font-weight', '700')
      .attr('text-anchor', 'middle')
      .attr('class', 'text_title')
      .attr('x', this.width / 2)
      .attr('y', yPos - 12)
      .text(`${title}`);

    label_group
      .append('text')
      .attr('fill', 'var(--text-color-secondary)')
      .attr('font-weight', '700')
      .attr('text-anchor', 'middle')
      .attr('class', 'text_x')
      .attr('x', this.width / 2)
      .attr('y', yPos + this.height - this.margin.bottom / 2)
      .text('Date' + timeRange);

    label_group
      .append('text')
      .attr('fill', 'var(--text-color-secondary)')
      .attr('font-weight', '700')
      .attr('class', 'text_y')
      .attr('x', 0)
      .attr('y', yPos - 12)
      .text(i > 0 ? 'Original Value' : `Score (threshold: ${threshold})`);

    if (threshold > 0 && i === 0)
      chart_group
        .select<SVGGElement>('g.data_group_0')
        .append('line')
        .attr('class', 'threshold')
        .style('stroke-dasharray', '6, 3')
        .style('stroke', 'blue')
        .style('stroke-width', 1)
        .attr('x1', this.margin.left * 2)
        .attr('x2', this.width - this.margin.right)
        .attr('y1', yPos + this.height - this.margin.block)
        .attr('y2', yPos + this.height - this.margin.block);
  }

  private bindChartData(): void {
    this.chartData.forEach(({ data, threshold }: ChartItem, i: number): void => {
      this.svg.select<SVGRectElement>(`clipPath#clip_axis_${i} rect`).attr('width', this.width - this.margin.right + 1);

      this.svg
        .select<SVGRectElement>(`clipPath#clip_data_${i} rect`)
        .attr('width', this.width - this.margin.right - this.margin.left * 2);

      this.svg.select<SVGGElement>(`g.axis_group_${i} g.axis_x`).call(this.basicFunc(i).xAxis);

      this.svg.select<SVGGElement>(`g.axis_group_${i} g.axis_y`).call(this.basicFunc(i).yAxis);

      this.svg
        .select<SVGPathElement>(`g.data_group_${i} path.data_path`)
        .datum(data)
        .attr('d', this.basicFunc(i).dataPath);

      if (threshold > 0 && i === 0) {
        this.svg
          .select<SVGGElement>('g.data_group_0 line.threshold')
          .transition()
          .duration(800)
          .attr('x2', this.width - this.margin.right)
          .attr('y1', this.basicFunc().yScale(threshold / 1.2) + this.margin.top / 1.2)
          .attr('y2', this.basicFunc().yScale(threshold / 1.2) + this.margin.top / 1.2);
      }

      this.svg.select<SVGTextElement>(`g.label_group_${i} text.text_title`).attr('x', this.width / 2);

      this.svg.select<SVGTextElement>(`g.label_group_${i} text.text_x`).attr('x', this.width / 2);

      if (i === 1) data.forEach(d => d.isAnomaly && this.drawAnomaly(d.time));
    });
  }

  private drawAnomaly(time: Date): void {
    this.svg
      .select('g.anomaly_line_data')
      .append('line')
      .style('stroke', 'var(--surface-border)')
      .style('stroke-width', 1)
      .attr('x1', this.basicFunc().xScale(time))
      .attr('x2', this.basicFunc().xScale(time))
      .attr('y1', this.chartMargin)
      .attr('y2', this.chartHeight - this.margin.block);
  }
}

interface AnomalyChartChanges extends SimpleChanges {
  $data: SimpleChange;
  $margin: SimpleChange;
  $height: SimpleChange;
  $width: SimpleChange;
  $displayGuideLine: SimpleChange;
  $yRange: SimpleChange;
}
