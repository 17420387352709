export const TagStyles: string[] = [
  '#FF8F8F',
  '#C78FFF',
  '#8F96FF',
  '#41C5A1',
  '#FF935B',
  '#FF8F8F',
  '#C78FFF',
  '#8F96FF',
  '#41C5A1',
  '#FF935B',
];

export interface Announcement {
  announcementId?: number;
  appRoleId: string;
  title: string;
  content: string;
  announcementTypeIdArray: string[];
  writer?: string;
  announcementTime: string;
}

export interface NewAnnouncementType {
  tagName: string;
  tagId: string;
  style: string;
  ordinal: string;
  isSelected: boolean;
}

export interface TypeTag {
  tagName: string;
  tagId: string;
  style: string;
  ordinal: string;
  isSelected: boolean;
}

export interface Announcements {
  announcementId: number;
  appRoleId: number;
  title: string;
  announcementTypes: string[];
  writer: string;
  announcementTime: string;
  createdTime: string;
}

export interface AnnouncementAdminQuery {
  announcementTypeArray: string[];
  writerArray: string[];
  startPeriod: string;
  endPeriod: string;
}
