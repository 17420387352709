import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    LABEL: {
      ABNORMAL_DETECTION: 'Abnormal Detection ',
      ACCESSIBLE_RESOURCES: 'Accessible Resources ',
      ACCESS_ACTIVITIES: 'Access Activities ',
      ACCESS_CONTROL: 'access control',
      ACCESS_GROUP: 'Access Group',
      ACCESS_HOSTS: 'access hosts',
      ACCESS_MEMBERS: 'access members',
      ACCOUNT: 'Account ',
      ACCOUNT_BALANCE: 'Account Balance ',
      ACCOUNT_HOLDER_TYPE: 'Account Holder Type ',
      ACCOUNT_NAME: 'Account Name ',
      ACTION: 'Action ',
      ACTIONS: 'Actions ',
      ACTIVATE: 'Activate ',
      ACTIVATE_SUBSCRIPTION: 'Activate Subscription ',
      ACTIVE: 'Active ',
      ADD: 'Add',
      ADDITIONAL_EMAIL: 'Additional Email ',
      ADDRESS: 'Address ',
      ADDRESS_LINE_1: 'Address Line 1 ',
      ADDRESS_LINE_2: 'Address Line 2 ',
      ADD_NEW_CUSTOMER: 'Add New Customer ',
      ADD_ON_SUBSCRIPTION: 'Add On Subscription ',
      ADMINISTER_SERVICES: 'Administer services ',
      ADMIN_USERS: 'Admin Users ',
      ADMIN_USER_ASSIGNMENTS: 'Admin User Assignments ',
      ADVISOR_CATEGORY: 'Advisor Category ',
      AGREEMENT_HAS_ACCEPTED: 'Customer has accepted the latest Microsoft Customer Agreement ',
      AGREEMENT_TYPE: 'Agreement Type ',
      AGREEMENT_WILL_BE_ASKED: 'Customer will be asked to accept the Microsoft Customer Agreement in Microsoft 365 Admin Center ',
      ALERT_RECIPIENTS: 'Alert Recipients ',
      ALL: 'All ',
      AMORTIZED_RI_COST: 'Amortized RI Cost ',
      ANNOUNCEMENT: 'Announcement ',
      ANNOUNCEMENT_TIME: 'Announcement Time ',
      ANNOUNCEMENT_TYPE: 'Announcement Type ',
      ANNUAL: 'Annual ',
      API_ACCESS_CONFIG: 'API Access Config ',
      API_ACCESS_KEY: 'API Access Key ',
      API_SETTING_TEST: 'API Setting Test ',
      API_TYPE: 'API Type ',
      APP_ROLE: 'App Role ',
      APP_ROLES: 'App Roles ',
      ASSIGNED: 'Assigned ',
      ASSIGNED_ROLES: 'Assigned Roles ',
      ASSIGNED_USERS: 'Assigned Users ',
      ASSIGNMENTS: 'Assignments ',
      ASSIGN_LICENSE: 'Assign License ',
      AT: 'at ',
      ATTACHMENTS: 'Attachments ',
      ATTENTION: 'Attention ',
      AT_TIME: 'at time ',
      AUTHORIZED_SUBSCRIPTION: 'Authorized Subscription ',
      AVERAGE: 'Average ',
      AVG_CPU_PERCENT_30_DAYS: 'Average CPU(%) 30 Days ',
      BANK: 'Bank ',
      BANK_NAME: 'Bank Name ',
      BANK_ROUTING: 'Routing ',
      BC_CUSTOMER: 'BC Customer ',
      BC_PARTNER: 'BC Partner ',
      BEYOND_DATE: 'Beyond Date ',
      BEYOND_DATE_ALERT: 'Beyond Date Alert ',
      BILLING: 'Billing ',
      BILLING_ACCOUNT: 'Billing Account ',
      BILLING_CURRENCY: 'Billing Currency ',
      BILLING_CYCLE: 'Billing Cycle ',
      BILLING_PERIOD: 'Billing Period ',
      BILLING_SCHEDULE: 'Billing Schedule ',
      BY_FOR_GROUP: 'By For Group ',
      CALOUDI_USER: 'Caloudi User ',
      CANCEL: 'Cancel ',
      CANCEL_ORDER: 'Cancel Order ',
      CC_EMAILS: 'Cc Emails ',
      CHANGE_LANG: 'Change Language ',
      CHARGE_UNIT: 'Charge Unit ',
      CHART_GROUP_BY: 'Chart Group By ',
      CITY: 'City ',
      CLEAR: 'Clear',
      CLEAR_FILTER: 'Clear Filter ',
      CLOSE: 'Close ',
      CLOUD_USAGE_DATA: 'Cloud Usage Data ',
      CLOUD_USAGE_DATA_SYNC_PROGRESS: 'Cloud Usage Data Sync Progress ',
      CLOUD_VENDOR: 'Cloud Vendor ',
      COLLAPSE: 'Collapse ',
      COMMENT: 'Comment ',
      COMMENTS: 'Comments ',
      COMPANY: 'Company ',
      COMPANY_COUNTRY: 'Company Country ',
      COMPANY_ID: 'Company ID ',
      COMPANY_NAME: 'Company Name ',
      COMPANY_SIZE: 'Company Size ',
      COMPANY_WEBSITE: 'Company Website ',
      COMPARISON: 'Comparison ',
      CONSTRAINTS: 'Constraints',
      CONSUMED: 'Consumed ',
      CONSUMPTION: 'Consumption ',
      CONTACT: 'Contact ',
      CONTACT_INFO: 'Contact Info ',
      CONTACT_METHOD: 'Contact Method ',
      CONTACT_TYPES: 'Contact Types ',
      COPY_TO_CLIPBOARD: 'Copy to clipboard ',
      CORE: 'Core ',
      COST_PERCENTAGE_LIST_PRICE: 'Cost % List Price ',
      COST_PRICE: 'Cost Price ',
      COST_WITH_RECOMMENDATION: 'Cost With Recommendation ',
      COUNTRY: 'Country ',
      COUNTS: 'Counts ',
      COVERAGE_RATE: 'Coverage Rate ',
      CREATED: 'Created ',
      CREATED_BY: 'Created By ',
      CREATED_TIME: 'Created Time ',
      CREATE_DATE: 'Create Date ',
      CREATE_SALES_ORDER: 'Create Sales Order ',
      CREATE_TIME: 'Create Time ',
      CREATION_TIME: 'Creation Time ',
      CREATOR: 'Creator ',
      CREDITS: 'Credits ',
      CREDIT_CARD: 'Credit Card ',
      CREDIT_CARD_HOLDER_NAME: 'Credit Card Holder Name ',
      CREDIT_CARD_NUMBER: 'Credit Card Num ',
      CREDIT_CARD_TYPE: 'Credit Card Type ',
      CREDIT_LIMIT: 'Credit Limit ',
      CRON_LANG: 'en ',
      CUD_RECOMMENDATION: 'CUD Recommendation ',
      CURRENT: 'Current ',
      CURRENT_COST: 'Current Cost ',
      CURRENT_QTY: 'Current Quantity ',
      CURRENT_SKU: 'Current SKU ',
      CUSTOMER: 'Customer ',
      CUSTOMER_AGREEMENT: 'Customer Agreement ',
      CUSTOMER_ASSIGNMENTS: 'Customer Assignments ',
      CUSTOMER_DETAIL: 'Customer Detail ',
      CUSTOMER_MANAGEMENT: 'Customer Management ',
      CUSTOMER_PRIMARY_CONTACT: 'Customer Primary Contact ',
      CUSTOMER_SEGMENT: 'Customer Segment ',
      CUSTOMER_TYPE: 'Customer Type ',
      CUSTOM_EVENTS: 'Custom Events ',
      CVC_NUMBER: 'CVC Number ',
      DASHBOARD: 'Dashboard ',
      DEDUCTED_HOURS: 'Deducted Hours ',
      DEFAULT_METHOD: 'Default Method ',
      DELETE: 'Delete ',
      DELETED_USERS: 'Deleted Users ',
      DESCRIPTION: 'Description ',
      DETAIL: 'Detail ',
      DETAILS: 'Details ',
      DIMENSION: 'Dimension ',
      DISCOUNT_PERCENTAGE: '% List Price ',
      DISCOUNT_RATE: 'Discount Rate ',
      DISPLAY_NAME: 'Display Name ',
      DISPLAY_VALUE: 'Display Value ',
      DISTRIBUTION: 'Distribution ',
      DOCUMENTATION: 'Documentation ',
      DURATION: 'Duration ',
      EA_ACCESS_CONTROL: 'Customer Access Control ',
      EA_ACCESS_CONTROL_ASSIGNMENTS: 'Customer Access Control Assignments ',
      EA_ACCESS_CONTROL_LIST: 'Customer Access Control List ',
      EA_ENROLLMENT: 'EA Enrollment ',
      EA_USAGE_HOURS: 'EA Usage Hours ',
      EDIT: 'Edit ',
      EDIT_LICENSE_QTY: 'Edit License Quantity ',
      EMAIL: 'Email ',
      EMAIL_RECIPIENTS: 'Email Recipient(s) ',
      END_CUSTOMER_TYPE: 'End Customer Type ',
      ENGINEER_OWNER: 'Engineer Owner ',
      ENROLLMENT_NUMBER: 'Enrollment Number ',
      ESTIMATED_BY: 'Estimated By ',
      EVENT_TYPE: 'Event Type ',
      EVERY: 'Every ',
      EXCEPTION: 'Exception ',
      EXCLUDE: 'Exclude ',
      EXPIRATION_DATE: 'Expiration Date ',
      FAILURE: 'Failure ',
      FEATURE: 'Feature ',
      FEATURE_UPDATES: 'Feature Updates ',
      FILTER: 'Filter ',
      FILTERED_BY: 'Filtered By ',
      FILTER_BY_CUSTOMER: 'Filter by Customer ',
      FILTER_NAME: 'Filter Name ',
      FIND: 'Find ',
      FINDING_TIME: 'Finding Time ',
      FIRST_NAME: 'First Name ',
      FOLDER: 'Folder ',
      FREQUENCY: 'Frequency ',
      FULL_PAYNENT: 'Full Payment ',
      GOAL: 'Goal',
      GOAL_ACCOMPLISHED: 'Goal Accomplished ',
      GOAL_STATUS: 'Goal Status',
      GOAL_UNDONE: 'Goal Undone ',
      GPU: 'GPU ',
      GROUPS: 'Groups ',
      GROUP_BY: 'Group By ',
      GUIDING_CARD_TITLE: 'Thank you for choosing Caloudi CMP. Check out the following information to help you get started. ',
      HAS_ADD_ONS: 'Has Add Ons ',
      HINTS: 'Hints',
      HOMEPAGE: 'Homepage ',
      HOST: 'Host ',
      HOSTS: 'Hosts ',
      HTTP_HEADERS: 'HTTP Headers',
      IDENTIFIED: 'identified',
      ID_EXISTED: 'ID Existed ',
      IMPACT: 'Impact ',
      IMPACTED_FIELD: 'Impact Field ',
      IMPORT: 'Imports',
      INACTIVE: 'Inactive ',
      INCLUDE: 'Include ',
      INCLUDE_CREDITS: 'Include Credits ',
      INTERNAL: 'internal',
      INTERVAL: 'Interval ',
      INVITE_NEW_CUSTOMER: 'Invite New Customer ',
      INVITE_NEW_RESELLER: 'Invite New Reseller ',
      ISSUE_TYPE: 'Issue Type ',
      IS_ADD_ON: 'Is Add On ',
      IS_MS_PRODUCT: 'Is MS Product ',
      ITEMS_IN_CART: 'Item(s) In Cart. ',
      ITEMS_SELECTED: 'Items Selected ',
      JOB_TITLE: 'Job Title ',
      KEY: 'Key ',
      KEYWORD: 'Keyword ',
      LABEL: 'Label ',
      LABEL_KEY: 'Label Key ',
      LABEL_SELECTED: 'Label(s) Selected ',
      LANGUAGE: 'Language ',
      LAST_MODIFIED_TIME: 'Last Modified Time ',
      LAST_NAME: 'Last Name ',
      LAST_UPDATED: 'Last Updated ',
      LATENCY_FROM_YOU: 'Latency from you ',
      LETTERSPACE: '  ',
      LIST: 'List ',
      LIST_PRICE: 'List Price ',
      LOCATION: 'Location ',
      MACHINE_FAMILY: 'Machine Family ',
      MANAGEMENT: 'management',
      MANAGEMENT_GROUP: 'Management Group ',
      MANAGEMENT_GROUP_ASSIGNMENT: 'Management Group Assignment ',
      MANAGER: 'Manager ',
      MAP: 'Map ',
      MAPPING: 'Mapping ',
      MARGIN_PERCENTAGE_SYMBOL: 'Margin % ',
      MARKETPLACE: 'Marketplace ',
      MEMO: 'Memo ',
      MENU: 'Menu ',
      MENU_TYPE: 'Menu Type ',
      MESSAGE: 'Message ',
      METERED_BILLING: 'Metered Billing ',
      METERING_SERVICE_DIMENSIONS: 'Metering Service Dimensions ',
      METRICS: 'Metrics ',
      MICROSOFT_PARTNER_AGREEMENT: 'Microsoft Partner Agreement ',
      MIDDLE_NAME: 'Middle Name ',
      MONTHLY_OVERRAGE_DATE_ALERT: 'Monthly Budget Overage Alert ',
      MONTHLY_REVENUE: 'Monthly Revenue ',
      MONTHLY_SAVINGS: 'Monthly Savings ',
      MONTHLY_USAGE: 'Monthly Usage ',
      MORE: 'More ',
      MY_CART: 'My Cart ',
      MY_RECOMMENDATION_LIST: 'My Recommendation List ',
      NAME: 'Name ',
      NEW: 'New ',
      NEW_DIMENSION: 'New Dimension ',
      NEW_MONTHLY_HOURS: 'New Monthly Hours ',
      NEW_UNIT_PRICE_1Y: 'New Unit Price 1Y ',
      NEW_USAGE_HOURS: 'New Usage Hours ',
      NEW_USAGE_QTY: 'New Usage Quantity ',
      NEXT: 'Next ',
      NONE: 'None ',
      NORMAL: 'Normal ',
      NOTE: 'Note',
      NOTIFICATION: 'Notification ',
      NOT_AVAILABLE_FOR_PURCHASE: 'No Available for Purchase ',
      NO_ADMIN_USERS: 'No Admin Users ',
      NO_INSTANCE_LABEL: 'No Instance Label ',
      NO_LABEL: 'No Label ',
      NO_PURCHASE_ITEM: 'No Purchase Item ',
      NO_SUBSCRIPTION: 'No Subscription ',
      NUMBER_OF_CHARACTER_CLASSES: 'Number Of Character Classes ',
      NUMBER_OF_RESOURCES: 'Number of Resources ',
      NUMBER_OF_TYPES: 'Number of Types ',
      OFFER: 'Offer ',
      OFFER_NAME: 'Offer Name ',
      OF_EVERY: 'of every ',
      ONLY_ONE_SERVICE_PER_SUBSCRIPTION: 'Only one service per subscription ',
      ON_THE: 'On the ',
      OPEN_IN_EMAIL: 'Open in Email ',
      OPERATION_HISTORY: 'Operation History ',
      ORDER: 'Order ',
      ORDER_NUMBER: 'Order Number ',
      ORDER_RESULT: 'Order Result ',
      ORDER_STATUS: 'Order Status ',
      ORDINAL: 'Ordinal ',
      ORGANIZATION: 'Organization ',
      ORGANIZATION_NAME: 'Organization Name ',
      ORGANIZATION_UNIT: 'Organization Unit ',
      ORGANIZATION_UNIT_NAME: 'Organization Unit Name ',
      ORIGINAL_PRICE: 'Original Price ',
      OVERALL: 'Overall ',
      OWNER: 'Owner ',
      PAGE: 'Page ',
      PAGEVIEWS_DASHBOARD: 'Pageviews Dashboard ',
      PART_NUMBER: 'Part Number ',
      PAYMENT_AMOUNT: 'Payment Amount ',
      PAYMENT_METHOD: 'Payment Method ',
      PAYMENT_METHOD_NAME: 'Payment Method Name ',
      PAYMENT_TYPE: 'Payment Type ',
      PERCENT: 'Percent ',
      PERCENTAGE: 'Percentage ',
      PERCENTAGE_LIST_PRICE: 'Percentage of List Price ',
      PERCENTAGE_LIST_PRICE_SYMBOL: '% List Price ',
      PERIOD: 'Period ',
      PERMISSIONS: 'Permissions ',
      PERSONAL_INFO: 'You can save or apply frequently used view combinations ',
      PER_HOUR: 'Per Hour ',
      PER_MONTH: 'Per Month ',
      PER_YEAR: 'Per Year ',
      PHONE: 'Phone ',
      PIVOT_SAVE_INFO: 'Please save changes if information below has been altered. ',
      PLAN: 'Plan ',
      PLATFORM: 'Platform ',
      PORT: 'Port ',
      POSTAL_CODE: 'Postal Code ',
      PREFERRED_REGION: 'Preferred Region ',
      PREFERRED_VENDOR: 'Preferred Vendor ',
      PRESET_FILTER: 'Preset Filter ',
      PRESET_INFO: 'You can save or apply frequently used filter combinations ',
      PREVIOUS: 'Previous ',
      PRICE_SCHEDULES: 'Price Schedules ',
      PRICE_SHEET: 'Price Sheet ',
      PRICE_SHEET_UPLOAD: 'Price Sheet Upload ',
      PRICING_CONFIG: 'Pricing Config ',
      PRICING_SETTING: 'Pricing Setting ',
      PRICING_SETTINGS: 'Pricing Settings ',
      PRIMARY_DOMAIN: 'Primary Domain ',
      PRINCIPAL_TYPE: 'Principal Type ',
      PRIORITY: 'Priority ',
      PROBLEM_SUBTYPE: 'Problem Subtype ',
      PROBLEM_SUBTYPE_DESCRIPTION: 'Problem Subtype Description ',
      PROBLEM_SUBTYPE_LIST: 'Problem Subtype List ',
      PROBLEM_TYPE: 'Problem Type ',
      PROBLEM_TYPE_DESCRIPTION: 'Problem Type Description ',
      PROCESSED: 'Processed ',
      PRODUCT_CATEGORY: 'Product Category ',
      PROGRAM: 'Program',
      PROPERTIES: 'Properties ',
      PROVINCE: 'Province ',
      PUBLISHED: 'Published ',
      PUBLISHER: 'Publisher ',
      PURCHASE_PREREQUISITE: 'Purchase Prerequisites ',
      QUANTITY: 'Quantity ',
      QUERY_RESULT: 'Query Result ',
      RAM: 'RAM ',
      RANGE: 'Range ',
      RATING: 'Rating ',
      RECEIVER: 'Receiver ',
      RECIPIENTS: 'Recipient(s) ',
      RECOMMEND: 'Recommend ',
      RECOMMENDED_BY: 'Recommended By ',
      RECOMMENDED_INSTANCE: 'Recommended Instance ',
      RECOMMENDED_SKU: 'Recommended SKU ',
      RECOMMEND_RIGHT_SIZING_ANALYSIS_INSTANCE: 'Recommend Right Sizing Analysis Instance ',
      RECORDS: 'Records ',
      REFERENCE_EXCHANGE_RATE: 'Reference Exchange Rate ',
      REGION: 'Region ',
      REMOVE_PARTNERSHIP: 'Remove Partnership ',
      REPLAY: 'Replay',
      REQUEST: 'Request ',
      REQUIRED: 'Required ',
      RESELLER: 'Reseller ',
      RESELLERS: 'Resellers ',
      RESELLER_ACCEPTANCE_THROUGH_MICROSOFT: 'Reseller Acceptance Through Microsoft ',
      RESELLER_AGENT: 'Reseller Agent ',
      RESELLER_AGENT_ASSIGNMENT: 'Reseller Agent Assignment ',
      RESELLER_ASSIGNMENTS: 'Reseller Assignments ',
      RESELLER_EMAIL: 'Reseller Email ',
      RESELLER_PRIMARY_CONTACT: 'Reseller Primary Contact ',
      RESERVATION: 'Reservation ',
      RESERVED_HOURS: 'Reserved Hours ',
      RESOURCES_LIST: 'Resources List ',
      RESOURCE_CHARGES_LIST: 'Resource Charges List ',
      RESOURCE_DATA: 'Resource Data ',
      RESOURCE_PROPERTIES: 'Resource Properties ',
      RESPONSE_TIME: 'Response Time ',
      RESULTS: 'Result(s) ',
      RESULT_CODE: 'Result Code ',
      RETAIL: 'Retail ',
      REVENUE_PERIOD: 'Revenue Period ',
      REVIEW: 'Review ',
      REVISION: 'Revision ',
      RIGHT_SIZING: 'Right Sizing ',
      RIGHT_SIZING_ANALYSIS: 'Right Sizing Analysis ',
      RIGHT_SIZING_ANALYSIS_DETAIL: 'Right Sizing Analysis Detail ',
      ROLE: 'Role ',
      ROLE_ASSIGNMENTS: 'Role Assignments ',
      ROLE_LIST: 'Role List ',
      ROOT_CAUSE_ANALYSIS: 'Root Cause Analysis ',
      ROOT_DOMAIN: 'Root Domain ',
      ROUND_TO: 'Round to ',
      SALES: 'sales',
      SALES_AGENT: 'Sales Agent ',
      SALES_AGENT_ASSIGNMENT: 'Sales Agent Assignment ',
      SALES_AGENT_EMAIL: 'Sales Agent Email ',
      SALES_AMOUNT: 'Sales Amount ',
      SALES_REVENUE: 'Sales Revenue ',
      SAVE: 'Save ',
      SAVING: 'Saving ',
      SAVINGS: 'Savings ',
      SAVINGS_RANGE: 'Savings Range ',
      SAVING_PERCENT: 'Average Saving Percent ',
      SCAN: 'Scan',
      SCANNER: 'Scanner',
      SCHEDULED_REPORT: 'Scheduled Report ',
      SCOPE: 'Scope ',
      SCORE: 'Score ',
      SCREENSHOT: 'ScreenShot ',
      SECURITY: 'security',
      SELECTED: 'Selected ',
      SELECT_ONE_OPTION: 'Select one of the following options ',
      SEND_EMAIL_DESCRIPTION: 'Edit the text if necessary, and send the email to your customer ',
      SENSITIVITY: 'Sensitivity ',
      SERVICE_ANALYSIS: 'Service Analysis ',
      SERVICE_COST: 'Service Cost ',
      SERVICE_PLANS: 'Service Plans ',
      SERVICE_TYPE: 'Service Type ',
      SETTING: 'Setting',
      SEVERITY: 'Severity ',
      SHOW_PRICE_AS: 'Show Price As ',
      SHOW_REVENUE_AS: 'Show Revenue As ',
      SIZE: 'Size ',
      SKU_COUNTS: 'SKU Counts ',
      SKU_INFO: 'SKU Information ',
      SKU_NAME: 'SKU Name ',
      SLOWEST_REQUEST: 'Slowest Request ',
      SPOT: 'Spot ',
      STATE: 'State ',
      STATUS: 'Status ',
      STORAGE: 'Storage ',
      SUBJECT: 'Subject ',
      SUBMENU: 'Submenu ',
      SUBMITTED: 'Submitted ',
      SUBSCRIBED: 'Subscribed ',
      SUBSCRIPTIONS: 'Subscriptions ',
      SUBSCRIPTION_NAME: 'Subscription Name ',
      SUMMARY: 'Summary ',
      SUPPLIER: 'Supplier ',
      SUPPLIERS: 'Suppliers ',
      SUPPORT: 'Support ',
      SUPPORT_AGENT: 'Support Agent ',
      SUPPORT_AGENT_EMAIL: 'Support Agent Email ',
      SUPPORT_OWNER: 'Support Owner ',
      SUPPORT_TICKET: 'Support Ticket ',
      SUPPORT_TICKET_INFO: 'Support Ticket Info ',
      SUSPEND: 'Suspend ',
      SUSPENDED: 'Suspended ',
      SUSPENDED_SUBSCRIPTION: 'Suspend Subscription ',
      SWITCHABLE_USER_ASSIGNMENTS: 'Switchable User Assignments ',
      SWITCHABLE_USER_HAS_BEEN_UPDATED: 'Switchable user has been updated ',
      SYNCED: 'Synced ',
      SYNC_LAST_DATA_MODIFIED_TIME: 'Sync Last Data Modified Time ',
      SYSTEM_MAINTENANCE: 'System Maintenance ',
      TABLE: 'Table ',
      TAG: 'tag',
      TAGS: 'Tags ',
      TAG_ID: 'Tag ID ',
      TAG_NAME: 'Tag Name ',
      TARGET: 'Target ',
      TARGETS: 'Targets ',
      TARGET_SKU: 'Target SKU ',
      TASK: 'Task',
      TAX_EXEMPTION: 'Tax Exemption ',
      TAX_EXEMPTION_DETAIL: 'Tax Exemption Detail ',
      TAX_ID: 'Tax ID ',
      TAX_INFO: 'Tax Info ',
      TAX_RATE: 'Tax Rate ',
      TEMPSTORAGE: 'Temp Storage ',
      TERM: 'Term ',
      TEXT: 'Text ',
      TIMESPAN: 'Timespan ',
      TIME_PERIOD: 'Time Period',
      TIME_SCOPE: 'Time Scope ',
      TIME_STAMP: 'Time Stamp ',
      TIME_ZONE: 'Time Zone ',
      TITLE: 'Title ',
      TOOL: 'Tool ',
      TOP: 'Top ',
      TOTAL_USAGE: 'Total Usage ',
      TUTORIAL_VIDEO: 'Tutorial video ',
      TYPE: 'Type ',
      UNCONSUMED: 'Unconsumed ',
      UNDEDUCTED_COST: 'Undeducted Cost ',
      UNDEDUCTED_HOURS: 'Undeducted Hours ',
      UNIT_PRICE: 'Unit Price ',
      UNIT_PRICE_1Y: 'Unit Price 1Y ',
      UNIT_TYPE: 'Unit Type ',
      UNSELECTED: 'Unselected ',
      UNSUBSCRIBE: 'Unsubscribe ',
      UNSUBSCRIBED: 'Unsubscribed ',
      UNSYNCED: 'Unsynced ',
      UPDATE: 'Update ',
      UPDATE_LICENSE_AND_CREATE_INFO: 'Update License And Create Sales Order ',
      UPDATE_SUBSCRIPTION_STATUS: 'Update Subscription Status ',
      USAGE: 'Usage ',
      USAGE_BREAKDOWN: 'Usage Breakdown ',
      USAGE_COST: 'Usage Cost ',
      USAGE_DASHBOARD: 'Usage Dashboard ',
      USAGE_DATE: 'Usage Date ',
      USAGE_DETAIL: 'Usage Details ',
      USAGE_FILTER_DETAIL: 'Usage Filter Detail ',
      USAGE_HOURS: 'Usage Hours ',
      USAGE_LINEITEMS: 'Usage Line Items ',
      USAGE_MONITOR: 'Usage Monitor ',
      USAGE_QTY: 'Usage Quantity ',
      USAGE_QUERY: 'Usage Query ',
      USAGE_SUMMARY: 'Usage Summary ',
      USED_HOURS: 'Used Hours ',
      USER: 'User ',
      USERS: 'Users ',
      USERS_LICENSES: 'Users and licenses ',
      USER_ACTIVITIES: 'User Activities ',
      USER_ASSIGNMENTS: 'User Assingnments ',
      USER_GROUP: 'User Group ',
      USER_GROUPS: 'User Groups ',
      USER_GROUP_ASSIGNMENT: 'User Group Assignment ',
      USER_GROUP_CONTROL_ASSIGNMENTS: 'User Group Control Assignments ',
      USER_GROUP_CONTROL_LIST: 'User Group Control List ',
      USER_GROUP_NAME: 'User Group Name ',
      USER_INFO: 'User Info ',
      USER_LIST: 'User List ',
      USER_SEARCH: 'User Search ',
      VALUE: 'Value ',
      VALUES: 'values',
      VAULT_URI: 'Vault URI ',
      VENDOR: ' Vendor ',
      VIEW_CUSTOMERS: 'View Customers ',
      VIEW_NAME: 'View Name ',
      VISIBILITY: 'Visibility ',
      VM_SIZE: 'VM Size ',
      VULNERABILITY: 'Vulnerability ',
      WAIT_FOR: 'Wait For ',
      WEBSITE: 'Website ',
      WEEKLY_USAGE: 'Weekly Usage ',
      WRITER: 'Writer ',
      YEARLY_SAVINGS: 'Yearly Savings ',
    },
  },
};
