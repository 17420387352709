import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import BaseComponent from '@base';

@Component({
  selector: 'caloudi-read-more',
  templateUrl: './read-more.component.html',
  styleUrl: './read-more.component.sass',
})
export class ReadMoreComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  // @Input() public isReadMore: boolean;
  // public isCollapsed: boolean = true;
  // maximum height of the container
  @Input() public maxHeight: number = 10;
  @Input() public data: string;

  @ViewChild('containerDiv') public containerDiv: ElementRef<HTMLDivElement>;

  // @ContentChild(MarkdownComponent) private readonly markdown: MarkdownComponent;

  public get styleHeight(): number {
    return this.isCollapsed && this.isCollapsable ? this.maxHeight : this.containerHeight;
  }

  public containerHeight: number;

  public showReadMore: boolean;
  public isCollapsed: boolean = true;
  public isCollapsable: boolean;

  constructor(
    public readonly injector: Injector,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(injector);
    // afterRender(() => {
    //   // this.contentHeight = this.markdown?.element.nativeElement.offsetHeight;
    //   this.containerHeight = this.containerDiv.nativeElement.offsetHeight

    //   if (this.containerHeight > this.maxHeight) {
    //     this.isCollapsed = true;
    //     this.isCollapsable = true;
    //     // this.logger.debug([this.isCollapsed, this.isCollapsable]);
    //   }
    // }, { phase: AfterRenderPhase.Read });
  }

  public ngOnInit(): void {
    this.logger.debug('ngOnInit');
    // this.logger.debug('data', this.data);
  }

  public ngAfterViewInit(): void {
    this.containerHeight = this.containerDiv.nativeElement.offsetHeight;
    this.logger.debug('this.containerHeight', this.containerHeight);
    if (this.containerHeight > this.maxHeight) {
      this.isCollapsed = true;
      this.isCollapsable = true;
      // this.logger.debug([this.isCollapsed, this.isCollapsable]);
    }
  }


  public ngAfterViewChecked(): void {
    this.cdr.detectChanges();
    // throw new Error('Method not implemented.');
  }


  public switchReadMore(): void {
    this.isCollapsed = this.isCollapsed ? false : true;
    // this.logger.debug(this.isCollapsed);
  }
}
