// NGRX
import { createSelector } from '@ngrx/store';
import { AppState, TabMenuState } from '../reducers';

// For selector
export const selectTabMenuState: (state: AppState) => TabMenuState = (state: AppState): TabMenuState =>
  state.tabMenuBreadcrumb;

export const getTabMenuState = createSelector(selectTabMenuState, menu => menu);

export const getTabMenuFlatBreadcrumbs = createSelector(selectTabMenuState, menu => {
  if (!menu.breadcrumbs) return null;
  const keys = Object.keys(menu.breadcrumbs);
  return keys.flatMap(key => menu.breadcrumbs[key]);
});

export const getTabMenuBreadcrumbs = createSelector(selectTabMenuState, menu => menu.breadcrumbs);

export const getTabMenuCaches = createSelector(selectTabMenuState, menu => menu.cache);

export const getTabMenuItems = createSelector(selectTabMenuState, menu => menu.items);

export const getCurrentSubMenuItems = createSelector(selectTabMenuState, menu => menu?.currentSubmenu);
