export enum ErrorCodeOnboard {
  o001 = 'o001',
  o002 = 'o002',
  o003 = 'o003',
  o004 = 'o004',
  o005 = 'o005',
  o006 = 'o006',
  o007 = 'o007',
  o008 = 'o008',
  o009 = 'o009',
  o010 = 'o010',
  o011 = 'o011',
  o012 = 'o012',
  o013 = 'o013',
  o014 = 'o014',
  o015 = 'o015',
  o016 = 'o016',
  o017 = 'o017',
  o018 = 'o018',
  o019 = 'o019',
  o020 = 'o020',
  o021 = 'o021',
  o022 = 'o022',
  o023 = 'o023',
  o024 = 'o024',
  o025 = 'o025',
  o026 = 'o026',
  o027 = 'o027',
  o028 = 'o028',
  o029 = 'o029',
  o030 = 'o030',
}
