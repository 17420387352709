// Angular
import { Injectable } from '@angular/core';
// Caloudi
import { FontawesomeBrands, FontawesomeIcons } from '@core/model';
// Third Party
import * as FAB from '@fortawesome/free-brands-svg-icons';
import * as FAS from '@fortawesome/free-solid-svg-icons';

@Injectable({ providedIn: 'root' })
export class FontawesomeService {
  constructor() {}

  // Fontawesome
  public readonly fas: FontawesomeIcons = FAS;
  public readonly fab: FontawesomeBrands = FAB;
}
