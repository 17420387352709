export const localeKeys = {
  ANOMALY: {
    ANOMALY: 'ANOMALY.ANOMALY',
    ANOMALY_DETECTION_MODEL: 'ANOMALY.ANOMALY_DETECTION_MODEL',
    ANOMALY_DETECTION: 'ANOMALY.ANOMALY_DETECTION',
    ANOMALY_POINTS_NOTICE: 'ANOMALY.ANOMALY_POINTS_NOTICE',
    ANOMALY_POINTS: 'ANOMALY.ANOMALY_POINTS',
    AVAILABLE_CHARTS: 'ANOMALY.AVAILABLE_CHARTS',
    PRIMARY_INSTANCE_CAUSES: 'ANOMALY.PRIMARY_INSTANCE_CAUSES',
    PRIMARY_LABEL_CAUSES: 'ANOMALY.PRIMARY_LABEL_CAUSES',
    PRIMARY_PRODUCT_CAUSES: 'ANOMALY.PRIMARY_PRODUCT_CAUSES',
    PRIMARY_SERVICE_CAUSES: 'ANOMALY.PRIMARY_SERVICE_CAUSES',
    PRIMARY_SKU_CAUSES: 'ANOMALY.PRIMARY_SKU_CAUSES',
  },
  ENTITY: {
    ACQUIRE: 'ENTITY.ACQUIRE',
    APP_INSIGHT: 'ENTITY.APP_INSIGHT',
    AUDIT_TYPE: 'ENTITY.AUDIT_TYPE',
    BALANCE_SUMMARY: 'ENTITY.BALANCE_SUMMARY',
    CATALOG: 'ENTITY.CATALOG',
    CATEGORY: 'ENTITY.CATEGORY',
    CHART: 'ENTITY.CHART',
    CONSUMED_SERVICE: 'ENTITY.CONSUMED_SERVICE',
    DEPARTMENT: 'ENTITY.DEPARTMENT',
    DEPENDENCY: 'ENTITY.DEPENDENCY',
    ENROLLMENT: 'ENTITY.ENROLLMENT',
    DOMAIN: 'ENTITY.DOMAIN',
    FLEXIBILITY_GROUP: 'ENTITY.FLEXIBILITY_GROUP',
    INSTANCE: 'ENTITY.INSTANCE',
    INSTANCE_NAME: 'ENTITY.INSTANCE_NAME',
    INSTANCE_TYPE: 'ENTITY.INSTANCE_TYPE',
    INSTANCE_ID: 'ENTITY.INSTANCE_ID',
    OPERATION: 'ENTITY.OPERATION',
    OS: 'ENTITY.OS',
    PRODUCT: 'ENTITY.PRODUCT',
    PROJECT: 'ENTITY.PROJECT',
    RESOURCE_GROUP: 'ENTITY.RESOURCE_GROUP',
    RESOURCE_LABELS: 'ENTITY.RESOURCE_LABELS',
    RESOURCE_TAG_KEY: 'ENTITY.RESOURCE_TAG_KEY',
    RESOURCE_TAG_VALUE: 'ENTITY.RESOURCE_TAG_VALUE',
    RESOURCE_TAG: 'ENTITY.RESOURCE_TAG',
    RESOURCE_TYPE: 'ENTITY.RESOURCE_TYPE',
    RESOURCE_VALUE: 'ENTITY.RESOURCE_VALUE',
    RESOURCE: 'ENTITY.RESOURCE',
    SERVICE: 'ENTITY.SERVICE',
    SERVICE_CATEGORY: 'ENTITY.SERVICE_CATEGORY',
    SETTING: 'ENTITY.SETTING',
    SKU: 'ENTITY.SKU',
    SQL: 'ENTITY.SQL',
    SUBSCRIPTION: 'ENTITY.SUBSCRIPTION',
    SUBSCRIPTION_ID: 'ENTITY.SUBSCRIPTION_ID',
    TENANT_DOMAIN: 'ENTITY.TENANT_DOMAIN',
    TENANT: 'ENTITY.TENANT',
    TENANT_GUID: 'ENTITY.TENANT_GUID',
    VIRTUAL_MACHINE: 'ENTITY.VIRTUAL_MACHINE',
    VM_FLEXIBILITY_GROUP: 'ENTITY.VM_FLEXIBILITY_GROUP',
    VM_RECOMMENDATION: 'ENTITY.VM_RECOMMENDATION',
    VM: 'ENTITY.VM',
  },
  BUDGET: {
    BUDGET_ALERT: 'BUDGET.BUDGET_ALERT',
    BUDGET_ID: 'BUDGET.BUDGET_ID',
    BUDGET_NAME: 'BUDGET.BUDGET_NAME',
    BUDGET_PERCENT: 'BUDGET.BUDGET_PERCENT',
    BUDGET_SUGGESTION: 'BUDGET.BUDGET_SUGGESTION',
    BUDGET_YEAR: 'BUDGET.BUDGET_YEAR',
    BUDGET: 'BUDGET.BUDGET',
    EXPIRATION_DATE: 'BUDGET.EXPIRATION_DATE',
    OVERALL_COST_ACCOUNT: 'BUDGET.OVERALL_COST_ACCOUNT',
    OVERALL_COST_DEPARTMENT: 'BUDGET.OVERALL_COST_DEPARTMENT',
    OVERALL_COST_PRODUCT: 'BUDGET.OVERALL_COST_PRODUCT',
    OVERALL_COST_REGION: 'BUDGET.OVERALL_COST_REGION',
    OVERALL_COST_RESOURCE_GROUP: 'BUDGET.OVERALL_COST_RESOURCE_GROUP',
    OVERALL_COST_SERVICE: 'BUDGET.OVERALL_COST_SERVICE',
    OVERALL_COST_SUBSCRIPTION: 'BUDGET.OVERALL_COST_SUBSCRIPTION',
    OVERALL_COST_TAG: 'BUDGET.OVERALL_COST_TAG',
    OVERALL_COST_THE_LABEL: 'BUDGET.OVERALL_COST_THE_LABEL',
    OVERALL_COST_THE_PROJECT: 'BUDGET.OVERALL_COST_THE_PROJECT',
    OVERALL_COST_THE_REGION: 'BUDGET.OVERALL_COST_THE_REGION',
    OVERALL_COST_THE_SERVICE: 'BUDGET.OVERALL_COST_THE_SERVICE',
    OVERALL_COST_THE_SKU: 'BUDGET.OVERALL_COST_THE_SKU',
    OVERALL_COST: 'BUDGET.OVERALL_COST',
    THRESHOLD_1: 'BUDGET.THRESHOLD_1',
    THRESHOLD_2: 'BUDGET.THRESHOLD_2',
    THRESHOLD_3: 'BUDGET.THRESHOLD_3',
    TOTAL_BUDGET_OVERRAGE_ALERT: 'BUDGET.TOTAL_BUDGET_OVERRAGE_ALERT',
    TOTAL_BUDGET: 'BUDGET.TOTAL_BUDGET',
  },
  CHART: {
    BAR_CHART: 'CHART.BAR_CHART',
    LINE_CHART: 'CHART.LINE_CHART',
    LINECHART_INFO: 'CHART.LINECHART_INFO',
    OTHER_CHART: 'CHART.OTHER_CHART',
    PIE_CHART_3D: 'CHART.PIE_CHART_3D',
    PIE_CHART: 'CHART.PIE_CHART',
    STACK_CHART: 'CHART.STACK_CHART',
    STACKED: 'CHART.STACKED',
    SWITCH_BAR: 'CHART.SWITCH_BAR',
    SWITCH_PIE: 'CHART.SWITCH_PIE',
  },
  COST: {
    ALL_METERED_COST: 'COST.ALL_METERED_COST',
    BALANCE: 'COST.BALANCE',
    COST: 'COST.COST',
    COST_30D: 'COST.COST_30D',
    COST_30D_SUMMARY: 'COST.COST_30D_SUMMARY',
    COST_30D_TOTAL: 'COST.COST_30D_TOTAL',
    COST_DETAILS: 'COST.COST_DETAILS',
    COST_DEVIATION: 'COST.COST_DEVIATION',
    COST_OF_CURRENT_MONTH: 'COST.COST_OF_CURRENT_MONTH',
    COST_OF_LAST_MONTH: 'COST.COST_OF_LAST_MONTH',
    COST_QUERY_YEAR: 'COST.COST_QUERY_YEAR',
    COST_SUMMARY: 'COST.COST_SUMMARY',
    COST_TYPE: 'COST.COST_TYPE',
    CUSTOMER_CHARGES: 'COST.CUSTOMER_CHARGES',
    DAILY_COST: 'COST.DAILY_COST',
    LAST_30_DAYES_DAILY_COST: 'COST.LAST_30_DAYES_DAILY_COST',
    LIST_PRICE_TOTAL: 'COST.LIST_PRICE_TOTAL',
    MARGIN: 'COST.MARGIN',
    METERED_COST: 'COST.METERED_COST',
    MONTHLY_COST: 'COST.MONTHLY_COST',
    NEW_COST: 'COST.NEW_COST',
    ORIGINAL_CHARGES: 'COST.ORIGINAL_CHARGES',
    PARTNER_CHARGES: 'COST.PARTNER_CHARGES',
    PRETAX_SUBTOTAL: 'COST.PRETAX_SUBTOTAL',
    SUBTOTAL: 'COST.SUBTOTAL',
    TAX: 'COST.TAX',
    TOTAL_AMOUNT: 'COST.TOTAL_AMOUNT',
    TOTAL_CHARGES: 'COST.TOTAL_CHARGES',
    TOTAL_COST: 'COST.TOTAL_COST',
    TOTAL_COVERAGE_RATE: 'COST.TOTAL_COVERAGE_RATE',
    TOTAL_REVENUE: 'COST.TOTAL_REVENUE',
    TOTAL_QTY: 'COST.TOTAL_QTY',
    TOTAL_TAX: 'COST.TOTAL_TAX',
    TOTAL: 'COST.TOTAL',
  },
  COMMON: {
    A: 'COMMON.A',
    BY: 'COMMON.BY',
    FROM: 'COMMON.FROM',
    YES: 'COMMON.YES',
    NO: 'COMMON.NO',
    NOT: 'COMMON.NOT',
    OF: 'COMMON.OF',
    OR: 'COMMON.OR',
    TO: 'COMMON.TO',
  },
  CURRENCY: {
    CURRENCY: 'CURRENCY.CURRENCY',
    NTD: 'CURRENCY.NTD',
    USD: 'CURRENCY.USD',
    TWD: 'CURRENCY.TWD',
  },
  LOG: {
    ACTIVITY_LOG: 'LOG.ACTIVITY_LOG',
    ACTIVITY_LOGS: 'LOG.ACTIVITY_LOGS',
    LOG_DATE: 'LOG.LOG_DATE',
    LOG_PERIOD: 'LOG.LOG_PERIOD',
    LOG_QUERY: 'LOG.LOG_QUERY',
    LOG_TYPE: 'LOG.LOG_TYPE',
  },
  INVOICE: {
    ACCOUNT_PROFILE: 'INVOICE.ACCOUNT_PROFILE',
    BILLING_DETAILS: 'INVOICE.BILLING_DETAILS',
    BILLING_INFO: 'INVOICE.BILLING_INFO',
    BILLING_PROFILE: 'INVOICE.BILLING_PROFILE',
    BILLING_SUMMARY: 'INVOICE.BILLING_SUMMARY',
    BILL_TO: 'INVOICE.BILL_TO',
    COMPANY_NAME: 'INVOICE.COMPANY_NAME',
    COMPANY_PROFILE: 'INVOICE.COMPANY_PROFILE',
    CUSTOMERS: 'INVOICE.CUSTOMERS',
    CUSTOMER_PROFILE: 'INVOICE.CUSTOMER_PROFILE',
    DATE: 'INVOICE.DATE',
    DATE_END: 'INVOICE.DATE_END',
    DATE_START: 'INVOICE.DATE_START',
    DATE_UTC: 'INVOICE.DATE_UTC',
    DETAILS: 'INVOICE.DETAILS',
    DOCUMENT_TYPE: 'INVOICE.DOCUMENT_TYPE',
    ID: 'INVOICE.ID',
    INFO: 'INVOICE.INFO',
    INVOICE: 'INVOICE.INVOICE',
    LICENSE: 'INVOICE.LICENSE',
    LICENSES: 'INVOICE.LICENSES',
    LICENSE_LINEITEMS: 'INVOICE.LICENSE_LINEITEMS',
    LINEITEM: 'INVOICE.LINEITEM',
    LINEITEMS: 'INVOICE.LINEITEMS',
    LINEITEM_DETAILS: 'INVOICE.LINEITEM_DETAILS',
    LUMPSUM: 'INVOICE.LUMPSUM',
    NET_N_DAYS: 'INVOICE.NET_N_DAYS',
    NUMBER: 'INVOICE.NUMBER',
    OFFERS: 'INVOICE.OFFERS',
    ONETIME: 'INVOICE.ONETIME',
    ONETIME_LINEITEMS: 'INVOICE.ONETIME_LINEITEMS',
    ORDER_ID: 'INVOICE.ORDER_ID',
    ORDER_INFO: 'INVOICE.ORDER_INFO',
    PAID_AMOUNT: 'INVOICE.PAID_AMOUNT',
    PAYMENT_STATUS: 'INVOICE.PAYMENT_STATUS',
    PAYMENT_TERMS: 'INVOICE.PAYMENT_TERMS',
    PRODUCTS: 'INVOICE.PRODUCTS',
    PRODUCT_CATS: 'INVOICE.PRODUCT_CATS',
    PRODUCT_INFO: 'INVOICE.PRODUCT_INFO',
    PURCHASE_INFO: 'INVOICE.PURCHASE_INFO',
    QUANTITY_AMOUNT: 'INVOICE.QUANTITY_AMOUNT',
    RECON: 'INVOICE.RECON',
    RECURRING: 'INVOICE.RECURRING',
    SKUS: 'INVOICE.SKUS',
    SOLD_TO: 'INVOICE.SOLD_TO',
    SUMMARY: 'INVOICE.SUMMARY',
    TOTAL_AMOUNT: 'INVOICE.TOTAL_AMOUNT',
    TYPE: 'INVOICE.TYPE',
  },
  REPORT: {
    EMAIL_LIST: 'REPORT.EMAIL_LIST',
    NOTE: 'REPORT.NOTE',
    REPORT: 'REPORT.REPORT',
    TITLE: 'REPORT.TITLE',
    UNTITLED: 'REPORT.UNTITLED',
  },
  RESELLER_QUERY: {
    INCLUDE_PRIVILEGES_DESCRIPTION: 'RESELLER_QUERY.INCLUDE_PRIVILEGES_DESCRIPTION',
    REQUEST_RELATIONSHIP_DESCRIPTION: 'RESELLER_QUERY.REQUEST_RELATIONSHIP_DESCRIPTION',
    SEND_EMAIL_DESCRIPTION: 'RESELLER_QUERY.SEND_EMAIL_DESCRIPTION',
  },
  RI: {
    CURRENT_RI_COVERAGE_RATE: 'RI.CURRENT_RI_COVERAGE_RATE',
    NEW_RI_COVERAGE_RATE: 'RI.NEW_RI_COVERAGE_RATE',
    NO_RI_COST: 'RI.NO_RI_COST',
    RECOMMENDED_COST: 'RI.RECOMMENDED_COST',
    RI_ANALYSIS_DETAIL: 'RI.RI_ANALYSIS_DETAIL',
    RI_COST_ALLOCATION: 'RI.RI_COST_ALLOCATION',
    RI_COST_WITH_NO_RI: 'RI.RI_COST_WITH_NO_RI',
    RI_COST_WITH_RI: 'RI.RI_COST_WITH_RI',
    RI_COST: 'RI.RI_COST',
    RI_COVERAGE_ANALYSIS: 'RI.RI_COVERAGE_ANALYSIS',
    RI_COVERAGE_ANALYSIS_NOTE: 'RI.RI_COVERAGE_ANALYSIS_NOTE',
    RI_COVERAGE_ANALYSIS_WITH_ISF_NOTE: 'RI.RI_COVERAGE_ANALYSIS_WITH_ISF_NOTE',
    RI_COVERAGE_RATE: 'RI.RI_COVERAGE_RATE',
    RI_ONE_YEAR: 'RI.RI_ONE_YEAR',
    RI_ORDER: 'RI.RI_ORDER',
    RI_PRICE_SHEET: 'RI.RI_PRICE_SHEET',
    RI_RECOMMEND_QUANTITY: 'RI.RI_RECOMMEND_QUANTITY',
    RI_RECOMMENDATION: 'RI.RI_RECOMMENDATION',
    RI_THREE_YEAR: 'RI.RI_THREE_YEAR',
    RI_TRANSFER_ESTIMATION: 'RI.RI_TRANSFER_ESTIMATION',
    RI_UNUSED_COST: 'RI.RI_UNUSED_COST',
    RI_USAGE_DETAIL: 'RI.RI_USAGE_DETAIL',
    RI_USAGE_SUMMARY: 'RI.RI_USAGE_SUMMARY',
    RI_UTILIZATION_RATE: 'RI.RI_UTILIZATION_RATE',
    RI1Y: 'RI.RI1Y',
    RI3Y: 'RI.RI3Y',
    TRANSFER_ESTIMATE: {
      ADDITIONAL_PAYMENT: 'RI.TRANSFER_ESTIMATE.ADDITIONAL_PAYMENT',
      EQUIVALENT_HOURS_TO_NEW_RI_ORDER: 'RI.TRANSFER_ESTIMATE.EQUIVALENT_HOURS_TO_NEW_RI_ORDER',
      NEW_RI_ORDER_COST: 'RI.TRANSFER_ESTIMATE.NEW_RI_ORDER_COST',
      NEW_RI_ORDER_RESERVATION_COST: {
        ESTIMATE_INFO: 'RI.TRANSFER_ESTIMATE.NEW_RI_ORDER_RESERVATION_COST.ESTIMATE_INFO',
        PER_HOUR: 'RI.TRANSFER_ESTIMATE.NEW_RI_ORDER_RESERVATION_COST.PER_HOUR',
        PER_MONTH: 'RI.TRANSFER_ESTIMATE.NEW_RI_ORDER_RESERVATION_COST.PER_MONTH',
        PER_YEAR: 'RI.TRANSFER_ESTIMATE.NEW_RI_ORDER_RESERVATION_COST.PER_YEAR',
      },
      NO_RI_INFO: 'RI.TRANSFER_ESTIMATE.NO_RI_INFO',
      NOT_INCLUDE_FEE: 'RI.TRANSFER_ESTIMATE.NOT_INCLUDE_FEE',
      REMAINING_BALANCE: 'RI.TRANSFER_ESTIMATE.REMAINING_BALANCE',
      REMAINING_HOURS: 'RI.TRANSFER_ESTIMATE.REMAINING_HOURS',
    },
  },
  DATE: {
    SECONDS: 'DATE.SECONDS',
    MINUTE: 'DATE.MINUTE',
    MINUTES: 'DATE.MINUTES',
    HOUR: 'DATE.HOUR',
    HOURLY: 'DATE.HOURLY',
    HOURS: 'DATE.HOURS',
    SUN: 'DATE.SUNDAY',
    MON: 'DATE.MONDAY',
    TUE: 'DATE.TUESDAY',
    WED: 'DATE.WEDNESDAY',
    THU: 'DATE.THURSDAY',
    FRI: 'DATE.FRIDAY',
    SAT: 'DATE.SATURDAY',
    TODAY: 'DATE.TODAY',
    DAY: 'DATE.DAY',
    DAYS: 'DATE.DAYS',
    DAILY: 'DATE.DAILY',
    DAY_OF_THE_WEEK: 'DATE.DAY_OF_THE_WEEK',
    WEEK: 'DATE.WEEK',
    WEEKLY: 'DATE.WEEKLY',
    WEEK_DAY: 'DATE.WEEK_DAY',
    MONTH: 'DATE.MONTH',
    MONTHLY: 'DATE.MONTHLY',
    LAST_MONTH: 'DATE.LAST_MONTH',
    CURRENT_MONTH: 'DATE.CURRENT_MONTH',
    PREVIOUS_MONTH: 'DATE.PREVIOUS_MONTH',
    DATE: 'DATE.DATE',
    LAST_7_DAYS: 'DATE.LAST_7_DAYS',
    LAST: 'DATE.LAST',
    QUARTERLY: 'DATE.QUARTERLY',
    YEARLY: 'DATE.YEARLY',
  },
  ADVISOR_DASHBOARD: {
    ADD_TO_MY_ADVISORY_LIST: 'ADVISOR_DASHBOARD.ADD_TO_MY_ADVISORY_LIST',
    CHECK_ALL_ANOMALY_DETECTIONS: 'ADVISOR_DASHBOARD.CHECK_ALL_ANOMALY_DETECTIONS',
    CHECK_ALL_RIRECOMMENDATIONS: 'ADVISOR_DASHBOARD.CHECK_ALL_RIRECOMMENDATIONS',
    CHECK_ALL_RIGHT_SIZINGS: 'ADVISOR_DASHBOARD.CHECK_ALL_RIGHT_SIZINGS',
    CHECK_USAGE_SUMMARY: 'ADVISOR_DASHBOARD.CHECK_USAGE_SUMMARY',
    INSIGHTS_OVERVIEW: 'ADVISOR_DASHBOARD.INSIGHTS_OVERVIEW',
    LOW_RI_COVERAGE_RATE: 'ADVISOR_DASHBOARD.LOW_RI_COVERAGE_RATE',
    LOW_RI_UTILIZATION_VM_CPU: 'ADVISOR_DASHBOARD.LOW_RI_UTILIZATION_VM_CPU',
    MAX_CPU_95TH_PERCENTILE: 'ADVISOR_DASHBOARD.MAX_CPU_95TH_PERCENTILE',
    MAX_MEMORY_95TH_PERCENTILE: 'ADVISOR_DASHBOARD.MAX_MEMORY_95TH_PERCENTILE',
    MAX_NETWORK_95TH_PERCENTILE: 'ADVISOR_DASHBOARD.MAX_NETWORK_95TH_PERCENTILE',
    MY_ADVISORY_LIST: 'ADVISOR_DASHBOARD.MY_ADVISORY_LIST',
    NO_RI_INFO: 'ADVISOR_DASHBOARD.NO_RI_INFO',
    NO_RIGHT_SIZING_INFO: 'ADVISOR_DASHBOARD.NO_RIGHT_SIZING_INFO',
    NO_ABNORMAL_INFO: 'ADVISOR_DASHBOARD.NO_ABNORMAL_INFO',
    RI_ESTIMATED_BY_RECOMMENDED_SKU: 'ADVISOR_DASHBOARD.RI_ESTIMATED_BY_RECOMMENDED_SKU',
    RI_ESTIMATED_BY_FLEXIBILITY_GROUP: 'ADVISOR_DASHBOARD.RI_ESTIMATED_BY_FLEXIBILITY_GROUP',
    RI_RECOMMEND_QUANITITY_NOTE: 'ADVISOR_DASHBOARD.RI_RECOMMEND_QUANITITY_NOTE',
  },
  ADVISORY: {
    AVAILABILITY_ADVISORY: 'ADVISORY.AVAILABILITY_ADVISORY',
    HIGH_IMPACT: 'ADVISORY.HIGH_IMPACT',
    IMPACT: 'ADVISORY.IMPACT',
    IMPACTED_RESOURCES: 'ADVISORY.IMPACTED_RESOURCES',
    LOW_IMPACT: 'ADVISORY.LOW_IMPACT',
    MEDIUM_IMPACT: 'ADVISORY.MEDIUM_IMPACT',
    OPERATION_ADVISORY: 'ADVISORY.OPERATION_ADVISORY',
    PERFORMANCE_ADVISORY: 'ADVISORY.PERFORMANCE_ADVISORY',
    RECOMMENDATIONS: 'ADVISORY.RECOMMENDATIONS',
    SECURITY_ADVISORY: 'ADVISORY.SECURITY_ADVISORY',
    TOTAL_RECOMMENDATIONS: 'ADVISORY.TOTAL_RECOMMENDATIONS',
  },
  SEARCH: {
    ALERT_RECIPIENTS: 'SEARCH.ALERT_RECIPIENTS',
    CHARACTER_TWO: 'SEARCH.CHARACTER_TWO',
    COMPANY: 'SEARCH.COMPANY',
    DOMAIN: 'SEARCH.DOMAIN',
    KEYWORDS: 'SEARCH.KEYWORDS',
    OPERATION_NAME: 'SEARCH.OPERATION_NAME',
    PART_NUMBER: 'SEARCH.PART_NUMBER',
    PRODUCT: 'SEARCH.PRODUCT',
    PRODUCT_PART_NUMBER: 'SEARCH.PRODUCT_PART_NUMBER',
    RESOURCE: 'SEARCH.RESOURCE',
    RESOURCE_FULL: 'SEARCH.RESOURCE_FULL',
    RESOURCE_GROUP: 'SEARCH.RESOURCE_GROUP',
    RESOURCE_TAG: 'SEARCH.RESOURCE_TAG',
    RESOURCE_TAG_VALUE: 'SEARCH.RESOURCE_TAG_VALUE',
    RESULT_CODE: 'SEARCH.RESULT_CODE',
    USER_ID: 'SEARCH.USER_ID',
  },
  LOGIN: {
    LOGIN_IN: 'LOGIN.LOGIN_IN',
    LOGIN_FAILED: 'LOGIN.LOGIN_FAILED',
    PASSWORD: 'LOGIN.PASSWORD',
    NEW_PASSWORD: 'LOGIN.NEW_PASSWORD',
    CONFIRM_PASSWORD: 'LOGIN.CONFIRM_PASSWORD',
    MFA_SIGN_IN: 'LOGIN.MFA_SIGN_IN',
    SSO: 'LOGIN.SSO',
    SIGNIN: 'LOGIN.SIGNIN',
    TITLE: 'LOGIN.TITLE',
    USERID: 'LOGIN.USERID',
    VALIDATOR: {
      CLASSES: 'LOGIN.VALIDATOR.CLASSES',
      LENGTH: 'LOGIN.VALIDATOR.LENGTH',
      MATCH_EXIST_PASSWORD: 'LOGIN.VALIDATOR.MATCH_EXIST_PASSWORD',
      PASSWD_MISMATCH: 'LOGIN.VALIDATOR.PASSWD_MISMATCH',
      USERID: 'LOGIN.VALIDATOR.USERID',
    },
    MESSAGE: {
      PASSWD_REQUIRED: 'LOGIN.MESSAGE.PASSWD_REQUIRED',
      ID_REQUIRED: 'LOGIN.MESSAGE.ID_REQUIRED',
      EMAIL_FORMAT: 'LOGIN.MESSAGE.EMAIL_FORMAT',
    },
    MFA: {
      STEP_1: 'LOGIN.MFA.STEP_1',
      STEP_2: 'LOGIN.MFA.STEP_2',
      STEP_3: 'LOGIN.MFA.STEP_3',
      APPS: 'LOGIN.MFA.APPS',
      ENTER: 'LOGIN.MFA.ENTER',
      SETUP_KEY: 'LOGIN.MFA.SETUP_KEY',
      REVEAL_KEY: 'LOGIN.MFA.REVEAL_KEY',
    },
  },
  THEME: {
    COLORMODE: {
      DARK: 'THEME.COLORMODE.DARK',
      LIGHT: 'THEME.COLORMODE.LIGHT',
    },
    MODE: {
      STATIC: 'THEME.MODE.STATIC',
      OVERLAY: 'THEME.MODE.OVERLAY',
      SLIM: 'THEME.MODE.SLIM',
      HORIZONTAL: 'THEME.MODE.HORIZONTAL',
    },
    SIZE: {
      COMPACT: 'THEME.SIZE.COMPACT',
      MATERIAL: 'THEME.SIZE.MATERIAL',
    },
    LABEL: {
      MENU: 'THEME.LABEL.MENU',
      SIZE: 'THEME.LABEL.SIZE',
      THEMES: 'THEME.LABEL.THEMES',
      MENU_MODES: 'THEME.LABEL.MENU_MODES',
      MENU_COLORS: 'THEME.LABEL.MENU_COLORS',
      MENU_SIZES: 'THEME.LABEL.MENU_SIZES',
    },
  },
  PAGE: {
    EMPTY: 'PAGE.EMPTY',
    NONE: 'PAGE.NONE',
    NO_RECORDS_FOUND: 'PAGE.NO_RECORDS_FOUND',
    DATA_QUERY_EMPTY: 'PAGE.DATA_QUERY_EMPTY',
    DATA_QUERY: 'PAGE.DATA_QUERY',
    SHOW_DATA_FOR_LAST: 'PAGE.SHOW_DATA_FOR_LAST',
    SIGN_OUT: 'PAGE.SIGN_OUT',
  },
  SECURITY: {
    ACCESS_PERMISSIONS: 'SECURITY.ACCESS_PERMISSIONS',
    ACCOUNT_IS_LOCKED: 'SECURITY.ACCOUNT_IS_LOCKED',
    ACCOUNT_LOCKOUT_DURATION: 'SECURITY.ACCOUNT_LOCKOUT_DURATION',
    ACCOUNT_LOCKOUT_POLICY: 'SECURITY.ACCOUNT_LOCKOUT_POLICY',
    ACCOUNT_LOCKOUT_THRESHOLD: 'SECURITY.ACCOUNT_LOCKOUT_THRESHOLD',
    ENFORCE_MFA: 'SECURITY.ENFORCE_MFA',
    ENFORCE_SINGLE_SIGN_ON: 'SECURITY.ENFORCE_SINGLE_SIGN_ON',
    LOGIN_FAIL_RETENTION_DURATION: 'SECURITY.LOGIN_FAIL_RETENTION_DURATION',
    MFA_RESET_NOTE: 'SECURITY.MFA_RESET_NOTE',
    MIN_PASSWORD_LENGTH: 'SECURITY.MIN_PASSWORD_LENGTH',
    NO_ACCESS_PERMISSION_TO_TEXT: 'SECURITY.NO_ACCESS_PERMISSION_TO_TEXT',
    NO_PERMISSION: 'SECURITY.NO_PERMISSION',
    PASSWORD_CAN_BE_MATCH_USER_ID: 'SECURITY.PASSWORD_CAN_BE_MATCH_USER_ID',
    PASSWORD_HAS_BEEN_RESET: 'SECURITY.PASSWORD_HAS_BEEN_RESET',
    PASSWORD_NOTE: 'SECURITY.PASSWORD_NOTE',
    PASSWORD_POLICY: 'SECURITY.PASSWORD_POLICY',
    PASSWORD_RESET_NOTE: 'SECURITY.PASSWORD_RESET_NOTE',
    PWD_CHANGE_AND_LOGIN: 'SECURITY.PWD_CHANGE_AND_LOGIN',
    SECURITY_POLICY_SETTINGS: 'SECURITY.SECURITY_POLICY_SETTINGS',
    UNLOCK_ACCOUNT_NOTE_SUCCESSFULLY: 'SECURITY.UNLOCK_ACCOUNT_NOTE_SUCCESSFULLY',
    UNLOCK_ACCOUNT_NOTE: 'SECURITY.UNLOCK_ACCOUNT_NOTE',
    UNLOCK_ACCOUNT: 'SECURITY.UNLOCK_ACCOUNT',
    USAGE_ACCESS_CONTROL_ASSIGNMENTS: 'SECURITY.USAGE_ACCESS_CONTROL_ASSIGNMENTS',
    USAGE_ACCESS_CONTROL_LIST: 'SECURITY.USAGE_ACCESS_CONTROL_LIST',
    POLICY_INFO: {
      ACCOUNT_LOCKOUT_DURATION: 'SECURITY.POLICY_INFO.ACCOUNT_LOCKOUT_DURATION',
      ACCOUNT_LOCKOUT_THRESHOLD: 'SECURITY.POLICY_INFO.ACCOUNT_LOCKOUT_THRESHOLD',
      LOGIN_FAIL_RETENTION_DURATION: 'SECURITY.POLICY_INFO.LOGIN_FAIL_RETENTION_DURATION',
      MATCH_EXIST_PASSWORD: 'SECURITY.POLICY_INFO.MATCH_EXIST_PASSWORD',
      MFA_INFO: 'SECURITY.POLICY_INFO.MFA_INFO',
      MIN_PASSWORD_LENGTH: 'SECURITY.POLICY_INFO.MIN_PASSWORD_LENGTH',
      NUMBER_OF_CHARACTER_CLASSES_MAX: 'SECURITY.POLICY_INFO.NUMBER_OF_CHARACTER_CLASSES_MAX',
      NUMBER_OF_CHARACTER_CLASSES_MIN: 'SECURITY.POLICY_INFO.NUMBER_OF_CHARACTER_CLASSES_MIN',
      NUMBER_OF_CHARACTER_CLASSES: 'SECURITY.POLICY_INFO.NUMBER_OF_CHARACTER_CLASSES',
      PASSWORD_CAN_BE_MATCH_USER_ID: 'SECURITY.POLICY_INFO.PASSWORD_CAN_BE_MATCH_USER_ID',
      ORGANIZATION_POLICY_INFO: 'SECURITY.POLICY_INFO.ORGANIZATION_POLICY_INFO',
      OVERWRITE_ENROLLMENT_POLICY_NOTE: 'SECURITY.POLICY_INFO.OVERWRITE_ENROLLMENT_POLICY_NOTE',
    },
  },
  POWERBI: {
    BALANCE_SUMMARY: 'POWERBI.BALANCE_SUMMARY',
    COST_BREAKDOWN: 'POWERBI.COST_BREAKDOWN',
    COST_FORECAST: 'POWERBI.COST_FORECAST',
    DAILY_USAGE_DETAIL: 'POWERBI.DAILY_USAGE_DETAIL',
    DAILY_USAGE_ANALYSIS: 'POWERBI.DAILY_USAGE_ANALYSIS',
    MONTHLY_USAGE_DETAIL: 'POWERBI.MONTHLY_USAGE_DETAIL',
    MONTHLY_USAGE_ANALYSIS: 'POWERBI.MONTHLY_USAGE_ANALYSIS',
    METERED_BILLING_ANALYSIS: 'POWERBI.METERED_BILLING_ANALYSIS',
    ONBOARD_DETAIL: 'POWERBI.ONBOARD_DETAIL',
    ONBOARD_ANALYSIS: 'POWERBI.ONBOARD_ANALYSIS',
    OVERALL_COST_ANALYSIS: 'POWERBI.OVERALL_COST_ANALYSIS',
    OVERALL_DATA_INFO: 'POWERBI.OVERALL_DATA_INFO',
    PRODUCT_COST_ANALYSIS: 'POWERBI.PRODUCT_COST_ANALYSIS',
    RESOURCE_COST_ANALYSIS: 'POWERBI.RESOURCE_COST_ANALYSIS',
    RI_OREDER: 'POWERBI.RI_OREDER',
    RI_OVERALL: 'POWERBI.RI_OVERALL',
    RI_USAGE_ANALYSIS: 'POWERBI.RI_USAGE_ANALYSIS',
    SERVICE_COST_ANALYSIS: 'POWERBI.SERVICE_COST_ANALYSIS',
    SAAS_METERING: 'POWERBI.SAAS_METERING',
    SAAS_METERING_ANALYSIS: 'POWERBI.SAAS_METERING_ANALYSIS',
    SAAS_SUBSCRIPTION_ANALYSIS: 'POWERBI.SAAS_SUBSCRIPTION_ANALYSIS',
    SAAS_USAGE: 'POWERBI.SAAS_USAGE',
    SAAS_USAGE_DETAIL: 'POWERBI.SAAS_USAGE_DETAIL',
  },
  DATA_TABLE: {
    NO_DATA: 'DATA_TABLE.NO_DATA',
    LOADING: 'DATA_TABLE.LOADING',
    LESS_THAN_10: 'DATA_TABLE.LESS_THAN_10',
    ONE_RECORD: 'DATA_TABLE.ONE_RECORD',
    PAGINATOR: 'DATA_TABLE.PAGINATOR',
  },
  USAGE_BUDGET_INFO: {
    BEYOND_DATE_ALERT: 'USAGE_BUDGET_INFO.BEYOND_DATE_ALERT',
    EXPIRATION_DATE: 'USAGE_BUDGET_INFO.EXPIRATION_DATE',
    MONTHLY_BUDGET_OVERAGE_ALERT: 'USAGE_BUDGET_INFO.MONTHLY_BUDGET_OVERAGE_ALERT',
    THRESHOLD_1: 'USAGE_BUDGET_INFO.THRESHOLD_1',
    THRESHOLD_2: 'USAGE_BUDGET_INFO.THRESHOLD_2',
    THRESHOLD_3: 'USAGE_BUDGET_INFO.THRESHOLD_3',
    TOTAL_BUDGET_OVERAGE_ALERT: 'USAGE_BUDGET_INFO.TOTAL_BUDGET_OVERAGE_ALERT',
  },
  CUSTOMER_PROFILE: {
    ADDRESS_LINE_1: 'CUSTOMER_PROFILE.ADDRESS_LINE_1',
    ADDRESS_LINE_2: 'CUSTOMER_PROFILE.ADDRESS_LINE_2',
    AGREEMENT_DATE: 'CUSTOMER_PROFILE.AGREEMENT_DATE',
    ALLOW_CUSTOMER_TO_PURCHASE: 'CUSTOMER_PROFILE.ALLOW_CUSTOMER_TO_PURCHASE',
    BILL_TO_INFO: 'CUSTOMER_PROFILE.BILL_TO_INFO',
    COMPANY_INFO: 'CUSTOMER_PROFILE.COMPANY_INFO',
    COUNTRY_TOOLTIP: 'CUSTOMER_PROFILE.COUNTRY_TOOLTIP',
    CREDIT_AMOUNT: 'CUSTOMER_PROFILE.CREDIT_AMOUNT',
    CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER: 'CUSTOMER_PROFILE.CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER',
    CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT: 'CUSTOMER_PROFILE.CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT',
    CUSTOMER_ACCOUNT_INFO: 'CUSTOMER_PROFILE.CUSTOMER_ACCOUNT_INFO',
    CUSTOMER_AGREEMENT_INFO: 'CUSTOMER_PROFILE.CUSTOMER_AGREEMENT_INFO',
    CUSTOMER_EMAIL: 'CUSTOMER_PROFILE.CUSTOMER_EMAIL',
    CUSTOMER_PERMISSIONS: 'CUSTOMER_PROFILE.CUSTOMER_PERMISSIONS',
    EMAIL_TOOLTIP: 'CUSTOMER_PROFILE.EMAIL_TOOLTIP',
    LINK_CORRECTING_EDUCATION_INFO: 'CUSTOMER_PROFILE.LINK_CORRECTING_EDUCATION_INFO',
    LINK_CUSTOMER_VALIDATION_PROCESS: 'CUSTOMER_PROFILE.LINK_CUSTOMER_VALIDATION_PROCESS',
    LINK_HOW_CONFIRM_AGREEMENT: 'CUSTOMER_PROFILE.LINK_HOW_CONFIRM_AGREEMENT',
    LINK_HOW_DISPUTE_DECISION: 'CUSTOMER_PROFILE.LINK_HOW_DISPUTE_DECISION',
    MANAGEMENT_INFO: 'CUSTOMER_PROFILE.MANAGEMENT_INFO',
    NOT_PROVIDED: 'CUSTOMER_PROFILE.NOT_PROVIDED',
    POSTAL_CODE: 'CUSTOMER_PROFILE.POSTAL_CODE',
    PROVIDED: 'CUSTOMER_PROFILE.PROVIDED',
    RESERVATIONS: 'CUSTOMER_PROFILE.RESERVATIONS',
    SALES_AGENT: 'CUSTOMER_PROFILE.SALES_AGENT',
    SPECIAL_QUALIFICATIONS: 'CUSTOMER_PROFILE.SPECIAL_QUALIFICATIONS',
    UPDATE_QUALIFICATION: 'CUSTOMER_PROFILE.UPDATE_QUALIFICATION',
    UPDATE_AGREEMENT_INFO: 'CUSTOMER_PROFILE.UPDATE_AGREEMENT_INFO',
    COPY_AGREEMENT_URL: 'CUSTOMER_PROFILE.COPY_AGREEMENT_URL',
    HINT_CANCEL_CONFIRM_HEADER: 'CUSTOMER_PROFILE.HINT_CANCEL_CONFIRM_HEADER',
    HINT_CANCEL_CONFIRM_MESSAGE: 'CUSTOMER_PROFILE.HINT_CANCEL_CONFIRM_MESSAGE',
  },
  USER_PROFILE: {
    HAS_BEEN_UPDATED: 'USER_PROFILE.HAS_BEEN_UPDATED',
    LANGUAGE: 'USER_PROFILE.LANGUAGE',
    LAST_LOGIN_INFO: 'USER_PROFILE.LAST_LOGIN_INFO',
    SUMMARY: 'USER_PROFILE.SUMMARY',
    TIME_ZONE: 'USER_PROFILE.TIME_ZONE',
    TITLE: 'USER_PROFILE.TITLE',
    USER_ID: 'USER_PROFILE.USER_ID',
    USER_NAME: 'USER_PROFILE.USER_NAME',
    PASSWD: {
      CHANGE_PASSWD: 'USER_PROFILE.PASSWD.CHANGE_PASSWD',
      CONFIRM_NEW_PASSWD: 'USER_PROFILE.PASSWD.CONFIRM_NEW_PASSWD',
      CONFIRM_PASSWD: 'USER_PROFILE.PASSWD.CONFIRM_PASSWD',
      CONFIRM_REQUIRED: 'USER_PROFILE.PASSWD.CONFIRM_REQUIRED',
      CURRENT_PASSWD: 'USER_PROFILE.PASSWD.CURRENT_PASSWD',
      CURRENT_REQUIRED: 'USER_PROFILE.PASSWD.CURRENT_REQUIRED',
      ENTER_CURRENT_PASSWD: 'USER_PROFILE.PASSWD.ENTER_CURRENT_PASSWD',
      NEW_PASSWD: 'USER_PROFILE.PASSWD.NEW_PASSWD',
      NEW_REQUIRED: 'USER_PROFILE.PASSWD.NEW_REQUIRED',
      NEW_SAME_AS_CURRENT: 'USER_PROFILE.PASSWD.NEW_SAME_AS_CURRENT',
      PASSWD_MISMATCH: 'USER_PROFILE.PASSWD.PASSWD_MISMATCH',
    },
  },
  USAGE_COMMON_FILTER: {
    ACCESS_CONTROL_RESTRICTIONS: 'USAGE_COMMON_FILTER.ACCESS_CONTROL_RESTRICTIONS',
    HIERARCHY: 'USAGE_COMMON_FILTER.HIERARCHY',
    IMAGE_LINK_HACKER: 'USAGE_COMMON_FILTER.IMAGE_LINK_HACKER',
    IMAGE_LINK: 'USAGE_COMMON_FILTER.IMAGE_LINK',
    QUERY_PRODUCT: 'USAGE_COMMON_FILTER.QUERY_PRODUCT',
    QUERY_RESOURCE_GROUP: 'USAGE_COMMON_FILTER.QUERY_RESOURCE_GROUP',
    QUERY_TAG: 'USAGE_COMMON_FILTER.QUERY_TAG',
    RESTRICTIONS_HEADER: 'USAGE_COMMON_FILTER.RESTRICTIONS_HEADER',
    UNRESTRICTED: 'USAGE_COMMON_FILTER.UNRESTRICTED',
  },
  EA_OB_PAGE: {
    AUTHORIZATION_CONFIG: 'EA_OB_PAGE.AUTHORIZATION_CONFIG',
    CLOUD_TENANT_ADMIN_AUTHORIZATION: 'EA_OB_PAGE.CLOUD_TENANT_ADMIN_AUTHORIZATION',
    EA_ENROLLMENT_NUM: 'EA_OB_PAGE.EA_ENROLLMENT_NUM',
    EA_NAME: 'EA_OB_PAGE.EA_NAME',
    INIT_SYNC_TIME: 'EA_OB_PAGE.INIT_SYNC_TIME',
    LAST_RM_SYNC_TIME: 'EA_OB_PAGE.LAST_RM_SYNC_TIME',
    NOTICE: 'EA_OB_PAGE.NOTICE',
    OB_INVITATION: 'EA_OB_PAGE.OB_INVITATION',
    OB_PROGRESS: 'EA_OB_PAGE.OB_PROGRESS',
    OVERALL_OB_PROGRESS: 'EA_OB_PAGE.OVERALL_OB_PROGRESS',
    PRICE_SHEET_IMPORT_PROGRESS: 'EA_OB_PAGE.PRICE_SHEET_IMPORT_PROGRESS',
    STATUS: 'LABEL.STATUS',
    USAGE_IMPORT_PROGRESS: 'EA_OB_PAGE.USAGE_IMPORT_PROGRESS',
    VALID_SUBSCRIPTION: 'EA_OB_PAGE.VALID_SUBSCRIPTION',
  },
  ERRROR_HANDLER: {
    HTTP_ERROR_SUMMARY: 'ERRROR_HANDLER.HTTP_ERROR_SUMMARY',
  },
  FORECAST: {
    FORECAST_MODEL: 'FORECAST.FORECAST_MODEL',
    FORECAST: 'FORECAST.FORECAST',
    ACTUAL_COST: 'FORECAST.ACTUAL_COST',
    DIFF_COST: 'FORECAST.DIFF_COST',
    FORECAST_COST: 'FORECAST.FORECAST_COST',
    PREDICT_COST: 'FORECAST.PREDICT_COST',
  },
  FEATURE_PACK: {
    ASSIGN: 'FEATURE_PACK.ASSIGN',
    DETAIL: 'FEATURE_PACK.DETAIL',
    EDIT: 'FEATURE_PACK.EDIT',
    LIST: 'FEATURE_PACK.LIST',
    NEW_FEATURE_PACK_NAME: 'FEATURE_PACK.NEW_FEATURE_PACK_NAME',
    SELECTED_LIST: 'FEATURE_PACK.SELECTED_LIST',
    ENROLLMENT_FEATURE: {
      LIST: 'FEATURE_PACK.ENROLLMENT_FEATURE.LIST',
      DETAIL: 'FEATURE_PACK.ENROLLMENT_FEATURE.DETAIL',
      ACCESS: 'FEATURE_PACK.ENROLLMENT_FEATURE.ACCESS',
      ASSIGNED_LIST: 'FEATURE_PACK.ENROLLMENT_FEATURE.ASSIGNED_LIST',
    },
  },
  USER_GROUP: {
    NO_CUSTOMERS: 'USER_GROUP.NO_CUSTOMERS',
    NO_ASSIGNED_CUSTOMERS: 'USER_GROUP.NO_ASSIGNED_CUSTOMERS',
    NO_RESELLERS: 'USER_GROUP.NO_RESELLERS',
    NO_ASSIGNED_RESELLERS: 'USER_GROUP.NO_ASSIGNED_RESELLERS',
    NO_USER_GROUPS: 'USER_GROUP.NO_USER_GROUPS',
    NO_USERS: 'USER_GROUP.NO_USERS',
  },
  PRICING_CONFIG: {
    BASELINE_MARKUP_RULE: 'PRICING_CONFIG.BASELINE_MARKUP_RULE',
    BASELINE_MARKUP_RULE_NOTE: 'PRICING_CONFIG.BASELINE_MARKUP_RULE_NOTE',
    BY_DISCOUNT_PERCENT_RULES: 'PRICING_CONFIG.BY_DISCOUNT_PERCENT_RULES',
    BY_SKU_RULES: 'PRICING_CONFIG.BY_SKU_RULES',
    CATEGORY: 'PRICING_CONFIG.CATEGORY',
    PERCENTAGE_OF_COST_PRICE: 'PRICING_CONFIG.PERCENTAGE_OF_COST_PRICE',
    PERCENTAGE_OF_LIST_PRICE: 'PRICING_CONFIG.PERCENTAGE_OF_LIST_PRICE',
    PEC_OF_LIST_PRICE: 'PRICING_CONFIG.PEC_OF_LIST_PRICE',
    CUSTOMER_DISCOUNT_PERCENT: 'PRICING_CONFIG.CUSTOMER_DISCOUNT_PERCENT',
    CUSTOMER_LIST: 'PRICING_CONFIG.CUSTOMER_LIST',
    DISTRIBUTOR_DISCOUNT_PERCENT: 'PRICING_CONFIG.DISTRIBUTOR_DISCOUNT_PERCENT',
    MARKUP: 'PRICING_CONFIG.MARKUP',
    MARKUP_OF_COST_PRICE: 'PRICING_CONFIG.MARKUP_OF_COST_PRICE',
    NO_DISCOUNT_PERCENT_RULES: 'PRICING_CONFIG.NO_DISCOUNT_PERCENT_RULES',
    NO_SKU_RULES: 'PRICING_CONFIG.NO_SKU_RULES',
    PARTNER_DISCOUNT_PERCENT: 'PRICING_CONFIG.PARTNER_DISCOUNT_PERCENT',
    PARTNER_EARNED_CREDIT_SHARING_PERCENTAGE_FOR_RESELLER:
      'PRICING_CONFIG.PARTNER_EARNED_CREDIT_SHARING_PERCENTAGE_FOR_RESELLER',
    PEC_PECENT_FOR_RESELLER: 'PRICING_CONFIG.PEC_PECENT_FOR_RESELLER',
    PRICING_CONFIG_NAME: 'PRICING_CONFIG.PRICING_CONFIG_NAME',
    RESELLER_DISCOUNT_PERCENT: 'PRICING_CONFIG.RESELLER_DISCOUNT_PERCENT',
    RESELLER_LIST: 'PRICING_CONFIG.RESELLER_LIST',
    SELECT_A_DISCOUNT_PERCENT: 'PRICING_CONFIG.SELECT_A_DISCOUNT_PERCENT',
  },
  SALES_ORDERS: {
    CREATE_INVOICE: 'SALES_ORDERS.CREATE_INVOICE',
    CUSTOMER_VIEW: 'SALES_ORDERS.CUSTOMER_VIEW',
    EDIT_LINE_ITEM: 'SALES_ORDERS.EDIT_LINE_ITEM',
    ON_DEMAND_INVOICE: 'SALES_ORDERS.ON_DEMAND_INVOICE',
    INVOICE_CREATED: 'SALES_ORDERS.INVOICE_CREATED',
    LINE_ITEM: {
      NEW: 'SALES_ORDERS.LINE_ITEM.NEW',
      ORDERED: 'SALES_ORDERS.LINE_ITEM.ORDERED',
      ORDER_FAILED: 'SALES_ORDERS.LINE_ITEM.ORDER_FAILED',
      VOID: 'SALES_ORDERS.LINE_ITEM.VOID',
    },
    ORDER: {
      NEW: 'SALES_ORDERS.ORDER.NEW',
      APPROVED: 'SALES_ORDERS.ORDER.APPROVED',
      COMPLETED: 'STAT.COMPLETED',
      PARTIAL_ORDERED: 'SALES_ORDERS.ORDER.PARTIAL_ORDERED',
      VOID: 'SALES_ORDERS.ORDER.VOID',
    },
    ORDER_LINE_ITEMS: 'SALES_ORDERS.ORDER_LINE_ITEMS',
    PARTNER_VIEW: 'SALES_ORDERS.PARTNER_VIEW',
    PLACE_ORDER: 'SALES_ORDERS.PLACE_ORDER',
    PLACING_ORDER: 'SALES_ORDERS.PLACING_ORDER',
    RESELLER_VIEW: 'SALES_ORDERS.RESELLER_VIEW',
    SALES_ORDER_DETAIL: 'SALES_ORDERS.SALES_ORDER_DETAIL',
    VIEW_INVOICE: 'SALES_ORDERS.VIEW_INVOICE',
  },
  SAAS_SUBSCRIPTION: {
    AUTO_RENEW: 'SAAS_SUBSCRIPTION.AUTO_RENEW',
    BENEFICIARY_EMAIL: 'SAAS_SUBSCRIPTION.BENEFICIARY_EMAIL',
    CHANGE_QTY: 'SAAS_SUBSCRIPTION.CHANGE_QTY',
    IS_FREE_TRIAL: 'SAAS_SUBSCRIPTION.IS_FREE_TRIAL',
    IS_TEST: 'SAAS_SUBSCRIPTION.IS_TEST',
    SELECT_A_PLAN: 'SAAS_SUBSCRIPTION.SELECT_A_PLAN',
    ONBOARDING: 'SAAS_SUBSCRIPTION.ONBOARDING',
    ONBOARD_PROCESS: 'SAAS_SUBSCRIPTION.ONBOARD_PROCESS',
    PURCHASE_EMAIL: 'SAAS_SUBSCRIPTION.PURCHASE_EMAIL',
    SUBSCRIPTION_STATUS: 'SAAS_SUBSCRIPTION.SUBSCRIPTION_STATUS',
  },
  CONTACT: {
    ADMIN: 'CONTACT.ADMIN',
    BILLING: 'CONTACT.BILLING',
    TECHNICAL: 'CONTACT.TECHNICAL',
  },
  PREREQUISITES: {
    MICROSOFT_PARTNER_AGREEMENT_SIGNED: 'PREREQUISITES.MICROSOFT_PARTNER_AGREEMENT_SIGNED',
    INDIRECT_RESELLER_ACTIVE: 'PREREQUISITES.INDIRECT_RESELLER_ACTIVE',
    COMPANY_PROFILE_PROVIDED: 'PREREQUISITES.COMPANY_PROFILE_PROVIDED',
    BILLING_PROFILE_PROVIDED: 'PREREQUISITES.BILLING_PROFILE_PROVIDED',
    TAX_INFO_PROVIDED: 'PREREQUISITES.TAX_INFO_PROVIDED',
    ADMIN_USERS_ASSIGNED: 'PREREQUISITES.ADMIN_USERS_ASSIGNED',
    RESELLER_AGENT_ASSIGNED: 'PREREQUISITES.RESELLER_AGENT_ASSIGNED',
    MANAGEMENT_GROUP_ASSIGNED: 'PREREQUISITES.MANAGEMENT_GROUP_ASSIGNED',
    PRICING_CONFIG_ASSIGNED: 'PREREQUISITES.PRICING_CONFIG_ASSIGNED',
  },
  RI_RECOMM_REPORT: {
    ONE_Y: 'RI_RECOMM_REPORT.ONE_Y',
    ONE_YEAR_RI: 'RI_RECOMM_REPORT.ONE_YEAR_RI',
    ONE_YEAR_RI_RECOMM: 'RI_RECOMM_REPORT.ONE_YEAR_RI_RECOMM',
    ONE_YEAR_RI_YEARLY_SAVING: 'RI_RECOMM_REPORT.ONE_YEAR_RI_YEARLY_SAVING',
    ONE_Y_RI_RECOMM: 'RI_RECOMM_REPORT.ONE_Y_RI_RECOMM',
    FILTERED_BY_FLEXIBILITY: 'RI_RECOMM_REPORT.FILTERED_BY_FLEXIBILITY',
    THREE_Y: 'RI_RECOMM_REPORT.THREE_Y',
    THREE_YEAR_RI: 'RI_RECOMM_REPORT.THREE_YEAR_RI',
    THREE_YEAR_RI_RECOMM: 'RI_RECOMM_REPORT.THREE_YEAR_RI_RECOMM',
    THREE_YEAR_RI_YEARLY_SAVING: 'RI_RECOMM_REPORT.THREE_YEAR_RI_YEARLY_SAVING',
    THREE_Y_RI_RECOMM: 'RI_RECOMM_REPORT.THREE_Y_RI_RECOMM',
    TOTAL_CURRENT_COST: 'RI_RECOMM_REPORT.TOTAL_CURRENT_COST',
    TOTAL_NEW_COST: 'RI_RECOMM_REPORT.TOTAL_NEW_COST',
    TOTAL_RI_PRICE: 'RI_RECOMM_REPORT.TOTAL_RI_PRICE',
    TOTAL_SAVING_YEARLY: 'RI_RECOMM_REPORT.TOTAL_SAVING_YEARLY',
    SKU_REGION_PRICE: 'RI_RECOMM_REPORT.SKU_REGION_PRICE',
    SKU_REGION_PRICE_COMPARISON: 'RI_RECOMM_REPORT.SKU_REGION_PRICE_COMPARISON',
    RI_RECOMMENDATION_ANALYSIS: 'RI_RECOMM_REPORT.RI_RECOMMENDATION_ANALYSIS',
    YEARLY_SAVINGS_RANGE: 'RI_RECOMM_REPORT.YEARLY_SAVINGS_RANGE',
  },
  CUSTOM_DIMENSION: {
    ADD_DIMENSION_VALUE_MAPPING_RULE: 'CUSTOM_DIMENSION.ADD_DIMENSION_VALUE_MAPPING_RULE',
    ADD_TOOLTIP: 'CUSTOM_DIMENSION.ADD_TOOLTIP',
    BUILD_DIMENSION_HINT: 'CUSTOM_DIMENSION.BUILD_DIMENSION_HINT',
    COST_CATEGORY_KEY_ALL: 'CUSTOM_DIMENSION.COST_CATEGORY_KEY_ALL',
    COST_CATEGORY_KEY_ANY: 'CUSTOM_DIMENSION.COST_CATEGORY_KEY_ANY',
    CHANGE_DIMENSION_NAME: 'CUSTOM_DIMENSION.CHANGE_DIMENSION_NAME',
    CHANGE_DIMENSION_NAME_WARNING: 'CUSTOM_DIMENSION.CHANGE_DIMENSION_NAME_WARNING',
    COST_CATEGORY_VALUE_MAX_WARNING: 'CUSTOM_DIMENSION.COST_CATEGORY_VALUE_MAX_WARNING',
    COST_CATEGORY_VALUE_DISABLE_WARNING: 'CUSTOM_DIMENSION.COST_CATEGORY_VALUE_DISABLE_WARNING',
    DUPLICATED_DIMENSION_NAME_WARNING: 'CUSTOM_DIMENSION.DUPLICATED_DIMENSION_NAME_WARNING',
    EDIT_DIMENSION_AND_DISPLAY_VALUE: 'CUSTOM_DIMENSION.EDIT_DIMENSION_AND_DISPLAY_VALUE',
    IMPORT_DIMENSION_VALUE_MAPPING_RULE: 'CUSTOM_DIMENSION.IMPORT_DIMENSION_VALUE_MAPPING_RULE',
    IMPORT_TOOLTIP: 'CUSTOM_DIMENSION.IMPORT_TOOLTIP',
    LABEL_KEY_ALL: 'CUSTOM_DIMENSION.LABEL_KEY_ALL',
    LABEL_KEY_ANY: 'CUSTOM_DIMENSION.LABEL_KEY_ANY',
    LABEL_VALUE_MAX_WARNING: 'CUSTOM_DIMENSION.LABEL_VALUE_MAX_WARNING',
    LABEL_VALUE_DISABLE_WARNING: 'CUSTOM_DIMENSION.LABEL_VALUE_DISABLE_WARNING',
    MAX_DIMENSIONS_HINT: 'CUSTOM_DIMENSION.MAX_DIMENSIONS_HINT',
    TAG_KEY_ALL: 'CUSTOM_DIMENSION.TAG_KEY_ALL',
    TAG_KEY_ANY: 'CUSTOM_DIMENSION.TAG_KEY_ANY',
    TAG_VALUE_MAX_WARNING: 'CUSTOM_DIMENSION.TAG_VALUE_MAX_WARNING',
    TAG_VALUE_DISABLE_WARNING: 'CUSTOM_DIMENSION.TAG_VALUE_DISABLE_WARNING',
  },
  COST_DASHBOARD: {
    CHART_HINT: 'COST_DASHBOARD.CHART_HINT',
    CLOUD_COST_OVER_MONTHS: 'COST_DASHBOARD.CLOUD_COST_OVER_MONTHS',
    CLOUD_COST_OVER_WEEKS: 'COST_DASHBOARD.CLOUD_COST_OVER_WEEKS',
    COST_ANALYSIS_BY_SERVICE_CATEGORY: 'COST_DASHBOARD.COST_ANALYSIS_BY_SERVICE_CATEGORY',
    CURRENT_MONTH: 'COST_DASHBOARD.CURRENT_MONTH',
    OF_CURRENT_MONTH: 'COST_DASHBOARD.OF_CURRENT_MONTH',
    SWITCH_STACKED_BAR_CHART: 'COST_DASHBOARD.SWITCH_STACKED_BAR_CHART',
    SWITCH_LINE_CHART: 'COST_DASHBOARD.SWITCH_LINE_CHART',
    TOP_10_REGION: 'COST_DASHBOARD.TOP_10_REGION',
  },
  PROVISION: {
    APPROVAL_STATUS: 'PROVISION.APPROVAL_STATUS',
    COST_DETAILS: 'PROVISION.COST_DETAILS',
    COST_RANGE: 'PROVISION.COST_RANGE',
    CONFIGRATION_DETAIL: 'PROVISION.CONFIGRATION_DETAIL',
    DESTROY_STATUS: 'PROVISION.DESTROY_STATUS',
    DURATION_TIME: 'PROVISION.DURATION_TIME',
    ESIMATED_COST_MONTH: 'PROVISION.ESIMATED_COST_MONTH',
    PLAN_STATUS: 'PROVISION.PLAN_STATUS',
    PROVISION_ITEM: 'PROVISION.PROVISION_ITEM',
    PROVISION_RESULT: 'PROVISION.PROVISION_RESULT',
    PROVISION_STATUS: 'PROVISION.PROVISION_STATUS',
    VIEW_DETAILS: 'PROVISION.VIEW_DETAILS',
  },
  VM_PROVISION: {
    ACCOUNT_DETAIL: 'VM_PROVISION.ACCOUNT_DETAIL',
    ACCOUNT_DETAIL_INFO: 'VM_PROVISION.ACCOUNT_DETAIL_INFO',
    ADMINISTRATION_ACCOUNT: 'VM_PROVISION.ADMINISTRATION_ACCOUNT',
    AUTHENTICATION_TYPE: 'VM_PROVISION.AUTHENTICATION_TYPE',
    BASIC: 'VM_PROVISION.BASIC',
    CREATE_NEW: 'VM_PROVISION.CREATE_NEW',
    CUSTOM_DURATION: 'VM_PROVISION.CUSTOM_DURATION',
    DATE_RANGE_ERROR: 'VM_PROVISION.DATE_RANGE_ERROR',
    DELETE_WITH_VM: 'VM_PROVISION.DELETE_WITH_VM',
    DISK_OPTIONS: 'VM_PROVISION.DISK_OPTIONS',
    DUPLICATE_NAME: 'VM_PROVISION.DUPLICATE_NAME',
    DURATION_INFO: 'VM_PROVISION.DURATION_INFO',
    ESTIMATED_COST: 'VM_PROVISION.ESTIMATED_COST',
    EXISTING: 'VM_PROVISION.EXISTING',
    FROM_DATE_TIME: 'VM_PROVISION.FROM_DATE_TIME',
    GCP_VIRTUAL_MACHINE_FORMAT_INFO: 'VM_PROVISION.GCP_VIRTUAL_MACHINE_FORMAT_INFO',
    IMAGE: 'VM_PROVISION.IMAGE',
    INSTANCE_DETAILS: 'VM_PROVISION.INSTANCE_DETAILS',
    LINUX_VM_INFO: 'VM_PROVISION.LINUX_VM_INFO',
    MANAGED_DISKS: 'VM_PROVISION.MANAGED_DISKS',
    NETWORK_SERVICE: 'VM_PROVISION.NETWORK_SERVICE',
    NO_LIMIT: 'VM_PROVISION.NO_LIMIT',
    OS_DISK_TYPE: 'VM_PROVISION.OS_DISK_TYPE',
    OS_DISK_SIZE: 'VM_PROVISION.OS_DISK_SIZE',
    OS_DISK_DELETE_ON_TERMINATION: 'VM_PROVISION.OS_DISK_DELETE_ON_TERMINATION',
    OS_IMAGE: 'VM_PROVISION.OS_IMAGE',
    OS_DISK_VOLUME_SIZE_RANGE_INFO: 'VM_PROVISION.OS_DISK_VOLUME_SIZE_RANGE_INFO',
    PASSWORD_FORMAT_INFO: 'VM_PROVISION.PASSWORD_FORMAT_INFO',
    PASSWORD_CONFIRM_INFO: 'VM_PROVISION.PASSWORD_CONFIRM_INFO',
    PROJECT_DETAILS: 'VM_PROVISION.PROJECT_DETAILS',
    PROJECT_INFO: 'VM_PROVISION.PROJECT_INFO',
    PROVISION_DURATION: 'VM_PROVISION.PROVISION_DURATION',
    REGION_ZONE: 'VM_PROVISION.REGION_ZONE',
    PUBLIC_IP: 'VM_PROVISION.PUBLIC_IP',
    RESOURCE_GROUP_FORMAT_INFO: 'VM_PROVISION.RESOURCE_GROUP_FORMAT_INFO',
    RESOURCE_GROUP_NAME: 'VM_PROVISION.RESOURCE_GROUP_NAME',
    STANDARD: 'VM_PROVISION.STANDARD',
    SSH_PUBLIC_KEY: 'VM_PROVISION.SSH_PUBLIC_KEY',
    SSH_REGEXP_INFO: 'VM_PROVISION.SSH_REGEXP_INFO',
    SUBSCRIPTION_NO_SKU_INFO: 'VM_PROVISION.SUBSCRIPTION_NO_SKU_INFO',
    TO_DATE_TIME: 'VM_PROVISION.TO_DATE_TIME',
    VALIDATOR_LENGTH_INFO: 'VM_PROVISION.VALIDATOR_LENGTH_INFO',
    VIRTUAL_MACHINE_FORMAT_INFO: 'VM_PROVISION.VIRTUAL_MACHINE_FORMAT_INFO',
    VIRTUAL_MACHINE_NAME: 'VM_PROVISION.VIRTUAL_MACHINE_NAME',
    VIRTUAL_MACHINE_INSTANCE: 'VM_PROVISION.VIRTUAL_MACHINE_INSTANCE',
    WINDOWS_VM_INFO: 'VM_PROVISION.WINDOWS_VM_INFO',
    USER_NAME_FORMAT_INFO: 'VM_PROVISION.USER_NAME_FORMAT_INFO',
  },
  USER_ACCESS: {
    ROLE_ASSIGNMENTS: 'USER_ACCESS.ROLE_ASSIGNMENTS',
    ROOT_SCOPE_ROLE: 'USER_ACCESS.ROOT_SCOPE_ROLE',
    ROOT_SCOPE_ROLE_HINT: 'USER_ACCESS.ROOT_SCOPE_ROLE_HINT',
    SCOPE_HINT: 'USER_ACCESS.SCOPE_HINT',
  },
  RESOURCE_SCHEDULE: {
    ATTACH_SCHEDULE: 'RESOURCE_SCHEDULE.ATTACH_SCHEDULE',
    ATTACH_SCHEDULE_NOTE: 'RESOURCE_SCHEDULE.ATTACH_SCHEDULE_NOTE',
    CHANGE_SCHEDULE: 'RESOURCE_SCHEDULE.CHANGE_SCHEDULE',
    CREATE_RESOURCE_SCHEDULE: 'RESOURCE_SCHEDULE.CREATE_RESOURCE_SCHEDULE',
    DETACH_SCHEDULE: 'RESOURCE_SCHEDULE.DETACH_SCHEDULE',
    DETACH_SCHEDULE_NOTE: 'RESOURCE_SCHEDULE.DETACH_SCHEDULE_NOTE',
    DELETE_SCHEDULE_NOTE: 'RESOURCE_SCHEDULE.DELETE_SCHEDULE_NOTE',
    EDIT_SCHEDULE: 'RESOURCE_SCHEDULE.EDIT_SCHEDULE',
    EXISTED_NAME_ALERT: 'RESOURCE_SCHEDULE.EXISTED_NAME_ALERT',
    RESET_SCHEDULE: 'RESOURCE_SCHEDULE.RESET_SCHEDULE',
    RESOURCE_SCHEDULE: 'RESOURCE_SCHEDULE.RESOURCE_SCHEDULE',
    SAVE_AS_NEW_SCHEDULE: 'RESOURCE_SCHEDULE.SAVE_AS_NEW_SCHEDULE',
    SCHEDULE_NAME: 'RESOURCE_SCHEDULE.SCHEDULE_NAME',
    SCHEDULE_RESOLUTION: 'RESOURCE_SCHEDULE.SCHEDULE_RESOLUTION',
    UPDATE_SCHEDULE_NOTE: 'RESOURCE_SCHEDULE.UPDATE_SCHEDULE_NOTE',
  },
  DROPCELL: {
    ORPHAN: 'DROPCELL.ORPHAN',
  },
  ORPHAN: {
    TITLE: 'ORPHAN.TITLE',
    RESOURCE_PERCENT_IN_COST: 'ORPHAN.RESOURCE_PERCENT_IN_COST',
  },
  UNUSED_LICENSE: {
    TITLE: 'UNUSED_LICENSE.TITLE',
    NUMER_OF_LICENSE_TYPES: 'UNUSED_LICENSE.NUMER_OF_LICENSE_TYPES',
    NUMBER_OF_LICENSE: 'UNUSED_LICENSE.NUMBER_OF_LICENSE',
    POTENTIAL_WASTE_PER_MONTH: 'UNUSED_LICENSE.POTENTIAL_WASTE_PER_MONTH',
    UNUSED_LICENSE_COST_PERCENTAGE: 'UNUSED_LICENSE.UNUSED_LICENSE_COST_PERCENTAGE',
    TOTAL_UNITS: 'UNUSED_LICENSE.TOTAL_UNITS',
    UNUSED_NUMBER: 'UNUSED_LICENSE.UNUSED_NUMBER',
    INACTIVE_LICENSE: 'UNUSED_LICENSE.INACTIVE_LICENSE',
    UNACTIVATED_LICENSE: 'UNUSED_LICENSE.UNACTIVATED_LICENSE',
    UNASSIGNED_LICENSE: 'UNUSED_LICENSE.UNASSIGNED_LICENSE',
    PRODUCT_SERVICE_PLANS: 'UNUSED_LICENSE.PRODUCT_SERVICE_PLANS',
    USER_SERVICE_PLANS: 'UNUSED_LICENSE.USER_SERVICE_PLANS',
  },
};
