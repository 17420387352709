/* eslint-disable @typescript-eslint/no-explicit-any */

// Primeng
import { FilterMetadata, SortEvent, TableState } from 'primeng/api';
import { TableFilterEvent, TableLazyLoadEvent, TableRowCollapseEvent, TableRowSelectEvent, TableRowUnSelectEvent } from 'primeng/table';
// Caloudi
import { SortOrder } from '@core/enum';

export interface PageEvent {
  first: number;
  rows: number;
}

export interface SortingEvent<T = any> extends SortEvent {
  data?: T[];
  order?: SortOrder;
}

export interface RowSelectEvent<T = any> extends TableRowSelectEvent, TableRowUnSelectEvent {
  data?: T;
  index?: number;
  type?: string;
}

export interface LazyLoadingEvent extends TableLazyLoadEvent {
  sortOrder?: SortOrder;
}

export interface FilterEvent<T = any> extends Omit<TableFilterEvent, 'filters'> {
  filters?: TableFilterMeta;
  filteredValue?: T[];
}

export interface RowExpandEvent<T = any> extends Omit<TableRowCollapseEvent, 'data'> {
  data: T;
}

export interface TableStateEvent extends TableState {
  sortOrder?: SortOrder;
  filters?: TableFilterMeta;
}

interface TableFilterMeta {
  [x: string]: FilterMetaData | FilterMetaData[];
}

export interface FilterMetaData extends FilterMetadata {
  value?: string;
}
