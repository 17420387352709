import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    ANOMALY: {
      ADD_NOTIFICATION: '新增異常通知',
      ANOMALY: '異常狀態',
      ANOMALY_DETECTION_MODEL: '異常偵測模型',
      ANOMALY_DETECTION: '異常偵測',
      ANOMALY_NOTIFICATION_SETTINGS: '異常通知設定',
      ANOMALY_POINTS_NOTICE: '*僅顯示最近30天內的異常點詳情',
      ANOMALY_POINTS: '異常點',
      AVAILABLE_CHARTS: '可用圖表',
      COST_DEVIATION_GREATER_THAN: '費用偏差大於',
      NOTIFICATION_LANGUAGE: '通知語言',
      NOTIFICATION_RECIPIENTS: '通知接收者',
      PRIMARY_INSTANCE_CAUSES: '異常資源主要原因',
      PRIMARY_LABEL_CAUSES: '異常標籤主要原因',
      PRIMARY_PRODUCT_CAUSES: '異常產品主要原因',
      PRIMARY_SERVICE_CAUSES: '異常服務主要原因',
      PRIMARY_SKU_CAUSES: '異常 SKU 主要原因',
    },
    ENTITY: {
      ACQUIRE: '獲取',
      APP_INSIGHT: '應用程式監控',
      AUDIT_TYPE: '稽核類型',
      BALANCE_SUMMARY: '餘額摘要',
      CATALOG: '目錄',
      CATEGORY: '服務類別',
      CHART: '圖表',
      CONSUMED_SERVICE: '已消費服務',
      DEPARTMENT: '部門',
      DEPENDENCY: '依賴項目',
      DOMAIN: '網域',
      ENROLLMENT: 'enrollment',
      FLEXIBILITY_GROUP: '彈性群組',
      INSTANCE: '實例',
      INSTANCE_NAME: '實例名稱',
      INSTANCE_TYPE: '實例類型',
      INSTANCE_ID: '實例編號',
      OPERATION: '作業',
      OS: '作業系統',
      PRODUCT: '產品',
      PROJECT: '專案',
      RESOURCE_GROUP: '資源群組',
      RESOURCE_LABELS: '資源標籤',
      RESOURCE_TAG_KEY: '標籤鍵',
      RESOURCE_TAG_VALUE: '標籤值',
      RESOURCE_TAG: '資源標籤',
      RESOURCE_TYPE: '資源類型',
      RESOURCE_VALUE: '資源值',
      RESOURCE: '資源',
      SERVICE: '服務',
      SERVICE_CATEGORY: '服務類別',
      SETTING: '設定',
      SKU: 'SKU',
      SQL: 'SQL',
      SUBSCRIPTION: '訂閱',
      SUBSCRIPTION_ID: '訂閱編號',
      TENANT_DOMAIN: '租戶網域',
      TENANT: '租戶',
      TENANT_GUID: '租戶識別碼',
      VIRTUAL_MACHINE: '虛擬機',
      VM_FLEXIBILITY_GROUP: 'VM 彈性群組',
      VM_RECOMMENDATION: 'VM 建議',
      VM: '虛擬機',
    },
    BUDGET: {
      BUDGET_ALERT: '預算警示',
      BUDGET_ID: '預算編號',
      BUDGET_NAME: '預算名稱',
      BUDGET_PERCENT: '預算百分比',
      BUDGET_SUGGESTION: '預算建議',
      BUDGET_YEAR: '預算年度',
      BUDGET: '預算',
      EXPIRATION_DATE: '預算警示終止日期',
      OVERALL_COST_ACCOUNT: '帳戶總成本',
      OVERALL_COST_DEPARTMENT: '部門總成本',
      OVERALL_COST_PRODUCT: '產品與訂閱總成本',
      OVERALL_COST_REGION: '區域與訂閱總成本',
      OVERALL_COST_RESOURCE_GROUP: '資源群組與訂閱總成本',
      OVERALL_COST_SERVICE: '服務與訂閱總成本',
      OVERALL_COST_SUBSCRIPTION: '訂閱總成本',
      OVERALL_COST_TAG: '標籤與訂閱總成本',
      OVERALL_COST_THE_LABEL: '所選帳戶標籤總成本',
      OVERALL_COST_THE_PROJECT: '專案在所選帳戶的總成本',
      OVERALL_COST_THE_REGION: '區域在所選帳戶的總成本',
      OVERALL_COST_THE_SERVICE: '服務在所選帳戶的總成本',
      OVERALL_COST_THE_SKU: 'SKU 在所選帳戶的總成本',
      OVERALL_COST: '總成本',
      THRESHOLD_1: '第一預算門檻',
      THRESHOLD_2: '第二預算門檻',
      THRESHOLD_3: '第三預算門檻',
      TOTAL_BUDGET_OVERRAGE_ALERT: '預算超額警示',
      TOTAL_BUDGET: '預算總額',
    },
    CHART: {
      BAR_CHART: '長條圖',
      LINE_CHART: '折線圖',
      LINECHART_INFO: '拖曳時間軸進行放大，點擊空白處還原。',
      OTHER_CHART: '其他圖表',
      PIE_CHART_3D: '3D 圓餅圖',
      PIE_CHART: '圓餅圖',
      STACK_CHART: '堆疊長條圖',
      STACKED: '堆疊',
      SWITCH_BAR: '切換長條圖類型',
      SWITCH_PIE: '切換圓餅圖類型',
    },
    COST: {
      ALL_METERED_COST: '所有計量成本',
      BALANCE: '餘額',
      BEGINING_BALANCE: '期初餘額',
      COST: '成本',
      COST_30D: '最近 30 天成本',
      COST_30D_SUMMARY: '最近 30 天成本總覽',
      COST_30D_TOTAL: '最近 30 天總成本',
      COST_DETAILS: '成本詳情',
      COST_DEVIATION: '成本偏差',
      COST_OF_CURRENT_MONTH: '本月成本',
      COST_OF_LAST_MONTH: '上月成本',
      COST_QUERY_YEAR: '成本查詢年度',
      COST_SUMMARY: '成本總覽',
      COST_TYPE: '成本類型',
      CURRENT_USAGE: '目前使用量',
      CUSTOMER_CHARGES: '客戶費用',
      DAILY_COST: '每日成本',
      LAST_30_DAYES_DAILY_COST: '最近 30 天每日成本',
      LIST_PRICE_TOTAL: '標價總額',
      MARGIN: '毛利',
      METERED_COST: '計量成本',
      MONTHLY_COST: '每月成本',
      NEW_COST: '新成本',
      ORIGINAL_CHARGES: '原始費用',
      PARTNER_CHARGES: '合作夥伴費用',
      PRETAX_SUBTOTAL: '税前小計',
      REMAINING_BALANCE: '剩餘餘額',
      SUBTOTAL: '小計',
      TAX: '稅金',
      TOTAL: '總額',
      TOTAL_AMOUNT: '總數量',
      TOTAL_CHARGES: '總費用',
      TOTAL_COST: '總成本',
      TOTAL_COVERAGE_RATE: '總覆蓋率',
      TOTAL_QTY: '總數量',
      TOTAL_REVENUE: '總收入',
      TOTAL_TAX: '稅金總額',
      WEEKLY_COST: '每週成本',
    },
    COMMON: {
      A: '',
      BY: '',
      FROM: '從',
      YES: '是',
      NO: '否',
      NOT: '不',
      OF: '/',
      OR: '或',
      TO: '到',
    },
    CURRENCY: {
      CURRENCY: '貨幣',
      NTD: '新台幣',
      USD: '美元',
      TWD: '新台幣',
    },
    LOG: {
      ACTIVITY_LOG: '活動紀錄',
      ACTIVITY_LOGS: '活動紀錄',
      LOG_DATE: '紀錄日期',
      LOG_PERIOD: '紀錄週期',
      LOG_QUERY: '紀錄查詢',
      LOG_TYPE: '紀錄類型',
    },
    INVOICE: {
      ACCOUNT_PROFILE: '帳戶資料',
      BILLING_DETAILS: '帳單詳情',
      BILLING_INFO: '帳單資訊',
      BILLING_PROFILE: '帳單資料',
      BILLING_SUMMARY: '帳單摘要',
      BILL_TO: '帳單寄送至',
      COMPANY_NAME: '公司名稱',
      COMPANY_PROFILE: '公司資料',
      CUSTOMERS: '客戶',
      CUSTOMER_PROFILE: '客戶資料',
      DATE: '帳單日期',
      DATE_END: '結束日期',
      DATE_START: '開始日期',
      DATE_UTC: 'UTC 帳單日期',
      DETAILS: '發票詳情',
      DOCUMENT_TYPE: '憑證類型',
      ID: '帳單 ID',
      INFO: '發票資訊',
      INVOICE: '發票',
      LICENSE: '授權',
      LICENSES: '授權',
      LICENSE_LINEITEMS: '授權項目',
      LINEITEM: '項目',
      LINEITEMS: '項目',
      LINEITEM_DETAILS: '項目詳情',
      LUMPSUM: '總額',
      NET_N_DAYS: '淨 {{ N }} 天',
      NUMBER: '帳單號碼',
      OFFERS: '供應項目',
      ONETIME: '一次性',
      ONETIME_LINEITEMS: '一次性項目',
      ORDER_ID: '訂單號碼',
      ORDER_INFO: '訂單資訊',
      PAID_AMOUNT: '已付金額',
      PAYMENT_STATUS: '付款狀態',
      PAYMENT_TERMS: '付款條款',
      PRODUCTS: '產品',
      PRODUCT_CATS: '產品類別',
      PRODUCT_INFO: '產品資訊',
      PURCHASE_INFO: '購買資訊',
      QUANTITY_AMOUNT: '數量與金額',
      RECON: '對帳',
      RECURRING: '週期性',
      SKUS: 'SKU',
      SOLD_TO: '售予',
      SUMMARY: '總覽',
      TOTAL_AMOUNT: '總金額',
      TYPE: '帳單類型',
    },
    REPORT: {
      EMAIL_LIST: '電子郵件列表',
      REPORT: '報表',
      NOTE: '報告註記',
      TITLE: '報告標題',
      UNTITLED: '無標題',
    },
    RESELLER_QUERY: {
      INCLUDE_PRIVILEGES_DESCRIPTION: '包含 Azure AD 與 Office 365 的系統管理權限',
      REQUEST_RELATIONSHIP_DESCRIPTION:
        '若要建立經銷商關係，請將以下內容（包含網址）複製並寄送至客戶電子郵件中。',
      SEND_EMAIL_DESCRIPTION: '您可編輯訊息內容，並透過電子郵件發送給您的客戶。',
    },
    RI: {
      CURRENT_RI_COVERAGE_RATE: '當前 RI 覆蓋率',
      NEW_RI_COVERAGE_RATE: '新 RI 覆蓋率',
      NO_RI_COST: '無 RI 成本',
      RECOMMENDED_COST: '建議費用',
      RI_ANALYSIS_DETAIL: 'RI 建議詳情分析',
      RI_COST_ALLOCATION: 'RI 成本分配',
      RI_COST_WITH_NO_RI: '無 RI 成本',
      RI_COST_WITH_RI: '有 RI 成本',
      RI_COST: 'RI 成本',
      RI_COVERAGE_ANALYSIS: 'RI 覆蓋率分析',
      RI_COVERAGE_ANALYSIS_NOTE: 'SKU 建議基於最近 30 天的使用情況（自 {{ startDate }} 起）。',
      RI_COVERAGE_ANALYSIS_WITH_ISF_NOTE: 'SKU 建議基於最近 30 天的使用情況及相同彈性群組（自 {{ startDate }} 起）。',
      RI_COVERAGE_RATE: 'RI 覆蓋率',
      RI_COVERAGE_AND_UTILIZATION_RATE: 'RI 覆蓋率 / 使用率',
      RI_ONE_YEAR: 'RI 一年期',
      RI_ORDER: 'RI 訂單',
      RI_PRICE_SHEET: 'RI 價格表',
      RI_RECOMMEND_QUANTITY: 'RI 建議數量',
      RI_RECOMMENDATION: 'RI 建議',
      RI_THREE_YEAR: 'RI 三年期',
      RI_TRANSFER_ESTIMATION: 'RI 轉移估算',
      RI_UNUSED_COST: 'RI 未使用成本',
      RI_USAGE_DETAIL: 'RI 使用詳情',
      RI_USAGE_SUMMARY: 'RI 使用摘要',
      RI_UTILIZATION_RATE: 'RI 使用率',
      RI1Y: 'RI 1年期',
      RI3Y: 'RI 3年期',
      SHOW_ACTIVE_RI_ORDER: '僅顯示有效的 RI 訂單',
      TRANSFER_ESTIMATE: {
        ADDITIONAL_PAYMENT: '額外付款金額',
        EQUIVALENT_HOURS_TO_NEW_RI_ORDER: '剩餘金額轉換新 RI 訂單的等價時數',
        NEW_RI_ORDER_COST: '新 RI 訂單費用',
        NEW_RI_ORDER_RESERVATION_COST: {
          ESTIMATE_INFO: '新 RI 訂單費用（估算）',
          PER_HOUR: '每小時',
          PER_MONTH: '每月',
          PER_YEAR: '每年',
        },
        NO_RI_INFO: '無 RI 資訊',
        NOT_INCLUDE_FEE: 'RI 轉移估算不包含 12% 的提前解約費',
        REMAINING_BALANCE: '剩餘金額',
        REMAINING_HOURS: '剩餘時數',
      },
    },
    DATE: {
      SECONDS: '秒',
      MINUTE: '分鐘',
      MINUTES: '分鐘',
      HOUR: '小時',
      HOURLY: '每小時',
      HOURS: '小時',
      SUN: '週日',
      MON: '週一',
      TUE: '週二',
      WED: '週三',
      THU: '週四',
      FRI: '週五',
      SAT: '週六',
      SUNDAY: '星期日',
      MONDAY: '星期一',
      TUESDAY: '星期二',
      WEDNESDAY: '星期三',
      THURSDAY: '星期四',
      FRIDAY: '星期五',
      SATURDAY: '星期六',
      TODAY: '今天',
      DAY: '天',
      DAYS: '天',
      DAILY: '每日',
      DAY_OF_THE_WEEK: '星期',
      WEEK: '週',
      WEEKLY: '每週',
      WEEKDAY: '平日',
      WEEK_DAY: '平日 (週一至週五)',
      MONTH: '月份',
      MONTHLY: '每月',
      LAST_MONTH: '上個月',
      CURRENT_MONTH: '本月',
      PREVIOUS_MONTH: '前一個月',
      DATE: '日期',
      DATES: '日期',
      LAST_7_DAYS: '最近 7 天',
      LAST_N_DAYS: '最近 {{ N }} 天',
      LAST: '最近',
      QUARTERLY: '每季',
      YEARLY: '每年',
    },
    ADVISOR_DASHBOARD: {
      ADD_TO_MY_ADVISORY_LIST: '新增至我的建議清單',
      CHECK_ALL_ANOMALY_DETECTIONS: '查看所有異常偵測',
      CHECK_ALL_RIRECOMMENDATIONS: '查看所有 RI 建議',
      CHECK_ALL_RIGHT_SIZINGS: '查看所有型號調整建議',
      CHECK_USAGE_SUMMARY: '查看使用摘要',
      INSIGHTS_OVERVIEW: '洞察概述',
      LOW_RI_COVERAGE_RATE: 'RI 覆蓋率低',
      LOW_RI_UTILIZATION_VM_CPU: 'RI 使用率低 (VM CPU)',
      MAX_CPU_95TH_PERCENTILE: '最大 CPU 95 百分位數',
      MAX_MEMORY_95TH_PERCENTILE: '最大記憶體 95 百分位數',
      MAX_NETWORK_95TH_PERCENTILE: '最大網路使用率 95 百分位數',
      MY_ADVISORY_LIST: '我的建議清單',
      NO_ABNORMAL_INFO: '無異常偵測',
      NO_RI_INFO: '無 RI 建議',
      NO_RIGHT_SIZING_INFO: '無型號調整建議',
      RI_ESTIMATED_BY_RECOMMENDED_SKU: '依建議 SKU 預估',
      RI_ESTIMATED_BY_FLEXIBILITY_GROUP: '依 {{ skuName }} 彈性群組預估',
      RI_RECOMMEND_QUANTITY_NOTE: '建議數量基於建議 SKU 預估',
    },
    ADVISORY: {
      AVAILABILITY_ADVISORY: '可用性建議',
      CATEGORY: '類別',
      HIGH_IMPACT: '高影響',
      IMPACT: '影響',
      IMPACTED_RESOURCES: '受影響資源',
      LOW_IMPACT: '低影響',
      MEDIUM_IMPACT: '中影響',
      OPERATION_ADVISORY: '操作建議',
      PERFORMANCE_ADVISORY: '效能建議',
      RECOMMENDATIONS: '建議',
      SECURITY_ADVISORY: '安全性建議',
      STATE: '狀態',
      TOTAL_RECOMMENDATIONS: '建議總數',
    },
    SEARCH: {
      ALERT_RECIPIENTS: '輸入通知接收者的電子郵件',
      CHARACTER_TWO: '輸入至少兩個字元',
      COMPANY: '輸入公司名稱',
      DOMAIN: '輸入網域名稱',
      KEYWORDS: '輸入關鍵字',
      OPERATION_NAME: '輸入作業名稱',
      PART_NUMBER: '輸入品號',
      PRODUCT: '輸入產品名稱',
      PRODUCT_PART_NUMBER: '輸入產品名稱或品號',
      RESOURCE: '輸入資源關鍵字',
      RESOURCE_FULL: '輸入完整資源名稱',
      RESOURCE_GROUP: '輸入資源群組',
      RESOURCE_TAG: '輸入資源標籤 "Key=Value"',
      RESOURCE_TAG_VALUE: '輸入標籤值',
      RESULT_CODE: '輸入結果代碼',
      USER_ID: '輸入使用者識別碼',
    },
    LOGIN: {
      LOGIN_IN: '登入',
      LOGIN_FAILED: '登入失敗',
      PASSWORD: '密碼',
      NEW_PASSWORD: '新密碼',
      CONFIRM_PASSWORD: '確認密碼',
      MFA_SIGN_IN: '使用 MFA 登入',
      SSO: '第三方登入',
      SIGNIN: '使用 {{ authority }} 登入',
      TITLE: '登入',
      USERID: '使用者帳號/電子郵件',
      VALIDATOR: {
        CLASSES: '密碼必須至少包含 {{ length }} 種組合：小寫字母、大寫字母、數字、特殊符號',
        LENGTH: '密碼長度至少為 {{ length }} 位字元',
        MATCH_EXIST_PASSWORD: '密碼不可與現有密碼相同',
        PASSWD_MISMATCH: '密碼不一致',
        USERID: '密碼不可與帳號相同',
      },
      MESSAGE: {
        PASSWD_REQUIRED: '密碼為必填',
        ID_REQUIRED: '帳號或電子郵件為必填',
        EMAIL_FORMAT: '電子郵件格式錯誤',
      },
      MFA: {
        STEP_1: '下載驗證器應用程式',
        STEP_2: '掃描以下 QR 碼',
        STEP_3: '輸入產生的驗證碼',
        APPS: '[ Microsoft Authenticator, Google Authenticator, Duo, Authy 等 ]',
        ENTER: '輸入由驗證器產生的 6 位數字碼',
        SETUP_KEY: '手動設定碼',
        REVEAL_KEY: '顯示手動設定碼',
      },
    },
    THEME: {
      COLORMODE: {
        DARK: '深色模式',
        LIGHT: '亮色模式',
      },
      MODE: {
        STATIC: '靜態',
        OVERLAY: '重疊',
        SLIM: '輕量',
        HORIZONTAL: '水平',
      },
      SIZE: {
        COMPACT: '緊密',
        MATERIAL: '質感',
      },
      LABEL: {
        MENU: '選單',
        SIZE: '尺寸',
        THEMES: '主題',
        MENU_MODES: '選單模式',
        MENU_COLORS: '選單顏色',
        MENU_SIZES: '選單尺寸',
      },
    },
    PAGE: {
      EMPTY: '無資料',
      NONE: '無',
      NO_RECORDS_FOUND: '查無資料',
      DATA_QUERY_EMPTY: '查無資料',
      DATA_QUERY: '資料查詢',
      SHOW_DATA_FOR_LAST: '顯示最近的資料',
      SIGN_OUT: '登出',
    },
    SECURITY: {
      ACCESS_PERMISSIONS: '存取權限',
      ACCOUNT_IS_LOCKED: '帳號已鎖定',
      ACCOUNT_LOCKOUT_DURATION: '帳號鎖定時間',
      ACCOUNT_LOCKOUT_POLICY: '帳號鎖定政策',
      ACCOUNT_LOCKOUT_THRESHOLD: '帳號登入失敗門檻',
      ENFORCE_MFA: '強制啟用多重驗證 (MFA)',
      ENFORCE_SINGLE_SIGN_ON: '強制使用單一登入 (SSO)',
      LOGIN_FAIL_RETENTION_DURATION: '限時內登入失敗鎖定',
      MFA_RESET_NOTE: '確定要重置使用者的多重驗證？',
      MIN_PASSWORD_LENGTH: '最小密碼長度',
      NO_ACCESS_PERMISSION_TO_TEXT: '您的帳戶無權存取該資源',
      NO_PERMISSION: '無存取權限',
      PASSWORD_CAN_BE_MATCH_USER_ID: '密碼可與帳號相同',
      PASSWORD_HAS_BEEN_RESET: '密碼已重置',
      PASSWORD_NOTE: '請將使用者臨時密碼複製保存，離開此頁面後將無法再查看臨時密碼。',
      PASSWORD_POLICY: '密碼政策',
      PASSWORD_RESET_NOTE: '系統將產生新密碼，現有密碼將無效。',
      PWD_CHANGE_AND_LOGIN: '變更密碼並登入',
      SECURITY_POLICY_SETTINGS: '安全政策設定',
      UNLOCK_ACCOUNT_NOTE_SUCCESSFULLY: '已成功解鎖帳戶「{{ userAccount }}」',
      UNLOCK_ACCOUNT_NOTE: '確定要解鎖帳戶「{{ userAccount }}」嗎？',
      UNLOCK_ACCOUNT: '解鎖帳戶',
      USAGE_ACCESS_CONTROL_ASSIGNMENTS: '存取限制指派',
      USAGE_ACCESS_CONTROL_LIST: '存取限制清單',
      POLICY_INFO: {
        ACCOUNT_LOCKOUT_DURATION: '帳號鎖定時間（分鐘）',
        ACCOUNT_LOCKOUT_THRESHOLD: '帳號登入失敗門檻',
        LOGIN_FAIL_RETENTION_DURATION: '限時內登入失敗次數超過門檻時，將鎖定帳號',
        MATCH_EXIST_PASSWORD: '新密碼不可與現有密碼相同',
        MFA_INFO: '啟用多重驗證',
        MIN_PASSWORD_LENGTH: '密碼至少 {{ Length }} 字元',
        NUMBER_OF_CHARACTER_CLASSES_MAX: '最多支援 2 個類別',
        NUMBER_OF_CHARACTER_CLASSES_MIN: '密碼需至少包含 2 個類別',
        NUMBER_OF_CHARACTER_CLASSES: '密碼複雜度需包含4個類別中的 {{ Length }} 個，如 [A-Z]、[a-z]、[0-9]、[@#!?]',
        PASSWORD_CAN_BE_MATCH_USER_ID: '密碼{{ Not }}可與帳號相同（不區分大小寫）',
        ORGANIZATION_POLICY_INFO: '已設置組織管理安全政策，公司政策不可再設置',
        OVERWRITE_ENROLLMENT_POLICY_NOTE: '覆寫註冊的安全政策設定',
      },
    },
    POWERBI: {
      BALANCE_SUMMARY: '餘額摘要',
      COST_BREAKDOWN: '費用分析',
      COST_FORECAST: '費用預測',
      DAILY_USAGE_DETAIL: '每日使用明細',
      DAILY_USAGE_ANALYSIS: '每日使用分析',
      MONTHLY_USAGE_DETAIL: '每月使用明細',
      MONTHLY_USAGE_ANALYSIS: '每月使用分析',
      METERED_BILLING_ANALYSIS: '計量計費分析',
      OVERALL_COST_ANALYSIS: '總體費用分析',
      OVERALL_DATA_INFO: '資料基於最近 30 天',
      ONBOARD_ANALYSIS: '上線分析',
      ONBOARD_CONTACT: '上線聯絡資訊',
      ONBOARD_DETAIL: '上線細節',
      PRODUCT_COST_ANALYSIS: '產品費用分析',
      RESOURCE_COST_ANALYSIS: '資源費用分析',
      RI_ORDER: 'RI 訂單',
      RI_OVERALL: 'RI 總覽',
      RI_USAGE_ANALYSIS: 'RI 使用分析',
      SERVICE_COST_ANALYSIS: '服務費用分析',
      SAAS_METERING: 'SaaS 計量',
      SAAS_METERING_ANALYSIS: 'SaaS 計量分析',
      SAAS_SUBSCRIPTION_ANALYSIS: 'SaaS 訂閱分析',
      SAAS_USAGE: 'SaaS 使用量',
      SAAS_USAGE_DETAIL: 'SaaS 使用量明細',
    },
    DATA_TABLE: {
      NO_DATA: '無可用資料',
      LOADING: '載入中',
      LESS_THAN_10: '共 {{ totalRecords }} 項',
      ONE_RECORD: '共 1 項',
      PAGINATOR: '顯示第 {{ first }} 至 {{ last }} 項結果，共 {{ totalRecords }} 項',
    },
    USAGE_BUDGET_INFO: {
      BEYOND_DATE_ALERT: '超出指定日期後仍有計費時發出警示',
      EXPIRATION_DATE: '預算停止評估並不再發送警示的日期',
      MONTHLY_BUDGET_OVERAGE_ALERT: '當月計費超出當月預算時發出預警',
      THRESHOLD_1: '預算超出指定百分比時發出第一次預警',
      THRESHOLD_2: '預算超出指定百分比時發出第二次預警',
      THRESHOLD_3: '預算超出指定百分比時發出第三次預警',
      TOTAL_BUDGET_OVERAGE_ALERT: '當計費超出總預算時發出預警',
    },
    CUSTOMER_PROFILE: {
      ADDRESS_LINE_1: '地址第一行',
      ADDRESS_LINE_2: '地址第二行',
      AGREEMENT_DATE: '合約日期',
      ALLOW_CUSTOMER_TO_PURCHASE: '允許客戶購買',
      BILL_TO_INFO: '帳單寄送資訊',
      COMPANY_INFO: '公司資訊',
      CREDIT_AMOUNT: '信用額度',
      CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER: '合作夥伴已確認客戶接受',
      CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT: '客戶透過 Microsoft 接受',
      CUSTOMER_ACCOUNT_INFO: '客戶帳戶資訊',
      CUSTOMER_AGREEMENT_INFO: '客戶合約資訊',
      CUSTOMER_EMAIL: '客戶電子郵件',
      CUSTOMER_PERMISSIONS: '客戶權限',
      LINK_CORRECTING_EDUCATION_INFO: '了解更多關於矯正教育的資訊',
      LINK_CUSTOMER_VALIDATION_PROCESS: '了解教育客戶驗證程序',
      LINK_HOW_CONFIRM_AGREEMENT: '如何確認客戶已接受 Microsoft 客戶合約',
      LINK_HOW_DISPUTE_DECISION: '如何對此決定提出異議？',
      MANAGEMENT_INFO: '管理資訊',
      NOT_PROVIDED: '未提供',
      POSTAL_CODE: '郵遞區號',
      RESERVATIONS: '預訂',
      SALES_AGENT: '銷售代理',
      SPECIAL_QUALIFICATIONS: '特殊資格',
      UPDATE_QUALIFICATION: '更新資格',
      COUNTRY_TOOLTIP: '您只能銷售給您市場中的客戶。若要在多個市場銷售，您的組織必須向 Microsoft 註冊多個帳號。',
      EMAIL_TOOLTIP: '客戶的電子郵件網域將用於 OneVet EDU 資格追蹤。',
      PROVIDED: '已提供',
      UPDATE_AGREEMENT_INFO: '更新合約資訊',
      COPY_AGREEMENT_URL: '複製合約網址',
      HINT_CANCEL_CONFIRM_HEADER: '確定要取消？',
      HINT_CANCEL_CONFIRM_MESSAGE: '您尚未建立客戶資料，確定要取消嗎？',
    },
    USER_PROFILE: {
      HAS_BEEN_UPDATED: '使用者資料已更新',
      LANGUAGE: '語言',
      LAST_LOGIN_INFO: '上次登入資訊',
      SUMMARY: '使用者資料摘要',
      TIME_ZONE: '時區',
      TITLE: '使用者資料',
      USER_ID: '使用者識別碼',
      USER_NAME: '使用者名稱',
      PASSWD: {
        CHANGE_PASSWD: '變更密碼',
        CONFIRM_NEW_PASSWD: '確認新密碼',
        CONFIRM_PASSWD: '確認密碼',
        CONFIRM_REQUIRED: '需要確認新密碼',
        CURRENT_PASSWD: '目前密碼',
        CURRENT_REQUIRED: '需要目前密碼',
        ENTER_CURRENT_PASSWD: '輸入目前密碼',
        NEW_PASSWD: '新密碼',
        NEW_REQUIRED: '需要新密碼',
        NEW_SAME_AS_CURRENT: '新密碼不可與現有密碼相同',
        PASSWD_MISMATCH: '新密碼與確認密碼不相符',
      }
    },
    USAGE_COMMON_FILTER: {
      ACCESS_CONTROL_RESTRICTIONS: '費用存取控制限制',
      HIERARCHY: '費用階層結構圖',
      IMAGE_LINK: 'assets/images/usage_filter/usage_filter_hierarchy_zh-hant.png',
      IMAGE_LINK_HACKER: 'assets/images/usage_filter/usage_filter_hierarchy_hacker_zh-hant.png',
      QUERY_PRODUCT: '產品查詢結果將依據您目前選擇的部門、帳戶、訂閱及服務篩選',
      QUERY_RESOURCE_GROUP: '資源群組查詢結果將依據您目前選擇的部門、帳戶及訂閱篩選',
      QUERY_TAG: '標籤查詢結果將依據您目前選擇的部門、帳戶、訂閱及資源群組篩選',
      RESTRICTIONS_HEADER: '您的管理員為您設置了以下限制',
      UNRESTRICTED: '未受限',
    },
    EA_OB_PAGE: {
      AUTHORIZATION_CONFIG: '授權設定',
      CLOUD_TENANT_ADMIN_AUTHORIZATION: '雲端租戶管理者授權',
      EA_ENROLLMENT_NUM: '企業註冊編號',
      EA_NAME: '企業名稱',
      INIT_SYNC_TIME: '開始同步時間',
      LAST_RM_SYNC_TIME: '上次資源資料同步時間',
      NOTICE: '您可以離開此頁面並隨時返回檢查同步狀態。',
      OB_INVITATION: '上線邀請',
      OB_PROGRESS: '上線進度',
      OVERALL_OB_PROGRESS: '總體上線進度',
      PRICE_SHEET_IMPORT_PROGRESS: '價格表同步進度',
      USAGE_IMPORT_PROGRESS: '使用資料同步進度',
      VALID_SUBSCRIPTION: '有效訂閱',
    },
    ERROR_HANDLER: {
      HTTP_ERROR_SUMMARY: '資料存取錯誤',
    },
    FORECAST: {
      FORECAST_MODEL: '預測模型',
      FORECAST: '預測',
      ACTUAL_COST: '實際費用',
      DIFF_COST: '差異',
      FORECAST_COST: '預測費用',
      PREDICT_COST: '預估費用',
    },
    FEATURE_PACK: {
      ASSIGN: '指派功能組',
      DETAIL: '功能組明細',
      EDIT: '編輯功能組',
      LIST: '功能組清單',
      NEW_FEATURE_PACK_NAME: '新功能組名稱',
      SELECTED_LIST: '選擇清單',
      ENROLLMENT_FEATURE: {
        LIST: '企業功能組清單',
        DETAIL: '企業功能組詳細資訊',
        ACCESS: '企業功能組存取',
        ASSIGNED_LIST: '指派的功能組清單',
      }
    },
    USER_GROUP: {
      NO_ASSIGNED_CUSTOMERS: '尚未指派客戶',
      NO_ASSIGNED_RESELLERS: '尚未指派經銷商',
      NO_CUSTOMERS: '無客戶',
      NO_RESELLERS: '無經銷商',
      NO_USERS: '無使用者',
      NO_USER_GROUPS: '無使用者群組',
    },
    PRICING_CONFIG: {
      BASELINE_MARKUP_RULE: '基準加價規則',
      BASELINE_MARKUP_RULE_NOTE: '當無折扣及 SKU 規則時適用',
      BY_DISCOUNT_PERCENT_RULES: '依折扣率規則',
      BY_SKU_RULES: '依 SKU 規則',
      CATEGORY: '類別',
      CUSTOMER_DISCOUNT_PERCENT: '客戶折扣%',
      CUSTOMER_LIST: '客戶清單',
      DISTRIBUTOR_DISCOUNT_PERCENT: '分銷商折扣%',
      MARKUP: '加成',
      MARKUP_OF_COST_PRICE: '成本加成',
      NO_DISCOUNT_PERCENT_RULES: '無折扣率規則',
      NO_SKU_RULES: '無 SKU 規則',
      PARTNER_DISCOUNT_PERCENT: '合作夥伴折扣%',
      PARTNER_EARNED_CREDIT_SHARING_PERCENTAGE_FOR_RESELLER: '合作夥伴賺取的折扣分享比例',
      PEC_OF_LIST_PRICE: '% 標價',
      PEC_PECENT_FOR_RESELLER: '經銷商 PEC%',
      PERCENTAGE_OF_COST_PRICE: '成本價格折扣%',
      PERCENTAGE_OF_LIST_PRICE: '標價折扣%',
      PRICING_CONFIG_NAME: '定價配置名稱',
      RESELLER_DISCOUNT_PERCENT: '經銷商折扣%',
      RESELLER_LIST: '經銷商清單',
      SELECT_A_DISCOUNT_PERCENT: '選擇折扣率',
    },
    SALES_ORDERS: {
      CREATE_INVOICE: '建立發票',
      CUSTOMER_VIEW: '客戶視角',
      EDIT_LINE_ITEM: '編輯項目',
      ON_DEMAND_INVOICE: '按需發票',
      INVOICE_CREATED: '發票已建立',
      LINE_ITEM: {
        NEW: '新增項目',
        ORDERED: '訂單已完成',
        ORDER_FAILED: '訂單失敗',
        VOID: '作廢',
      },
      ORDER: {
        NEW: '新訂單',
        APPROVED: '已核准',
        PARTIAL_ORDERED: '部分訂單已完成',
        VOID: '訂單作廢',
      },
      ORDER_LINE_ITEMS: '訂單項目',
      PARTNER_VIEW: '合作夥伴視角',
      PLACE_ORDER: '下訂單',
      PLACING_ORDER: '下訂中',
      RESELLER_VIEW: '經銷商視角',
      SALES_ORDER_DETAIL: '銷售訂單明細',
      VIEW_INVOICE: '查看發票',
    },
    SAAS_SUBSCRIPTION: {
      AUTO_RENEW: '自動續約',
      BENEFICIARY_EMAIL: '受益人電子郵件',
      CHANGE_QTY: '更改數量',
      IS_FREE_TRIAL: '試用版',
      IS_TEST: '測試版',
      ONBOARDING: '上線中',
      ONBOARD_PROCESS: '上線進度',
      PURCHASE_EMAIL: '購買者電子郵件',
      SELECT_A_PLAN: '選擇方案',
      SUBSCRIPTION_STATUS: '訂閱狀態',
    },
    CONTACT: {
      ADMIN: '行政',
      BILLING: '帳務',
      TECHNICAL: '技術',
    },
    PREREQUISITES: {
      ADMIN_USERS_ASSIGNED: '管理者已指派',
      BILLING_PROFILE_PROVIDED: '已提供帳單資訊',
      COMPANY_PROFILE_PROVIDED: '已提供公司資訊',
      INDIRECT_RESELLER_ACTIVE: '間接經銷商狀態',
      MANAGEMENT_GROUP_ASSIGNED: '管理群組已指派',
      MICROSOFT_PARTNER_AGREEMENT_SIGNED: '已簽署 Microsoft 合作夥伴協議',
      PRICING_CONFIG_ASSIGNED: '已指派定價配置',
      RESELLER_AGENT_ASSIGNED: '經銷商管理者已指派',
      TAX_INFO_PROVIDED: '已提供稅務資訊',
    },
    RI_RECOMM_REPORT: {
      FILTERED_BY_FLEXIBILITY: '依彈性群組篩選',
      ONE_Y: '1 年',
      ONE_YEAR_RI: '1 年 RI',
      ONE_YEAR_RI_RECOMM: '1 年 RI 建議',
      ONE_YEAR_RI_YEARLY_SAVING: '1 年 RI 年節省費用',
      ONE_Y_RI_RECOMM: '1 年 RI 建議',
      RI_RECOMMENDATION_ANALYSIS: 'RI 建議分析',
      SKU_REGION_PRICE: 'SKU 區域價格',
      SKU_REGION_PRICE_COMPARISON: 'SKU 區域價格比較',
      THREE_Y: '3 年',
      THREE_YEAR_RI: '3 年 RI',
      THREE_YEAR_RI_RECOMM: '3 年 RI 建議',
      THREE_YEAR_RI_YEARLY_SAVING: '3 年 RI 年節省費用',
      THREE_Y_RI_RECOMM: '3 年 RI 建議',
      TOTAL_CURRENT_COST: '當前總成本',
      TOTAL_NEW_COST: '新總成本',
      TOTAL_RI_PRICE: 'RI 總價格',
      TOTAL_SAVING_YEARLY: '年總節省費用',
      YEARLY_SAVINGS_RANGE: '每年節省範圍',
    },
    CUSTOM_DIMENSION: {
      ADD_DIMENSION_VALUE_MAPPING_RULE: '新增維度對應規則',
      ADD_TOOLTIP: '新增單一維度對應規則',
      BUILD_DIMENSION_HINT: '建立維度資料後，資料處理可能需要幾分鐘到幾小時，請確保您已完成設計。',
      CHANGE_DIMENSION_NAME: '更改維度名稱（適用於所有同名維度）',
      CHANGE_DIMENSION_NAME_WARNING: '不得更改已使用的名稱',
      COST_CATEGORY_KEY_ALL: '將所有成本類別值對應到單一顯示值',
      COST_CATEGORY_KEY_ANY: '將成本類別值複製到顯示值',
      COST_CATEGORY_VALUE_DISABLE_WARNING: '基於效能考量，不支援超過 500 個值的成本類別鍵',
      COST_CATEGORY_VALUE_MAX_WARNING: '此成本類別鍵 {{ tagKey }} 含有 {{ tagValueCount }} 個值',
      DUPLICATED_DIMENSION_NAME_WARNING: '不得新增已使用的名稱',
      EDIT_DIMENSION_AND_DISPLAY_VALUE: '編輯維度與顯示值',
      IMPORT_DIMENSION_VALUE_MAPPING_RULE: '匯入維度對應規則',
      IMPORT_TOOLTIP: '匯入多個維度對應規則',
      LABEL_KEY_ALL: '將所有標籤值對應到單一顯示值',
      LABEL_KEY_ANY: '將標籤值複製到顯示值',
      LABEL_VALUE_DISABLE_WARNING: '基於效能考量，不支援超過 500 個標籤值的標籤類別',
      LABEL_VALUE_MAX_WARNING: '此標籤類別 {{ tagKey }} 含有 {{ tagValueCount }} 個標籤值',
      MAX_DIMENSIONS_HINT: '最多可設置 5 個維度',
      TAG_KEY_ALL: '將所有標籤值對應到單一顯示值',
      TAG_KEY_ANY: '將標籤值複製到顯示值',
      TAG_VALUE_DISABLE_WARNING: '基於效能考量，不支援超過 500 個標籤值的標籤類別',
      TAG_VALUE_MAX_WARNING: '此標籤類別 {{ tagKey }} 含有 {{ tagValueCount }} 個標籤值',
    },
    COST_DASHBOARD: {
      CHART_HINT: '1. 每週從星期一開始；2. 資料中的最後一週為當週',
      CLOUD_COST_OVER_MONTHS: '每月雲端成本',
      CLOUD_COST_OVER_WEEKS: '每週雲端成本',
      COST_ANALYSIS_BY_SERVICE_CATEGORY: '依服務類別分析成本',
      CURRENT_MONTH: '本月',
      OF_CURRENT_MONTH: '本月成本',
      SWITCH_LINE_CHART: '切換為折線圖',
      SWITCH_STACKED_BAR_CHART: '切換為堆疊長條圖',
      TOP_10_REGION: '前 10 名區域',
    },
    PROVISION: {
      APPROVAL_STATUS: '核准狀態',
      CONFIGRATION_DETAIL: '配置細節',
      COST_DETAILS: '成本明細',
      COST_RANGE: '成本範圍',
      DESTROY_STATUS: '移除狀態',
      DURATION_TIME: '持續時間',
      ESTIMATED_COST_MONTH: '預估每月成本',
      PLAN_STATUS: '方案狀態',
      PROVISION_ITEM: '佈建項目',
      PROVISION_RESULT: '佈建結果',
      PROVISION_STATUS: '佈建狀態',
      VIEW_DETAILS: '查看詳情',
    },
    VM_PROVISION: {
      ACCOUNT_DETAIL: '帳戶詳細資料',
      ACCOUNT_DETAIL_INFO: '選擇帳戶以管理已部署的資源與成本。',
      ADMINISTRATION_ACCOUNT: '管理帳戶',
      AUTHENTICATION_TYPE: '驗證類型',
      BASIC: '基本',
      CREATE_NEW: '建立新帳戶',
      CUSTOM_DURATION: '自定義持續時間',
      DATE_RANGE_ERROR: '所選日期已過期。',
      DELETE_WITH_VM: '使用 VM 刪除',
      DISK_OPTIONS: '磁碟選項',
      DUPLICATE_NAME: '此名稱已存在。',
      DURATION_INFO: '預設持續時間將從起始時間開始，無預設結束時間。',
      ESTIMATED_COST: '預估成本',
      EXISTING: '現有的',
      FROM_DATE_TIME: '起始時間',
      GCP_VIRTUAL_MACHINE_FORMAT_INFO: '名稱必須由小寫字母 (a-z)、數字與連字符 (-) 組成。',
      IMAGE: '映像檔',
      INSTANCE_DETAILS: '實例詳細資料',
      LINUX_VM_INFO: "Linux VM 名稱只能包含字母、數字、'.' 和 '-'。",
      MANAGED_DISKS: '託管磁碟',
      NETWORK_SERVICE: '網路服務',
      NO_LIMIT: '無限制',
      OS_DISK_DELETE_ON_TERMINATION: '在虛擬機終止時刪除 OS 磁碟',
      OS_DISK_SIZE: 'OS 磁碟容量',
      OS_DISK_TYPE: 'OS 磁碟類型',
      OS_DISK_VOLUME_SIZE_RANGE_INFO: '磁碟容量範圍 {{ minSize }} ~ {{ maxSize }} GiB。',
      OS_IMAGE: 'OS 映像檔',
      PASSWORD_CONFIRM_INFO: '密碼與確認密碼必須相符。',
      PASSWORD_FORMAT_INFO: '密碼必須包含以下項目中的三項：小寫字母、大寫字母、數字及特殊字元。',
      PROJECT_DETAILS: '專案詳細資料',
      PROJECT_INFO: '選擇帳戶來管理已部署的資源與成本。',
      PROVISION_DURATION: '佈建持續時間',
      PUBLIC_IP: '公用 IP',
      REGION_ZONE: '地區/區域',
      RESOURCE_GROUP_FORMAT_INFO: '資源群組名稱只允許使用字母、數字、句點、下劃線、連字符、括號，且不能以句點結尾。',
      RESOURCE_GROUP_NAME: '資源群組名稱',
      SSH_PUBLIC_KEY: 'SSH 公鑰',
      SSH_REGEXP_INFO: "多行 SSH 金鑰必須以 '---- BEGIN SSH2 PUBLIC KEY ----' 開頭，並以 '---- END SSH2 PUBLIC KEY ----' 結尾。",
      STANDARD: '標準',
      SUBSCRIPTION_NO_SKU_INFO: '此容量目前無法用於 {{ region }} 中的此訂閱。',
      TO_DATE_TIME: '結束時間',
      USER_NAME_FORMAT_INFO: '使用者名稱僅能包含字母、數字、連字符和下劃線，且不能以連字符或數字開頭。',
      VALIDATOR_LENGTH_INFO: '此值的長度必須在 {{ minLength }} 和 {{ maxLength }} 字元之間。',
      VIRTUAL_MACHINE_FORMAT_INFO: "Azure 資源名稱不能包含特殊字元（如 \\/\"\"[]:|<>+=;,?*@&）或空白字元，開頭不能為 '_'，結尾不能為 '.' 或 '-'。",
      VIRTUAL_MACHINE_INSTANCE: '虛擬機實例',
      VIRTUAL_MACHINE_NAME: '虛擬機名稱',
      WINDOWS_VM_INFO: "Windows VM 名稱不能包含 '_' 或 '.'。",
    },
    USER_ACCESS: {
      ROLE_ASSIGNMENTS: '角色指派',
      ROOT_SCOPE_ROLE: '使用者的根範圍角色',
      ROOT_SCOPE_ROLE_HINT: '此使用者的根範圍角色為 {{ selectedUserRootRule }}，表格僅顯示其他存取權限。',
      SCOPE_HINT: '範圍階層包括以下四級：管理群組、訂閱、資源群組、資源',
    },
    RESOURCE_SCHEDULE: {
      ATTACH_SCHEDULE_NOTE: '確定要將排程綁定至資源 {{ resource }} 嗎？',
      ATTACH_SCHEDULE: '綁定排程',
      CHANGE_SCHEDULE: '變更排程',
      CREATE_RESOURCE_SCHEDULE: '新建資源排程',
      DELETE_SCHEDULE_NOTE: '確定要刪除選取的排程 "{{ scheduleName }}" 嗎？',
      DETACH_SCHEDULE_NOTE: '確定要解除資源 {{ resource }} 的排程綁定嗎？',
      DETACH_SCHEDULE: '解除排程綁定',
      EDIT_SCHEDULE: '編輯排程',
      EXISTED_NAME_ALERT: '已有同名排程存在',
      RESET_SCHEDULE: '重置排程',
      RESOURCE_SCHEDULE: '資源排程',
      SAVE_AS_NEW_SCHEDULE: '另存新排程',
      SCHEDULE_NAME: '排程名稱',
      SCHEDULE_RESOLUTION: '時間間隔',
      SCHEDULED_TIME: '排程時間',
      UPDATE_SCHEDULE_NOTE: '確定要更新選取的排程嗎？',
    },
    RESOURCE_PLAN: {
      ACTUAL: '實際',
      ACTUAL_COST: '實際成本',
      ADD_THRESHOLD: '新增門檻',
      ALERT_CONDITION: '警示條件',
      ALERT_RECIPIENTS: '警示接收者（電子郵件）',
      AMORTIZED_COST: '攤銷成本',
      AMOUNT: '金額',
      BEYOND_DATE: '超過指定日期後發送警示',
      BUDGET_AMOUNT: '預算金額',
      BUDGET_HISTORY: '預算歷史',
      BUDGET_OWNER: '預算建立者',
      COPY_FROM: '複製現有計劃',
      CREATION_DATE: '建立日期',
      CURRENT_SPEND: '當前花費',
      DATA_CALCULATED_DATE: '資料計算日期',
      EFFECTIVE_DATE: '生效日期',
      EXPIRATION_DATE: '失效日期',
      FORECAST: '預測',
      GIVE_THRESHOLD: '設定預算門檻',
      GO_USAGE_QUERY: '前往使用費查詢',
      HEADER: '名稱',
      HIDE_EXPIRED_PLAN: '隱藏已失效計劃',
      HISTORICAL_FUTURE: '過去與預測花費',
      MY_PLAN_ONLY: '僅顯示我的計劃',
      NEW_PLAN: '新計劃',
      NEW_RESOURCE_PLAN: '新資源計劃',
      OVERAGE_THRESHOLD: '超支警示',
      OWNER: '計劃建立者',
      RESET: '重置',
      RESET_PERIOD: '重置週期',
      RESOURCE_PLAN_LIST: '資源計劃列表',
      SUGGESTED_BUDGET: '建議預算：${{ forecastAmount }}（根據預測）',
      THRESHOLD: '門檻',
      TIME_PERIOD: '時間週期',
      TITLE: '資源計劃',
      TRIGGER_ON: '警示觸發條件',
    },
    DROPCELL: {
      ORPHAN: '無主資源數量',
    },
    ORPHAN: {
      TITLE: '無主資源',
      RESOURCE_PERCENT_IN_COST: '無主資源占總成本的比例',
    },
    XCLOUD: {
      POTENTIAL_WASTE_30D: '30 天潛在優化空間',
      OPERATION_HEALTHINESS_30D: '30 天運營健康度',
    },
    UNUSED_LICENSE: {
      ACTIVATION_DATE: '啟用日期',
      ACTIVITY_DATE: '活動日期',
      ASSIGNED_BY: '指派者',
      ASSIGNED_TIME: '指派時間',
      FIELDS_NOT_SUPPORTED: '本資源不支援以下欄位：',
      INACTIVE_30_DAYS: '30 天未啟動的授權',
      NOT_INSTALLED_LICENSE: '未安裝的授權',
      NUMBER_OF_LICENSE: '授權數量',
      NUMBER_OF_LICENSE_TYPES: '授權類型數量',
      NUMBER_OF_UNUSED_LICENSES: '未使用授權數量',
      POTENTIAL_WASTE_PER_MONTH: '每月潛在浪費',
      PRODUCT_SERVICE_PLANS: '產品服務方案',
      TITLE: '未使用授權',
      TOTAL_UNITS: '總單位數',
      UNASSIGNED_LICENSE: '未指派授權',
      UNUSED_LICENSE_COST_PERCENTAGE: '未使用授權的成本百分比',
      UNUSED_NUMBER: '未使用數量',
      USER_SERVICE_PLANS: '使用者服務方案',
    },
    NVA: {
      AFFECTED_SOFTWARE: '受影響的軟體/作業系統',
      DETECTION_METHOD: '檢測方法',
      DETECTION_RESULT: '檢測結果',
      HIGH_SEVERITY: '高嚴重性',
      IMPACTED_HOSTS: '受影響的主機',
      INSIGHT: '深入解析',
      LOW_SEVERITY: '低嚴重性',
      MEDIUM_SEVERITY: '中等嚴重性',
      REFERENCES: '參考資料',
      SUMMARY: '概要',
      TOTAL_VULNERABILITIES: '總漏洞數量',
      VULNERABILITY: '漏洞',
    },
    CLOUD_SEC: {
      ACTIVATED_SINCE: '自 {{ N }} 起啟動',
      ADD_TARGET: '新增目標',
      AGE: '存續時間',
      ALIAS: '別名',
      ALTERNATIVES: '替代修復步驟',
      ATTACK_COMPLEXITY: '攻擊複雜度',
      ATTACK_VECTOR: '攻擊途徑',
      AVAILABLE_IMPACT: '可用性影響',
      AVG_SCORE_TOP_5: '前五名漏洞的平均分數',
      CLASSIFICATION: '分類',
      CLICK_TO_UPLOAD: '點擊以上傳檔案。',
      CONFIDENTIALITY_IMPACT: '機密性影響',
      CONTACT_PERSON: '聯絡人',
      CRITICAL: '嚴重',
      CUSTOMER_NAME: '客戶名稱',
      CUSTOMER_NUMBER: '客戶編號',
      CVSS_METRICS: 'CVSS 指標',
      CVSS_SCORE: 'CVSS 分數',
      DELETE_DOMAIN: '此操作無法復原\n您確定要刪除此網域嗎？',
      DELETE_MESSAGE: '此操作無法復原，將刪除與此主機相關的掃描目標。',
      DESCRIPTION: '描述',
      DETAILED_GUIDE: '詳細指南',
      DISTRIBUTION_CVE_YEAR: 'CVE 年度分布',
      DOMAIN_EXIST: '網域已存在',
      ENTER_DOMAIN: '請輸入網域',
      ENTRY_BY: '輸入者',
      ENTRY_DATE: '輸入日期',
      EVIDENCE: '證據',
      EXECUTIVE_SUMMARY: '執行摘要',
      FILE_TYPE: '檔案類型',
      FIRST_DETECTED: '首次偵測',
      FIRST_SCAN_DATE: '第一次掃描日期',
      FIRST_SCAN_N_RESOLUTIONS: '首次掃描發現的 {{ N }} 個漏洞解決方案',
      FIRST_SCAN: '首次掃描',
      FIX_ANALYSIS_REPORT: '修復分析報告',
      FIX_PROCEDURE: '修復步驟',
      FIX_TYPE: '修復類型',
      FIXED_THIS_MONTH: '本月修復',
      FIXED_TIMELINE: '修復時間軸',
      FIXED_VUL_LAST_30D: '過去 30 天修復的漏洞',
      GENERAL_INFO: '一般資訊',
      HEALTH_SCORE: '健康分數',
      HIGH: '高',
      HOST_SEVERITY_DISTRIBUTION: '主機嚴重度分布',
      HOW_TO_CREATE: '如何建立？',
      HOW_TO_FIX: '如何修復',
      IMPORTED_BY: '匯入者',
      INDUSTRY_RANK: '行業排名',
      INTEGRITY_IMPACT: '完整性影響',
      KEY_VUL: '關鍵漏洞',
      LAST_DETECTED: '最後偵測',
      LAST_RESCAN: '最後重新掃描',
      LAST_SCAN_DATE: '最後掃描日期',
      LAST_SCAN: '最後掃描',
      LATEST_VUL: '最新漏洞',
      LOG_RESOLUTION: '解決記錄',
      LOW: '低',
      MANAGE_SUBSCRIPTION: '管理訂閱',
      MEDIUM: '中',
      NEW_THIS_MONTH: '本月新增',
      NEW_VUL_LAST_30D: '過去 30 天新增漏洞',
      NEW: '新',
      NOTE: '備註',
      NUM_OF_VUL: '漏洞數量',
      OPEN_VUL: '未解決漏洞',
      OVERALL_RESOLVED_VUL: '已解決漏洞總覽',
      OWASP_TOP_10: 'OWASP 前 10 名',
      OWNER_ASSIGN: '主機負責人指派',
      PERCENTILE_RANK: '百分位排名',
      PLAN_EXPIRE: '您的計劃將於 {{ N }} 到期',
      PREFER_SCAN_TIME: '偏好掃描時間',
      PRIVILEGE_EVIDENCE: '所需權限',
      PUBLISHED: '發佈時間',
      REFERENCE: '參考資料',
      REMEDIATION_ADVICE: '修復建議',
      REPORT_DATE: '報告生成日期',
      REPORT_DOWNLOAD: '下載報告',
      REPORT_GEN_DATE: '報告生成日期',
      REPORT: '報告',
      RESCAN: '重新掃描',
      RESOLVED: '已解決',
      RISK_INFO: '風險資訊',
      RISK: '風險',
      SCAN_DURATION: '掃描時長',
      SCAN_END_TIME: '掃描結束時間',
      SCAN_FINDINGS: '掃描結果',
      SCAN_REPORTS: '掃描報告',
      SCAN_RESULTS: '掃描結果',
      SCAN_START_TIME: '掃描開始時間',
      SCAN_TARGET: '掃描目標',
      SCAN_TEMPLATE: '掃描範本',
      SCAN_TIME: '掃描時間',
      SCAN_TOOLS: '掃描工具',
      SCANNER: '掃描器',
      SCANS: '掃描',
      SCORE_LAST_30D: '過去 30 天的分數',
      SECOND_SCAN_DATE: '第二次掃描日期',
      SECOND_SCAN_N_NEW: '第二次掃描發現的 {{ N }} 個新漏洞',
      SECOND_SCAN: '第二次掃描',
      SELECT_CRITERIA: '選擇條件',
      SEVERITY_DISTRIBUTION: '嚴重度分布',
      SEVERITY_INDEX: '嚴重性指數',
      SEVERITY: '嚴重度',
      SOLUTION: '解決方案',
      SUBDOMAIN_NOTIFICATION: '此過程至少需要 10 分鐘',
      SUBDOMAIN_TITLE: '主網域及已知子網域',
      SUBDOMAIN: '子網域',
      SUBSCRIPTION_TIME: '訂閱時間',
      TIMELINE: '時間軸',
      TOTAL_FINDING: '總發現',
      TOTAL_HOSTS: '總主機數',
      TOTAL_VUL: '總漏洞數',
      UNRESOLVED: '未解決',
      UPDATED: '更新時間',
      USER_INTERACTION: '使用者互動',
      VPN_PROFILE: 'VPN 設定檔',
      VUL_COUNT: '漏洞數量',
      VUL_INFO: '漏洞資訊',
      VUL_LAST_30D: '過去 30 天漏洞',
      VUL_OVERVIEW: '漏洞概覽',
      VUL_RESOLUTION_REPORT: '漏洞處理報告',
      VUL_TREND: '漏洞趨勢',
      VUL: '漏洞',
      WEBSITE_COUNT: '網站數量',
    },
    BALANCE_SUMMARY: {
      FORECAST_HISTORY: '根據過去三個月的平均花費進行預測',
      FORECAST_RECOMMEND: '預測每月花費建議 ${{ N }}',
      FORECAST_END_BALANCE: '合約到期餘額',
      MEET_BALANCE: '餘額耗盡時間',
      AVG_COST: '平均花費',
      TOTAL_PURCHASE: '總購買量',
    },
    VRM: {
      CREATE_QUESTIONNAIRE: '建立自訂問卷',
      PUBLISHED_SCANS: '已發佈掃描數量',
      QUESTIONNAIRE_TITLE: '問卷庫',
      SCAN_STATUS: '掃描狀態',
      TOTAL_VENDORS: '供應商總數',
      VENDOR_COUNT: '供應商數量',
      MEDIAN_SECURITY_RATING: '安全評級中位數',
      SECURITY_RATING_DISTRIBUTION: '安全評級分布',
      VENDOR_BREAKDOWN: '供應商細目',
      QUESTIONNAIRE: '問卷',
      QUESTIONNAIRES: '問卷',
      QUESTIONNAIRE_AVERAGE_SCORE: '問卷平均分數',
      SECURITY_RATING_TREND: '安全評級趨勢',
      VENDOR_SCORE: '供應商分數',
      SEND_QUESTIONNAIRE: '傳送問卷',
      SELECT_SCOPE: '選擇範圍',
      SELECT_SCHEDULE: '選擇排程',
      TITLE_MESSAGE: '標題與訊息',
      REVIEW_SEND: '審查並送出',
      DUE_DATE: '到期日',
      SELECT_PROGRAM_VENDOR: '選擇計畫與供應商',
      SET_DUE_DATE: '設定到期日',
      ADD_TITLE: '新增標題',
      ADD_MESSAGE: '新增訊息',
      SELECT_SCHEDULE_INFO: '設定問卷完成的時間，並設定提醒以重新發送問卷。',
      SET_DUE_DATE_INFO: '設定問卷的到期日。',
      TITLE_MESSAGE_INFO: '收件人會看到您設定的標題與訊息。',
      ADD_TITLE_INFO: '收件人將看到您設定的標題。',
      ADD_MESSAGE_INFO: '您設定的訊息將顯示於收件人的電子郵件及問卷中。',
      REVIEW_SEND_INFO: '請仔細檢查後再送出。',
    },
    STAT: {
      COMPLETED: '已完成',
      IDLE: '閒置中',
      INCOMPLETE: '未完成',
      IN_PROGRESS: '進行中',
      NOT_START: '尚未開始',
    },
    AUTH_SCAN: {
      AUTOMATIC: '自動',
      AUTH_RESULT: '驗證結果',
      CHECK_AUTH: '檢查驗證',
      CHOOSE: '選擇',
      COOKIE_HEADER: 'Cookie 標頭',
      COOKIES: 'Cookies',
      ENABLE_AUTH: '啟用驗證',
      RECORDED: '已記錄',
      UPLOAD_FILE: '上傳檔案。',
      UPLOADED: '已上傳。',
      START_SCAN: '開始掃描',
    },
    YSL: {
      TASK_NAME: '任務名稱',
      RUNS: '運行次數',
      EACH_SCAN: '每次掃描',
    },
    LLM_AGENT: {
      MESSAGE: {
        TARGET_REQUIRE: '目標為必填項。',
        GOAL_REQUIRE: '目的是必填項。',
      },
      CONNECTING: '連線中',
      CONNECTED: '已連線',
      DISCONNECTED: '已斷線',
    },
    CVE: {
      CVE_LIST: 'CVE 清單',
      CVE_MESSAGE: '這是 YODA 偵測到的漏洞清單，我們使用了多種工具（如網路掃描器、網站掃描器等）偵測到了超過 {{ N }} 個漏洞。',
    },
  },
};
