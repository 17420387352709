/**
 * Specific format validators.
 */
export default class ValidatorUtil {

  /**
   * Check the value is mail format or not.
   * @param value original string
   * @returns is mail or not
   */
  public static mailValidate(value: string): boolean {
    try {
      const exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return value?.length > 0 && new RegExp(exp).test(value);
    } catch (error) { return false; }
  }

  /**
   * Check the value is url format or not.
   * @param value original string
   * @returns is url or not
   */
  public static urlValidate(value: string): boolean {
    try {
      const exp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
      return value?.length > 0 && new RegExp(exp).test(value);
    } catch (error) { return false; }
  }

  /**
   * Check the value is ip format or not.
   * @param value The string
   * @returns is ip or not
   */
  public static ipValidate(value: string): boolean {
    try {
      const exp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return value?.length > 0 && new RegExp(exp).test(value);
    } catch (error) { return false; }
  }
}
