// Angular
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
// Interface
import { PrimeChipColor } from '@base/model';

@Component({
  selector: 'prime-chip',
  templateUrl: './prime-chip.component.html',
  styleUrls: ['./prime-chip.component.sass'],
})
export class PrimeChipComponent implements AfterViewInit {

  @Input('label') public label: string;
  @Input('icon') public icon: string;
  @Input('image') public image: string;
  @Input('removable') public removable: boolean;
  @Input('style') public style: Record<string, boolean | number | string>;
  @Input('styleClass') public styleClass: string;
  @Input('removeIcon') public removeIcon: string;

  @Input('bgColor') public bgColor: string;
  @Input('color') public color: string;
  @Input('chipColor') public chipColor: PrimeChipColor;

  @Output('onRemove') public _onRemove = new EventEmitter<Event>;
  @Output('onImageError') public _onImageError = new EventEmitter<Event>;

  private readonly logActive: boolean = !1;

  constructor(
    private readonly element: ElementRef<HTMLElement>,
  ) { }

  public ngAfterViewInit(): void {
    const el = this.element.nativeElement;

    this.logActive && console.debug('init', [el, this.bgColor]);
    el.querySelector<HTMLDivElement>('.p-chip').style.backgroundColor = this.bgColor || this.chipColor?.background;
    el.querySelector<HTMLDivElement>('.p-chip').style.color = this.color || this.chipColor?.color;
  }

  public onRemove(event: Event): void {
    this._onRemove.emit(event);
  }

  public onImageError(event: Event): void {
    this._onImageError.emit(event);
  }
}
