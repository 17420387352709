// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';

@Injectable({ providedIn: 'root' })
export class CMPDocsService {
  constructor(private readonly http: HttpClient) {}

  // Md content and page of contents
  public getPageMDFile(getMarkdownFileRequest: GetMarkdownFileRequest): Observable<DocsContent> {
    return this.http.post<DocsContent>(
      `${environment.cmpAPIServer.url}/api/cmp/docs/getMDFile/pageMD`,
      getMarkdownFileRequest
    );
  }

  // Table of contents
  public getSingleMDFile(getMarkdownFileRequest: GetMarkdownFileRequest): Observable<string> {
    return this.http.post<string>(
      `${environment.cmpAPIServer.url}/api/cmp/docs/getMDFile/singleMD`,
      getMarkdownFileRequest
    );
  }
}

export interface GetMarkdownFileRequest {
  urlPath: string;
}

export interface DocsContent {
  html: string;
  toc: string;
}
