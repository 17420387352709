import { createReducer, on } from '@ngrx/store';
import { PeriodSelectorAction } from '../actions';

export interface PeriodSelectorState {
  startBillingPeriods: BillingPeriodSelectItem[];
  endBillingPeriods: BillingPeriodSelectItem[];
  selectedStartBillingPeriods: BillingPeriodSelectItem;
  selectedEndBillingPeriods: BillingPeriodSelectItem;
  startUsageDates: BillingDateSelectItem[];
  endUsageDates: BillingDateSelectItem[];
  selectedStartUsageDates: BillingDateSelectItem;
  selectedEndUsageDates: BillingDateSelectItem;
  displayMonthlyFilter: boolean;
  init?: boolean;
}

export const initialPeriodSelectorState: PeriodSelectorState = {
  startBillingPeriods: [],
  endBillingPeriods: [],
  selectedStartBillingPeriods: undefined,
  selectedEndBillingPeriods: undefined,
  startUsageDates: [],
  endUsageDates: [],
  selectedStartUsageDates: undefined,
  selectedEndUsageDates: undefined,
  displayMonthlyFilter: true,
  init: true,
};

export const periodSelectorReducer = createReducer(
  initialPeriodSelectorState,
  on(PeriodSelectorAction, (_state, payload: PeriodSelectorState): PeriodSelectorState => payload)
);

interface BillingPeriodSelectItem {
  billingPeriodId: string;
  billingPeriodLabel: string;
}
interface BillingDateSelectItem {
  usageDate: string;
  usageDateLabel: string;
}
