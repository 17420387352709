// Angular
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
// App Insights
import { ApplicationInsights, ICustomProperties, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
// Rxjs
import { first } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '@Reducers';
import Selectors from '@Selectors';
// ENV
import environment from '@env';
import { AppUtilModule, RouteUtilModule } from '../common';

@Injectable({ providedIn: 'root' })
export class CaloudiAppInsightsService {
  private readonly appInsights: ApplicationInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsight,
      enableAutoRouteTracking: false,
      autoTrackPageVisitTime: true,
    },
  });

  private isLogin: boolean;

  private readonly logActive: boolean = !1 && !environment.production;

  constructor(
    private readonly store: Store<AppState>,
    private readonly logger: NGXLogger,
    private readonly routeUtil: RouteUtilModule,
    private readonly appUtil: AppUtilModule
  ) {
    this.appInsights.loadAppInsights();
    this.store
      .select(Selectors.auth)
      .pipe(first())
      .subscribe(auth => {
        if (!auth.user?.userProfile) return;
        this.isLogin = auth.loggedIn;
        const profile = auth.user.userProfile;
        this.appInsights.setAuthenticatedUserContext(profile.userId, profile.eaEnrollmentNumber, true);
        this.logActive && logger.debug('app insight init:', [
          {
            isLogin: this.isLogin,
            userId: profile.userId,
            eaNum: profile.eaEnrollmentNumber,
          },
          this.routeUtil.getRouteEvent,
          this.routeUtil.routeEvent?.value,
        ]);
      });
  }

  public trackPageView(pageStay: number): void {
    this.routeUtil.onRouteChange.pipe(first()).subscribe(event => {
      this.logActive && this.logger.debug('trackPage:', [event]);
      const payload$: IPageViewTelemetry = {
        name: event.pageTitle,
        isLoggedIn: this.appUtil.isLogin || false,
        uri: event.routeLocation,
        refUri: event.routeUrl,
        properties: {},
      };
      if (typeof pageStay === 'number') payload$.properties.pageStay = pageStay;
      if (event.routeFrag) payload$.properties.frag = event.routeFrag;
      if (event.routeQueryParams) payload$.properties.queryParams = event.routeQueryParams;
      this.appInsights.trackPageView(payload$);
    });
  }

  public trackEvent(eventName: string, payload: ICustomProperties, properties?: ICustomProperties): void {
    const properties$: ICustomProperties = { ...properties, refUri: this.routeUtil.getRouteEvent.routeUrl };
    const payload$: ICustomProperties = { ...payload };
    delete payload$.properties;
    this.logActive &&
      this.logger.debug(`${eventName} trackEvent:`, { properties: { ...properties$ }, payload: { ...payload$ } });
    this.appInsights.trackEvent({ name: eventName, properties: properties$ }, payload$);
  }

  public trackTrace(error: Error, event?: unknown): void {
    if (!environment.production) {
      console.debug('trace:', [error.message, event]);
      console.error(error);
      return;
    } else if (environment.production) {
      console.error(error);
    }
    this.appInsights.trackTrace({ message: error.message, severityLevel: 3 }, { detail: error });
  }
}
