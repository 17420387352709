import { createAction, props } from '@ngrx/store';
import { PeriodSelectorState } from '../reducers';
import { ActionCreatorProp } from '.';

// The Action interface
export enum PeriodSelectorActionTypes {
  Save = '[Period Selector] Action',
}

export type PeriodSelectorPayload = {
  Save: PeriodSelectorState;
};

export const PeriodSelectorAction: ActionCreatorProp<PeriodSelectorPayload['Save']> = createAction(
  PeriodSelectorActionTypes.Save,
  props()
);

export const PeriodSelectorActions = {
  PeriodSelectorAction,
};
