// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Caloudi
import { PowerbiEmbed } from '@base/model';
import environment from '@env';

@Injectable({ providedIn: 'root' })
export class PowerbiService {
  constructor(private readonly http: HttpClient) {}

  public getEmbedTokenAad(): Observable<PowerbiEmbed> {
    return this.http.get<PowerbiEmbed>(`${environment.cmpAPIServer.url}/api/pbi/embed/Aad`);
  }

  // Saas subscription pbi
  public getSaaSSubscriptionEmbedTokenAad(): Observable<PowerbiEmbed> {
    return this.http.get<PowerbiEmbed>(`${environment.cmpAPIServer.url}/api/pbi/embed/Aad/saasSubscription`);
  }

  public getPayoutEmbedTokenAad(): Observable<PowerbiEmbed> {
    return this.http.get<PowerbiEmbed>(`${environment.cmpAPIServer.url}/api/pbi/embed/Aad/payout`);
  }

  public getQnAEmbedTokenAad(): Observable<PowerbiEmbed> {
    return this.http.get<PowerbiEmbed>(`${environment.cmpAPIServer.url}/api/pbi/embed/Aad/QnA`);
  }

  public getLeadEmbedTokenAad(): Observable<PowerbiEmbed> {
    return this.http.get<PowerbiEmbed>(`${environment.cmpAPIServer.url}/api/pbi/embed/Aad/lead`);
  }

  // To test one fit all pbi page below
  public testTabLabels: string[];
  public enableTabs: boolean[];

  public tabConfig: TabConfigs = {
    'lead-management-powerbi': {
      tabLabels: ['Azure SaaS Lead'],
      enableTabs: [true],
      getEmbedAad: this.getLeadEmbedTokenAad(),
    },
    'payout-powerbi': {
      tabLabels: [
        'Payout by Program',
        'Payout by Lever',
        'Payout Trend & Portion',
        'Payout Decomposition',
        'Payout Query',
        'Payout Top N',
        'Unlinked Subscription',
        'Unlinked By Owner',
        'CMP Usage By Owner',
        'CMP Subscription Usage',
      ],
      enableTabs: [true, true, true, true, true, true, true, true, true, true],
      getEmbedAad: this.getPayoutEmbedTokenAad(),
    },
    'saas-subscription-pbi': {
      tabLabels: [
        'POWERBI.ONBOARD_CONTACT',
        'POWERBI.SAAS_SUBSCRIPTION_ANALYSIS',
        'POWERBI.ONBOARD_DETAIL',
        'POWERBI.ONBOARD_ANALYSIS',
        'POWERBI.DAILY_USAGE_DETAIL',
        'POWERBI.DAILY_USAGE_ANALYSIS',
        'POWERBI.MONTHLY_USAGE_DETAIL',
        'POWERBI.MONTHLY_USAGE_ANALYSIS',
        'POWERBI.SAAS_METERING',
        'POWERBI.SAAS_METERING_ANALYSIS',
      ],
      enableTabs: [true, true, true, true, true, true, true, true, true, true],
      getEmbedAad: this.getSaaSSubscriptionEmbedTokenAad(),
    },
  };

  public getTabLabels(tabKey: keyof TabConfigs): string[] {
    return this.tabConfig[tabKey].tabLabels;
  }

  public getEnableTabs(tabKey: keyof TabConfigs): boolean[] {
    return this.tabConfig[tabKey].enableTabs;
  }

  public getEmbedTokenAadByKey(tabKey: keyof TabConfigs): Observable<PowerbiEmbed> {
    return this.tabConfig[tabKey].getEmbedAad;
  }
}

interface TabConfig {
  tabLabels: string[];
  enableTabs: boolean[];
  getEmbedAad: Observable<PowerbiEmbed>;
}

interface TabConfigs {
  'lead-management-powerbi': TabConfig;
  'payout-powerbi': TabConfig;
  'saas-subscription-pbi': TabConfig;
}

export interface PowerBITabConfigs {
  TabConfig: TabConfig;
  TabConfigs: TabConfigs;
}
