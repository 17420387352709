// NGRX
import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { AppState, TempStoreState } from '../reducers';

// For selector
export const selectTempStoreState: <T>(state: AppState) => TempStoreState<T> = <T extends TempStoreState<T>>(
  state: AppState<T>
): TempStoreState<T> => state.tempStore;

export const getTempStore = createSelector(selectTempStoreState, (store: TempStoreState<unknown>) => store);

export const getTempStoreByKey = <T>(key: string): MemoizedSelector<AppState<T>, T, DefaultProjectorFn<T>> =>
  createSelector(selectTempStoreState, (store: TempStoreState<unknown>) => store?.[key] as T);
