// Angular
import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, Output } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
// Interface
import { ParkingSchedule } from '@base/model';

@Component({
  selector: 'caloudi-parking-schedule-cell, parking-schedule-cell, [parking-schedule-cell]',
  templateUrl: './parking-schedule-cell.component.html',
  styleUrls: ['./parking-schedule-cell.component.sass'],
})
export class ParkingScheduleCellComponent extends BaseComponent {
  @Input('row') public rowIndex: number;
  @Input('col') public colIndex: number;
  @Input('coord') public coord: ParkingSchedule['Coord'];

  @Output('coordStart')
  public coordStartEmitter = new EventEmitter<ParkingSchedule['Coord']>();

  @Output('coordEnd')
  public coordEndEmitter = new EventEmitter<ParkingSchedule['Coord']>();

  @HostListener('mousedown')
  public onMouseDown(): void {
    this.coordStartEmitter.emit({ x: this.rowIndex, y: this.colIndex });
  }

  @HostListener('mouseup')
  public onMouseUp(): void {
    this.coordEndEmitter.emit({ x: this.rowIndex, y: this.colIndex });
  }

  constructor(public readonly injector: Injector, public readonly el: ElementRef<HTMLElement>) {
    super(injector);
  }
}
