export enum EAAPITYPE {
  AzureGlobalEAAPI = 'Azure Global EA API',
  Azure21VEAAPI = 'Azure 21V EA API',
  GCP = 'GCP',
  AliChina = 'AliChina',
  AWS = 'AWS',
  AWD = 'AWD', // PAYG
  CaloudiCSPClient = 'CaloudiCSPClient', // Caloudi CSP Azure Plan
  AzurePlan = 'AzurePlan',
  XCloud = 'XCloud',
  M365 = 'M365',
  CloudSec = 'CloudSec',
  VRM = 'VRM',
  YSL = 'YSL',
}
