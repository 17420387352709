// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
// Interface
import { ShareLinkCreate, ShareLinkPageState } from '@base/model';

@Injectable({ providedIn: 'root' })
export class ShareLinkService {
  constructor(private readonly http: HttpClient) {}

  public getPageState<T>(pageStateEncId: string): Observable<ShareLinkPageState & T> {
    return this.http.get<ShareLinkPageState & T>(
      `${environment.cmpAPIServer.url}/api/pageState/sharedLink/${pageStateEncId}`
    );
  }

  public createShareLink<T>(shareLinkCreate: ShareLinkCreate<T>): Observable<string> {
    return this.http.put<string>(`${environment.cmpAPIServer.url}/api/pageState/sharedLink`, shareLinkCreate);
  }
}
