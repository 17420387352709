// Angular
import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// Caloudi
import { BaseComponent } from '@base';
import { ReportService } from '@base/service';
import { CommonValidatorService } from '@core/service';
// Interface
import { EmailReport } from '@base/model';
import { MessageSeverity } from '@core/enum';

@Component({
  selector: 'caloudi-report-sender',
  templateUrl: './caloudi-report-sender.component.html',
  styleUrls: ['./caloudi-report-sender.component.sass'],
})
export class CaloudiReportSenderComponent extends BaseComponent implements OnChanges {
  @Input('id') public reportId: string;
  @Input('visible') public dialogVisible: boolean;
  @Output('visibleChange') public displaySendReportDialog = new EventEmitter<boolean>();

  // public displaySendReportDialog: boolean;
  public displayConfirmDialog: boolean;
  public reportDeliveryForm: FormGroup<ReportDeliveryFormGroup>;
  public reportTitle: string;
  public reportNote: string;
  public emailRecipients: string[];

  public get formControls(): ReportDeliveryFormGroup {
    return this.reportDeliveryForm.controls;
  }

  constructor(
    public readonly injector: Injector,
    private readonly formBuilder: FormBuilder,
    private readonly validator: CommonValidatorService,
    private readonly reportService: ReportService
  ) {
    super(injector);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.reportId?.currentValue && changes.dialogVisible?.currentValue) {
      this.showSendReport();
    }
  }

  public showSendReport(): void {
    this.reportDeliveryForm = this.formBuilder.group<ReportDeliveryFormGroup>({
      mailList: new FormControl(this.emailRecipients, this.validator.multiEmailAddressValidator()),
      mailTitle: new FormControl(this.reportTitle),
      mailNote: new FormControl(this.reportNote),
    });
  }

  public sendReport(): void {
    this.displayConfirmDialog = true;
  }

  public sendAccept(): void {
    const emailReport: EmailReport = {
      // ReportUrl: this.router.url,
      reportUrl: location.href.split('#')[0] + '&mode=report',
      emailRecipients: this.formControls.mailList.value,
      reportName: this.lang('INVOICE.INVOICE_DETAILS') + ': ' + this.reportId,
      reportTitle: this.formControls.mailTitle.value,
      reportNote: this.formControls.mailNote.value,
    };
    this.logger.debug('report:', [this.reportDeliveryForm, emailReport]);
    this.reportService.emailReport(emailReport).subscribe(res => {
      // Toast success message
      this.store.dispatch(
        this.actions.GlobalMsgAction({
          severity: MessageSeverity.SUCCESS,
          summary: this.lang('ACTION.SEND_REPORT_BY_EMAIL'),
          message: res.message,
        })
      );
      this.displayConfirmDialog = false;
      this.displaySendReportDialog.emit(false);
    });
  }

  public sendReject(): void {
    this.displayConfirmDialog = false;
  }
}

interface ReportDeliveryFormGroup {
  mailList: FormControl<string[]>;
  mailTitle: FormControl<string>;
  mailNote: FormControl<string>;
}
