/* eslint-disable @typescript-eslint/no-explicit-any */
// NGRX
import { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
// Selectors
import * as auth from './auth.selectors';
import * as blockui from './blockui.selectors';
import * as layout from './layout.selectors';
import * as message from './message.selectors';
import * as period from './period-selector.selectors';
import * as tabmenu from './tab-menu.selectors';
import * as temp from './temp-store.selectors';
// Reducers
import { AppState, AuthState, BlockUIState, LayoutConfig, MessageState, PeriodSelectorState, TabMenuState, TempStoreState } from '@Reducers';
// Export selectors
export * from './auth.selectors';
export * from './blockui.selectors';
export * from './layout.selectors';
export * from './message.selectors';
export * from './period-selector.selectors';
export * from './tab-menu.selectors';
export * from './temp-store.selectors';

export const StoreSelectors: StoreSelectors = {
  auth: auth,
  blockui: blockui,
  layout: layout,
  message: message,
  period: period,
  tabmenu: tabmenu,
  temp: temp,
};

const Selectors: Selectors = {
  auth: auth.getAuth,
  blockui: blockui.getBlockUI,
  layout: layout.getLayouts,
  message: message.getGlobalMessage,
  period: period.getPeriodSelector,
  temp: temp.getTempStore,
  tabmenu: tabmenu.getTabMenuState,
  tempKey: temp.getTempStoreByKey,
  ...auth,
  ...blockui,
  ...layout,
  ...message,
  ...period,
  ...tabmenu,
  ...temp,
};

interface StoreSelectors {
  auth: typeof auth;
  blockui: typeof blockui;
  layout: typeof layout;
  message: typeof message;
  period: typeof period;
  tabmenu: typeof tabmenu;
  temp: typeof temp;
}

type AllSelectors = typeof auth &
  typeof blockui &
  typeof layout &
  typeof message &
  typeof period &
  typeof tabmenu &
  typeof temp;

interface Selectors<T = any> extends AllSelectors {
  auth: MemoizedSelector<AppState<T>, AuthState, DefaultProjectorFn<AuthState>>;
  blockui: MemoizedSelector<AppState<T>, BlockUIState, DefaultProjectorFn<BlockUIState>>;
  layout: MemoizedSelector<AppState<T>, LayoutConfig, DefaultProjectorFn<LayoutConfig>>;
  message: MemoizedSelector<AppState<T>, MessageState, DefaultProjectorFn<MessageState>>;
  period: MemoizedSelector<AppState<T>, PeriodSelectorState, DefaultProjectorFn<PeriodSelectorState>>;
  tabmenu: MemoizedSelector<AppState<T>, TabMenuState, DefaultProjectorFn<TabMenuState>>;
  temp: MemoizedSelector<AppState<T>, T, DefaultProjectorFn<TempStoreState<T>>>;
  tempKey: (key: string) => MemoizedSelector<AppState<T>, T, DefaultProjectorFn<TempStoreState<T>>>;
}

export default Selectors;
