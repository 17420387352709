<ng-content></ng-content>
<div container>
  <fa-icon
    *ngIf="value"
    [icon]="ikon"
    (click)="onClick()"
    (mousedown)="onMousedown()"
    (mouseup)="onMouseup()"></fa-icon>
  <span tooltip *ngIf="copied">Copied!</span>
</div>
