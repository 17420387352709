import { createReducer, on } from '@ngrx/store';
import { JSONUtil } from '@util';
import { TempStoreActions } from '../actions';

export interface TempStoreState<T> {
  [x: string]: T;
}

export const tempStoreReducer = createReducer(
  {},
  on(
    TempStoreActions.TempStoreAction,
    <T>(_state: TempStoreState<T>, payload: TempStoreState<T>): TempStoreState<T> => payload
  ),
  on(TempStoreActions.TempStoreAppend, <T>(state: TempStoreState<T>, payload: TempStoreState<T>): TempStoreState<T> => {
    const newStateAppend: TempStoreState<T> = JSONUtil.parse<TempStoreState<T>>(JSONUtil.stringify(state || null));
    return { ...newStateAppend, ...payload };
  }),
  on(
    TempStoreActions.TempStoreRemoveByKey,
    <T>(state: TempStoreState<T>, { key }: { key: string }): TempStoreState<T> => {
      try {
        const newStateRemoveKey: TempStoreState<T> = JSONUtil.parse<TempStoreState<T>>(
          JSONUtil.stringify(state || null)
        );
        delete newStateRemoveKey[key];
        return Object.keys(newStateRemoveKey)?.length === 0 ? null : newStateRemoveKey;
      } catch (error) {
        return null;
      }
    }
  )
);
