export enum ComponentStateKey {
  AWSPowerbiCmp = 'aws-powerbi-cmp',
  AWSResourcePlan = 'aws-resource-plan',
  AgentEnrollment = 'agent-enrollment',
  AzurePowerbiCmp = 'azure-powerbi-cmp',
  AzureRIFilter = 'azure-ri-filter',
  AzureSaaSSubscriptionPBI = 'azure-saas-subscription-pbi',
  AzurePayoutPBI = 'azure-payout-pbi',
  AzurePBIQnA = 'azure-pbi-qna',
  AzureTenantUpdate = 'azure-tenant-update',
  AzureUsageCommonFilter = 'azure-usage-common-filter',
  AzureUsageDashboard = 'azure-usage-dashboard',
  AzureUsageInstanceUsageDiff = 'azure-usage-instance-usage-diff',
  AzureResourcePlan = 'azure-resource-plan',
  AzureInstanceCostPivot = 'azure-instance-cost-pivot',
  CMPAgentSupportTicket = 'cmp-agent-support-ticket',
  CSPCustomerInvoiceFilter = 'csp-customer-invoice-filter',
  CSPPartnerInvoiceFilter = 'csp-partner-invoice-filter',
  CSPResellerInvoiceFilter = 'csp-reseller-invoice-filter',
  CSPRevenueCommonFilter = 'csp-revenue-common-filter',
  DataStudio = 'data-studio',
  EAState = 'ea-state',
  GCPCommonFilter = 'gcp-common-filter',
  GCPResourcePlan = 'gcp-resource-plan',
  LeadManagementPBI = 'lead-management-pbi',
  OnboardStatus = 'onboard-status',
  OrphanResourceDetail = 'orphan-resource-detail',
  SalesOrdersFilter = 'sales-orders-filter',
  SwitchEnrollment = 'switch-enrollment',
  UsageBudget = 'usage-budget',
  UsageBudgetEvalUserList = 'usage-budget-eval-user-list',
  CloudSecDashboard = 'cloud-sec-dashboard',
  CloudSecReport = 'cloud-sec-report',
  CloudSecFinding = 'cloud-sec-finding',
  CloudSecScan = 'cloud-sec-scan',
  CloudSecTarget = 'cloud-sec-target',
  YslTaskList = 'ysl-task-list',
  YslScansByRuns = 'ysl-scans-by-runs',
  YslScansByScans = 'ysl-scans-by-scans',
}
