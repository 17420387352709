// Angular
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
// Interface
import { RatingBarChartRange } from '@base/model';
// D3
import * as d3 from 'd3';
import { sha256 } from 'js-sha256';

@Component({
  selector: 'caloudi-rating-bar-chart',
  templateUrl: './rating-bar-chart.component.html',
  styleUrls: ['./rating-bar-chart.component.sass']
})
export class RatingBarChartComponent implements AfterViewInit {

  public readonly chartId: string;

  @Input('height') public _height: number;
  @Input('width') public _width: number;
  @Input('value') public value: number;
  @Input('data') public data: RatingBarChartRange[];

  private readonly logActive: boolean = !1;

  constructor(
    private readonly element: ElementRef<HTMLElement>,
  ) {
    this.chartId = 'rating-bar-chart-' + sha256(Math.random().toString()).slice(0, 10);
  }

  public ngAfterViewInit(): void {
    const el: HTMLElement = this.element.nativeElement;
    const width: number = this._width || el.parentElement.clientWidth;
    const height: number = this._height || el.parentElement.clientHeight || 20;
    const totalCount: number = this.data[this.data.length - 1].threshold;
    const colors: string[] = this.data.map(({ color }) => color);
    const thresholds: number[] = this.data.map(({ threshold }) => threshold);
    const xScale: d3.ScaleLinear<number, number> = d3.scaleLinear().range([0, width]).domain([0, totalCount]);

    !this.logActive && console.debug('view:', [el, this.chartId, totalCount, colors, thresholds]);

    /** Basic svg  */
    const svg = d3.select<HTMLDivElement, RatingBarChartRange>('#' + this.chartId)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height]);

    /** Clip path */
    const clip = svg.append('defs');

    clip.append('clipPath')
      .attr('id', 'rbc-clip')
      .append('rect')
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('width', width)
      .attr('height', height);

    /** Clip path 2 */
    clip.append('clipPath')
      .attr('id', 'rbc-clip2')
      .append('rect')
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('width', width)
      .attr('height', height);

    /** Background color */
    svg.append('rect')
      .attr('clip-path', 'url(#rbc-clip)')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#eee');

    /** Data section */
    const data = svg.append('g')
      .attr('class', 'data')
      .attr('clip-path', 'url(#rbc-clip2)');

    /** Data Bar */
    data.selectAll<SVGRectElement, RatingBarChartRange>('rect')
      .join('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height);

    /** Bind data */
    d3.select<HTMLDivElement, RatingBarChartRange>('#' + this.chartId)
      .select('.data')
      .selectAll<SVGRectElement, RatingBarChartRange>('rect')
      .data(this.data, (d) => d.threshold)
      .join(
        enter => enter.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('height', height)
          .attr('width', () => xScale(this.value))
          .attr('fill', () => colors[this.data.findIndex(d => this.value <= d.threshold)])
          .append('title'),
        update => update,
        exit => exit.call(item =>
          item.remove())
      );
  }
}

// interface Changes extends SimpleChanges {
//   data: NgChange<RatingBarChartRange[]>;
//   value: NgChange<number>;
//   _height: NgChange<number>;
//   _width: NgChange<number>;
// }
