/* eslint-disable
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-explicit-any
 */
// Angular
import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// Rxjs
import { first } from 'rxjs';
// Store
import { TempStoreAppend } from '@Actions';
import { Store } from '@ngrx/store';
import Selectors from '@Selectors';

@Directive({ selector: '[temp]' })
export class TempStoreDirective implements OnChanges {
  @Input('temp') public tempStorePayload: TempStorePayload;

  @Output('tempChange') public tempStoreEmitter = new EventEmitter<any>();

  private readonly logActive: boolean;

  constructor(private readonly store: Store, private readonly router: Router) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tempStorePayload.firstChange) {
      this.store
        .select(Selectors.temp)
        .pipe(first())
        .subscribe((res: TempStoreState & TempStoreStateItem): void => {
          const key = this.router.url;
          const keys = Object.keys(res || {});

          if (!keys.some(v => v === key) || !res[key]?.[this.tempStorePayload.key])
            this.store.dispatch(
              TempStoreAppend({
                [key]: {
                  ...res[key],
                  [this.tempStorePayload.key as keyof TempStoreState]: this.tempStorePayload
                    .payload as TempStoreStateItem[keyof TempStoreState],
                },
              })
            );
          else this.tempStoreEmitter.emit(res[key][this.tempStorePayload.key]);

          this.logActive && console.debug('temp store:', [res, keys]);
        });
    } else {
      this.store
        .select(Selectors.temp)
        .pipe(first())
        .subscribe((res: TempStoreState & TempStoreStateItem) => {
          const key = this.router.url;
          this.store.dispatch(
            TempStoreAppend({
              [key]: {
                ...res[key],
                [this.tempStorePayload.key as keyof TempStoreState]: this.tempStorePayload
                  .payload as TempStoreStateItem[keyof TempStoreState],
              },
            })
          );
        });
    }
  }
}

interface TempStoreStateItem {
  [route: string]: TempStorePayload;
}

interface TempStoreState {
  type: string;
}

interface TempStorePayload {
  key: string;
  payload: any;
}
