// NGRX
import { createSelector } from '@ngrx/store';
import { AppState, LayoutConfig, LayoutTabMenuState } from '../reducers';

// For selector
export const selectLayoutState: (state: AppState) => LayoutConfig = (state: AppState): LayoutConfig => state.layout;

// Point to 'error' from state subtree
export const getLayouts = createSelector(selectLayoutState, layout => layout);

/**
 * Layout state without x-cloud featuresa
 */
export const getLayout = createSelector(
  selectLayoutState,
  layout =>
    <LayoutConfig>{
      blockLoading: layout.blockLoading,
      colorScheme: layout.colorScheme,
      inputStyle: layout.inputStyle,
      lang: layout.lang,
      menuMode: layout.menuMode,
      ripple: layout.ripple,
      scale: layout.scale,
      componentTheme: layout.componentTheme,
      menuTheme: layout.menuTheme,
      topbarTheme: layout.topbarTheme,
    }
);

/**
 * For lang only
 */
export const getLayoutLang = createSelector(selectLayoutState, layout => layout.lang);

/**
 * For x-cloud new ui
 */
export const getLayoutTabMenu = createSelector(
  selectLayoutState,
  layout =>
    <LayoutTabMenuState>{
      tabKey: layout.tabKey,
      layoutType: layout.layoutType || 0,
    }
);

export const getLayoutType = createSelector(selectLayoutState, layout => layout.layoutType);
