import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    ANOMALY: {
      ADD_NOTIFICATION: '新增异常通知',
      ANOMALY: '异常',
      ANOMALY_DETECTION_MODEL: '异常检测模组',
      ANOMALY_DETECTION: '异常检测',
      ANOMALY_NOTIFICATION_SETTINGS: '异常通知设定',
      ANOMALY_POINTS_NOTICE: '*仅列出30天异常点细节',
      ANOMALY_POINTS: '异常点',
      AVAILABLE_CHARTS: '图表可使用',
      COST_DEVIATION_GREATER_THAN: '费用偏差大于',
      NOTIFICATION_LANGUAGE: '通知语言',
      NOTIFICATION_RECIPIENTS: '通知收件人',
      PRIMARY_INSTANCE_CAUSES: '异常实例主因',
      PRIMARY_LABEL_CAUSES: '异常标签主因',
      PRIMARY_PRODUCT_CAUSES: '异常产品主因',
      PRIMARY_SERVICE_CAUSES: '异常服务主因',
      PRIMARY_SKU_CAUSES: '异常SKU主因',
    },
    ENTITY: {
      ACQUIRE: '取得',
      APP_INSIGHT: '应用程序监测',
      AUDIT_TYPE: '稽核类别',
      BALANCE_SUMMARY: '余额汇总',
      CATALOG: '目录',
      CATEGORY: '服务类别',
      CHART: '统计图表',
      CONSUMED_SERVICE: '已购买服务',
      DEPARTMENT: '部门',
      DEPENDENCY: '依赖项',
      ENROLLMENT: '注册',
      DOMAIN: '网域',
      FLEXIBILITY_GROUP: '弹性群组',
      INSTANCE: '实例',
      INSTANCE_NAME: '实例名称',
      INSTANCE_TYPE: '实例类别',
      INSTANCE_ID: '实例ID',
      OPERATION: '作业',
      OS: '操作系统',
      PRODUCT: '产品',
      PROJECT: '专案',
      RESOURCE_GROUP: '资源群组',
      RESOURCE_LABELS: '资源标签',
      RESOURCE_TAG_KEY: '标签键',
      RESOURCE_TAG_VALUE: '标签值',
      RESOURCE_TAG: '资源标签',
      RESOURCE_TYPE: '资源类别',
      RESOURCE_VALUE: '资源值',
      RESOURCE: '资源',
      SERVICE: '服务',
      SERVICE_CATEGORY: '服务类别',
      SETTING: '设定',
      SKU: 'SKU',
      SQL: 'SQL',
      SUBSCRIPTION: '订阅',
      SUBSCRIPTION_ID: '订阅编号',
      TENANT_DOMAIN: '租户网域',
      TENANT: '租户',
      TENANT_GUID: '租户标识符',
      VIRTUAL_MACHINE: '虚拟机',
      VM_FLEXIBILITY_GROUP: 'VM 弹性群组',
      VM_RECOMMENDATION: 'VM建议',
      VM: 'VM',
    },
    BUDGET: {
      BUDGET_ALERT: '	预算预警',
      BUDGET_ID: '预算编号',
      BUDGET_NAME: '预算名称',
      BUDGET_PERCENT: '预算百分比',
      BUDGET_SUGGESTION: '预算建议',
      BUDGET_YEAR: '预算年度',
      BUDGET: '预算',
      EXPIRATION_DATE: '预算停止预警日期',
      OVERALL_COST_ACCOUNT: '该帐户的总费用',
      OVERALL_COST_DEPARTMENT: '该部门的总费用',
      OVERALL_COST_PRODUCT: '该产品与订阅的总费用',
      OVERALL_COST_REGION: '该区域与订阅的总费用',
      OVERALL_COST_RESOURCE_GROUP: '该资源群组与订阅的总费用',
      OVERALL_COST_SERVICE: '该服务与订阅的总费用',
      OVERALL_COST_SUBSCRIPTION: '该订阅的总费用',
      OVERALL_COST_TAG: '该标签与订阅的总费用',
      OVERALL_COST_THE_LABEL: '所选帐单帐户中标签的总费用',
      OVERALL_COST_THE_PROJECT: '所选帐单帐户中专案的总费用',
      OVERALL_COST_THE_REGION: '所选帐单帐户中区域的总费用',
      OVERALL_COST_THE_SERVICE: '所选帐单帐户中服务的总费用',
      OVERALL_COST_THE_SKU: '所选帐单帐户中SKU的总费用',
      OVERALL_COST: '总费用',
      THRESHOLD_1: '第一门槛',
      THRESHOLD_2: '第二门槛',
      THRESHOLD_3: '第三门槛',
      TOTAL_BUDGET_OVERRAGE_ALERT: '总预算超额预警',
      TOTAL_BUDGET: '预算总额',
    },
    CHART: {
      BAR_CHART: '条形图',
      LINE_CHART: '折线图',
      LINECHART_INFO: '时间轴处拖曳放大. 点击空白处还原.',
      OTHER_CHART: '其他图表',
      PIE_CHART_3D: '3D饼形图',
      PIE_CHART: '饼形图',
      STACK_CHART: '堆积条形图',
      STACKED: '堆积条形图',
      SWITCH_BAR: '切换条形图类型',
      SWITCH_PIE: '切换饼形图表类型',
    },
    COST: {
      ALL_METERED_COST: '所有计量费用',
      BALANCE: '余额',
      BEGINING_BALANCE: '初始费用',
      COST: '费用',
      COST_30D: '近30日费用',
      COST_30D_SUMMARY: '近30日费用总览',
      COST_30D_TOTAL: '近30日费用总和',
      COST_DETAILS: '费用明细',
      COST_DEVIATION: '费用偏差',
      COST_OF_CURRENT_MONTH: '当月费用',
      COST_OF_LAST_MONTH: '上月费用',
      COST_QUERY_YEAR: '费用查询年度',
      COST_SUMMARY: '费用总览',
      COST_TYPE: '费用类别',
      CURRENT_USAGE: '当前用量',
      CUSTOMER_CHARGES: '客户费用',
      DAILY_COST: '每日费用',
      LAST_30_DAYES_DAILY_COST: '最近30日每日费用',
      LIST_PRICE_TOTAL: '牌价总计',
      MARGIN: '净利',
      METERED_COST: '费用计量',
      MONTHLY_COST: '每月费用',
      NEW_COST: '新费用',
      ORIGINAL_CHARGES: '原价总计',
      PARTNER_CHARGES: '伙伴费用',
      PRETAX_SUBTOTAL: '税前小计',
      REMAINING_BALANCE: '剩余费用',
      SUBTOTAL: '小计',
      TAX: '税',
      TOATL_COVERAGE_RATE: '总覆盖率',
      TOTAL: '总计',
      TOTAL_AMOUNT: '总数',
      TOTAL_CHARGES: '总费用',
      TOTAL_COST: '费用总计',
      TOTAL_QTY: '数量总计',
      TOTAL_REVENUE: '总收入',
      TOTAL_TAX: '税金总计',
      WEEKLY_COST: '每周费用',
    },
    COMMON: {
      A: '',
      BY: '',
      FROM: '自',
      YES: '是',
      NO: '否',
      NOT: '不',
      OF: '/',
      OR: '或',
      TO: '到',
    },
    CURRENCY: {
      CURRENCY: '货币',
      NTD: '新台币',
      USD: '美元',
      TWD: '新台币',
    },
    LOG: {
      ACTIVITY_LOG: '活动日志',
      ACTIVITY_LOGS: '活动日志',
      LOG_DATE: '日志日期',
      LOG_PERIOD: '日志周期',
      LOG_QUERY: '日志查询',
      LOG_TYPE: '日志类别',
    },
    INVOICE: {
      ACCOUNT_PROFILE: '帐户资讯',
      BILLING_DETAILS: '单据细项',
      BILLING_INFO: '单据资讯',
      BILLING_PROFILE: '单据资讯',
      BILLING_SUMMARY: '单据摘要',
      BILL_TO: '收帐方',
      COMPANY_NAME: '公司名',
      COMPANY_PROFILE: '公司资讯',
      CUSTOMERS: '客户',
      CUSTOMER_PROFILE: '客户资讯',
      DATE: '单据日期',
      DATE_END: '结束日',
      DATE_START: '起始日',
      DATE_UTC: 'UTC单据日期',
      DETAILS: '发票细节',
      DOCUMENT_TYPE: '凭证类别',
      ID: '单据编号',
      INFO: '发票资讯',
      INVOICE: '帐单',
      LICENSE: '授权',
      LICENSES: '授权',
      LICENSE_LINEITEMS: '授权单项',
      LINEITEM: '项目',
      LINEITEMS: '项目',
      LINEITEM_DETAILS: '项目细节',
      LUMPSUM: '合计',
      NET_N_DAYS: '净 {{ N }} 天',
      NUMBER: '单据号',
      OFFERS: '项目',
      ONETIME: '一次性',
      ONETIME_LINEITEMS: '一次性单项',
      ORDER_ID: '订单编号',
      ORDER_INFO: '订单资讯',
      PAID_AMOUNT: '已付金额',
      PAYMENT_STATUS: '付款状态',
      PAYMENT_TERMS: '付款条件',
      PRODUCTS: '产品',
      PRODUCT_CATS: '商品分类',
      PRODUCT_INFO: '产品资讯',
      PURCHASE_INFO: '购买资讯',
      QUANTITY_AMOUNT: '数量与费用',
      RECON: '明细',
      RECURRING: '周期性',
      SKUS: 'SKU',
      SOLD_TO: '受买方',
      SUMMARY: '概观',
      TOTAL_AMOUNT: '总金额',
      TYPE: '账单类别',
    },
    REPORT: {
      EMAIL_LIST: '邮箱清单',
      REPORT: '报告',
      NOTE: '报告注记',
      TITLE: '报告标题',
      UNTITLED: '无标题',
    },
    RESELLER_QUERY: {
      INCLUDE_PRIVILEGES_DESCRIPTION: '包含Azure Active Directory和Office 365的委派系统管理许可权',
      REQUEST_RELATIONSHIP_DESCRIPTION:
        '若要要求与客户建立经销商关系，请将以下内容，包含网址，复制并贴上到电子邮箱中。',
      SEND_EMAIL_DESCRIPTION: '若有需要可以编辑内容，再透过电子邮箱发送给您的客户。',
    },
    RI: {
      CURRENT_RI_COVERAGE_RATE: '当前RI覆盖率',
      NEW_RI_COVERAGE_RATE: 'RI新覆盖率',
      NO_RI_COST: '无RI成本',
      RECOMMENDED_COST: '建议费用',
      RI_ANALYSIS_DETAIL: '建议RI细节分析',
      RI_COST_ALLOCATION: 'RI成本摊提',
      RI_COST_WITH_NO_RI: '每年无RI成本',
      RI_COST_WITH_RI: '每年有RI成本',
      RI_COST: 'RI成本',
      RI_COVERAGE_ANALYSIS: 'RI覆盖率分析',
      RI_COVERAGE_ANALYSIS_NOTE: 'SKU建议是基于最近30天(从 {{ startDate }} 起)的使用情况。',
      RI_COVERAGE_ANALYSIS_WITH_ISF_NOTE: 'SKU建议是基于最近30天(从 {{ startDate }} 起)的使用情况及相同弹性群组。',
      RI_COVERAGE_RATE: 'RI覆盖率',
      RI_COVERAGE_AND_UTILIZATION_RATE: 'RI复盖率 / 使用率',
      RI_ONE_YEAR: 'RI 1年',
      RI_ORDER: 'RI订单',
      RI_PRICE_SHEET: 'RI价格表',
      RI_RECOMMEND_QUANTITY: 'RI数量',
      RI_RECOMMENDATION: 'RI建议',
      RI_THREE_YEAR: 'RI 3年',
      RI_TRANSFER_ESTIMATION: 'RI转移估算',
      RI_UNUSED_COST: 'RI未使用成本',
      RI_USAGE_DETAIL: 'RI使用明细',
      RI_USAGE_SUMMARY: 'RI使用摘要',
      RI_UTILIZATION_RATE: 'RI使用率',
      RI1Y: 'RI 1年',
      RI3Y: 'RI 3年',
      SHOW_ACTIVE_RI_ORDER: '仅显示使用中RI订单',
      TRANSFER_ESTIMATE: {
        ADDITIONAL_PAYMENT: '额外支付费用',
        EQUIVALENT_HOURS_TO_NEW_RI_ORDER: '剩余金额转换新RI订单之相等时数',
        NEW_RI_ORDER_COST: '新RI订单价格',
        NEW_RI_ORDER_RESERVATION_COST: {
          ESTIMATE_INFO: '新RI订单价格(预估)',
          PER_HOUR: '小时',
          PER_MONTH: '月',
          PER_YEAR: '年',
        },
        NO_RI_INFO: '无RI资讯',
        NOT_INCLUDE_FEE: 'RI转移估算不包括百分之 12 的提前解约金',
        REMAINING_BALANCE: '剩余金额',
        REMAINING_HOURS: '剩余时数',
      },
    },
    DATE: {
      SECONDS: '秒',
      MINUTE: '分',
      MINUTES: '分',
      HOUR: '小时',
      HOURLY: '每小时',
      HOURS: '小时',
      SUN: '星期日',
      MON: '星期一',
      TUE: '星期二',
      WED: '星期三',
      THU: '星期四',
      FRI: '星期五',
      SAT: '星期六',
      SUNDAY: '星期日',
      MONDAY: '星期一',
      TUESDAY: '星期二',
      WEDNESDAY: '星期三',
      THURSDAY: '星期四',
      FRIDAY: '星期五',
      SATURDAY: '星期六',
      TODAY: '今天',
      DAY: '天',
      DAYS: '天',
      DAILY: '每日',
      DAY_OF_THE_WEEK: '星期',
      WEEK: '周',
      WEEKLY: '每周',
      WEEKDAY: '工作日',
      WEEK_DAY: '在工作日(周一到周五)',
      MONTH: '月份',
      MONTHLY: '每月',
      LAST_MONTH: '上月',
      CURRENT_MONTH: '本月',
      PREVIOUS_MONTH: '前一月',
      DATE: '日期',
      DATES: '日期',
      LAST_7_DAYS: '最近7天',
      LAST_N_DAYS: '最近{{N}}天',
      LAST: '最近',
      QUARTERLY: '每季',
      YEARLY: '每年',
    },
    ADVISOR_DASHBOARD: {
      ADD_TO_MY_ADVISORY_LIST: '添加到我的建议清单',
      CHECK_ALL_ANOMALY_DETECTIONS: '查看所有异常检测',
      CHECK_ALL_RIRECOMMENDATIONS: '查看所有RI建议',
      CHECK_ALL_RIGHT_SIZINGS: '查看所有型号更换',
      CHECK_USAGE_SUMMARY: '查看费用摘要',
      INSIGHTS_OVERVIEW: '建议概述',
      LOW_RI_COVERAGE_RATE: 'RI覆盖率低',
      LOW_RI_UTILIZATION_VM_CPU: 'RI低使用率VM CPU',
      MAX_CPU_95TH_PERCENTILE: '最大CPU 95百分位数',
      MAX_MEMORY_95TH_PERCENTILE: '最大内存95百分位数',
      MAX_NETWORK_95TH_PERCENTILE: '最大网路95百分位数',
      MY_ADVISORY_LIST: '我的建议清单',
      NO_ABNORMAL_INFO: '无异常检测',
      NO_RI_INFO: '无RI建议',
      NO_RIGHT_SIZING_INFO: '无型号更换建议',
      RI_ESTIMATED_BY_RECOMMENDED_SKU: '预估依建议SKU',
      RI_ESTIMATED_BY_FLEXIBILITY_GROUP: '预估依 {{ skuName }} 弹性类别为基准',
      RI_RECOMMEND_QUANITITY_NOTE: '建议数量是依建议SKU预估',
    },
    ADVISORY: {
      AVAILABILITY_ADVISORY: '可用性建议',
      CATEGORY: '类别',
      HIGH_IMPACT: '高度影响',
      IMPACT: '影响',
      IMPACTED_RESOURCES: '影响资源',
      LOW_IMPACT: '低度影响',
      MEDIUM_IMPACT: '中度影响',
      OPERATION_ADVISORY: '营运建议',
      PERFORMANCE_ADVISORY: '效能建议',
      RECOMMENDATIONS: '建议',
      SECURITY_ADVISORY: '安全性建议',
      STATE: '状态',
      TOTAL_RECOMMENDATIONS: '建议总数',
    },
    SEARCH: {
      ALERT_RECIPIENTS: '输入预警接收者邮箱',
      CHARACTER_TWO: '输入至少两个字元',
      COMPANY: '输入公司名称',
      DOMAIN: '输入网域名称',
      KEYWORDS: '输入关键字',
      OPERATION_NAME: '输入作业',
      PART_NUMBER: '输入品号',
      PRODUCT: '输入产品名称',
      PRODUCT_PART_NUMBER: '输入产品名称或品号',
      RESOURCE: '输入资源关键字',
      RESOURCE_FULL: '输入完整资源名称',
      RESOURCE_GROUP: '输入资源群组',
      RESOURCE_TAG: '输入标签键"Key=Value"',
      RESOURCE_TAG_VALUE: '输入标签值',
      RESULT_CODE: '输入结果代码',
      USER_ID: '输入用户识别码',
    },
    LOGIN: {
      LOGIN_IN: '登入',
      LOGIN_FAILED: '登入失败',
      PASSWORD: '用户密码',
      NEW_PASSWORD: '新密码',
      CONFIRM_PASSWORD: '确认密码',
      MFA_SIGN_IN: '使用 MFA 登入',
      SSO: '第三方登入',
      SIGNIN: '使用 {{ authority }} 登入',
      TITLE: '登入',
      USERID: '用户帐户/信箱',
      VALIDATOR: {
        CLASSES: '密码至少含有 {{ length }} 种以下组合: 小写字母, 大写字母, 数字, 特殊符号',
        LENGTH: '密码长度至少含有 {{ length }} 位字母',
        MATCH_EXIST_PASSWORD: '密码不可与现有的相同',
        PASSWD_MISMATCH: '密码不符合',
        USERID: '密码不可与ID相同',
      },
      MESSAGE: {
        PASSWD_REQUIRED: '需要密码',
        ID_REQUIRED: '需要 使用者ID/信箱',
        EMAIL_FORMAT: '信箱格式不正确',
      },
      MFA: {
        STEP_1: '下载两步骤验证软件',
        STEP_2: '扫描以下QR码',
        STEP_3: '输入产生的代码',
        APPS: '[ Microsoft Authenticator, Google Authenticator, Duo, Authy, etc ]',
        ENTER: '输入由您的验证软件产生的六位数数字',
        SETUP_KEY: '手动设置代码',
        REVEAL_KEY: '显示手动设置代码',
      },
    },
    THEME: {
      COLORMODE: {
        DARK: '暗色',
        LIGHT: '亮色',
      },
      MODE: {
        STATIC: '静态',
        OVERLAY: '覆盖',
        SLIM: '轻薄',
        HORIZONTAL: '水平',
      },
      SIZE: {
        COMPACT: '紧致',
        MATERIAL: '质感',
      },
      LABEL: {
        MENU: '菜单',
        SIZE: '大小',
        THEMES: '主题',
        MENU_MODES: '菜单模式',
        MENU_COLORS: '菜单颜色',
        MENU_SIZES: '设计',
      },
    },
    PAGE: {
      EMPTY: '无',
      NONE: '无',
      NO_RECORDS_FOUND: '查无数据',
      DATA_QUERY_EMPTY: '查无数据',
      DATA_QUERY: '数据查找',
      SHOW_DATA_FOR_LAST: '时间范围',
      SIGN_OUT: '登出',
    },
    SECURITY: {
      ACCESS_PERMISSIONS: '访问权限',
      ACCOUNT_IS_LOCKED: '帐户已锁定',
      ACCOUNT_LOCKOUT_DURATION: '帐户锁定时间',
      ACCOUNT_LOCKOUT_POLICY: '帐户锁定规范',
      ACCOUNT_LOCKOUT_THRESHOLD: '帐户登入错误门槛',
      ENFORCE_MFA: '强制多重要素验证',
      ENFORCE_SINGLE_SIGN_ON: '强制第三方登入 (SSO)',
      LOGIN_FAIL_RETENTION_DURATION: '限时内累计失败锁定',
      MFA_RESET_NOTE: '确定要将用户的多重要素验证重置?',
      MIN_PASSWORD_LENGTH: '最小密码长度',
      NO_ACCESS_PERMISSION_TO_TEXT: '您的用户帐户没有访问权限到',
      NO_PERMISSION: '无访问权限',
      PASSWORD_CAN_BE_MATCH_USER_ID: '帐户ID与密码可相同',
      PASSWORD_HAS_BEEN_RESET: '已重设密码',
      PASSWORD_NOTE: '复制用户识别码与临时密码以提供给用户。离开此页面之后，将无法重新检视此临时密码。',
      PASSWORD_POLICY: '密码规范',
      PASSWORD_RESET_NOTE: '将产生新密码，而且用户的目前密码将会无效。',
      PWD_CHANGE_AND_LOGIN: '更换密码并登入',
      SECURITY_POLICY_SETTINGS: '安全性原则设置',
      UNLOCK_ACCOUNT_NOTE_SUCCESSFULLY: '解锁帐户 「{{ userAccount }}」 成功',
      UNLOCK_ACCOUNT_NOTE: '确认要解锁帐户「{{ userAccount }}」?',
      UNLOCK_ACCOUNT: '解锁帐户',
      USAGE_ACCESS_CONTROL_ASSIGNMENTS: '存取限制指派',
      USAGE_ACCESS_CONTROL_LIST: '存取限制清单',
      POLICY_INFO: {
        ACCOUNT_LOCKOUT_DURATION: '帐户保持锁定时间(分钟)',
        ACCOUNT_LOCKOUT_THRESHOLD: '帐户登入失败门槛',
        LOGIN_FAIL_RETENTION_DURATION: '限时内(分钟)累计登入失败次数超过门槛次数,将再次锁定',
        MATCH_EXIST_PASSWORD: '密码不可与现有的相同',
        MFA_INFO: '启用多重要素验证',
        MIN_PASSWORD_LENGTH: '至少包含{{ Length }}个字符',
        NUMBER_OF_CHARACTER_CLASSES_MAX: '最多包含4个类别',
        NUMBER_OF_CHARACTER_CLASSES_MIN: '至少包含2个类别',
        NUMBER_OF_CHARACTER_CLASSES:
          '密码复杂度必须至少包含4个类别中的{{ Length }}个，例如[A-Z]、[a-z]、[0-9]、[@＃!?]',
        PASSWORD_CAN_BE_MATCH_USER_ID: '帐户ID与密码{{ Not }}可相同 (不区分字母大小写)',
        ORGANIZATION_POLICY_INFO: '组织管理安全性原则已设置，公司安全性原则无法再设置',
        OVERWRITE_ENROLLMENT_POLICY_NOTE: '覆写注册的安全性原则设置',
      },
    },
    POWERBI: {
      BALANCE_SUMMARY: '余额汇总',
      COST_BREAKDOWN: '费用拆解',
      COST_FORECAST: '费用预测',
      DAILY_USAGE_DETAIL: '每日用量细节',
      DAILY_USAGE_ANALYSIS: '每日用量分析',
      MONTHLY_USAGE_DETAIL: '每月用量细节',
      MONTHLY_USAGE_ANALYSIS: '每月用量分析',
      METERED_BILLING_ANALYSIS: '计量计费分析',
      OVERALL_COST_ANALYSIS: '总体费用分析',
      OVERALL_DATA_INFO: '数据基于最近30天',
      ONBOARD_ANALYSIS: '上线分析',
      ONBOARD_CONTACT: '上线联络资讯',
      ONBOARD_DETAIL: '上线细节',
      PRODUCT_COST_ANALYSIS: '产品费用分析',
      RESOURCE_COST_ANALYSIS: '资源费用分析',
      RI_OREDER: 'RI 订单',
      RI_OVERALL: 'RI 总体',
      RI_USAGE_ANALYSIS: 'RI 使用分析',
      SERVICE_COST_ANALYSIS: '服务费用分析',
      SAAS_METERING: 'SaaS计量',
      SAAS_METERING_ANALYSIS: 'SaaS计量分析',
      SAAS_SUBSCRIPTION_ANALYSIS: 'SaaS订阅分析',
      SAAS_USAGE: 'Saas使用量',
      SAAS_USAGE_DETAIL: 'Saas使用量明细',
    },
    DATA_TABLE: {
      NO_DATA: '无可用数据',
      LOADING: '载入中',
      LESS_THAN_10: '共 {{ totalRecords }} 项',
      ONE_RECORD: '共 1 项',
      PAGINATOR: '显示第 {{ first }} 至 {{ last }} 项结果，共 {{ totalRecords }} 项',
    },
    USAGE_BUDGET_INFO: {
      BEYOND_DATE_ALERT: '超出指定日期后仍有计费时发出预警',
      EXPIRATION_DATE: '预算停止评估并且不再发送警示的日期',
      MONTHLY_BUDGET_OVERAGE_ALERT: '当月计费额度超出当月预算时发出预警',
      THRESHOLD_1: '预算超出指定百分比时发出第一次预警',
      THRESHOLD_2: '预算超出指定百分比时发出第二次预警',
      THRESHOLD_3: '预算超出指定百分比时发出第三次预警',
      TOTAL_BUDGET_OVERAGE_ALERT: '当计费额度超出总预算时发出预警',
    },
    CUSTOMER_PROFILE: {
      ADDRESS_LINE_1: '地址行1',
      ADDRESS_LINE_2: '地址行2',
      AGREEMENT_DATE: '合同日期',
      ALLOW_CUSTOMER_TO_PURCHASE: '允许客户购买',
      BILL_TO_INFO: '账单接收方信息',
      COMPANY_INFO: '公司信息',
      COUNTRY_TOOLTIP: '你只能销售给您市场中的客户。若要在多个市场销售，你的组织必须向 Microsoft 注册多个帐号。',
      CREDIT_AMOUNT: '信用额度',
      CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER: '由合作伙伴确认的客户接受',
      CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT: '通过 Microsoft 的客户接受',
      CUSTOMER_ACCOUNT_INFO: '客户帐户信息',
      CUSTOMER_AGREEMENT_INFO: '客户合同信息',
      CUSTOMER_EMAIL: '客户电子邮件',
      CUSTOMER_PERMISSIONS: '客户权限',
      EMAIL_TOOLTIP: '客户的电子邮件网域将用于 OneVet EDU 资格追踪。',
      LINK_CORRECTING_EDUCATION_INFO: '了解有关更正教育信息的详细信息',
      LINK_CUSTOMER_VALIDATION_PROCESS: '详细了解教育客户验证流程',
      LINK_HOW_CONFIRM_AGREEMENT: '如何确认客户已接受 Microsoft 客户合约',
      LINK_HOW_DISPUTE_DECISION: '如何反对这一决策?',
      MANAGEMENT_INFO: '管理信息',
      NOT_PROVIDED: '未提供',
      POSTAL_CODE: '邮政编码',
      PROVIDED: '已提供',
      RESERVATIONS: '预订',
      SALES_AGENT: '销售代理人',
      SPECIAL_QUALIFICATIONS: '特殊资格',
      UPDATE_QUALIFICATION: '更新资格',
      UPDATE_AGREEMENT_INFO: '更新合同信息',
      COPY_AGREEMENT_URL: '复制合同网址',
      HINT_CANCEL_CONFIRM_HEADER: '确定要取消？',
      HINT_CANCEL_CONFIRM_MESSAGE: '尚未建立您添加的客户资料。',
    },
    USER_PROFILE: {
      HAS_BEEN_UPDATED: '用户资料已更新',
      LANGUAGE: '语言',
      LAST_LOGIN_INFO: '最近一次登入资讯',
      SUMMARY: '用户资料更新',
      TIME_ZONE: '时区',
      TITLE: '用户资料',
      USER_ID: '用户识别码',
      USER_NAME: '用户名称',
      PASSWD: {
        CHANGE_PASSWD: '更换密码',
        CONFIRM_NEW_PASSWD: '请确认新密码',
        CONFIRM_PASSWD: '确认密码',
        CONFIRM_REQUIRED: '需要确认新密码',
        CURRENT_PASSWD: '目前密码',
        CURRENT_REQUIRED: '需要目前密码',
        ENTER_CURRENT_PASSWD: '请输入目前密码',
        NEW_PASSWD: '新密码',
        NEW_REQUIRED: '需要输入新密码',
        NEW_SAME_AS_CURRENT: '新密码不可与目前相同',
        PASSWD_MISMATCH: '确认密码与新密码不相符',
      },
    },
    USAGE_COMMON_FILTER: {
      ACCESS_CONTROL_RESTRICTIONS: '费用查找存取限制',
      HIERARCHY: '费用阶层图',
      IMAGE_LINK: 'assets/images/usage_filter/usage_filter_hierarchy_zh-hans.png',
      IMAGE_LINK_HACKER: 'assets/images/usage_filter/usage_filter_hierarchy_hacker_zh-hans.png',
      QUERY_PRODUCT: '产品查询结果受限于您当前选择的部门、帐户、订阅及服务',
      QUERY_RESOURCE_GROUP: '资源群组查询结果受限于您当前选择的部门、帐户及订阅',
      QUERY_TAG: '标签查询结果受限于您当前选择的部门、帐户、订阅及资源群组',
      RESTRICTIONS_HEADER: '您的管理员为您设定了以下限制',
      UNRESTRICTED: '未受限',
    },
    EA_OB_PAGE: {
      AUTHORIZATION_CONFIG: '授权设定',
      CLOUD_TENANT_ADMIN_AUTHORIZATION: '云端租户管理者授权',
      EA_ENROLLMENT_NUM: '企业注册号码',
      EA_NAME: '企业名称',
      INIT_SYNC_TIME: '开始数据同步时间',
      LAST_RM_SYNC_TIME: '最后资源资料同步时间',
      NOTICE: '您可以离开此页面并随时返回以检查数据同步状态。',
      OB_INVITATION: '上线邀请',
      OB_PROGRESS: '上线进展',
      OVERALL_OB_PROGRESS: '总体上线进展',
      PRICE_SHEET_IMPORT_PROGRESS: '价格表同步进展',
      USAGE_IMPORT_PROGRESS: '费用数据同步进展',
      VALID_SUBSCRIPTION: '有效订阅',
    },
    ERRROR_HANDLER: {
      HTTP_ERROR_SUMMARY: '数据请求',
    },
    FORECAST: {
      FORECAST_MODEL: '预测模组',
      FORECAST: '预测',
      ACTUAL_COST: '实际',
      DIFF_COST: '差异',
      FORECAST_COST: '预测',
      PREDICT_COST: '预报',
    },
    FEATURE_PACK: {
      ASSIGN: '分配功能组',
      DETAIL: '功能组明细',
      EDIT: '编辑功能组',
      LIST: '功能组清单',
      NEW_FEATURE_PACK_NAME: '新功能组名称',
      SELECTED_LIST: '选择清单',
      ENROLLMENT_FEATURE: {
        LIST: '企业功能组清单',
        DETAIL: '企业功能组详细信息',
        ACCESS: '企业功能组存取',
        ASSIGNED_LIST: '指派的功能组清单',
      },
    },
    USER_GROUP: {
      NO_ASSIGNED_CUSTOMERS: '没有指派的客户',
      NO_ASSIGNED_RESELLERS: '没有指派的经销商',
      NO_CUSTOMERS: '没有客户',
      NO_RESELLERS: '没有经销商',
      NO_USERS: '没有用户',
      NO_USER_GROUPS: '没有用户群组',
    },
    PRICING_CONFIG: {
      BASELINE_MARKUP_RULE: '成本加成规则',
      BASELINE_MARKUP_RULE_NOTE: '没有折扣率及SKU规则时适用',
      BY_DISCOUNT_PERCENT_RULES: '按折扣率规则',
      BY_SKU_RULES: '按SKU规则',
      CATEGORY: '类别',
      CUSTOMER_DISCOUNT_PERCENT: '客户折扣%',
      CUSTOMER_LIST: '客户清单',
      DISTRIBUTOR_DISCOUNT_PERCENT: '分销商折扣%',
      MARKUP: '加成',
      MARKUP_OF_COST_PRICE: '成本价加成',
      NO_DISCOUNT_PERCENT_RULES: '没有折扣率规则',
      NO_SKU_RULES: '没有SKU规则',
      PARTNER_DISCOUNT_PERCENT: '合作伙伴折扣%',
      PARTNER_EARNED_CREDIT_SHARING_PERCENTAGE_FOR_RESELLER: 'Partner Earned Credit 经销商分享百分比',
      PEC_OF_LIST_PRICE: '% 标价',
      PEC_PECENT_FOR_RESELLER: '经销商PEC% ',
      PERCENTAGE_OF_COST_PRICE: '成本折扣%',
      PERCENTAGE_OF_LIST_PRICE: '标价折扣%',
      PRICING_CONFIG_NAME: '定价配置名称',
      RESELLER_DISCOUNT_PERCENT: '经销商折扣%',
      RESELLER_LIST: '经销商清单',
      SELECT_A_DISCOUNT_PERCENT: '选择一个折扣率',
    },
    SALES_ORDERS: {
      CREATE_INVOICE: '创建发票',
      CUSTOMER_VIEW: '客户视角',
      EDIT_LINE_ITEM: '编辑项目',
      ON_DEMAND_INVOICE: '经请求发票',
      INVOICE_CREATED: '发票已创建',
      LINE_ITEM: {
        NEW: '新项目',
        ORDERED: '下单完成',
        ORDER_FAILED: '下单失败',
        VOID: '作废',
      },
      ORDER: {
        NEW: '新订单',
        APPROVED: '已核准',
        PARTIAL_ORDERED: '部分完成',
        VOID: '作废',
      },
      ORDER_LINE_ITEMS: '订单项目',
      PARTNER_VIEW: '合作伙伴视角',
      PLACE_ORDER: '下订单',
      PLACING_ORDER: '下单中',
      RESELLER_VIEW: '经销商视角',
      SALES_ORDER_DETAIL: '销售订单细节',
      VIEW_INVOICE: '检视发票',
    },
    SAAS_SUBSCRIPTION: {
      AUTO_RENEW: '自动更新',
      BENEFICIARY_EMAIL: '受益者邮件 ',
      CHANGE_QTY: '变更数量',
      IS_FREE_TRIAL: '试用版',
      IS_TEST: '测试版',
      ONBOARDING: '上线中',
      ONBOARD_PROCESS: '审核进度',
      PURCHASE_EMAIL: '购买者邮件',
      SELECT_A_PLAN: '选择方案',
      SUBSCRIPTION_STATUS: '订阅状态',
    },
    CONTACT: {
      ADMIN: '行政',
      BILLING: '帐单',
      TECHNICAL: '技术',
    },
    PREREQUISITES: {
      ADMIN_USERS_ASSIGNED: '管理者指派',
      BILLING_PROFILE_PROVIDED: '帐单资料',
      COMPANY_PROFILE_PROVIDED: '公司资料',
      INDIRECT_RESELLER_ACTIVE: '间接经销商状态',
      MANAGEMENT_GROUP_ASSIGNED: '管理群组指派',
      MICROSOFT_PARTNER_AGREEMENT_SIGNED: 'Microsoft合作伙伴合约签约',
      PRICING_CONFIG_ASSIGNED: '定价配置指派',
      RESELLER_AGENT_ASSIGNED: '经销商管理员指派',
      TAX_INFO_PROVIDED: '税务资讯',
    },
    RI_RECOMM_REPORT: {
      FILTERED_BY_FLEXIBILITY: '依弹性群组筛选',
      ONE_Y: '1年',
      ONE_YEAR_RI: '1年RI',
      ONE_YEAR_RI_RECOMM: '1年RI推荐',
      ONE_YEAR_RI_YEARLY_SAVING: '1年RI每年可节省',
      ONE_Y_RI_RECOMM: '1年RI推荐',
      RI_RECOMMENDATION_ANALYSIS: 'RI建议分析',
      SKU_REGION_PRICE: 'SKU区域价格',
      SKU_REGION_PRICE_COMPARISON: 'SKU区域价格比较',
      THREE_Y: '3年',
      THREE_YEAR_RI: '3年RI',
      THREE_YEAR_RI_RECOMM: '3年RI推荐',
      THREE_YEAR_RI_YEARLY_SAVING: '3年RI每年可节省',
      THREE_Y_RI_RECOMM: '3年RI推荐',
      TOTAL_CURRENT_COST: '当前费用总计',
      TOTAL_NEW_COST: '新费用总计',
      TOTAL_RI_PRICE: 'RI价格总计',
      TOTAL_SAVING_YEARLY: '每年可节省费用总计',
      YEARLY_SAVINGS_RANGE: '每年可节省费用范围',
    },
    CUSTOM_DIMENSION: {
      ADD_DIMENSION_VALUE_MAPPING_RULE: '新增维度对应规则',
      ADD_TOOLTIP: '新增单一维度对应规则',
      BUILD_DIMENSION_HINT: '开始建立后将在后端生成维度资料，可能需要几分钟到几小时才能完成，请确保您已完成维度设计。',
      CHANGE_DIMENSION_NAME: '修改维度名称（适用于所有同名维度）',
      CHANGE_DIMENSION_NAME_WARNING: '您不得更改为已使用的名称',
      COST_CATEGORY_KEY_ALL: '将所有成本类别值对应到一个显示值',
      COST_CATEGORY_KEY_ANY: '将成本类别值复制到显示值',
      COST_CATEGORY_VALUE_DISABLE_WARNING: '基于性能考量，我们不支持超过500个值的成本类别键',
      COST_CATEGORY_VALUE_MAX_WARNING: '此成本类别键 {{ tagKey }} 有 {{ tagValueCount }} 个值',
      DUPLICATED_DIMENSION_NAME_WARNING: '您不得新增已使用的名称',
      EDIT_DIMENSION_AND_DISPLAY_VALUE: '编辑维度 & 显示值',
      IMPORT_DIMENSION_VALUE_MAPPING_RULE: '汇入维度对应规则',
      IMPORT_TOOLTIP: '汇入多个维度对应规则',
      LABEL_KEY_ALL: '将所有标签值对应到一个显示值',
      LABEL_KEY_ANY: '将标签值复制到显示值',
      LABEL_VALUE_DISABLE_WARNING: '基于性能考量，我们不支持超过500个标签值的标签类别',
      LABEL_VALUE_MAX_WARNING: '此标签类别 {{ tagKey }} 有 {{ tagValueCount }} 个标签值',
      MAX_DIMENSIONS_HINT: '您最多可以有 (5) 个维度',
      TAG_KEY_ALL: '将所有标签值对应到一个显示值',
      TAG_KEY_ANY: '将标签值复制到显示值',
      TAG_VALUE_DISABLE_WARNING: '基于性能考量，我们不支持超过500个标签值的标签类别',
      TAG_VALUE_MAX_WARNING: '此标签类别 {{ tagKey }} 有 {{ tagValueCount }} 个标签值',
    },
    COST_DASHBOARD: {
      CHART_HINT: '1.每周从星期一开始 2.资料中的最后一周为当周',
      CLOUD_COST_OVER_MONTHS: '月云端费用',
      CLOUD_COST_OVER_WEEKS: '周云端费用',
      COST_ANALYSIS_BY_SERVICE_CATEGORY: '服务类别成本分析',
      CURRENT_MONTH: '当月',
      OF_CURRENT_MONTH: '当月费用',
      SWITCH_LINE_CHART: '切换折线图',
      SWITCH_STACKED_BAR_CHART: '切换堆栈条形图',
      TOP_10_REGION: '前 10 名区域',
    },
    PROVISION: {
      APPROVAL_STATUS: '审批状态',
      CONFIGRATION_DETAIL: '配置细项',
      COST_DETAILS: '费用细项',
      COST_RANGE: '费用范围',
      DESTROY_STATUS: '移除状态',
      DURATION_TIME: '持续时间',
      ESIMATED_COST_MONTH: '估计成本月',
      PLAN_STATUS: '方案状态',
      PROVISION_ITEM: '预配项目',
      PROVISION_RESULT: '预配结果',
      PROVISION_STATUS: '预配状态',
      VIEW_DETAILS: '查看细项',
    },
    VM_PROVISION: {
      ACCOUNT_DETAIL: '账户详细资料',
      ACCOUNT_DETAIL_INFO: '选择帐户以管理已部署的资源和成本。',
      ADMINISTRATION_ACCOUNT: 'Administration 帐户',
      AUTHENTICATION_TYPE: '认证类型',
      BASIC: '基本',
      CREATE_NEW: '创建新的',
      CUSTOM_DURATION: '自定义期限',
      DATE_RANGE_ERROR: '所选日期时间已过期。',
      DELETE_WITH_VM: '使用 VM 删除',
      DISK_OPTIONS: '磁盘选项',
      DUPLICATE_NAME: '此名称已存在。',
      DURATION_INFO: '预设时间将从起始时间开始运行，并且默认无终止期限。',
      ESTIMATED_COST: '预估费用',
      EXISTING: '现有的',
      FROM_DATE_TIME: '起始时间',
      GCP_VIRTUAL_MACHINE_FORMAT_INFO: '名称必须由小写字母 (a-z)、数字和连字符 (-) 组成。',
      IMAGE: '映像',
      INSTANCE_DETAILS: '执行个体详细资料',
      LINUX_VM_INFO: 'Linux VM 名称只能包含字母、数字、"." 和 "-"。',
      MANAGED_DISKS: '托管磁盘',
      NETWORK_SERVICE: '网络服务',
      NO_LIMIT: '无期限',
      OS_DISK_DELETE_ON_TERMINATION: '在虚拟机终止时同时删除',
      OS_DISK_SIZE: 'OS 磁盘容量',
      OS_DISK_TYPE: 'OS 磁盘类型',
      OS_DISK_VOLUME_SIZE_RANGE_INFO: '容量范围 {{ minSize }} ~ {{ maxSize }} GiB.',
      OS_IMAGE: '映像',
      PASSWORD_CONFIRM_INFO: '密码和确认密码必须匹配。',
      PASSWORD_FORMAT_INFO: '密码必须要有下列项目中的 3 项: 1 个小写字元、1 个大写字元、1 个数字与 1 个特殊字元。',
      PROJECT_DETAILS: '专案详细资料',
      PROJECT_INFO: '选取用以管理部署资源及成本的订用帐户。使用像资料夹这样的资源群组来安排及管理您的所有资源。',
      PROVISION_DURATION: '供应期限',
      PUBLIC_IP: '公用 IP',
      REGION_ZONE: '特定区域',
      RESOURCE_GROUP_FORMAT_INFO: '资源群组名称仅允许使用字母数字字符、句点、下划线、连字符和括号，并且不能以句点结尾。',
      RESOURCE_GROUP_NAME: '资源群组名称',
      SSH_PUBLIC_KEY: 'SSH 公开金钥',
      SSH_REGEXP_INFO: "多行的 SSH 金钥必须以 '---- BEGIN SSH2 PUBLIC KEY ----' 开头且以 '---- END SSH2 PUBLIC KEY ----' 结尾。",
      STANDARD: '标准',
      SUBSCRIPTION_NO_SKU_INFO: '此容量目前在{{ region }}中无法用于此订阅: 不可用于订阅。',
      TO_DATE_TIME: '截止时间',
      USER_NAME_FORMAT_INFO: '用户名称只能包含字母、数字、连字符和下划线，并且不能以连字符或数字开头。',
      VALIDATOR_LENGTH_INFO: '此值的长度必须介于 {{ minLength }} 和 {{ maxLength }} 个字元之间。',
      VIRTUAL_MACHINE_FORMAT_INFO: 'Azure 资源名称不能包含特殊字符 \\/""[]:|<>+=;,?*@& 和空格，不能以 "_" 开头，且不能以 "." 或 "-" 结尾。',
      VIRTUAL_MACHINE_INSTANCE: '虚拟机器执行个体',
      VIRTUAL_MACHINE_NAME: '虚拟机器名称',
      WINDOWS_VM_INFO: 'Windows VM 名称不得包含 "_" 或 "."。',
    },
    USER_ACCESS: {
      ROLE_ASSIGNMENTS: '角色分配',
      ROOT_SCOPE_ROLE: '此用户的根范围角色',
      ROOT_SCOPE_ROLE_HINT: '此用户被分配到的根范围角色为 {{ selectedUserRootRule }}，下方表格仅显示额外的访问权限。',
      SCOPE_HINT: '范围阶层包括以下 4 个级别：管理群组、订阅、资源群组、资源',
    },
    RESOURCE_SCHEDULE: {
      ATTACH_SCHEDULE_NOTE: '确定要绑定排程至资源{{ resource }}?',
      ATTACH_SCHEDULE: '绑定排程',
      CHANGE_SCHEDULE: '更改排程',
      CREATE_RESOURCE_SCHEDULE: '新建资源排程',
      DELETE_SCHEDULE_NOTE: "确定要删除选取的排程'{{ scheduleName }}'?",
      DETACH_SCHEDULE_NOTE: '确定要解绑资源{{ resource }}的排程?',
      DETACH_SCHEDULE: '排程解绑',
      EDIT_SCHEDULE: '编辑排程',
      EXISTED_NAME_ALERT: '已有同名的排程存在',
      RESET_SCHEDULE: '重置排程',
      RESOURCE_SCHEDULE: '排程',
      SAVE_AS_NEW_SCHEDULE: '另存为新排程',
      SCHEDULE_NAME: '排程名称',
      SCHEDULE_RESOLUTION: '时间间隔',
      SCHEDULED_TIME: '排程时间',
      UPDATE_SCHEDULE_NOTE: '确定要更新选取的排程?',
    },
    RESOURCE_PLAN: {
      ACTUAL: '实际',
      ACTUAL_COST: '实际金额',
      ADD_THRESHOLD: '新增域值',
      ALERT_CONDITION: '警示条件',
      ALERT_RECIPIENTS: '警示收件者 (电子邮件)',
      AMORTIZED_COST: '摊销金额',
      AMOUNT: '金额',
      BEYOND_DATE: '指定日期预警日期',
      BUDGET_AMOUNT: '预算金额',
      BUDGET_HISTORY: '历史预算',
      BUDGET_OWNER: '建立者',
      COPY_FROM: '复制现有计划',
      CREATION_DATE: '建立日期',
      CURRENT_SPEND: '目前金额',
      DATA_CALCULATED_DATE: '资料计算至',
      EFFECTIVE_DATE: '生效日期',
      EXPIRATION_DATE: '失效日期',
      FORECAST: '预测',
      GIVE_THRESHOLD: '提供您的预算金额阈值',
      GO_USAGE_QUERY: '前往费用查询',
      HEADER: '名称',
      HIDE_EXPIRED_PLAN: '隐藏已失效计划',
      HISTORICAL_FUTURE: '过去金额及预测金额',
      MY_PLAN_ONLY: '仅显示我的计划',
      NEW_PLAN: '新计划',
      NEW_RESOURCE_PLAN: '新资源计划',
      OVERAGE_THRESHOLD: '超额预警',
      OWNER: '建立者',
      RESET: '重置',
      RESET_PERIOD: '重设周期',
      RESOURCE_PLAN_LIST: '资源计划列表',
      SUGGESTED_BUDGET: '建议预算： ${{ forecastAmount }} （根据预测）',
      THRESHOLD: '域值',
      TIME_PERIOD: '周期',
      TITLE: '资源规划',
      TRIGGER_ON: '触发警示依据',
    },
    DROPCELL: {
      ORPHAN: '无主资源数量',
    },
    ORPHAN: {
      TITLE: '无主资源',
      RESOURCE_PERCENT_IN_COST: '无主资源占总支出比例',
    },
    XCLOUD: {
      POTENTIAL_WASTE_30D: '30天潜在优化空间',
      OPERATION_HEALTHINESS_30D: '30天操作健康度',
    },
    UNUSED_LICENSE: {
      ACTIVATION_DATE: '启用日期',
      ACTIVITY_DATE: '活动日期',
      ASSIGNED_BY: '指派者',
      ASSIGNED_TIME: '指派时间',
      FIELDS_NOT_SUPPORTED: '本资源不支援以下栏位：',
      INACTIVE_30_DAYS: '30天未活动授权',
      NOT_INSTALLED_LICENSE: '未安装授权',
      NUMBER_OF_LICENSE: '授权数量',
      NUMBER_OF_LICENSE_TYPES: '授权种类数量',
      NUMBER_OF_UNUSED_LICENSES: '未使用授权数',
      POTENTIAL_WASTE_PER_MONTH: '每月潜在浪费',
      PRODUCT_SERVICE_PLANS: '产品服务方案',
      TITLE: '未使用授权',
      TOTAL_UNITS: '总单位数',
      UNASSIGNED_LICENSE: '未指派授权',
      UNUSED_LICENSE_COST_PERCENTAGE: '未使用授权费用 %',
      UNUSED_NUMBER: '未使用数量',
      USER_SERVICE_PLANS: '使用者服务方案',
    },
    NVA: {
      AFFECTED_SOFTWARE: '受影响软件/作业系统',
      DETECTION_METHOD: '检测方法',
      DETECTION_RESULT: '检测结果',
      HIGH_SEVERITY: '高严重性',
      IMPACTED_HOSTS: '受影响主机',
      INSIGHT: '深入解析',
      LOW_SEVERITY: '低严重性',
      MEDIUM_SEVERITY: '中严重性',
      REFERENCES: '参考',
      SUMMARY: '概要',
      TOTAL_VULNERABILITIES: '总弱点数',
      VULNERABILITY: '弱点',
    },
    CLOUD_SEC: {
      ACTIVATED_SINCE: '激活自 {{ N }}',
      ADD_TARGET: '增加目标',
      AGE: '存活时间',
      ALIAS: '别名',
      ALTERNATIVES: '其他修正步骤',
      ATTACK_COMPLEXITY: '攻击复杂性',
      ATTACK_VECTOR: '攻击向量',
      AVAILABLE_IMPACT: '可用性',
      AVG_SCORE_TOP_5: '前五名漏洞的平均分数',
      CLASSIFICATION: '类别',
      CLICK_TO_UPLOAD: '点此上传档案。',
      CONFIDENTIALITY_IMPACT: '保密性',
      CONTACT_PERSON: '业务窗口',
      CRITICAL: '严重',
      CUSTOMER_NAME: '客户名称',
      CUSTOMER_NUMBER: '客户编号',
      CVSS_METRICS: 'CVSS 度量',
      CVSS_SCORE: 'CVSS 分数',
      DELETE_DOMAIN: '本动作无法回复\n请问您是否确定要删除本域名？',
      DELETE_MESSAGE: '本动作无法回复，并且会删除本主机相关目标之扫描。',
      DESCRIPTION: '叙述',
      DETAILED_GUIDE: '详细指导',
      DISTRIBUTION_CVE_YEAR: 'CVE年度分布',
      DOMAIN_EXIST: '域名已存在',
      ENTER_DOMAIN: '请输入域名',
      ENTRY_BY: '输入者',
      ENTRY_DATE: '输入日期',
      EVIDENCE: '证据',
      EXECUTIVE_SUMMARY: '执行摘要',
      FILE_TYPE: '档案类型',
      FIRST_DETECTED: '初次侦测',
      FIRST_SCAN_DATE: '第一次扫描日期',
      FIRST_SCAN_N_RESOLUTIONS: '在首次扫描中找到的{{N}}个弱点解决状况',
      FIRST_SCAN: '第一次扫描',
      FIX_ANALYSIS_REPORT: '已修复分析报告',
      FIX_PROCEDURE: '修正步骤',
      FIX_TYPE: '修正种类',
      FIXED_THIS_MONTH: '本月修复',
      FIXED_TIMELINE: '修复时间轴',
      FIXED_VUL_LAST_30D: '过去30天修复漏洞',
      GENERAL_INFO: '一般资讯',
      HEALTH_SCORE: '健康分数',
      HIGH: '高',
      HOST_SEVERITY_DISTRIBUTION: '主机严重度分布',
      HOW_TO_CREATE: '如何建立？',
      HOW_TO_FIX: '如何修复',
      IMPORTED_BY: '汇入者',
      INDUSTRY_RANK: '行业排行',
      INTEGRITY_IMPACT: '正值',
      KEY_VUL: '关键弱点',
      LAST_DETECTED: '最后侦测',
      LAST_RESCAN: '最后复扫',
      LAST_SCAN_DATE: '前次扫描日期',
      LAST_SCAN: '前次扫描',
      LATEST_VUL: '近期漏洞',
      LOG_RESOLUTION: '解决历程',
      LOW: '低',
      MANAGE_SUBSCRIPTION: '订阅管理',
      MEDIUM: '中',
      NEW_THIS_MONTH: '本月新增',
      NEW_VUL_LAST_30D: '过去30天新增漏洞',
      NEW: '新',
      NOTE: '记事',
      NUM_OF_VUL: '漏洞数量',
      OPEN_VUL: '未解决漏洞',
      OVERALL_RESOLVED_VUL: '已解决弱点总览',
      OWASP_TOP_10: 'OWASP前10',
      OWNER_ASSIGN: '主机负责人指派',
      PERCENTILE_RANK: '百分等级',
      PLAN_EXPIRE: '您的计划将于 {{ N }} 到期',
      PREFER_SCAN_TIME: '偏好扫描时间',
      PRIVILEDGE_EVIDENCE: '所需许可权',
      PUBLISHED: '发表时间',
      REFERENCE: '参考资讯',
      REMEDIATION_ADVICE: '修复建议',
      REPORT_DATE: '报告生成日',
      REPORT_DOWNLOAD: '下载报告',
      REPORT_GEN_DATE: '报告产生日期',
      REPORT: '报告',
      RESCAN: '复扫',
      RESOLVED: '已解决',
      RISK_INFO: '风险资讯',
      RISK: '风险',
      SCAN_DURATION: '扫描期间',
      SCAN_END_TIME: '扫描结束时间',
      SCAN_FINDINGS: '扫描发现',
      SCAN_REPORTS: '扫描报告',
      SCAN_RESULTS: '扫描结果',
      SCAN_START_TIME: '扫描开始时间',
      SCAN_TARGET: '扫描标的',
      SCAN_TEMPLATE: '扫描样板',
      SCAN_TIME: '扫描时间',
      SCAN_TOOLS: '扫描工具',
      SCANNER: '扫描器',
      SCANS: '扫描',
      SCORE_LAST_30D: '30天前分数',
      SECOND_SCAN_DATE: '第二次扫描日期',
      SECOND_SCAN_N_NEW: '第二次扫描中发现的{{N}}个新弱点',
      SECOND_SCAN: '第二次扫描',
      SELECT_CRITERIA: '选择您的条件',
      SEVERITY_DISTRIBUTION: '严重度分布',
      SEVERITY_INDEX: '严重性指标',
      SEVERITY: '严重度',
      SOLUTION: '解决方案',
      SUBDOMAIN_NOTIFICATION: '这个过程可能需要至少10分钟',
      SUBDOMAIN_TITLE: '主网域及已知子网域',
      SUBDOMAIN: '子域名',
      SUBSCRIPTION_TIME: '订阅时间',
      TIMELINE: '时间轴',
      TOTAL_FINDING: '总发现',
      TOTAL_HOSTS: '总主机数',
      TOTAL_VUL: '总弱点',
      UNRESOLVED: '未解决',
      UPDATED: '更新时间',
      USER_INTERACTION: '使用者交互',
      VPN_PROFILE: 'VPN设定档',
      VUL_COUNT: '弱点数量',
      VUL_INFO: '漏洞资讯',
      VUL_LAST_30D: '过去30天漏洞',
      VUL_OVERVIEW: '弱点概观',
      VUL_RESOLUTION_REPORT: '弱点处理报告',
      VUL_TREND: '弱点趋势',
      VUL: '弱点',
      WEBSITE_COUNT: '网站数量',
    },
    BALANCE_SUMMARY: {
      FORECAST_HISTORY: '根据过去三个月的平均花费预测图',
      FORECAST_RECOMMEND: '建议花费 ${{N}}/月 预测图',
      FORECAST_END_BALANCE: '合约到期余额',
      MEET_BALANCE: '金额用罄时间',
      AVG_COST: '平均花费',
      TOTAL_PURCHASE: '总购入'
    },
    VRM: {
      CREATE_QUESTIONNAIRE: '创建自定义调查表',
      PUBLISHED_SCANS: '已发佈扫描总数',
      QUESTIONNAIRE_TITLE: '问卷库',
      SCAN_STATUS: '扫描状态',
      TOTAL_VENDORS: '供应商总数',
      VENDOR_COUNT: '供应商数量',
      MEDIAN_SECURITY_RATING: '安全等级中位数',
      SECURITY_RATING_DISTRIBUTION: '安全等级分布',
      VENDOR_BREAKDOWN: '供应商分类',
      QUESTIONNAIRE: '问卷',
      QUESTIONNAIRES: '问卷',
      QUESTIONNAIRE_AVERAGE_SCORE: '问卷平均分数',
      SECURITY_RATING_TREND: '安全等级趋势',
      VENDOR_SCORE: '供应商分数',
      SEND_QUESTIONNAIRE: '传送问卷',
      SELECT_SCOPE: '选择范围',
      SELECT_SCHEDULE: '选择排程',
      TITLE_MESSAGE: '标题 & 讯息',
      REVIEW_SEND: '审查 & 送出',
      DUE_DATE: '到期日',
      SELECT_PROGRAM_VENDOR: '选择计划&供应商',
      SET_DUE_DATE: '选择到期日',
      ADD_TITLE: '加入标题',
      ADD_MESSAGE: '加入讯息',
      SELECT_SCHEDULE_INFO: '让供应商知道问卷需要在甚么时间完成，您可以选择设定提醒来重新寄送问卷。',
      SET_DUE_DATE_INFO: '设定问卷到期日。',
      TITLE_MESSAGE_INFO: '收件人收到问卷时，将会看到您设定的标题与讯息。',
      ADD_TITLE_INFO: '收件人将会看到您设定的标题。',
      ADD_MESSAGE_INFO: '您设定的讯息内容将会显示于收件人收到的信件以及问卷本身。',
      REVIEW_SEND_INFO: '请在送出前仔细检查。'
    },
    STAT: {
      COMPLETED: '完成',
      IDLE: '闲置',
      INCOMPLETE: '未完成',
      IN_PROGRESS: '进行中',
      NOT_START: '未开始',
    },
    AUTH_SCAN: {
      AUTOMATIC: '自动',
      AUTH_RESULT: '验证结果',
      CHECK_AUTH: '检查验证',
      CHOOSE: '选择',
      COOKIE_HEADER: 'Cookie Header',
      COOKIES: 'Cookies',
      ENABLE_AUTH: '启动验证',
      RECORDED: '录制',
      UPLOAD_FILE: '将您的档案上传至此。',
      UPLOADED: '已上传。',
      START_SCAN: '开始扫描'
    },
    YSL: {
      TASK_NAME: '作业名称',
      RUNS: '运行',
      EACH_SCAN: '个别扫描',
    },
    LLM_AGENT: {
      MESSAGE: {
        TARGET_REQUIRE: '目标为必填。',
        GOAL_REQUIRE: '目的为必填。',
      },
      // CONFIG_PENTEST_TASK:'',
      CONNECTING: '连线中',
      CONNECTED: '已连线',
      DISCONNECTED: '未连线',
    },
    CVE: {
      CVE_LIST: 'CVEs 列表',
      CVE_MESSAGE: '这是YODA侦测到的弱点列表。我们以多种工具（Network Scanner, Website Scanner...等）侦测到超过{{ N }}个的弱点。'
    }
  },
};
