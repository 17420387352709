// Primeng
import { SelectItem } from 'primeng/api';
// Store
import { LayoutConfig } from '@Reducers';
// Interface
import { ColorScheme, ColorSchemeLabel, DocumentLangType, InputStyles, LayoutScale, MenuMode, MenuModeLabel, ThemeComponentColor, ThemeMenuColor, ThemeTopbarColor } from '@core/enum';

export const LayoutThemeScales: LayoutScaleType[] = [10, 12, 13, 14, 16, 18, 20];

const InputStyleList: InputStyleItem[] = [
  { label: InputStyles.OUTLINED, value: InputStyles.OUTLINED },
  { label: InputStyles.FILLED, value: InputStyles.FILLED },
];

const ThemeOrientationList: ThemeOrientationItem[] = [
  { label: MenuMode.DRAWER, value: MenuModeLabel.DRAWER },
  { label: MenuMode.HORIZONTAL, value: MenuModeLabel.HORIZONTAL },
  { label: MenuMode.OVERLAY, value: MenuModeLabel.OVERLAY },
  { label: MenuMode.REVEAL, value: MenuModeLabel.REVEAL },
  { label: MenuMode.SLIM, value: MenuModeLabel.SLIM },
  { label: MenuMode.SLIMPLUS, value: MenuModeLabel.SLIMPLUS },
  { label: MenuMode.STATIC, value: MenuModeLabel.STATIC },
];

const ThemeColorSchemeList: ColorSchemeItem[] = [
  { label: ColorScheme.LIGHT, value: ColorSchemeLabel.LIGHT },
  { label: ColorScheme.DARK, value: ColorSchemeLabel.DARK },
];

export const ThemeMenu: ThemeMenuColorItem[] = [
  { label: ThemeMenuColor.LIGHT, color: '#FDFEFF' },
  { label: ThemeMenuColor.DARK, color: '#434B54' },
  { label: ThemeMenuColor.INDIGO, color: '#1A237E' },
  { label: ThemeMenuColor.BLUEGREY, color: '#37474F' },
  { label: ThemeMenuColor.BROWN, color: '#4E342E' },
  { label: ThemeMenuColor.CYAN, color: '#006064' },
  { label: ThemeMenuColor.GREEN, color: '#2E7D32' },
  { label: ThemeMenuColor.DEEPPURPLE, color: '#4527A0' },
  { label: ThemeMenuColor.DEEPORANGE, color: '#BF360C' },
  { label: ThemeMenuColor.PINK, color: '#880E4F' },
  { label: ThemeMenuColor.PURPLE, color: '#6A1B9A' },
  { label: ThemeMenuColor.TEAL, color: '#00695C' },
  // { label: ThemeMenuColor.TEST, color: '#6666FF' },
];

export const ThemeTopbar: ThemeTopbarColorItem[] = [
  { label: ThemeTopbarColor.BLUE, color: '#1565C0' },
  { label: ThemeTopbarColor.LIGHTBLUE, color: '#2E88FF' },
  { label: ThemeTopbarColor.DARK, color: '#363636' },
  { label: ThemeTopbarColor.WHITE, color: '#FDFEFF' },
  { label: ThemeTopbarColor.DEEPPURPLE, color: '#4527A0' },
  { label: ThemeTopbarColor.PURPLE, color: '#6A1B9A' },
  { label: ThemeTopbarColor.PINK, color: '#AD1457' },
  { label: ThemeTopbarColor.CYAN, color: '#0097A7' },
  { label: ThemeTopbarColor.TEAL, color: '#00796B' },
  { label: ThemeTopbarColor.GREEN, color: '#43A047' },
  { label: ThemeTopbarColor.LIGHTGREEN, color: '#689F38' },
  { label: ThemeTopbarColor.LIME, color: '#AFB42B' },
  { label: ThemeTopbarColor.YELLOW, color: '#FBC02D' },
  { label: ThemeTopbarColor.AMBER, color: '#FFA000' },
  { label: ThemeTopbarColor.ORANGE, color: '#FB8C00' },
  { label: ThemeTopbarColor.DEEPORANGE, color: '#D84315' },
  { label: ThemeTopbarColor.BROWN, color: '#5D4037' },
  { label: ThemeTopbarColor.GREY, color: '#616161' },
  { label: ThemeTopbarColor.BLUEGREY, color: '#546E7A' },
  { label: ThemeTopbarColor.INDIGO, color: '#3F51B5' },
  // { label: ThemeTopbarColor.TEST, color: '#6666FF' },
];

export const ThemeComponent: ThemeComponentColorItem[] = [
  { label: ThemeComponentColor.INDIGO, color: '#3F51B5' },
  { label: ThemeComponentColor.PINK, color: '#E91E63' },
  { label: ThemeComponentColor.PURPLE, color: '#9C27B0' },
  { label: ThemeComponentColor.DEEPPURPLE, color: '#673AB7' },
  { label: ThemeComponentColor.BLUE, color: '#2196F3' },
  { label: ThemeComponentColor.LIGHTBLUE, color: '#03A9F4' },
  { label: ThemeComponentColor.CYAN, color: '#00BCD4' },
  { label: ThemeComponentColor.TEAL, color: '#009688' },
  { label: ThemeComponentColor.GREEN, color: '#4CAF50' },
  { label: ThemeComponentColor.LIGHTGREEN, color: '#8BC34A' },
  { label: ThemeComponentColor.LIME, color: '#CDDC39' },
  { label: ThemeComponentColor.YELLOW, color: '#FFEB3B' },
  { label: ThemeComponentColor.AMBER, color: '#FFC107' },
  { label: ThemeComponentColor.ORANGE, color: '#FF9800' },
  { label: ThemeComponentColor.DEEPORANGE, color: '#FF5722' },
  { label: ThemeComponentColor.BROWN, color: '#795548' },
  { label: ThemeComponentColor.BLUEGREY, color: '#607D8B' },
  // { label: ThemeComponentColor.TEST, color: '#6666FF' },
];

export const ThemeOptions: ConfigOption = {
  menuMode: ThemeOrientationList,
  menuTheme: ThemeMenu,
  topbarTheme: ThemeTopbar,
  componentTheme: ThemeComponent,
  colorScheme: ThemeColorSchemeList,
  scale: LayoutThemeScales,
  input: InputStyleList,
};

export const ThemeOptionsInit = (): LayoutConfig =>
  <LayoutConfig>{
    scale: LayoutScale[2] as LayoutScaleType,
    colorScheme: ColorScheme.LIGHT,
    menuMode: MenuMode.STATIC,
    menuTheme: ThemeMenuColor.LIGHT,
    topbarTheme: ThemeTopbarColor.BLUE,
    componentTheme: ThemeComponentColor.INDIGO,
    inputStyle: InputStyles.FILLED,
    lang: DocumentLangType.ENG,
  };

interface ConfigOption {
  menuMode: ThemeOrientationItem[];
  menuTheme: ThemeMenuColorItem[];
  topbarTheme: ThemeTopbarColorItem[];
  componentTheme: ThemeComponentColorItem[];
  colorScheme: ColorSchemeItem[];
  scale: LayoutScaleType[];
  input: InputStyleItem[];
}

interface InputStyleItem extends SelectItem<string> {
  label: InputStyles;
  value: string;
}

interface ThemeOrientationItem extends SelectItem<string> {
  label: MenuMode;
  value: string;
}

interface ColorSchemeItem extends SelectItem<string> {
  label: ColorScheme;
  value: string;
}

export interface ThemeMenuColorItem extends PrimeThemes {
  label: ThemeMenuColor;
}

export interface ThemeTopbarColorItem extends PrimeThemes {
  label: ThemeTopbarColor;
}

export interface ThemeComponentColorItem extends PrimeThemes {
  label: ThemeComponentColor;
}

type Hex = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
type ColorCode = `${Hex}${Hex}${Hex}`;

export type HexColor = `#${ColorCode}`;

export interface PrimeThemes {
  label: ThemeComponentColor | ThemeMenuColor | ThemeTopbarColor;
  color: `#${string}`;
}

export type ThemeConfigOption =
  | ColorSchemeItem
  | ThemeComponentColorItem
  | ThemeMenuColorItem
  | ThemeOrientationItem
  | ThemeTopbarColorItem;

export enum LayoutType {
  LEGACY,
  TABMENU,
}

export type LayoutScaleType = 10 | 12 | 13 | 14 | 16 | 18 | 20;

export interface MenuChangeEvent {
  key: string;
  routeEvent?: boolean;
}
