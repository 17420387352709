import { GoogleComboChartOptions } from '../google-chart';

export class GetPowerBIOverallChartOptions {
  public readonly barStacked: GoogleComboChartOptions;

  constructor() {
    this.barStacked = {
      width: '100%',
      height: '100%',
      isStacked: true,
      seriesType: 'bars',
      series: {
        0: { color: '#C0C0D0' },
        1: { color: '#1AB394' },
      },
      legend: {
        position: 'none',
      },
      hAxis: {
        textStyle: { fontSize: 12 },
      },
      vAxis: {
        // gridlines: { color: 'white' },
        format: 'short',
      },
      theme: 'material',
      chartArea: {
        top: 5,
        left: 50,
        width: '94%',
        height: '87%',
      },
      bar: { groupWidth: 38 },
    };
  }
}
