// Angular
import { Component, ElementRef, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { ColumnFormat } from '@base/service';
// Interface
import { GoogleChartData, GoogleChartOptions } from '@base/model';
import { ChartTypes } from '@core/enum';
import { NgChange } from '@core/model';
// Third Party
import { Chart, ChartConfiguration, ChartType } from 'chart.js';

@Component({
  selector: 'caloudi-chart-v2',
  templateUrl: './chartjs.component.html',
  styleUrls: ['./chartjs.component.sass'],
})
export class ChartJSComponent extends BaseComponent implements OnChanges {
  @Input('chartType') public _chartType: ChartTypes = ChartTypes.ColumnChart;
  @Input('data') public _data: GoogleChartData;
  @Input('options') public _options: GoogleChartOptions;
  @Input('columns') public _columns: (number | { [key: string]: number | string; })[];
  @Input('columnFormats') public _columnFormats: ColumnFormat[];

  public options: GoogleChartOptions;
  // private chartSub: Subject<LayoutConfig> = new Subject<LayoutConfig>();
  private element: HTMLCanvasElement;
  // private data: ChartData;

  constructor(public readonly injector: Injector, private readonly elementRef: ElementRef<HTMLElement>) {
    super(injector);
  }

  public ngOnChanges(changes: GoogleChartChanges): void {
    this.element = this.elementRef.nativeElement.querySelector('.caloudi_chart');
    this.logger.trace('any changes:', [changes]);
    // if (changes.options?.currentValue) this.options = { ...this._options };
    // if (CommonUtil.ngIsChanges(changes.data)) this.drawChart();
    if (!changes._data) return;
    const values: (number | string)[][] = [...changes._data.currentValue] as (number | string)[][];
    const labels = values.shift() as string[];
    const payload: DataForm[][] = values.map(value => value.map((v, i) => ({ label: labels[i], data: v } as DataForm)));

    let chartType: ChartType;
    switch (this._chartType) {
      case ChartTypes.BarChart:
        chartType = 'bar';
        break;
      case ChartTypes.ColumnChart:
        chartType = 'bar';
        break;
      case ChartTypes.ComboChart:
        chartType = 'bar';
        break;
      case ChartTypes.LineChart:
        chartType = 'line';
        break;
      case ChartTypes.MaterialBar:
        chartType = 'bar';
        break;
      case ChartTypes.MaterialLine:
        chartType = 'line';
        break;
      case ChartTypes.PieChart:
        chartType = 'pie';
        break;
      default:
        chartType = 'pie';
        break;
    }

    this.drawChart(this.convertConfig(labels, payload, chartType, [...changes._data.currentValue]));
  }

  private convertConfig(labels: string[], payload: DataForm[][], chartType: ChartType, ...rest): ChartConfiguration {
    // const data: ChartDataset[] = payload.map(v => ({
    //   label: v,
    //   data: v,
    //   backgroundColor: [...chartColors],
    //   borderWidth: 0,
    // }));
    const output: ChartConfiguration = {
      type: chartType,
      data: {
        labels: labels,
        datasets: [],
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.logger.debug(`${chartType} check:`, [this.element], [labels, payload, ...rest]);
    return output;
  }

  private drawChart(config: ChartConfiguration): void {
    new Chart(this.element, config);
  }
}

interface GoogleChartChanges extends SimpleChanges {
  _chartType: NgChange<string>;
  _data: NgChange<GoogleChartData>;
  _options: NgChange<GoogleChartOptions>;
  _columns: NgChange<(number | { [key: string]: number | string; })[]>;
  _columnFormats: NgChange<ColumnFormat[]>;
}

// const chartColors: string[] = [
//   '#3366cc', '#dc3912', '#ff9900',
//   '#109618', '#990099', '#0099c6',
//   '#dd4477', '#66aa00', '#b82e2e',
//   '#316395', '#994499', '#22aa99',
//   '#aaaa11', '#6633cc', '#e67300',
//   '#8b0707', '#651067', '#329262',
//   '#5574a6', '#3b3eac', '#b77322',
//   '#16d620', '#b91383', '#f4359e',
//   '#9c5935', '#a9c413', '#2a778d',
//   '#668d1c', '#bea413', '#0c5922',
//   '#743411',
// ];

interface DataForm {
  label: string;
  data: number;
}
