/* eslint-disable
   @typescript-eslint/no-unsafe-call,
   @typescript-eslint/no-unsafe-member-access,
 */
// Angular
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { JSONUtil } from '@util';
// Interface
import { DataTable, PivotConfig } from '@base/model';
// Third Party
// import $ from 'jquery-ui-dist/jquery-ui.min.js';
// import 'jquery/dist/jquery.min.js';
// import 'pivottable/dist/pivot.min.js';
import Subtotal from 'src/assets/js/subtotal.js';

@Component({
  selector: 'caloudi-pivot-table',
  templateUrl: './pivot-table.component.html',
  styleUrls: ['./pivot-table.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class PivotTableComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('data') public data: DataTable<unknown>;
  @Input('config') public config: PivotConfig;
  @Input('showUI') public showUI: boolean;
  @Output('configChange') public configChangeEmitter: EventEmitter<PivotConfig> = new EventEmitter<PivotConfig>();

  private userConfig: PivotConfig;
  private pivotInit: boolean;
  private bindingConfigChange: boolean;

  private readonly logActive: boolean = !1;

  constructor(
    public readonly injector: Injector,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.logActive && this.logger.debug('Pivot Table ngOnInit');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.logActive && this.logger.debug('any changes:', [changes]);
    this.logActive && this.logger.debug('pivotInit:', [this.pivotInit]);
    this.bindingConfigChange = !!changes.config;
    // Note: this event fires before ngAfterViewInit
    // Render when data changes
    if (this.pivotInit) this.render(false);
  }

  public ngAfterViewInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    this.logActive && this.logger.debug('ngAfterViewInit');
    new Subtotal($);
    this.pivotInit = true;
    this.render(true);
  }

  private render(init: boolean): void {
    this.logActive && this.logger.debug('render', [init, this.data, this.config]);

    let pivotConfig: PivotTableConfig = this.getDefaultConfig(this.data);
    this.logActive && this.logger.debug('defaultOptions', [pivotConfig]);
    // Handle init data and config
    this.logActive && this.logger.debug('config', [this.config]);
    if ((init || this.bindingConfigChange) && this.config) {
      pivotConfig = this.mergeConfig(pivotConfig, this.config, this.data);
    } else if (this.userConfig) {
      pivotConfig = this.mergeConfig(pivotConfig, this.userConfig, this.data);
    }

    if (pivotConfig.cols.length === 0) {
      pivotConfig.cols = ['billingPeriod', 'usageDate', 'week'];
    }

    this.logActive && this.logger.debug('pivotConfig', [pivotConfig, $(this.elementRef.nativeElement)]);
    $(this.elementRef.nativeElement).pivotUI(this.data.values, pivotConfig, true);
    $('.pvtTriangle').css({ position: 'relative' });
  }

  private mergeConfig(
    pivotConfig: PivotTableConfig,
    newConfig: PivotConfig,
    data: DataTable<unknown>
  ): PivotTableConfig {
    const fieldsFromData: string[] = data.columns
      .map(column => column.field)
      .filter(
        (field: string) =>
          !field.includes('Id') &&
          !field.includes('serviceCode') &&
          field !== 'usageDate' &&
          field !== 'billingPeriod' &&
          field !== 'week' &&
          !field.includes('Guid') &&
          !field.toUpperCase().includes('COST')
      );

    pivotConfig.rendererName = newConfig.rendererName;
    pivotConfig.aggregatorName = newConfig.aggregatorName;
    pivotConfig.rows = newConfig.rows;
    fieldsFromData.forEach(field => {
      if (!pivotConfig.rows.includes(field)) {
        pivotConfig.rows.push(field);
      }
    });
    // Remove from rows if the field already in newConfig.cols
    newConfig.cols.forEach(field => {
      const index = pivotConfig.rows.indexOf(field);
      if (index !== -1) {
        pivotConfig.rows.splice(index, 1);
      }
    });
    pivotConfig.cols = newConfig.cols;
    pivotConfig.vals = newConfig.vals;
    return pivotConfig;
  }

  private getDefaultConfig(data: DataTable<unknown>): PivotTableConfig {
    this.logActive && this.logger.debug('renderers', [$.pivotUtilities]);
    let renderers: Record<string, unknown> = $.extend(
      $.pivotUtilities?.subtotal_renderers,
      $.pivotUtilities?.plotly_renderers
    );

    // Remove Horizontal charts
    renderers = Object.keys(renderers)
      .filter(key => !key.startsWith('Horizontal') && !key.startsWith('Scatter'))
      .reduce((obj, key) => {
        obj[key] = renderers[key];
        return obj;
      }, {});

    let aggregators: Record<string, unknown> = $.pivotUtilities?.aggregators;
    // this.logger.trace('renderers', renderers);
    // Remove unfit aggregators
    aggregators = Object.keys(aggregators)
      .filter(
        key =>
          !key.startsWith('Count') &&
          !key.startsWith('List') &&
          !key.startsWith('80%') &&
          key !== 'Sum over Sum' &&
          key !== 'First' &&
          key !== 'Last'
      )
      .reduce((obj, key) => {
        obj[key] = aggregators[key];
        return obj;
      }, {});
    // Config hidden attributes
    const hiddenAttributes: string[] = data.columns
      .map(column => column.field)
      .filter(
        (field: string) =>
          (field.includes('Id') && field !== 'billingPeriod' && field !== 'week') ||
          field.includes('Guid') ||
          field.includes('serviceCode')
      );
    // Config hidden from aggregators
    const hiddenFromAggregators: string[] = data.columns
      .map(column => column.field)
      .filter((field: string) => !field.toUpperCase().includes('COST'));
    // Config rows
    const fieldsFromData: string[] = data.columns
      .map(column => column.field)
      .filter(
        (field: string) =>
          !field.includes('Id') &&
          !field.includes('serviceCode') &&
          field !== 'cost' &&
          field !== 'usageDate' &&
          !field.includes('Guid')
      );
    this.logActive && this.logger.debug('fieldsFromData', [fieldsFromData]);
    const rendererName: string = 'Table With Subtotal Heatmap';
    const aggregatorName: string = 'Integer Sum';
    const rows: string[] = fieldsFromData;
    const cols: string[] = ['billingPeriod', 'usageDate', 'week'];
    const vals: string[] = ['cost', 'totalCost', 'riCost', 'usageCost'];
    const hiddenFromDragDrop: string[] = ['cost', 'totalCost', 'riCost', 'usageCost'];
    return {
      dataClass: $.pivotUtilities.SubtotalPivotData,
      renderers: renderers as unknown as string,
      aggregators: aggregators as unknown as string,
      rendererName: rendererName,
      aggregatorName: aggregatorName,
      rows: rows,
      cols: cols,
      vals: vals,
      hiddenFromAggregators: hiddenFromAggregators,
      hiddenAttributes: hiddenAttributes,
      hiddenFromDragDrop: hiddenFromDragDrop,
      showUI: this.showUI,
      rendererOptions: {
        localeStrings: {
          totals: 'Total',
          subtotalOf: 'Subtotal',
        },
        arrowExpanded: '[+]',
        arrowCollapsed: '[-]',
        rowSubtotalDisplay: {
          collapseAt: 0,
        },
        colSubtotalDisplay: {
          collapseAt: 0,
        },
        plotly: {
          xaxis: {
            type: 'category',
          },
        },
        plotlyConfig: {
          displaylogo: false,
          modeBarButtonsToRemove: ['select2d', 'lasso2d'],
        },
      },
      onRefresh: (config: PivotTableConfig): void => {
        let config_copy: PivotTableConfig = JSONUtil.parse<PivotTableConfig>(JSONUtil.stringify(config));
        this.logActive && this.logger.debug('config_copy', [config_copy]);
        // Delete some values which are functions
        delete config_copy['aggregators'];
        delete config_copy['renderers'];
        // Delete some bulky default values
        delete config_copy['rendererOptions'];
        delete config_copy['localeStrings'];
        this.logActive && this.logger.trace('emit config change', [config_copy]);
        this.userConfig = {
          aggregatorName: config_copy.aggregatorName,
          rendererName: config_copy.rendererName,
          cols: [...config_copy.cols],
          rows: [...config_copy.rows],
          vals: [...config_copy.vals],
        };
        this.configChangeEmitter.emit(this.userConfig);
      },
    };
  }
}

interface PivotTableConfig extends PivotConfig {
  dataClass: string;
  renderers: string;
  aggregators: string;
  rendererName: string;
  aggregatorName: string;
  rows: string[];
  cols: string[];
  vals: string[];
  hiddenFromAggregators: string[];
  hiddenAttributes: string[];
  hiddenFromDragDrop: string[];
  showUI: boolean;
  rendererOptions: {
    localeStrings: {
      totals: string;
      subtotalOf: string;
    };
    arrowExpanded: string;
    arrowCollapsed: string;
    rowSubtotalDisplay: {
      collapseAt: number;
    };
    colSubtotalDisplay: {
      collapseAt: number;
    };
    plotly: {
      xaxis: {
        type: string;
      };
    };
    plotlyConfig: {
      displaylogo: boolean;
      modeBarButtonsToRemove: string[];
    };
  };
  onRefresh: (config: PivotTableConfig) => void;
}
