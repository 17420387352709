import { easeCubic, interpolateNumber, Selection } from 'd3';
import { Needle, needleValueModifier } from '.';

/**
 * Gauge data interface.
 */
export interface GaugeLineDatum {
  x: number;
  y: number;
}

/**
 * Gauge interface.
 */
export interface GaugeInterface {
  updateNeedle(needleValue: number): void;
  removeGauge(): void;
}

export interface GaugeOptions {
  needleValue?: number;
  needleColor?: string;
  arcColors?: string[];
  arcRatios?: number[];
  rangeLabel?: string[];
  centralLabel?: string;
  hasNeedle?: boolean;
  outerNeedle?: boolean;
  needleStartValue?: number;
  needleUpdateSpeed?: number;
  arcDelimiters?: number[];
  arcOverEffect?: boolean;
  arcPadding?: number;
  arcPaddingColor?: string;
  arcLabels?: string[];
  arcLabelFontSize?: number;
  rangeLabelFontSize?: number;
  labelsFont?: string;
}

export class Gauge {
  private readonly _svg: Selection<SVGSVGElement, number, null, undefined>;
  private readonly _needleUpdateSpeed: number;
  private readonly _needle: Needle;

  constructor(
    public readonly svg: Selection<SVGSVGElement, number, null, undefined>,
    public readonly needleUpdateSpeed: number,
    public readonly needle: Needle = null
  ) {
    this._svg = svg;
    this._needleUpdateSpeed = needleUpdateSpeed;
    this._needle = needle;
  }

  public updateNeedle(needleValue: number): void {
    if (!this._needle) {
      console.warn('Gauge-chart Warning: no needle to update');
      return;
    }
    needleValue = needleValueModifier(needleValue);
    this._needle
      .getSelection()
      .transition()
      // for dynamic speed change .duration(Math.abs(needleValue - this.needle.getValue()) * 20)
      .duration(this._needleUpdateSpeed)
      .ease(easeCubic)
      .tween('needle animation', () => {
        const prevValue: number = this._needle.getValue();
        const i: (t: number) => number = interpolateNumber(prevValue, needleValue);
        return (t: number): void => this._needle.setValue(i(t));
      });
  }

  public removeGauge(): void {
    this._svg.remove();
  }
}
