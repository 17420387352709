// Angular
import { AfterViewInit, Component, ElementRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { DateAddDashPipe } from '@base/pipe';
// Interface
import { MarginInfo } from '@base/model';
import { NgChange } from '@core/model';
// D3
import * as d3 from 'd3';

@Component({
  selector: 'caloudi-d3-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class LinechartComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('d3LineChart')
  private readonly d3LineChart: ElementRef<HTMLElement>;

  @Input('data') public data: string[][];
  @Input('margin') public marginInput: number[] | number;
  @Input('height') public chartHeight: string = '50vh';

  private element: HTMLElement;
  private margin: MarginInfo = { left: 50, top: 50, right: 50, bottom: 50 };
  private width: number;
  private height: number;
  private lineChartData: ChartData;
  private readonly lineChartDates: string[] = [];

  public selectedLegend: unknown;
  public dataLabels: string[];
  public dataLines: string[];
  public colorIndex: number[] = [];
  public colors: D3Colors = {
    strokeline: '#66666680',
    colorSeries: [
      '#00ffcc',
      '#00aaff',
      '#00ffaa',
      '#0066ff',
      '#ff33dd',
      '#ff00cc',
      '#ffaa00',
      '#ff66cc',
      '#ffcc00',
      '#ffaaff',
      '#aaffff',
      '#aa00aa',
    ],
  };

  constructor(public readonly injector: Injector, private readonly dateAddDash: DateAddDashPipe) {
    super(injector);
    window.onresize = () => {
      const width = this.d3LineChart.nativeElement.parentElement.clientWidth;
      this.resize(width);
    };
  }

  public ngOnInit(): void {
    this.setMargin();
  }

  public ngOnChanges(changes: Changes): void {
    // this.logger.debug('cahrt data changes:', this.data);
    if (changes.data.currentValue !== undefined && changes.data.previousValue === undefined) {
      this.data = changes.data.currentValue;
      this.dataLabels = [...changes.data.currentValue.shift()];
      this.dataLines = [...this.dataLabels];
      this.dataLines.shift();
      // this.logger.debug('on change ele:', this.d3LineChart)
      this.createData();
    }
    if (changes.data.currentValue !== changes.data.previousValue && changes.data.previousValue !== undefined) {
      this.dataLabels = [...changes.data.currentValue.shift()];
      this.dataLines = [...this.dataLabels];
      this.dataLines.shift();
      this.updateChart();
    }
  }

  private resize(width: number): void {
    if (this.lineChartData) {
      this.logger.debug('width change', width);
      this.width = width * 0.8;
      this.height = this.d3LineChart.nativeElement.clientHeight;
      this.createChart();
    }
  }

  public ngAfterViewInit(): void {
    this.element = this.d3LineChart.nativeElement;
    this.element.setAttribute('style', `height: ${this.chartHeight}`);
    this.width = this.element.clientWidth;
    this.height = this.element.clientHeight;
    // this.setMargin();
    // this.logger.debug('after view ele:', this.element)
    if (this.data) {
      this.createChart();
    }
  }

  private setMargin(): MarginInfo {
    if (Array.isArray(this.marginInput)) {
      if (this.marginInput.length === 2) {
        return (this.margin = {
          left: this.marginInput[0],
          top: this.marginInput[1],
          right: this.marginInput[0],
          bottom: this.marginInput[1],
        });
      }
      if (this.marginInput.length === 3) {
        return (this.margin = {
          left: this.marginInput[0],
          top: this.marginInput[1],
          right: this.marginInput[0],
          bottom: this.marginInput[2],
        });
      }
      if (this.marginInput.length === 4) {
        return (this.margin = {
          left: this.marginInput[0],
          top: this.marginInput[1],
          right: this.marginInput[2],
          bottom: this.marginInput[3],
        });
      }
    }
    return (this.margin = {
      left: this.marginInput as number,
      top: this.marginInput as number,
      right: this.marginInput as number,
      bottom: this.marginInput as number,
    });
  }

  /**
   * support draw multi-lines
   */
  private createData(): void {
    const dataValues = [...this.data];
    this.logger.debug('incoming data:', dataValues);
    this.lineChartDates.splice(0, this.lineChartDates.length);
    const chartData: ChartData = Object.assign(
      dataValues.map(data => {
        let result = {};
        data.forEach((e: string, i: number): void => {
          result[this.dataLabels[i]] = e;
        });
        this.lineChartDates.push(data[0]);
        return result;
      }),
      { x: this.dataLabels[0] },
      { y: this.lang('COST.COST') }
    );
    this.lineChartData = chartData;
    this.colorIndex = this.dataLabels
      .map((_v, i) => i % this.colors.colorSeries.length)
      .slice(1, this.dataLabels.length);
    this.logger.debug('colorIndex:', this.colorIndex);
    this.logger.debug('chartData:', chartData);
    this.logger.debug('chartDate:', this.lineChartDates);
    this.createChart(chartData);
  }

  /**
   * import chartData to draw new lines else will do resize
   * @param chartData
   */
  private createChart(chartData?: ChartData): void {
    if (!chartData) {
      d3.select(this.element).select('svg').remove();
      chartData = this.lineChartData;
    }
    let maxArray: number[] = [];
    this.dataLabels.forEach(e => {
      maxArray.push(d3.max(chartData, value => value[e]));
    });

    const xScale = d3
      .scaleLinear()
      .domain([0, chartData.length - 1])
      .range([this.margin.left, this.width - this.margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(maxArray)])
      .nice()
      .range([this.height - this.margin.bottom, this.margin.top]);

    const xAxis: (selection: d3.Selection<SVGGElement, unknown, null, undefined>, ...args: unknown[]) => void = g =>
      g
        .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
        .attr('stroke-width', 0.5)
        .attr('stroke', this.colors.strokeline)
        .attr('fill', 'none')
        .call(
          d3
            .axisBottom(xScale)
            .ticks(chartData.length - 1)
            .tickSizeOuter(0)
            .tickSize(-this.height + this.margin.top * 2)
            .tickFormat((d: number) => this.dateAddDash.transform(chartData[d][this.dataLabels[0]].toString()))
        )
        .selectAll('text:first-of-type')
        .attr('y', 16);

    const yAxis: (selection: d3.Selection<SVGGElement, unknown, null, undefined>, ...args: unknown[]) => void = g =>
      g
        .attr('transform', `translate(${this.margin.left}, 0)`)
        .attr('stroke-width', 0.5)
        .attr('stroke', this.colors.strokeline)
        .attr('fill', 'none')
        .call(
          d3
            .axisLeft(yScale)
            .ticks(this.height / 60)
            .tickSizeOuter(0)
            .tickSize(-this.width + this.margin.right * 2)
        )
        .call(g => g.select('.domain').remove())
        .selectAll('text')
        .attr('x', -8);

    // Setting up chart area
    const svg = d3
      .select(this.element)
      .append('svg')
      .attr('viewBox', `${-this.margin.left}, 0, ${this.width + this.margin.left}, ${this.height}`)
      .attr('width', this.width)
      .attr('height', this.height);

    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('x', 0)
      .attr('y', 0);

    svg
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'transparent')
      .on('mouseup', () => {
        d3.select('g.popout_info').remove();
        d3.select('div.selected_data').remove();
      });

    // X axis label
    svg
      .append('g')
      .attr('class', 'axis x')
      .attr('transform', `translate(0, ${this.height - this.margin.top * 2})`)
      .call(xAxis)
      .append('text')
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', '700')
      .attr('transform', `translate(${this.width / 2}, ${this.margin.bottom})`)
      .text(chartData.x);

    // Y axis label
    svg
      .append('g')
      .attr('class', 'axis y')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .call(yAxis)
      .append('text')
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', '700')
      .attr('transform', `translate(-${this.margin.left / 2}, ${this.margin.top / 2})`)
      .text(chartData.y);

    this.dataLines.forEach((e, i): void => {
      // this.logger.debug('draw lines', e, i)
      const line: d3.Line<[number, number]> = d3
        .line()
        .x((_d, i) => xScale(i))
        .y((d, _i) => yScale(d[e] as d3.NumberValue))
        .curve(d3.curveMonotoneX);

      // X axis data
      svg
        .append('g')
        .attr('class', `data path_v${i}`)
        .append('path')
        .datum(chartData)
        .attr('class', `line_v${i}`)
        .attr('fill', 'none')
        .attr('stroke', this.colors.colorSeries[this.colorIndex[i]])
        .attr('d', line as unknown as (number | string)[])
        .style('transition', '.4s');
      svg
        .append('g')
        .attr('class', `data dots path_v${i}`)
        .selectAll('.dot')
        .data(chartData)
        .enter()
        .append('circle')
        .attr('fill', this.colors.colorSeries[this.colorIndex[i]])
        .attr('val', e)
        .attr('cx', (_d, i) => xScale(i))
        .attr('cy', (d, _i) => yScale(d[e]))
        .attr('r', 5)
        .style('transition', '.4s')
        .on('mouseup', (event: MouseEvent, d) => this.handleMouseClick(event, d))
        .on('mouseover', (event: MouseEvent) => this.handleMouseOver(event))
        .on('mouseout', (event: MouseEvent) => this.handleMouseOut(event));
    });
  }

  /**
   * TODO: add live update function
   */
  private updateChart(): void {
    this.logger.debug('do update');
    this.legendClick();
    const svg = d3.select(this.element);
    svg.select('svg').remove();
    svg.selectAll('g.popout_info').remove();
    svg.select('div.selected_data').remove();
    this.createData();
  }

  private handleMouseClick(event: MouseEvent, data?: { [key: string]: number; }): void {
    let y: number;
    let x: number;
    const svg = d3.select('svg');
    const dot = event.target as SVGCircleElement;
    const parent: HTMLElement = (<HTMLElement>svg.node())['parentElement'];
    // this.logger.debug('d:', data, 'e:', event)
    // this.logger.debug('parent:', svg.node())
    const selectDiv = d3.select(parent);
    // const lineOfDot = dot.attr('val')
    svg.selectAll('g.popout_info').remove();
    selectDiv.select('div.selected_data').remove();

    svg.append('g').attr('class', 'popout_info');

    svg
      .select('g.popout_info')
      .append('g')
      .attr('class', 'selected_dot')
      .append('circle')
      .attr('fill', 'transparent')
      .attr('stroke', '#ff3300')
      .attr('stroke-width', 5)
      .attr('r', 10);
    svg
      .select('g.popout_info')
      .select('g.selected_dot')
      .attr('opacity', '.75')
      .append('circle')
      .attr('fill', 'none')
      .attr('stroke', '#ff3300')
      .attr('stroke-width', 2)
      .attr('r', 20);

    svg
      .selectAll('g.selected_dot')
      .selectAll('circle')
      .attr('transform', `translate(${dot.cx.baseVal.value}, ${dot.cy.baseVal.value})`);

    selectDiv.append('div').attr('class', 'selected_data');

    selectDiv
      .select('div.selected_data')
      .append('h6')
      .text(`${Object.keys(data)[0]}: ${Object.values(data)[0]}`);

    selectDiv.select('div.selected_data').append('ul');

    // Display single dot data
    // SelectDiv
    //   .select('div.selected_data')
    //   .select('ul')
    //   .append('li')
    //   .text(`${lineOfDot}: ${data[lineOfDot]}`)

    // Display multi-dots data
    for (let count = Object.keys(data).length - 1; count >= 1; count--) {
      const dataKey = Object.keys(data)[count];
      const dataValue = ~~data[dataKey];
      selectDiv.select('div.selected_data').select('ul').append('li').text(`${dataKey}: $${dataValue}`);
    }

    const dialogWidth: number = (<HTMLElement>d3.select('div.selected_data').node()).clientWidth;
    const dialogHeight: number = (<HTMLElement>d3.select('div.selected_data').node()).clientHeight;
    if (parent.clientWidth - dot.cx.baseVal.value <= dialogWidth * 2) {
      x = -dialogWidth - 30;
    } else {
      x = 30;
    }
    if (parent.clientHeight - dot.cy.baseVal.value <= dialogHeight * 2) {
      y = -dialogHeight - 30;
    } else {
      y = 30;
    }

    selectDiv
      .select('div.selected_data')
      .style('left', String(event.pageX + x) + 'px')
      .style('top', String(event.pageY + y) + 'px');
  }

  private handleMouseOver(event: MouseEvent): void {
    d3.select(event.target as SVGCircleElement)
      .attr('stroke', '#fff')
      .attr('r', 10);
  }

  private handleMouseOut(event: MouseEvent): void {
    // d3.select('svg').selectAll('g.popout_info').remove()
    d3.select(event.target as SVGCircleElement)
      .attr('stroke', 'none')
      .attr('r', 5);
  }

  public legendClick(e?: number): void {
    const path = d3.selectAll('g.data').select('path');
    this.selectedLegend = null;
    d3.select('div.selected_data').remove();
    d3.select('svg').selectAll('g.popout_info').remove();
    path.attr('stroke-width', 1).style('opacity', '1');
    d3.selectAll('g.data').selectAll('circle').attr('r', 5);
    if (e >= 0) {
      this.selectedLegend = e;
      path.attr('stroke-width', 0.5).style('opacity', '.5');
      d3.selectAll('g.data.dots').selectAll('circle').attr('r', 0);

      d3.select(`path.line_v${e}`).attr('stroke-width', 4).style('opacity', '1');
      d3.select(`g.data.dots.path_v${e}`).selectAll('circle').attr('r', 5);
    }
  }
}

interface D3Colors {
  strokeline: string;
  colorSeries: string[];
}

type ChartData = { [key: string]: number; }[] & { x: string; } & { y: string; };

interface Changes extends SimpleChanges {
  data: NgChange<string[][]>;
}
