// Third party
import { md5 as JSMd5 } from 'js-md5';
import { sha224 as jsSha224, sha256 as jsSha256 } from 'js-sha256';

/**
 * MD5 & sha256 crypto methods.
 */
export default class CryptoUtil {
  /**
   * MD5 Hash and return hex string.
   * @param message The message you want to hash.
   * @returns Hash string
   */
  public static md5(message: Message): string {
    return JSMd5(message);
  }

  /**
   * SHA256 Hash and return hex string.
   * @param message The message you want to hash.
   * @returns Hash string
   */
  public static sha256(message: Message): string {
    return jsSha256(message);
  }

  /**
   * SHA224 Hash and return hex string.
   * @param message The message you want to hash.
   * @returns Hash string
   */
  public static sha224(message: Message): string {
    return jsSha224(message);
  }
}

type Message = ArrayBuffer | number[] | Uint8Array | string;
