// D3
import { curveLinear, Line, line, Selection } from 'd3';
// Interface
import { GaugeLineDatum, perc2RadWithShift } from '.';

/**
 * Needle interface.
 */
export class Needle {
  public needleValue: number;
  public centralLabel: string;
  public chartHeight: number;
  public outerRadius: number;
  public needleColor: string;
  public offset: number;
  public lineData: number;
  public lineFunction: Line<GaugeLineDatum>;
  public needleSvg: Selection<SVGPathElement, number, null, undefined>;
  public outerNeedle: boolean;

  constructor(
    svg: d3.Selection<SVGSVGElement, number, null, undefined>,
    needleValue: number,
    centralLabel: string,
    chartHeight: number,
    outerRadius: number,
    offset: number,
    needleColor: string,
    outerNeedle: boolean
  ) {
    this.needleValue = needleValue;
    this.centralLabel = centralLabel;
    this.chartHeight = chartHeight;
    this.outerRadius = outerRadius;
    this.offset = offset;
    this.needleColor = needleColor;
    this.outerNeedle = outerNeedle;
    this.lineFunction = line<GaugeLineDatum>()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveLinear);

    if (outerNeedle) {
      this.needleSvg = svg
        .append('path')
        .attr('d', this.getLine())
        .attr('stroke', this.needleColor)
        .attr('stroke-width', 2)
        .attr('fill', this.needleColor)
        .attr(
          'transform',
          'translate(' + (this.chartHeight + this.offset * 2) + ', ' + (this.chartHeight + this.offset) + ')'
        );
    } else {
      this.needleSvg = svg
        .append('path')
        .attr('d', this.getLine())
        .attr('stroke', this.needleColor)
        .attr('stroke-width', 2)
        .attr('fill', this.needleColor)
        .attr(
          'transform',
          'translate(' + (this.chartHeight + this.offset * 2) + ', ' + (this.chartHeight + this.offset) + ')'
        );
    }
  }

  public setValue(needleValue: number): void {
    this.needleValue = needleValue;
    this.needleSvg.attr('d', this.getLine());
  }

  public getValue(): number {
    return this.needleValue;
  }

  public calcCoordinates(): GaugeLineDatum[] {
    // Thin needle if there is no central label and wide if there is.
    let needleWidth = this.centralLabel ? this.chartHeight * 0.7 : this.chartHeight * 0.1;
    needleWidth = this.outerNeedle ? this.chartHeight * 0.25 : needleWidth;
    const needleHeadLength: number = this.outerNeedle ? this.outerRadius * 1.4 : this.outerRadius * 0.97;
    const needleTailLength: number = needleWidth * 0.5;
    const needleWaypointOffset: number = needleWidth * 0.5;
    const needleAngle: number = perc2RadWithShift(this.needleValue);
    let needleCoords: GaugeLineDatum[];

    if (this.outerNeedle) {
      needleCoords = [
        {
          x: needleHeadLength * Math.sin(needleAngle),
          y: -needleHeadLength * Math.cos(needleAngle),
        },
        {
          x:
            (needleHeadLength + needleTailLength) * Math.sin(needleAngle) +
            needleWaypointOffset * Math.cos(needleAngle),
          y:
            -(needleHeadLength + needleTailLength) * Math.cos(needleAngle) +
            needleWaypointOffset * Math.sin(needleAngle),
        },
        {
          x:
            (needleHeadLength + needleTailLength) * Math.sin(needleAngle) -
            needleWaypointOffset * Math.cos(needleAngle),
          y:
            -(needleHeadLength + needleTailLength) * Math.cos(needleAngle) -
            needleWaypointOffset * Math.sin(needleAngle),
        },
        {
          x: needleHeadLength * Math.sin(needleAngle),
          y: -needleHeadLength * Math.cos(needleAngle),
        },
      ];
    } else {
      if (this.centralLabel)
        needleCoords = [
          {
            x: needleHeadLength * Math.sin(needleAngle),
            y: -needleHeadLength * Math.cos(needleAngle),
          },
          {
            x: needleWaypointOffset * 1.5 * Math.sin(needleAngle) - (needleWaypointOffset / 3) * Math.cos(needleAngle),
            y:
              -(needleWaypointOffset * 1.5) * Math.cos(needleAngle) -
              (needleWaypointOffset / 3) * Math.sin(needleAngle),
          },
          {
            x: needleWaypointOffset * 1.5 * Math.sin(needleAngle) + (needleWaypointOffset / 3) * Math.cos(needleAngle),
            y:
              -(needleWaypointOffset * 1.5) * Math.cos(needleAngle) +
              (needleWaypointOffset / 3) * Math.sin(needleAngle),
          },
          {
            x: needleHeadLength * Math.sin(needleAngle),
            y: -needleHeadLength * Math.cos(needleAngle),
          },
        ];
      else
        needleCoords = [
          {
            x: needleHeadLength * Math.sin(needleAngle),
            y: -needleHeadLength * Math.cos(needleAngle),
          },
          {
            x: -needleWaypointOffset * Math.cos(needleAngle),
            y: -needleWaypointOffset * Math.sin(needleAngle),
          },
          {
            x: -needleTailLength * Math.sin(needleAngle),
            y: needleTailLength * Math.cos(needleAngle),
          },
          {
            x: needleWaypointOffset * Math.cos(needleAngle),
            y: needleWaypointOffset * Math.sin(needleAngle),
          },
          {
            x: needleHeadLength * Math.sin(needleAngle),
            y: -needleHeadLength * Math.cos(needleAngle),
          },
        ];
    }
    return needleCoords;
  }

  public getSelection(): Selection<SVGPathElement, number, null, undefined> {
    return this.needleSvg;
  }

  private getLine(): string {
    return this.lineFunction(this.calcCoordinates());
  }
}
