/* eslint-disable @typescript-eslint/no-explicit-any */
// Angular
import { ElementRef, Injectable } from '@angular/core';
// Ngx
import { NGXLogger } from 'ngx-logger';
// Interface
import { GoogleChartData, GoogleChartOptions, GoogleCharts, GoogleData } from '@base/model';
import { ChartTypes, Formatters } from '@core/enum';

@Injectable({ providedIn: 'root' })
export class GoogleChartService {
  private readonly apiKey = 'AIzaSyDlCgFvrTQ5LuKKniuMxxiOoPMUibHzLj8';
  private chartIns: GoogleCharts;
  private readonly logActive: boolean = false;

  constructor(private readonly logger: NGXLogger) {}

  public buildChart(
    chartType: ChartTypes,
    elementRef: ElementRef<HTMLElement>,
    _data: GoogleChartData,
    _options?: GoogleChartOptions,
    columns?: any[],
    columnFormats?: ColumnFormat[]
  ): void {
    this.logActive && this.logger.debug('buildChart', [chartType, elementRef, _data, _options, columns, columnFormats]);

    let packages: string;
    switch (chartType) {
      case ChartTypes.MaterialBar:
        packages = 'bar';
        break;
      case ChartTypes.MaterialLine:
        packages = 'line';
        break;
      case ChartTypes.GeoChart:
        packages = 'geochart';
        break;
      case ChartTypes.Gauge:
        packages = 'gauge';
        break;
      case ChartTypes.DataTable:
        packages = 'table';
        break;
      default:
        packages = 'corechart';
        break;
    }

    google.charts.load('current', { packages, mapsApiKey: this.apiKey }).then((): void => {
      const data: GoogleData = (() => {
        const d = google.visualization.arrayToDataTable(_data);
        if (columnFormats?.length > 0)
          columnFormats.forEach(columnFormat => {
            const formatter = this.getFormatter(columnFormat.formatterType, columnFormat.options);
            formatter.format(d, columnFormat.columnIndex as number[] & number);
          });
        switch (chartType) {
          case ChartTypes.ColumnChart:
            return new google.visualization.DataView(d);
          case ChartTypes.BarChart:
            return new google.visualization.DataView(d);
          case ChartTypes.MaterialBar:
            return new google.visualization.DataView(d);
          default:
            return d;
        }
      })();

      const options: GoogleChartOptions = (() => {
        switch (chartType) {
          case ChartTypes.MaterialBar:
            return google.charts.Bar.convertOptions(_options);
          case ChartTypes.MaterialLine:
            return google.charts.Line.convertOptions(_options);
          default:
            return _options;
        }
      })();

      this.logActive && this.logger.debug('final options:', [options]);

      const chart: GoogleCharts = (() => {
        switch (chartType) {
          case ChartTypes.ColumnChart:
            return new google.visualization.ColumnChart(elementRef.nativeElement);
          case ChartTypes.BarChart:
            return new google.visualization.BarChart(elementRef.nativeElement);
          case ChartTypes.MaterialBar:
            return new google.charts.Bar(elementRef.nativeElement) as google.visualization.BarChart;
          case ChartTypes.MaterialLine:
            return new google.charts.Line(elementRef.nativeElement) as google.visualization.LineChart;
          case ChartTypes.AreaChart:
            return new google.visualization.AreaChart(elementRef.nativeElement);
          case ChartTypes.LineChart:
            return new google.visualization.LineChart(elementRef.nativeElement);
          case ChartTypes.ComboChart:
            return new google.visualization.ComboChart(elementRef.nativeElement);
          case ChartTypes.PieChart:
            return new google.visualization.PieChart(elementRef.nativeElement);
          case ChartTypes.GeoChart:
            return new google.visualization.GeoChart(elementRef.nativeElement);
          case ChartTypes.Gauge:
            return new google.visualization.Gauge(elementRef.nativeElement);
          case ChartTypes.DataTable:
            return new google.visualization.Table(elementRef.nativeElement);
          default:
            return void 0;
        }
      })();
      if (!chart) return;
      google.visualization.events.addListener(chart, 'error', (e: Record<string, string>) =>
        this.googleChartErrorHandler(e));
      this.chartIns = chart;
      chart.draw(data, options as unknown);
    });
  }

  private getFormatter(
    formatter: Formatters,
    options: FormatterOption
  ): google.visualization.DateFormat | google.visualization.NumberFormat | google.visualization.PatternFormat {
    switch (formatter) {
      case Formatters.DateFormat:
        return new google.visualization.DateFormat(options as google.visualization.DateFormatOptions);
      case Formatters.NumberFormat:
        return new google.visualization.NumberFormat(options as google.visualization.NumberFormatOptions);
      case Formatters.PatternFormat:
        return new google.visualization.PatternFormat(options as string);
      default:
        return new google.visualization.NumberFormat(options as google.visualization.NumberFormatOptions);
    }
  }

  private googleChartErrorHandler(googleChartError: Record<string, string>): void {
    this.logger.error('Google Chart Error:', [googleChartError.message, googleChartError]);
    // google.visualization.errors.removeError(googleChartError.id);
  }

  public killListener(): void {
    this.logActive &&
      this.logger.debug('killed', [
        { ...this?.chartIns },
        { ...google?.visualization?.events },
        this.chartIns?.getContainer(),
      ]);
    google?.visualization?.events?.removeAllListeners(this.chartIns);
    this.chartIns?.getContainer()?.remove();
  }
}

export interface ColumnFormat {
  formatterType: Formatters;
  options: FormatterOption;
  columnIndex: number[] | number;
}

type FormatterOption = google.visualization.DateFormatOptions | google.visualization.NumberFormatOptions | string;
