// NGRX
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { PeriodSelectorState } from '../reducers';

// For selector
export const selectPeriodSelectorState: (state: AppState) => PeriodSelectorState = (
  state: AppState
): PeriodSelectorState => state.period;

export const getPeriodSelector = createSelector(selectPeriodSelectorState, store => store);
