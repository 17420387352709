import { DropdownDefaultList } from '@base/model';
import { LanguageService } from '@core/service/config';

export class GetDropdownDefault {
  public DropdownDefault: DropdownDefaultList;
  constructor(private readonly lang: LanguageService) {
    this.DropdownDefault = {
      typeAll: {
        label: this.lang.get('LABEL.ALL').value,
        value: '*',
      },
      typeNone: {
        label: this.lang.get('LABEL.NONE').value,
        value: '*',
      },
      typeEmpty: {
        label: '',
        value: '',
      },
      typeStar: {
        label: '*',
        value: '*',
      },
    };
  }
}

export const DropdownDefault: DropdownDefaultList = {
  typeAll: {
    label: 'ALL',
    value: '*',
  },
  typeNone: {
    label: 'NONE',
    value: '*',
  },
  typeEmpty: {
    label: '',
    value: '',
  },
  typeStar: {
    label: '*',
    value: '*',
  },
};
