import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHT,
  data: {
    CONFIG: {
      TITLE: {
        COLOR_SCHEME: '色彩方案',
        INPUT: '下拉選單',
        LAYOUT_MODE: '佈局模式',
        LAYOUT_TYPE: '佈局類型',
        MENU_MODE: '選單模式',
        SCALE: '介面比例',
        SPECIAL_FEATS: '特殊功能',
        THEME_CUSTOMIZATION: '主題自訂',
      },
      THEMES: {
        MENU: '選單主題',
        TOPBAR: '頂部工具列主題',
        COMPONENT: '元件主題',
      },
      TAB_MENU: {
        PRIME_LAYOUT: 'PrimeNG 佈局',
        TAB_MENU_LAYOUT: '標籤式佈局',
      },
      SPECIAL_FEATS: {
        BLOCK_LOADING: 'Block Loading',
        INLINE_BREADCRUMB: 'Inline Breadcrumb',
        TAB_ENROLLMENT: 'Tab Enrollment',
      },
      LIGHT: '淺色模式',
      DARK: '深色模式',
      SLIM: '精簡模式',
      SLIMPLUS: '精簡+模式',
      REVEAL: '視窗模式',
      DRAWER: '抽屜式模式',
      STATIC: '靜態模式',
      HORIZONTAL: '水平模式',
      OVERLAY: '覆蓋模式',
      OUTLINED: '外框樣式',
      FILLED: '填充樣式',
    },
  },
};
