/**
 * JSON with types, and color converter.
 */
export default class JSONUtil {
  /**
   * Safty Parse JSON string.
   *
   * Converts a JavaScript Object Notation (JSON) string into an object.
   * @param text A valid JSON string.
   * @param reviver A function that transforms the results. This function is called for each member of the object. If a member contains nested objects, the nested objects are transformed before the parent object is.
   * @returns User defined object
   */
  public static parse<T>(
    text: string,
    reviver?: (this: unknown, key: string, value: T) => T
  ): T {
    try {
      return JSON.parse(String(text), reviver) as T;
    } catch (error) {
      throw new Error('JSON Parse Error: ', error);
    }
  }

  /**
   * Safety convert JSON to string.
   *
   * Converts a JavaScript value to a JavaScript Object Notation (JSON) string.
   * @param value A JavaScript value, usually an object or array, to be converted.
   * @param replacer A function that transforms the results.
   * @param space Adds indentation, white space, and line break characters to the return-value JSON text to make it easier to read.
   * @returns JSON string
   */
  public static stringify<T>(
    value: T,
    replacer?: (this: unknown, key: string, value: T) => T,
    space?: number | string
  ): string {
    try {
      return JSON.stringify(value, replacer, space);
    } catch (error) {
      throw new Error('JSON Stringify Error: ', error);
    }
  }

  /**
   * Convert json into colorful text.
   * @param json JSON or json string
   * @returns html string
   */
  public static syntaxHighlight<T>(json: JSON | T | string): string {
    if (typeof json !== 'string') json = JSONUtil.stringify(json, void 0, 2);
    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    return (json.replace(
      // Various regex string /("(\\u[\w\d]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[e][+-]?\d+)?)/
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      (match: string): string => {
        let klass: string = 'number';
        if (match.startsWith('"')) klass = match.endsWith(':') ? 'key' : 'string';
        else if (/true|false/.test(match)) klass = 'boolean';
        else if (match.includes('null')) klass = 'null';
        return `<span class="${klass}">${match}</span>`;
      }
    ));
  }
}
