import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    VPN_PROFILE: {
      DOWNLOADS: '下载',
      NAME: 'VPN 配置文件名称',
      DNS_SERVERS: 'DNS 服务器',
      TITLE: {
        DELETE: '删除 VPN 配置文件',
        EDIT: '编辑 VPN 配置文件',
        NEW: '新增 VPN 配置文件',
        TEST: '测试连接',
      },
      ADD_SUCCESS: '新增成功',
      EDIT_SUCCESS: '编辑成功',
      DELETE_SUCCESS: '删除成功',
      ACTION: {
        TEST: '测试连接',
        EDIT: '编辑 VPN 配置文件',
        DELETE: '删除 VPN 配置文件',
      },
      TEST: {
        START: '开始测试',
        TESTING: '正在测试',
        STANDBY: '待机中',
      },
      MESSAGE: {
        DELETE: '此操作无法撤销。\n您确定要删除此 VPN 配置文件吗？',
      },
    },
  },
};
