import { Action, ActionReducer } from '@ngrx/store';
import { JSONUtil } from '@util';

const INIT_ACTION = '@ngrx/store/init';

const setSavedState = <T>(state: T, key: string): void => {
  localStorage.setItem(key, JSONUtil.stringify(state));
};

const getSavedState = <T>(key: string): T => {
  const storedState = localStorage.getItem(key);
  if (storedState !== null) return JSONUtil.parse<T>(storedState);
  else return null;
};

// The keys from state which we'd like to save.
// const stateKeys = ['auth', 'layout'];

// The key for the local storage.
const localStorageKey = 'APP_REDUX_STATE';

export const storageMetaReducer =
  <S, A extends Action = Action>(reducer: ActionReducer<S, A>) =>
  (state: S, action: A): S => {
    if (action.type === INIT_ACTION) {
      // Init the application state.
      const storedData = getSavedState<S>(localStorageKey);
      if (storedData !== null) {
        return storedData;
      } else {
        // Reduce the nextState.
        return reducer(state, action);
      }
    }

    // Reduce the nextState
    const nextState = reducer(state, action);
    // Save the next state to the application storage.
    // const stateToSave = pick(nextState, stateKeys);
    const stateToSave = {
      auth: nextState['auth' as keyof S],
      layout: nextState['layout' as keyof S],
    };

    // console.debug(`meta:`, [stateToSave, nextState, { ...stateKeys }]);
    setSavedState(stateToSave, localStorageKey);
    return nextState;
  };
