import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { AuthActions, AuthActionTypes } from './auth.actions';
import { BlockUIActions, BlockUIActionTypes } from './blockui.actions';
import { LayoutActions, LayoutActionTypes } from './layout.actions';
import { MessageActions, MessageActionTypes } from './message.actions';
import { PeriodSelectorActions, PeriodSelectorActionTypes } from './period-selector.actions';
import { TabMenuActions, TabMenuActionTypes, TabMenuStaticActions, TabMenuStaticActionTypes } from './tab-menu.actions';
import { TempStoreActions, TempStoreActionTypes } from './temp-store.actions';

export * from './auth.actions';
export * from './blockui.actions';
export * from './layout.actions';
export * from './message.actions';
export * from './period-selector.actions';
export * from './tab-menu.actions';
export * from './temp-store.actions';

export type ActionCreatorProp<T> = ActionCreator<string, (props?: T) => T & TypedAction<string>>;
export type ActionCreatorType = ActionCreator<string, () => TypedAction<string>>;

export const ActionTypes = {
  auth: AuthActionTypes,
  blockUI: BlockUIActionTypes,
  layout: LayoutActionTypes,
  message: MessageActionTypes,
  period: PeriodSelectorActionTypes,
  TabMenuBreadcrumb: TabMenuActionTypes,
  TabMenuStatic: TabMenuStaticActionTypes,
  tempStore: TempStoreActionTypes,
};

const Actions = {
  ...AuthActions,
  ...BlockUIActions,
  ...LayoutActions,
  ...MessageActions,
  ...PeriodSelectorActions,
  ...TabMenuActions,
  ...TabMenuStaticActions,
  ...TempStoreActions,
};

export default Actions;
