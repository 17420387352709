/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
// Angular
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
// Primeng
import { SelectItem } from 'primeng/api';
//  Caloudi
import { BaseComponent } from '@base';
import { downloadPng, downloadSvg } from '@base/service/svg-crowbar';
import { JSONUtil } from '@util';
// Interface
import { AnomalyDetectionProfile, AnomalyDetectionResult as Azure_ADR, AnomalyPoint as Azure_AP, DailyCostValue } from '@azure-usage/model';
import { DataTable, MarginInfo } from '@base/model';
import { MessageSeverity } from '@core/enum';
import { NgChange } from '@core/model';
import { AnomalyDetectionResult as GCP_ADR, AnomalyPoint as GCP_AP } from '@gcp-usage/model';
// Third Party
import * as d3 from 'd3';
// import { downloadPng, downloadSvg } from 'svg-crowbar';

type AnomalyPoint = Azure_AP | GCP_AP;
type AnomalyDetectionResult = Azure_ADR | GCP_ADR;

@Component({
  selector: 'caloudi-anomaly-linechart',
  templateUrl: './anomaly-linechart.component.html',
  styleUrls: ['./anomaly-linechart.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class AnomalyLinechartComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('anomalyLineChart') private readonly anomalyLineChart: ElementRef<HTMLElement>;

  @Input('data') public data: AnomalyDetectionResult;
  @Input('displayExport') public displayExport: boolean;
  @Input('displayLegends') public displayLegends: boolean = true;
  @Input('displayPopout') public displayPopout: boolean = true;
  @Input('displayTimeline') public displayTimeline: boolean = true;

  @Input('height') public chartHeight = '68vh';
  @Input('margin') public marginInput: number[] | number;
  @Input('selectedDot') public selectedAnomalyDot: AnomalyPoint;
  @Input('viewInterval') public viewInterval: SelectItem<Date | string>;

  @Output('viewIntervalChange') private readonly SelectedViewInterval = new EventEmitter<SelectItem<Date | string>>();
  @Output('selectedDotChange') private readonly selectedAnomalyDotChange = new EventEmitter<AnomalyPoint>();

  private adjustedXScale: [number, number];
  private element: HTMLElement;
  private margin: MarginInfo = { left: 20, top: 40, right: 20, bottom: 40 };
  private width: number;
  private divHeight: number;
  private height: number;
  private height2: number;
  private anomalyDetectionProfile: AnomalyDetectionProfile;
  private dailyCostValues: DailyCostValue[];
  private anomalyPoints: DataTable<AnomalyPoint>;

  public id: number = Date.now();

  // private colorIndex: number[];
  private dataLines: string[];
  private readonly colors = {
    strokeline: '#66666680',
    colorSeries: [
      '#e34d4d',
      '#00ff00',
      '#0000ff',
      '#00ffff',
      '#ff00ff',
      '#ffff00',
      '#ffaa00',
      '#ff66cc',
      '#ffcc00',
      '#ffaaff',
      '#aaffff',
      '#aa00aa',
    ],
  };

  public displayDialog: boolean = false;
  private readonly logActive: boolean = false;

  constructor(public readonly injector: Injector, private readonly ngZone: NgZone) {
    super(injector);
    window.onresize = () => this.ngZone.run(() => this.resize());
  }

  public ngOnInit(): void {
    this.setMargin();
  }

  public ngOnChanges(changes: Changes): void {
    this.logActive && this.logger.debug('any changes:', changes);
    const dataCompare =
      JSONUtil.stringify(changes?.data?.currentValue) !== JSONUtil.stringify(changes?.data?.previousValue);
    const intervalCompare =
      JSONUtil.stringify(changes?.viewInterval?.currentValue) !==
      JSONUtil.stringify(changes?.viewInterval?.previousValue);
    const anomalyDotCompare =
      JSONUtil.stringify(changes?.selectedAnomalyDot?.currentValue) !==
      JSONUtil.stringify(changes?.selectedAnomalyDot?.previousValue);
    if (dataCompare) {
      if (!changes.data.currentValue?.dailyCostValues) {
        d3.select<HTMLElement, DailyCostValue>(this.element).select('svg').remove();
        this.chartInit();
        return;
      }
      const value = changes.data.currentValue as AnomalyDetectionResult;
      // this.logger.debug('data input:', [this.data, this.id]);
      this.dataLines = [...Object.keys(value.dailyCostValues[0])];
      this.dataLines.shift();
      this.anomalyDetectionProfile = value.anomalyDetectionProfile || {
        xLabel: this.lang('LABEL.USAGE_DATE'),
        yLabel: this.lang('COST.COST'),
      };
      this.dailyCostValues = value.dailyCostValues;
      this.anomalyPoints = value.anomalyPoints;
      setTimeout(() => this.createChart(), 0);

      // this.logger.debug('dataCompare:', {
      //   currentValue: JSONUtil.stringify(changes?.data?.currentValue),
      //   previousValue: JSONUtil.stringify(changes?.data?.previousValue),
      //   dataCompare: dataCompare,
      // });
      // this.logger.debug('on change ele:', this.anomalyLineChart)
    }
    try {
      if (anomalyDotCompare) {
        this.selectedAnomalyDot = changes.selectedAnomalyDot.currentValue;
        this.logActive && this.logger.debug('anomalyDotCompare:', [changes.selectedAnomalyDot.currentValue]);
        this.displayPopoutInfo(changes.selectedAnomalyDot.currentValue);
      }
      if (intervalCompare) {
        this.onViewIntervalChange();
      }
    } catch (error) {
      this.store.dispatch(
        this.actions.GlobalMsgAction({
          severity: MessageSeverity.ERROR,
          summary: `${String(error).replace(/(.*):.*/, '$1')}`,
          message: `${error as string}`,
        })
      );
      return;
    }
  }

  private resize(): void {
    if (!this.data) return;
    this.logActive && this.logger.debug('width change:', this.anomalyLineChart.nativeElement.parentElement.clientWidth);
    this.width = this.anomalyLineChart.nativeElement.parentElement.clientWidth;
    this.divHeight = this.anomalyLineChart.nativeElement.clientHeight;
    this.height = this.anomalyLineChart.nativeElement.clientHeight * 0.8;
    this.height2 = this.anomalyLineChart.nativeElement.clientHeight * 0.2;
    setTimeout(() => this.createChart());
  }

  public ngAfterViewInit(): void {
    this.element = this.anomalyLineChart.nativeElement;
    this.element.setAttribute('style', `height: ${this.chartHeight}`);
    this.width = this.element.clientWidth;
    this.divHeight = this.element.clientHeight;
    this.height = this.element.clientHeight * 0.8;
    this.height2 = this.element.clientHeight * 0.2;
    this.chartInit();
  }

  private setMargin(): MarginInfo {
    if (Array.isArray(this.marginInput)) {
      switch (this.marginInput.length) {
        case 2:
          return (this.margin = {
            left: this.marginInput[0],
            top: this.marginInput[1],
            right: this.marginInput[0],
            bottom: this.marginInput[1],
          });
        case 3:
          return (this.margin = {
            left: this.marginInput[0],
            top: this.marginInput[1],
            right: this.marginInput[0],
            bottom: this.marginInput[2],
          });
        case 4:
          return (this.margin = {
            left: this.marginInput[0],
            top: this.marginInput[1],
            right: this.marginInput[2],
            bottom: this.marginInput[3],
          });
        default:
          return (this.margin = {
            left: 20,
            top: 40,
            right: 20,
            bottom: 40,
          });
      }
    }

    return (this.margin = {
      left: this.marginInput,
      top: this.marginInput,
      right: this.marginInput,
      bottom: this.marginInput,
    });
  }

  private basicFunc(): BaseFunc {
    const maxArray: number[] = [];
    this.dailyCostValues.forEach(val => maxArray.push(val.cost));

    const contentData = [...this.dailyCostValues].map(value => ({
      date: new Date(value.usageDate),
      cost: value.cost,
    }));

    const zoneCorrection = (d: Date | string): Date =>
      new Date(new Date(d).getTime() + new Date().getTimezoneOffset() * 6e4);

    const dots = d3
      .select('g.anomaly_dots')
      .select('g.dots:first-of-type')
      .selectAll<SVGCircleElement, AnomalyPoint>('circle')
      .nodes();

    const brush: d3.BrushBehavior<AnomalyPoint> = d3
      .brushX<AnomalyPoint>()
      .extent([
        [this.margin.left, this.margin.bottom],
        [this.width - this.margin.right, this.height2],
      ])
      .on('end', (event: d3.D3BrushEvent<AnomalyPoint>): void => {
        this.logActive && this.logger.debug('brush adjustXScale:', [this.adjustedXScale, event]);
        this.updateChart(event);
      });

    const focusXScale = d3
      .scaleTime()
      .domain(d3.extent(this.dailyCostValues, d => zoneCorrection(d.usageDate)))
      .range([this.margin.left, this.width - this.margin.right]);

    const contentXScale = d3
      .scaleTime()
      .domain(d3.extent(this.dailyCostValues, d => zoneCorrection(d.usageDate)))
      .range([this.margin.left, this.width - this.margin.right]);

    const focusYScale = d3
      .scaleLinear()
      .domain(d3.extent(maxArray))
      .nice()
      // .domain([0, d3.max(maxArray)]).nice()
      .range([(this.displayTimeline ? this.height : this.divHeight) - this.margin.bottom, this.margin.top]);

    const contentYScale = d3
      .scaleLinear()
      .domain(d3.extent(maxArray))
      .nice()
      // .domain([0, d3.max(maxArray)]).nice()
      .range([this.height2, this.margin.top]);

    const intervalScale: (data: DailyCostValue[]) => d3.ScaleTime<number, number> = (data: DailyCostValue[]) =>
      d3
        .scaleTime()
        .domain(d3.extent(data, d => zoneCorrection(d.usageDate)))
        .range([this.margin.left, this.width - this.margin.right]);

    const drawFocusLine = d3
      .line<DailyCostValue>()
      .x(d => focusXScale(zoneCorrection(d.usageDate)))
      .y(d => focusYScale(d.cost))
      .curve(d3.curveMonotoneX);

    const drawContentLine = d3
      .line<DailyCostValue>()
      .x(d => contentXScale(zoneCorrection(d.usageDate)))
      .y(d => contentYScale(d.cost))
      .curve(d3.curveMonotoneX);

    const xAxis: (
      gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
      height2?: number
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
      height2 = 0
    ) =>
        gxAxis
          .attr('transform', `translate(0, ${this.height + height2 - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(d3.axisBottom(focusXScale).ticks(d3.timeMonth).tickFormat(d3.timeFormat('%b-%y')).tickPadding(0))
          .selectAll('text:first-of-type')
          .attr('y', 10)
          .attr('x', -16);

    const xAxisWeek: (
      gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
      height2?: number,
      scaleType?: d3.ScaleTime<number, number>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
      height2 = 0,
      scaleType = focusXScale
    ) =>
        gxAxis
          .attr('transform', `translate(0, ${this.height + height2 - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(d3.axisBottom(scaleType).ticks(d3.timeWeek).tickFormat(d3.timeFormat('%e-%b-%y')).tickPadding(0))
          .selectAll('text:first-of-type')
          .attr('y', 10)
          .attr('x', -16);

    const xAxisDay: (
      gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
      height2?: number,
      scaleType?: d3.ScaleTime<number, number>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      gxAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>,
      height2 = 0,
      scaleType = focusXScale
    ) =>
        gxAxis
          .attr('transform', `translate(0, ${this.height + height2 - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(d3.axisBottom(scaleType).ticks(d3.timeDay).tickFormat(d3.timeFormat('%e-%b-%y')).tickPadding(0))
          .selectAll('text:first-of-type')
          .attr('y', 10)
          .attr('x', 8);

    const xAxis2: (
      e: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      e: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => xAxis(e, this.height2);
    const xAxisWeek2: (
      e: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      e: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => xAxisWeek(e, this.height2, contentXScale);
    const xAxisDay2: (
      e: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      e: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => xAxisDay(e, this.height2, contentXScale);

    const yAxis: (
      gyAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      gyAxis: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) =>
        gyAxis
          .attr('transform', `translate(${this.margin.left}, 0)`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(
            d3
              .axisLeft(focusYScale)
              .ticks(this.height / 60)
              .tickSizeOuter(0)
              .tickSize(-this.width + this.margin.left + this.margin.right)
          )
          .call(gy => gy.select('.domain').remove())
          .selectAll('text')
          .attr('x', -8);

    const yAxis2: (
      gyAxis2: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown> = (
      gyAxis2: d3.Selection<SVGGElement, unknown, HTMLElement, undefined>
    ) =>
        gyAxis2
          .attr('transform', `translate(${this.margin.left}, ${this.height - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .call(
            d3
              .axisLeft(contentYScale)
              .ticks(this.height2 / 30)
              .tickSizeOuter(0)
              .tickSize(-this.width + this.margin.left + this.margin.right)
          )
          .call(gy2 => gy2.select('.domain').remove())
          .selectAll('text')
          .attr('x', -8);

    const nodeCounts = (): number =>
      d3.select('g.focus').select('g.axis_group').select('g.x').selectAll('.tick').nodes().length;

    const viewIntervalData: (date: Date) => DailyCostValue[] = (date: Date): DailyCostValue[] => {
      const intervalData: DailyCostValue[] = [...this.dailyCostValues].slice(
        [...this.dailyCostValues].findIndex(v => {
          const valueTime = zoneCorrection(v.usageDate);
          const valueTimeString = `${valueTime.getUTCFullYear()}-${valueTime.getUTCMonth() + 1
            }-${valueTime.getUTCDate()}`;
          const dateTimeString = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
          return valueTimeString === dateTimeString;
        })
      );
      return intervalData;
    };

    const timeStampAdjust: () => void = (): void => {
      const timeStamp = d3
        .select('g.focus')
        .select('g.axis_group')
        .select('g.x')
        .selectAll<SVGGElement, DailyCostValue>('.tick')
        .selectAll<SVGTextElement, DailyCostValue>('text:first-of-type');
      if (nodeCounts() >= 15) {
        timeStamp.style('transform', 'rotate(-30deg)');
      } else {
        timeStamp.style('transform', '');
      }
    };

    return {
      /** d3 brush draw and moves on end */
      brush: brush,
      /** get focus group date scale */
      focusXScale: focusXScale,
      /** get content group date scale */
      contentXScale: contentXScale,
      /** get focus group cost scale */
      focusYScale: focusYScale,
      /** get content group cost scale */
      contentYScale: contentYScale,
      /** draw focus group line */
      drawFocusLine: drawFocusLine,
      /** draw content group line */
      drawContentLine: drawContentLine,
      /** remap daily cost values to {date, cost} */
      contentData: contentData,
      /** d3.select all the anomaly dots */
      anomalyDots: dots,
      /** draw axis x ticks on focus group */
      xAxis: xAxis,
      /** draw ticks by week on focus group */
      xAxisWeek: xAxisWeek,
      /** draw ticks by day on focus group */
      xAxisDay: xAxisDay,
      /** draw axis x ticks on content group */
      xAxis2: xAxis2,
      /** draw ticks by week on content group */
      xAxisWeek2: xAxisWeek2,
      /** draw ticks by day on content group */
      xAxisDay2: xAxisDay2,
      /** draw axis y ticks on focus group */
      yAxis: yAxis,
      /** draw axis y ticks on content group */
      yAxis2: yAxis2,
      /** get nodes on axis x
       * @returns number
       * */
      nodeCounts: nodeCounts,
      /** get view interval data
       * @returns DailyCostValue[]
       * */
      viewIntervalData: viewIntervalData,
      /**
       * @param data: DailyCostValue[]
       * @returns interval scale
       * */
      intervalScale: intervalScale,
      /** rotate time stamp if too many nodes */
      timeStampAdjust: timeStampAdjust,
      /** d3.select focus_line */
      focusLine: d3.select<SVGGElement, DailyCostValue>('g.focus_line'),
      /** d3.select focus group */
      focusGroup: d3.select<SVGGElement, DailyCostValue>('g.focus'),
      /** d3.select content group */
      contentGroup: d3.select<SVGGElement, DailyCostValue>('g.content'),
      /** d3.select anomaly_dots group in focus group */
      focusAnomalyDotGroup: d3.select<SVGGElement, DailyCostValue>('g.focus').select('g.anomaly_dots'),
      /** d3.select x axis in focus group */
      focusAxisGroup: d3.select<SVGGElement, DailyCostValue>('g.focus').select('g.axis_group').select('g.x'),
      /** d3.select x axis in content group */
      contentAxisGroup: d3.select<SVGGElement, DailyCostValue>('g.content').select('g.axis_group').select('g.x'),
      /** @returns date + time zone offset */
      zoneCorrection: zoneCorrection,
    };
  }

  private chartInit(): void {
    const svg = d3
      .select<HTMLElement, DailyCostValue>(this.element)
      .append('svg')
      .attr(
        'viewBox',
        `0, 0, ${this.width - (this.margin.left + this.margin.right) / 2}, ${this.divHeight + this.margin.bottom / 2}`
      )
      .attr('width', this.width)
      .attr('height', this.divHeight)
      .attr('sid', this.id);
    this.adjustedXScale = [this.margin.left, this.width - this.margin.right];
    for (let i = 0; i < 3; i++) {
      svg
        .append('circle')
        .attr('fill', 'currentColor')
        .attr('cx', (this.width - this.margin.left - this.margin.right) / 2)
        .attr('cy', (this.height - this.margin.top) / 2)
        .style('animation', `loading 2s ${(2 / 3) * i}s ease-in-out infinite`);
    }
    // this.logger.debug('svg:', [
    //   d3.select(this.element).select('svg').node(),
    // ]);
  }

  private createChart(): void {
    const base = this.basicFunc();
    d3.select(this.element).select('svg').remove();
    d3.selectAll('div.popout_info').remove();
    d3.selectAll('div.anomaly_legend').remove();
    // this.logger.debug('chart created:', [d3.select('svg')]);
    // const localStorageContent = JSONUtil.parse(localStorage.getItem('APP_REDUX_STATE'));
    // const isHackerTheme = localStorageContent['layout']['themeColor'] === 'hacker';
    const svg = d3
      .select<HTMLElement, DailyCostValue>(this.element)
      .append('svg')
      .attr(
        'viewBox',
        `0, 0, ${this.width - (this.margin.left + this.margin.right) / 2}, ${this.divHeight + this.margin.bottom / 2}`
      )
      .attr('width', this.width)
      .attr('height', this.divHeight)
      .attr('sid', this.id);

    const clipPath = svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.width - this.margin.left - this.margin.right)
      .attr('height', this.divHeight)
      .attr('x', (this.margin.left + this.margin.right) / 2)
      .attr('y', 0);

    const clipPath2 = svg
      .select('defs')
      .append('clipPath')
      .attr('id', 'clip2')
      .append('rect')
      .attr('width', this.width - this.margin.right)
      .attr('height', this.divHeight)
      .attr('x', this.margin.left)
      .attr('y', 0);

    const focusGroup = svg
      .append('g')
      .attr('transform', `translate(${this.margin.left / 2}, 0)`)
      .attr('class', 'focus');

    const focusAxisGroup = focusGroup.append('g').attr('class', 'axis_group');

    // X axis label
    focusAxisGroup
      .append('g')
      .attr('class', 'axis x')
      .attr(
        'transform',
        `translate(0, ${(this.displayTimeline ? this.height : this.divHeight) - this.margin.top - this.margin.bottom})`
      )
      .call(base.xAxis);

    // Y axis label
    focusAxisGroup
      .append('g')
      .attr('class', 'axis y')
      .call(base.yAxis)
      .append('text')
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', '700')
      .attr('transform', `translate(-40, ${this.margin.top / 2})`)
      .text(this.anomalyDetectionProfile.yLabel);

    // Add line
    const focusDataGroup = focusGroup.append('g').attr('class', 'data_group').attr('clip-path', 'url(#clip)');

    focusDataGroup
      .append('g')
      .attr('class', 'data paths')
      .append('path')
      .datum(this.dailyCostValues)
      .attr('class', 'data_path')
      .attr('fill', 'none')
      .attr('stroke', this.colors.colorSeries[0])
      .attr('d', <never>base.drawFocusLine)
      .style('transition', '.4s');

    focusDataGroup
      .append('rect')
      .attr('width', this.width - this.margin.right)
      .attr('height', this.displayTimeline ? this.height : this.divHeight)
      .attr('fill', 'transparent')
      .attr('class', 'overlay')
      .on('mouseover', () => focusLine.style('display', null))
      .on('mouseout', () => focusLine.style('display', 'none'))
      .on('mousemove', (event: MouseEvent) => this.mousemove(event))
      .on('mouseup', () => {
        d3.selectAll('div.popout_info').remove();
        d3.selectAll('g.selected_dot').remove();
        this.selectedAnomalyDot = undefined;
        this.selectedAnomalyDotChange.emit(this.selectedAnomalyDot);
      });

    // Add anomaly dots
    const focusAnomalyDotGroup = focusDataGroup.append('g').attr('class', 'anomaly_dots');

    focusAnomalyDotGroup
      .append('g')
      .attr('class', 'data dots')
      .selectAll('.dot')
      .data(this.anomalyPoints.values)
      .enter()
      .append('circle')
      // .attr('fill', this.colors.colorSeries[0])
      .attr('fill', 'currentColor')
      .attr('val', 'cost')
      .attr('cx', d =>
        base.focusXScale(base.zoneCorrection(this.dailyCostValues.find(v => v?.usageDate === d.anomalyDate)?.usageDate)))
      .attr('cy', d => base.focusYScale(d.cost))
      .attr('r', 2.5)
      .style('transition', '.4s');

    focusAnomalyDotGroup
      .append('g')
      .attr('class', 'data dots click_field')
      .selectAll('.dot')
      .data(this.anomalyPoints.values)
      .enter()
      .append('circle')
      .attr('fill', 'transparent')
      .attr('val', 'cost')
      .attr('cx', d =>
        base.focusXScale(base.zoneCorrection(this.dailyCostValues.find(v => v?.usageDate === d.anomalyDate)?.usageDate)))
      .attr('cy', d => base.focusYScale(d.cost))
      .attr('r', 7.5)
      .style('transition', '.4s')
      .on('mouseover', () => focusLine.style('display', null))
      .on('mouseout', () => focusLine.style('display', 'none'))
      .on('mousemove', (event: MouseEvent) => this.mousemove(event))
      .on('mouseup', (event: MouseEvent, data) => {
        this.logActive && this.logger.debug('on dot select:', [event, data]);
        // this.selectedAnomalyDot = data;
        this.selectedAnomalyDotChange.emit(data);
        this.displayPopoutInfo(data);
      });

    // Hover display times
    const focusLine = svg.select('g.focus').append('g').attr('class', 'focus_line').style('display', 'none');

    focusLine.append('line').attr('stroke', 'currentColor').attr('stroke-width', 0.5);

    focusLine.append('text').attr('class', 'info_date').attr('x', 9).attr('dy', '2rem').attr('fill', 'currentColor');

    focusLine.append('text').attr('class', 'info_cost').attr('x', 9).attr('dy', '3rem').attr('fill', 'currentColor');

    if (!this.displayTimeline) {
      const contextNodeCounts = focusAxisGroup.selectAll('.tick').nodes();
      if (this.dailyCostValues.length < new Date().getUTCDate() * 6 && contextNodeCounts.length < 10) {
        focusAxisGroup.select<SVGGElement>('.axis.x').call(e => base.xAxisWeek(e, this.height2));
      }
      if (this.dailyCostValues.length < new Date().getUTCDate() && contextNodeCounts.length < 5) {
        focusAxisGroup.select<SVGGElement>('.axis.x').call(e => base.xAxisDay(e, this.height2));
      }

      focusAxisGroup
        .select('g.axis.x')
        .append('text')
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .attr('font-weight', '700')
        .attr('transform', `translate(${this.width / 2}, ${this.margin.bottom + 4})`)
        .text(this.anomalyDetectionProfile.xLabel);
    }

    if (this.displayTimeline) {
      const contextAxisGroup = svg.append('g').attr('class', 'context').append('g').attr('class', 'axis_group');

      // X axis label
      contextAxisGroup
        .append('g')
        .attr('class', 'axis x')
        .attr('transform', `translate(0, ${this.height2 - this.margin.bottom})`);

      if (base.nodeCounts() <= 10) {
        contextAxisGroup.select<SVGGElement>('g.axis.x').call(base.xAxisWeek2);
      } else if (base.nodeCounts() <= 5) {
        contextAxisGroup.select<SVGGElement>('g.axis.x').call(base.xAxisDay2);
      } else {
        contextAxisGroup.select<SVGGElement>('g.axis.x').call(base.xAxis2);
      }

      contextAxisGroup
        .select('g.axis.x')
        .append('text')
        .attr('fill', 'currentColor')
        .attr('text-anchor', 'start')
        .attr('font-weight', '700')
        .attr('transform', `translate(${this.width / 2}, ${this.margin.bottom + 4})`)
        .text(this.anomalyDetectionProfile.xLabel);

      const contextNodeCounts = contextAxisGroup.selectAll('.tick').nodes();
      if (this.dailyCostValues.length < new Date().getUTCDate() * 6 && contextNodeCounts.length < 10) {
        contextAxisGroup.select<SVGGElement>('.axis.x').call(e => base.xAxisWeek(e, this.height2));
      }
      if (this.dailyCostValues.length < new Date().getUTCDate() && contextNodeCounts.length < 5) {
        contextAxisGroup.select<SVGGElement>('.axis.x').call(e => base.xAxisDay(e, this.height2));
      }

      // Y axis label
      contextAxisGroup.append('g').attr('class', 'axis y').call(base.yAxis2);

      const contextGroup = svg
        .select('g.context')
        .attr('transform', `translate(${this.margin.left / 2}, 0)`)
        .append('g');

      const contextDataGroup = contextGroup
        .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
        .attr('class', 'data_group');

      contextDataGroup
        .append('g')
        .attr('class', 'data paths')
        .append('path')
        .datum(this.dailyCostValues)
        .attr('class', 'data_path')
        .attr('fill', 'none')
        .attr('stroke', this.colors.colorSeries[0])
        .attr('d', <never>base.drawContentLine)
        .style('transition', '.4s');

      // Add anomaly dots
      const contextAnomalyDotGroup = contextDataGroup.append('g').attr('class', 'anomaly_dots');

      contextAnomalyDotGroup
        .append('g')
        .attr('class', 'data dots')
        .selectAll('.dot')
        .data(this.anomalyPoints.values)
        .enter()
        .append('circle')
        // .attr('fill', this.colors.colorSeries[index])
        .attr('fill', 'currentColor')
        .attr('val', 'cost')
        .attr('cx', d =>
          base.contentXScale(
            base.zoneCorrection(this.dailyCostValues.find(date => date?.usageDate === d.anomalyDate)?.usageDate)
          ))
        .attr('cy', d => base.contentYScale(d.cost))
        .attr('r', 2);

      const contextBrush = contextGroup
        .append('g')
        .attr('class', 'context_brush')
        .append<SVGGElement>('g')
        .attr('class', 'brush')
        .call(base.brush)
        .call(
          (g, e) => base.brush.move(g, e),
          () => {
            this.logActive &&
              this.logger.debug('context bush adjustedXScale', [
                this.adjustedXScale,
                [this.margin.left, this.width - this.margin.right],
              ]);
            if (
              this.adjustedXScale?.[0] === this.margin.left &&
              this.adjustedXScale?.[1] === this.width - this.margin.right
            ) {
              this.adjustedXScale = undefined;
            }
            return this.adjustedXScale;
          }
        );
      this.logActive && this.logger.debug(':', [contextBrush]);
    }

    if (this.displayLegends) {
      const legend = d3
        .select(svg.node())
        .append('g')
        .attr('class', 'anomaly_legend')
        .attr('transform', `translate(${this.width - this.margin.right - this.margin.left}, 20)`);
      // .select(svg.node()['parentElement'])
      // .append('div')
      // .attr('class', 'anomaly_legend')
      // .style('top', 20 + 'px')
      // .style('right', this.margin.right / 2 + 'px');

      legend.append('text').text(this.lang('COST.COST')).style('fill', this.colors.colorSeries[0]);
      // .append('p')
      // .text(this.lang('COST.COST'))
      // .style('color', this.colors.colorSeries[0]);
    }
    const contextAxisGroup = svg.select('g.context').select('g.axis_group');

    if (focusAxisGroup.select('g.axis.x').selectAll('g.tick').nodes().length >= 20) {
      focusAxisGroup
        .select('g.axis.x')
        .selectAll('g.tick')
        .selectAll('text:first-of-type')
        .style('transform', 'rotate(-30deg)');
    } else {
      focusAxisGroup.select('g.axis.x').selectAll('g.tick').selectAll('text:first-of-type').style('transform', '');
    }

    if (contextAxisGroup.select('g.axis.x').selectAll('g.tick').nodes().length >= 20) {
      contextAxisGroup
        .select('g.axis.x')
        .selectAll('g.tick')
        .selectAll('text:first-of-type')
        .style('transform', 'rotate(-30deg)');
    } else {
      contextAxisGroup.select('g.axis.x').selectAll('g.tick').selectAll('text:first-of-type').style('transform', '');
    }
    this.logActive && this.logger.debug(':', [clipPath, clipPath2]);
  }

  /**
   * Dropline function
   * @param event MouseEvent
   */
  private mousemove(event: MouseEvent): void {
    const base = this.basicFunc();
    const mouseX: number = d3.pointer(event)[0];
    this.logActive && this.logger.debug('mouse event:', [event, mouseX, this.adjustedXScale]);
    if (!this.adjustedXScale) return;
    if (mouseX >= this.width - this.margin.right || mouseX <= this.margin.left) return;
    const bisectDate = (array: ArrayLike<Azure_AP>, x: Date, lo?: number, hi?: number): number =>
      d3.bisector((val: Azure_AP) => base.zoneCorrection(val.date)).left(array, x, lo, hi);
    const mouseDate = base.focusXScale
      .domain(this.adjustedXScale.map(v => base.contentXScale.invert(v), base.contentXScale))
      .invert(mouseX);
    const mousePos = bisectDate(base.contentData, mouseDate, 1);
    const d0: Azure_AP = base.contentData[mousePos - 1];
    const d1: Azure_AP = base.contentData[mousePos];
    this.logActive && this.logger.debug('mousePos:', [base.contentData, mousePos, d0, d1]);
    if (!d0 || !d1) return;
    const dSector =
      mouseDate.getTime() - base.zoneCorrection(d0.date).getTime() >
        base.zoneCorrection(d1.date).getTime() - base.zoneCorrection(mouseDate).getTime()
        ? d1
        : d0;
    const timeStamp: (date: Date) => string = (date: Date): string => base.zoneCorrection(date).toDateString();
    const yLong: () => number = (): number => {
      const getLong = this.height - this.margin.bottom - base.focusYScale(dSector.cost);
      if (getLong >= this.margin.bottom) return getLong;
      else return -64;
    };
    base.focusLine.attr(
      'transform',
      `translate(${base.focusXScale(base.zoneCorrection(dSector.date))}, ${base.focusYScale(dSector.cost)})`
    );
    base.focusLine.select('line').attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', yLong);
    base.focusLine.select('text.info_date').text(timeStamp(dSector.date));
    base.focusLine
      .select('text.info_cost')
      .text(`${this.lang('COST.COST')}: $${Math.round(dSector.cost)}`);
    if (base.focusXScale(base.zoneCorrection(dSector.date)) > this.width - this.margin.right * 8) {
      base.focusLine.selectAll('text').attr('x', -(9 + this.margin.right * 6));
    } else {
      base.focusLine.selectAll('text').attr('x', 9);
    }
    if (base.focusYScale(dSector.cost) > this.height - this.margin.bottom * 4) {
      base.focusLine.select('text.info_date').attr('dy', '-4rem');
      base.focusLine.select('text.info_cost').attr('dy', '-3rem');
    } else {
      base.focusLine.select('text.info_date').attr('dy', '2rem');
      base.focusLine.select('text.info_cost').attr('dy', '3rem');
    }
  }

  /**
   * Draw brush area
   * @param event D3BrushEvent<Datum>
   */
  private updateChart(event: d3.D3BrushEvent<AnomalyPoint>): void {
    this.logActive && this.logger.debug('update event:', event);
    if (!event.sourceEvent) return;
    d3.selectAll('div.popout_info').remove();
    d3.selectAll('g.selected_dot').remove();
    const base = this.basicFunc();
    const selectionRight = <number>event?.selection?.['1'] || -1;
    const selectionLeft = <number>event?.selection?.['0'] || -1;
    const selectionRange = selectionRight - selectionLeft;
    let brushDate = [...this.dailyCostValues];
    let range: [Date | string, Date | string] = [undefined, undefined];
    if (selectionRange > 0) {
      range[0] = base.focusXScale
        .domain([selectionLeft, selectionLeft].map(v => base.contentXScale.invert(v), base.contentXScale))
        .invert(selectionLeft);
      range[1] = base.focusXScale
        .domain([selectionRight, selectionRight].map(v => base.contentXScale.invert(v), base.contentXScale))
        .invert(selectionRight);
      range[0] = `${range[0].getUTCFullYear()}-${range[0].getUTCMonth() + 1}-${range[0].getUTCDate()}`;
      range[1] = `${range[1].getUTCFullYear()}-${range[1].getUTCMonth() + 1}-${range[1].getUTCDate()}`;
      brushDate = brushDate.slice(
        this.dailyCostValues.findIndex(v => {
          const vTime = base.zoneCorrection(v.usageDate);
          const time = `${vTime.getUTCFullYear()}-${vTime.getUTCMonth() + 1}-${vTime.getUTCDate()}`;
          return time === range[0];
        }),
        this.dailyCostValues.findIndex(v => {
          const vTime = base.zoneCorrection(v.usageDate);
          const time = `${vTime.getUTCFullYear()}-${vTime.getUTCMonth() + 1}-${vTime.getUTCDate()}`;
          return time === range[1];
        }) + 1
      );
    } else range = [undefined, undefined];
    this.adjustedXScale = <[number, number]>event.selection || <[number, number]>base.contentXScale.range();
    base.focusXScale.domain(this.adjustedXScale.map(v => base.contentXScale.invert(v), base.contentXScale));
    base.focusGroup.select<SVGPathElement>('.data_path').attr('d', base.drawFocusLine as unknown as number);
    base.focusGroup.select('.axis.x').call(base.xAxis);
    this.logActive && this.logger.debug('lengths:', [brushDate.length, base.nodeCounts(), base.drawFocusLine]);
    base.timeStampAdjust();
    base.focusAnomalyDotGroup
      .selectAll('circle')
      .attr('cx', (d: AnomalyPoint) =>
        base.focusXScale(
          base.zoneCorrection(this.dailyCostValues.find(date => date?.usageDate === d.anomalyDate)?.usageDate)
        ));
    if ((brushDate.length > 14 && brushDate.length <= 30) || base.nodeCounts() <= 14) {
      base.focusGroup.select('.axis.x').call(base.xAxisWeek);
      base.timeStampAdjust();
      this.logActive && this.logger.debug('axis week');
    }
    if ((brushDate.length > 0 && brushDate.length <= 14) || base.nodeCounts() <= 7) {
      base.focusGroup.select('.axis.x').call(base.xAxisDay);
      base.timeStampAdjust();
      this.logActive && this.logger.debug('axis day');
    }
    if (base.nodeCounts() > 14) {
      base.focusGroup.select('.axis.x').call(base.xAxisWeek);
      base.timeStampAdjust();
      this.logActive && this.logger.debug('axis week');
    }
    if (base.nodeCounts() > 30) {
      base.focusGroup.select('.axis.x').call(base.xAxis);
      base.timeStampAdjust();
      this.logActive && this.logger.debug('axis month');
    }
    this.logActive &&
      this.logger.debug('dateValues:', [
        { range },
        { brushDate },
        { selectionRange: [selectionLeft, selectionRight] },
        { valueCounts: this.dailyCostValues },
        { nodeCounts: base.nodeCounts() },
        { brushEvent: event },
      ]);
    // this.displayPopoutInfo(this.selectedAnomalyDot);
  }

  /**
   * Change brush area for view interval change
   */
  private onViewIntervalChange(): void {
    const base = this.basicFunc();
    const allValues = [...this.dailyCostValues];
    let viewXScale: [number, number];
    if (this.viewInterval?.value) {
      const iValue = this.viewInterval.value;
      const intervalData = base.viewIntervalData(base.zoneCorrection(iValue));
      const intervalScale = base.intervalScale(intervalData).range() as [number, number];
      const dayInterval = this.width / allValues.length;
      viewXScale = [
        this.width - this.margin.left - dayInterval * (intervalData.length - 1),
        this.width - this.margin.right,
      ];
      if (viewXScale[0] < 0) viewXScale = intervalScale;
      if (
        (viewXScale[0] === this.margin.left || viewXScale[0] === this.width - this.margin.left) &&
        viewXScale[1] === this.width - this.margin.right
      ) {
        this.store.dispatch(
          this.actions.GlobalMsgAction({
            severity: MessageSeverity.WARN,
            summary: 'Out of Range',
            message: 'Out of Range',
          })
        );
        viewXScale = undefined;
        return;
      }
      // this.selectedAnomalyDot = undefined;
      // this.selectedAnomalyDotChange = undefined;
      this.adjustedXScale = viewXScale;
      d3.select<SVGGElement, AnomalyPoint>('g.brush').call(
        (g, e) => base.brush.move(g, e),
        () => {
          this.logActive && this.logger.debug('viewXScale Changes:', viewXScale);
          return viewXScale;
        }
      );
      this.SelectedViewInterval.emit(this.viewInterval);
      this.logActive &&
        this.logger.debug('on view interval change:', [
          { dayInterval: dayInterval },
          { adjustedXScale: this.adjustedXScale },
          { contentXScale: base.contentXScale.range() },
          { viewXScale: viewXScale },
          { viewInterval: this.viewInterval },
          { dataLength: allValues.length },
          { selectedAnomalyDot: this.selectedAnomalyDot },
          { selectedAnomalyDotChange: this.selectedAnomalyDotChange },
        ]);
    } else {
      this.logActive && this.logger.debug('viewInterval.value', [allValues, viewXScale]);
      d3.select<SVGGElement, AnomalyPoint>('g.brush').call(
        (g, e) => base.brush.move(g, e),
        () => undefined
      );
    }
  }

  /**
   * Dot on mouse click
   * @param data AnomalyPoint
   */
  private displayPopoutInfo(point: AnomalyPoint): void {
    this.logActive && this.logger.debug('displayPopoutInfo:', [point, this.selectedAnomalyDot]);
    if (!point) return;
    this.selectedAnomalyDot = point;
    let x: number, y: number;
    let dotMatch: d3.Selection<SVGCircleElement, AnomalyPoint, SVGGElement, undefined>;
    const base = this.basicFunc();
    base.anomalyDots.forEach(node => {
      const nodeData = d3.select<SVGCircleElement, AnomalyPoint>(node);
      // this.logger.debug('node data:', [nodeData.data(), data])
      if (nodeData.data().find(d => d.anomalyDate === point.anomalyDate)) {
        dotMatch = nodeData;
      }
      // this.logger.debug('dotMatch', dotMatch)
    });
    if (!dotMatch) {
      this.selectedAnomalyDot = undefined;
      return;
    }
    const elementSVG = d3.select<HTMLElement, DailyCostValue>(this.element).select<SVGSVGElement>('svg');
    const selectDiv = d3.select<HTMLElement, AnomalyPoint>(elementSVG.node().parentElement);
    const svgWidth: number = elementSVG.node().clientWidth;
    const svgHeight: number = elementSVG.node().clientHeight;
    const dotX: number = dotMatch.node().cx.baseVal.value;
    const dotY: number = dotMatch.node().cy.baseVal.value;
    const dotData: Azure_AP = dotMatch.data()[0];
    const boxColor: string[] = ['#19b293', '#5885ff', '#e458ff', '#ff9d1d'];

    let dailyCost: AnomalyPoint[];
    if (dotData.dailyCost) dailyCost = JSONUtil.parse(dotData.dailyCost);

    this.logActive &&
      this.logger.debug('popout:', [
        elementSVG,
        dotData,
        selectDiv,
        svgWidth,
        svgHeight,
        dotX,
        dotY,
        boxColor,
        dailyCost,
      ]);

    selectDiv.selectAll('div.popout_info').remove();
    elementSVG.selectAll('g.selected_dot').remove();

    const selectedDot = elementSVG
      .append('g')
      .attr('class', 'selected_dot')
      .attr('clip-path', 'url(#clip2)')
      .attr('pointer-events', 'none')
      .attr('opacity', '.5')
      .attr('style', 'translate: .4s');

    selectedDot
      .append('circle')
      .attr('fill', 'transparent')
      .attr('stroke', '#ff3300')
      .attr('stroke-width', 3)
      .attr('r', 10);

    selectedDot.selectAll('circle').attr('transform', `translate(${dotX + this.margin.left / 2}, ${dotY})`);

    if (!this.displayPopout) return;

    const popoutInfo = selectDiv.append('div').attr('class', 'popout_info');

    if (!dotData.dailyCost) popoutInfo.attr('style', 'min-height: unset');

    const title = popoutInfo.append('div').attr('class', 'title');

    title.append('h6').text(dotData.anomalyDate);

    title.append('span').text(`$${Math.round(dotData.cost)}`);

    popoutInfo.append('section');

    if (dailyCost?.length <= 0) {
      dailyCost.forEach((element, i): void => {
        const slot = popoutInfo.select('section').append('dl').style('background-color', boxColor[i]);

        slot
          .append('dt')
          .text(`${Object.values(element)[0] as string}`)
          .append('hr')
          .style('border-color', 'currentColor');
        // .text(`${Object.keys(element)[0]}: ${Object.values(element)[0]}`)
        slot.append('dd').text(`$${Math.round(element.cost)}`);
      });
    }

    const dialogWidth: number = d3.select<HTMLDivElement, undefined>('div.popout_info').node().clientWidth;
    const dialogHeight: number = d3.select<HTMLDivElement, undefined>('div.popout_info').node().clientHeight;
    if (svgWidth - dotX <= dialogWidth) {
      x = -dialogWidth - 30;
    } else {
      x = 30;
    }
    if (svgHeight - dotY <= dialogHeight + svgHeight * 0.2) {
      y = -dialogHeight - 30;
    } else {
      y = 30;
    }
    popoutInfo.style('left', dotX + x + 'px').style('top', dotY + y + 'px');
  }

  public showExportDialog(e: MouseEvent): void {
    this.logActive && this.logger.debug('dialog:', e);
    this.displayDialog = true;
  }

  public exportPNG(e: MouseEvent): void {
    const svg = this.anomalyLineChart.nativeElement.firstElementChild as SVGSVGElement;
    this.logActive && this.logger.debug('export:', [e, svg]);
    try {
      downloadPng(svg); // eslint-disable-line @typescript-eslint/no-unsafe-call
      d3.select<HTMLElement, DailyCostValue>(this.element).select('svg').remove();
      this.createChart();
      this.displayDialog = false;
    } catch (error: unknown) {
      this.store.dispatch(
        this.actions.GlobalMsgAction({
          severity: MessageSeverity.WARN,
          summary: `${String(error).replace(/(.*):.*/, '$1')}`,
          message: `${error as string}`,
        })
      );
      return;
    }
  }

  public exportSVG(e: MouseEvent): void {
    const svg = this.anomalyLineChart.nativeElement.firstElementChild as SVGSVGElement;
    this.logActive && this.logger.debug('export:', [e, svg]);
    this.displayDialog = false;
    downloadSvg(svg); // eslint-disable-line @typescript-eslint/no-unsafe-call
    d3.select<HTMLElement, DailyCostValue>(this.element).select('svg').remove();
    this.createChart();
  }
}

interface BaseFunc {
  brush: d3.BrushBehavior<AnomalyPoint>;
  focusXScale: d3.ScaleTime<number, number>;
  contentXScale: d3.ScaleTime<number, number>;
  focusYScale: d3.ScaleLinear<number, number>;
  contentYScale: d3.ScaleLinear<number, number>;
  drawFocusLine: d3.Line<DailyCostValue>;
  drawContentLine: d3.Line<DailyCostValue>;
  contentData: { date: Date; cost: number; }[];
  anomalyDots: SVGCircleElement[];
  xAxisWeek: (
    gxAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>,
    height2?: number,
    scaleType?: d3.ScaleTime<number, number>
  ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
  xAxisWeek2: (
    gxAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>,
    height2?: number,
    scaleType?: d3.ScaleTime<number, number>
  ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
  xAxisDay: (
    gxAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>,
    height2?: number,
    scaleType?: d3.ScaleTime<number, number>
  ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
  xAxisDay2: (
    gxAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>,
    height2?: number,
    scaleType?: d3.ScaleTime<number, number>
  ) => d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>;
  xAxis: (
    gxAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown>;
  xAxis2: (
    gxAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown>;
  yAxis: (
    gyAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown>;
  yAxis2: (
    gyAxis: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown>;
  nodeCounts: () => number;
  viewIntervalData: (date: Date) => DailyCostValue[];
  intervalScale: (data: DailyCostValue[]) => d3.ScaleTime<number, number>;
  timeStampAdjust: () => void;
  focusGroup: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>;
  contentGroup: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>;
  focusAnomalyDotGroup: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>;
  contentAxisGroup: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>;
  focusAxisGroup: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>;
  focusLine: d3.Selection<SVGGElement, DailyCostValue, HTMLElement, any>;
  zoneCorrection: (d: Date | string) => Date;
}

interface Changes extends SimpleChanges {
  data: NgChange<AnomalyDetectionResult & string[][]>;
  displayExport: NgChange<boolean>;
  displayLegends: NgChange<boolean>;
  displayPopout: NgChange<boolean>;
  displayTimeline: NgChange<boolean>;
  chartHeight: NgChange<string>;
  marginInput: NgChange<number[] | number>;
  selectedAnomalyDot: NgChange<AnomalyPoint>;
  viewInterval: NgChange<SelectItem<Date | string>>;
}
