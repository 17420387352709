import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fillText' })
export class TextReplacementPipe implements PipeTransform {
  public transform(value: number | string, replacement?: string): number | string {
    const replace = replacement?.length > 0 ? replacement : '-';

    if (typeof value === 'string') return value.length === 0 ? replace : value;
    else if (typeof value === 'number') return value;
    else return replace;
  }
}
