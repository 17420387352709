/* eslint-disable
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/unbound-method
 */
// Angular
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
// Interface
import { ForecastDailyCost } from '@azure-usage/model';
import { MarginInfo } from '@base/model';
// import { ForecastCostResult, ForecastDailyCost } from '@azure-usage/model';
import { AnomalyPoint as Azure_AP, DailyCostValue, ForecastProfile, ForecastResult } from '@azure-usage/model';
import { AnomalyPoint as GCP_AP } from '@gcp-usage/model';
// D3
import * as d3 from 'd3';

type AnomalyPoint = Azure_AP | GCP_AP;

@Component({
  selector: 'caloudi-forecast-linechart',
  templateUrl: './forecast-linechart.component.html',
  styleUrls: ['./forecast-linechart.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ForecastLinechartComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('forecastLineChart') private readonly forecastLineChart: ElementRef<HTMLElement>;

  @Input('data') public data: ForecastResult;
  @Input('margin') public marginInput: number[] | number;
  @Input('height') public chartHeight: string = '68vh';
  @Input('selectedDot') public selectedDot: AnomalyPoint | ForecastDailyCost;
  @Output('selectedDotChange') public selectedDotChange = new EventEmitter<AnomalyPoint | ForecastDailyCost>();

  private init: boolean = true;
  private adjustedXScale: [number, number];
  private element: Element;
  private margin: MarginInfo = { left: 20, top: 40, right: 20, bottom: 40 };
  private width: number;
  private divHeight: number;
  private height: number;
  private height2: number;
  private forecastProfile: ForecastProfile;
  // private dailyCostValues: ForecastDailyCost[];
  // private forecastCostResult: ForecastCostResult;
  private actualValues: DailyCostValue[];
  private diffValues: DailyCostValue[];
  private forecastValues: DailyCostValue[];
  private predictValues: DailyCostValue[];

  public dataLines: string[];
  public colorIndex: number[] = [];
  public colors: D3Colors = {
    strokeline: '#66666680',
    colorSeries: [
      '#e34d4d',
      '#28d1bd',
      '#476ce6',
      '#4cd128',
      '#ff00ff',
      '#ffff00',
      '#ffaa00',
      '#ff66cc',
      '#ffcc00',
      '#ffaaff',
      '#aaffff',
      '#aa00aa',
    ],
  };

  // @HostListener('window:resize', ['$event'])
  // onresize(event: Event) {
  //   this.resize();
  // }

  constructor(public readonly injector: Injector, private readonly ngZone: NgZone) {
    super(injector);
    window.onresize = () => {
      this.ngZone.run(() => {
        this.resize();
      });
    };
  }

  public ngOnInit(): void {
    this.setMargin();
  }

  public ngAfterViewInit(): void {
    this.element = this.forecastLineChart.nativeElement;
    this.element.setAttribute('style', `height: ${this.chartHeight}`);
    this.width = this.element.clientWidth;
    this.divHeight = this.element.clientHeight;
    this.height = this.element.clientHeight * 0.8;
    this.height2 = this.element.clientHeight * 0.2;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // this.logger.debug('any changes:', changes);
    if (changes.data) {
      // this.logger.debug('data changed:', changes.data)
      if (changes.data.currentValue !== undefined || changes.data.currentValue !== changes.data.previousValue) {
        this.forecastProfile = this.data.forecastProfile;
        // this.forecastCostResult = this.data.forecastCostResult;
        this.forecastValues = this.data.forecastCostResult.forecastValues;
        this.predictValues = this.data.forecastCostResult.predictValues;
        this.actualValues = this.data.forecastCostResult.actualValues;
        // this.dailyCostValues = this.data.forecastDailyCost.values;
        this.diffValues = this.data.forecastCostResult.diffValues;
        this.createChart();
        // this.logger.debug('on change ele:', this.forecastLineChart)
      }
    }
  }

  private resize(): void {
    if (this.data) {
      this.width = this.forecastLineChart.nativeElement.parentElement.clientWidth;
      this.divHeight = this.forecastLineChart.nativeElement.clientHeight;
      this.height = this.forecastLineChart.nativeElement.clientHeight * 0.8;
      this.height2 = this.forecastLineChart.nativeElement.clientHeight * 0.2;
      this.init = true;
      // const xMove = (this.xScale[1] - this.margin.right) / this.width
      // this.xScale = [this.xScale[0] * xMove, this.xScale[1] * xMove]
      // this.logger.debug('width change:', this.width)
      this.createChart();
    }
  }

  private setMargin(): MarginInfo {
    if (Array.isArray(this.marginInput)) {
      if (this.marginInput.length === 2) {
        return (this.margin = {
          left: this.marginInput[0],
          top: this.marginInput[1],
          right: this.marginInput[0],
          bottom: this.marginInput[1],
        });
      }
      if (this.marginInput.length === 3) {
        return (this.margin = {
          left: this.marginInput[0],
          top: this.marginInput[1],
          right: this.marginInput[0],
          bottom: this.marginInput[2],
        });
      }
      if (this.marginInput.length === 4) {
        return (this.margin = {
          left: this.marginInput[0],
          top: this.marginInput[1],
          right: this.marginInput[2],
          bottom: this.marginInput[3],
        });
      }
    }
    return (this.margin = {
      left: this.marginInput as number,
      top: this.marginInput as number,
      right: this.marginInput as number,
      bottom: this.marginInput as number,
    });
  }

  private baseFunc(): BaseFunc {
    let costArray: number[] = [];
    let dateArray: (Date | string)[] = [];
    this.actualValues.forEach(val => {
      costArray.push(val.cost);
      dateArray.push(val.usageDate);
    });
    this.forecastValues.forEach(val => {
      costArray.push(val.cost);
      dateArray.push(val.usageDate);
    });
    this.diffValues.forEach(val => costArray.push(val.cost));
    this.predictValues.forEach(val => costArray.push(val.cost));

    const brush: d3.BrushBehavior<unknown> = d3
      .brushX()
      .extent([
        [this.margin.left, this.margin.bottom],
        [this.width - this.margin.right, this.height2],
      ])
      .on('end', (event: d3.D3BrushEvent<unknown>) => {
        this.updateChart(event);
      });

    const lineArray = [[...this.actualValues], [...this.predictValues], [...this.diffValues], [...this.forecastValues]];

    const flatArray = [...this.actualValues, ...this.forecastValues];

    const label = [
      this.lang('FORECAST.ACTUAL_COST'),
      this.lang('FORECAST.PREDICT_COST'),
      this.lang('FORECAST.DIFF_COST'),
      this.lang('FORECAST.FORECAST_COST'),
      this.lang('COST.COST'),
    ];

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(dateArray, d => new Date(d)))
      .range([this.margin.left, this.width - this.margin.right]);

    const xScale2 = d3
      .scaleTime()
      .domain(d3.extent(dateArray, d => new Date(d)))
      .range([this.margin.left, this.width - this.margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain(d3.extent(costArray))
      .nice()
      .range([this.height - this.margin.bottom, this.margin.top]);

    const yScale2 = d3.scaleLinear().domain(d3.extent(costArray)).nice().range([this.height2, this.margin.top]);

    const line = d3
      .line()
      .x(d => xScale(new Date(d['usageDate'] as Date)))
      .y(d => yScale(d['cost'] as d3.NumberValue))
      .curve(d3.curveMonotoneX);

    const line2 = d3
      .line()
      .x(d => xScale2(new Date(d['usageDate'] as Date)))
      .y(d => yScale2(d['cost'] as d3.NumberValue))
      .curve(d3.curveMonotoneX);

    const area = d3
      .area()
      .x(d => xScale(new Date(d['usageDate'] as Date)))
      .y0(this.height - this.margin.bottom)
      .y1(d => yScale(d['cost'] as d3.NumberValue));

    const area2 = d3
      .area()
      .x(d => xScale2(new Date(d['usageDate'] as Date)))
      .y0(this.height2)
      .y1(d => yScale2(d['cost'] as d3.NumberValue));

    const xAxis: (
      gxAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) => d3.Selection<d3.BaseType, unknown, SVGElement, any> = (
      gxAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ): d3.Selection<d3.BaseType, unknown, SVGElement, any> =>
        gxAxis
          .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(
            d3.axisBottom(xScale).ticks(d3.timeMonth).tickFormat(d3.timeFormat('%y-%b')) as (
              selection: d3.Selection<SVGElement, unknown, HTMLElement, any>,
              ...args: any[]
            ) => void
          )
          .selectAll('text:first-of-type')
          .attr('y', 16)
          .attr('x', -8);

    const xAxis2: (
      gxAxis2: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown> = (
      gxAxis2: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) =>
        gxAxis2
          .attr('transform', `translate(0, ${this.height + this.height2 - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(
            d3.axisBottom(xScale2).ticks(d3.timeMonth).tickFormat(d3.timeFormat('%y-%b')) as (
              selection: d3.Selection<SVGElement, unknown, HTMLElement, any>,
              ...args: any[]
            ) => void
          )
          .selectAll('text:first-of-type')
          .attr('y', 16)
          .attr('x', -8);

    const yAxis: (
      gyAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown> = (
      gyAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) =>
        gyAxis
          .attr('transform', `translate(${this.margin.left}, 0)`)
          .attr('stroke-width', 0.5)
          .attr('stroke', this.colors.strokeline)
          .attr('fill', 'none')
          .call(
            d3
              .axisLeft(yScale)
              .ticks(this.height / 60)
              .tickSizeOuter(0)
              .tickSize(-this.width + this.margin.left + this.margin.right) as (
                selection: d3.Selection<SVGElement, unknown, HTMLElement, any>,
                ...args: any[]
              ) => void
          )
          .call(gy => gy.select('.domain').remove())
          .selectAll('text')
          .attr('x', -8);

    const yAxis2: (
      gyAxis2: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown> = (
      gyAxis2: d3.Selection<SVGElement, unknown, HTMLElement, any>
    ) =>
        gyAxis2
          .attr('transform', `translate(${this.margin.left}, ${this.height - this.margin.bottom})`)
          .attr('stroke-width', 0.5)
          .call(
            d3
              .axisLeft(yScale2)
              .ticks(this.height2 / 30)
              .tickSizeOuter(0)
              .tickSize(-this.width + this.margin.left + this.margin.right) as (
                selection: d3.Selection<SVGElement, unknown, HTMLElement, any>,
                ...args: any[]
              ) => void
          )
          .call(gy2 => gy2.select('.domain').remove())
          .selectAll('text')
          .attr('x', -8);

    return {
      brush: brush,
      xScale: xScale,
      xScale2: xScale2,
      yScale: yScale,
      yScale2: yScale2,
      line: line,
      line2: line2,
      area: area,
      area2: area2,
      xAxis: xAxis,
      xAxis2: xAxis2,
      yAxis: yAxis,
      yAxis2: yAxis2,
      focusGroup: d3.select('g.focus'),
      focusDataGroup: d3.select('g.data_group'),
      focusAxisGroup: d3.select('g.axis_group'),
      focusLine: d3.select('g.focus_line'),
      lineArray: lineArray,
      flatArray: flatArray,
      label: label,
    };
  }

  private createChart(): void {
    const base = this.baseFunc();
    d3.select('svg').remove();
    d3.selectAll('div.popout_info').remove();
    d3.selectAll('div.forecast_legend').remove();
    // this.logger.debug('cost array:', costArray)
    // this.logger.debug('date array:', dateArray)
    // const localStorageContent = JSONUtil.parse(localStorage.getItem('APP_REDUX_STATE'));
    // const isHackerTheme = localStorageContent['layout']['themeColor'] === 'hacker';

    const svg: d3.Selection<SVGElement, unknown, HTMLElement, any> = d3
      .select(this.element)
      .append('svg')
      .attr('viewBox', `${-this.margin.left}, 0, ${this.width + this.margin.left}, ${this.divHeight}`)
      .attr('width', this.width)
      .attr('height', this.divHeight);

    // Clip path
    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.width - this.margin.right - this.margin.left)
      .attr('height', this.divHeight)
      .attr('x', this.margin.left)
      .attr('y', 0);

    const focusGroup = svg
      .append('g')
      .attr('transform', `translate(${this.margin.left / 2}, 0)`)
      .attr('transform', `translate(${this.margin.left / 2}, 0)`)
      .attr('class', 'focus');

    const focusAxisGroup = focusGroup.append('g').attr('class', 'axis_group');

    // X axis label
    focusAxisGroup
      .append('g')
      .attr('class', 'axis x')
      .attr('transform', `translate(0, ${this.height - this.margin.top - this.margin.bottom})`)
      .call(base.xAxis as (selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>, ...args: any[]) => void);

    // Y axis label
    focusAxisGroup
      .append('g')
      .attr('class', 'axis y')
      .call(base.yAxis as (selection: d3.Selection<SVGGElement, unknown, HTMLElement, any>, ...args: any[]) => void)
      .append('text')
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', '700')
      .attr('transform', `translate(-40, ${this.margin.top / 2})`)
      .text(this.forecastProfile.yLabel);

    // Separate line
    focusAxisGroup
      .append('line')
      .attr('class', 'forecast_line')
      .attr('clip-path', 'url(#clip)')
      .attr('stroke', 'currentColor')
      .attr('stroke-width', 1)
      .attr('x1', base.xScale(new Date(this.actualValues[this.actualValues.length - 1].usageDate)))
      .attr('x2', base.xScale(new Date(this.actualValues[this.actualValues.length - 1].usageDate)))
      .attr('y1', this.margin.top)
      .attr('y2', this.height - this.margin.bottom)
      .style('transition', '.4s');

    // Add line
    const focusDataGroup = focusGroup.append('g').attr('class', 'data_group');

    base.lineArray.forEach((array, index) => {
      focusDataGroup
        .append('g')
        .attr('class', 'data paths')
        .append('path')
        .datum(array)
        .attr('class', 'data_path')
        .attr('clip-path', 'url(#clip)')
        // .attr('fill', this.colors.colorSeries[index] + '20')
        .attr('fill', 'none')
        .attr('stroke', this.colors.colorSeries[index])
        .attr('d', base.line as unknown as (number | string)[])
        .style('transition', '.4s');
    });

    // Make forecast line to area
    focusDataGroup
      .select('g.paths:last-of-type')
      .select('path.data_path')
      .attr('d', base.area)
      .attr('fill', this.colors.colorSeries[3] + '20');

    // Functional pane
    focusDataGroup
      .append('rect')
      .attr('width', this.width - this.margin.right)
      .attr('height', this.height)
      .attr('fill', 'transparent')
      .attr('clip-path', 'url(#clip)')
      .attr('class', 'overlay')
      .on('mouseover', () => focusLine.style('display', null))
      .on('mouseout', () => focusLine.style('display', 'none'))
      .on('mousemove', (event: MouseEvent) => this.mousemove(event));

    // Hover display times
    const focusLine = svg.select('g.focus').append('g').attr('class', 'focus_line').style('display', 'none');

    focusLine.append('line').attr('stroke', 'currentColor').attr('stroke-width', 0.5);

    focusLine.append('text').attr('class', 'info_date').attr('x', 9).attr('dy', '2rem').attr('fill', 'currentColor');

    focusLine.append('text').attr('class', 'info_cost').attr('x', 9).attr('dy', '4rem').attr('fill', 'currentColor');

    const contextAxisGroup = svg.append('g').attr('class', 'context').append('g').attr('class', 'axis_group');

    // X axis label
    contextAxisGroup
      .append('g')
      .attr('class', 'axis x')
      .attr('transform', `translate(0, ${this.height2 - this.margin.bottom})`)
      .call(base.xAxis2)
      .append('text')
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .attr('font-weight', '700')
      .attr('transform', `translate(${this.width / 2}, ${this.margin.bottom})`)
      .text(this.forecastProfile.xLabel);

    // Y axis label
    contextAxisGroup.append('g').attr('class', 'axis y').call(base.yAxis2);

    const contextGroup = svg
      .select('g.context')
      .attr('transform', `translate(${this.margin.left / 2}, 0)`)
      .append('g');

    // Add lines
    const contextDataGroup = contextGroup
      .attr('transform', `translate(0, ${this.height - this.margin.bottom})`)
      .attr('class', 'data_group');

    base.lineArray.forEach((array, index) => {
      contextDataGroup
        .append('g')
        .attr('class', 'data paths')
        .append('path')
        .datum(array)
        .attr('class', 'data_path')
        .attr('clip-path', 'url(#clip)')
        .attr('fill', 'none')
        .attr('stroke', this.colors.colorSeries[index])
        .attr('d', base.line2 as unknown as (number | string)[])
        .style('transition', '.4s');
    });

    // Forecast line area
    contextDataGroup
      .select('g.paths:last-of-type')
      .select('path.data_path')
      .attr('d', base.area2)
      .attr('fill', this.colors.colorSeries[3] + '20');

    const contextBrush = contextGroup.append('g').attr('class', 'context_brush');

    contextBrush
      .append('g')
      .attr('class', 'brush')
      .call(base.brush)
      .call(base.brush.move, () => {
        const scaleDate = this.actualValues[this.actualValues.length - this.forecastValues.length - 2].usageDate;
        if (this.init) {
          this.init = false;
          return [base.xScale2(new Date(scaleDate)), this.width - this.margin.right];
        } else {
          if (this.adjustedXScale[0] === this.margin.left && this.adjustedXScale[1] === this.width - this.margin.right)
            this.adjustedXScale = undefined;
          return this.adjustedXScale;
        }
      });

    const legend = d3
      .select(svg.node()['parentElement'])
      .append('div')
      .attr('class', 'forecast_legend')
      .style('top', '0px')
      .style('right', String(this.margin.right / 2) + 'px');

    base.lineArray.forEach((value, index) => {
      legend.append('p').text(base.label[index]).style('color', this.colors.colorSeries[index]);
      value;
    });

    /**
     * for ticks rotate
     */
    if (focusAxisGroup.selectAll('.tick').nodes().length >= 35) {
      focusAxisGroup
        .select('g.axis.x')
        .selectAll('g.tick')
        .selectAll('text:first-of-type')
        .style('transform', 'rotate(-30deg)');
    } else {
      focusAxisGroup.select('g.axis.x').selectAll('g.tick').selectAll('text:first-of-type').style('transform', null);
    }
    if (contextAxisGroup.selectAll('.tick').nodes().length >= 35) {
      contextAxisGroup
        .select('g.axis.x')
        .selectAll('g.tick')
        .selectAll('text:first-of-type')
        .style('transform', 'rotate(-30deg)');
    } else {
      contextAxisGroup.select('g.axis.x').selectAll('g.tick').selectAll('text:first-of-type').style('transform', null);
    }
  }

  /**
   * dropline function
   */
  private mousemove(event: MouseEvent): void {
    const base = this.baseFunc();
    const mouseX: number = d3.pointer(event)[0];
    // this.logger.debug('event', event, 'pointer:', D3Pointer(event))
    if (mouseX >= this.width - this.margin.right || mouseX <= this.margin.left) return;
    const bisectDate = d3.bisector((val: DailyCostValue) => new Date(val.usageDate)).left;
    const mouseDate = base.xScale.domain(this.adjustedXScale.map(base.xScale2.invert, base.xScale2)).invert(mouseX);
    // this.logger.debug('mouse date:', mouseDate)
    const mousePos = bisectDate(base.flatArray, mouseDate, 1);
    const d0: DailyCostValue = base.flatArray[mousePos - 1];
    const d1: DailyCostValue = base.flatArray[mousePos];
    const timeStamp = (date: Date): string => new Date(date).toDateString();
    const dSector =
      mouseDate.getTime() - new Date(d0.usageDate).getTime() > new Date(d1.usageDate).getTime() - mouseDate.getTime()
        ? d1
        : d0;
    const yLong = (): number => {
      const getLong = this.height - this.margin.bottom - base.yScale(dSector.cost);
      if (getLong >= this.margin.bottom) return getLong;
      else return -64;
    };
    base.focusLine.attr(
      'transform',
      `translate(${base.xScale(new Date(dSector.usageDate))}, ${base.yScale(dSector.cost)})`
    );
    base.focusLine.select('line').attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', yLong);
    base.focusLine.select('text.info_date').text(timeStamp(new Date(dSector.usageDate)));
    base.focusLine.select('text.info_cost').text(`${base.label[4]}: $${Math.floor(dSector.cost)}`);
    if (base.xScale(new Date(dSector.usageDate)) > this.width - this.margin.right * 8) {
      base.focusLine.selectAll('text').attr('x', -(9 + this.margin.right * 6));
    } else {
      base.focusLine.selectAll('text').attr('x', 9);
    }
    if (base.yScale(dSector.cost) > this.height - this.margin.bottom * 4) {
      base.focusLine.select('text.info_date').attr('dy', '-4rem');
      base.focusLine.select('text.info_cost').attr('dy', '-3rem');
    } else {
      base.focusLine.select('text.info_date').attr('dy', '2rem');
      base.focusLine.select('text.info_cost').attr('dy', '3rem');
    }
  }

  // Draw brush area
  private updateChart(event: d3.D3BrushEvent<unknown>): void {
    d3.selectAll('div.popout_info').remove();
    const base = this.baseFunc();
    this.adjustedXScale = <[number, number]>event.selection || <[number, number]>base.xScale2.range();
    base.xScale.domain(this.adjustedXScale.map(base.xScale2.invert, base.xScale2));
    base.focusGroup.selectAll('.data_path').attr('d', base.line);
    base.focusGroup.select('.axis.x').call(base.xAxis);
    base.focusGroup
      .select('line.forecast_line')
      .attr('x1', base.xScale(new Date(this.actualValues[this.actualValues.length - 1].usageDate)))
      .attr('x2', base.xScale(new Date(this.actualValues[this.actualValues.length - 1].usageDate)));
    base.focusDataGroup.select('g:last-of-type').select('path.data_path').attr('d', base.area);
    if (base.focusAxisGroup.selectAll('.tick').nodes().length >= 30) {
      base.focusAxisGroup
        .select('g.axis.x')
        .selectAll('g.tick')
        .selectAll('text:first-of-type')
        .style('transform', 'rotate(-30deg)');
    } else {
      base.focusAxisGroup.select('g.axis.x').selectAll('g.tick').selectAll('text:first-of-type').style('transform', '');
    }
  }
}

interface D3Colors {
  strokeline: string;
  colorSeries: string[];
}

interface BaseFunc {
  brush: d3.BrushBehavior<unknown>;
  xScale: d3.ScaleTime<number, number>;
  xScale2: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  yScale2: d3.ScaleLinear<number, number>;
  line: d3.Line<[number, number]>;
  line2: d3.Line<[number, number]>;
  area: d3.Area<[number, number]>;
  area2: d3.Area<[number, number]>;
  xAxis: (
    gxAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, any>;
  xAxis2: (
    gxAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, any>;
  yAxis: (
    gyAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown>;
  yAxis2: (
    gyAxis: d3.Selection<SVGElement, unknown, HTMLElement, any>
  ) => d3.Selection<d3.BaseType, unknown, SVGElement, unknown>;
  focusGroup: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  focusDataGroup: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  focusAxisGroup: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  focusLine: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
  lineArray: DailyCostValue[][];
  flatArray: DailyCostValue[];
  label: string[];
}
