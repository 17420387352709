// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Primeng
import { SelectItemGroup } from 'primeng/api';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';

@Injectable({ providedIn: 'root' })
export class CustomPeriodSelectorService {
  constructor(private readonly http: HttpClient) {}

  public getCommonTimeScopeGroupList(): Observable<SelectItemGroup<string>[]> {
    return this.http.get<SelectItemGroup<string>[]>(`${environment.cmpAPIServer.url}/api/list/timeScope`);
  }

  public getTimeScopeGroupListContainsAllTime(): Observable<SelectItemGroup<string>[]> {
    return this.http.get<SelectItemGroup<string>[]>(`${environment.cmpAPIServer.url}/api/list/timeScope/allTime`);
  }

  public getTimeScopeGroupListContains12Months(): Observable<SelectItemGroup<string>[]> {
    return this.http.get<SelectItemGroup<string>[]>(`${environment.cmpAPIServer.url}/api/list/timeScope/with12Months`);
  }
}
