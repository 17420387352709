// Angular
import { Injector } from '@angular/core';
// NGX
import { NGXLogger } from 'ngx-logger';

export class ParamCheckers {
  private readonly logger: NGXLogger;

  constructor(private readonly injector: Injector) {
    this.logger = this.injector.get(NGXLogger);
  }

  private readonly delimiterRangeErrorChecker = (chartDelimiters: number[]): boolean => {
    if (chartDelimiters && (chartDelimiters.slice(-1)[0] >= 100 || chartDelimiters[0] <= 0)) {
      this.logger.error('Gauge-chart Error: gauge delimiters have to be LARGER than 0 and LESS than 100');
      return false;
    }
    return true;
  };

  private readonly delimiterSortErrorChecker = (chartDelimiters: number[]): boolean => {
    let isErrorFree = true;
    if (chartDelimiters) {
      chartDelimiters.forEach((delimiter, i) => {
        if (i && delimiter < chartDelimiters[i - 1]) {
          this.logger.error('Gauge-chart Error: gauge delimiters are not sorted');
          isErrorFree = false;
        }
      });
    }
    return isErrorFree;
  };

  private readonly colorsLackWarnChecker = (chartDelimiters: number[], chartColors: string[]): void => {
    if (chartDelimiters && chartColors && chartDelimiters.length > chartColors.length - 1)
      this.logger.warn('Gauge-chart Warning: list of colors is not complete, standard colors added to the chart');
  };

  private readonly colorsExcessWarnChecker = (chartDelimiters: number[], chartColors: string[]): void => {
    if (chartDelimiters && chartColors && chartDelimiters.length < chartColors.length - 1)
      this.logger.warn('Gauge-chart Warning: list of colors exceeds number of slices, therefore it was shortened');
  };

  // private readonly needleValueWarnChecker = (needleValue: number): void => {
  //   if (needleValue < 0 || needleValue > 100)
  //     this.logger.warn('Gauge-chart Warning: value of needdle is less that 0 or larger than 100');
  // };

  private readonly rangeLabelNumberWarnChecker = (rangeLabel: string[]): void => {
    if (rangeLabel.length > 2)
      this.logger.warn('Gauge-chart Warning: number of range label parameters is bigger than 2');
  };

  private readonly warnChecker = (chartDelimiters: number[], chartColors: string[], rangeLabel: string[]): void => {
    this.colorsLackWarnChecker(chartDelimiters, chartColors);
    this.colorsExcessWarnChecker(chartDelimiters, chartColors);
    // needleValueWarnChecker(needleValue)
    this.rangeLabelNumberWarnChecker(rangeLabel);
  };

  private readonly errorChecker = (chartDelimiters: number[]): boolean => {
    return this.delimiterRangeErrorChecker(chartDelimiters) && this.delimiterSortErrorChecker(chartDelimiters);
  };

  /**
   * Function that checks whether there are any errors or typos in specified parameters.
   * Outputs to logger errors and warnings if there are any.
   * @param chartDelimiters - array of delimiters in percentage.
   * @param chartColors - array of colors.
   * @param needleValue - value at which needle points.
   * @returns modified needleValue.
   */
  public paramChecker = (chartDelimiters: number[], chartColors: string[], rangeLabel: string[]): boolean => {
    this.warnChecker(chartDelimiters, chartColors, rangeLabel);
    return this.errorChecker(chartDelimiters);
  };
}
