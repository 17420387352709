import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateAddDash' })
export class DateAddDashPipe implements PipeTransform {
  public transform(value: string): string {
    try {
      return value?.replace(/^[0-9]{6}$/, () => `${value.slice(0, 4)}-${value.slice(-2)}`);
    } catch (error) {
      console.error(error);
      return value;
    }
  }
}
