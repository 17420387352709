export enum APPTYPE {
  CSPDisti = 'CSPDisti',
  CSPPartner = 'CSPPartner',
  CSPReseller = 'CSPReseller',
  CMP = 'CMP',
  GCP = 'GCP',
}

export enum APPUSERTYPE {
  CSPDisti = 'DISTRIBUTOR',
  CSPPartner = 'PARTNER',
  CSPReseller = 'RESELLER',
  Customer = 'CUSTOMER',
}

export enum APPROUTETYPE {
  CSPDisti = 'disti',
  CSPPartner = 'partner',
  CSPReseller = 'reseller',
  CMPCustomer = 'customer',
}

export class APPTYPES {
  public router: APPROUTETYPE;
  constructor(private readonly payload: APPTYPE) {
    switch (this.payload) {
      case APPTYPE.CSPDisti:
        this.router = APPROUTETYPE.CSPDisti;
        break;
      case APPTYPE.CSPPartner:
        this.router = APPROUTETYPE.CSPPartner;
        break;
      case APPTYPE.CSPReseller:
        this.router = APPROUTETYPE.CSPReseller;
        break;
      default:
        this.router = APPROUTETYPE.CMPCustomer;
        break;
    }
  }
}
