// Angular
import { Injectable } from '@angular/core';
// Caloudi
import { JSONUtil } from '@util';
// Interface
import { OriginState } from '@core/model';

@Injectable({ providedIn: 'root' })
export class ComponentStateService {
  public loaded: boolean;
  private readonly localStorageKey: string = 'COMPONENT_STATE';
  private readonly staticStorageKey: string = 'STATIC_COMPONENT_STATE';
  private componentStates: Record<string, unknown> = {};
  private staticComponentStates: Record<string, unknown> = {};
  private originState: OriginState;

  constructor() {
    // Private logger: NGXLogger,
    this.componentStates = this.getStateFromLocatStorage(this.localStorageKey);
    this.staticComponentStates = this.getStateFromLocatStorage(this.staticStorageKey);
  }

  /**
   * This function will do partial update local storage then return full storage infomations.
   * @param key Local storage key
   * @param state State changes local storage
   * @returns {OriginState} { localStorageKey, stateChanges, previousStorage, currentStorage, fullStorage }
   */
  public setState<T>(key: string, state: T, preserve?: boolean): OriginState {
    const storage$ = preserve ? this.staticComponentStates : this.componentStates;
    const key$ = preserve ? this.staticStorageKey : this.localStorageKey;
    const localStorage$: T = storage$[key] as T;
    this.originState = {
      localStorageKey: key,
      stateChanges: undefined,
      previousStorage: this.getStateFromLocatStorage(key$)[key],
      fullStorage: undefined,
      currentStorage: localStorage$,
    };
    const stateChanges = {} as T;
    if (!this.originState.currentStorage) {
      preserve ? (this.staticComponentStates[key] = state) : (this.componentStates[key] = state);
      this.originState.stateChanges = state;
      this.originState.currentStorage = state;
      this.saveStateToLocatStorage({ ...storage$ }, key$);
    } else {
      // partial update
      Object.keys(state).forEach(stateKey => {
        if (this.originState.currentStorage[stateKey] !== state[stateKey])
          stateChanges[stateKey] = state[stateKey as keyof T];
        this.originState.currentStorage[stateKey] = state[stateKey as keyof T];
      });
      this.saveStateToLocatStorage({ ...storage$, [key]: this.originState.currentStorage }, key$);
    }
    stateChanges && Object.keys(stateChanges).length !== 0
      ? (this.originState.stateChanges = stateChanges)
      : (this.originState.previousStorage = undefined);
    this.originState.fullStorage = this.getStateFromLocatStorage(key$);
    return this.originState;
  }

  public getState<T>(key: string, preserve?: boolean): T {
    return (preserve ? this.staticComponentStates[key] : this.componentStates[key]) as T;
  }

  /**
   * this method will add state's properties to the existing local storage state
   * IF the state's properties do not exits in the local staorage state.
   * @param key Local storage key
   * @param state State changes local storage
   * @returns Retrun state from local storage with key
   */
  public mergeState<T>(key: string, state: T, preserve?: boolean): T {
    const storage$ = preserve ? this.staticComponentStates : this.componentStates;
    const key$ = preserve ? this.staticStorageKey : this.localStorageKey;
    const localStorage$: T = storage$[key] as T;
    // this.logger.debug('updateState-localStorage', localStorage)
    if (state && localStorage$) {
      const localStorageKeys = Object.keys(localStorage$);
      Object.keys(state).forEach(stateKey => {
        if (!localStorageKeys.includes(stateKey)) {
          localStorage$[stateKey] = state[stateKey as keyof T];
        }
      });
      // This will erase the states of other key.
      this.saveStateToLocatStorage({ ...storage$, [key]: localStorage$ }, key$);
      return localStorage$;
    } else if (!state && localStorage$) {
      return localStorage$;
    } else {
      this.saveStateToLocatStorage({ ...storage$, [key]: state }, key$);
      return state;
    }
  }

  public removeState(key: string): void {
    delete this.componentStates[key];
    this.saveStateToLocatStorage({ ...this.componentStates }, this.localStorageKey);
  }

  public getStateAll<T>(): T {
    return this.componentStates as T;
  }

  public clearState(): void {
    this.componentStates = {};
    localStorage.removeItem(this.localStorageKey);
  }

  private saveStateToLocatStorage<T>(state: T, localStorageKey: string): void {
    localStorage.setItem(localStorageKey, JSONUtil.stringify(state));
  }

  private getStateFromLocatStorage<T>(localStorageKey: string): T {
    const storedState = localStorage.getItem(localStorageKey);
    if (storedState !== null)
      try {
        return JSONUtil.parse<T>(storedState);
      } catch (error) {
        return {} as T;
      }
    else return {} as T;
  }
}
