import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    ACTION: {
      ACCEPT: '接受',
      ACCESS_DENIED: '访问被拒绝',
      ADD_CHILD: '添加子目录',
      ADD_COMMENT: '添加评论',
      ADD_CONTACT: '添加联系人',
      ADD_CRON_REPORT: '添加报告',
      ADD_DISCOUNT_PERCENT_RULE: '添加折扣率规则',
      ADD_ENROLLMENT: '添加注册',
      ADD_FEATURE_PACK: '添加功能包',
      ADD_FILTER: '添加筛选器',
      ADD_LABEL: '添加标签',
      ADD_NEW: '添加数据',
      ADD_ORGANIZATION_UNIT: '添加组织单元',
      ADD_ORGANIZATION: '添加组织',
      ADD_PAYMENT_METHOD: '添加支付方式',
      ADD_PRICING_CONFIG: '添加定价配置',
      ADD_PROBLEM_SUBTYPE: '添加问题子类',
      ADD_PROBLEM_TYPE: '添加问题类型',
      ADD_PRODUCT: '添加产品',
      ADD_PRODUCTS: '购买商品',
      ADD_RESELLER: '添加代理商',
      ADD_RESOURCE_GROUP: '添加资源组',
      ADD_ROOT: '添加根目录',
      ADD_SKU_RULE: '添加SKU规则',
      ADD_SUBSCRIPTIONS: '添加订阅',
      ADD_TAG: '添加标签',
      ADD_TO_CART: '加入购物车',
      ADD_USER_GROUP: '添加用户组',
      ADD_USER: '添加用户',
      ADD: '添加',
      ADDED_TO_CART: '已加入购物车',
      ANALYSIS_DETAIL: '分析详情',
      APPLY_FILTER: '应用筛选器',
      ATTACH: '关联',
      BACK: '返回',
      BACK_TO_SEARCH_MODE: '返回搜索模式并清除选择项',
      BUILD: '创建',
      BUY: '购买',
      CANCEL: '取消',
      CHANGE_PASSWORD: '修改密码',
      CHOOSE: '选择',
      CLICK: '点击',
      CLOSE: '关闭',
      CONFIRM: '确认',
      CONFIRM_NEXT: '确认并进入下一步',
      CONFIRMATION: '确认',
      CONTINUE: '继续',
      CONTINUE_PURCHASE: '继续购物',
      COPY_RESOURCE_ID_TO_CLIPBOARD: '复制资源ID',
      CREATE_ANNOUNCEMENT: '创建公告',
      CREATE_USAGE_ACCESS_CONTROL: '创建费用访问控制',
      DEACTIVE: '停用',
      DELETE: '删除',
      DELETE_BUDGET: '删除预算',
      DELETE_CRON_REPORT: '删除报告',
      DELETE_FILTER: '删除筛选器',
      DELETE_MENU_NODE: '确认删除菜单 「{{ label }}」 吗?',
      DELETE_MENU_NODE_SUCCESSFULLY: '成功删除菜单 「{{ label }}」',
      DELETE_TAG: '删除标签',
      DELETE_USAGE_ACCESS_CONTROL: '删除费用访问控制',
      DELETE_USER: '删除用户',
      DELETE_VIEW: '删除视图',
      DELIVER: '发送',
      DENIED: '拒绝',
      DETACH: '解绑',
      DETECTING: '检测中',
      DISABLE: '禁用',
      DISABLED: '已禁用',
      DONE: '完成',
      DOWNLOAD: '下载',
      EDIT: '编辑',
      EDIT_ADMIN_USER: '编辑管理员',
      EDIT_ANNOUNCEMENT: '编辑公告',
      EDIT_CONTACT: '编辑联系人',
      EDIT_MEMO: '编辑备忘录',
      EDIT_ORGANIZATION: '编辑组织',
      EDIT_PRIORITY: '编辑优先级',
      EDIT_PROBLEM_SUBTYPE: '编辑问题子类',
      EDIT_PROBLEM_TYPE: '编辑问题类型',
      EDIT_STATUS: '编辑状态',
      EDIT_SWITCHABLE_USER: '编辑可切换用户',
      EDIT_TAG: '编辑标签',
      EDIT_USER: '编辑用户',
      ENABLE: '启用',
      ENABLED: '已启用',
      ENTER_NEW_NAME: '输入新名称',
      EXPAND: '展开',
      EXPAND_GROUP: '展开组',
      EXPORT: '导出',
      EXPORT_ALL: '导出全部',
      GENERATE_REPORT: '生成报告',
      GO_BACK: '返回',
      HIDE_DETAILS: '隐藏详情',
      IMPORT: '导入',
      INTERACTIVE_MODE: '互动模式',
      LEARN_MORE: '了解更多信息',
      LOGOUT: '退出登录',
      MAIL_SCHEDULE: '邮件 / 定时报告',
      MAKE_A_PAYMENT: '支付',
      MODIFY: '修改',
      NEW: '添加',
      NEW_ACCESS_GROUP: '添加访问组',
      NEW_BUDGET: '添加预算',
      NEW_REPORT: '添加报告',
      NEW_SUPPORT_TICKET: '添加支持工单',
      NEXT: '下一步: {{ target }}',
      PAUSE: '暂停',
      PAY: '支付',
      PREVIEW: '预览',
      PRINT: '打印',
      PRINT_MODE: '打印模式',
      PUBLISH: '发布',
      QUERY: '查询',
      REFRESH: '刷新',
      REFRESHING: '刷新中',
      REFRESH_LATENCY: '实时检测，延迟可能受到网络速度和质量影响。',
      REMOVE: '移除',
      RESEND: '重新发送',
      RESET: '重置',
      RESET_USER_MFA: '重置用户多因素认证',
      RESET_USER_PASSWORD: '重置用户密码',
      RESTORE: '恢复',
      RETURN: '返回',
      SAVE: '保存',
      SAVE_AS: '另存为',
      SAVE_CHART: '保存图表',
      SAVE_FILTER: '保存筛选器',
      SAVE_PNG: '另存为PNG',
      SAVE_SVG: '另存为SVG',
      SAVE_VIEW: '保存视图',
      SCAN: '扫描',
      SEARCH: '搜索',
      SELECT: '选择',
      SELECT_DATE: '选择日期',
      SELECT_DIMENSION: '选择维度',
      SELECT_FOLDER: '选择文件夹',
      SELECT_KEY: '选择键',
      SELECT_VALUE: '选择值',
      SELECT_VALUES: '选择值',
      SEND: '发送',
      SENT: '已发送',
      SEND_CRON_REPORT: '发送报告',
      SEND_INVITATION_SUCCESS: '邀请已发送',
      SEND_NOW: '立即发送',
      SEND_REPORT: '发送报告',
      SEND_REPORT_BY_EMAIL: '通过邮箱发送报告',
      SET_AS_DEFAULT: '设为默认',
      SHARE: '分享',
      SHOW_DETAILS: '显示详情',
      START: '开始',
      STATUS_HAS_BEEN_UPDATED: '状态已更新',
      SUBMIT: '提交',
      SUPPORT_TICKET_HAS_BEEN_CREATED: '支持工单已创建',
      SWITCH: '切换',
      SWITCH_APP: '切换应用',
      SWITCH_CUSTOMER: '切换客户',
      SWITCH_EA: '切换EA',
      SWITCH_ENROLLMENT: '切换 Enrollment',
      SWITCH_PARTNER: '切换 Partner',
      SWITCH_RESELLER: '切换 Reseller',
      SWITCH_USER: '切换用户',
      SWITCH_VIEW: '切换视图',
      TAKE_ACTION: '采取行动',
      TOGGLE_FULL_WIDTH: '切换全宽',
      TOP: '顶部',
      UPDATE: '更新',
      UPDATE_BUDGET: '更新预算',
      UPDATE_CRON_REPORT: '更新报告',
      UPDATE_EA_ACCESS_CONTROL: '更新EA访问控制',
      UPDATE_MENU: '更新菜单',
      UPDATE_USAGE_ACCESS_CONTROL: '更新费用访问控制',
      UPLOAD: '上传',
      UPLOAD_USER: '上传用户',
      VIEW: '查看',
      VIEW_ORDERS: '查看订单',
      VIEW_SUBSCRIPTIONS: '查看订阅',
    },
  },
};
