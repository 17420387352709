import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class CommonValidatorService {
  public emailValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      if (!control.value || control.value.length === 0) {
        return null;
      }
      const regex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const valid: boolean = regex.test(control.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  public multiEmailAddressValidator(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
      if (!control.value || control.value.length === 0) {
        return null;
      }
      const regex = new RegExp(
        /^(|([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([,.](([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/
      );
      const valid: boolean = regex.test(control.value);
      return valid ? null : { invalidEmail: true };
    };
  }

  /**
   * Valid between 2 input is match or not
   * @param password first password selector
   * @param confirmPassword second password selector
   * @param passwordMatchConfirm return match or mismatch
   */
  public passwordValidator(
    password: string,
    confirmPassword: string,
    passwordMatchConfirm: boolean = false
  ): ValidatorFn {
    return (formGroup: FormGroup<PasswdFormGroup>): ValidationErrors => {
      const passwordControl: FormControl<string> = formGroup.controls[password];
      const confirmPasswordControl: FormControl<string> = formGroup.controls[confirmPassword];
      const matchMessange: 'passwordMatch' | 'passwordMismatch' = passwordMatchConfirm
        ? 'passwordMismatch'
        : 'passwordMatch';

      if (String(passwordControl.value)?.length <= 0 || String(confirmPasswordControl.value)?.length <= 0) return null;

      delete confirmPasswordControl.errors?.[matchMessange];
      confirmPasswordControl.setErrors({ ...confirmPasswordControl.errors });
      if (
        (!passwordMatchConfirm && passwordControl.value === confirmPasswordControl.value) ||
        (passwordMatchConfirm && passwordControl.value !== confirmPasswordControl.value)
      )
        Object.assign(confirmPasswordControl.errors, { [matchMessange]: true });

      if (Object.keys(passwordControl.errors || {}).length === 0) passwordControl.setErrors(null);
      if (Object.keys(confirmPasswordControl.errors || {}).length === 0) confirmPasswordControl.setErrors(null);
      return passwordControl.errors || confirmPasswordControl.errors;
    };
  }

  /**
   * Valid password classes that is contain few of defined character classes
   * @param password password selector
   * @param numberOfCharacterClasses how many classes needs to match
   */
  public passwordClassesValidator(password: string, numberOfCharacterClasses: number): ValidatorFn {
    return (formGroup: FormGroup<PasswdFormGroup>): ValidationErrors => {
      if (!formGroup?.controls?.[password]) return null;
      const passwordControl: FormControl<string> = formGroup.controls[password];
      let classesPassed: number = 0;
      if (/[a-z]/g.test(passwordControl.value)) classesPassed++;
      if (/[A-Z]/g.test(passwordControl.value)) classesPassed++;
      if (/[0-9]/g.test(passwordControl.value)) classesPassed++;
      if (/[\W]/g.test(passwordControl.value)) classesPassed++;

      if (String(passwordControl.value)?.length <= 0) return null;
      classesPassed < numberOfCharacterClasses
        ? passwordControl.setErrors({ ...passwordControl.errors, classesMismatch: true })
        : passwordControl.setErrors({ ...passwordControl.errors });

      if (Object.keys(passwordControl.errors || {}).length === 0) passwordControl.setErrors(null);
      return passwordControl.errors;
    };
  }
}

type PasswdFormGroup = {
  [key: string]: FormControl<string>;
};
