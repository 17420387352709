<div class="period_section" [logPeriod]="{ label: (customLabel || 'period') + '_selector', payload: loggerPayload }">
  <label *ngIf="displayTitle">{{ selectorTitle || ('LABEL.BILLING_PERIOD' | translate) }}:</label>
  <div *ngIf="loggerPayload || empty; else uninitialized">
    <p-dropdown
      scrollHeight="400px"
      [disabled]="disable || empty"
      [autoDisplayFirst]="false"
      [options]="dropdownItems.startList"
      [ngModel]="dropdownItems.start"
      (ngModelChange)="onDateStartChange($event)"></p-dropdown>
    <span>~</span>
    <p-dropdown
      scrollHeight="400px"
      [disabled]="disable || empty"
      [autoDisplayFirst]="false"
      [options]="dropdownItems.endList"
      [ngModel]="dropdownItems.end"
      (ngModelChange)="onDateEndChange($event)"></p-dropdown>
  </div>
</div>

<ng-template #uninitialized>
  <uninitialized></uninitialized>
</ng-template>
