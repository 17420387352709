/* eslint-disable @typescript-eslint/no-explicit-any */
// Angular
import { RouterState } from '@angular/router';
// NGRX
import environment from '@env';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
// Custom Reducers
import { authReducer, AuthState, initialAuthState } from './auth.reducers';
import { blockUIReducer, BlockUIState, initialBlockUIState } from './blockui.reducers';
import { initialLayoutState, LayoutConfig, layoutReducer } from './layout.reducers';
import { initialMsgState, messageReducer, MessageState } from './message.reducers';
import { initialPeriodSelectorState, periodSelectorReducer, PeriodSelectorState } from './period-selector.reducers';
import { storageMetaReducer } from './storage.metareducers';
import { initialTabMenuState, tabMenuBreadcrumbListReducer, TabMenuState } from './tab-menu.reducers';
import { tempStoreReducer } from './temp-store.reducers';
// State interface
export { AuthState } from './auth.reducers';
export { BlockUIState } from './blockui.reducers';
export {
  initialLayoutState, LayoutConfig, LayoutState, LayoutTabMenuState
} from './layout.reducers';
export { MessageState } from './message.reducers';
export { PeriodSelectorState } from './period-selector.reducers';
export { TabMenuState } from './tab-menu.reducers';
export { TempStoreState } from './temp-store.reducers';
// Interface
import { InitialState } from '@ngrx/store/src/models';
import { RouterStateUrl } from '@store/custom-route-serializer';

export interface AppState<T = any> {
  auth: AuthState;
  blockUI: BlockUIState;
  layout: LayoutConfig;
  message: MessageState;
  period: PeriodSelectorState;
  router: RouterReducerState<RouterStateUrl>;
  tabMenuBreadcrumb: TabMenuState;
  tempStore: T;
}

const Reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  blockUI: blockUIReducer,
  layout: layoutReducer,
  message: messageReducer,
  period: periodSelectorReducer,
  router: routerReducer,
  tabMenuBreadcrumb: tabMenuBreadcrumbListReducer,
  tempStore: tempStoreReducer,
};

export const stateInit: InitialState<AppState<undefined>> = {
  auth: initialAuthState,
  blockUI: initialBlockUIState,
  layout: initialLayoutState,
  message: initialMsgState,
  period: initialPeriodSelectorState,
  router: undefined,
  tabMenuBreadcrumb: initialTabMenuState,
  tempStore: undefined,
};

export interface ReducerStates {
  auth: AuthState;
  message: MessageState;
  layout: LayoutConfig;
  router: RouterState;
  blockUI: BlockUIState;
  tabMenuBreadcrumb: TabMenuState;
  periodSelector: PeriodSelectorState;
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storeFreeze, storageMetaReducer]
  : [storageMetaReducer];

export default Reducers;
