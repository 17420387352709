import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    HINT: {
      ACCESS_GROUP_DELETED: 'access group deleted.',
      ACCESS_GROUP_UPDATED: 'access group updated.',
      ACTIVATE_ORGANIZATION_UNIT_NOTE: 'Are you sure you want to activate selected organization unit?',
      ACTIVATE_USER_GROUP_NOTE: 'Are you sure you want to activate selected user group?',
      ADD_NEW_ACCESS_GROUP: 'add new access group?',
      ADD_TO_ADVISORY_NOTE: 'Saving and coverage rate will change with current usage by quantity.',
      ADMIN_USER_HAS_BEEN_UPDATED: 'Admin user has been updated',
      ANNOUNCEMENT_HAS_BEEN_CREATED: 'Announcement has been created',
      ANNOUNCEMENT_HAS_BEEN_DELETED: 'Announcement has been deleted',
      ANNOUNCEMENT_HAS_BEEN_UPDATED: 'Announcement has been updated',
      ANNOUNCEMENT_TYPE_HAS_BEEN_CREATED: 'Announcement Type has been created',
      ANNOUNCEMENT_TYPE_HAS_BEEN_DELETED: 'Announcement Type has been deleted',
      ANNOUNCEMENT_TYPE_HAS_BEEN_UPDATED: 'Announcement Type has been updated',
      ASSIGNMENT_LIST_HAS_BEEN_UPDATED: 'Assignment list has been updated',
      AUTOCOMPLETE_INFO: 'Enter keyword to search first, then select result',
      BC_CUSTOMER_INFO: 'Billing Cycle for Customer',
      BC_PARTNER_INFO: 'Billing Cycle for Partner',
      COMMENT_HAS_BEEN_ADDED: 'Comment has been added',
      CONFIRM_DELETE_TAG: 'Do you want to delete the tag? This action will affect all vendors associated with this tag.',
      CONFIRM_DELETE_TAG_VALUE: 'Do you want to delete this value? This action will affect all vendors associated with this tag value.',
      CONTACT_HAS_BEEN_CREATED: 'Contact has been created',
      CONTACT_HAS_BEEN_DELETED: 'Contact has been deleted',
      CONTACT_HAS_BEEN_UPDATED: 'Contact has been updated',
      COST_DEVIATION_TOOLTIP: 'Cost diffirence from the expected value',
      CSP_EMPTY_BAR_INFO: 'Please select a company to view the query results',
      CUSTOMER_HAS_BEEN_CREATED: 'Customer has been created',
      CUSTOMER_HAS_BEEN_UPDATED: 'Customer has been updated',
      CUSTOMER_ORDER_HISTORY_CANCEL: 'Are you sure you want to cancel this order?',
      CUSTOMER_SUBSCRIPTION_ACTIVE_INFO: 'Are you sure you want to activate subscription',
      CUSTOMER_SUBSCRIPTION_SPSENDED_INFO: 'Are you sure you want to suspend subscription?',
      DEFAULT_PRICING_NOTE: 'Default applied as no pricing config assigned',
      DELETE_ACCESS_GROUP: 'delete access group?',
      DELETE_ACCESS_GROUP_FAILED: 'delete access group failed.',
      DELETE_ANNOUNCEMENT_NOTE: 'Are you sure you want to delete this announcement?',
      DELETE_ANNOUNCEMENT_TYPE_NOTE: 'Are you sure you want to delete {{ tagName }} tag?',
      DELETE_BUDGET_NOTE: 'Are you sure you want to delete this budget?',
      DELETE_CONTACT_NOTE: 'Are you sure you want to delete selected contact?',
      DELETE_DISCOUNT_PERCENT_RULE_NOTE: 'Are you sure you want to delete selected discount percent rule?',
      DELETE_EAENROLLMENT_NOTE: 'Are you sure you want to delete selected EA Enrollment?',
      DELETE_FEATURE_PACK_NOTE: 'Are you sure you want to delete feature pack "{{ filterName }}" ?',
      DELETE_MAPPING_RULE_NOTE: 'Are you sure you want to delete selected dimension value mapping rule?',
      DELETE_ORGANIZATION_NOTE: 'Are you sure you want to delete selected organization?',
      DELETE_PERSONAL_FILTER_NOTE: 'Are you sure you want to delete this preset filter "{{ filterName }}"?',
      DELETE_PERSONAL_VIEW_NOTE: 'Are you sure you want to delete this view "{{ viewName }}"?',
      DELETE_PRICING_CONFIG_NOTE: 'Are you sure you want to delete selected pricing config?',
      DELETE_PROBLEM_SUBTYPE_NOTE: 'Are you sure you want to delete selected problem subtype?',
      DELETE_PROBLEM_TYPE_NOTE: 'Are you sure you want to delete selected problem type?',
      DELETE_RECOMMENDATION_NOTE: 'Are you sure you want to delete this recommendation?',
      DELETE_RESELLER_CONFIRM: 'Are you sure you want to delete "{{ companyName }}" ?',
      DELETE_RI_RECOMM_REPORT_NOTE: 'Are you sure you want to delete selected report?',
      DELETE_SKU_RULE_NOTE: 'Are you sure you want to delete selected sku rule?',
      DELETE_USAGE_ACCESS_CONTROL_NOTE: 'Are you sure you want to delete this usage access control "{{ filterName }}"?',
      DELETE_USER_NOTE: 'Are you sure you want to delete selected user? When you delete a user account, you also delete their permissions.',
      DESTROY_PROVISION_NOTE: 'Are you sure you want to destroy this provision ?',
      DIMENSION_RULE_HAS_BEEN_CREATED: 'Dimension value mapping rule created',
      DIMENSION_RULE_HAS_BEEN_DELETED: 'Dimension value mapping rule deleted',
      DIMENSION_RULE_HAS_BEEN_IMPORTED: 'Dimension value mapping rule imported',
      DIMENSION_RULE_HAS_BEEN_UPDATED: 'Dimension value mapping rule updated',
      EA_ENROLLMENT_SAVE_CONFIRM: 'Are you sure you want to save this EA Enrollment setting now?',
      EMAIL_RECIPIENTS_HINT: 'Press “Enter” key for each email entered',
      ENGINEER_OWNER_HAS_BEEN_ASSIGNED: 'Engineer owner has been assigned',
      FILE_UPLOADED: 'File Uploaded',
      INTERVAL_INFO: 'Set the interval to limit the number of results shown each time.',
      INVOICE_BILLING_PERIOD: 'This invoice is for the billing period',
      LINE_ITEM_HAS_BEEN_UPDATED: 'Line item has been updated',
      LINE_ITEM_HAS_BEEN_VOIDED: 'Line item has been voided',
      LOADING_MESSAGE: 'Please wait while the request is being processed...',
      MAKE_PAYMENT_NOTE: 'Are you sure you want to make a payment?',
      MAX_CPU_P_95_NOTE: 'Max CPU 95th Percentile',
      MAX_MEMORY_P_95_NOTE: 'Max Memory 95th Percentile',
      MAX_TOTAL_NETWORK_P_95_NOTE: 'Max Total Network 95th Percentile',
      MEMO_UPDATED: 'Memo Updated',
      MUST_CONTAIN8888: 'The DNS list must contain ip 8.8.8.8.',
      NAME_EMPTY_ALERT: 'At least one character must be entered',
      NEW_ACCESS_GROUP_ADDED: 'new access group added.',
      NEW_ACCESS_GROUP_FAILED: 'add new access group failed.',
      NEW_USER_HAS_BEEN_CREATED: 'New User Created',
      ORGANIZATION_HAS_BEEN_CREATED: 'Organization has been created',
      ORGANIZATION_HAS_BEEN_DELETED: 'Organization has been deleted',
      ORGANIZATION_HAS_BEEN_UPDATED: 'Organization has been updated',
      ORGANIZATION_UNIT_HAS_BEEN_CREATED: 'Organization unit has been created',
      ORGANIZATION_UNIT_HAS_BEEN_DELETED: 'Organization unit has been deleted',
      ORGANIZATION_UNIT_HAS_BEEN_UPDATED: 'Organization unit has been updated',
      PAYMENT_SUCCESSFUL: 'Payment Successful',
      PLACE_LINE_ITEM_ORDER_NOTE: 'Are you sure you want to place selected line item order?',
      PLACE_ORDER_NOTE: 'Are you sure you want to place the order?',
      PLEASE_WAIT: 'Please wait ...',
      PRICING_CONFIG_HAS_BEEN_CREATED: 'Pricing Config has been created',
      PRICING_CONFIG_HAS_BEEN_DELETED: 'Pricing Config has been deleted',
      PRICING_CONFIG_HAS_BEEN_UPDATED: 'Pricing Config has been updated',
      PRIORITY_HAS_BEEN_UPDATED: 'Priority has been updated',
      PROBLEM_SUBTYPE_HAS_BEEN_CREATED: 'Problem subtype has been created',
      PROBLEM_SUBTYPE_HAS_BEEN_DELETED: 'Problem subtype has been deleted',
      PROBLEM_SUBTYPE_HAS_BEEN_UPDATED: 'Problem subtype has been updated',
      PROBLEM_TYPE_HAS_BEEN_CREATED: 'Problem type has been created',
      PROBLEM_TYPE_HAS_BEEN_DELETED: 'Problem type has been deleted',
      PROBLEM_TYPE_HAS_BEEN_UPDATED: 'Problem type has been updated',
      PURCHASE_AGREEMENT_INFO: 'Customer must accept Microsoft Customer Agreement to purchase items',
      PURCHASE_AGREEMENT_VISIT: 'To accept Microsoft Customer Agreement, please visit',
      PURCHASE_RI_INFO: 'To purchase RI, you must subscribe to an Azure Plan first.',
      RECOMMENDATIONS_NOTICS: 'Recommendations are based on last 30 days VM usage. Actual savings may vary.',
      RECOMMENDATION_HAS_BEEN_DELETED: 'Recommendation has been deleted',
      REPORT_HAS_BEEN_DELETED: 'Report has been deleted',
      REPORT_IS_BEING_GENERATED: 'Report is being generated',
      RESELLER_PREREQUISITES_NOTE: 'This reseller can not place orders for customers as it does not fit all the purchase prerequisites.',
      RESERLLER_PROFILE_LINK_NOTE: 'Check out reseller profile page to edit.',
      RESOURCE_PLAN_HAS_BEEN_CREATED: 'Resource plan has been created.',
      RESOURCE_PLAN_HAS_BEEN_DELETED: 'Resource plan has been deleted.',
      RESOURCE_PLAN_HAS_BEEN_UPDATED: 'Resource plan has been updated.',
      RESOURCE_TAG_INFO: 'Enter keyword to query Key and add "=", then enter keyword to query Value',
      RI_ANALYSIS_COST_WITH_RECOMMENDATION_NOTE: 'Total estimated cost of new usage plus existing and new RI utilization RI utilization',
      RI_ANALYSIS_COVERAGE_RATE_NOTE: 'Estimated by hour',
      RI_ANALYSIS_CURRENT_COST_NOTE: 'Total estimated cost of current usage and RI utilization',
      RI_ANALYSIS_RESET_NOTE: 'Reset the Recommendation',
      RI_COVERAGE_RATE_COST_NOTE: 'RI Cost / (Usage Cost + RI Cost)',
      RI_COVERAGE_RATE_NOTE: 'RI Reserved Hours / (Usage Hours + RI Reserved Hours)',
      RI_COVERAGE_RATE_QUANTITY_NOTE: 'RI Reserved Quantity / (Usage Quantity + RI Reserved Quantity)',
      RI_DEDUCTED_HOURS_NOTE: 'Used Hours With Ratio',
      RI_NEW_COVERAGE_RATE_NOTE: 'New RI Reserved Hours / (New Usage Hours + New RI Reserved Hours)',
      RI_NEW_COVERAGE_RATE_QUANTITY_NOTE: 'New RI Reserved Quantity / (New Usage Quantity + New RI Reserved Quantity)',
      RI_RECOMMENDATIONS_NOTICS: 'Reserved Instance Recommendations are based on last 30 days VM usage. Actual savings may vary.',
      RI_RECOMM_REPORT_NOTICES: 'Reserved Instance Recommendations are based on last 30 days VM usage, 1 Year and Shared. Actual savings may vary.',
      RI_UTILIZATION_RATE_NOTE: 'Deducted Hours / Reserved Hours',
      RI_UTILIZATION_RATE_QUANTITY_NOTE: 'Deducted Quantity / Reserved Quantity',
      SALES_ORDER_HAS_BEEN_VOIDED: 'Sales order has been voided',
      SAME_NAME_ALERT: 'Same name already exists',
      SAVING_RANGE_INPUT_HINT: 'Max savings for the selected service type is ${{ maxSavings }}. Please enter a number between $0 ~ ${{ maxSavings }}',
      SAVING_RANGE_MIN_MAX_HINT: 'Min savings can not be greater than max savings.',
      SET_UP_ACCOUNT_NOTE: 'Please set up a payment account first.',
      STATUS_HAS_BEEN_UPDATED: 'Status has been updated',
      SUBSCRIPTION_COUNTS_ONBOARD_NOTE: 'Subscription counts when onboarding',
      SUPPORT_OWNER_HAS_BEEN_ASSIGNED: 'Support owner has been assigned',
      SUPPORT_TICKET_HAS_BEEN_CREATED: 'Support Ticket has been created',
      SUSPEND_ORGANIZATION_UNIT_NOTE: 'Are you sure you want to suspend selected organization unit?',
      SUSPEND_USER_GROUP_NOTE: 'Are you sure you want to suspend selected user group?',
      SWITCH_TO_USERID_NOTE: 'Are you sure you want to switch to 「{{ userId }}」?',
      SWITCH_TO_USERID_SUCESSFULLY: 'SWITCH TO 「{{ userId }}」 SUCESSFULLY',
      TAG_MANAGEMENT_INFO: 'A key component of a successful third-party risk management program is tagging. You can create up to three tags. These tags will generate a custom column when adding a new vendor, and the first tag will be used for display on the dashboard.',
      TOTAL_REVENUE_INFO: 'Total revenue is based on the selected filter.',
      UNSUBSCRIBE_SUBSCRIPTION: 'Are you sure you want to unsubscribe this subscription 「{{ subscriptionName }}」 ?',
      UPDATE_ACCESS_GROUP: 'update access group?',
      UPDATE_ACCESS_GROUP_FAILED: 'update access group failed.',
      UPLOADING: 'Uploading ...',
      UPLOAD_FAILED: 'Upload failed',
      USER_GROUP_HAS_BEEN_CREATED: 'User group has been created',
      USER_GROUP_HAS_BEEN_DELETED: 'User group has been deleted',
      USER_GROUP_HAS_BEEN_UPDATED: 'User group has been updated',
      VOID_LINE_ITEM_NOTE: 'Are you sure you want to void selected line item?',
      VOID_SALES_ORDER_NOTE: 'Are you sure you want to void selected sales order?',
      WRONG_EMAIL_FORMAT: 'Wrong Email Format',
    },
  },
};
