/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';
import { ActionCreatorProp } from '.';

// The Action interface
export enum TempStoreActionTypes {
  Temp_Store = '[Temp Store] Action',
  Temp_Store_Append = '[Temp Store] Append',
  Temp_Store_RemoveByKey = '[Temp Store] RemoveByKey',
}

export type TempStorePayload<T = any> = {
  Temp_Store: T;
  Temp_Store_Append: T;
  Temp_Store_RemoveByKey: { key: string };
};

export const TempStoreAction: ActionCreatorProp<TempStorePayload['Temp_Store']> = createAction(
  TempStoreActionTypes.Temp_Store,
  props()
);

export const TempStoreAppend: ActionCreatorProp<TempStorePayload['Temp_Store_Append']> = createAction(
  TempStoreActionTypes.Temp_Store_Append,
  props()
);

export const TempStoreRemoveByKey: ActionCreatorProp<TempStorePayload['Temp_Store_RemoveByKey']> = createAction(
  TempStoreActionTypes.Temp_Store_RemoveByKey,
  props()
);

export const TempStoreActions = {
  TempStoreAction,
  TempStoreAppend,
  TempStoreRemoveByKey,
};
