import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yyyyMMdd' })
export class ConvertToyyyyMMddPipe implements PipeTransform {
  public transform(value: string): string {
    // const today = new Date(Date.now() + new Date().getTimezoneOffset() * 6e4);
    const today = new Date();
    const nowMonth = today.getMonth();
    const nowdate = today.getDate();
    switch (value) {
      case 'Current Month': return formatDate(today, 'yyyy-MM', 'en-US');
      case 'Last Month': return formatDate(today.setMonth(nowMonth - 1), 'yyyy-MM', 'en-US');
      case 'Last 7 Days': return formatDate(today.setDate(nowdate - 6), 'yyyy-MM-dd', 'en-US');
      case 'Last 14 Days': return formatDate(today.setDate(nowdate - 13), 'yyyy-MM-dd', 'en-US');
      case 'Today': return formatDate(today, 'yyyy-MM-dd', 'en-US');
      default: return value;
    }
  }
}
