import { createReducer, on } from '@ngrx/store';
import { LayoutActions, TabMenuStaticActions } from '../actions';
// Interface
import { LayoutScaleType, LayoutType } from '@base/model';
import { ColorScheme, DocumentLangType, InputStyles, MenuMode, MenuProfilePosition, ThemeComponentColor, ThemeMenuColor, ThemeTopbarColor } from '@core/enum';

// Defining the state shape
export interface LayoutConfig extends LayoutTabMenuState {
  blockLoading?: boolean;
  colorScheme: ColorScheme;
  componentTheme: ThemeComponentColor;
  inlineBreadcrumb?: boolean;
  inputStyle: InputStyles;
  lang: DocumentLangType;
  menuMode: MenuMode;
  menuProfilePosition?: MenuProfilePosition;
  menuTheme: ThemeMenuColor;
  ripple: boolean;
  scale: LayoutScaleType;
  tabEnrollment?: boolean;
  topbarTheme: ThemeTopbarColor;
}

export interface LayoutState {
  anchored: boolean;
  configSidebarVisible: boolean;
  menuHoverActive: boolean;
  menuProfileActive: boolean;
  overlayMenuActive: boolean;
  rightMenuActive: boolean;
  sidebarActive: boolean;
  staticMenuDesktopInactive: boolean;
  staticMenuMobileActive: boolean;
  topbarMenuActive: boolean;
}
export interface LayoutTabMenuState {
  layoutType?: LayoutType;
  tabKey?: string;
}

// Setting the initial state
export const initialLayoutState: LayoutConfig = {
  blockLoading: true,
  colorScheme: ColorScheme.LIGHT,
  inputStyle: InputStyles.OUTLINED,
  lang: DocumentLangType.ENG,
  layoutType: LayoutType.LEGACY,
  menuMode: MenuMode.STATIC,
  ripple: true,
  scale: 14,
  tabKey: '',
  componentTheme: ThemeComponentColor.INDIGO,
  menuTheme: ThemeMenuColor.LIGHT,
  topbarTheme: ThemeTopbarColor.BLUE,
};

export const layoutReducer = createReducer(
  initialLayoutState,
  on(
    LayoutActions.ChangeLayoutAction,
    (state, payload: LayoutConfig): LayoutConfig => ({
      blockLoading: payload.blockLoading || state.blockLoading,
      colorScheme: payload.colorScheme || state.colorScheme,
      componentTheme: payload.componentTheme || state.componentTheme,
      inlineBreadcrumb: payload.inlineBreadcrumb || state.inlineBreadcrumb,
      inputStyle: payload.inputStyle || state.inputStyle,
      lang: payload.lang || state.lang,
      layoutType: payload.layoutType || state.layoutType,
      menuMode: payload.menuMode || state.menuMode,
      menuTheme: payload.menuTheme || state.menuTheme,
      ripple: payload.ripple || state.ripple,
      scale: payload.scale || state.scale,
      tabEnrollment: payload.tabEnrollment || state.tabEnrollment,
      tabKey: payload.tabKey || state.tabKey,
      topbarTheme: payload.topbarTheme || state.topbarTheme,
    })
  ),
  on(
    TabMenuStaticActions.ClearTabMenuStaticsAction,
    (state): LayoutConfig => ({
      blockLoading: state.blockLoading,
      colorScheme: state.colorScheme,
      componentTheme: state.componentTheme,
      inlineBreadcrumb: state.inlineBreadcrumb,
      inputStyle: state.inputStyle,
      lang: state.lang,
      layoutType: state.layoutType,
      menuMode: state.menuMode,
      menuTheme: state.menuTheme,
      ripple: state.ripple,
      scale: state.scale,
      tabEnrollment: state.tabEnrollment,
      topbarTheme: state.topbarTheme,
    })
  ),
  on(
    LayoutActions.ChangeBlockLoadingAction,
    (state, { blockLoading }): LayoutConfig => ({ ...state, blockLoading })
  ),
  on(
    LayoutActions.ChangeInlineBreadcrumbAction,
    (state, { inlineBreadcrumb }): LayoutConfig => ({ ...state, inlineBreadcrumb })
  ),
  on(
    LayoutActions.ChangeTabEnrollmentAction,
    (state, { tabEnrollment }): LayoutConfig => ({ ...state, tabEnrollment })
  ),
  on(
    LayoutActions.ChangeScaleAction,
    (state, { scale }): LayoutConfig => ({ ...state, scale })
  ),
  on(
    LayoutActions.ChangeInputStyleAction,
    (state, { input }): LayoutConfig => ({ ...state, inputStyle: input })
  ),
  on(
    LayoutActions.ChangeColorSchemeAction,
    (state, { colorScheme }): LayoutConfig => ({ ...state, colorScheme })
  ),
  on(
    LayoutActions.ChangeMenuModeAction,
    (state, { menuMode }): LayoutConfig => ({ ...state, menuMode })
  ),
  on(
    LayoutActions.ChangeMenuColorAction,
    (state, { menuTheme }): LayoutConfig => ({ ...state, menuTheme })
  ),
  on(
    LayoutActions.ChangeMnuProfilePositionAction,
    (state, { menuProfilePosition }): LayoutConfig => ({ ...state, menuProfilePosition })
  ),
  on(
    LayoutActions.ChangeTopbarColorAction,
    (state, { topbarColor }): LayoutConfig => ({ ...state, topbarTheme: topbarColor })
  ),
  on(
    LayoutActions.ChangeComponentColorAction,
    (state, { componentColor }): LayoutConfig => ({ ...state, componentTheme: componentColor })
  ),
  on(
    LayoutActions.ChangeLanguageAction,
    (state, { lang }): LayoutConfig => ({ ...state, lang })
  ),
  on(
    LayoutActions.ChangeRippleAction,
    (state, { ripple }): LayoutConfig => ({ ...state, ripple })
  ),
  on(
    TabMenuStaticActions.ChangeTabKeyAction,
    (state, { tabKey }): LayoutConfig => ({ ...state, tabKey })
  ),
  on(
    TabMenuStaticActions.ChangeLayoutTypeAction,
    (state, { layoutType }): LayoutConfig => ({ ...state, layoutType })
  )
);
