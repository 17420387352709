// Angular
import { Component, ElementRef, Input } from '@angular/core';
// Caloudi
import { JSONUtil } from '@util';
// Icon
import { faCopy } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: '[copy]',
  templateUrl: './copy2clipboard.component.html',
  styleUrls: ['./copy2clipboard.component.sass'],
})
export class Copy2ClipboardComponent {
  @Input('copy') public value: unknown;

  public readonly ikon = faCopy;
  public copied: boolean;

  private _el: HTMLElement;

  constructor(private readonly el: ElementRef<HTMLElement>) {}

  public onClick(): void {
    this._el = this.el.nativeElement.lastChild.firstChild as HTMLElement;

    console.debug('copy:', [this.value, this.el.nativeElement, this._el]);
    const payload$ = typeof this.value === 'object' ? JSONUtil.stringify(this.value) : String(this.value).trim();

    navigator.clipboard.writeText(payload$).then(
      () => {
        console.debug('copied:', payload$);
        this.copied = true;
        setTimeout(() => (this.copied = false), 1000);
      },
      e => console.debug('copy failed', e)
    );
  }

  public onMousedown(): void {
    try {
      this._el.classList.add('active');
    } catch (error) {
      return;
    }
  }

  public onMouseup(): void {
    try {
      this._el.classList.remove('active');
    } catch (error) {
      return;
    }
  }
}
