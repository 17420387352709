/* eslint-disable @typescript-eslint/no-explicit-any */
// Angular
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Rxjs
import { distinctUntilChanged } from 'rxjs';
// Caloudi
import BaseComponent from '@base';
// Interface
import { NgChange } from '@core/model';
// Third Party
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'caloudi-formly',
  templateUrl: './caloudi-formly.component.html',
  styleUrls: ['./caloudi-formly.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class CaloudiFormlyComponent extends BaseComponent implements OnInit, OnChanges {
  @Input('fields') public $fields: FormlyFieldConfig[];
  @Input('model') public $model: any;

  @Output('onSubmit') private readonly $submitEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('valid') private readonly $isValidEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public valid: boolean;
  public form: FormGroup = new FormGroup({});

  private readonly logActive: boolean;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  public ngOnChanges(changes: Changes): void {
    this.logActive && this.logger.debug('changes:', [changes, this.$model, this.$fields]);
    if (!changes.$model.currentValue || !changes.$model.firstChange) return;
    setTimeout(() => {
      this.logActive && console.time('changes');
      this.form.setValue({ ...changes.$model.currentValue });
    });
  }

  public ngOnInit(): void {
    this.logActive && this.logger.debug('init', [this.form.controls]);
    this.form.statusChanges.pipe(distinctUntilChanged()).subscribe(res => {
      this.valid = res === 'VALID';
      this.$isValidEmitter.emit(res === 'VALID');
      this.logActive && this.logger.debug('form change:', [res]);
      this.logActive && console.timeEnd('changes');
    });
  }

  public onSubmit(model: { [key: string]: any; }): void {
    this.logActive && this.logger.debug('onSubmit:', [model]);
    if (!this.form.valid) return;
    this.$submitEmitter.emit(model);
  }
}

interface Changes extends SimpleChanges {
  $fields: NgChange<FormlyFieldConfig[]>;
  $model: NgChange<{ [key: string]: any; }>;
}
