/* eslint-disable @typescript-eslint/naming-convention */
import { SVGCrowbarPNGOptions, SVGCrowbarPNGSource } from '@base/model';
import { DEFAULT_FILENAME } from './const';
import { commenceDownload } from './util';

const DEFAULT_OPTIONS: SVGCrowbarPNGOptions = {
  debug: false,
  fixSource: (source: string): string => {
    return btoa(
      window.decodeURI(
        encodeURIComponent(
          source.replace(/[\u00A0-\u2666]/g, (c: string): string => {
            return '&#'.concat(c.charAt(0), ';');
          })
        )
      )
    );
  },
  scale: 1,
};

export const downloadPng = (source: SVGCrowbarPNGSource, filename?: string, options?: SVGCrowbarPNGOptions): void => {
  console.debug('download png', [source, filename, options]);
  const _filename: string = filename !== undefined ? filename : DEFAULT_FILENAME;
  const _ref: SVGCrowbarPNGOptions = options !== undefined ? options : DEFAULT_OPTIONS;
  const _ref$debug: boolean = _ref.debug;
  const debug: boolean = _ref$debug === void 0 ? DEFAULT_OPTIONS.debug : _ref$debug;
  const _ref$fixSource: (source: string) => string = _ref.fixSource;
  const fixSource: (source: string) => string = _ref$fixSource === void 0 ? DEFAULT_OPTIONS.fixSource : _ref$fixSource;
  const _ref$scale: number = _ref.scale;
  const scale: number = _ref$scale === void 0 ? DEFAULT_OPTIONS.scale : _ref$scale;

  const canvas = document.createElement('canvas');
  const dpr = window.devicePixelRatio || 1;
  document.body.appendChild(canvas);
  canvas.setAttribute('id', 'svg-image');
  canvas.setAttribute('width', String(source.width * dpr * scale));
  canvas.setAttribute('height', String(source.height * dpr * scale));

  if (!debug) {
    canvas.style.display = 'none';
  }

  const context: CanvasRenderingContext2D = canvas.getContext('2d');
  const imgsrc: string = 'data:image/svg+xml;base64,'.concat(fixSource(source.source));
  const image = new Image();

  const onLoad = (): void => {
    context.scale(dpr * scale, dpr * scale);
    context.drawImage(image, 0, 0);
    const canvasdata: string = canvas.toDataURL('image/png');

    if (!debug) {
      commenceDownload(''.concat(_filename, '.png'), canvasdata, () => {
        return document.body.removeChild(canvas);
      });
    }
  };

  image.onload = onLoad;
  image.src = imgsrc;

  if (debug) {
    document.body.appendChild(image);
  }
};
