import { Locale } from '@core/model';
import { locale as locale_action_enUS } from './i18n/action/en-us';
import { locale as locale_action_zhHanS } from './i18n/action/zh-hans';
import { locale as locale_action_zhHanT } from './i18n/action/zh-hant';
import { locale as locale_config_enUS } from './i18n/config/en-us';
import { locale as locale_config_zhHanS } from './i18n/config/zh-hans';
import { locale as locale_config_zhHanT } from './i18n/config/zh-hant';
import { locale as locale_enUS } from './i18n/en-us';
import { locale as locale_hint_enUS } from './i18n/hint/en-us';
import { locale as locale_hint_zhHanS } from './i18n/hint/zh-hans';
import { locale as locale_hint_zhHanT } from './i18n/hint/zh-hant';
import { locale as locale_label_enUS } from './i18n/label/en-us';
import { locale as locale_label_zhHanS } from './i18n/label/zh-hans';
import { locale as locale_label_zhHanT } from './i18n/label/zh-hant';
import { locale as locale_onboard_enUS } from './i18n/onboard/en-us';
import { locale as locale_onboard_zhHanS } from './i18n/onboard/zh-hans';
import { locale as locale_onboard_zhHanT } from './i18n/onboard/zh-hant';
import { locale as locale_vpn_profiles_enUS } from './i18n/vpn-profiles/en-us';
import { locale as locale_vpn_profiles_zhHanS } from './i18n/vpn-profiles/zh-hans';
import { locale as locale_vpn_profiles_zhHanT } from './i18n/vpn-profiles/zh-hant';
import { locale as locale_zhHanS } from './i18n/zh-hans';
import { locale as locale_zhHanT } from './i18n/zh-hant';

import { localeKeys as localeKeys_action } from './i18n/action/locale-keys';
import { localeKeys as localeKeys_config } from './i18n/config/locale-keys';
import { localeKeys as localeKeys_hint } from './i18n/hint/locale-keys';
import { localeKeys as localeKeys_label } from './i18n/label/locale-keys';
import { localeKeys as localeKeys_i18n } from './i18n/locale-keys';
import { localeKeys as localeKeys_onboard } from './i18n/onboard/locale-keys';
import { localeKeys as localeKeys_vpn_profiles } from './i18n/vpn-profiles/locale-keys';

export const ORIGIN_TRANSLATION: Locale[] = [locale_enUS, locale_zhHanT, locale_zhHanS];

export const CUSTOM_TRANSLATION: Locale[] = [
  locale_action_enUS,
  locale_action_zhHanS,
  locale_action_zhHanT,
  locale_config_enUS,
  locale_config_zhHanS,
  locale_config_zhHanT,
  locale_hint_enUS,
  locale_hint_zhHanS,
  locale_hint_zhHanT,
  locale_label_enUS,
  locale_label_zhHanS,
  locale_label_zhHanT,
  locale_onboard_enUS,
  locale_onboard_zhHanS,
  locale_onboard_zhHanT,
  locale_vpn_profiles_enUS,
  locale_vpn_profiles_zhHanS,
  locale_vpn_profiles_zhHanT,
];

export const TRANSLATION_INDEX = {
  label: locale_label_enUS.data,
  onboard: locale_onboard_enUS.data,
  origin: locale_enUS.data,
  vpn_profiles: locale_vpn_profiles_enUS.data,
};

export const LOCALE_KEYS = {
  ...localeKeys_action,
  ...localeKeys_config,
  ...localeKeys_hint,
  ...localeKeys_i18n,
  ...localeKeys_label,
  ...localeKeys_onboard,
  ...localeKeys_vpn_profiles,
};
