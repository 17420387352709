export const localeKeys = {
  LABEL: {
    ABNORMAL_DETECTION: 'LABEL.ABNORMAL_DETECTION',
    ACCESS_ACTIVITIES: 'LABEL.ACCESS_ACTIVITIES',
    ACCESSIBLE_RESOURCES: 'LABEL.ACCESSIBLE_RESOURCES',
    ACCOUNT: 'LABEL.ACCOUNT',
    ACCOUNT_BALANCE: 'LABEL.ACCOUNT_BALANCE',
    ACCOUNT_HOLDER_TYPE: 'LABEL.ACCOUNT_HOLDER_TYPE',
    ACCOUNT_NAME: 'LABEL.ACCOUNT_NAME',
    ACTIONS: 'LABEL.ACTIONS',
    ACTIVATE: 'LABEL.ACTIVATE',
    ACTIVATE_SUBSCRIPTION: 'LABEL.ACTIVATE_SUBSCRIPTION',
    ACTIVE: 'LABEL.ACTIVE',
    ADDITIONAL_EMAIL: 'LABEL.ADDITIONAL_EMAIL',
    ADDRESS: 'LABEL.ADDRESS',
    ADDRESS_LINE_1: 'LABEL.ADDRESS_LINE_1',
    ADDRESS_LINE_2: 'LABEL.ADDRESS_LINE_2',
    ADD_NEW_CUSTOMER: 'LABEL.ADD_NEW_CUSTOMER',
    ADD_ON_SUBSCRIPTION: 'LABEL.ADD_ON_SUBSCRIPTION',
    ADMINISTER_SERVICES: 'LABEL.ADMINISTER_SERVICES',
    ADMIN_USERS: 'LABEL.ADMIN_USERS',
    ADMIN_USER_ASSIGNMENTS: 'LABEL.ADMIN_USER_ASSIGNMENTS',
    ADVISOR_CATEGORY: 'LABEL.ADVISOR_CATEGORY',
    AGREEMENT_HAS_ACCEPTED: 'LABEL.AGREEMENT_HAS_ACCEPTED',
    AGREEMENT_TYPE: 'LABEL.AGREEMENT_TYPE',
    AGREEMENT_WILL_BE_ASKED: 'LABEL.AGREEMENT_WILL_BE_ASKED',
    ALERT_RECIPIENTS: 'LABEL.ALERT_RECIPIENTS',
    ALL: 'LABEL.ALL',
    AMORTIZED_RI_COST: 'LABEL.AMORTIZED_RI_COST',
    ANNUAL: 'LABEL.ANNUAL',
    ANNOUNCEMENT: 'LABEL.ANNOUNCEMENT',
    ANNOUNCEMENT_TIME: 'LABEL.ANNOUNCEMENT_TIME',
    ANNOUNCEMENT_TYPE: 'LABEL.ANNOUNCEMENT_TYPE',
    API_ACCESS_KEY: 'LABEL.API_ACCESS_KEY',
    API_ACCESS_CONFIG: 'LABEL.API_ACCESS_CONFIG',
    API_SETTING_TEST: 'LABEL.API_SETTING_TEST',
    API_TYPE: 'LABEL.API_TYPE',
    APP_ROLE: 'LABEL.APP_ROLE',
    APP_ROLES: 'LABEL.APP_ROLES',
    ASSIGNED: 'LABEL.ASSIGNED',
    ASSIGNED_ROLES: 'LABEL.ASSIGNED_ROLES',
    ASSIGNMENTS: 'LABEL.ASSIGNMENTS',
    ASSIGN_LICENSE: 'LABEL.ASSIGN_LICENSE',
    AT: 'LABEL.AT',
    ATTACHMENTS: 'LABEL.ATTACHMENTS',
    AT_TIME: 'LABEL.AT_TIME',
    AVERAGE: 'LABEL.AVERAGE',
    AVG_CPU_PERCENT_30_DAYS: 'LABEL.AVG_CPU_PERCENT_30_DAYS',
    AUTHORIZED_SUBSCRIPTION: 'LABEL.AUTHORIZED_SUBSCRIPTION',
    BANK: 'LABEL.BANK',
    BANK_NAME: 'LABEL.BANK_NAME',
    BANK_ROUTING: 'LABEL.BANK_ROUTING',
    BC_CUSTOMER: 'LABEL.BC_CUSTOMER',
    BC_PARTNER: 'LABEL.BC_PARTNER',
    BEYOND_DATE: 'LABEL.BEYOND_DATE',
    BEYOND_DATE_ALERT: 'LABEL.BEYOND_DATE_ALERT',
    BILLING: 'LABEL.BILLING',
    BILLING_ACCOUNT: 'LABEL.BILLING_ACCOUNT',
    BILLING_CURRENCY: 'LABEL.BILLING_CURRENCY',
    BILLING_CYCLE: 'LABEL.BILLING_CYCLE',
    BILLING_PERIOD: 'LABEL.BILLING_PERIOD',
    BILLING_SCHEDULE: 'LABEL.BILLING_SCHEDULE',
    BY_FOR_GROUP: 'LABEL.BY_FOR_GROUP',
    CANCEL_ORDER: 'LABEL.CANCEL_ORDER',
    CALOUDI_USER: 'LABEL.CALOUDI_USER',
    CC_EMAILS: 'LABEL.CC_EMAILS',
    CHANGE_LANG: 'LABEL.CHANGE_LANG',
    CHARGE_UNIT: 'LABEL.CHARGE_UNIT',
    CHART_GROUP_BY: 'LABEL.CHART_GROUP_BY',
    CITY: 'LABEL.CITY',
    CLEAR_FILTER: 'LABEL.CLEAR_FILTER',
    CLOUD_VENDOR: 'LABEL.CLOUD_VENDOR',
    COLLAPSE: 'LABEL.COLLAPSE',
    COMMENT: 'LABEL.COMMENT',
    COMMENTS: 'LABEL.COMMENTS',
    COMPANY: 'LABEL.COMPANY',
    COMPANY_ID: 'LABEL.COMPANY_ID',
    COMPARISON: 'LABEL.COMPARISON',
    CONSUMED: 'LABEL.CONSUMED',
    CONSUMPTION: 'LABEL.CONSUMPTION',
    CONTACT: 'LABEL.CONTACT',
    CONTACT_INFO: 'LABEL.CONTACT_INFO',
    CONTACT_METHOD: 'LABEL.CONTACT_METHOD',
    CONTACT_TYPES: 'LABEL.CONTACT_TYPES',
    COPY_TO_CLIPBOARD: 'LABEL.COPY_TO_CLIPBOARD',
    CORE: 'LABEL.CORE',
    COST_PERCENTAGE_LIST_PRICE: 'LABEL.COST_PERCENTAGE_LIST_PRICE',
    COST_PRICE: 'LABEL.COST_PRICE',
    COST_WITH_RECOMMENDATION: 'LABEL.COST_WITH_RECOMMENDATION',
    COUNTS: 'LABEL.COUNTS',
    COUNTRY: 'LABEL.COUNTRY',
    COVERAGE_RATE: 'LABEL.COVERAGE_RATE',
    CREATED_BY: 'LABEL.CREATED_BY',
    CREATE_DATE: 'LABEL.CREATE_DATE',
    CREATE_SALES_ORDER: 'LABEL.CREATE_SALES_ORDER',
    CREATE_TIME: 'LABEL.CREATE_TIME',
    CREATED_TIME: 'LABEL.CREATED_TIME',
    CREATION_TIME: 'LABEL.CREATION_TIME',
    CREATOR: 'LABEL.CREATOR',
    CREDITS: 'LABEL.CREDITS',
    CREDIT_CARD: 'LABEL.CREDIT_CARD',
    CREDIT_CARD_HOLDER_NAME: 'LABEL.CREDIT_CARD_HOLDER_NAME',
    CREDIT_CARD_NUMBER: 'LABEL.CREDIT_CARD_NUMBER',
    CREDIT_CARD_TYPE: 'LABEL.CREDIT_CARD_TYPE',
    CREDIT_LIMIT: 'LABEL.CREDIT_LIMIT',
    CRON_LANG: 'LABEL.CRON_LANG',
    CUD_RECOMMENDATION: 'LABEL.CUD_RECOMMENDATION',
    CURRENT: 'LABEL.CURRENT',
    CURRENT_COST: 'LABEL.CURRENT_COST',
    CURRENT_QTY: 'LABEL.CURRENT_QTY',
    CURRENT_SKU: 'LABEL.CURRENT_SKU',
    CUSTOM_EVENTS: 'LABEL.CUSTOM_EVENTS',
    CUSTOMER: 'LABEL.CUSTOMER',
    CUSTOMER_AGREEMENT: 'LABEL.CUSTOMER_AGREEMENT',
    CUSTOMER_ASSIGNMENTS: 'LABEL.CUSTOMER_ASSIGNMENTS',
    CUSTOMER_DETAIL: 'LABEL.CUSTOMER_DETAIL',
    CUSTOMER_MANAGEMENT: 'LABEL.CUSTOMER_MANAGEMENT',
    CUSTOMER_TYPE: 'LABEL.CUSTOMER_TYPE',
    CLOUD_USAGE_DATA: 'LABEL.CLOUD_USAGE_DATA',
    CLOUD_USAGE_DATA_SYNC_PROGRESS: 'LABEL.CLOUD_USAGE_DATA_SYNC_PROGRESS',
    CUSTOMER_PRIMARY_CONTACT: 'LABEL.CUSTOMER_PRIMARY_CONTACT',
    CUSTOMER_SEGMENT: 'LABEL.CUSTOMER_SEGMENT',
    CVC_NUMBER: 'LABEL.CVC_NUMBER',
    DEDUCTED_HOURS: 'LABEL.DEDUCTED_HOURS',
    DEFAULT_METHOD: 'LABEL.DEFAULT_METHOD',
    DELETED_USERS: 'LABEL.DELETED_USERS',
    DESCRIPTION: 'LABEL.DESCRIPTION',
    DETAIL: 'LABEL.DETAIL',
    DETAILS: 'LABEL.DETAILS',
    DIMENSION: 'LABEL.DIMENSION',
    DISCOUNT_PERCENTAGE: 'LABEL.DISCOUNT_PERCENTAGE',
    DISCOUNT_RATE: 'LABEL.DISCOUNT_RATE',
    DISPLAY_NAME: 'LABEL.DISPLAY_NAME',
    DISPLAY_VALUE: 'LABEL.DISPLAY_VALUE',
    DOCUMENTATION: 'LABEL.DOCUMENTATION',
    DURATION: 'LABEL.DURATION',
    EA_ACCESS_CONTROL: 'LABEL.EA_ACCESS_CONTROL',
    EA_ACCESS_CONTROL_ASSIGNMENTS: 'LABEL.EA_ACCESS_CONTROL_ASSIGNMENTS',
    EA_ACCESS_CONTROL_LIST: 'LABEL.EA_ACCESS_CONTROL_LIST',
    EA_ENROLLMENT: 'LABEL.EA_ENROLLMENT',
    EA_USAGE_HOURS: 'LABEL.EA_USAGE_HOURS',
    EDIT_LICENSE_QTY: 'LABEL.EDIT_LICENSE_QTY',
    EMAIL: 'LABEL.EMAIL',
    EMAIL_RECIPIENTS: 'LABEL.EMAIL_RECIPIENTS',
    END_CUSTOMER_TYPE: 'LABEL.END_CUSTOMER_TYPE',
    ENGINEER_OWNER: 'LABEL.ENGINEER_OWNER',
    ENROLLMENT_NUMBER: 'LABEL.ENROLLMENT_NUMBER',
    ESTIMATED_BY: 'LABEL.ESTIMATED_BY',
    EVENT_TYPE: 'LABEL.EVENT_TYPE',
    EVERY: 'LABEL.EVERY',
    EXCEPTION: 'LABEL.EXCEPTION',
    EXCLUDE: 'LABEL.EXCLUDE',
    EXPIRATION_DATE: 'LABEL.EXPIRATION_DATE',
    FAILURE: 'LABEL.FAILURE',
    FEATURE: 'LABEL.FEATURE',
    FEATURE_UPDATES: 'LABEL.FEATURE_UPDATES',
    FILTER: 'LABEL.FILTER',
    FILTERED_BY: 'LABEL.FILTERED_BY',
    FILTER_BY_CUSTOMER: 'LABEL.FILTER_BY_CUSTOMER',
    FILTER_NAME: 'LABEL.FILTER_NAME',
    VIEW_NAME: 'LABEL.VIEW_NAME',
    FIRST_NAME: 'LABEL.FIRST_NAME',
    FOLDER: 'LABEL.FOLDER',
    FREQUENCY: 'LABEL.FREQUENCY',
    FULL_PAYNENT: 'LABEL.FULL_PAYNENT',
    GPU: 'LABEL.GPU',
    GROUP_BY: 'LABEL.GROUP_BY',
    GROUPS: 'LABEL.GROUPS',
    GUIDING_CARD_TITLE: 'LABEL.GUIDING_CARD_TITLE',
    HAS_ADD_ONS: 'LABEL.HAS_ADD_ONS',
    HOMEPAGE: 'LABEL.HOMEPAGE',
    ID_EXISTED: 'LABEL.ID_EXISTED',
    IMPACT: 'LABEL.IMPACT',
    IMPACTED_FIELD: 'LABEL.IMPACTED_FIELD',
    INACTIVE: 'LABEL.INACTIVE',
    INCLUDE: 'LABEL.INCLUDE',
    INCLUDE_CREDITS: 'LABEL.INCLUDE_CREDITS',
    INTERVAL: 'LABEL.INTERVAL',
    INVITE_NEW_CUSTOMER: 'LABEL.INVITE_NEW_CUSTOMER',
    INVITE_NEW_RESELLER: 'LABEL.INVITE_NEW_RESELLER',
    ISSUE_TYPE: 'LABEL.ISSUE_TYPE',
    IS_ADD_ON: 'LABEL.IS_ADD_ON',
    IS_MS_PRODUCT: 'LABEL.IS_MS_PRODUCT',
    ITEMS_IN_CART: 'LABEL.ITEMS_IN_CART',
    ITEMS_SELECTED: 'LABEL.ITEMS_SELECTED',
    KEY: 'LABEL.KEY',
    KEYWORD: 'LABEL.KEYWORD',
    LABEL: 'LABEL.LABEL',
    LABEL_KEY: 'LABEL.LABEL_KEY',
    LABEL_SELECTED: 'LABEL.LABEL_SELECTED',
    LAST_MODIFIED_TIME: 'LABEL.LAST_MODIFIED_TIME',
    LAST_NAME: 'LABEL.LAST_NAME',
    LAST_UPDATED: 'LABEL.LAST_UPDATED',
    LIST: 'LABEL.LIST',
    LIST_PRICE: 'LABEL.LIST_PRICE',
    LOCATION: 'LABEL.LOCATION',
    MACHINE_FAMILY: 'LABEL.MACHINE_FAMILY',
    MANAGEMENT_GROUP: 'LABEL.MANAGEMENT_GROUP',
    MANAGEMENT_GROUP_ASSIGNMENT: 'LABEL.MANAGEMENT_GROUP_ASSIGNMENT',
    MANAGER: 'LABEL.MANAGER',
    MAPPING: 'LABEL.MAPPING',
    MARGIN_PERCENTAGE_SYMBOL: 'LABEL.MARGIN_PERCENTAGE_SYMBOL',
    MARKETPLACE: 'LABEL.MARKETPLACE',
    MEMO: 'LABEL.MEMO',
    MENU: 'LABEL.MENU',
    MENU_TYPE: 'LABEL.MENU_TYPE',
    METERED_BILLING: 'LABEL.METERED_BILLING',
    METERING_SERVICE_DIMENSIONS: 'LABEL.METERING_SERVICE_DIMENSIONS',
    MESSAGE: 'LABEL.MESSAGE',
    METRICS: 'LABEL.METRICS',
    MICROSOFT_PARTNER_AGREEMENT: 'LABEL.MICROSOFT_PARTNER_AGREEMENT',
    MIDDLE_NAME: 'LABEL.MIDDLE_NAME',
    MONTHLY_OVERRAGE_DATE_ALERT: 'LABEL.MONTHLY_OVERRAGE_DATE_ALERT',
    MONTHLY_REVENUE: 'LABEL.MONTHLY_REVENUE',
    MONTHLY_SAVINGS: 'LABEL.MONTHLY_SAVINGS',
    MONTHLY_USAGE: 'LABEL.MONTHLY_USAGE',
    MORE: 'LABEL.MORE',
    MY_CART: 'LABEL.MY_CART',
    MY_RECOMMENDATION_LIST: 'LABEL.MY_RECOMMENDATION_LIST',
    NAME: 'LABEL.NAME',
    NEXT: 'LABEL.NEXT',
    NEW_DIMENSION: 'LABEL.NEW_DIMENSION',
    NEW_MONTHLY_HOURS: 'LABEL.NEW_MONTHLY_HOURS',
    NEW_UNIT_PRICE_1Y: 'LABEL.NEW_UNIT_PRICE_1Y',
    NEW_USAGE_QTY: 'LABEL.NEW_USAGE_QTY',
    NEW_USAGE_HOURS: 'LABEL.NEW_USAGE_HOURS',
    NONE: 'LABEL.NONE',
    NORMAL: 'LABEL.NORMAL',
    NOT_AVAILABLE_FOR_PURCHASE: 'LABEL.NOT_AVAILABLE_FOR_PURCHASE',
    NO_ADMIN_USERS: 'LABEL.NO_ADMIN_USERS',
    NO_INSTANCE_LABEL: 'LABEL.NO_INSTANCE_LABEL',
    NO_LABEL: 'LABEL.NO_LABEL',
    NO_PURCHASE_ITEM: 'LABEL.NO_PURCHASE_ITEM',
    NO_SUBSCRIPTION: 'LABEL.NO_SUBSCRIPTION',
    NUMBER_OF_CHARACTER_CLASSES: 'LABEL.NUMBER_OF_CHARACTER_CLASSES',
    NUMBER_OF_RESOURCES: 'LABEL.NUMBER_OF_RESOURCES',
    NUMBER_OF_TYPES: 'LABEL.NUMBER_OF_TYPES',
    OFFER: 'LABEL.OFFER',
    OFFER_NAME: 'LABEL.OFFER_NAME',
    OF_EVERY: 'LABEL.OF_EVERY',
    ONLY_ONE_SERVICE_PER_SUBSCRIPTION: 'LABEL.ONLY_ONE_SERVICE_PER_SUBSCRIPTION',
    ON_THE: 'LABEL.ON_THE',
    OPEN_IN_EMAIL: 'LABEL.OPEN_IN_EMAIL',
    OPERATION_HISTORY: 'LABEL.OPERATION_HISTORY',
    ORDER: 'LABEL.ORDER',
    ORDER_NUMBER: 'LABEL.ORDER_NUMBER',
    ORDER_RESULT: 'LABEL.ORDER_RESULT',
    ORDER_STATUS: 'LABEL.ORDER_STATUS',
    ORDINAL: 'LABEL.ORDINAL',
    ORGANIZATION: 'LABEL.ORGANIZATION',
    ORGANIZATION_NAME: 'LABEL.ORGANIZATION_NAME',
    ORGANIZATION_UNIT: 'LABEL.ORGANIZATION_UNIT',
    ORGANIZATION_UNIT_NAME: 'LABEL.ORGANIZATION_UNIT_NAME',
    ORIGINAL_PRICE: 'LABEL.ORIGINAL_PRICE',
    PAGE: 'LABEL.PAGE',
    PART_NUMBER: 'LABEL.PART_NUMBER',
    PAGEVIEWS_DASHBOARD: 'LABEL.PAGEVIEWS_DASHBOARD',
    PAYMENT_AMOUNT: 'LABEL.PAYMENT_AMOUNT',
    PAYMENT_METHOD: 'LABEL.PAYMENT_METHOD',
    PAYMENT_METHOD_NAME: 'LABEL.PAYMENT_METHOD_NAME',
    PAYMENT_TYPE: 'LABEL.PAYMENT_TYPE',
    PER_HOUR: 'LABEL.PER_HOUR',
    PER_MONTH: 'LABEL.PER_MONTH',
    PER_YEAR: 'LABEL.PER_YEAR',
    PERCENT: 'LABEL.PERCENT',
    PERCENTAGE: 'LABEL.PERCENTAGE',
    PERCENTAGE_LIST_PRICE: 'LABEL.PERCENTAGE_LIST_PRICE',
    PERCENTAGE_LIST_PRICE_SYMBOL: 'LABEL.PERCENTAGE_LIST_PRICE_SYMBOL',
    PERIOD: 'LABEL.PERIOD',
    PERSONAL_INFO: 'LABEL.PERSONAL_INFO',
    PERMISSIONS: 'LABEL.PERMISSIONS',
    PHONE: 'LABEL.PHONE',
    PIVOT_SAVE_INFO: 'LABEL.PIVOT_SAVE_INFO',
    PLAN: 'LABEL.PLAN',
    PLATFORM: 'LABEL.PLATFORM',
    POSTAL_CODE: 'LABEL.POSTAL_CODE',
    PREFERRED_REGION: 'LABEL.PREFERRED_REGION',
    PREFERRED_VENDOR: 'LABEL.PREFERRED_VENDOR',
    PRESET_FILTER: 'LABEL.PRESET_FILTER',
    PRESET_INFO: 'LABEL.PRESET_INFO',
    PREVIOUS: 'LABEL.PREVIOUS',
    PRICE_SCHEDULES: 'LABEL.PRICE_SCHEDULES',
    PRICE_SHEET: 'LABEL.PRICE_SHEET',
    PRICE_SHEET_UPLOAD: 'LABEL.PRICE_SHEET_UPLOAD',
    PRICING_CONFIG: 'LABEL.PRICING_CONFIG',
    PRICING_SETTING: 'LABEL.PRICING_SETTING',
    PRICING_SETTINGS: 'LABEL.PRICING_SETTINGS',
    PRIMARY_DOMAIN: 'LABEL.PRIMARY_DOMAIN',
    PRINCIPAL_TYPE: 'LABEL.PRINCIPAL_TYPE',
    PRIORITY: 'LABEL.PRIORITY',
    PROBLEM_SUBTYPE: 'LABEL.PROBLEM_SUBTYPE',
    PROBLEM_SUBTYPE_DESCRIPTION: 'LABEL.PROBLEM_SUBTYPE_DESCRIPTION',
    PROBLEM_SUBTYPE_LIST: 'LABEL.PROBLEM_SUBTYPE_LIST',
    PROBLEM_TYPE: 'LABEL.PROBLEM_TYPE',
    PROBLEM_TYPE_DESCRIPTION: 'LABEL.PROBLEM_TYPE_DESCRIPTION',
    PROCESSED: 'LABEL.PROCESSED',
    PRODUCT_CATEGORY: 'LABEL.PRODUCT_CATEGORY',
    PROPERTIES: 'LABEL.PROPERTIES',
    PROVINCE: 'LABEL.PROVINCE',
    PUBLISHER: 'LABEL.PUBLISHER',
    PURCHASE_PREREQUISITE: 'LABEL.PURCHASE_PREREQUISITE',
    QUANTITY: 'LABEL.QUANTITY',
    QUERY_RESULT: 'LABEL.QUERY_RESULT',
    RAM: 'LABEL.RAM',
    RANGE: 'LABEL.RANGE',
    RECOMMEND: 'LABEL.RECOMMEND',
    RECOMMENDED_BY: 'LABEL.RECOMMENDED_BY',
    RECOMMENDED_INSTANCE: 'LABEL.RECOMMENDED_INSTANCE',
    RECOMMENDED_SKU: 'LABEL.RECOMMENDED_SKU',
    RECOMMEND_RIGHT_SIZING_ANALYSIS_INSTANCE: 'LABEL.RECOMMEND_RIGHT_SIZING_ANALYSIS_INSTANCE',
    RECORDS: 'LABEL.RECORDS',
    REFERENCE_EXCHANGE_RATE: 'LABEL.REFERENCE_EXCHANGE_RATE',
    REGION: 'LABEL.REGION',
    REMOVE_PARTNERSHIP: 'LABEL.REMOVE_PARTNERSHIP',
    REQUEST: 'LABEL.REQUEST',
    REQUIRED: 'LABEL.REQUIRED',
    RESELLER: 'LABEL.RESELLER',
    RESELLERS: 'LABEL.RESELLERS',
    RESELLER_ACCEPTANCE_THROUGH_MICROSOFT: 'LABEL.RESELLER_ACCEPTANCE_THROUGH_MICROSOFT',
    RESELLER_AGENT: 'LABEL.RESELLER_AGENT',
    RESELLER_AGENT_ASSIGNMENT: 'LABEL.RESELLER_AGENT_ASSIGNMENT',
    RESELLER_ASSIGNMENTS: 'LABEL.RESELLER_ASSIGNMENTS',
    RESELLER_EMAIL: 'LABEL.RESELLER_EMAIL',
    RESELLER_PRIMARY_CONTACT: 'LABEL.RESELLER_PRIMARY_CONTACT',
    RESERVED_HOURS: 'LABEL.RESERVED_HOURS',
    RESOURCE_DATA: 'LABEL.RESOURCE_DATA',
    RESOURCES_LIST: 'LABEL.RESOURCES_LIST',
    RESOURCE_CHARGES_LIST: 'LABEL.RESOURCE_CHARGES_LIST',
    RESOURCE_PROPERTIES: 'LABEL.RESOURCE_PROPERTIES',
    RESERVATION: 'LABEL.RESERVATION',
    RESPONSE_TIME: 'LABEL.RESPONSE_TIME',
    RESULTS: 'LABEL.RESULTS',
    RESULT_CODE: 'LABEL.RESULT_CODE',
    RETAIL: 'LABEL.RETAIL',
    REVENUE_PERIOD: 'LABEL.REVENUE_PERIOD',
    REVIEW: 'LABEL.REVIEW',
    REVISION: 'LABEL.REVISION',
    RI: 'LABEL.RI',
    RIGHT_SIZING: 'LABEL.RIGHT_SIZING',
    RIGHT_SIZING_ANALYSIS: 'LABEL.RIGHT_SIZING_ANALYSIS',
    RIGHT_SIZING_ANALYSIS_DETAIL: 'LABEL.RIGHT_SIZING_ANALYSIS_DETAIL',
    ROLE: 'LABEL.ROLE',
    ROLE_ASSIGNMENTS: 'LABEL.ROLE_ASSIGNMENTS',
    ROLE_LIST: 'LABEL.ROLE_LIST',
    ROOT_CAUSE_ANALYSIS: 'LABEL.ROOT_CAUSE_ANALYSIS',
    ROUND_TO: 'LABEL.ROUND_TO',
    SALES_AGENT: 'LABEL.SALES_AGENT',
    SALES_AGENT_ASSIGNMENT: 'LABEL.SALES_AGENT_ASSIGNMENT',
    SALES_AGENT_EMAIL: 'LABEL.SALES_AGENT_EMAIL',
    SALES_AMOUNT: 'LABEL.SALES_AMOUNT',
    SALES_REVENUE: 'LABEL.SALES_REVENUE',
    SAVING: 'LABEL.SAVING',
    SAVINGS: 'LABEL.SAVINGS',
    SAVING_PERCENT: 'LABEL.SAVING_PERCENT',
    SAVINGS_RANGE: 'LABEL.SAVINGS_RANGE',
    SCHEDULED_REPORT: 'LABEL.SCHEDULED_REPORT',
    SCOPE: 'LABEL.SCOPE',
    SELECTED: 'LABEL.SELECTED',
    SELECT_ONE_OPTION: 'LABEL.SELECT_ONE_OPTION',
    SEND_EMAIL_DESCRIPTION: 'LABEL.SEND_EMAIL_DESCRIPTION',
    SENSITIVITY: 'LABEL.SENSITIVITY',
    SERVICE_PLANS: 'LABEL.SERVICE_PLANS',
    SERVICE_TYPE: 'LABEL.SERVICE_TYPE',
    SHOW_PRICE_AS: 'LABEL.SHOW_PRICE_AS',
    SHOW_REVENUE_AS: 'LABEL.SHOW_REVENUE_AS',
    SKU_COUNTS: 'LABEL.SKU_COUNTS',
    SKU_INFO: 'LABEL.SKU_INFO',
    SKU_NAME: 'LABEL.SKU_NAME',
    SLOWEST_REQUEST: 'LABEL.SLOWEST_REQUEST',
    SPOT: 'LABEL.SPOT',
    STATE: 'LABEL.STATE',
    STORAGE: 'LABEL.STORAGE',
    SUBJECT: 'LABEL.SUBJECT',
    SUBMENU: 'LABEL.SUBMENU',
    SUBMITTED: 'LABEL.SUBMITTED',
    SUBSCRIBED: 'LABEL.SUBSCRIBED',
    SUBSCRIPTIONS: 'LABEL.SUBSCRIPTIONS',
    SUBSCRIPTION_NAME: 'LABEL.SUBSCRIPTION_NAME',
    SUMMARY: 'LABEL.SUMMARY',
    SUPPORT: 'LABEL.SUPPORT',
    SUPPORT_AGENT: 'LABEL.SUPPORT_AGENT',
    SUPPORT_AGENT_EMAIL: 'LABEL.SUPPORT_AGENT_EMAIL',
    SUPPORT_OWNER: 'LABEL.SUPPORT_OWNER',
    SUPPORT_TICKET: 'LABEL.SUPPORT_TICKET',
    SUPPORT_TICKET_INFO: 'LABEL.SUPPORT_TICKET_INFO',
    SUSPEND: 'LABEL.SUSPEND',
    SUSPENDED: 'LABEL.SUSPENDED',
    SUSPENDED_SUBSCRIPTION: 'LABEL.SUSPENDED_SUBSCRIPTION',
    SWITCHABLE_USER_ASSIGNMENTS: 'LABEL.SWITCHABLE_USER_ASSIGNMENTS',
    SWITCHABLE_USER_HAS_BEEN_UPDATED: 'LABEL.SWITCHABLE_USER_HAS_BEEN_UPDATED',
    SYNC_LAST_DATA_MODIFIED_TIME: 'LABEL.SYNC_LAST_DATA_MODIFIED_TIME',
    SYNCED: 'LABEL.SYNCED',
    SYSTEM_MAINTENANCE: 'LABEL.SYSTEM_MAINTENANCE',
    TABLE: 'LABEL.TABLE',
    TAG_ID: 'LABEL.TAG_ID',
    TAG_NAME: 'LABEL.TAG_NAME',
    TAGS: 'LABEL.TAGS',
    TARGET_SKU: 'LABEL.TARGET_SKU',
    TAX_EXEMPTION_DETAIL: 'LABEL.TAX_EXEMPTION_DETAIL',
    TAX_EXEMPTION: 'LABEL.TAX_EXEMPTION',
    TAX_ID: 'LABEL.TAX_ID',
    TAX_INFO: 'LABEL.TAX_INFO',
    TAX_RATE: 'LABEL.TAX_RATE',
    TEMPSTORAGE: 'LABEL.TEMPSTORAGE',
    TERM: 'LABEL.TERM',
    TEXT: 'LABEL.TEXT',
    TIME_STAMP: 'LABEL.TIME_STAMP',
    TIMESPAN: 'LABEL.TIMESPAN',
    TIME_ZONE: 'LABEL.TIME_ZONE',
    TITLE: 'LABEL.TITLE',
    TOP: 'LABEL.TOP',
    TUTORIAL_VIDEO: 'LABEL.TUTORIAL_VIDEO',
    TYPE: 'LABEL.TYPE',
    UNCONSUMED: 'LABEL.UNCONSUMED',
    UNDEDUCTED_COST: 'LABEL.UNDEDUCTED_COST',
    UNDEDUCTED_HOURS: 'LABEL.UNDEDUCTED_HOURS',
    UNIT_PRICE: 'LABEL.UNIT_PRICE',
    UNIT_PRICE_1Y: 'LABEL.UNIT_PRICE_1Y',
    UNIT_TYPE: 'LABEL.UNIT_TYPE',
    UNSELECTED: 'LABEL.UNSELECTED',
    UNSUBSCRIBE: 'LABEL.UNSUBSCRIBE',
    UNSUBSCRIBED: 'LABEL.UNSUBSCRIBED',
    UNSYNCED: 'LABEL.UNSYNCED',
    UPDATE_LICENSE_AND_CREATE_INFO: 'LABEL.UPDATE_LICENSE_AND_CREATE_INFO',
    UPDATE_SUBSCRIPTION_STATUS: 'LABEL.UPDATE_SUBSCRIPTION_STATUS',
    USAGE: 'LABEL.USAGE',
    USAGE_BREAKDOWN: 'LABEL.USAGE_BREAKDOWN',
    USAGE_COST: 'LABEL.USAGE_COST',
    USAGE_DASHBOARD: 'LABEL.USAGE_DASHBOARD',
    USAGE_DATE: 'LABEL.USAGE_DATE',
    USAGE_DETAIL: 'LABEL.USAGE_DETAIL',
    USAGE_HOURS: 'LABEL.USAGE_HOURS',
    USAGE_FILTER_DETAIL: 'LABEL.USAGE_FILTER_DETAIL',
    USAGE_LINEITEMS: 'LABEL.USAGE_LINEITEMS',
    USAGE_MONITOR: 'LABEL.USAGE_MONITOR',
    USAGE_QTY: 'LABEL.USAGE_QTY',
    USAGE_QUERY: 'LABEL.USAGE_QUERY',
    USAGE_SUMMARY: 'LABEL.USAGE_SUMMARY',
    USED_HOURS: 'LABEL.USED_HOURS',
    USER: 'LABEL.USER',
    USERS: 'LABEL.USERS',
    USERS_LICENSES: 'LABEL.USERS_LICENSES',
    USER_ACTIVITIES: 'LABEL.USER_ACTIVITIES',
    USER_ASSIGNMENTS: 'LABEL.USER_ASSIGNMENTS',
    USER_GROUP: 'LABEL.USER_GROUP',
    USER_GROUPS: 'LABEL.USER_GROUPS',
    USER_GROUP_ASSIGNMENT: 'LABEL.USER_GROUP_ASSIGNMENT',
    USER_GROUP_CONTROL_ASSIGNMENTS: 'LABEL.USER_GROUP_CONTROL_ASSIGNMENTS',
    USER_GROUP_CONTROL_LIST: 'LABEL.USER_GROUP_CONTROL_LIST',
    USER_GROUP_NAME: 'LABEL.USER_GROUP_NAME',
    USER_INFO: 'LABEL.USER_INFO',
    USER_LIST: 'LABEL.USER_LIST',
    LATENCY_FROM_YOU: 'LABEL.LATENCY_FROM_YOU',
    USER_SEARCH: 'LABEL.USER_SEARCH',
    VALUE: 'LABEL.VALUE',
    VAULT_URI: 'LABEL.VAULT_URI',
    VEIW_CUSTOMERS: 'LABEL.VEIW_CUSTOMERS',
    VM_SIZE: 'LABEL.VM_SIZE',
    WAIT_FOR: 'LABEL.WAIT_FOR',
    WEEKLY_USAGE: 'LABEL.WEEKLY_USAGE',
    WRITER: 'LABEL.WRITER',
    YEARLY_SAVINGS: 'LABEL.YEARLY_SAVINGS',
  },
};
