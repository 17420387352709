import { Component, Injector, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
// Caloudi
import { BaseComponent } from '@base';

// Replace by this one
const range = (start: number, end: number): number[] => {
  let ans: number[] = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
};

@Component({
  selector: 'cron-time-picker',
  templateUrl: './cron-time-picker.component.html',
  styleUrls: ['./cron-time-picker.component.sass'],
  providers: [],
})
export class TimePickerComponent extends BaseComponent {
  @Input('disabled') public disabled: boolean;
  @Input('use24HourTime') public use24HourTime: boolean = true;
  @Input('hideHours') public hideHours: boolean;
  @Input('hideMinutes') public hideMinutes: boolean;
  @Input('hideSeconds') public hideSeconds: boolean = true;

  public get hours(): number[] {
    return this.use24HourTime ? range(0, 23) : range(0, 12);
  }

  public minutes = range(0, 59);
  public seconds = range(0, 59);
  public hourTypes = ['AM', 'PM'];

  constructor(public readonly injector: Injector, public readonly parent: ControlContainer) {
    super(injector);
  }
}
