// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
// Interface
import { AuthUser } from '@core/model';

/**
 * Auth Management For Back-End API
 *
 * @author Tony.su
 *
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly httpOptions: Record<string, unknown>;

  constructor(private readonly http: HttpClient) {
    this.httpOptions = { withCredentials: true };
  }

  public getSSORequestConfig(): Observable<SSORequestConfig> {
    const configKey: string = environment.apiServer.ssoConfig;
    return this.http.get<SSORequestConfig>(
      `${environment.apiServer.url}/api/auth/sso/requestConfig/${configKey}`,
      this.httpOptions
    );
  }

  public ssoLogin(idTokenBase64: string, stateBase64: string): Observable<AuthUser> {
    return this.http.post<AuthUser>(
      `${environment.apiServer.url}/api/auth/sso/login`,
      { idTokenBase64, stateBase64 },
      this.httpOptions
    );
  }

  public refreshAccessToken(): Observable<string> {
    return this.http.get<string>(`${environment.apiServer.url}/api/auth/refreshAccessToken`, this.httpOptions);
  }

  public login(userId: string, password: string): Observable<AuthUser> {
    return this.http.post<AuthUser>(
      `${environment.apiServer.url}/api/auth/login`,
      { userId, password },
      this.httpOptions
    );
  }

  public yslLogin(accessKey: string): Observable<AuthUser> {
    return this.http.post<AuthUser>(
      `${environment.apiServer.url}/api/auth/login`,
      { accessKey },
      this.httpOptions
    );
  }

  public loginWithPasswordChange(loginWithPasswdChange: LoginWithPasswdChange): Observable<AuthUser> {
    return this.http.post<AuthUser>(
      `${environment.apiServer.url}/api/auth/loginWithPasswordChange`,
      loginWithPasswdChange,
      this.httpOptions
    );
  }

  public autoLogin(encUserToken: string): Observable<AuthUser> {
    return this.http.get<AuthUser>(`${environment.apiServer.url}/api/auth/autoLogin/${encUserToken}`, this.httpOptions);
  }

  /** Passwd is from 2fa */
  public otpLogin(userId: string, password: string): Observable<AuthUser> {
    return this.http.post<AuthUser>(`${environment.apiServer.url}/api/auth/login/OTP`, { userId, password });
  }
}

export interface SSORequestConfig {
  azureSSORequestUrl: string;
  gcpSSORequestUrl: string;
}

export interface LoginWithPasswdChange {
  userLoginPost: { userId: string; password: string; };
  userPasswordUpdatePost: { existingPassword: string; newPassword: string; };
}
