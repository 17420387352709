// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';

@Injectable({ providedIn: 'root' })
export class WebChatBotService {
  constructor(private readonly http: HttpClient) {}

  public GenerateWebChatToken(): Observable<ChatBotToken> {
    return this.http.get<ChatBotToken>(`${environment.cmpAPIServer.url}/api/cloudsec/webchat/tokens/generate`);
  }

  public RefreshWebChatToken(token: ChatBotToken): Observable<ChatBotToken> {
    return this.http.post<ChatBotToken>(`${environment.cmpAPIServer.url}/api/cloudsec/webchat/tokens/refresh`, token);
  }
}

export interface ChatBotToken {
  conversationId: string;
  token: string;
  expires_in: number;
}
