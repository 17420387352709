// Angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'caloudi-donut-chart',
  templateUrl: './caloudi-donut-chart.component.html',
  styleUrls: ['./caloudi-donut-chart.component.sass'],
})
export class CaloudiDonutChartComponent {
  @Input('dataInput') public dataInput: unknown[];
  @Input('labelInput') public labelInput: unknown[];

  public data: unknown;
  public options: unknown;

  constructor() {
    this.data = {
      labels: this.labelInput,
      datasets: [
        {
          data: this.dataInput,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };

    this.options = {
      animation: {
        duration: 0,
      },
    };
  }
}
