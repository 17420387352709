export interface PowerbiEmbed {
  embedToken: string;
  embedUrl: string;
  reportId: string;
  datasetId?: string;
  tokenType: number; // 0=Aad, 1=Embed
}

export class PowerbiEmbed {
  public static init(): PowerbiEmbed {
    const powerbiEmbed: PowerbiEmbed = {
      embedToken: '',
      embedUrl: '',
      reportId: '',
      datasetId: '',
      tokenType: 0,
    };
    return powerbiEmbed;
  }
}
