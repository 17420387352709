<div class="dashboard">
  <div class="grid colorbox-{{ colorboxNum }}">
    <div class="dollar_icon color_icon-{{ colorboxNum }} py-3">
      <i class="pi pi-dollar {{ icon }}"></i>
    </div>
    <div>
      <p class="colorbox-name m-0">{{ name }}</p>
      <p
        class="colorbox-count"
        [attr.length]="(count | number: '1.0-0').toString().length"
        [ngClass]="{ mid: (count | number: '1.0-0').toString().length > 10 }"
        [textContent]="count | number: '1.0-0'"></p>
    </div>
  </div>
</div>
