// Interface
import { DocumentLangType, LanguageType } from '../enum';

/**
 * String converters.
 */
export default class StringUtil {

  /**
   * Check if the string is empty or null.
   * @param data original string
   * @returns boolean
   */
  public static checkNotNullAndNotEmpty(data: string): boolean {
    return data != null && data !== '';
  }

  /**
   * Functions to convert between document language and locale.
   * @returns LanguageSwitch
   */
  public static languageSwitch(): LanguageSwitch {
    const Trans2lang = (payload: DocumentLangType): LanguageType => {
      let result: LanguageType;
      switch (payload) {
        case DocumentLangType.CHT: result = LanguageType.CHT; break;
        case DocumentLangType.CHS: result = LanguageType.CHS; break;
        default: result = LanguageType.ENG; break;
      }
      return result;
    };

    const Lang2trans = (payload: LanguageType): DocumentLangType => {
      let result: DocumentLangType;
      switch (payload) {
        case LanguageType.CHT: result = DocumentLangType.CHT; break;
        case LanguageType.CHS: result = DocumentLangType.CHS; break;
        default: result = DocumentLangType.ENG; break;
      }
      return result;
    };
    return {
      Trans2lang: Trans2lang,
      Lang2trans: Lang2trans,
    };
  }

  /**
   * Turn string into a start case format.
   *
   * ex: start666Case => Start Case
   * @param payload original string
   * @returns final result
   */
  public static startCase(payload: string): string {
    return payload
      ?.replace(/[^\w]/g, ' ')
      .split(' ')
      .map(k => k.charAt(0)?.toUpperCase() + k.slice(1).toLowerCase())
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim() || '';
  }
}

declare type Trans2lang = (payload: DocumentLangType) => LanguageType;
declare type Lang2trans = (payload: LanguageType) => DocumentLangType;
declare interface LanguageSwitch {
  Trans2lang: Trans2lang;
  Lang2trans: Lang2trans;
}
