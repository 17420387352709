// Angular Core
import { AfterViewInit, Component, EmbeddedViewRef, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
// Primeng
import { Dialog } from 'primeng/dialog';
// Caloudi
import { BaseComponent } from '@base';

@Component({
  selector: 'prime-dialog',
  templateUrl: './prime-dialog.component.html',
  styleUrls: ['./prime-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class PrimeDialogComponent extends BaseComponent implements OnChanges, AfterViewInit {
  /** Table Instance */
  @ViewChild('dialog') public dialog: Dialog;

  @Input('class') public klass: string;
  public _klass: string;
  public set class(payload: string) {
    this._klass = payload || '';
  }

  public get class(): string {
    const menu: string = this.layoutService.isHorizontal
      ? ' menu_horizontal'
      : !this.layoutService.state.staticMenuDesktopInactive
        ? ' menu_active'
        : '';
    return (this._klass + menu).trim();
  }

  /** Prime Ng Icon */
  @Input('icon') public icon: string;
  /** Using hex */
  @Input('iconColor') public iconColor: string;
  @Input('header') public header: string;
  /** User custom dialog height */
  @Input('height') public height: string;

  /** TODO: add height & width contorl */
  @Input('minHeight') public minHeight: string;
  @Input('minWidth') public minWidth: string;

  @Input('visible') public displayDialog: boolean;
  @Output('visibleChange') public displayDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Overwrite default */
  @Input('dismissableMask') public dismissableMask: boolean = true;
  @Input('focusOnShow') public focusOnShow: boolean;
  @Input('maximizable') public maximizable: boolean;
  @Input('blockScroll') public blockScroll: boolean = true;
  @Input('draggable') public draggable: boolean;
  @Input('resizable') public resizable: boolean;
  @Input('closable') public closable: boolean = true;
  @Input('modal') public modal: boolean = true;

  @ViewChild('headerTemplate') private readonly headerTemplate: TemplateRef<HTMLElement>;
  @ViewChild('footerTemplate') private readonly footerTemplate: TemplateRef<HTMLElement>;
  @ViewChild('headerContent') private readonly headerContent: HTMLElement;
  @ViewChild('footerContent') private readonly footerContent: HTMLElement;
  public headerElement: EmbeddedViewRef<HTMLElement>;
  public footerElement: EmbeddedViewRef<HTMLElement>;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  /**
   * Change class when parent change it
   * @param changes SimpleChanges
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // this.logger.debug('changes:', [changes]);
    this.class = changes.class?.currentValue as string;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const el: HTMLElement = document.querySelector('div.p-dialog-footer');
      this.headerElement = this.headerTemplate.createEmbeddedView(this.headerContent);
      this.footerElement = this.footerTemplate.createEmbeddedView(this.footerContent);
      // this.logger.debug('view:', [this.headerElement, this.footerElement, el]);
      if (this.footerElement?.rootNodes.length === 0) el.remove();
    }, 0);
  }

  public hideDialog(): void {
    // this.logger.debug('hide:', [this.displayDialog]);
    this.dialog.unbindGlobalListeners();
    this.displayDialogChange.emit(this.displayDialog);
  }
}
