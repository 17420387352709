<p *ngIf="debug">
  coord:
  {{ currentCoord?.x ?? 0 }}x{{ currentCoord?.y ?? 0 }} | {{ selectionArea?.startx ?? 0 }}x{{
    selectionArea?.starty ?? 0
  }}
  | {{ selectionArea?.endx ?? 0 }}x{{ selectionArea?.endy ?? 0 }}
</p>

<button pButton aria-label="Button" *ngIf="enableUndo" [label]="'undo'" (click)="undoLast()"></button>

<table [style]="{ '--padding': padding }">
  <thead>
    <th></th>
    <ng-container *ngFor="let col of colTitleList">
      <th>
        <div>{{ col.label }}</div>
      </th>
    </ng-container>
  </thead>
  <tbody #tbody>
    <ng-container *ngFor="let row of rowTitleList; let rowIndex = index">
      <tr>
        <td>
          <div>{{ row.label }}</div>
        </td>
        <ng-container *ngFor="let col of colTitleList; let colIndex = index">
          <td parking-schedule-cell [row]="rowIndex + 1" [col]="colIndex + 1">
            <div
              #cell
              class="cell"
              [ngClass]="{ animated: currentState && cellAnimated }"
              [attr.rowIndex]="rowIndex + 1"
              [attr.colIndex]="colIndex + 1">
              <ng-container *ngIf="currentState && displayLabel">
                {{ currentState?.[rowIndex]?.[colIndex] }}
              </ng-container>
            </div>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>

<div
  *ngIf="onSelect"
  class="on_select"
  [style]="{
    '--start-x': calcPoint(selectionArea.starty, selectionArea.endy) + 'px',
    '--start-y': calcPoint(selectionArea.startx, selectionArea.endx) + 'px',
    '--width': calcArea(selectionArea.startx, selectionArea.endx) + 'px',
    '--height': calcArea(selectionArea.starty, selectionArea.endy) + 'px',
  }"></div>
