/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// Angular
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
// Ngx
import { NGXLogger } from 'ngx-logger';
// Primeng
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';
// Rxjs
import { Subject, takeUntil } from 'rxjs';
// Caloudi
import { CommonUtil, CryptoUtil, CSVUtil, JSONUtil } from '@util';
// Store
import { Store } from '@ngrx/store';
import { AppState, AuthState } from '@Reducers';
import Selectors from '@Selectors';
// Interface
import * as M from '@base/model';
import { PaginatorPosition, SelectionMode } from '@core/enum';
import { fas } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'prime-table',
  templateUrl: './prime-table.component.html',
  styleUrls: ['./prime-table.component.sass'],
  animations: [
    trigger('columnAnimation', [
      transition(':enter', [
        style({ opacity: 0.5 }),
        animate('.2s cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.2s cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 0.5 })),
      ]),
    ]),
    trigger('rowExpansionAnimation', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('.2s cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('.2s cubic-bezier(0.86, 0, 0.07, 1)', style({ opacity: 0, height: 0 })),
      ]),
    ]),
  ],
})
export class PrimeTableComponent implements OnChanges, OnDestroy {

  // Table Instance
  @ViewChild('dt') public primeTable: Table;
  private readonly store: Store<AppState>;
  private readonly logger: NGXLogger;

  // Statments
  public replaceEmptyCell: boolean = !0;
  public loading: boolean = !0;
  public hasData: boolean = !1;

  public filterInput = '';
  public selectedRow: any;
  public globalFilterFields: string[];
  public pageItems: number[];
  public filters: M.TableStateEvent['filters'] = {};

  private _eid: string;
  private tableStates: M.TableStateEvent;
  private dialogElement: HTMLElement;
  private searchBoxValue: string;

  // Readonly States
  public readonly fas = fas;
  public readonly styleClass: string = 'p-datatable-striped';
  public readonly pivotStyleClass: string = '';
  public readonly exportFilename = '8iSoft';
  private readonly logActive: boolean = !1;
  private readonly pageItemsArray: number[] = [5, 10, 15, 25, 50, 100];
  private readonly timeFormCell: string[] = ['syncTime', 'lastSeenOn', 'createdOn', 'updatedOn'];
  private readonly hiddenColumns: string[] = ['_attributes'];
  private readonly dataKeyIgnoreList: string[] = ['actiongroup', 'edit', 'billingperiod', 'billingperiodid', '_attributes'];

  // Table Configurations
  @Input('selection') public selection: TableItem;
  @Input('caption') public caption: boolean = !1;
  @Input('paginator') public paginator: boolean = !0;
  @Input('autoLayout') public autoLayout: boolean = !0;
  @Input('values') public values: TableItem[];
  @Input('columns') public columns: M.PrimeTableColumn[];
  @Input('scrollHeight') public scrollHeight: string;
  @Input('rowExpansion') public rowExpansion: boolean = !1;
  @Input('stickyColumn') public stickyColumn: number;
  @Input('pageSize') public pageSize: number;
  @Input('excludeList') public excludeList: string[] = [];
  @Input('rowExpandMode') public rowExpandMode: 'multiple' | 'single' = 'single';
  @Input('columnTemplate') public columnTemplate: TemplateRef<{ column: unknown; rowData: unknown; rowIndex: unknown; expanded: unknown; }>;
  @Input('pivotTemplate') public pivotTemplate: TemplateRef<HTMLElement>;
  @Input('captionTemplate') public captionTemplate: TemplateRef<{ columns: unknown; }>;
  @Input('paginatorPosition') public paginatorPosition: PaginatorPosition = PaginatorPosition.top;
  @Input('selectionMode') public selectionMode: SelectionMode = SelectionMode.single;
  @Input('expandedRowKeys') public expandedRowKeys: Record<string, boolean> = {};
  @Input('rowExpansionTemplate') public rowExpansionTemplate: TemplateRef<{ columns: unknown; rowData: unknown; rowIndex: unknown; }>;
  @Input('resizeable') public resizeable: boolean;
  @Input('sortField') public _sortField: string;
  @Input('groupKey') public _groupKey: string;
  @Input('dataKey') public _dataKey: boolean | string;
  @Input('pivotKey') public _pivotKey: number = -1;
  @Input('sortOrder') public sortOrder: -1 | 1 = 1;
  @Input('tableName') public _tableName: string = '';

  // Custom Configurations
  @Input('emptyValueMessage') public emptyValueMessage: string;
  @Input('loading') public manualLoading: boolean;
  @Input('emptyCell') public stringReplacement: boolean | string = '-';

  // All events are same as p-table's events
  @Output('onFilter') public onFilterEmitter = new EventEmitter<M.FilterEvent>();
  @Output('onLazyLoad') public onLazyLoadEmitter = new EventEmitter<M.LazyLoadingEvent>();
  @Output('onPage') public onPageEmitter = new EventEmitter<M.PageEvent>();
  @Output('onRowCollapse') public onRowCollapseEmitter = new EventEmitter<M.RowExpandEvent>();
  @Output('onRowExpand') public onRowExpandEmitter = new EventEmitter<M.RowExpandEvent>();
  @Output('onRowSelect') public onRowSelectEmitter = new EventEmitter<M.RowSelectEvent>();
  @Output('onRowUnselect') public onRowUnselectEmitter = new EventEmitter<M.RowSelectEvent>();
  @Output('onSort') public onSortEmitter = new EventEmitter<M.SortingEvent>();
  @Output('onStateRestore') public onStateRestoreEmitter = new EventEmitter<M.TableStateEvent>();
  @Output('onStateSave') public onStateSaveEmitter = new EventEmitter<M.TableStateEvent>();

  public get eid(): string {
    return this._eid ? '@@' + this._eid : void 0;
  }

  public get tableName(): string {
    return this._tableName
      .trim()
      .toLowerCase()
      .replace(/\s/gi, '_')
      .replace(/(.*)_table$/, '$1');
  }

  public get dataKey(): string {
    try {
      if (typeof this._dataKey === 'boolean' && this._dataKey)
        return this.columns.filter(el =>
          !this.dataKeyIgnoreList.includes(el.field.toLowerCase()))[0].field;
      else return (this._dataKey as string) || void 0;
    } catch (e) {
      return void 0;
    }
  }

  public get stateKey(): string {
    try {
      if (!this.eid) return void 0;
      const dialog: HTMLElement = this.el.nativeElement.closest<HTMLElement>('p-dialog');
      const key: string = (
        this.eid +
        location.pathname.slice(1) +
        (this.tableName ? ':' + this.tableName : '')
      ).toLowerCase();
      // + this.eid;
      if (this.primeTable && dialog && this.dialogElement !== dialog) {
        this.dialogElement = dialog;
        setTimeout(() => this.bindPageSize(this.values));
      }
      this.logActive && this.logger.debug('crypto:', [CryptoUtil.md5(key)]);
      this.logActive && this.logger.debug('key:', [this.eid, key, location.pathname.slice(1), this.tableName]);
      if (!dialog) return CryptoUtil.md5(key);
      else return void 0;
    } catch (e) {
      this.logger.error('table state key error:', e);
      return void 0;
    }
  }

  public get pivotKey(): string[] {
    try {
      return this._pivotKey > 0 ? this.columns.slice(0, this._pivotKey).map(el => el.field) : void 0;
    } catch (e) {
      return void 0;
    }
  }

  public get sortField(): string {
    return this._groupKey || this._sortField || this.dataKey;
  }

  public get groupMode(): 'rowspan' | 'subheader' {
    if (this._groupKey) return 'subheader';
    else if (this.pivotKey) return 'rowspan';
    else return null;
  }

  public get searchBoxInputValue(): M.LogUserEventTable {
    const item: M.LogUserEventTable = { label: 'table_search', payload: this.searchBoxValue };
    if (this.tableName) item.tableName = this.tableName;
    return item;
  }

  public get logEventTableColumns(): M.LogUserEventTable {
    const item: M.LogUserEventTable = { label: 'table_export', payload: this.columns };
    if (this.tableName) item.tableName = this.tableName;
    return item;
  }

  public get currentPageReportTemplate(): string {
    return this.hasData ? '{currentPage} - {totalPages}' : '1 - 1';
  }

  public exportItems: MenuItem[] = [
    { label: 'CSV', icon: 'pi pi-file', command: () => this.exportCSV() },
    // { label: 'EXCEL', icon: 'pi pi-file', command: () => this.exportExcel() },
    // { label: 'PRINT', icon: 'pi pi-file', command: () => this.printTable() },
    // { label: 'COPY', icon: 'pi pi-file', command: () => { } },
  ];

  private readonly tableSub$: Subject<AuthState> = new Subject<AuthState>();

  constructor(
    private readonly _injector: Injector,
    private readonly el: ElementRef<HTMLElement>,
  ) {
    this.logger = this._injector.get(NGXLogger);
    this.store = this._injector.get<Store<AppState>>(Store);

    this.store.select(Selectors.auth).pipe(takeUntil(this.tableSub$))
      .subscribe(auth => (this._eid = auth.user?.userProfile?.eaEnrollmentNumber));
  }

  public ngOnChanges(changes: TableSimpleChanges): void {
    const col: TableSimpleChange<M.PrimeTableColumn[]> = changes.columns;
    const val: TableSimpleChange<TableItem[]> = changes.values;
    const colValue: M.PrimeTableColumn[] = changes.columns?.currentValue;
    const valValue: TableItem[] = changes.values?.currentValue;
    const colPre: M.PrimeTableColumn[] = changes.columns?.previousValue;
    const valPre: TableItem[] = changes.values?.previousValue;
    const colChange: boolean = CommonUtil.ngIsChanges(col);
    const valChange: boolean = CommonUtil.ngIsChanges(val);
    const dialog: HTMLElement = this.el.nativeElement.closest('p-dialog');
    [this.dialogElement, this.excludeList] = [undefined, [...this.timeFormCell]];

    if (valValue === undefined && valPre) this.loading = true;

    this.logActive && this.logger.debug(
      ['chg:', changes, this.columns?.length, this.values?.length, this.pageSize, this.pageItems],
      ['col:', col?.currentValue?.length, colPre?.length, colChange],
      ['val:', val?.currentValue?.length, valPre?.length, valChange],
      ['fin:', this.loading, !!col?.currentValue, !!val?.currentValue],
      ['dialog:', dialog, this.dialogElement],
      ['table:', this.primeTable?.rows, this.primeTable?.first]
    );

    /** Empty Cell Replacement */
    if (changes?.stringReplacement?.firstChange && this.stringReplacement === false) this.replaceEmptyCell = false;
    if (changes?.excludeList?.firstChange && CommonUtil.ngIsChanges(changes?.excludeList))
      this.excludeList = [...this.timeFormCell, ...this.excludeList];

    /** Bind Page Size Triggers */
    if (colValue && valValue) {
      this.logActive && this.logger.debug('a:', [valChange, colChange, valValue, this.pivotKey]);
      this.bindPageSize(this.values);
      if ((this.tableStates?.first ?? 0) >= valValue.length) this.tableResetDelay();
      if ((valValue.length || -1) < (valPre ? valPre.length - (valPre.length % this.pageSize) : 0)) {
        this.logActive && this.logger.debug('b:', [valChange, valValue.length, valPre?.length]);
        this.tableResetDelay();
      }
    } else if (!colChange && valChange) {
      this.logActive && this.logger.debug('c:', [valChange]);
      this.bindPageSize(this.values);
      this.tableResetDelay();
    }

    if (changes.selection?.currentValue) {
      this.logActive && this.logger.debug('selection', [changes.selection.currentValue, this.primeTable]);
      const value = CommonUtil.deepCopy(this.values);
      value.forEach((v: TableItem, i: number) => {
        if (CommonUtil.equals(v, changes.selection.currentValue)) {
          // this.logger.debug('result:', [v, i])
          this.primeTable.scrollToVirtualIndex(i);
        }
      });
    }

    if (this.pivotKey?.length > 0) {
      try {
        this.values = this.values.map(v => ({
          ...v,
          _attributes: v['_attributes'] && JSONUtil.parse(v['_attributes'] as string)
        }));
        this.logActive && this.logger.debug('v:', [this.values]);
      } catch (error) { this.logger.error('pivot error', [error]); }
    }

    setTimeout(() => {
      if (!dialog && !val?.firstChange && typeof valPre !== 'undefined') this.saveState();
      // if (!valValue?.some(v => v === this.tableStates?.selection)) this.primeTable.clearState();
      this.logActive && this.logger.debug('table:', [this.primeTable.filters, this.filters]);
    });
  }

  private bindPageSize(val: TableItem[]): void {
    if (!Array.isArray(val)) return;
    this.logActive &&
      this.logger.debug('pagesize init:', [val.length, this.pageSize, this.pageItems, this.primeTable?.rows]);
    this.pageItems = [];

    /** Bind Page Items list */
    if (val.length > 0 && val.length < 100) {
      this.pageItemsArray.forEach(item => {
        if (item < val.length) this.pageItems.push(item);
      });
      this.pageItems.push(val.length);
    } else {
      this.pageItems = [...this.pageItemsArray];
    }

    this.logActive && this.logger.debug('pageitem:', [this.pageSize, this.pageItems, this.primeTable?.rows]);

    /** Bind Page Size */
    setTimeout(() => {
      const currentRow = this.pageItems.findIndex(item => item === this.primeTable.rows);
      this.pageSize =
        this.pageItems.length <= 2 && this.pageSize !== this.pageItems[0]
          ? this.pageItems[1] || this.pageItems[0]
          : (this.pageSize = currentRow >= 0 ? this.pageItems[currentRow] : this.pageItems[1]);
      this.primeTable.rows = this.pageSize;
      this.logActive &&
        this.logger.debug('bind pagesize:', [this.pageSize, this.pageItems, currentRow, this.primeTable.rows]);
    });

    /** Finalize */
    this.hasData = this.values?.length > 0 && this.columns?.length > 0;
    this.globalFilterFields = this.columns?.map((item: M.PrimeTableColumn) => item.field);
    this.loading = false;
  }

  public getGroupRange(item: TableItem, rowIndex: number, key: string): RangeItem {
    const list = this.getSlicedList(rowIndex);
    let first = list.findIndex(val => typeof val !== 'undefined' && (<string>val[key])?.toUpperCase() === (<string>item[key])?.toUpperCase());
    let last = list.findIndex(val => typeof val !== 'undefined' && (<string>val[key])?.toUpperCase() !== (<string>item[key])?.toUpperCase());

    if (last === -1) last = list.length + 1;
    return { range: this.values.slice(first, last), rest: this.values.slice(last), first: first, last: last };
  }

  private getSlicedList(rowIndex: number, add: number = 0): TableItem[] {
    const list = rowIndex === 0 ? [...this.values] : this.values.slice(rowIndex + add);
    for (let i = 0; i < rowIndex; i++) {
      list.unshift(undefined);
    }
    return list;
  }

  /**
   * TODO: bind sum row into data
   */
  // private bindSumData(): void {
  //   return;
  // }

  public sumData(item: TableItem[], key: string): number {
    return item.map(el => el[key] as number).reduce((p, c) => p + c);
  }

  public actionGroupFix(col: M.PrimeTableColumn): boolean {
    return col.sort !== undefined && col.field === 'actionGroup' && !col.sort;
  }

  public searchBoxInput(e: Event): void {
    this.searchBoxValue = ((e.target as HTMLInputElement).value ?? '').trim();
    this.primeTable.filterGlobal(this.searchBoxValue, 'contains');
  }

  public getExpandLogItem<T>(rowData: T, expanded: boolean): M.LogEventRowExpand {
    const item: M.LogEventRowExpand = { label: 'row_expansion', payload: rowData, expanded: expanded };
    if (this.tableName) item.tableName = this.tableName;
    return item;
  }

  /**
   * Export CSV File
   */
  public exportCSV(): void {
    this.caloudiExportCSV();
  }

  private caloudiExportCSV(options?: { selectionOnly: boolean; }): void {
    let data = this.primeTable.filteredValue || this.primeTable.value;
    let csv = '';
    if (options?.selectionOnly) {
      data = this.primeTable.selection || [];
    }
    // Headers
    this.primeTable.columns.forEach((col: M.PrimeTableColumn, i, arr): void => {
      if (col.exportable !== false && col.field && col.field !== 'actionGroup') {
        csv += `"${col.field}"`;
        if (i < arr.length - 1) csv += this.primeTable.csvSeparator;
      }
    });
    // Body
    data.forEach(record => {
      csv += '\n';
      this.primeTable.columns.forEach((col: M.PrimeTableColumn, i, arr): void => {
        if (col.exportable !== false && col.field && col.field !== 'actionGroup') {
          let cellData = ObjectUtils.resolveFieldData(record, col.field);
          if (cellData == null) {
            cellData = '';
          } else {
            this.primeTable.exportFunction
              ? (cellData = this.primeTable.exportFunction({ data: cellData, field: col.field }))
              : (cellData = String(cellData).replace(/"/g, '""'));
          }
          csv += '"' + String(cellData) + '"';
          if (i < arr.length - 1) csv += this.primeTable.csvSeparator;
        }
      });
    });

    CSVUtil.exportCSV(csv, this.primeTable.exportFilename);
  }

  public hiddenColumn(payload: M.PrimeTableColumn): boolean {
    return this.hiddenColumns.includes(payload.field);
  }

  public onRowSelect<T>(event: M.RowSelectEvent<T>): void {
    this.selectedRow = event;
    this.onRowSelectEmitter.emit(event);
  }

  public onRowUnselect<T>(event: M.RowSelectEvent<T>): void {
    this.selectedRow = undefined;
    this.onRowUnselectEmitter.emit(event);
  }

  public onPage(event: M.PageEvent): void {
    this.onPageEmitter.emit(event);
  }

  public onSort<T>(event: M.SortingEvent<T>): void {
    if (!this.values) return;
    this.onSortEmitter.emit(event);
  }

  public onFilter(event: M.FilterEvent<TableItem>): void {
    setTimeout(() => this.bindPageSize(event?.filteredValue));
    this.onFilterEmitter.emit(event);
  }

  public onRowExpand<T>(event: M.RowExpandEvent<T>): void {
    this.onRowExpandEmitter.emit(event);
  }

  public onRowCollapse<T>(event: M.RowExpandEvent<T>): void {
    this.onRowCollapseEmitter.emit(event);
  }

  public onLazyLoad(event: M.LazyLoadingEvent): void {
    this.onLazyLoadEmitter.emit(event);
  }

  public onStateSave(event: M.TableStateEvent): void {
    this.onStateSaveEmitter.emit(event);
  }

  public onStateRestore(event: M.TableStateEvent): void {
    this.logActive && this.logger.debug('restore:', [event]);
    this.filterInput = (event.filters?.global as M.FilterMetaData)?.value || '';
    this.tableStates = event;
    this._sortField = event.sortField;
    this.sortOrder = event.sortOrder || 1;
    this.filters = event.filters || {};
    this.pageSize = event.rows;
    this.onStateRestoreEmitter.emit(event);
  }

  public tableReset(): void {
    this.filters = {};
    this.filterInput = '';
    this.primeTable.reset();
    this.saveState();
  }

  private tableResetDelay(): void {
    setTimeout(() => {
      this.filters = {};
      this.filterInput = '';
      this.primeTable.reset();
      this.saveState();
    });
  }

  private saveState(): void {
    this.logActive && this.logger.debug('saveState');
    this.primeTable.saveState();
  }

  public valueEmpty<T>(rowData: T): boolean {
    if (!this.replaceEmptyCell) return !0;
    switch (typeof rowData) {
      case 'string': return rowData?.length > 0;
      case 'object': return Object.keys(rowData ?? {})?.length > 0;
      case 'boolean':
      default: return !0;
    }
  }

  public specificColumn(field: keyof TableItem): boolean {
    return this.excludeList.some(item => item === field);
  }

  public specificColumnfilter(field: keyof TableItem): boolean {
    return [...this.timeFormCell].some(item => item === field);
  }

  public bindSelection(rowData: TableItem): void {
    this.selection = rowData;
  }

  /** Judge Header aligns */
  public isNumber(column: M.PrimeTableColumn): boolean {
    return column.headerPosition === 'right'
      || this.values?.some(item => typeof item?.[column.field] === 'number');
  }

  public ngOnDestroy(): void {
    this.tableSub$?.unsubscribe();
  }
}

interface TableItem<T = any> {
  [x: string]: T;
}

interface RangeItem<T = TableItem> {
  range: T[];
  rest: T[];
  first: number;
  last: number;
}

interface TableSimpleChanges extends SimpleChanges {
  selection: TableSimpleChange<TableItem>;
  caption: TableSimpleChange<boolean>;
  paginator: TableSimpleChange<boolean>;
  autoLayout: TableSimpleChange<boolean>;
  values: TableSimpleChange<TableItem[]>;
  columns: TableSimpleChange<M.PrimeTableColumn[]>;
  scrollHeight: TableSimpleChange<string>;
  rowExpansion: TableSimpleChange<boolean>;
  stickyColumn: TableSimpleChange<number>;
  pageSize: TableSimpleChange<number>;
  _dataKey: TableSimpleChange<boolean | string>;
  excludeList: TableSimpleChange<string[]>;
  rowExpandMode: TableSimpleChange<'multiple' | 'single'>;
  columnTemplate: TableSimpleChange<TemplateRef<HTMLElement>>;
  pivotTemplate: TableSimpleChange<TemplateRef<HTMLElement>>;
  captionTemplate: TableSimpleChange<TemplateRef<HTMLElement>>;
  paginatorPosition: TableSimpleChange<typeof PaginatorPosition>;
  selectionMode: TableSimpleChange<typeof SelectionMode>;
  expandedRowKeys: TableSimpleChange<Record<string, boolean>>;
  rowExpansionTemplate: TableSimpleChange<TemplateRef<HTMLElement>>;
  tableName: TableSimpleChange<string>;
  _pivotKey: TableSimpleChange<number>;
  emptyValueMessage: TableSimpleChange<string>;
  manualLoading: TableSimpleChange<boolean>;
  stringReplacement: TableSimpleChange<boolean | string>;
}

interface TableSimpleChange<T> extends SimpleChange {
  previousValue: T;
  currentValue: T;
}
