import { TabMenuItem } from '@base/model';
import { createAction, props } from '@ngrx/store';
import { LayoutConfig, TabMenuState } from '@Reducers';
import { ActionCreatorProp, ActionCreatorType } from '.';

// The Action interface
export enum TabMenuActionTypes {
  Change_Tab_Menu_Cache_By_Key = '[Change Cache By Key] Action',
  Change_Tab_Menu_Breadcrumbs = '[Change Tab Menu Breadcrumbs] Action',
  Change_Tab_Menu_Items = '[Change Tab Menu Items] Action',
  Change_Tab_Menu_Lang = '[Change Tab Menu Lang] Action',
  Change_Sub_Menu = '[Change Sub Menu] Action',
}

export enum TabMenuStaticActionTypes {
  Change_Tab_Menu_Statics_Tab_Key = '[Change Tab Key] Action',
  Change_Tab_Menu_Statics_Layout_Type = '[Change Layout Type] Action',
  Clear_Tab_Menu_Statics = '[Clear Tab Menu Statics] Action',
}

export type TabMenuPayload = {
  Change_Tab_Menu_Cache_By_Key: { cache: TabMenuState['cache']; };
  Change_Tab_Menu_Breadcrumbs: { breadcrumbs: TabMenuState['breadcrumbs']; };
  Change_Tab_Menu_Items: { items: TabMenuState['items']; };
  Change_Tab_Menu_Lang: { lang: TabMenuState['lang']; };
  Change_Sub_Menu: { items: TabMenuItem[]; };
};

export type TabMenuStaticPayload = {
  Change_Tab_Key: { tabKey: LayoutConfig['tabKey']; };
  Change_Layout_Type: { layoutType: LayoutConfig['layoutType']; };
};

// Tabmenu
export const ChangeCacheByKeyAction: ActionCreatorProp<TabMenuPayload['Change_Tab_Menu_Cache_By_Key']> = createAction(
  TabMenuActionTypes.Change_Tab_Menu_Cache_By_Key,
  props()
);

export const ChangeTabMenuBreadcrumbsAction: ActionCreatorProp<TabMenuPayload['Change_Tab_Menu_Breadcrumbs']> =
  createAction(TabMenuActionTypes.Change_Tab_Menu_Breadcrumbs, props());

export const ChangeTabMenuItemsAction: ActionCreatorProp<TabMenuPayload['Change_Tab_Menu_Items']> = createAction(
  TabMenuActionTypes.Change_Tab_Menu_Items,
  props()
);

export const ChangeTabMenuLangAction: ActionCreatorProp<TabMenuPayload['Change_Tab_Menu_Lang']> = createAction(
  TabMenuActionTypes.Change_Tab_Menu_Lang,
  props()
);

// Submenu
export const ChangeSubmenuAction: ActionCreatorProp<TabMenuPayload['Change_Sub_Menu']> = createAction(
  TabMenuActionTypes.Change_Sub_Menu,
  props()
);

// Statics Tabmenu
export const ChangeTabKeyAction: ActionCreatorProp<TabMenuStaticPayload['Change_Tab_Key']> = createAction(
  TabMenuStaticActionTypes.Change_Tab_Menu_Statics_Tab_Key,
  props()
);

export const ChangeLayoutTypeAction: ActionCreatorProp<TabMenuStaticPayload['Change_Layout_Type']> = createAction(
  TabMenuStaticActionTypes.Change_Tab_Menu_Statics_Layout_Type,
  props()
);

export const ClearTabMenuStaticsAction: ActionCreatorType = createAction(
  TabMenuStaticActionTypes.Clear_Tab_Menu_Statics
);

export const TabMenuActions: TabMenuActions = {
  ChangeCacheByKeyAction,
  ChangeTabMenuBreadcrumbsAction,
  ChangeTabMenuItemsAction,
  ChangeTabMenuLangAction,
  ChangeSubmenuAction,
};

export const TabMenuStaticActions: TabMenuStaticActions = {
  ChangeTabKeyAction,
  ChangeLayoutTypeAction,
  ClearTabMenuStaticsAction,
};

interface TabMenuActions {
  ChangeCacheByKeyAction: typeof ChangeCacheByKeyAction;
  ChangeTabMenuBreadcrumbsAction: typeof ChangeTabMenuBreadcrumbsAction;
  ChangeTabMenuItemsAction: typeof ChangeTabMenuItemsAction;
  ChangeTabMenuLangAction: typeof ChangeTabMenuLangAction;
  ChangeSubmenuAction: typeof ChangeSubmenuAction;
}

interface TabMenuStaticActions {
  ChangeTabKeyAction: typeof ChangeTabKeyAction;
  ChangeLayoutTypeAction: typeof ChangeLayoutTypeAction;
  ClearTabMenuStaticsAction: typeof ClearTabMenuStaticsAction;
}
