import { ConvertToyyyyMMddPipe } from './convert-to-yyyyMMdd.pipe';
import { DateAddDashPipe } from './date-add-dash.pipe';
import { NumberPipe } from './number.pipe';
import { StringPipe } from './string.pipe';
import { TableNaPipe } from './table-na.pipe';
import { TextReplacementPipe } from './text-replacement.pipe';

export * from './convert-to-yyyyMMdd.pipe';
export * from './date-add-dash.pipe';
export * from './number.pipe';
export * from './string.pipe';
export * from './table-na.pipe';
export * from './text-replacement.pipe';

export const CUSTOM_PIPES = [
  ConvertToyyyyMMddPipe,
  DateAddDashPipe,
  NumberPipe,
  StringPipe,
  TableNaPipe,
  TextReplacementPipe,
];
