// Angular
import { SimpleChange } from '@angular/core';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { BehaviorSubject, Observable } from 'rxjs';
// Caloudi
import JSONUtil from './json.util';

/**
 * Most used utils.
 */
export default class CommonUtil {

  /**
   * Check if it's object or not.
   * @param payload object to test
   * @returns boolean
   */
  public static isObject<T>(payload: T): boolean {
    if (payload === null || payload === undefined) return false;
    return typeof payload === 'function' || typeof payload === 'object';
  }

  /**
   * Check the object is empty or not.
   * @param payload object to test
   * @returns boolean
   */
  public static checkEmptyObject<T>(payload: T): boolean {
    try {
      return (<(keyof T)[]>Object.keys(payload)).length <= 0;
    } catch (error) {
      return false;
    }
  }

  /**
   * Check if the data is undefined.
   * @param payload object to test
   * @returns boolean
   */
  public static isUndefined<T>(payload: T): boolean {
    return payload === undefined || typeof payload === 'undefined';
  }

  /**
   * Change data type from Observable to BehaviorSubject.
   * @param observable observable type of object
   * @param initValue initial value
   * @returns BehaviorSubject
   */
  public static observable2BehaviorSub<T>(observable: Observable<T>, initValue: T = null): BehaviorSubject<T> {
    const subject = new BehaviorSubject(initValue);

    observable.subscribe({
      next: v => subject.next(v),
      error: e => subject.error(e),
      complete: () => subject.complete(),
    });

    return subject;
  }

  /**
   * Encode url UTF-8 message to base64, browser not support chinese url.
   * @param message origin string
   * @returns encoded string
   */
  public static base64Encode(message: string): string {
    try {
      return window.btoa(encodeURIComponent(message));
    } catch (error) {
      return window.btoa(encodeURI(message));
    }
  }

  /**
   * Decode url base64 to UTF-8 meaasage.
   * @param message encoded string
   * @returns decoded string
   */
  public static base64Decode(message: string): string {
    try {
      if (!message) return '';
      return decodeURIComponent(window.atob(message)) || decodeURI(window.atob(message));
    } catch (error) {
      return void 0;
    }
  }

  /**
   * Convert url fragment or parameter string into Json.
   * @param payload url fragment or parameter
   * @returns decoded url
   */
  public static URLDecode<T>(payload: string): T {
    try {
      const value: string = payload.slice(new RegExp(/^[?|#]/).test(payload) ? 1 : 0);
      const filter = /&([\w_]*)=((.|\r|\n)*&?(.|\r|\n)*)/;
      let [fragItem, temp] = [undefined as T, this.deepCopy('&' + value)];
      new URLSearchParams(value).forEach(val => {
        fragItem = { ...fragItem, [new RegExp(filter).exec(temp)[1]]: val };
        temp = '&' + new RegExp(filter).exec(temp)[2];
      });
      return fragItem;
    } catch (error) {
      return void 0;
    }
  }

  /**
   * Convert base64 string to Json object.
   * @param message base64 string
   * @returns Json object or error
   */
  public static base64toObject<T>(message: string): T | TypeError {
    try {
      return JSONUtil.parse<T>(this.base64Decode(message));
    } catch (error) {
      try {
        const result = {};
        message.split('.').map((val: string, i: number): void => {
          try {
            result[i] = JSONUtil.parse<T>(this.base64Decode(val));
          } catch (error) {
            result[i] = val;
          }
        });
        return result as T;
      } catch (error) {
        return error as TypeError;
      }
    }
  }

  /**
   * Convert base64 string to Blob.
   * @param message base64 string
   * @returns Blob object
   */
  public static base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays: BlobPart[] = new Array<BlobPart>(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Copy object to prevent variables connection.
   * @param payload object to copy
   * @returns copies from origin
   */
  public static deepCopy<T>(payload: T): T;
  public static deepCopy(...payloads: unknown[]): unknown[];
  public static deepCopy<T>(payload: T, ...payloads: unknown[]): unknown {
    try {
      // console.debug('deepcopy:', [payload, payloads]);
      let result = [payload ? JSONUtil.parse(JSONUtil.stringify(payload)) : undefined];
      if (payloads?.length > 0) {
        payloads.forEach(item => result.push(item ? JSONUtil.parse(JSONUtil.stringify(item)) : undefined));
        return result;
      } else {
        return payload ? JSONUtil.parse(JSONUtil.stringify(payload)) : (undefined as T);
      }
    } catch (error) {
      console.error('deepcopy error', [error, payload, payloads]);
      return void 0;
    }
  }

  /**
   * Equals without case, check a and b is deep equal.
   * @param a object A
   * @param b object B
   * @returns boolean
   */
  public static equals<A, B>(a: A, b: B): boolean {
    try {
      return JSONUtil.stringify(a)?.toUpperCase() === JSONUtil.stringify(b)?.toUpperCase();
    } catch (error) {
      return false;
    }
  }

  /**
   * Equals without case, check ngOnChanges data is change.
   * @param payload SimpleChange object
   * @param isFirst check is first change or not
   * @returns boolean
   */
  public static ngIsChanges(payload: SimpleChange, isFirst?: boolean): boolean {
    try {
      if (payload === undefined) return false;
      if (payload.currentValue === undefined && payload.previousValue === undefined) return false;
      if (payload.currentValue && payload.previousValue === undefined) return true;
      if (typeof isFirst === 'boolean' && isFirst && payload.previousValue === undefined) return false;
      return JSONUtil.stringify(payload.currentValue) !== JSONUtil.stringify(payload.previousValue || {});
    } catch (error) {
      return false;
    }
  }

  /**
   * Replace value in object key by key.
   * @param a object needs to replace values
   * @param b object provide new values to replace with
   */
  public static replaceKeyByKey<A, B>(a: A, b: B): void {
    if (!a || !b) return;
    const keyA = Object.keys(a);
    const keyB = Object.keys(b);
    keyB.forEach(keyb =>
      keyA.forEach(keya => {
        if (keya === keyb) a[keya] = b[keyb as keyof B];
      }));
  }

  /**
   * Create SelectItem array, if source is an object, please provide a key.
   * @param source string[] | object[]
   * @param key string
   * @returns SelectItem[]
   */
  public static bindAsSelectItem<S, K = string>(source: S[], key?: string): SelectItem<K>[] | SelectItem<S>[] {
    if (typeof source === 'undefined') return [];
    if (key) return source.map(val => <SelectItem<K>>{ label: val[key as keyof S], value: val[key as keyof S] });
    else return source.map(val => <SelectItem<S>>{ label: String(val), value: val });
  }

  /**
   * Create PrimeTableColumn by DataColumn.
   * @param source dataColumn[]
   * @returns PrimeTableColumn[]
   */
  public static bindAsPrimeTableColumn(source: DataColumn[]): PrimeTableColumn[] {
    return source?.map(val => <PrimeTableColumn>{ field: val.field, header: val.header || val.field });
  }

  /**
   * Create an dropdown format array with deduplicate items.
   * @param source source item
   * @param key if source is an object, key is required
   * @returns SelectItem[]
   */
  public static bindAsDropdown<S>(source: S[], key?: string): SelectItem<string>[] {
    return this.deDuplicate(<SelectItem<string>[]>CommonUtil.bindAsSelectItem(source, key));
  }

  /**
   * Deduplicate an array.
   * @param source any array
   * @param key source item key
   * @returns deduplicated item
   */
  public static deDuplicate<S>(source: S[], key?: string): S[] {
    return source?.filter((item, i, self) =>
      self.findIndex(val => (val?.[key] ?? val) === (item?.[key] ?? item)) === i);
  }

  /**
   * Filter items from another array's item.
   * @param source filter source
   * @param compare filter from
   * @param key object key
   * @returns filtered source item
   */
  public static filterSome<S, C>(source: S[], compare: C[], key?: string): S[] {
    return source?.filter(item => compare?.some(val => (val?.[key] ?? val) === (item?.[key] ?? item)));
  }

  /**
   * Easy way to transform object to encoded string.
   * @param source object that needs to bind on url
   * @returns base64 string
   */
  public static toParam<T>(source: T): string {
    return window.btoa(encodeURI(JSONUtil.stringify(source)));
  }

  /**
   * Use map reduce to collect sum of item values.
   * @param source array of items
   * @param key key witch needs to collect
   * @returns final answer
   */
  public static sum<T>(source: T[], key: number | string): number {
    return source.map(val => <number>val[key]).reduce((sum, value) => sum + value, 0);
  }
}

interface DataColumn {
  field: string;
  header: string;
}

class PrimeTableColumn {
  public field: string;
  public header?: string = '';
  public sort?: boolean = true;
  public exportable?: boolean = true;
  public toolTip?: string;
}
