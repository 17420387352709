import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tonumber' })
export class NumberPipe implements PipeTransform {
  public transform(value: unknown): number {
    try {
      return Number(value);
    } catch (error) {
      return value as number;
    }
  }
}
