export enum ThemeMenuColor {
  BLUEGREY = 'bluegrey',
  BROWN = 'brown',
  CYAN = 'cyan',
  DARK = 'dark',
  DEEPORANGE = 'deeporange',
  DEEPPURPLE = 'deeppurple',
  GREEN = 'green',
  INDIGO = 'indigo',
  LIGHT = 'light',
  PINK = 'pink',
  PURPLE = 'purple',
  TEAL = 'teal',
  // TEST = 'test',
}

export enum ThemeTopbarColor {
  AMBER = 'amber',
  BLUE = 'blue',
  BLUEGREY = 'bluegrey',
  BROWN = 'brown',
  CYAN = 'cyan',
  DARK = 'dark',
  DEEPORANGE = 'deeporange',
  DEEPPURPLE = 'deeppurple',
  GREEN = 'green',
  GREY = 'grey',
  INDIGO = 'indigo',
  LIGHTBLUE = 'lightblue',
  LIGHTGREEN = 'lightgreen',
  LIME = 'lime',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  TEAL = 'teal',
  WHITE = 'white',
  YELLOW = 'yellow',
  // TEST = 'test',
}

export enum ThemeComponentColor {
  AMBER = 'amber',
  BLUE = 'blue',
  BLUEGREY = 'bluegrey',
  BROWN = 'brown',
  CYAN = 'cyan',
  DEEPORANGE = 'deeporange',
  DEEPPURPLE = 'deeppurple',
  GREEN = 'green',
  INDIGO = 'indigo',
  LIGHTBLUE = 'lightblue',
  LIGHTGREEN = 'lightgreen',
  LIME = 'lime',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  TEAL = 'teal',
  YELLOW = 'yellow',
  // TEST = 'test',
}
