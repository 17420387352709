import { Component, Input } from '@angular/core';

@Component({
  selector: 'caloudi-color-box',
  templateUrl: './color-box.component.html',
  styleUrls: ['./color-box.component.sass'],
})
export class ColorBoxComponent {
  @Input('icon') public icon: string;
  @Input('name') public name: string;
  @Input('count') public count: number;
  @Input('colorboxNum') public colorboxNum: number;
  @Input('sizeNum') public sizeNum: number;

  constructor() {}
}
