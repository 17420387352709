import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tostring' })
export class StringPipe implements PipeTransform {
  public transform(value: unknown): string {
    try {
      return String(value);
    } catch (error) {
      return value as string;
    }
  }
}
