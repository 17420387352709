<p-table
  #dt
  class="prime_table"
  [autoLayout]="autoLayout"
  [columns]="columns"
  [currentPageReportTemplate]="currentPageReportTemplate"
  [dataKey]="dataKey"
  [expandedRowKeys]="expandedRowKeys"
  [exportFilename]="exportFilename"
  [filters]="filters"
  [globalFilterFields]="globalFilterFields"
  [groupRowsBy]="_groupKey || pivotKey"
  [loading]="!1 && (loading || manualLoading)"
  [pageLinks]="5"
  [paginatorDropdownScrollHeight]="'20rem'"
  [paginatorPosition]="paginatorPosition"
  [paginator]="_pivotKey > 0 ? false : paginator"
  [resizableColumns]="resizeable"
  [responsiveLayout]="'scroll'"
  [rowExpandMode]="rowExpandMode"
  [rowGroupMode]="groupMode"
  [rowsPerPageOptions]="pageItems"
  [rows]="_pivotKey <= 0 && !paginator ? pageSize : null"
  [scrollHeight]="scrollHeight"
  [scrollable]="false"
  [selectionMode]="selectionMode"
  [showCurrentPageReport]="true"
  [sortField]="_sortField"
  [sortOrder]="paginator ? sortOrder : null"
  [stateKey]="stateKey"
  [styleClass]="_pivotKey > 0 ? pivotStyleClass : styleClass"
  [value]="values"
  [(selection)]="selection"
  (onFilter)="onFilter($event)"
  (onLazyLoad)="onLazyLoad($event)"
  (onPage)="onPage($event)"
  (onRowCollapse)="onRowCollapse($event)"
  (onRowExpand)="onRowExpand($event)"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)"
  (onSort)="onSort($event)"
  (onStateRestore)="onStateRestore($event)"
  (onStateSave)="onStateSave($event)">
  <ng-template *ngIf="caption" pTemplate="caption" let-columns="columns">
    <ng-container *ngTemplateOutlet="captionTemplate; context: { columns }"></ng-container>
  </ng-template>

  <ng-template pTemplate="header">
    <tr *ngIf="loading">
      <th [attr.scope]="'column'"></th>
    </tr>

    <tr *ngIf="!loading && !emptyValueMessage" @columnAnimation>
      <ng-container *ngIf="rowExpansion">
        <th [attr.scope]="'column'"></th>
      </ng-container>

      <ng-container *ngFor="let column of columns; index as i">
        <ng-container *ngIf="!hiddenColumn(column)">
          <!-- <ng-container *ngIf="column.field !== 'actionGroup'"> -->

          <!-- column.sort will be true or undefined -->
          <ng-container *ngIf="column.sort !== false; else notSortable">
            <th
              *ngIf="column.header && column.display !== false"
              [pSortableColumn]="_pivotKey <= 0 && paginator ? column.field : null"
              [attr.scope]="'column'"
              [ngClass]="{ sticky_column: i === stickyColumn, pivot_style: _pivotKey > 0 }">
              <p
                [ngClass]="{ 'text-right': isNumber(column) && column.headerPosition !== 'left' }"
                [pTooltip]="column.toolTip"
                tooltipPosition="top">
                {{ column.header }}
                <fa-icon class="header_icon ml-1" *ngIf="column.toolTip" [icon]="fas.faCircleInfo"></fa-icon>
                <p-sortIcon class="header_icon" *ngIf="_pivotKey <= 0 && paginator" [field]="column.field"></p-sortIcon>
              </p>
            </th>
          </ng-container>

          <ng-template #notSortable>
            <th [attr.scope]="'column'" [ngClass]="{ sticky_column: i === stickyColumn }">
              <p [ngClass]="{ 'text-right': isNumber(column) }" [pTooltip]="column.toolTip" tooltipPosition="top">
                {{ column.header }}
                <fa-icon *ngIf="column.toolTip" [icon]="fas.faCircleInfo"></fa-icon>
              </p>
            </th>
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- </ng-container> -->
    </tr>
  </ng-template>

  <!-- <ng-template pTemplate="groupheader" let-rowData let-rowIndex="rowIndex">
    <tr @columnAnimation pRowGroupHeader>
      <td [attr.colspan]="columns.length">
        <span class="font-bolds ml-2">{{ columns?.[groupKey]?.header }}</span>
        <span>{{ rowData[groupKey] }}</span>
      </td>
    </tr>
  </ng-template> -->

  <!-- <ng-template pTemplate="groupfooter" let-rowData>
    <tr>
      <td [attr.colspan]="columns.length" class="text-right font-bold pr-6">
        {{ rowData[groupKey] }}
      </td>
    </tr>
  </ng-template> -->

  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
    let-expanded="expanded"
    [logSelected]="{ label: 'selected_row', payload: selectedRow, rowNumber: selectedRow?.index }">
    <tr @columnAnimation [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
      <td *ngIf="rowExpansion" class="row_exp" [logRowExpand]="getExpandLogItem(rowData, expanded)">
        <button
          pButton
          pRipple
          aria-label="Button"
          type="button"
          [pRowToggler]="rowData"
          (click)="bindSelection(expanded ? rowData : null)"
          [text]="true"
          [rounded]="true"
          [plain]="true"
          [faIcon]="expanded ? fas.faChevronDown : fas.faChevronRight"></button>
      </td>

      <ng-container *ngFor="let column of columns; index as i">
        <ng-container *ngIf="!hiddenColumn(column)">
          <td
            *ngIf="
              (!pivotKey?.[i] || (pivotKey?.[i] && rowData?._attributes?.rowSpan?.[i])) && column.display !== false
            "
            [attr.rowspan]="rowData?._attributes?.rowSpan?.[i]"
            [ngClass]="{
              sticky_column: i === stickyColumn,
              action_group: actionGroupFix(column),
              pivot_style: _pivotKey > 0,
            }">
            <ng-container *ngIf="!specificColumn(column.field)">
              <ng-container *ngIf="valueEmpty(rowData?.[column.field]); else nullTemplate">
                <ng-container
                  [ngTemplateOutlet]="columnTemplate"
                  [ngTemplateOutletContext]="{ column, rowData, rowIndex, expanded }">
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="specificColumn(column.field)"
              [ngTemplateOutlet]="customBodyTemplate"
              [ngTemplateOutletContext]="{ column, rowData, rowIndex }">
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <div @rowExpansionAnimation tb class="row_expanded">
          <ng-container *ngTemplateOutlet="rowExpansionTemplate; context: { columns, rowData, rowIndex }">
          </ng-container>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft" let-state>
    <p
      *ngIf="state.totalRecords > 10"
      [translate]="'DATA_TABLE.PAGINATOR'"
      [translateParams]="{
        first: state.page * state.rows + 1,
        last: state.rows * (state.page + 1) <= state.totalRecords ? state.rows * (state.page + 1) : state.totalRecords,
        totalRecords: state.totalRecords,
      }"></p>
    <p
      *ngIf="state.totalRecords <= 10 && state.totalRecords > 1"
      [translate]="'DATA_TABLE.LESS_THAN_10'"
      [translateParams]="{ totalRecords: state.totalRecords }"></p>
    <p *ngIf="state.totalRecords === 1">{{ 'DATA_TABLE.ONE_RECORD' | translate }}</p>
  </ng-template>

  <ng-template pTemplate="paginatorright">
    <section class="search_bar p-inputgroup">
      <fa-icon [icon]="fas.faMagnifyingGlass"></fa-icon>
      <input
        pInputText
        type="text"
        [(ngModel)]="filterInput"
        [placeholder]="'LABEL.FILTER' | translate"
        (input)="searchBoxInput($event)"
        [logInput]="searchBoxInputValue" />
      <button
        pButton
        aria-label="Button"
        [label]="'ACTION.EXPORT' | translate"
        [faIcon]="fas.faFileArrowDown"
        (click)="exportCSV()"
        [logButton]="logEventTableColumns"></button>
      <!-- <p-splitButton [label]="'ACTION.EXPORT' | translate"
                     [faIcon]="fas.faFileArrowDown"
                     [model]="exportItems"
                     [menuStyleClass]="'prime_table_export'"
                     (onClick)="exportCSV()">
      </p-splitButton> -->
    </section>
    <!-- <section>
      <p-columnFilter field="status" matchMode="equals">
        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
          <p-dropdown
            [ngModel]="value"
            [options]="globalFilterFields"
            [optionLabel]="'name'"
            optionValue="value"
            (onChange)="filter($event.value)"
            placeholder="Select a Status"
            [showClear]="true">
          </p-dropdown>
        </ng-template>
      </p-columnFilter>
    </section> -->
  </ng-template>
</p-table>

<section *ngIf="loading || manualLoading">
  <div class="data_loading">
    <p class="text-center">{{ 'DATA_TABLE.LOADING' | translate }}</p>
  </div>
</section>

<section *ngIf="!hasData && !(loading || manualLoading)">
  <div class="empty_data">
    <p class="text-center">{{ emptyValueMessage || 'PAGE.DATA_QUERY_EMPTY' | translate }}.</p>
  </div>
</section>

<ng-template pTemplate="emptymessage" let-columns>
  <tr>
    <td [attr.colspan]="columns.length">
      {{ 'PAGE.NO_RECORDS_FOUND' | translate }}
    </td>
  </tr>
</ng-template>

<ng-template #nullTemplate let-c="class">
  <div tb class="text-center text-muted" [ngClass]="c">{{ stringReplacement }}</div>
</ng-template>

<ng-template #customBodyTemplate let-column="column" let-rowData="rowData" let-rowIndex="rowIndex">
  <ng-container *ngIf="specificColumnfilter(column.field)">
    <ng-container *ngIf="valueEmpty(rowData?.[column.field]); else nullTemplate">
      <div tb class="time">
        <span>{{ rowData[column.field] | date: 'yyyy-MM-dd' }} </span>
        <span>{{ rowData[column.field] | date: 'HH:mm:ss' }}</span>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
