<section id="report-topbar" role="group" class="shadow-2">
  <div>
    <div>
      <ng-container *ngTemplateOutlet="goBackTemplate"> </ng-container>
      <ng-container *ngIf="!goBackTemplate">
        <ng-container *ngIf="hasLinkBack; else directBack">
          <button
            pButton
            aria-label="Button"
            type="button"
            [faIcon]="fas.faArrowLeft"
            class="arrow-btn"
            (click)="goBack()"
            [pTooltip]="'ACTION.BACK' | translate"
            tooltipPosition="top"
            [showDelay]="200"
            [logButton]="'Back'"></button>
        </ng-container>
        <ng-template #directBack>
          <button
            pButton
            aria-label="Button"
            type="button"
            [faIcon]="fas.faArrowLeft"
            class="arrow-btn"
            (click)="goBack()"
            [pTooltip]="'ACTION.BACK' | translate"
            tooltipPosition="top"
            [showDelay]="200"
            [logButton]="'Back'"></button>
        </ng-template>
      </ng-container>
      <button
        pButton
        aria-label="Button"
        type="button"
        [faIcon]="fas.faArrowLeft"
        class="arrow-btn"
        style="transform: rotate(90deg)"
        (click)="scrollTop()"
        [pTooltip]="'ACTION.TOP' | translate"
        tooltipPosition="top"
        [showDelay]="200"
        [logButton]="'Top'"></button>
    </div>

    <div id="topbar_section_anchors" *ngIf="sectionAnchors">
      <ng-container *ngFor="let section of sectionAnchors">
        <button
          pButton
          aria-label="Button"
          type="button"
          *ngIf="section.display"
          (click)="triggerScrollTo(section.link)"
          [label]="section.label"
          [logButton]="section.label"></button>
      </ng-container>
    </div>
  </div>

  <div>
    <div *ngIf="printingBtn">
      <button
        pButton
        aria-label="Button"
        type="button"
        *ngIf="printMode"
        icon=""
        (click)="toggleMode()"
        [label]="'ACTION.PRINT_MODE' | translate"
        [pTooltip]="'ACTION.PRINT_MODE' | translate"
        [tooltipPosition]="'bottom'"
        [showDelay]="200"
        [logButton]="'Print Mode'"></button>
      <button
        pButton
        aria-label="Button"
        type="button"
        *ngIf="!printMode"
        icon=""
        (click)="toggleMode()"
        [label]="'ACTION.INTERACTIVE_MODE' | translate"
        [pTooltip]="'ACTION.INTERACTIVE_MODE' | translate"
        [tooltipPosition]="'bottom'"
        [showDelay]="200"
        [logButton]="'Interactive Mode'"></button>
    </div>

    <div>
      <button
        pButton
        aria-label="Button"
        type="button"
        [faIcon]="fas.faEnvelope"
        (click)="showReportDeliveryDialog()"
        [pTooltip]="'ACTION.MAIL_SCHEDULE' | translate"
        tooltipPosition="top"
        [showDelay]="200"
        [logDialog]="{ label: 'Mail Schedule' }"></button>
      <button
        pButton
        aria-label="Button"
        type="button"
        *ngIf="printingBtn"
        [faIcon]="fas.faPrint"
        onclick="window.print()"
        [pTooltip]="'ACTION.PRINT' | translate"
        tooltipPosition="top"
        [showDelay]="200"
        [logButton]="'Print'"></button>
    </div>
  </div>
</section>

<caloudi-report-delivery
  #reportDelivery
  [reportUrl]="''"
  [reportName]="''"
  [reportTitle]="''"
  [reportNote]="''"
  [schedule]="''">
</caloudi-report-delivery>
