import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.ENG,
  data: {
    ANOMALY: {
      ADD_NOTIFICATION: 'Add Notification',
      ANOMALY: 'Anomaly',
      ANOMALY_DETECTION_MODEL: 'Anomaly Detection Model',
      ANOMALY_DETECTION: 'Anomaly Detection',
      ANOMALY_NOTIFICATION_SETTINGS: 'Anomaly Notification Settings',
      ANOMALY_POINTS_NOTICE: '*Anomaly points details displayed are based on the last 30 days.',
      ANOMALY_POINTS: 'Anomaly Points',
      AVAILABLE_CHARTS: 'Available Charts',
      COST_DEVIATION_GREATER_THAN: 'Cost deviation greater than',
      NOTIFICATION_LANGUAGE: 'Notification Language',
      NOTIFICATION_RECIPIENTS: 'Notification Recipients',
      PRIMARY_INSTANCE_CAUSES: 'Primary Instance Causes',
      PRIMARY_LABEL_CAUSES: 'Primary Label Causes',
      PRIMARY_PRODUCT_CAUSES: 'Primary Product Causes',
      PRIMARY_SERVICE_CAUSES: 'Primary Service Causes',
      PRIMARY_SKU_CAUSES: 'Primary SKU Causes',
    },
    ENTITY: {
      ACQUIRE: 'Acquire',
      APP_INSIGHT: 'App Insights',
      AUDIT_TYPE: 'Audit Type',
      BALANCE_SUMMARY: 'Balance Summary',
      CATALOG: 'Catalog',
      CATEGORY: 'Category',
      CHART: 'Chart',
      CONSUMED_SERVICE: 'Consumed Service',
      DEPARTMENT: 'Department',
      DEPENDENCY: 'Dependency',
      ENROLLMENT: 'Enrollment',
      DOMAIN: 'Domain',
      FLEXIBILITY_GROUP: 'Flexibility Group',
      INSTANCE: 'Instance',
      INSTANCE_NAME: 'Instance Name',
      INSTANCE_TYPE: 'Instance Type',
      INSTANCE_ID: 'Instant ID',
      OPERATION: 'Operation',
      OS: 'Operating System',
      PRODUCT: 'Product',
      PROJECT: 'Project',
      RESOURCE_GROUP: 'Resource Group',
      RESOURCE_LABELS: 'Resource Labels',
      RESOURCE_TAG_KEY: 'Tag Key',
      RESOURCE_TAG_VALUE: 'Tag Value',
      RESOURCE_TAG: 'Resource Tag',
      RESOURCE_TYPE: 'Resource Type',
      RESOURCE_VALUE: 'Resource Value',
      RESOURCE: 'Resource',
      SERVICE: 'Service',
      SERVICE_CATEGORY: 'Service Category',
      SETTING: 'Setting',
      SKU: 'SKU',
      SQL: 'SQL',
      SUBSCRIPTION: 'Subscription',
      SUBSCRIPTION_ID: 'Subscription ID',
      TENANT_DOMAIN: 'Tenant Domain',
      TENANT: 'Tenant',
      TENANT_GUID: 'Tenant Guid',
      VIRTUAL_MACHINE: 'Virtual Machine',
      VM_FLEXIBILITY_GROUP: 'VM Flexibility Group',
      VM_RECOMMENDATION: 'VM Recommendation',
      VM: 'VM',
    },
    BUDGET: {
      BUDGET_ALERT: '	Budget Alert',
      BUDGET_ID: 'Budget Id',
      BUDGET_NAME: 'Budget Name',
      BUDGET_PERCENT: 'Budget Percent',
      BUDGET_SUGGESTION: 'Budget Suggestion',
      BUDGET_YEAR: 'Budget Year',
      BUDGET: 'Budget',
      EXPIRATION_DATE: 'Budget Expiration Date',
      OVERALL_COST_ACCOUNT: 'Overall cost of the account',
      OVERALL_COST_DEPARTMENT: 'Overall cost of the department',
      OVERALL_COST_PRODUCT: 'Overall cost of the product and subscription',
      OVERALL_COST_REGION: 'Overall cost of the region and subscription',
      OVERALL_COST_RESOURCE_GROUP: 'Overall cost of the resource group and subscription',
      OVERALL_COST_SERVICE: 'Overall cost of the service and subscription',
      OVERALL_COST_SUBSCRIPTION: 'Overall cost of the subscription',
      OVERALL_COST_TAG: 'Overall cost of the tag and subscription',
      OVERALL_COST_THE_LABEL: 'Overall cost of the label from the selected billing account',
      OVERALL_COST_THE_PROJECT: 'Overall cost of the project from the selected billing account',
      OVERALL_COST_THE_REGION: 'Overall cost of the region from the selected billing account',
      OVERALL_COST_THE_SERVICE: 'Overall cost of the service from the selected billing account',
      OVERALL_COST_THE_SKU: 'Overall cost of the SKU from the selected billing account',
      OVERALL_COST: 'Overall Cost',
      THRESHOLD_1: '1st Threshold',
      THRESHOLD_2: '2nd Threshold',
      THRESHOLD_3: '3rd Threshold',
      TOTAL_BUDGET_OVERRAGE_ALERT: 'Total Budget Overage Alert',
      TOTAL_BUDGET: 'Total Budget',
    },
    CHART: {
      BAR_CHART: 'Bar Chart',
      LINE_CHART: 'Line Chart',
      LINECHART_INFO: 'Drag on Timeline Window to zoom in. Click on outside of zoomed area to reset.',
      OTHER_CHART: 'Other Chart',
      PIE_CHART_3D: '3D Pie Chart',
      PIE_CHART: 'Pie Chart',
      STACK_CHART: 'Stack Bar Chart',
      STACKED: 'Stacked',
      SWITCH_BAR: 'Switch Bar Chart Type',
      SWITCH_PIE: 'Switch Pie Chart Type',
    },
    COST: {
      ALL_METERED_COST: 'All Metered Cost',
      BALANCE: 'Balance',
      BEGINING_BALANCE: 'Beginning Balance',
      COST: 'Cost',
      COST_30D: '30-Day Cost',
      COST_30D_SUMMARY: 'Cost 30 Days Summary',
      COST_30D_TOTAL: '30 Days Total Cost',
      COST_DETAILS: 'Cost Details',
      COST_DEVIATION: 'Cost Deviation',
      COST_OF_CURRENT_MONTH: 'Cost of Current Month',
      COST_OF_LAST_MONTH: 'Cost of Last Month',
      COST_QUERY_YEAR: 'Cost Query Year',
      COST_SUMMARY: 'Cost Summary',
      COST_TYPE: 'Cost Type',
      CURRENT_USAGE: 'Current Usage',
      CUSTOMER_CHARGES: 'Customer Charges',
      DAILY_COST: 'Daily Cost',
      LAST_30_DAYES_DAILY_COST: 'Last 30 Days Daily Cost',
      LIST_PRICE_TOTAL: 'List Price Total',
      MARGIN: 'Margin',
      METERED_COST: 'Metered Cost',
      MONTHLY_COST: 'Monthly Cost',
      NEW_COST: 'New Cost',
      ORIGINAL_CHARGES: 'Original Charges',
      PARTNER_CHARGES: 'Partner Charges',
      PRETAX_SUBTOTAL: 'Pre-Tax Subotal',
      REMAINING_BALANCE: 'Remaining Balance',
      SUBTOTAL: 'Subtotal',
      TAX: 'Tax',
      TOTAL: 'Total',
      TOTAL_AMOUNT: 'Total Amount',
      TOTAL_CHARGES: 'Total Charges',
      TOTAL_COST: 'Total Cost',
      TOTAL_COVERAGE_RATE: 'Total Coverage Rate',
      TOTAL_QTY: 'Total QTY',
      TOTAL_REVENUE: 'Total Revenue',
      TOTAL_TAX: 'Total Tax',
      WEEKLY_COST: 'Weekly Cost',
    },
    COMMON: {
      A: 'a',
      BY: 'By',
      FROM: 'From',
      YES: 'Yes',
      NO: 'No',
      NOT: 'Not',
      OF: 'of',
      OR: 'or',
      TO: 'To',
    },
    CURRENCY: {
      CURRENCY: 'Currency',
      NTD: 'NTD',
      USD: 'USD',
      TWD: 'TWD',
    },
    LOG: {
      ACTIVITY_LOG: 'Activity Log',
      ACTIVITY_LOGS: 'Activity Logs',
      LOG_DATE: 'Log Date',
      LOG_PERIOD: 'Log Period',
      LOG_QUERY: 'Log Query',
      LOG_TYPE: 'Log Type',
    },
    INVOICE: {
      ACCOUNT_PROFILE: 'Account Profile',
      BILLING_DETAILS: 'Billing Details',
      BILLING_INFO: 'Billing Info',
      BILLING_PROFILE: 'Billing Profile',
      BILLING_SUMMARY: 'Billing Summary',
      BILL_TO: 'Bill To',
      COMPANY_NAME: 'Company Name',
      COMPANY_PROFILE: 'Company Profile',
      CUSTOMERS: 'Customers',
      CUSTOMER_PROFILE: 'Customer Profile',
      DATE: 'Invoice Date',
      DATE_END: 'Invoice Date End',
      DATE_START: 'Invoice Date Start',
      DATE_UTC: 'Invoice Date in UTC',
      DETAILS: 'Invoice Details',
      DOCUMENT_TYPE: 'Document Type',
      ID: 'Invoice ID',
      INFO: 'Invoice Info',
      INVOICE: 'Invoice',
      LICENSE: 'License',
      LICENSES: 'Licenses',
      LICENSE_LINEITEMS: 'License Line Items',
      LINEITEM: 'Line Item',
      LINEITEMS: 'Line Items',
      LINEITEM_DETAILS: 'Line Item Detail',
      LUMPSUM: 'Lump Sum',
      NET_N_DAYS: 'Net {{ N }} days',
      NUMBER: 'Invoice Number',
      OFFERS: 'Offers',
      ONETIME: 'One Time',
      ONETIME_LINEITEMS: 'One Time Line Items',
      ORDER_ID: 'Order ID',
      ORDER_INFO: 'Order Info',
      PAID_AMOUNT: 'Paid Amount',
      PAYMENT_STATUS: 'Payment Status',
      PAYMENT_TERMS: 'Payment Terms',
      PRODUCTS: 'Products',
      PRODUCT_CATS: 'Product Categories',
      PRODUCT_INFO: 'Product Info',
      PURCHASE_INFO: 'Purchase Info',
      QUANTITY_AMOUNT: 'Quantity And Amount',
      RECON: 'Reconciliation',
      RECURRING: 'Invoice Recurring',
      SKUS: 'SKUs',
      SOLD_TO: 'Sold To',
      SUMMARY: 'Invoice Summary',
      TOTAL_AMOUNT: 'Total Amount',
      TYPE: 'Invoice Type',
    },
    REPORT: {
      EMAIL_LIST: 'Email List',
      NOTE: 'Report Note',
      REPORT: 'Report',
      TITLE: 'Report Title',
      UNTITLED: 'Untitled',
    },
    RESELLER_QUERY: {
      INCLUDE_PRIVILEGES_DESCRIPTION: 'Include delegated administration privileges for Azure Active Directory and Office 365.',
      REQUEST_RELATIONSHIP_DESCRIPTION: 'To request a reseller relationship with a customer, copy and paste the following text, including the URL, into an email.',
      SEND_EMAIL_DESCRIPTION: 'Edit the text if necessary, and send the email to your customer.',
    },
    RI: {
      CURRENT_RI_COVERAGE_RATE: 'Current RI Coverage Rate',
      NEW_RI_COVERAGE_RATE: 'New RI Coverage Rate',
      NO_RI_COST: 'No RI Cost',
      RECOMMENDED_COST: 'Recommended Cost',
      RI_ANALYSIS_DETAIL: 'RI Analysis Detail',
      RI_COST_ALLOCATION: 'RI Cost Allocation',
      RI_COST_WITH_NO_RI: 'Cost With No RI',
      RI_COST_WITH_RI: 'Cost With RI',
      RI_COST: 'RI Cost',
      RI_COVERAGE_ANALYSIS: 'RI Coverage Analysis',
      RI_COVERAGE_ANALYSIS_NOTE: 'The SKU Recommendation is based on last 30 days ( started from {{ startDate }} ) usage.',
      RI_COVERAGE_ANALYSIS_WITH_ISF_NOTE: 'The SKU Recommendation is based on last 30 days ( started from {{ startDate }} ) usage and in the same flexibitility group.',
      RI_COVERAGE_RATE: 'RI Coverage Rate',
      RI_COVERAGE_AND_UTILIZATION_RATE: 'RI Coverage and Utilization Rate',
      RI_ONE_YEAR: 'RI One-Year',
      RI_ORDER: 'RI Order',
      RI_PRICE_SHEET: 'RI Price Sheet',
      RI_RECOMMEND_QUANTITY: 'Recommend Quantity',
      RI_RECOMMENDATION: 'RI Recommendation',
      RI_THREE_YEAR: 'RI Three-Year',
      RI_TRANSFER_ESTIMATION: 'RI Transfer Estimation',
      RI_UNUSED_COST: 'RI Unused Cost',
      RI_USAGE_DETAIL: 'RI Usage Detail',
      RI_USAGE_SUMMARY: 'RI Usage Summary',
      RI_UTILIZATION_RATE: 'RI Utilization Rate',
      RI1Y: 'RI 1Y',
      RI3Y: 'RI 3Y',
      SHOW_ACTIVE_RI_ORDER: 'Show Active RI Order',
      TRANSFER_ESTIMATE: {
        ADDITIONAL_PAYMENT: 'Additional Payment',
        EQUIVALENT_HOURS_TO_NEW_RI_ORDER: 'Equivalent Hours to New RI Order',
        NEW_RI_ORDER_COST: 'New RI Order Cost',
        NEW_RI_ORDER_RESERVATION_COST: {
          ESTIMATE_INFO: 'New RI Order Reservation Cost (Estimate)',
          PER_HOUR: 'Hour',
          PER_MONTH: 'Month',
          PER_YEAR: 'Year',
        },
        NO_RI_INFO: 'No RI Information',
        NOT_INCLUDE_FEE: 'RI Transfer Estimate does not include a 12% of the early termination fee.',
        REMAINING_BALANCE: 'Remaining Balance',
        REMAINING_HOURS: 'Remaining Hours',
      },
    },
    DATE: {
      SECONDS: 'Second(s)',
      MINUTE: 'Minute',
      MINUTES: 'Minute(s)',
      HOUR: 'Hour',
      HOURLY: 'Hourly',
      HOURS: 'Hour(s)',
      SUN: 'Sun',
      MON: 'Mon',
      TUE: 'Tue',
      WED: 'Wed',
      THU: 'Thu',
      FRI: 'Fri',
      SAT: 'Sat',
      SUNDAY: 'Sunday',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      TODAY: 'Today',
      DAY: 'Day',
      DAYS: 'Day(s)',
      DAILY: 'Daily',
      DAY_OF_THE_WEEK: 'Day of The Week',
      WEEK: 'Week',
      WEEKLY: 'Weekly',
      WEEKDAY: 'Weekday',
      WEEK_DAY: 'Week Day (Mon.-Fri.) at',
      MONTH: 'Month',
      MONTHLY: 'Monthly',
      LAST_MONTH: 'Last Month',
      CURRENT_MONTH: 'Current Month',
      PREVIOUS_MONTH: 'Previous Month',
      DATE: 'Date',
      DATES: 'Dates',
      LAST_7_DAYS: 'Last 7 Days',
      LAST_N_DAYS: 'Last {{ N }} Days',
      LAST: 'Last',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly',
    },
    ADVISOR_DASHBOARD: {
      ADD_TO_MY_ADVISORY_LIST: 'Add To My Advisory List',
      CHECK_ALL_ANOMALY_DETECTIONS: 'Check All Anomaly Detections',
      CHECK_ALL_RIRECOMMENDATIONS: 'Check All Recommendations',
      CHECK_ALL_RIGHT_SIZINGS: 'Check All Right Sizings',
      CHECK_USAGE_SUMMARY: 'Check Usage Summary',
      INSIGHTS_OVERVIEW: 'Insights Overview',
      LOW_RI_COVERAGE_RATE: 'Low RI Coverage Rate',
      LOW_RI_UTILIZATION_VM_CPU: 'Low RI Utilizaiton VM CPU',
      MAX_CPU_95TH_PERCENTILE: 'Max CPU(%) 95th Percentile',
      MAX_MEMORY_95TH_PERCENTILE: 'Max Memory(%) 95th Percentile',
      MAX_NETWORK_95TH_PERCENTILE: 'Max Network(MB) 95th Percentile',
      MY_ADVISORY_LIST: 'My Advisory List',
      NO_RI_INFO: 'You are following all of our RI recommendations',
      NO_RIGHT_SIZING_INFO: 'You are following all of our right sizings',
      NO_ABNORMAL_INFO: 'No Anomaly Points',
      RI_ESTIMATED_BY_RECOMMENDED_SKU: 'Estimated By Recommended SKU',
      RI_ESTIMATED_BY_FLEXIBILITY_GROUP: 'Estimated By Flexibility Group Normalized to {{ skuName }}',
      RI_RECOMMEND_QUANITITY_NOTE: 'Recommend Quantity is estimated by Recommend SKU',
    },
    ADVISORY: {
      AVAILABILITY_ADVISORY: 'Availability Advisory',
      CATEGORY: 'Category',
      HIGH_IMPACT: 'High Impact',
      IMPACT: 'Impact',
      IMPACTED_RESOURCES: 'Impacted Resources',
      LOW_IMPACT: 'Low Impact',
      MEDIUM_IMPACT: 'Medium Impact',
      OPERATION_ADVISORY: 'Operation Advisory',
      PERFORMANCE_ADVISORY: 'Performance Advisory',
      RECOMMENDATIONS: 'Recommendations',
      SECURITY_ADVISORY: 'Security Advisory',
      STATE: 'State',
      TOTAL_RECOMMENDATIONS: 'Total Recommendations',
    },
    SEARCH: {
      ALERT_RECIPIENTS: 'Enter Alert Recipients EMail',
      CHARACTER_TWO: 'Enter at least two characters',
      COMPANY: 'Enter Company',
      DOMAIN: 'Enter Domain',
      KEYWORDS: 'Enter Keywords',
      OPERATION_NAME: 'Enter Operation',
      PART_NUMBER: 'Enter Part Number',
      PRODUCT: 'Enter Product Name',
      PRODUCT_PART_NUMBER: 'Enter Product or Part Number',
      RESOURCE: 'Enter Instance Keywords',
      RESOURCE_FULL: 'Enter Full Instance ID',
      RESOURCE_GROUP: 'Enter Resource Group',
      RESOURCE_TAG: 'Enter Resource Tag "Key=Value"',
      RESOURCE_TAG_VALUE: 'Enter Resource Tag Value',
      RESULT_CODE: 'Enter Result Code',
      USER_ID: 'Enter UserId',
    },
    LOGIN: {
      LOGIN_IN: 'Sign In',
      LOGIN_FAILED: 'Login Failed',
      PASSWORD: 'Password',
      NEW_PASSWORD: 'New Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      MFA_SIGN_IN: 'MFA Sign In',
      SSO: 'Single Sign On with Azure AD',
      SIGNIN: 'Sign in with {{ authority }}',
      TITLE: 'Sign In',
      USERID: 'User ID (Email)',
      VALIDATOR: {
        CLASSES:
          'Password must contain at least {{ length }} combinations of lowercase letters, uppercase letters, numbers, special characters',
        LENGTH: 'Password must be at least {{ length }} characters long',
        MATCH_EXIST_PASSWORD: 'Password can not be the same as existing password',
        PASSWD_MISMATCH: 'Passwords do not match',
        USERID: 'Password can not be the same as user ID',
      },
      MESSAGE: {
        PASSWD_REQUIRED: 'Password is Required',
        ID_REQUIRED: 'UserId/Email is Required',
        EMAIL_FORMAT: 'Email Format Invalid',
      },
      MFA: {
        STEP_1: 'Download Authenticator Apps.',
        STEP_2: 'Scan QR code below.',
        STEP_3: 'Enter generated MFA token.',
        APPS: '[ Microsoft Authenticator, Google Authenticator, Duo, Authy, etc ]',
        ENTER: 'Enter 6-digit MFA token provided in your authenticator app.',
        SETUP_KEY: 'Manual Setup Key',
        REVEAL_KEY: 'Reveal manual setup key here.',
      },
    },
    THEME: {
      COLORMODE: {
        DARK: 'Dark',
        LIGHT: 'Light',
      },
      MODE: {
        STATIC: 'Static',
        OVERLAY: 'Overlay',
        SLIM: 'Slim',
        HORIZONTAL: 'Horizontal',
      },
      SIZE: {
        COMPACT: 'Compact',
        MATERIAL: 'Material',
      },
      LABEL: {
        MENU: 'Menu',
        SIZE: 'Size',
        THEMES: 'Themes',
        MENU_MODES: 'Menu Modes',
        MENU_COLORS: 'Menu Colors',
        MENU_SIZES: 'Sizes',
      },
    },
    PAGE: {
      EMPTY: 'Empty',
      NONE: 'None',
      NO_RECORDS_FOUND: 'No Records Found',
      DATA_QUERY_EMPTY: 'No Data Available',
      DATA_QUERY: 'Data Query',
      SHOW_DATA_FOR_LAST: 'Show data for last',
      SIGN_OUT: 'Logout',
    },
    SECURITY: {
      ACCESS_PERMISSIONS: 'Access Permissions',
      ACCOUNT_IS_LOCKED: 'Account is locked',
      ACCOUNT_LOCKOUT_DURATION: 'Account Lockout Duration',
      ACCOUNT_LOCKOUT_POLICY: 'Account Lockout Policy',
      ACCOUNT_LOCKOUT_THRESHOLD: 'Account Lockout Threshold',
      ENFORCE_MFA: 'Enforce MFA',
      ENFORCE_SINGLE_SIGN_ON: 'Enforce Single Sign-On (SSO)',
      LOGIN_FAIL_RETENTION_DURATION: 'Login Fail Retention Duration',
      MFA_RESET_NOTE: 'Are you sure you want to reset user MFA?',
      MIN_PASSWORD_LENGTH: 'Min Password Length',
      NO_ACCESS_PERMISSION_TO_TEXT: "Your user account doesn't have permission to access",
      NO_PERMISSION: 'No Permission',
      PASSWORD_CAN_BE_MATCH_USER_ID: 'Password Can Match User ID',
      PASSWORD_HAS_BEEN_RESET: 'Password Reseted',
      PASSWORD_NOTE: 'Copy the User Id and temporary password to give to the user. After you leave this page, you can\'t view this temporary password again.',
      PASSWORD_POLICY: 'Password Policy',
      PASSWORD_RESET_NOTE: 'A new password will be generated and the user\'s current password will no longer work.',
      PWD_CHANGE_AND_LOGIN: 'Change Password and Sign In',
      SECURITY_POLICY_SETTINGS: 'Security Policy Settings',
      UNLOCK_ACCOUNT_NOTE_SUCCESSFULLY: 'Unlock Account 「{{ userAccount }}」 Successfully',
      UNLOCK_ACCOUNT_NOTE: 'Are you sure you want to unlock account 「{{ userAccount }}」?',
      UNLOCK_ACCOUNT: 'Unlock Account',
      USAGE_ACCESS_CONTROL_ASSIGNMENTS: 'Usage Access Control Assignments',
      USAGE_ACCESS_CONTROL_LIST: 'Usage Access Control List',
      POLICY_INFO: {
        ACCOUNT_LOCKOUT_DURATION: 'Amount of time account is locked out (minutes)',
        ACCOUNT_LOCKOUT_THRESHOLD: 'Number of invalid login attempts',
        LOGIN_FAIL_RETENTION_DURATION: 'Account will be locked out if failed attempts exceed threshold during specified duration',
        MATCH_EXIST_PASSWORD: 'Password can not be same as exist password.',
        MFA_INFO: 'Enforce Multi-factor authentication',
        MIN_PASSWORD_LENGTH: 'At least {{ Length }} characters',
        NUMBER_OF_CHARACTER_CLASSES_MAX: 'Up to 4 categories',
        NUMBER_OF_CHARACTER_CLASSES_MIN: 'At least 2 categories',
        NUMBER_OF_CHARACTER_CLASSES: 'Password complexity must contain at least {{ Length }} of the 4 categories, for example [A-Z]、[a-z]、[0-9]、[@#!?]',
        PASSWORD_CAN_BE_MATCH_USER_ID: 'Password Can {{ Not }} Match User ID (Case-insensitive)',
        ORGANIZATION_POLICY_INFO: 'Organization security policy has been set. Enrollment security policy can not be set',
        OVERWRITE_ENROLLMENT_POLICY_NOTE: 'Overwrite Enrollment Security Policy Settings',
      },
    },
    POWERBI: {
      BALANCE_SUMMARY: 'Balance Summary',
      COST_BREAKDOWN: 'Cost Breakdown',
      COST_FORECAST: 'Cost Forecast',
      DAILY_USAGE_DETAIL: 'Daily Usage Detail',
      DAILY_USAGE_ANALYSIS: 'Daily Usage Analysis',
      MONTHLY_USAGE_DETAIL: 'Monthly Usage Detail',
      MONTHLY_USAGE_ANALYSIS: 'Monthly Usage Analysis',
      METERED_BILLING_ANALYSIS: 'Metered Billing Analysis',
      ONBOARD_ANALYSIS: 'Onboard Analysis',
      ONBOARD_CONTACT: 'Onboard Contact',
      ONBOARD_DETAIL: 'Onboard Detail',
      OVERALL_COST_ANALYSIS: 'Overall Cost Analysis',
      OVERALL_DATA_INFO: 'Data are based on last 30 days.',
      PRODUCT_COST_ANALYSIS: 'Product Cost Analysis',
      RESOURCE_COST_ANALYSIS: 'Resource Cost Analysis',
      RI_OREDER: 'RI Order',
      RI_OVERALL: 'RI Overall',
      RI_USAGE_ANALYSIS: 'RI Usage Analysis',
      SERVICE_COST_ANALYSIS: 'Service Cost Analysis',
      SAAS_METERING: 'SaaS Metering',
      SAAS_METERING_ANALYSIS: 'SaaS Metering Analysis',
      SAAS_SUBSCRIPTION_ANALYSIS: 'SaaS Subscription Analysis',
      SAAS_USAGE: 'SaaS Usage',
      SAAS_USAGE_DETAIL: 'SaaS Usage Detail',
    },
    DATA_TABLE: {
      NO_DATA: 'No Data Available',
      LOADING: 'Loading',
      LESS_THAN_10: 'Total {{ totalRecords }} Records',
      ONE_RECORD: 'Total 1 Record',
      PAGINATOR: 'Show {{ first }} to {{ last }} of {{ totalRecords }}',
    },
    USAGE_BUDGET_INFO: {
      BEYOND_DATE_ALERT: 'Warn when there is still billing after the specified date',
      EXPIRATION_DATE: 'Alerts will no longer be send after this date',
      MONTHLY_BUDGET_OVERAGE_ALERT: 'Alert when the billing amount of the month exceeds the budget of the month',
      THRESHOLD_1: 'The first warning is issued when the budget exceeds a specified percentage',
      THRESHOLD_2: 'The second warning is issued when the budget exceeds the specified percentage',
      THRESHOLD_3: 'The third warning is issued when the budget exceeds the specified percentage',
      TOTAL_BUDGET_OVERAGE_ALERT: 'Alert when billing amount exceeds total budget',
    },
    CUSTOMER_PROFILE: {
      ADDRESS_LINE_1: 'Address Line 1',
      ADDRESS_LINE_2: 'Address Line 2',
      AGREEMENT_DATE: 'Agreement Date',
      ALLOW_CUSTOMER_TO_PURCHASE: 'Allow customer to purchase',
      BILL_TO_INFO: 'Bill-to Info',
      COMPANY_INFO: 'Company Info',
      COUNTRY_TOOLTIP: 'You can only sell to customers in your market. To sell in multiple markets, your organization must create multiple accounts with Microsoft.',
      CREDIT_AMOUNT: 'Credit Amount',
      CUSTOMER_ACCEPTANCE_CONFIRMED_BY_PARTNER: 'Customer Acceptance Confirmed by Partner',
      CUSTOMER_ACCEPTANCE_THROUGH_MICROSOFT: 'Customer Acceptance Through Microsoft',
      CUSTOMER_ACCOUNT_INFO: 'Customer Account Info',
      CUSTOMER_AGREEMENT_INFO: 'Customer Agreement Info',
      CUSTOMER_EMAIL: 'Customer Email',
      CUSTOMER_PERMISSIONS: 'Customer Permissions',
      EMAIL_TOOLTIP: "Customer's email domain will be used for OneVet EDU eligibility tracking.",
      LINK_CORRECTING_EDUCATION_INFO: 'Learn More about Correcting Education Information',
      LINK_CUSTOMER_VALIDATION_PROCESS: 'Learn more about Education customer validation process',
      LINK_HOW_CONFIRM_AGREEMENT: 'How to confirm your customer has accepted the Microsoft Customer Agreement',
      LINK_HOW_DISPUTE_DECISION: 'How do I dispute this decision',
      MANAGEMENT_INFO: 'Management Info',
      NOT_PROVIDED: 'Not Provided',
      POSTAL_CODE: 'Postal Code',
      PROVIDED: 'Provided',
      RESERVATIONS: 'Reservations',
      SALES_AGENT: 'Sales Agent',
      SPECIAL_QUALIFICATIONS: 'Special Qualifications',
      UPDATE_QUALIFICATION: 'Update Qualification',
      UPDATE_AGREEMENT_INFO: 'Update Agreement Info',
      COPY_AGREEMENT_URL: 'Copy Agreement URL',
      HINT_CANCEL_CONFIRM_HEADER: 'Are you sure you want to cancel?',
      HINT_CANCEL_CONFIRM_MESSAGE: 'The new customer has not been created.',
    },
    USER_PROFILE: {
      HAS_BEEN_UPDATED: 'User Profile Updated',
      LANGUAGE: 'User Language',
      LAST_LOGIN_INFO: 'Last Login Info',
      SUMMARY: 'User Profile Update',
      TIME_ZONE: 'User Time Zone',
      TITLE: 'User Profile',
      USER_ID: 'User ID',
      USER_NAME: 'User Name',
      PASSWD: {
        CHANGE_PASSWD: 'Change Password',
        CONFIRM_NEW_PASSWD: 'Confirm new password',
        CONFIRM_PASSWD: 'Confirm Password',
        CONFIRM_REQUIRED: 'Confirm password is required',
        CURRENT_PASSWD: 'Current Password',
        CURRENT_REQUIRED: 'Current password is required',
        ENTER_CURRENT_PASSWD: 'Enter your current password',
        NEW_PASSWD: 'New password',
        NEW_REQUIRED: 'New password is required',
        NEW_SAME_AS_CURRENT: 'New password is same as current password',
        PASSWD_MISMATCH: 'Passwords do not match',
      },
    },
    USAGE_COMMON_FILTER: {
      ACCESS_CONTROL_RESTRICTIONS: 'Usage Query Access Control Restrictions',
      HIERARCHY: 'Usage Filter Hierarchy',
      IMAGE_LINK: 'assets/images/usage_filter/usage_filter_hierarchy_en-us.png',
      IMAGE_LINK_HACKER: 'assets/images/usage_filter/usage_filter_hierarchy_hacker_en-us.png',
      QUERY_PRODUCT: 'Product query result is restrcted by your selected departments, accounts, subscriptions and services',
      QUERY_RESOURCE_GROUP: 'Resource group query result is restrcted by your selected departments, accounts and subscriptions',
      QUERY_TAG: 'Tag query result is restrcted by your selected departments, accounts, subscriptions and resource groups',
      RESTRICTIONS_HEADER: 'Your Admin has configured the following restrictions for you.',
      UNRESTRICTED: 'Unrestricted',
    },
    EA_OB_PAGE: {
      AUTHORIZATION_CONFIG: 'Authorization Config',
      CLOUD_TENANT_ADMIN_AUTHORIZATION: 'Cloud Tenant Admin Authorization',
      EA_ENROLLMENT_NUM: 'EA Enrollment Number',
      EA_NAME: 'EA Name',
      INIT_SYNC_TIME: 'Init Sync Time',
      LAST_RM_SYNC_TIME: 'Last RM Sync Time',
      NOTICE: 'You can leave this page and come back to check the data sync status anytime.',
      OB_INVITATION: 'Onboard Invitation',
      OB_PROGRESS: 'Onboard Progress',
      OVERALL_OB_PROGRESS: 'Overall Onboard Progress',
      PRICE_SHEET_IMPORT_PROGRESS: 'Price Sheet Import Progress',
      USAGE_IMPORT_PROGRESS: 'Usage Import Progress',
      VALID_SUBSCRIPTION: 'Valid Subscription',
    },
    ERRROR_HANDLER: {
      HTTP_ERROR_SUMMARY: 'Data Request',
    },
    FORECAST: {
      FORECAST_MODEL: 'Forecast Model',
      FORECAST: 'Forecast',
      ACTUAL_COST: 'Actual',
      DIFF_COST: 'Difference',
      FORECAST_COST: 'Forecast',
      PREDICT_COST: 'Predicted',
    },
    FEATURE_PACK: {
      ASSIGN: 'Assign Feature Pack',
      DETAIL: 'Feature Pack Detail',
      EDIT: 'Edit Feature Pack',
      LIST: 'Feature Pack List',
      NEW_FEATURE_PACK_NAME: 'New Feature Pack Name',
      SELECTED_LIST: 'Selected List',
      ENROLLMENT_FEATURE: {
        LIST: 'Enrollment Feature Pack List',
        DETAIL: 'Enrollment Feature Pack Detail',
        ACCESS: 'Enrollment Feature Pack Assignments',
        ASSIGNED_LIST: 'Assigned Feature Pack List',
      },
    },
    USER_GROUP: {
      NO_ASSIGNED_CUSTOMERS: 'No Assigned Customers',
      NO_ASSIGNED_RESELLERS: 'No Assigned Resellers',
      NO_CUSTOMERS: 'No Customers',
      NO_RESELLERS: 'No Resellers',
      NO_USERS: 'No Users',
      NO_USER_GROUPS: 'No User Groups',
    },
    PRICING_CONFIG: {
      BASELINE_MARKUP_RULE: 'Baseline Markup Rule',
      BASELINE_MARKUP_RULE_NOTE: 'applied when no discount or SKU rules matched',
      BY_DISCOUNT_PERCENT_RULES: 'By Discount Percent Rules',
      BY_SKU_RULES: 'By SKU Rules',
      CATEGORY: 'Category',
      CUSTOMER_DISCOUNT_PERCENT: '% of List Pirce for Customer',
      CUSTOMER_LIST: 'Customer List',
      DISTRIBUTOR_DISCOUNT_PERCENT: '% of List Price for Distributor',
      MARKUP: 'Markup',
      MARKUP_OF_COST_PRICE: 'Markup of Cost Price',
      NO_DISCOUNT_PERCENT_RULES: 'No Discount Percent Rules',
      NO_SKU_RULES: 'No SKU Rules',
      PARTNER_DISCOUNT_PERCENT: '% of List Price for Partner',
      PARTNER_EARNED_CREDIT_SHARING_PERCENTAGE_FOR_RESELLER: 'Partner Earned Credit Sharing Percentage for Reseller',
      PEC_OF_LIST_PRICE: '% of List Price',
      PEC_PECENT_FOR_RESELLER: 'PEC% for Reseller',
      PERCENTAGE_OF_COST_PRICE: 'Percentage of Cost Price',
      PERCENTAGE_OF_LIST_PRICE: 'Percentage of List Price',
      PRICING_CONFIG_NAME: 'Pricing Config Name',
      RESELLER_DISCOUNT_PERCENT: '% of List Price for Reseller',
      RESELLER_LIST: 'Reseller List',
      SELECT_A_DISCOUNT_PERCENT: 'Select a Discount Percent',
    },
    SALES_ORDERS: {
      CREATE_INVOICE: 'Create Invoice',
      CUSTOMER_VIEW: 'Customer View',
      EDIT_LINE_ITEM: 'Edit Line Item',
      ON_DEMAND_INVOICE: 'On Demand Invoice',
      INVOICE_CREATED: 'Invoice has been created.',
      LINE_ITEM: {
        NEW: 'New',
        ORDERED: 'Ordered',
        ORDER_FAILED: 'Order Failed',
        VOID: 'Void',
      },
      ORDER: {
        NEW: 'New',
        APPROVED: 'Approved',
        PARTIAL_ORDERED: 'Partial Ordered',
        VOID: 'Void',
      },
      ORDER_LINE_ITEMS: 'Order Line Items',
      PARTNER_VIEW: 'Partner View',
      PLACE_ORDER: 'Place Order',
      PLACING_ORDER: 'Placing Order',
      RESELLER_VIEW: 'Reseller View',
      SALES_ORDER_DETAIL: 'Sales Order Detail',
      VIEW_INVOICE: 'View Invoice',
    },
    SAAS_SUBSCRIPTION: {
      AUTO_RENEW: 'Auto Renew',
      BENEFICIARY_EMAIL: 'Beneficiary Email',
      CHANGE_QTY: 'Change Quantity',
      IS_FREE_TRIAL: 'Is Free Trial',
      IS_TEST: 'Is Test',
      ONBOARDING: 'Onboarding',
      ONBOARD_PROCESS: 'Onboard Process',
      PURCHASE_EMAIL: 'Purchase Email',
      SELECT_A_PLAN: 'Select a Plan',
      SUBSCRIPTION_STATUS: 'Subscription Status',
    },
    CONTACT: {
      ADMIN: 'Admin',
      BILLING: 'Billing',
      TECHNICAL: 'Technical',
    },
    PREREQUISITES: {
      ADMIN_USERS_ASSIGNED: 'Admin Users Assigned',
      BILLING_PROFILE_PROVIDED: 'Billing Profile Provided',
      COMPANY_PROFILE_PROVIDED: 'Company Profile Provided',
      INDIRECT_RESELLER_ACTIVE: 'Indirect Reseller Active',
      MANAGEMENT_GROUP_ASSIGNED: 'Management Group Assigned',
      MICROSOFT_PARTNER_AGREEMENT_SIGNED: 'Microsoft Partner Agreement Signed',
      PRICING_CONFIG_ASSIGNED: 'Pricing Config Assigned',
      RESELLER_AGENT_ASSIGNED: 'Reseller Agent Assigned',
      TAX_INFO_PROVIDED: 'Tax Info Provided',
    },
    RI_RECOMM_REPORT: {
      FILTERED_BY_FLEXIBILITY: 'Filtered By Flexibility',
      ONE_Y: '1Y',
      ONE_YEAR_RI: '1 Year RI',
      ONE_YEAR_RI_RECOMM: '1 Year RI Recomm',
      ONE_YEAR_RI_YEARLY_SAVING: '1 Year RI Yearly Savings',
      ONE_Y_RI_RECOMM: '1Y RI Recomm',
      RI_RECOMMENDATION_ANALYSIS: 'RI Recommendation Analysis',
      SKU_REGION_PRICE: 'SKU Region Price',
      SKU_REGION_PRICE_COMPARISON: 'SKU Region Price Comparison',
      THREE_Y: '3Y',
      THREE_YEAR_RI: '3 Year RI',
      THREE_YEAR_RI_RECOMM: '3 Year RI Recomm',
      THREE_YEAR_RI_YEARLY_SAVING: '3 Year RI Yearly Savings',
      THREE_Y_RI_RECOMM: '3Y RI Recomm',
      TOTAL_CURRENT_COST: 'Total Current Cost',
      TOTAL_NEW_COST: 'Total New Cost',
      TOTAL_RI_PRICE: 'Total RI Price',
      TOTAL_SAVING_YEARLY: 'Total Saving Yearly',
      YEARLY_SAVINGS_RANGE: 'Yealy Savings Range',
    },
    CUSTOM_DIMENSION: {
      ADD_DIMENSION_VALUE_MAPPING_RULE: 'Add Dimension Value Mapping Rule',
      ADD_TOOLTIP: 'Add a single dimension value mapping rule',
      BUILD_DIMENSION_HINT: 'Build will generate dimension data in the backend and it may take minutes to hours to complete. Please make sure you have completed the design of dimensions.',
      CHANGE_DIMENSION_NAME: 'Change Dimension Name (applicable to all with same dimension)',
      CHANGE_DIMENSION_NAME_WARNING: 'You are not allowed to change to name that has been used.',
      COST_CATEGORY_KEY_ALL: 'Map all Cost Category Values to one Display Value',
      COST_CATEGORY_KEY_ANY: 'Copy Cost Category Value to Display Value',
      COST_CATEGORY_VALUE_DISABLE_WARNING: 'Cost category key with more than 500 values are not supported due to performance consideration.',
      COST_CATEGORY_VALUE_MAX_WARNING: 'The cost category key {{ tagKey }} has {{ tagValueCount }} values.',
      DUPLICATED_DIMENSION_NAME_WARNING: 'You are not allowed to add a name that has been used.',
      EDIT_DIMENSION_AND_DISPLAY_VALUE: 'Edit Dimension & Display Value',
      IMPORT_DIMENSION_VALUE_MAPPING_RULE: 'Import Dimension Value Mapping Rule',
      IMPORT_TOOLTIP: 'Import multiple dimension value mapping rules',
      LABEL_KEY_ALL: 'Map all Label Values to one Display Value',
      LABEL_KEY_ANY: 'Copy Label Value to Display Value',
      LABEL_VALUE_DISABLE_WARNING: 'Label key with more than 500 values are not supported due to performance consideration.',
      LABEL_VALUE_MAX_WARNING: 'The label key {{ tagKey }} has {{ tagValueCount }} values.',
      MAX_DIMENSIONS_HINT: 'You are allowed to have up to (5) dimensions.',
      TAG_KEY_ALL: 'Map all Tag Values to one Display Value',
      TAG_KEY_ANY: 'Copy Tag Value to Display Value',
      TAG_VALUE_DISABLE_WARNING: 'Tag key with more than 500 values are not supported due to performance consideration.',
      TAG_VALUE_MAX_WARNING: 'The tag key {{ tagKey }} has {{ tagValueCount }} values.',
    },
    COST_DASHBOARD: {
      CHART_HINT: '1. Weeks start with Mondays. 2. The last week in the data is the current week.',
      CLOUD_COST_OVER_MONTHS: 'Cloud Cost Over Months',
      CLOUD_COST_OVER_WEEKS: 'Cloud Cost Over Weeks',
      COST_ANALYSIS_BY_SERVICE_CATEGORY: 'Cost Analysis by Service Category',
      CURRENT_MONTH: 'Current Month',
      OF_CURRENT_MONTH: 'of Current Month',
      SWITCH_LINE_CHART: 'Swtich Line Chart',
      SWITCH_STACKED_BAR_CHART: 'Swtich Stacked Bar Chart',
      TOP_10_REGION: 'Top 10 Region',
    },
    PROVISION: {
      APPROVAL_STATUS: 'Approval Status',
      CONFIGRATION_DETAIL: 'Configration Detail',
      COST_DETAILS: 'Cost Details',
      COST_RANGE: 'Cost Range',
      DESTROY_STATUS: 'Destroy Status',
      DURATION_TIME: 'Duration Time',
      ESIMATED_COST_MONTH: 'Esimated Cost Month',
      PLAN_STATUS: 'Plan Status',
      PROVISION_ITEM: 'Provision Item',
      PROVISION_RESULT: 'Provision Result',
      PROVISION_STATUS: 'Provision Status',
      VIEW_DETAILS: 'View Details',
    },
    VM_PROVISION: {
      ACCOUNT_DETAIL: 'Account Detail',
      ACCOUNT_DETAIL_INFO: 'Select the account to manage deployed resources and costs.',
      ADMINISTRATION_ACCOUNT: 'Administration Account',
      AUTHENTICATION_TYPE: 'Authentication Type',
      BASIC: 'Basic',
      CREATE_NEW: 'Create New',
      CUSTOM_DURATION: 'Custom Duration',
      DATE_RANGE_ERROR: 'Selected date-time is expired.',
      DELETE_WITH_VM: 'Delete with VM',
      DISK_OPTIONS: 'Disk Options',
      DUPLICATE_NAME: 'This name has already existed.',
      DURATION_INFO: 'The Default setting time will start at the time. It will continue to operate and does not have a default termination.',
      ESTIMATED_COST: 'Estimated Cost',
      EXISTING: 'Existing',
      FROM_DATE_TIME: 'From Date Time',
      GCP_VIRTUAL_MACHINE_FORMAT_INFO: 'Name must consist of lowercase letters (a-z), numbers, and hyphens(-).',
      IMAGE: 'Image',
      INSTANCE_DETAILS: 'Instance details',
      LINUX_VM_INFO: "Linux VM names may only contain letters, numbers, '.', and '-'.",
      MANAGED_DISKS: 'Managed Disks',
      NETWORK_SERVICE: 'Network Service',
      NO_LIMIT: 'No Limit',
      OS_DISK_DELETE_ON_TERMINATION: 'OS Disk Delete On Termination',
      OS_DISK_SIZE: 'OS Disk Size',
      OS_DISK_TYPE: 'OS Disk Type',
      OS_DISK_VOLUME_SIZE_RANGE_INFO: 'Size Range {{ minSize }} ~ {{ maxSize }} GiB.',
      OS_IMAGE: 'Image',
      PASSWORD_CONFIRM_INFO: 'Password and confirm password must match.',
      PASSWORD_FORMAT_INFO: 'Password must have 3 of the following: 1 lower case character, 1 upper case character, 1 number, and 1 special character.',
      PROJECT_DETAILS: 'Project details',
      PROJECT_INFO: 'Select the subscription to manage deployed resources costs. Use resource groups like folders to organize and manage all your resource.',
      PROVISION_DURATION: 'Provision Duration',
      PUBLIC_IP: 'Public IP',
      REGION_ZONE: 'Region Zone',
      RESOURCE_GROUP_FORMAT_INFO: 'Resource group names only allow alphanumeric characters, periods, underscores, hyphens and parenthesis and cannot end in a period.',
      RESOURCE_GROUP_NAME: 'Resource Group Name',
      SSH_PUBLIC_KEY: 'SSH Public Key',
      SSH_REGEXP_INFO: "Multiline SSH key must begin with '---- BEGIN SSH2 PUBLIC KEY ----' and end with '---- END SSH2 PUBLIC KEY----'",
      STANDARD: 'Standard',
      SUBSCRIPTION_NO_SKU_INFO: 'This size is currently unavailable in {{ region }} for this subscription: Not Available For Subscription.',
      TO_DATE_TIME: 'To Date Time',
      USER_NAME_FORMAT_INFO: 'Username must only contain letters, numbers, hyphens, and underscores and may not start with a hyphen or number.',
      VALIDATOR_LENGTH_INFO: 'The value must be between {{ minLength }} and {{ maxLength }} characters long.',
      VIRTUAL_MACHINE_FORMAT_INFO: "Azure resource names cannot contain special characters \\/\"\"[]:|<>+=;,?*@&, whitespace, or begin with '_' or end with '.' or '-'",
      VIRTUAL_MACHINE_INSTANCE: 'Virtual Machine Instance',
      VIRTUAL_MACHINE_NAME: 'Virtual Machine Name',
      WINDOWS_VM_INFO: "Windows VM names may not contain '_' or '.'.",
    },
    USER_ACCESS: {
      ROLE_ASSIGNMENTS: 'Role Assignments',
      ROOT_SCOPE_ROLE: "Ths User's Root Scope Role(s)",
      ROOT_SCOPE_ROLE_HINT: 'This user is assigned root role with {{ selectedUserRootRule }} to all resources. The following table only displays additional access rights.',
      SCOPE_HINT: 'Scope hierarchy includes the following 4 levels: Management Group, Subscription, Resource Group, Resource',
    },
    RESOURCE_SCHEDULE: {
      ATTACH_SCHEDULE_NOTE: 'Are you sure you want to attach this schedule to {{ resource }}?',
      ATTACH_SCHEDULE: 'Attach Resource Schedule',
      CHANGE_SCHEDULE: 'Change Resource Schedule',
      CREATE_RESOURCE_SCHEDULE: 'Create Resource Schedule',
      DELETE_SCHEDULE_NOTE: "Are you sure you want to delete this resource schedule '{{ scheduleName }}'?",
      DETACH_SCHEDULE_NOTE: 'Are you sure you want to detach schedule from {{ resource }}?',
      DETACH_SCHEDULE: 'Detach Resource Schedule',
      EDIT_SCHEDULE: 'Edit Resource Schedule',
      EXISTED_NAME_ALERT: 'This schedule name has already existed',
      RESET_SCHEDULE: 'Reset Schedule',
      RESOURCE_SCHEDULE: 'Resource Schedule',
      SAVE_AS_NEW_SCHEDULE: 'Save As New Resource Schedule',
      SCHEDULE_NAME: 'Schedule Name',
      SCHEDULE_RESOLUTION: 'Schedule Resolution',
      SCHEDULED_TIME: 'Scheduled Time',
      UPDATE_SCHEDULE_NOTE: 'Are you sure you want to update this resource schedule?',
    },
    RESOURCE_PLAN: {
      ACTUAL: 'Actual',
      ACTUAL_COST: 'Actual Cost',
      ADD_THRESHOLD: 'Add Threshold',
      ALERT_CONDITION: 'Alert Condition',
      ALERT_RECIPIENTS: 'Alert Recipient',
      AMORTIZED_COST: 'Amortized Cost',
      AMOUNT: 'Amount',
      BEYOND_DATE: 'Beyond Date',
      BUDGET_AMOUNT: 'Budget Amount',
      BUDGET_HISTORY: 'Budget History',
      BUDGET_OWNER: 'Budget Owner',
      COPY_FROM: 'Copy From',
      CREATION_DATE: 'Creation Date',
      CURRENT_SPEND: 'Current Spending',
      DATA_CALCULATED_DATE: 'Data calculated to',
      EFFECTIVE_DATE: 'Effective Date',
      EXPIRATION_DATE: 'Expiration Date',
      FORECAST: 'Forecast',
      GIVE_THRESHOLD: 'Give your budget amount threshold.',
      GO_USAGE_QUERY: 'Go Usage Query',
      HEADER: 'Header',
      HIDE_EXPIRED_PLAN: 'Hide Expired Plan',
      HISTORICAL_FUTURE: 'Historical Cost and Future Forecast',
      MY_PLAN_ONLY: 'Only Show my plans',
      NEW_PLAN: 'New Plan',
      NEW_RESOURCE_PLAN: 'New Resource Plan',
      OVERAGE_THRESHOLD: 'Overage Threshold',
      OWNER: 'Owner',
      RESET: 'Reset',
      RESET_PERIOD: 'Reset Period',
      RESOURCE_PLAN_LIST: 'Resource Plan List',
      SUGGESTED_BUDGET: 'Suggetsted budget: ${{ forecastAmount }} based on forecast',
      THRESHOLD: 'Threshold',
      TIME_PERIOD: 'Time Period',
      TITLE: 'Resource Plan',
      TRIGGER_ON: 'Trigger On',
    },
    DROPCELL: {
      ORPHAN: 'Orphan Num',
    },
    ORPHAN: {
      TITLE: 'Orphan Resource',
      RESOURCE_PERCENT_IN_COST: 'Orphan Resource % in Cost',
    },
    XCLOUD: {
      POTENTIAL_WASTE_30D: '30 Days Potential Waste',
      OPERATION_HEALTHINESS_30D: '30 Days Operation Healthiness',
    },
    UNUSED_LICENSE: {
      ACTIVATION_DATE: 'Activation Date',
      ACTIVITY_DATE: 'Activity Date',
      ASSIGNED_BY: 'Assigned By',
      ASSIGNED_TIME: 'Assigned Time',
      FIELDS_NOT_SUPPORTED: 'Fields not supported by this resource:',
      INACTIVE_30_DAYS: 'Inactive 30 Days',
      NUMBER_OF_LICENSES: 'Number of Licenses',
      NUMBER_OF_LICENSE_TYPES: 'Number of License Types',
      NUMBER_OF_UNUSED_LICENSES: 'Number of Unused Licenses',
      POTENTIAL_WASTE_PER_MONTH: 'Potential Waste per Month',
      PRODUCT_SERVICE_PLANS: 'Product Service Plans',
      TITLE: 'Unused License',
      TOTAL_UNITS: 'Total Units',
      NOT_INSTALLED_LICENSE: 'Not Installed License',
      UNASSIGNED_LICENSE: 'Unassigned License',
      UNUSED_LICENSE_COST_PERCENTAGE: 'Unused License Cost %',
      UNUSED_NUMBER: 'Unused Number',
      USER_SERVICE_PLANS: 'User Service Plans',
    },
    NVA: {
      AFFECTED_SOFTWARE: 'Affected Software/OS',
      DETECTION_METHOD: 'Detection Method',
      DETECTION_RESULT: 'Detection Result',
      HIGH_SEVERITY: 'High Severity',
      IMPACTED_HOSTS: 'Impacted Hosts',
      INSIGHT: 'Insight',
      LOW_SEVERITY: 'Low Severity',
      MEDIUM_SEVERITY: 'Medium Severity',
      REFERENCES: 'References',
      SUMMARY: 'Summary',
      TOTAL_VULNERABILITIES: 'Total Vulnerabilities',
      VULNERABILITY: 'Vulnerability',
    },
    CLOUD_SEC: {
      ACTIVATED_SINCE: 'Activated since {{ N }}',
      ADD_TARGET: 'Add Target',
      AGE: 'Age',
      ALIAS: 'Alias',
      ALTERNATIVES: 'Alternatives',
      ASSIGNED_USER_LIST: 'Assigned User List',
      ATTACK_COMPLEXITY: 'Attack Complexity',
      ATTACK_VECTOR: 'Attack Vector',
      AVAILABLE_IMPACT: 'Available Impact',
      AVG_SCORE_TOP_5: 'Average Score for Top 5 Vulnerabilities',
      CLASSIFICATION: 'Classification',
      CLICK_TO_UPLOAD: 'Click here to upload file.',
      CONFIDENTIALITY_IMPACT: 'Confidentiality Impact',
      CONTACT_PERSON: 'Contact Person',
      CRITICAL: 'Critical',
      CUSTOMER_NAME: 'Customer Name',
      CUSTOMER_NUMBER: 'Customer Number',
      CVSS_METRICS: 'CVSS Metrics',
      CVSS_SCORE: 'CVSS Score',
      DELETE_DOMAIN: "This action can't be undone.\nAre you sure you want to delete this domain?",
      DELETE_MESSAGE: 'This action can\'t be undone and will delete "ALL" associated scans.',
      DESCRIPTION: 'Description',
      DETAILED_GUIDE: 'Detailed Guide',
      DISTRIBUTION_CVE_YEAR: 'Distribution By CVE Year',
      DOMAIN_EXIST: 'This domain already exists.',
      ENTER_DOMAIN: 'Please enter a domain.',
      ENTRY_BY: 'Entry By',
      ENTRY_DATE: 'Entry Date',
      EVIDENCE: 'Evidence',
      EXECUTIVE_SUMMARY: 'Executive Summary',
      FILE_TYPE: 'File Type',
      FIRST_DETECTED: 'First Detected',
      FIRST_SCAN_DATE: 'First Scan Date',
      FIRST_SCAN_N_RESOLUTIONS: 'Resolution of {{ N }} Vulnerabilities Found in The First Scan',
      FIRST_SCAN: 'First Scan',
      FIX_ANALYSIS_REPORT: 'Fixed Analysis Report',
      FIX_PROCEDURE: 'Fix Procedure',
      FIX_TYPE: 'Fix Type',
      FIXED_THIS_MONTH: 'Fixed This Month',
      FIXED_TIMELINE: 'Fixed Timeline',
      FIXED_VUL_LAST_30D: 'Fixed Vuls Last 30 Days',
      GENERAL_INFO: 'General Information',
      HEALTH_SCORE: 'Your Health Score',
      HIGH: 'High',
      HOST_SEVERITY_DISTRIBUTION: 'Host Severity Distribution',
      HOW_TO_CREATE: 'How to create?',
      HOW_TO_FIX: 'How to Fix',
      IMPORTED_BY: 'Imported By',
      INDUSTRY_RANK: 'Industry Rank',
      INTEGRITY_IMPACT: 'Integrity Impact',
      KEY_VUL: 'Key Vulnerabilities',
      LAST_DETECTED: 'Last Detected',
      LAST_RESCAN: 'Last Rescan',
      LAST_SCAN_DATE: 'Last Scan Date',
      LAST_SCAN: 'Last Scan',
      LATEST_VUL: 'Latest Vulnerabilities',
      LOG_RESOLUTION: 'Log of Resolution',
      LOW: 'Low',
      MANAGE_SUBSCRIPTION: 'Manage Subscription',
      MEDIUM: 'Medium',
      NEW_THIS_MONTH: 'New This Month',
      NEW_VUL_LAST_30D: 'New Vuls Last 30 Days',
      NEW: 'New',
      NOTE: 'Note',
      NUM_OF_VUL: 'Number of Vulnerabilities',
      OPEN_VUL: 'Open Vulnerabilities',
      OVERALL_RESOLVED_VUL: 'Overall Resolved Vulnerability',
      OWASP_TOP_10: 'OWASP Top 10',
      OWNER_ASSIGN: 'Host Owner Assign',
      PERCENTILE_RANK: 'Percentile Rank',
      PLAN_EXPIRE: 'Your Plan will expire on {{ N }}',
      PREFER_SCAN_TIME: 'Preferred Scan Time',
      PRIVILEDGE_EVIDENCE: 'Priviledge Evidence',
      PUBLISHED: 'Published',
      REFERENCE: 'References',
      REMEDIATION_ADVICE: 'Remediation Advice',
      REPORT_DATE: 'Report Date',
      REPORT_DOWNLOAD: 'Report Download',
      REPORT_GEN_DATE: 'Report Generation Date',
      REPORT: 'Reports',
      RESCAN: 'Rescan',
      RESOLVED: 'Resolved',
      RISK_INFO: 'Risk Information',
      RISK: 'Risk',
      SCAN_DURATION: 'Scan Duration',
      SCAN_END_TIME: 'Scan End Time',
      SCAN_FINDINGS: 'Findings',
      SCAN_REPORTS: 'Scan Reports',
      SCAN_RESULTS: 'Scan Results',
      SCAN_START_TIME: 'Scan Start Time',
      SCAN_TARGET: 'Scan Target',
      SCAN_TEMPLATE: 'Scan Template',
      SCAN_TIME: 'Scan Time',
      SCAN_TOOLS: 'Scan Tools',
      SCANNER: 'Scanner',
      SCANS: 'Scans',
      SCORE_LAST_30D: 'Score Last 30 Days',
      SECOND_SCAN_DATE: 'Second Scan Date',
      SECOND_SCAN_N_NEW: 'New Findings of {{N}} Vulnerabilities Found in The Second Scan',
      SECOND_SCAN: 'Second Scan',
      SELECT_CRITERIA: 'Select Your Criteria',
      SEVERITY_DISTRIBUTION: 'Severity Distribution',
      SEVERITY_INDEX: 'Severity Index',
      SEVERITY: 'Severity',
      SOLUTION: 'Solution',
      SUBDOMAIN_NOTIFICATION: 'The process may take at least 10 minutes.',
      SUBDOMAIN_TITLE: 'Your domains and found subdomains',
      SUBDOMAIN: 'Subdomains',
      SUBSCRIPTION_TIME: 'Subscription Time',
      TIMELINE: 'Timeline',
      TOTAL_FINDING: 'Total Findings',
      TOTAL_HOSTS: 'Total Hosts',
      TOTAL_VUL: 'Total Vuls',
      UNRESOLVED: 'Unresolved',
      UPDATED: 'Updated',
      USER_INTERACTION: 'User Interaction',
      VPN_PROFILE: 'VPN Profile',
      VUL_COUNT: 'Vulnerability Count',
      VUL_INFO: 'Vulnerability Information',
      VUL_LAST_30D: 'Vulnerabilities Last 30 Days',
      VUL_OVERVIEW: 'Vulnerability Overview',
      VUL_RESOLUTION_REPORT: 'Vulnerability Resolution Report',
      VUL_TREND: 'Vulnerabilities Trend',
      VUL: 'Vulnerabilities',
      WEBSITE_COUNT: 'Website Count',
    },
    BALANCE_SUMMARY: {
      AVG_COST: 'Average Cost',
      FORECAST_END_BALANCE: 'Forecast End Balance',
      FORECAST_HISTORY: 'Forecasts by history data',
      FORECAST_RECOMMEND: 'Forecasts by ${{N}}/month to meet balance',
      MEET_BALANCE: 'Time to meet balance',
      TOTAL_PURCHASE: 'Total Purchase'
    },
    VRM: {
      CREATE_QUESTIONNAIRE: 'create custom questionnaire',
      PUBLISHED_SCANS: 'published scans',
      QUESTIONNAIRE_TITLE: 'questionnaire library',
      SCAN_STATUS: 'scan status',
      TOTAL_VENDORS: 'total vendors',
      VENDOR_COUNT: 'vendor count',
      MEDIAN_SECURITY_RATING: 'median security rating',
      SECURITY_RATING_DISTRIBUTION: 'security rating distribution',
      VENDOR_BREAKDOWN: 'vendor breakdown',
      QUESTIONNAIRE: 'questionnaire',
      QUESTIONNAIRES: 'questionnaires',
      QUESTIONNAIRE_AVERAGE_SCORE: 'questionnaire average score',
      SECURITY_RATING_TREND: 'security rating trend',
      VENDOR_SCORE: 'vendor score',
      SEND_QUESTIONNAIRE: 'Send Questionnaire',
      SELECT_SCOPE: 'Select Scope',
      SELECT_SCHEDULE: 'Select Schedule',
      TITLE_MESSAGE: 'Title & Message',
      REVIEW_SEND: 'Review & Send',
      DUE_DATE: 'Due Date',
      SELECT_PROGRAM_VENDOR: 'Select Program & Vendor',
      SET_DUE_DATE: 'Set a Due Date',
      ADD_TITLE: 'Add a Title',
      ADD_MESSAGE: 'Add a Message',
      SELECT_SCHEDULE_INFO: `Let the vendor know when questionnaire should be completed by. Optionally, you can set the reminders to
              resend the questionnaire.`,
      SET_DUE_DATE_INFO: 'Set a date you would like this questionnaire to be completed by.',
      TITLE_MESSAGE_INFO: 'The title and message will be visible to recipients when the receive the questionnaire.',
      ADD_TITLE_INFO: 'The title will visible to you and recipient(s).',
      ADD_MESSAGE_INFO: `The message will be included in the email we send asking your recipient(s) to complete the questionnaire
                and show on the questionnaire itself.`,
      REVIEW_SEND_INFO: 'Please review this carefully before sending.'
    },
    STAT: {
      COMPLETED: 'completed',
      IDLE: 'idle',
      INCOMPLETE: 'incomplete',
      IN_PROGRESS: 'in progress',
      NOT_START: 'not start',
    },
    AUTH_SCAN: {
      AUTOMATIC: 'Automatic',
      AUTH_RESULT: 'Authentication result',
      CHECK_AUTH: 'Check Authentication',
      CHOOSE: 'Choose',
      COOKIE_HEADER: 'Cookie Header',
      COOKIES: 'Cookies',
      ENABLE_AUTH: 'Enable Authentication',
      RECORDED: 'Recorded',
      UPLOAD_FILE: 'Upload your file here.',
      UPLOADED: 'is uploaded.',
      START_SCAN: 'Start Scan'
    },
    YSL: {
      TASK_NAME: 'Task Name',
      RUNS: 'Runs',
      EACH_SCAN: 'Each Scan',
    },
    LLM_AGENT: {
      MESSAGE: {
        TARGET_REQUIRE: 'Target is required.',
        GOAL_REQUIRE: 'Goal is required.',
      },
      // CONFIG_PENTEST_TASK: 'Config Pentest Task',
      CONNECTING: 'Connecting',
      CONNECTED: 'Connected',
      DISCONNECTED: 'Disconnected',
    },
    CVE: {
      CVE_LIST: 'CVEs List',
      CVE_MESSAGE: 'This is the list of vulnerabilities that can be detect with YODA. We detect more than {{ N }} vulnerabilities with multiple tools (Network Scanner, Website Scanner and more).'
    }
  },
};
