/**
 * CSV converters.
 */
export default class CSVUtil {

  /**
   * Export data into csv with custom file name
   * @param csv csv data string
   * @param fileName file name
   */
  public static exportCSV(csv: string, fileName: string): void {
    let blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    if (link.download !== undefined) {
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', `export_${fileName}_${new Date().toISOString()}.csv`);
      link.click();
    } else {
      csv += 'data:text/csv;charset=utf-8,';
      window.open(encodeURI(csv));
    }
    document.body.removeChild(link);
  }
}
