// Angular
import { Component, Injector, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
import { CommonUtil, JSONUtil } from '@util';
// Interface
import { NgChange } from '@core/model';

/**
 * TODO: finish this and replace on inused component
 */
@Component({
  selector: 'json-formatter',
  templateUrl: './json-formatter.component.html',
  styleUrls: ['./json-formatter.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class JsonFormatterComponent extends BaseComponent implements OnChanges {
  @Input('value') public json: Record<string, unknown> | string;

  public isThemeHacker: boolean;
  public htmlString: string;

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  public ngOnChanges(changes: Changes): void {
    if (changes.json?.currentValue && !CommonUtil.equals(changes.json?.currentValue, changes.json?.previousValue)) {
      this.htmlString = JSONUtil.syntaxHighlight(changes.json.currentValue);
    }
    // this.logger.debug('json formatter:', [changes?.json, [this.htmlString]]);
  }
}

interface Changes extends SimpleChanges {
  json: NgChange<JSON | string>;
}
