export enum NotificationSeverity {
  Warning = 'Warning',
  Error = 'Error',
  Success = 'Success',
}

export enum NotificationColor {
  Warning = '#FDD543',
  Error = '#FF5151',
  Success = '#79FF79',
  Secondary = '#D0D0D0',
}
