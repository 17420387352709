// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
// Interface
import { DataTable, EAEnrollmentSelectItem, EntityObject } from '@base/model';
import { App, AuthUser, Language, SwitchAppResult, SwitchCustomerResult, TimeZone, UpdateUserProfileResult, UserMessage, UserProfile } from '@core/model';
import { SecurityPolicy } from '@sys-admin/model';

/**
 * User Management For Back-End API
 * @author Tony.su
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly http: HttpClient) {}

  public getMeUserProfileAsEntityObject(): Observable<EntityObject<UserProfile>> {
    return this.http.get<EntityObject<UserProfile>>(`${environment.apiServer.url}/api/users/me/asEntityObject`);
  }

  public getUserMe(): Observable<AuthUser> {
    return this.http.get<AuthUser>(`${environment.apiServer.url}/api/users/me`);
  }

  public updateUserMe(
    userName?: string,
    languageId?: string,
    timeZoneId?: string
  ): Observable<UpdateUserProfileResult> {
    return this.http.post<UpdateUserProfileResult>(`${environment.apiServer.url}/api/users/me`, {
      userName,
      languageId,
      timeZoneId,
    });
  }

  public updateUserLang(languageId?: string): Observable<UpdateUserProfileResult> {
    return this.http.post<UpdateUserProfileResult>(`${environment.apiServer.url}/api/users/me`, {
      userName: null,
      languageId,
      timeZoneId: null,
    });
  }

  public reloadMe(): Observable<UpdateUserProfileResult> {
    return this.http.get<UpdateUserProfileResult>(`${environment.apiServer.url}/api/users/me/reload`);
  }

  public changePassword(existingPassword: string, newPassword: string): Observable<UserMessage> {
    return this.http.post<UserMessage>(`${environment.apiServer.url}/api/users/me/changePassword`, {
      existingPassword,
      newPassword,
    });
  }

  public getSecurityPolicy(): Observable<SecurityPolicy> {
    return this.http.get<SecurityPolicy>(`${environment.apiServer.url}/api/users/securityPolicy`);
  }

  public getTimeZoneList(): Observable<TimeZone[]> {
    return this.http.get<TimeZone[]>(`${environment.apiServer.url}/api/users/dimension/timeZone`);
  }

  public getLanguageList(): Observable<Language[]> {
    return this.http.get<Language[]>(`${environment.apiServer.url}/api/users/dimension/language`);
  }

  public switchCustomer(customerId: string = ''): Observable<SwitchCustomerResult> {
    return this.http.get<SwitchCustomerResult>(
      `${environment.apiServer.url}/api/users/me/switchCustomer/${customerId}`
    );
  }

  public getAppList(): Observable<DataTable<App>> {
    return this.http.get<DataTable<App>>(`${environment.apiServer.url}/api/users/me/apps`);
  }

  public switchApp(appId: string): Observable<SwitchAppResult> {
    return this.http.get<SwitchAppResult>(`${environment.apiServer.url}/api/users/me/switchApp/${appId}`);
  }

  public getPartnerList(): Observable<DataTable<unknown>> {
    return this.http.get<DataTable<unknown>>(`${environment.apiServer.url}/api/users/me/partners`);
  }

  public switchPartner(cspId: string): Observable<UpdateUserProfileResult> {
    return this.http.get<UpdateUserProfileResult>(`${environment.apiServer.url}/api/users/me/switchPartner/${cspId}`);
  }

  public getResellerList(): Observable<DataTable<App>> {
    return this.http.get<DataTable<App>>(`${environment.apiServer.url}/api/users/me/resellers`);
  }

  public switchReseller(resellerId: string): Observable<UpdateUserProfileResult> {
    return this.http.get<UpdateUserProfileResult>(
      `${environment.apiServer.url}/api/users/me/switchReseller/${resellerId}`
    );
  }

  public getSwitchableEAList(): Observable<EAEnrollmentSelectItem[]> {
    return this.http.get<EAEnrollmentSelectItem[]>(`${environment.apiServer.url}/api/users/switchableEAList`);
  }

  public switchEAEnrollment(eaEnrollmentNumber: string): Observable<SwitchAppResult> {
    return this.http.get<SwitchAppResult>(
      `${environment.apiServer.url}/api/users/me/switchEAEnrollment/${eaEnrollmentNumber}`
    );
  }
}
