export enum ChartTypes {
  Anomaly = 'D3Anomaly',
  AreaChart = 'AreaChart',
  BarChart = 'BarChart',
  ColumnChart = 'ColumnChart',
  ComboChart = 'ComboChart',
  DataTable = 'DataTable',
  Gauge = 'Gauge',
  GeoChart = 'GeoChart',
  LineChart = 'LineChart',
  MaterialBar = 'MaterialBar',
  MaterialLine = 'MaterialLine',
  PieChart = 'PieChart',
}
