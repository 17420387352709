import { SVGCrowbarPNGOptions, SVGCrowbarPNGSource } from '@base/model';
import { DEFAULT_FILENAME } from './const';
import { commenceDownload } from './util';

export const downloadSvg: (source: SVGCrowbarPNGSource, filename?: string, options?: SVGCrowbarPNGOptions) => void = (
  source: SVGCrowbarPNGSource,
  filename?: string
): void => {
  let _filename: string = filename !== undefined ? filename : DEFAULT_FILENAME;
  let _url: string = URL.createObjectURL(
    new Blob([source.source], {
      type: 'text/xml',
    })
  );
  commenceDownload(''.concat(_filename, '.svg'), _url, (): void => {
    return void URL.revokeObjectURL(_url);
  });
};
