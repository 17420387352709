// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
import { UpdateUserProfileResult } from '@core/model';
/**
 * User Management For Back-End API
 * @author Tony.su
 */
@Injectable({ providedIn: 'root' })
export class UserCoreService {
  constructor(private readonly http: HttpClient) {}

  public updateUserLang(languageId?: string): Observable<UpdateUserProfileResult> {
    return this.http.post<UpdateUserProfileResult>(`${environment.apiServer.url}/api/users/me`, {
      userName: null,
      languageId,
      timeZoneId: null,
    });
  }

  public reloadMe(): Observable<UpdateUserProfileResult> {
    return this.http.get<UpdateUserProfileResult>(`${environment.apiServer.url}/api/users/me/reload`);
  }
}
