import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisconnectService {
  private readonly pendingHTTPRequests$ = new Subject<void>();

  constructor() {}

  public cancelPendingRequests(): void {
    this.pendingHTTPRequests$.next();
  }

  public onCancelPendingRequests(): Observable<void> {
    return this.pendingHTTPRequests$.asObservable();
  }
}
