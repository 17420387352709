// Angular
import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
// Caloudi
import { BaseComponent } from '@base';
// Interface
import { NgChange } from '@core/model';
// D3
import * as d3 from 'd3';

@Component({
  selector: 'caloudi-multi-donut-chart',
  templateUrl: './multi-donut-chart.component.html',
  styleUrls: ['./multi-donut-chart.component.sass']
})
export class MultiDonutChartComponent extends BaseComponent implements OnChanges {

  @Input('data') public data: ChartData[];
  @Input('score') public score: ChartData;
  @Input('width') public _width: number = 200;
  @Input('height') public _height: number = 200;

  private get width(): number { return Math.min(this._width, this._height); }
  private get height(): number { return Math.min(this._width, this._height); }

  private readonly chartColor = ['#E68340', '#E14B53', '#6BBBA9', '#D4DE65'];

  private readonly logActive: boolean = !1;

  constructor(public readonly injector: Injector) {
    super(injector);

    this.logActive && this.logger.debug('d3:', [d3]);
  }

  public ngOnChanges(changes: Changes): void {
    if (!changes.data.currentValue && !changes.score.currentValue) return;
    this.logActive && this.logger.debug('changes:', [changes]);

    /** Make sure not duplicate */
    d3.select('svg')?.remove();
    /** Find which is min to make svg square */
    const min = Math.min(this.width, this.height);
    /** Arc for polyline */
    // const polyarc = d3.arc<d3.PieArcDatum<ChartData>>()
    //   .innerRadius(min)
    //   .outerRadius(min);
    /** D3 Pie */
    const pie = d3.pie<ChartData>()
      // .padAngle(1 / radius)
      .sort(null)
      .value(d => d.value);
    /** Basic svg  */
    const svg = d3.select<SVGSVGElement, ChartData>('#multi-donut-chart')
      .append('svg')
      .attr('width', this.width * 2)
      .attr('height', this.height)
      .attr('viewBox', [-this.width / 2, -this.height / 2, this.width * 2, this.height]);

    /** Data section */
    svg.append('g')
      .attr('class', 'data');
    this.data.forEach((v, i) => {
      const radius = (min - min / 10 * (this.data.length + i)) / 1.25;
      const arc = d3.arc<d3.PieArcDatum<ChartData>>()
        .innerRadius(radius - min / 100 * 5)
        .outerRadius(radius);
      this.logActive && this.logger.debug('pie:', [radius, arc, pie, v, i]);
      svg.select('.data')
        .append('g')
        .attr('class', `data-${v.name}`)
        .selectAll()
        .data(pie([v, { name: null, value: 100 - v.value }]))
        .join('path')
        .attr('fill', d => d.data.name ? this.chartColor[i] : 'transparent')
        .attr('d', arc);
    });
    // .call(path =>
    // svg.append('g')
    //   // .attr('font-size', 12)
    //   .attr('text-anchor', 'middle')
    //   .selectAll()
    //   .data(pie([this.data[i], { name: null, value: 100 - v.value }]))
    //   .join('text')
    //   .attr('transform', d => `translate(${arc.centroid(d)})`)
    //   .call(text => text.append('tspan')
    //     .attr('y', '-0.4rem')
    //     .attr('font-weight', 'bold')
    //     .text(d => d.data.name))
    //   .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append('tspan')
    //     .attr('x', 0)
    //     .attr('y', '0.7rem')
    //     .attr('fill-opacity', 0.7)
    //     .text(d => d.data.name && d.data.value.toLocaleString('en-US')));
    // )
    // .call(path => path.datum().data.name && path.append('title')
    //   .text(d => `${d.data.name}: ${d.data.value.toLocaleString()}`));

    /** Score */
    svg.append('g')
      .attr('class', 'score')
      .call(g => g.append('circle')
        .attr('fill', '#F6C963')
        .attr('r', (min - min / 5 * (this.data.length)) / 1.25))
      .call(g => g.append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', 'white')
        .style('font-size', '2rem')
        .text(this.score.value))
      .call(g => g.append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', 'white')
        .attr('transform', 'translate(0, 20)')
        .text(`(${this.score.name})`));

    /** Annotations */
    svg.append('g')
      .attr('class', 'annotations')
      .selectAll()
      .data(this.data)
      .join('g')
      .attr('class', d => `annotation-${d.name}`)
      .call(g => g.append('circle')
        .attr('r', 4)
        .attr('transform', (_, i) => `translate(${this.width / 2 + 10}, ${-this.height / 2 + 20 * (i + 1) - 5})`)
        .attr('fill', (_, i) => this.chartColor[i]))
      .call(g => g.append('text')
        .attr('transform', (_, i) => `translate(${this.width / 2 + 20}, ${-this.height / 2 + 20 * (i + 1)})`)
        .attr('font-weight', 'bold')
        .attr('fill', 'currentColor')
        .text(d => d.name))
      .call(g => g.append('text')
        .attr('transform', (_, i) => `translate(${this.width / 2 + 100}, ${-this.height / 2 + 20 * (i + 1)})`)
        .attr('fill', 'currentColor')
        .text(d => d.value));

    // TODO: Add Polylines
    /** Polylines */
    // svg.append('g')
    //   .attr('class', 'polylines')
    //   .selectAll()
    //   .data(polypie(this.data))
    //   .join('g')
    //   .attr('class', d => `polyline-${d.data.name}`)
    //   .append('polyline')
    //   .attr('points', (d, i) => [...polyarc.centroid(d), ...[this.width / 2 + 100, -this.height / 2 + 20 * (i + 1)]]);
  }
}

interface Changes extends SimpleChanges {
  data: NgChange<ChartData[]>;
  score: NgChange<ChartData>;
  _width: NgChange<number>;
  _height: NgChange<number>;
}

interface ChartData {
  name: string;
  value: number;
}
