// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { Subject } from 'rxjs';
// Interface
import { MenuChangeEvent } from '@base/model';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private readonly menuSource = new Subject<MenuChangeEvent>();
  private readonly resetSource = new Subject<boolean>();

  public menuSource$ = this.menuSource.asObservable();
  public resetSource$ = this.resetSource.asObservable();

  public onMenuStateChange(event: MenuChangeEvent): void {
    this.menuSource.next(event);
  }

  public reset(): void {
    this.resetSource.next(true);
  }
}
