<section class="cron-editor-main">
  <p-tabView class="cron-editor-container" [(activeIndex)]="tabIndex" [logTabview]="'Cron Editor'">
    <!-- Daily-->
    <p-tabPanel class="cron-editor-tab" [header]="'DATE.DAILY' | translate" [formGroup]="dailyForm">
      <section>
        <div class="d-flex">
          <p-radioButton
            class="cron-editor-radio-button"
            name="subTab"
            formControlName="subTab"
            value="everyWeekDay"
            checked="checked"
            [logButton]="'EveryWeekDay'">
          </p-radioButton>
          <p>{{ 'DATE.WEEK_DAY' | translate }}</p>
        </div>
        <cron-time-picker
          formGroupName="everyWeekDay"
          [use24HourTime]="options.use24HourTime"
          [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
        </cron-time-picker>
      </section>

      <hr />

      <section>
        <div class="d-flex">
          <p-radioButton
            class="cron-editor-radio-button"
            name="subTab"
            formControlName="subTab"
            value="everyDays"
            [logButton]="'EveryDays'">
          </p-radioButton>
          <p class="cron-form-label">
            {{ 'LABEL.EVERY' | translate }}
          </p>
          <div class="form-field" formGroupName="everyDays">
            <!-- <mat-select formControlName="days">
              <mat-option *ngFor="let monthDay of selectOptions.monthDays"
                          [value]="monthDay">
                {{ monthDay }}
              </mat-option>
            </mat-select> -->
            <p-dropdown [options]="selectOptions?.monthDays" formControlName="days" [logDropdown]="'Month Day'">
            </p-dropdown>
          </div>
          <p>
            {{ 'DATE.DAYS' | translate }}
            {{ 'LABEL.AT' | translate }}
          </p>
        </div>
        <cron-time-picker
          formGroupName="everyDays"
          [use24HourTime]="options.use24HourTime"
          [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
        </cron-time-picker>
      </section>
    </p-tabPanel>

    <!-- Weekly-->
    <p-tabPanel class="cron-editor-tab" [header]="'DATE.WEEKLY' | translate">
      <section class="cron-editor-checknbox-group">
        <p class="cron-form-label">{{ 'LABEL.EVERY' | translate }}</p>
        <div [formGroup]="weeklyForm">
          <mat-checkbox>
            {{ 'DATE.MONDAY' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="TUE">
            {{ 'DATE.TUESDAY' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="WED">
            {{ 'DATE.WEDNESDAY' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="THU">
            {{ 'DATE.THURSDAY' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="FRI">
            {{ 'DATE.FRIDAY' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="SAT">
            {{ 'DATE.SATURDAY' | translate }}
          </mat-checkbox>
          <mat-checkbox formControlName="SUN">
            {{ 'DATE.SUNDAY' | translate }}
          </mat-checkbox>
        </div>
      </section>

      <hr />

      <section>
        <p class="cron-form-label">{{ 'LABEL.AT_TIME' | translate }}</p>
        <cron-time-picker
          [formGroup]="weeklyForm"
          [use24HourTime]="options.use24HourTime"
          [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
        </cron-time-picker>
      </section>
    </p-tabPanel>

    <!-- Monthly-->
    <p-tabPanel class="cron-editor-tab" [header]="'DATE.MONTHLY' | translate" [formGroup]="monthlyForm">
      <section>
        <div class="d-flex">
          <p-radioButton
            name="subTab"
            formControlName="subTab"
            value="specificDay"
            [logButton]="{ label: 'Specific Day' }">
          </p-radioButton>
          <!-- Spesific day -->
          <p formGroupName="specificDay">
            {{ 'LABEL.ON_THE' | translate }}
            <ng-container *ngIf="options.cronFlavor === 'quartz'">
              <div class="form-field">
                <mat-label>{{ 'DATE.DAY' | translate }}</mat-label>
                <mat-select class="month-days" formControlName="day">
                  <option
                    *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                    [value]="monthDaysWithLast">
                    {{ monthDayDisplay(monthDaysWithLast) }}
                  </option>
                </mat-select>
              </div>
            </ng-container>
            <ng-container *ngIf="options.cronFlavor === 'standard'">
              <div class="form-field">
                <!-- <mat-select class="month-days"
                            formControlName="day">
                  <mat-option *ngFor="let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts"
                              [value]="monthDaysWithOutLast">
                    {{ monthDayDisplay(monthDaysWithOutLast) }}
                  </mat-option>
                </mat-select> -->
                <p-dropdown
                  [options]="selectOptions.monthDaysWithOutLasts"
                  formControlName="day"
                  [logDropdown]="'Specific Day - Day'">
                </p-dropdown>
              </div>
            </ng-container>
            {{ 'DATE.DAY' | translate }}
            {{ 'LABEL.OF_EVERY' | translate }}
            <ng-container>
              <div class="form-field">
                <!-- <mat-select class="months-small"
                            formControlName="months"
                            [ngClass]="options.formSelectClass">
                  <mat-option *ngFor="let month of selectOptions.months"
                              [value]="month">
                    {{ month }}
                  </mat-option>
                </mat-select> -->
                <p-dropdown
                  [options]="selectOptions?.months"
                  formControlName="months"
                  [logDropdown]="'Specific Day - Month'">
                </p-dropdown>
              </div>
            </ng-container>
            {{ 'DATE.MONTH' | translate }}
            {{ 'LABEL.AT_TIME' | translate }}
          </p>
        </div>
        <cron-time-picker
          [disabled]="disabled"
          [formGroup]="$any(monthlyForm.controls.specificDay)"
          [use24HourTime]="options.use24HourTime"
          [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
        </cron-time-picker>
      </section>

      <section>
        <div class="d-flex">
          <p-radioButton
            name="subTab"
            formControlName="subTab"
            value="specificWeekDay"
            [logButton]="{ label: 'Specific Week Day' }">
          </p-radioButton>
          <!-- Spesific Week day -->
          <p formGroupName="specificWeekDay">
            {{ 'LABEL.ON_THE' | translate }}
            <ng-container>
              <div class="form-field">
                <!-- <mat-label></mat-label> -->
                <!-- <mat-select class="day-order-in-month"
                            formControlName="monthWeek"
                            [ngClass]="options.formSelectClass">
                  <mat-option *ngFor="let monthWeek of selectOptions.monthWeeks"
                              [value]="monthWeek">
                    {{ monthWeekDisplay(monthWeek) }}
                  </mat-option>
                </mat-select> -->
                <p-dropdown
                  [options]="selectOptions?.monthWeeks"
                  formControlName="monthWeek"
                  [logDropdown]="'Specific Week Day - Month Week'">
                </p-dropdown>
              </div>
              {{ 'DATE.WEEK' | translate }}
              <span>/</span>
              <div class="form-field">
                <!-- <mat-label></mat-label> -->
                <!-- <mat-select class="week-days"
                            formControlName="day"
                            [ngClass]="options.formSelectClass">
                  <mat-option *ngFor="let day of selectOptions.days"
                              [value]="day">
                    {{ dayDisplay(day) }}
                  </mat-option>
                </mat-select> -->
                <p-dropdown
                  [options]="selectOptions?.days"
                  formControlName="day"
                  [logDropdown]="'Specific Week Day - Day'">
                </p-dropdown>
              </div>
              {{ 'LABEL.OF_EVERY' | translate }}
              <div class="form-field">
                <!-- <mat-label></mat-label> -->
                <!-- <mat-select class="months-small"
                            formControlName="months">
                  <mat-option *ngFor="let month of selectOptions.months"
                              [value]="month">
                    {{ month }}
                  </mat-option>
                </mat-select> -->
                <p-dropdown
                  [options]="selectOptions?.months"
                  formControlName="months"
                  [logDropdown]="'Specific Week Day - Month'">
                </p-dropdown>
              </div>
              {{ 'DATE.MONTH' | translate }}
              {{ 'LABEL.AT_TIME' | translate }}
            </ng-container>
          </p>
        </div>
        <cron-time-picker
          [formGroup]="$any(monthlyForm.controls.specificWeekDay)"
          [use24HourTime]="options.use24HourTime"
          [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
        </cron-time-picker>
      </section>
    </p-tabPanel>
  </p-tabView>
</section>
