<p-dialog
  #dialog
  primeDialog
  [ngClass]="class"
  [style]="{ height }"
  [(visible)]="displayDialog"
  (onHide)="hideDialog()"
  [dismissableMask]="dismissableMask"
  [focusOnShow]="focusOnShow"
  [blockScroll]="blockScroll"
  [maximizable]="maximizable"
  [draggable]="draggable"
  [resizable]="resizable"
  [closable]="false"
  [header]="header"
  [modal]="modal">
  <ng-template pTemplate="header" *ngIf="!header">
    <i *ngIf="icon" [ngClass]="icon" [style]="{ color: iconColor }"></i>
    <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
  </ng-template>
  <div class="notification_message">
    <div class="content">
      <div class="icon" [ngStyle]="{ 'background-color': NotificationColor[severity] }">
        <ng-container [ngSwitch]="severity">
          <ng-container *ngSwitchCase="NotificationSeverity.Error">
            <i class="pi pi-times"></i>
          </ng-container>
          <ng-container *ngSwitchCase="NotificationSeverity.Success">
            <i class="pi pi-check"></i>
          </ng-container>
          <ng-container *ngSwitchCase="NotificationSeverity.Warning">
            <i class="pi pi-exclamation-triangle"></i>
          </ng-container>
        </ng-container>
      </div>
      <div class="message">
        <p>
          {{ message }}
        </p>
      </div>
      <div>
        <div [hidden]="!isError" class="alert alert-danger" style="width: 30rem; margin: auto">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="buttons">
      <ng-template [ngTemplateOutlet]="buttonsTemplate"></ng-template>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <ng-template [ngTemplateOutlet]="footerTemplate"></ng-template>
  </ng-template>
  <!-- <ng-content></ng-content> -->
</p-dialog>

<ng-template #headerTemplate>
  <ng-content #headerContent select="[header]"></ng-content>
</ng-template>

<ng-template #footerTemplate>
  <ng-content #footerContent select="[footer]"></ng-content>
</ng-template>

<ng-template #buttonsTemplate>
  <ng-content #footerContent select="[buttons]"></ng-content>
</ng-template>
