// NGRX
import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { MessageState } from '../reducers';

// For selector
export const selectMessageState: (state: AppState) => MessageState = (state: AppState): MessageState => state.message;

// Point to 'error' from state subtree
export const getGlobalMessage = createSelector(selectMessageState, message => message);
