<div [formGroup]="$any(parent.control)" class="cron-time-picker">
  <section>
    <ng-container *ngIf="!hideHours">
      <mat-select formControlName="hours" hidden> </mat-select>
      <div class="slider-group">
        {{ parent.control.get('hours').value }}

        <span *ngIf="parent.control.get('hours').value > 11; else amClock">PM</span>
        <ng-template #amClock>
          <span>AM</span>
        </ng-template>

        <p-slider formControlName="hours" [min]="0" [max]="23" [logSlider]="'Hours'"></p-slider>
      </div>
    </ng-container>
  </section>

  <ng-container *ngIf="!hideMinutes">
    <!-- <span *ngIf="!hideHours">:</span> -->
    <section>
      <mat-select formControlName="minutes" hidden> </mat-select>
      <div class="slider-group">
        {{ parent.control.get('minutes').value }} {{ 'DATE.MINUTES' | translate }}
        <p-slider formControlName="minutes" [min]="0" [max]="59" [logSlider]="'Minutes'"></p-slider>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="!hideSeconds">
    <!-- <span *ngIf="!hideMinutes">:</span> -->
    <section>
      <mat-form-field>
        <mat-label>{{ 'DATE.SECONDS' | translate }}</mat-label>
        <mat-select formControlName="seconds">
          <mat-option *ngFor="let second of seconds" [value]="second">{{ second }}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
  </ng-container>

  <ng-container *ngIf="!use24HourTime">
    <!-- <span>.</span> -->
    <section>
      <mat-form-field>
        <mat-select formControlName="hourType">
          <mat-option *ngFor="let hourType of hourTypes" [value]="hourType">{{ hourType }}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
  </ng-container>
</div>
