import { DocumentLangType } from '@core/enum';

export const locale = {
  lang: DocumentLangType.CHS,
  data: {
    LABEL: {
      ABNORMAL_DETECTION: '异常检测',
      ACCESSIBLE_RESOURCES: '可访问资源',
      ACCESS_ACTIVITIES: '访问活动记录',
      ACCESS_CONTROL: '访问控制',
      ACCESS_GROUP: '访问组',
      ACCESS_HOSTS: '访问主机',
      ACCESS_MEMBERS: '访问成员',
      ACCOUNT: '账户',
      ACCOUNT_BALANCE: '账户余额',
      ACCOUNT_HOLDER_TYPE: '账户持有人类型',
      ACCOUNT_NAME: '账户名称',
      ACTION: '操作',
      ACTIONS: '操作',
      ACTIVATE: '激活',
      ACTIVATE_SUBSCRIPTION: '激活订阅',
      ACTIVE: '激活',
      ADD: '添加',
      ADDITIONAL_EMAIL: '其他邮箱',
      ADDRESS: '地址',
      ADDRESS_LINE_1: '地址1',
      ADDRESS_LINE_2: '地址2',
      ADD_NEW_CUSTOMER: '新增客户',
      ADD_ON_SUBSCRIPTION: '附加订阅',
      ADMINISTER_SERVICES: '管理服务',
      ADMIN_USERS: '管理员',
      ADMIN_USER_ASSIGNMENTS: '管理员分配',
      ADVISOR_CATEGORY: '顾问类别',
      AGREEMENT_HAS_ACCEPTED: '客户已接受最新的Microsoft客户协议',
      AGREEMENT_TYPE: '协议类型',
      AGREEMENT_WILL_BE_ASKED: '客户将在Microsoft 365管理中心接受Microsoft客户协议',
      ALERT_RECIPIENTS: '警报接收者',
      ALL: '全部',
      AMORTIZED_RI_COST: 'RI摊销成本',
      ANNOUNCEMENT: '公告',
      ANNOUNCEMENT_TIME: '公告时间',
      ANNOUNCEMENT_TYPE: '公告类型',
      ANNUAL: '年度',
      API_ACCESS_CONFIG: 'API访问配置',
      API_ACCESS_KEY: 'API访问密钥',
      API_SETTING_TEST: 'API设置测试',
      API_TYPE: 'API类型',
      APP_ROLE: '应用角色',
      APP_ROLES: '应用角色',
      ASSIGNED: '已分配',
      ASSIGNED_ROLES: '已分配的角色',
      ASSIGNED_USERS: '已分配的用户',
      ASSIGNMENTS: '分配',
      ASSIGN_LICENSE: '分配许可',
      AT: '在',
      ATTACHMENTS: '附件',
      ATTENTION: '注意',
      AT_TIME: '在',
      AUTHORIZED_SUBSCRIPTION: '已授权订阅',
      AVERAGE: '平均',
      AVG_CPU_PERCENT_30_DAYS: '30天平均CPU(%)',
      BANK: '银行',
      BANK_NAME: '银行名称',
      BANK_ROUTING: '银行路由号码',
      BC_CUSTOMER: '客户结算周期',
      BC_PARTNER: '合作伙伴结算周期',
      BEYOND_DATE: '超出日期警告日期',
      BEYOND_DATE_ALERT: '超出日期警告',
      BILLING: '账单',
      BILLING_ACCOUNT: '账单账户',
      BILLING_CURRENCY: '账单货币',
      BILLING_CYCLE: '结算周期',
      BILLING_PERIOD: '账单周期',
      BILLING_SCHEDULE: '账单计划',
      BY_FOR_GROUP: '按组',
      CALOUDI_USER: 'Caloudi用户',
      CANCEL: '取消',
      CANCEL_ORDER: '取消订单',
      CC_EMAILS: '抄送邮箱',
      CHANGE_LANG: '语言切换',
      CHARGE_UNIT: '计费单位',
      CHART_GROUP_BY: '图表分组依据',
      CITY: '城市',
      CLEAR: '清除',
      CLEAR_FILTER: '清除筛选',
      CLOSE: '关闭',
      CLOUD_USAGE_DATA: '云使用数据',
      CLOUD_USAGE_DATA_SYNC_PROGRESS: '云使用数据同步进度',
      CLOUD_VENDOR: '云供应商',
      COLLAPSE: '折叠',
      COMMENT: '评论',
      COMMENTS: '评论',
      COMPANY: '公司',
      COMPANY_COUNTRY: '公司国家',
      COMPANY_ID: '公司ID',
      COMPANY_NAME: '公司名称',
      COMPANY_SIZE: '公司规模',
      COMPANY_WEBSITE: '公司网站',
      COMPARISON: '对比',
      CONSTRAINTS: '限制条件',
      CONSUMED: '已购买',
      CONSUMPTION: '一般费用',
      CONTACT: '联系人',
      CONTACT_INFO: '联系信息',
      CONTACT_METHOD: '联系方式',
      CONTACT_TYPES: '联系人类型',
      COPY_TO_CLIPBOARD: '复制到剪贴板',
      CORE: '核心',
      COST_PERCENTAGE_LIST_PRICE: '成本折扣百分比',
      COST_PRICE: '成本',
      COST_WITH_RECOMMENDATION: '推荐成本',
      COUNTRY: '国家',
      COUNTS: '数量',
      COVERAGE_RATE: '覆盖率',
      CREATED: '已创建',
      CREATED_BY: '创建者',
      CREATED_TIME: '创建时间',
      CREATE_SALES_ORDER: '新增销售订单',
      CREATE_TIME: '创建时间',
      CREATION_TIME: '创建时间',
      CREATOR: '创建者',
      CREAT_DATE: '创建日期',
      CREDITS: '信用额度',
      CREDIT_CARD: '信用卡',
      CREDIT_CARD_HOLDER_NAME: '信用卡持有人姓名',
      CREDIT_CARD_NUMBER: '信用卡号码',
      CREDIT_CARD_TYPE: '信用卡类型',
      CREDIT_LIMIT: '信用额度',
      CRON_LANG: 'zh_CN',
      CUD_RECOMMENDATION: 'CUD建议',
      CURRENT: '当前',
      CURRENT_COST: '当前费用',
      CURRENT_QTY: '当前数量',
      CURRENT_SKU: '当前SKU',
      CUSTOMER: '客户',
      CUSTOMER_AGREEMENT: '客户协议',
      CUSTOMER_ASSIGNMENTS: '客户分配',
      CUSTOMER_DETAIL: '客户详情',
      CUSTOMER_MANAGEMENT: '客户管理',
      CUSTOMER_PRIMARY_CONTACT: '客户主要联系人',
      CUSTOMER_SEGMENT: '客户类型',
      CUSTOMER_TYPE: '客户类型',
      CUSTOM_EVENTS: '自定义事件',
      CVC_NUMBER: 'CVC验证码',
      DASHBOARD: '仪表板',
      DEDUCTED_HOURS: '扣减小时数',
      DEFAULT_METHOD: '默认付款方式',
      DELETE: '删除',
      DELETED_USERS: '已删除用户',
      DESCRIPTION: '描述',
      DETAIL: '详情',
      DETAILS: '详情',
      DIMENSION: '维度',
      DISCOUNT_PERCENTAGE: '折扣率',
      DISCOUNT_RATE: '折扣率',
      DISPLAY_NAME: '显示名称',
      DISPLAY_VALUE: '显示值',
      DISTRIBUTION: '分布',
      DOCUMENTATION: '文档',
      DURATION: '时长',
      EA_ACCESS_CONTROL: 'EA访问控制',
      EA_ACCESS_CONTROL_ASSIGNMENTS: 'EA访问控制分配',
      EA_ACCESS_CONTROL_LIST: 'EA访问控制列表',
      EA_ENROLLMENT: 'EA注册',
      EA_USAGE_HOURS: 'EA使用时间',
      EDIT: '编辑',
      EDIT_LICENSE_QTY: '编辑许可数量',
      EMAIL: '电子邮件',
      EMAIL_RECIPIENTS: '邮件接收者',
      END_CUSTOMER_TYPE: '最终客户类型',
      ENGINEER_OWNER: '工程师负责人',
      ENROLLMENT_NUMBER: '注册编号',
      ESTIMATED_BY: '估计依据',
      EVENT_TYPE: '事件类型',
      EVERY: '每',
      EXCEPTION: '异常',
      EXCLUDE: '排除',
      EXPIRATION_DATE: '到期日期',
      FAILURE: '失败',
      FEATURE: '功能',
      FEATURE_UPDATES: '功能更新',
      FILTER: '筛选',
      FILTERED_BY: '筛选条件',
      FILTER_BY_CUSTOMER: '按客户筛选',
      FILTER_NAME: '筛选名称',
      FIND: '查找',
      FINDING_TIME: '查找时间',
      FIRST_NAME: '名字',
      FOLDER: '文件夹',
      FREQUENCY: '频率',
      FULL_PAYNENT: '全额付款',
      GOAL: '目标',
      GOAL_ACCOMPLISHED: '目标完成',
      GOAL_STATUS: '目标状态',
      GOAL_UNDONE: '目标未完成',
      GPU: 'GPU',
      GROUPS: '组',
      GROUP_BY: '分组依据',
      GUIDING_CARD_TITLE: '感谢您选择Caloudi CMP。以下信息帮助您快速入门。',
      HAS_ADD_ONS: '有附加组件',
      HINTS: '提示',
      HOMEPAGE: '首页',
      HOST: '主机',
      HOSTS: '主机',
      HTTP_HEADERS: 'HTTP Headers',
      IDENTIFIED: '已识别',
      ID_EXISTED: 'ID已存在',
      IMPACT: '影响',
      IMPACTED_FIELD: '影响字段',
      IMPORT: '导入',
      INACTIVE: '停用',
      INCLUDE: '包含',
      INCLUDE_CREDITS: '包含信用额度',
      INTERNAL: '内部',
      INTERVAL: '间隔',
      INVITE_NEW_CUSTOMER: '邀请新客户',
      INVITE_NEW_RESELLER: '邀请新经销商',
      ISSUE_TYPE: '问题类型',
      IS_ADD_ON: '是附加组件',
      IS_MS_PRODUCT: '是微软产品',
      ITEMS_IN_CART: '购物车中的项目',
      ITEMS_SELECTED: '选定的项目',
      JOB_TITLE: '职位名称',
      KEY: '键',
      KEYWORD: '关键字',
      LABEL: '标签',
      LABEL_KEY: '标签键',
      LABEL_SELECTED: '标签已选定',
      LANGUAGE: '语言',
      LAST_MODIFIED_TIME: '最后修改时间',
      LAST_NAME: '姓氏',
      LAST_UPDATED: '最后更新',
      LATENCY_FROM_YOU: '来自您的延迟',
      LETTERSPACE: '',
      LIST: '列表',
      LIST_PRICE: '原价',
      LOCATION: '位置',
      MACHINE_FAMILY: '机器系列',
      MANAGEMENT: '管理',
      MANAGEMENT_GROUP: '管理组',
      MANAGEMENT_GROUP_ASSIGNMENT: '管理组分配',
      MANAGER: '管理员',
      MAP: '地图',
      MAPPING: '映射',
      MARGIN_PERCENTAGE_SYMBOL: '利润率%',
      MARKETPLACE: '市场',
      MEMO: '备忘录',
      MENU: '菜单',
      MENU_TYPE: '菜单类型',
      MESSAGE: '消息',
      METERED_BILLING: '计量计费',
      METERING_SERVICE_DIMENSIONS: '计量服务维度',
      METRICS: '指标',
      MICROSOFT_PARTNER_AGREEMENT: 'Microsoft合作伙伴协议',
      MIDDLE_NAME: '中间名',
      MONTHLY_OVERRAGE_DATE_ALERT: '每月预算超额警报',
      MONTHLY_REVENUE: '月收入',
      MONTHLY_SAVINGS: '月节省',
      MONTHLY_USAGE: '每月用量',
      MORE: '更多',
      MY_CART: '我的购物车',
      MY_RECOMMENDATION_LIST: '我的推荐清单',
      NAME: '名称',
      NEW: '新增',
      NEW_DIMENSION: '新维度',
      NEW_MONTHLY_HOURS: '新每月使用小时数',
      NEW_UNIT_PRICE_1Y: '新单价1年',
      NEW_USAGE_HOURS: '新使用小时数',
      NEW_USAGE_QTY: '新使用数量',
      NEXT: '下一步',
      NONE: '无',
      NORMAL: '正常',
      NOTE: '备注',
      NOTIFICATION: '通知',
      NOT_AVAILABLE_FOR_PURCHASE: '不可购买',
      NO_ADMIN_USERS: '无管理员',
      NO_INSTANCE_LABEL: '无实例标签',
      NO_LABEL: '无标签',
      NO_PURCHASE_ITEM: '无购买项目',
      NO_SUBSCRIPTION: '无订阅',
      NUMBER_OF_CHARACTER_CLASSES: '字符类型数量',
      NUMBER_OF_RESOURCES: '资源总数',
      NUMBER_OF_TYPES: '类型总数',
      OFFER: '提供项',
      OFFER_NAME: '提供项名称',
      OF_EVERY: '每',
      ONLY_ONE_SERVICE_PER_SUBSCRIPTION: '每个订阅只能有一个服务',
      ON_THE: '在',
      OPEN_IN_EMAIL: '在邮件中打开',
      OPERATION_HISTORY: '操作历史',
      ORDER: '订单',
      ORDER_NUMBER: '订单编号',
      ORDER_RESULT: '订单结果',
      ORDER_STATUS: '订单状态',
      ORDINAL: '序号',
      ORGANIZATION: '组织',
      ORGANIZATION_NAME: '组织名称',
      ORGANIZATION_UNIT: '组织单元',
      ORGANIZATION_UNIT_NAME: '组织单元名称',
      ORIGINAL_PRICE: '原价',
      OVERALL: '整体',
      OWNER: '负责人',
      PAGE: '页',
      PAGEVIEWS_DASHBOARD: '页面浏览仪表板',
      PART_NUMBER: '零件号',
      PAYMENT_AMOUNT: '付款金额',
      PAYMENT_METHOD: '付款方式',
      PAYMENT_METHOD_NAME: '付款方式名称',
      PAYMENT_TYPE: '付款类型',
      PERCENT: '百分比',
      PERCENTAGE: '百分比',
      PERCENTAGE_LIST_PRICE: '折扣百分比',
      PERCENTAGE_LIST_PRICE_SYMBOL: '折扣百分比',
      PERIOD: '周期',
      PERMISSIONS: '权限',
      PERSINAL_INFO: '可以保存或应用常用视图组合',
      PER_HOUR: '每小时',
      PER_MONTH: '每月',
      PER_YEAR: '每年',
      PHONE: '电话',
      PIVOT_SAVE_INFO: '如果以下信息已更改，请保存更改。',
      PLAN: '计划',
      PLATFORM: '平台',
      PORT: '端口',
      POSTAL_CODE: '邮政编码',
      PREFERRED_REGION: '首选区域',
      PREFERRED_VENDOR: '首选供应商',
      PRESET_FILTER: '预设筛选',
      PRESET_INFO: '可以保存或应用常用的筛选组合',
      PREVIOUS: '上一个',
      PRICE_SCHEDULES: '价格计划',
      PRICE_SHEET: '价格表',
      PRICE_SHEET_UPLOAD: '价格表上传',
      PRICING_CONFIG: '定价配置',
      PRICING_SETTING: '定价设置',
      PRICING_SETTINGS: '定价设置',
      PRIMARY_DOMAIN: '主域',
      PRINCIPAL_TYPE: '主体类型',
      PRIORITY: '优先级',
      PROBLEM_SUBTYPE: '问题子类型',
      PROBLEM_SUBTYPE_DESCRIPTION: '问题子类型描述',
      PROBLEM_SUBTYPE_LIST: '问题子类型列表',
      PROBLEM_TYPE: '问题类型',
      PROBLEM_TYPE_DESCRIPTION: '问题类型描述',
      PROCESSED: '已处理',
      PRODUCT_CATEGORY: '产品类别',
      PROGRAM: '计划',
      PROPERTIES: '属性',
      PROVINCE: '省份',
      PUBLISHED: '发布时间 ',
      PUBLISHER: '发布者',
      PURCHASE_PREREQUISITE: '购买前提',
      QUANTITY: '数量',
      QUERY_RESULT: '查询结果',
      RAM: '内存',
      RANGE: '范围',
      RATING: '评分',
      RECEIVER: '接收者',
      RECIPIENTS: '接收者',
      RECOMMEND: '推荐',
      RECOMMENDED_BY: '推荐依据',
      RECOMMENDED_INSTANCE: '推荐实例',
      RECOMMENDED_SKU: '推荐SKU',
      RECOMMEND_RIGHT_SIZING_ANALYSIS_INSTANCE: '推荐规格调整分析',
      RECORDS: '记录',
      REFERENCE_EXCHANGE_RATE: '参考汇率',
      REGION: '地区',
      REMOVE_PARTNERSHIP: '移除合作',
      REPLAY: '重播',
      REQUEST: '请求',
      REQUIRED: '必填项',
      RESELLER: '经销商',
      RESELLERS: '经销商',
      RESELLER_ACCEPTANCE_THROUGH_MICROSOFT: '经销商通过Microsoft接受',
      RESELLER_AGENT: '经销商代理人',
      RESELLER_AGENT_ASSIGNMENT: '经销商代理人分配',
      RESELLER_ASSIGNMENTS: '经销商分配',
      RESELLER_EMAIL: '经销商电子邮件',
      RESELLER_PRIMARY_CONTACT: '经销商主要联系人',
      RESERVATION: '保留',
      RESERVED_HOURS: '保留时数',
      RESOURCES_LIST: '资源列表',
      RESOURCE_CHARGES_LIST: '资源费用列表',
      RESOURCE_DATA: '资源数据',
      RESOURCE_PROPERTIES: '资源属性',
      RESPONSE_TIME: '响应时间',
      RESULTS: '结果',
      RESULT_CODE: '结果代码',
      RETAIL: '零售',
      REVENUE_PERIOD: '收入周期',
      REVIEW: '审核',
      REVISION: '修订',
      RIGHT_SIZING: '规格调整',
      RIGHT_SIZING_ANALYSIS: '规格调整分析',
      RIGHT_SIZING_ANALYSIS_DETAIL: '规格调整分析详情',
      ROLE: '角色',
      ROLE_ASSIGNMENTS: '角色分配',
      ROLE_LIST: '角色列表',
      ROOT_CAUSE_ANALYSIS: '根本原因分析',
      ROOT_DOMAIN: '根域',
      ROUND_TO: '四舍五入到',
      SALES: '销售',
      SALES_AGENT: '销售代理人',
      SALES_AGENT_ASSIGNMENT: '销售代理人分配',
      SALES_AGENT_EMAIL: '销售代理人电子邮件',
      SALES_AMOUNT: '销售金额',
      SALES_REVENUE: '销售收入',
      SAVE: '保存',
      SAVING: '节省',
      SAVINGS: '节省',
      SAVINGS_RANGE: '节省范围',
      SAVING_PERCENT: '节省百分比',
      SCAN: '扫描',
      SCANNER: '扫描仪',
      SCHEDULED_REPORT: '定时报告',
      SCOPE: '范围',
      SCORE: '评分',
      SCREENSHOT: '截图',
      SECURITY: '安全',
      SELECTED: '已选',
      SELECT_ONE_OPTION: '请选择以下选项之一',
      SEND_EMAIL_DESCRIPTION: '如有需要，可编辑内容，再通过电子邮件发送给您的客户',
      SENSITIVITY: '敏感性',
      SERVICE_ANALYSIS: '服务分析',
      SERVICE_COST: '服务成本',
      SERVICE_PLANS: '服务计划',
      SERVICE_TYPE: '服务类型',
      SETTING: '设置',
      SEVERITY: '严重性',
      SHOW_PRICE_AS: '显示价格为',
      SHOW_REVENUE_AS: '显示收入为',
      SIZE: '大小',
      SKU_COUNTS: 'SKU数量',
      SKU_INFO: 'SKU信息',
      SKU_NAME: 'SKU名称',
      SLOWEST_REQUEST: '最慢请求',
      SPOT: '现货(Spot)',
      STATE: '州',
      STATUS: '状态',
      STORAGE: '存储',
      SUBJECT: '主题',
      SUBMENU: '子菜单',
      SUBMITTED: '已提交',
      SUBSCRIBED: '已订阅',
      SUBSCRIPTIONS: '订阅',
      SUBSCRIPTION_NAME: '订阅名称',
      SUMMARY: '摘要',
      SUPPLIER: '供应商',
      SUPPLIERS: '供应商',
      SUPPORT: '支持',
      SUPPORT_AGENT: '支持专员',
      SUPPORT_AGENT_EMAIL: '支持专员电子邮件',
      SUPPORT_OWNER: '支持负责人',
      SUPPORT_TICKET: '支持请求',
      SUPPORT_TICKET_INFO: '支持请求信息',
      SUSPEND: '暂停',
      SUSPENDED: '暂停',
      SUSPENDED_SUBSCRIPTION: '暂停订阅',
      SWITCHABLE_USER_ASSIGN: '可切换用户分配',
      SWITCHABLE_USER_HAS_BEEN_UPDATED: '可切换用户已更新',
      SYNCED: '已同步',
      SYNC_LAST_DATA_MODIFIED_TIME: '同步上次数据修改时间',
      SYSTEM_MAINTENANCE: '系统维护',
      TABLE: '表格',
      TAG: '标签',
      TAGS: '标签',
      TAG_ID: '标签ID',
      TAG_NAME: '标签名称',
      TARGET: '目标',
      TARGETS: '目标',
      TARGET_SKU: '目标SKU',
      TASK: '任务',
      TAX_EXEMPTION: '免税',
      TAX_EXEMPTION_DETAIL: '免税详情',
      TAX_ID: '税号',
      TAX_INFO: '税务信息',
      TAX_RATE: '税率',
      TEMPSTORAGE: '临时存储',
      TERM: '期限',
      TEXT: '文本',
      TIMESPAN: '时间跨度',
      TIME_PERIOD: '周期',
      TIME_SCOPE: '时间范围',
      TIME_STAMP: '时间戳',
      TIME_ZONE: '时区',
      TITLE: '标题',
      TOOL: '工具',
      TOP: '前',
      TOTAL_USAGE: '总使用量',
      TUTORIAL_VIDEO: '教程视频',
      TYPE: '类型',
      UNCONSUMED: '未消耗',
      UNDEDUCTED_COST: '未扣减成本',
      UNDEDUCTED_HOURS: '未扣减小时数',
      UNIT_PRICE: '单价',
      UNIT_PRICE_1Y: '单价1年',
      UNIT_TYPE: '单位类型',
      UNSELECTED: '未选',
      UNSUBSCRIBE: '取消订阅',
      UNSUBSCRIBED: '已取消订阅',
      UNSYNCED: '未同步',
      UPDATE: '更新',
      UPDATE_LICENSE_AND_CREATE_INFO: '更新授权数量及新增销售订单',
      UPDATE_SUBSCRIPTION_STATUS: '更新订阅状态',
      USAGE: '使用量',
      USAGE_BREAKDOWN: '使用明细',
      USAGE_COST: '使用费用',
      USAGE_DASHBOARD: '使用量仪表板',
      USAGE_DATE: '使用日期',
      USAGE_DETAIL: '使用明细',
      USAGE_FILTER_DETAIL: '使用筛选详情',
      USAGE_HOURS: '使用时间',
      USAGE_LINEITEMS: '使用单项',
      USAGE_MONITOR: '使用监控',
      USAGE_QTY: '使用数量',
      USAGE_QUERY: '使用查询',
      USAGE_SUMMARY: '使用摘要',
      USED_HOURS: '已用小时数',
      USER: '用户',
      USERS: '用户',
      USERS_LICENSES: '用户与许可',
      USER_ACTIVITIES: '用户活动',
      USER_ASSIGNMENTS: '用户分配',
      USER_GROUP: '用户组',
      USER_GROUPS: '用户组',
      USER_GROUP_ASSIGNMENT: '用户组分配',
      USER_GROUP_CONTROL_ASSIGNMENTS: '用户组控制分配',
      USER_GROUP_CONTROL_LIST: '用户组列表',
      USER_GROUP_NAME: '用户组名称',
      USER_INFO: '用户信息',
      USER_LIST: '用户列表',
      USER_SEARCH: '用户搜索',
      VALUE: '值',
      VALUES: '值',
      VAULT_URI: '金库URI',
      VENDOR: '供应商',
      VIEW_CUSTOMERS: '查看客户',
      VIEW_NAME: '视图名称',
      VISIBILITY: '可见性',
      VM_SIZE: 'VM大小',
      VULNERABILITY: '漏洞',
      WAIT_FOR: '等待',
      WEBSITE: '网站',
      WEEKLY_USAGE: '每周使用量',
      WRITER: '撰写者',
      YEARLY_SAVINGS: '年节省',
    },
  },
};
