export const localeKeys = {
  HINT: {
    ACTIVATE_ORGANIZATION_UNIT_NOTE: 'HINT.ACTIVATE_ORGANIZATION_UNIT_NOTE',
    ACTIVATE_USER_GROUP_NOTE: 'HINT.ACTIVATE_USER_GROUP_NOTE',
    ADD_TO_ADVISORY_NOTE: 'HINT.ADD_TO_ADVISORY_NOTE',
    ADMIN_USER_HAS_BEEN_UPDATED: 'HINT.ADMIN_USER_HAS_BEEN_UPDATED',
    ANNOUNCEMENT_HAS_BEEN_CREATED: 'HINT.ANNOUNCEMENT_HAS_BEEN_CREATED',
    ANNOUNCEMENT_HAS_BEEN_DELETED: 'HINT.ANNOUNCEMENT_HAS_BEEN_DELETED',
    ANNOUNCEMENT_HAS_BEEN_UPDATED: 'HINT.ANNOUNCEMENT_HAS_BEEN_UPDATED',
    ANNOUNCEMENT_TYPE_HAS_BEEN_CREATED: 'HINT.ANNOUNCEMENT_TYPE_HAS_BEEN_CREATED',
    ANNOUNCEMENT_TYPE_HAS_BEEN_DELETED: 'HINT.ANNOUNCEMENT_TYPE_HAS_BEEN_DELETED',
    ANNOUNCEMENT_TYPE_HAS_BEEN_UPDATED: 'HINT.ANNOUNCEMENT_TYPE_HAS_BEEN_UPDATED',
    ASSIGNMENT_LIST_HAS_BEEN_UPDATED: 'HINT.ASSIGNMENT_LIST_HAS_BEEN_UPDATED',
    AUTOCOMPLETE_INFO: 'HINT.AUTOCOMPLETE_INFO',
    BC_CUSTOMER_INFO: 'HINT.BC_CUSTOMER_INFO',
    BC_PARTNER_INFO: 'HINT.BC_PARTNER_INFO',
    COMMENT_HAS_BEEN_ADDED: 'HINT.COMMENT_HAS_BEEN_ADDED',
    CONTACT_HAS_BEEN_CREATED: 'HINT.CONTACT_HAS_BEEN_CREATED',
    CONTACT_HAS_BEEN_DELETED: 'HINT.CONTACT_HAS_BEEN_DELETED',
    CONTACT_HAS_BEEN_UPDATED: 'HINT.CONTACT_HAS_BEEN_UPDATED',
    COST_DEVIATION_TOOLTIP: 'HINT.COST_DEVIATION_TOOLTIP',
    CSP_EMPTY_BAR_INFO: 'HINT.CSP_EMPTY_BAR_INFO',
    CUSTOMER_HAS_BEEN_CREATED: 'HINT.CUSTOMER_HAS_BEEN_CREATED',
    CUSTOMER_HAS_BEEN_UPDATED: 'HINT.CUSTOMER_HAS_BEEN_UPDATED',
    CUSTOMER_ORDER_HISTORY_CANCEL: 'HINT.CUSTOMER_ORDER_HISTORY_CANCEL',
    CUSTOMER_SUBSCRIPTION_ACTIVE_INFO: 'HINT.CUSTOMER_SUBSCRIPTION_ACTIVE_INFO',
    CUSTOMER_SUBSCRIPTION_SPSENDED_INFO: 'HINT.CUSTOMER_SUBSCRIPTION_SPSENDED_INFO',
    DEFAULT_PRICING_NOTE: 'HINT.DEFAULT_PRICING_NOTE',
    DELETE_ANNOUNCEMENT_NOTE: 'HINT.DELETE_ANNOUNCEMENT_NOTE',
    DELETE_ANNOUNCEMENT_TYPE_NOTE: 'HINT.DELETE_ANNOUNCEMENT_TYPE_NOTE',
    DELETE_BUDGET_NOTE: 'HINT.DELETE_BUDGET_NOTE',
    DELETE_CONTACT_NOTE: 'HINT.DELETE_CONTACT_NOTE',
    DELETE_DISCOUNT_PERCENT_RULE_NOTE: 'HINT.DELETE_DISCOUNT_PERCENT_RULE_NOTE',
    DELETE_EAENROLLMENT_NOTE: 'HINT.DELETE_EAENROLLMENT_NOTE',
    DELETE_FEATURE_PACK_NOTE: 'HINT.DELETE_FEATURE_PACK_NOTE',
    DELETE_MAPPING_RULE_NOTE: 'HINT.DELETE_MAPPING_RULE_NOTE',
    DELETE_ORGANIZATION_NOTE: 'HINT.DELETE_ORGANIZATION_NOTE',
    DELETE_PERSONAL_FILTER_NOTE: 'HINT.DELETE_PERSONAL_FILTER_NOTE',
    DELETE_PERSONAL_VIEW_NOTE: 'HINT.DELETE_PERSONAL_VIEW_NOTE',
    DELETE_PRICING_CONFIG_NOTE: 'HINT.DELETE_PRICING_CONFIG_NOTE',
    DELETE_PROBLEM_SUBTYPE_NOTE: 'HINT.DELETE_PROBLEM_SUBTYPE_NOTE',
    DELETE_PROBLEM_TYPE_NOTE: 'HINT.DELETE_PROBLEM_TYPE_NOTE',
    DELETE_RECOMMENDATION_NOTE: 'HINT.DELETE_RECOMMENDATION_NOTE',
    DELETE_RESELLER_CONFIRM: 'HINT.DELETE_RESELLER_CONFIRM',
    DELETE_SKU_RULE_NOTE: 'HINT.DELETE_SKU_RULE_NOTE',
    DELETE_RI_RECOMM_REPORT_NOTE: 'HINT.DELETE_RI_RECOMM_REPORT_NOTE',
    DELETE_USAGE_ACCESS_CONTROL_NOTE: 'HINT.DELETE_USAGE_ACCESS_CONTROL_NOTE',
    DELETE_USER_NOTE: 'HINT.DELETE_USER_NOTE',
    DESTROY_PROVISION_NOTE: 'HINT.DESTROY_PROVISION_NOTE',
    DIMENSION_RULE_HAS_BEEN_CREATED: 'HINT.DIMENSION_RULE_HAS_BEEN_CREATED',
    DIMENSION_RULE_HAS_BEEN_DELETED: 'HINT.DIMENSION_RULE_HAS_BEEN_DELETED',
    DIMENSION_RULE_HAS_BEEN_UPDATED: 'HINT.DIMENSION_RULE_HAS_BEEN_UPDATED',
    DIMENSION_RULE_HAS_BEEN_IMPORTED: 'HINT.DIMENSION_RULE_HAS_BEEN_IMPORTED',
    EA_ENROLLMENT_SAVE_CONFIRM: 'HINT.EA_ENROLLMENT_SAVE_CONFIRM',
    EMAIL_RECIPIENTS_HINT: 'HINT.EMAIL_RECIPIENTS_HINT',
    ENGINEER_OWNER_HAS_BEEN_ASSIGNED: 'HINT.ENGINEER_OWNER_HAS_BEEN_ASSIGNED',
    FILE_UPLOADED: 'HINT.FILE_UPLOADED',
    INTERVAL_INFO: 'HINT.INTERVAL_INFO',
    INVOICE_BILLING_PERIOD: 'HINT.INVOICE_BILLING_PERIOD',
    LINE_ITEM_HAS_BEEN_UPDATED: 'HINT.LINE_ITEM_HAS_BEEN_UPDATED',
    LINE_ITEM_HAS_BEEN_VOIDED: 'HINT.LINE_ITEM_HAS_BEEN_VOIDED',
    LOADING_MESSAGE: 'HINT.LOADING_MESSAGE',
    MAKE_PAYMENT_NOTE: 'HINT.MAKE_PAYMENT_NOTE',
    MAX_CPU_P_95_NOTE: 'HINT.MAX_CPU_P_95_NOTE',
    MAX_MEMORY_P_95_NOTE: 'HINT.MAX_MEMORY_P_95_NOTE',
    MAX_TOTAL_NETWORK_P_95_NOTE: 'HINT.MAX_TOTAL_NETWORK_P_95_NOTE',
    MEMO_UPDATED: 'HINT.MEMO_UPDATED',
    NAME_EMPTY_ALERT: 'HINT.NAME_EMPTY_ALERT',
    NEW_USER_HAS_BEEN_CREATED: 'HINT.NEW_USER_HAS_BEEN_CREATED',
    ORGANIZATION_HAS_BEEN_CREATED: 'HINT.ORGANIZATION_HAS_BEEN_CREATED',
    ORGANIZATION_HAS_BEEN_DELETED: 'HINT.ORGANIZATION_HAS_BEEN_DELETED',
    ORGANIZATION_HAS_BEEN_UPDATED: 'HINT.ORGANIZATION_HAS_BEEN_UPDATED',
    ORGANIZATION_UNIT_HAS_BEEN_CREATED: 'HINT.ORGANIZATION_UNIT_HAS_BEEN_CREATED',
    ORGANIZATION_UNIT_HAS_BEEN_DELETED: 'HINT.ORGANIZATION_UNIT_HAS_BEEN_DELETED',
    ORGANIZATION_UNIT_HAS_BEEN_UPDATED: 'HINT.ORGANIZATION_UNIT_HAS_BEEN_UPDATED',
    PAYMENT_SUCCESSFUL: 'HINT.PAYMENT_SUCCESSFUL',
    PLACE_LINE_ITEM_ORDER_NOTE: 'HINT.PLACE_LINE_ITEM_ORDER_NOTE',
    PLACE_ORDER_NOTE: 'HINT.PLACE_ORDER_NOTE',
    PLEASE_WAIT: 'HINT.PLEASE_WAIT',
    PRICING_CONFIG_HAS_BEEN_CREATED: 'HINT.PRICING_CONFIG_HAS_BEEN_CREATED',
    PRICING_CONFIG_HAS_BEEN_DELETED: 'HINT.PRICING_CONFIG_HAS_BEEN_DELETED',
    PRICING_CONFIG_HAS_BEEN_UPDATED: 'HINT.PRICING_CONFIG_HAS_BEEN_UPDATED',
    PRIORITY_HAS_BEEN_UPDATED: 'HINT.PRIORITY_HAS_BEEN_UPDATED',
    PROBLEM_SUBTYPE_HAS_BEEN_CREATED: 'HINT.PROBLEM_SUBTYPE_HAS_BEEN_CREATED',
    PROBLEM_SUBTYPE_HAS_BEEN_DELETED: 'HINT.PROBLEM_SUBTYPE_HAS_BEEN_DELETED',
    PROBLEM_SUBTYPE_HAS_BEEN_UPDATED: 'HINT.PROBLEM_SUBTYPE_HAS_BEEN_UPDATED',
    PROBLEM_TYPE_HAS_BEEN_CREATED: 'HINT.PROBLEM_TYPE_HAS_BEEN_CREATED',
    PROBLEM_TYPE_HAS_BEEN_DELETED: 'HINT.PROBLEM_TYPE_HAS_BEEN_DELETED',
    PROBLEM_TYPE_HAS_BEEN_UPDATED: 'HINT.PROBLEM_TYPE_HAS_BEEN_UPDATED',
    PURCHASE_AGREEMENT_INFO: 'HINT.PURCHASE_AGREEMENT_INFO',
    PURCHASE_AGREEMENT_VISIT: 'HINT.PURCHASE_AGREEMENT_VISIT',
    PURCHASE_RI_INFO: 'HINT.PURCHASE_RI_INFO',
    RECOMMENDATIONS_NOTICS: 'HINT.RECOMMENDATIONS_NOTICS',
    RECOMMENDATION_HAS_BEEN_DELETED: 'HINT.RECOMMENDATION_HAS_BEEN_DELETED',
    RESELLER_PREREQUISITES_NOTE: 'HINT.RESELLER_PREREQUISITES_NOTE',
    RESERLLER_PROFILE_LINK_NOTE: 'HINT.RESERLLER_PROFILE_LINK_NOTE',
    REPORT_HAS_BEEN_DELETED: 'HINT.REPORT_HAS_BEEN_DELETED',
    REPORT_IS_BEING_GENERATED: 'HINT.REPORT_IS_BEING_GENERATED',
    RESOURCE_TAG_INFO: 'HINT.RESOURCE_TAG_INFO',
    RI_ANALYSIS_COST_WITH_RECOMMENDATION_NOTE: 'HINT.RI_ANALYSIS_COST_WITH_RECOMMENDATION_NOTE',
    RI_ANALYSIS_COVERAGE_RATE_NOTE: 'HINT.RI_ANALYSIS_COVERAGE_RATE_NOTE',
    RI_ANALYSIS_CURRENT_COST_NOTE: 'HINT.RI_ANALYSIS_CURRENT_COST_NOTE',
    RI_ANALYSIS_RESET_NOTE: 'HINT.RI_ANALYSIS_RESET_NOTE',
    RI_COVERAGE_RATE_COST_NOTE: 'HINT.RI_COVERAGE_RATE_COST_NOTE',
    RI_COVERAGE_RATE_NOTE: 'HINT.RI_COVERAGE_RATE_NOTE',
    RI_COVERAGE_RATE_QUANTITY_NOTE: 'HINT.RI_COVERAGE_RATE_QUANTITY_NOTE',
    RI_DEDUCTED_HOURS_NOTE: 'HINT.RI_DEDUCTED_HOURS_NOTE',
    RI_NEW_COVERAGE_RATE_NOTE: 'HINT.RI_NEW_COVERAGE_RATE_NOTE',
    RI_NEW_COVERAGE_RATE_QUANTITY_NOTE: 'HINT.RI_NEW_COVERAGE_RATE_QUANTITY_NOTE',
    RI_RECOMMENDATIONS_NOTICS: 'HINT.RI_RECOMMENDATIONS_NOTICS',
    RI_RECOMM_REPORT_NOTICES: 'HINT.RI_RECOMM_REPORT_NOTICES',
    RI_UTILIZATION_RATE_NOTE: 'HINT.RI_UTILIZATION_RATE_NOTE',
    RI_UTILIZATION_RATE_QUANTITY_NOTE: 'HINT.RI_UTILIZATION_RATE_QUANTITY_NOTE',
    SALES_ORDER_HAS_BEEN_VOIDED: 'HINT.SALES_ORDER_HAS_BEEN_VOIDED',
    SAME_NAME_ALERT: 'HINT.SAME_NAME_ALERT',
    SET_UP_ACCOUNT_NOTE: 'HINT.SET_UP_ACCOUNT_NOTE',
    SAVING_RANGE_INPUT_HINT: 'HINT.SAVING_RANGE_INPUT_HINT',
    SAVING_RANGE_MIN_MAX_HINT: 'HINT.SAVING_RANGE_MIN_MAX_HINT',
    STATUS_HAS_BEEN_UPDATED: 'HINT.STATUS_HAS_BEEN_UPDATED',
    SUBSCRIPTION_COUNTS_ONBOARD_NOTE: 'HINT.SUBSCRIPTION_COUNTS_ONBOARD_NOTE',
    SUPPORT_TICKET_HAS_BEEN_CREATED: 'HINT.SUPPORT_TICKET_HAS_BEEN_CREATED',
    SUPPORT_OWNER_HAS_BEEN_ASSIGNED: 'HINT.SUPPORT_OWNER_HAS_BEEN_ASSIGNED',
    SUSPEND_ORGANIZATION_UNIT_NOTE: 'HINT.SUSPEND_ORGANIZATION_UNIT_NOTE',
    SUSPEND_USER_GROUP_NOTE: 'HINT.SUSPEND_USER_GROUP_NOTE',
    SWITCH_TO_USERID_NOTE: 'HINT.SWITCH_TO_USERID_NOTE',
    SWITCH_TO_USERID_SUCESSFULLY: 'HINT.SWITCH_TO_USERID_SUCESSFULLY',
    TOTAL_REVENUE_INFO: 'HINT.TOTAL_REVENUE_INFO',
    UPLOADING: 'HINT.UPLOADING',
    UPLOAD_FAILED: 'HINT.UPLOAD_FAILED',
    USER_GROUP_HAS_BEEN_CREATED: 'HINT.USER_GROUP_HAS_BEEN_CREATED',
    USER_GROUP_HAS_BEEN_DELETED: 'HINT.USER_GROUP_HAS_BEEN_DELETED',
    USER_GROUP_HAS_BEEN_UPDATED: 'HINT.USER_GROUP_HAS_BEEN_UPDATED',
    UNSUBSCRIBE_SUBSCRIPTION: 'HINT.UNSUBSCRIBE_SUBSCRIPTION',
    VOID_LINE_ITEM_NOTE: 'HINT.VOID_LINE_ITEM_NOTE',
    VOID_SALES_ORDER_NOTE: 'HINT.VOID_SALES_ORDER_NOTE',
    WRONG_EMAIL_FORMAT: 'HINT.WRONG_EMAIL_FORMAT',
  },
};
