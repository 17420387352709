// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { Observable } from 'rxjs';
// Caloudi
import environment from '@env';
// Interface
import { EAEnrollmentSelectItem, AuditLogQuery } from '@base/model';
import { UserSelectItem, DateSelectItem, PeriodSelectItem } from '@base/model';
import { DataTable } from '@base/model';

@Injectable({ providedIn: 'root' })
export class AuditLogService {
  constructor(private readonly http: HttpClient) {}

  public getSysEAEnrollmentList(): Observable<EAEnrollmentSelectItem[]> {
    return this.http.get<EAEnrollmentSelectItem[]>(
      `${environment.cmpAPIServer.url}/api/auditLog/dimension/sysAdmin/eaEnrollment`
    );
  }

  public getAgentEAEnrollmentList(): Observable<EAEnrollmentSelectItem[]> {
    return this.http.get<EAEnrollmentSelectItem[]>(
      `${environment.cmpAPIServer.url}/api/auditLog/dimension/agentAdmin/eaEnrollment`
    );
  }

  public getSysUserList(): Observable<UserSelectItem[]> {
    return this.http.get<UserSelectItem[]>(`${environment.cmpAPIServer.url}/api/auditLog/dimension/sysAdmin/user`);
  }

  public getSysAuditTypeList(): Observable<SelectItem<string>[]> {
    return this.http.get<SelectItem<string>[]>(
      `${environment.cmpAPIServer.url}/api/auditLog/dimension/sysAdmin/auditType`
    );
  }

  public getEaUserList(): Observable<UserSelectItem[]> {
    return this.http.get<UserSelectItem[]>(`${environment.cmpAPIServer.url}/api/auditLog/dimension/eaAdmin/user`);
  }

  public getEaAuditTypeList(): Observable<SelectItem<string>[]> {
    return this.http.get<SelectItem<string>[]>(
      `${environment.cmpAPIServer.url}/api/auditLog/dimension/eaAdmin/auditType`
    );
  }

  public getAgentUserList(): Observable<UserSelectItem[]> {
    return this.http.get<UserSelectItem[]>(`${environment.cmpAPIServer.url}/api/auditLog/dimension/agentAdmin/user`);
  }

  public getAgentAuditTypeList(): Observable<SelectItem<string>[]> {
    return this.http.get<SelectItem<string>[]>(
      `${environment.cmpAPIServer.url}/api/auditLog/dimension/agentAdmin/auditType`
    );
  }

  public getDateList(): Observable<DateSelectItem[]> {
    return this.http.get<DateSelectItem[]>(`${environment.cmpAPIServer.url}/api/auditLog/dimension/date`);
  }

  public getPeriodList(): Observable<PeriodSelectItem[]> {
    return this.http.get<PeriodSelectItem[]>(`${environment.cmpAPIServer.url}/api/auditLog/dimension/billingPeriod`);
  }

  public queryAuditLogByEAAdmin(auditLogQuery: AuditLogQuery): Observable<DataTable<AuditLogQuery>> {
    return this.http.post<DataTable<AuditLogQuery>>(
      `${environment.cmpAPIServer.url}/api/auditLog/eaAdmin/query`,
      auditLogQuery
    );
  }

  public queryAuditLogByAgentAdmin(auditLogQuery: AuditLogQuery): Observable<DataTable<unknown>> {
    return this.http.post<DataTable<unknown>>(
      `${environment.cmpAPIServer.url}/api/auditLog/agentAdmin/query`,
      auditLogQuery
    );
  }

  public queryAuditLogBySysAdmin(auditLogQuery: AuditLogQuery): Observable<DataTable<unknown>> {
    return this.http.post<DataTable<unknown>>(
      `${environment.cmpAPIServer.url}/api/auditLog/sysAdmin/query`,
      auditLogQuery
    );
  }
}
