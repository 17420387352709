export const localeKeys = {
  ACTION: {
    ACCEPT: 'ACTION.ACCEPT',
    ADD: 'ACTION.ADD',
    ADDED_TO_CART: 'ACTION.ADDED_TO_CART',
    ADD_CHILD: 'ACTION.ADD_CHILD',
    ADD_COMMENT: 'ACTION.ADD_COMMENT',
    ADD_CONTACT: 'ACTION.ADD_CONTACT',
    ADD_CRON_REPORT: 'ACTION.ADD_CRON_REPORT',
    ADD_DISCOUNT_PERCENT_RULE: 'ACTION.ADD_DISCOUNT_PERCENT_RULE',
    ADD_ENROLLMENT: 'ACTION.ADD_ENROLLMENT',
    ADD_FEATURE_PACK: 'ACTION.ADD_FEATURE_PACK',
    ADD_FILTER: 'ACTION.ADD_FILTER',
    ADD_LABEL: 'ACTION.ADD_LABEL',
    ADD_NEW: 'ACTION.ADD_NEW',
    ADD_ORGANIZATION: 'ACTION.ADD_ORGANIZATION',
    ADD_ORGANIZATION_UNIT: 'ACTION.ADD_ORGANIZATION_UNIT',
    ADD_PAYMENT_METHOD: 'ACTION.ADD_PAYMENT_METHOD',
    ADD_PRICING_CONFIG: 'ACTION.ADD_PRICING_CONFIG',
    ADD_PROBLEM_SUBTYPE: 'ACTION.ADD_PROBLEM_SUBTYPE',
    ADD_PROBLEM_TYPE: 'ACTION.ADD_PROBLEM_TYPE',
    ADD_PRODUCT: 'ACTION.ADD_PRODUCT',
    ADD_PRODUCTS: 'ACTION.ADD_PRODUCTS',
    ADD_RESELLER: 'ACTION.ADD_RESELLER',
    ADD_RESOURCE_GROUP: 'ACTION.ADD_RESOURCE_GROUP',
    ADD_ROOT: 'ACTION.ADD_ROOT',
    ADD_SKU_RULE: 'ACTION.ADD_SKU_RULE',
    ADD_SUBSCRIPTIONS: 'ACTION.ADD_SUBSCRIPTIONS',
    ADD_TAG: 'ACTION.ADD_TAG',
    ADD_TO_CART: 'ACTION.ADD_TO_CART',
    ADD_USER: 'ACTION.ADD_USER',
    ADD_USER_GROUP: 'ACTION.ADD_USER_GROUP',
    ANALYSIS_DETAIL: 'ACTION.ANALYSIS_DETAIL',
    ATTACH: 'ACTION.ATTACH',
    BACK: 'ACTION.BACK',
    BACK_TO_SEARCH_MODE: 'ACTION.BACK_TO_SEARCH_MODE',
    BUILD: 'ACTION.BUILD',
    BUY: 'ACTION.BUY',
    CANCEL: 'ACTION.CANCEL',
    CHANGE_PASSWORD: 'ACTION.CHANGE_PASSWORD',
    CHOOSE: 'ACTION.CHOOSE',
    CLICK: 'ACTION.CLICK',
    CLOSE: 'ACTION.CLOSE',
    CONFIRM: 'ACTION.CONFIRM',
    CONFIRMATION: 'ACTION.CONFIRMATION',
    CONTINUE: 'ACTION.CONTINUE',
    CONTINUE_PURCHASE: 'ACTION.CONTINUE_PURCHASE',
    COPY_RESOURCE_ID_TO_CLIPBOARD: 'ACTION.COPY_RESOURCE_ID_TO_CLIPBOARD',
    CREATE_ANNOUNCEMENT: 'ACTION.CREATE_ANNOUNCEMENT',
    CREATE_USAGE_ACCESS_CONTROL: 'ACTION.CREATE_USAGE_ACCESS_CONTROL',
    DELETE: 'ACTION.DELETE',
    DELETE_BUDGET: 'ACTION.DELETE_BUDGET',
    DELETE_CRON_REPORT: 'ACTION.DELETE_CRON_REPORT',
    DELETE_FILTER: 'ACTION.DELETE_FILTER',
    DELETE_VIEW: 'ACTION.DELETE_VIEW',
    DELETE_MENU_NODE: 'ACTION.DELETE_MENU_NODE',
    DELETE_MENU_NODE_SUCCESSFULLY: 'ACTION.DELETE_MENU_NODE_SUCCESSFULLY',
    DELETE_TAG: 'ACTION.DELETE_TAG',
    DELETE_USAGE_ACCESS_CONTROL: 'ACTION.DELETE_USAGE_ACCESS_CONTROL',
    DELETE_USER: 'ACTION.DELETE_USER',
    DENIED: 'ACTION.DENIED',
    DETACH: 'ACTION.DETACH',
    DETECTING: 'ACTION.DETECTING',
    DONE: 'ACTION.DONE',
    DOWNLOAD: 'ACTION.DOWNLOAD',
    EDIT: 'ACTION.EDIT',
    EDIT_ADMIN_USERS: 'ACTION.EDIT_ADMIN_USERS',
    EDIT_ANNOUNCEMENT: 'ACTION.EDIT_ANNOUNCEMENT',
    EDIT_CONTACT: 'ACTION.EDIT_CONTACT',
    EDIT_MEMO: 'ACTION.EDIT_MEMO',
    EDIT_ORGANIZATION: 'ACTION.EDIT_ORGANIZATION',
    EDIT_PRIORITY: 'ACTION.EDIT_PRIORITY',
    EDIT_PROBLEM_SUBTYPE: 'ACTION.EDIT_PROBLEM_SUBTYPE',
    EDIT_PROBLEM_TYPE: 'ACTION.EDIT_PROBLEM_TYPE',
    EDIT_STATUS: 'ACTION.EDIT_STATUS',
    EDIT_SWITCHABLE_USERS: 'ACTION.EDIT_SWITCHABLE_USERS',
    EDIT_TAG: 'ACTION.EDIT_TAG',
    EDIT_USER: 'ACTION.EDIT_USER',
    ENTER_NEW_NAME: 'ACTION.ENTER_NEW_NAME',
    EXPAND: 'ACTION.EXPAND',
    EXPAND_GROUP: 'ACTION.EXPAND_GROUP',
    EXPORT: 'ACTION.EXPORT',
    GENERATE_REPORT: 'ACTION.GENERATE_REPORT',
    HIDE_DETAILS: 'ACTION.HIDE_DETAILS',
    INTERACTIVE_MODE: 'ACTION.INTERACTIVE_MODE',
    IMPORT: 'ACTION.IMPORT',
    IN_PROGRESS: 'ACTION.IN_PROGRESS',
    LEARN_MORE: 'ACTION.LEARN_MORE',
    MAIL_SCHEDULE: 'ACTION.MAIL_SCHEDULE',
    MAKE_A_PAYMENT: 'ACTION.MAKE_A_PAYMENT',
    MODIFY: 'ACTION.MODIFY',
    NEW: 'ACTION.NEW',
    NEW_BUDGET: 'ACTION.NEW_BUDGET',
    NEW_REPORT: 'ACTION.NEW_REPORT',
    NEW_SUPPORT_TICKET: 'ACTION.NEW_SUPPORT_TICKET',
    NEXT: 'ACTION.NEXT',
    PAY: 'ACTION.PAY',
    PREVIEW: 'ACTION.PREVIEW',
    PRINT: 'ACTION.PRINT',
    PRINT_MODE: 'ACTION.PRINT_MODE',
    QUERY: 'ACTION.QUERY',
    REFRESH: 'ACTION.REFRESH',
    REFRESH_LATENCY: 'ACTION.REFRESH_LATENCY',
    REFRESHING: 'ACTION.REFRESHING',
    REMOVE: 'ACTION.REMOVE',
    RESEND: 'ACTION.RESEND',
    RESET_USER_MFA: 'ACTION.RESET_USER_MFA',
    RESET_USER_PASSWORD: 'ACTION.RESET_USER_PASSWORD',
    RESTORE: 'ACTION.RESTORE',
    RETURN: 'ACTION.RETURN',
    SAVE: 'ACTION.SAVE',
    SAVE_AS: 'ACTION.SAVE_AS',
    SAVE_CHART: 'ACTION.SAVE_CHART',
    SAVE_FILTER: 'ACTION.SAVE_FILTER',
    SAVE_VIEW: 'ACTION.SAVE_VIEW',
    SAVE_PNG: 'ACTION.SAVE_PNG',
    SAVE_SVG: 'ACTION.SAVE_SVG',
    SEARCH: 'ACTION.SEARCH',
    SELECT: 'ACTION.SELECT',
    SELECT_DATE: 'ACTION.SELECT_DATE',
    SELECT_DIMENSION: 'ACTION.SELECT_DIMENSION',
    SELECT_FOLDER: 'ACTION.SELECT_FOLDER',
    SELECT_KEY: 'ACTION.SELECT_KEY',
    SELECT_VALUE: 'ACTION.SELECT_VALUE',
    SELECT_VALUES: 'ACTION.SELECT_VALUES',
    SEND: 'ACTION.SEND',
    SENT: 'ACTION.SENT',
    SEND_CRON_REPORT: 'ACTION.SEND_CRON_REPORT',
    SEND_INVITATION_SUCCESS: 'ACTION.SEND_INVITATION_SUCCESS',
    SEND_NOW: 'ACTION.SEND_NOW',
    SEND_REPORT: 'ACTION.SEND_REPORT',
    SEND_REPORT_BY_EMAIL: 'ACTION.SEND_REPORT_BY_EMAIL',
    SET_AS_DEFAULT: 'ACTION.SET_AS_DEFAULT',
    SHARE: 'ACTION.SHARE',
    SHOW_DETAILS: 'ACTION.SHOW_DETAILS',
    STATUS_HAS_BEEN_UPDATED: 'ACTION.STATUS_HAS_BEEN_UPDATED',
    SUBMIT: 'ACTION.SUBMIT',
    SUPPORT_TICKET_HAS_BEEN_CREATED: 'ACTION.SUPPORT_TICKET_HAS_BEEN_CREATED',
    SWITCH: 'ACTION.SWITCH',
    SWITCH_APP: 'ACTION.SWITCH_APP',
    SWITCH_CUSTOMER: 'ACTION.SWITCH_CUSTOMER',
    SWITCH_EA: 'ACTION.SWITCH_EA',
    SWITCH_ENROLLMENT: 'ACTION.SWITCH_ENROLLMENT',
    SWITCH_PARTNER: 'ACTION.SWITCH_PARTNER',
    SWITCH_RESELLER: 'ACTION.SWITCH_RESELLER',
    SWITCH_USER: 'ACTION.SWITCH_USER',
    SWITCH_VIEW: 'ACTION.SWITCH_VIEW',
    TAKE_ACTION: 'ACTION.TAKE_ACTION',
    TOGGLE_FULL_WIDTH: 'ACTION.TOGGLE_FULL_WIDTH',
    TOP: 'ACTION.TOP',
    UPDATE: 'ACTION.UPDATE',
    UPDATE_BUDGET: 'ACTION.UPDATE_BUDGET',
    UPDATE_CRON_REPORT: 'ACTION.UPDATE_CRON_REPORT',
    UPDATE_EA_ACCESS_CONTROL: 'ACTION.UPDATE_EA_ACCESS_CONTROL',
    UPDATE_MENU: 'ACTION.UPDATE_MENU',
    UPDATE_USAGE_ACCESS_CONTROL: 'ACTION.UPDATE_USAGE_ACCESS_CONTROL',
    UPLOAD: 'ACTION.UPLOAD',
    UPLOAD_USER: 'ACTION.UPLOAD_USER',
    VIEW: 'ACTION.VIEW',
    VIEW_ORDERS: 'ACTION.VIEW_ORDERS',
    VIEW_SUBSCRIPTIONS: 'ACTION.VIEW_SUBSCRIPTIONS',
  },
};
